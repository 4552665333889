import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const confirmDanger = (title: string) => {
  return MySwal.fire({
    title,
    icon: "error",
    showCloseButton: true,
    confirmButtonColor: "#db3030",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
};

export type ConfirmIconType =
  | "error"
  | "success"
  | "warning"
  | "info"
  | "question";
export interface IGenericConfirmationProps {
  confirmButtonText: string;
  cancelButtonText: string;
  icon: ConfirmIconType;
  title: string;
}

export const genericConfirmAction = ({
  title,
  confirmButtonText,
  icon,
  cancelButtonText,
}: IGenericConfirmationProps) => {
  return MySwal.fire({
    title,
    confirmButtonText,
    icon,
    cancelButtonText,
    showCloseButton: true,
    confirmButtonColor: "#db3030",
    showCancelButton: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
};
