import * as constants from "../../constants";
import { generateStatsSaga, IGenericActionCreator } from "./generateStatsSaga";
import * as actionCreators from "../actions";
import * as AxiosRequests from "../../axios";

const brandFailedOperationsNumber: IGenericActionCreator = {
  request: actionCreators.getBrandFailedOperationsNumber,
  success: actionCreators.getBrandFailedOperationsNumberSuccess,
  failure: actionCreators.getBrandFailedOperationsNumberFailure,
};
const brandRegisteredCustomerNumber: IGenericActionCreator = {
  request: actionCreators.getBrandRegisteredCustomerNumber,
  success: actionCreators.getBrandRegisteredCustomerNumberSuccess,
  failure: actionCreators.getBrandRegisteredCustomerNumberFailure,
};
const brandReviewsNumber: IGenericActionCreator = {
  request: actionCreators.getBrandReviewsNumber,
  success: actionCreators.getBrandReviewsNumberSuccess,
  failure: actionCreators.getBrandReviewsNumberFailure,
};
const brandConversionRate: IGenericActionCreator = {
  request: actionCreators.getBrandConversionRate,
  success: actionCreators.getBrandConversionRateSuccess,
  failure: actionCreators.getBrandConversionRateFailure,
};
const brandRedemptionRate: IGenericActionCreator = {
  request: actionCreators.getBrandRedemptionRate,
  success: actionCreators.getBrandRedemptionRateSuccess,
  failure: actionCreators.getBrandRedemptionRateFailure,
};
const brandRedemptionNumber: IGenericActionCreator = {
  request: actionCreators.getBrandRedemptionNumber,
  success: actionCreators.getBrandRedemptionNumberSuccess,
  failure: actionCreators.getBrandRedemptionNumberFailure,
};
const brandTempCustomersNumber: IGenericActionCreator = {
  request: actionCreators.getBrandTempCustomersNumber,
  success: actionCreators.getBrandTempCustomersNumberSuccess,
  failure: actionCreators.getBrandTempCustomersNumberFailure,
};
const brandVisitsNumber: IGenericActionCreator = {
  request: actionCreators.getBrandVisitsNumber,
  success: actionCreators.getBrandVisitsNumberSuccess,
  failure: actionCreators.getBrandVisitsNumberFailure,
};
const brandUnresolvedIssuesNumber: IGenericActionCreator = {
  request: actionCreators.getUnresolvedIssuesNumber,
  success: actionCreators.getUnresolvedIssuesNumberSuccess,
  failure: actionCreators.getUnresolvedIssuesNumberFailure,
};

export const brandFailedOperationsNumberSaga = generateStatsSaga(
  constants.getBrandFailedOperationsNumberAction,
  brandFailedOperationsNumber,
  AxiosRequests.getFailedOperationsNumber
);
export const brandRegisteredCustomerNumberSaga = generateStatsSaga(
  constants.getBrandRegisteredCustomerNumberAction,
  brandRegisteredCustomerNumber,
  AxiosRequests.getStoreRegisteredCustomersNumber
);
export const brandReviewsNumberSaga = generateStatsSaga(
  constants.getBrandReviewsNumberAction,
  brandReviewsNumber,
  AxiosRequests.getReviewsNumber
);
export const brandConversionRateSaga = generateStatsSaga(
  constants.getBrandConversionRateAction,
  brandConversionRate,
  AxiosRequests.getStoreConversionRate
);
export const brandRedemptionRateSaga = generateStatsSaga(
  constants.getBrandRedemptionRateAction,
  brandRedemptionRate,
  AxiosRequests.getStoreRedemptionRate
);
export const brandRedemptionNumberSaga = generateStatsSaga(
  constants.getBrandRedemptionNumberAction,
  brandRedemptionNumber,
  AxiosRequests.getStoreRedemptionNumber
);
export const brandTempCustomersNumberSaga = generateStatsSaga(
  constants.getBrandTempCustomersNumberAction,
  brandTempCustomersNumber,
  AxiosRequests.getStoreTempCustomersNumber
);
export const brandVisitsNumberSaga = generateStatsSaga(
  constants.getBrandVisitsNumberAction,
  brandVisitsNumber,
  AxiosRequests.getVisitsNumber
);
export const brandUnresolvedIssuesNumberSaga = generateStatsSaga(
  constants.getBrandUnresolvedIssuesNumberAction,
  brandUnresolvedIssuesNumber,
  AxiosRequests.getUnresolvedIssuesNumber
);
