import { action, payload } from "ts-action";
import { getReachCampaignsAction } from "../../constants";
import { IGetCampaignsFilters } from "../../axios/get-campaigns";
import { IStore } from "../../axios/getBrands";

export interface IManager {
  phoneNumber: string;
}

export interface IReachCampaignsResponse {
  active_at: number;
  created_at: number;
  manager_id: string;
  sent_users_count: number;
  sms_content: string;
  store_id: string;
  total_users_count: number;
  updated_at: number;
  id: string;
  campaign_name: string;
  launch_timestamp: number;
  launch_timezone: string;
  status: number;
  store: IStore;
  manager: IManager;
  sms_number: number;
  criteria: string;
}

export const getReachCampaigns = action(
  getReachCampaignsAction.requested,
  payload<IGetCampaignsFilters>()
);
export const getReachCampaignsSuccess = action(
  getReachCampaignsAction.fulfilled,
  payload<{ campaigns: IReachCampaignsResponse[]; total_campaigns: number }>()
);
export const getReachCampaignsFailure = action(
  getReachCampaignsAction.rejected,
  payload<Error>()
);
