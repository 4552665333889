import * as React from "react";
import * as styles from "../styles.module.sass";
import { BeatLoader } from "react-spinners";

interface IProps {
  label: string;
  type: string;
  placeholder: string;
  viewOnly?: boolean;
  className?: string;
  noValidationMessages?: boolean;
  disabled?: boolean;
  loading?: boolean;
  validationStyles?: {
    valid: React.CSSProperties;
    invalid: React.CSSProperties;
  };
  validationClassNames?: {
    valid: string;
    invalid: string;
  };
  postFix: string;
  value?: { koinz: number; brand: number };
  touched?: boolean;
  error?: any;
  name?: string;
  onChange: (e) => void;
  onBlur: (e) => void;
}

export const CustomPostfixField: React.FC<IProps> = (props) => {
  const {
    viewOnly,
    placeholder,
    noValidationMessages,
    className,
    disabled,
    loading,
    postFix,
    onChange,
    onBlur,
    name,
    error,
    touched,
    value,
  } = props;

  const shouldShowLoading = loading;

  const canValidate = !shouldShowLoading && touched;
  const isInvalid = canValidate && error;
  const isValid = canValidate && !error;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleClickWrapper = React.useCallback(() => {
    inputRef.current?.focus();
  }, []);
  const handleKoinzChange = (e) => {
    onChange({
      koinz: Number(e.target.value) <= 100 ? Number(e.target.value) : 0,
      brand: Number(e.target.value) <= 100 ? 100 - Number(e.target.value) : 100,
    });
  };
  const handleBrandChange = (e) => {
    // tslint:disable-next-line: no-console
    onChange({
      brand: Number(e.target.value) <= 100 ? Number(e.target.value) : 0,
      koinz: Number(e.target.value) <= 100 ? 100 - Number(e.target.value) : 100,
    });
    // tslint:disable-next-line: no-console
    // console.log('brand Important ====' , input.value , input.name);
  };

  return (
    <div className={styles.customWrapper}>
      <div
        onClick={handleClickWrapper}
        className={`${styles.inputWrapper}  ${className || ""}`}
      >
        <label htmlFor={name} className={styles.customLabel}>
          <span>Koinz percentage</span>
        </label>
        <div
          style={{
            // ...(active ? { borderColor: "#fdbb11" } : {}),
            ...(isValid ? { borderColor: "#38c172" } : {}),
            ...(isInvalid ? { borderColor: "#db3030" } : {}),
          }}
          className={`${styles.inputAndLoadingWrapper}`}
        >
          <input
            onBlur={onBlur}
            disabled={viewOnly || disabled}
            autoComplete="off"
            placeholder={placeholder}
            className={styles.input}
            id={name}
            onChange={handleKoinzChange}
            name={`${name}.koinz`}
            value={value?.koinz}
            type={"number"}
            min="0"
            max="100"
            pattern="^[0-100]"
          />

          {shouldShowLoading && (
            <BeatLoader loading={true} size={7} color={"#FEBE19"} />
          )}
          <div className={styles.postfixWrapper}>
            <span className={styles.postfix}>{postFix}</span>
          </div>
        </div>
        {!noValidationMessages && touched && error && (
          <p
            style={{
              margin: 0,
              padding: "1rem",
              color: "#db3030",
              fontSize: "1.2rem",
            }}
          >
            *{error}
          </p>
        )}
      </div>

      <div
        onClick={handleClickWrapper}
        className={`${styles.inputWrapper}  ${className || ""}`}
      >
        <label htmlFor={name} className={styles.customLabel}>
          <span>Brand percentage</span>
        </label>

        <div
          style={{
            // ...(active ? { borderColor: "#fdbb11" } : {}),
            ...(isValid ? { borderColor: "#38c172" } : {}),
            ...(isInvalid ? { borderColor: "#db3030" } : {}),
          }}
          className={`${styles.inputAndLoadingWrapper}`}
        >
          <input
            onBlur={onBlur}
            disabled={viewOnly || disabled}
            autoComplete="off"
            placeholder={placeholder}
            className={styles.input}
            id={name}
            onChange={handleBrandChange}
            name={`${name}.brand`}
            value={value?.brand}
            type={"number"}
            min="0"
            max="100"
            pattern="^[0-100]"
          />
          {shouldShowLoading && (
            <BeatLoader loading={true} size={7} color={"#FEBE19"} />
          )}
          <div className={styles.postfixWrapper}>
            <span className={styles.postfix}>{postFix}</span>
          </div>
        </div>
        {!noValidationMessages && error && (
          <p
            style={{
              margin: 0,
              padding: "1rem",
              color: "#db3030",
              fontSize: "1.2rem",
            }}
          >
            {error.message}
          </p>
        )}
      </div>
    </div>
  );
};
