import * as constants from "../../constants";
import { IVerifyPaymentSuccessAction } from "../../types";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";

export interface IPaymentReducerState {
  paid: number;
  verified: boolean;
  failed: boolean;
  verifying: boolean;
}

const VERIFIED = "verified";
const PAID = "paid";
const FAILED = "failed";
const VERIFYING = "verifying";

const initialState: IPaymentReducerState = Map({
  [VERIFIED]: false,
  [PAID]: 1,
  [FAILED]: false,
  [VERIFYING]: false,
}).toJS();

const paymentReducer = (
  state: IPaymentReducerState = initialState,
  action: IVerifyPaymentSuccessAction
): IPaymentReducerState => {
  switch (action.type) {
    case constants.verifyPaymentAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(PAID, (action as IVerifyPaymentSuccessAction).payload);
        map.set(VERIFIED, true);
        map.set(FAILED, false);
        map.set(VERIFYING, false);
      });
    }
    case constants.verifyPaymentAction.requested: {
      return mutateState(state, (map) => {
        map.set(PAID, 1);
        map.set(VERIFIED, false);
        map.set(FAILED, false);
        map.set(VERIFYING, true);
      });
    }
    case constants.verifyPaymentAction.rejected: {
      return mutateState(state, (map) => {
        map.set(PAID, 1);
        map.set(VERIFIED, false);
        map.set(FAILED, true);
        map.set(VERIFYING, false);
      });
    }
    case constants.ACTION_RESET_PAYMENT: {
      return mutateState(state, (map) => {
        map.set(PAID, 1);
        map.set(VERIFIED, false);
        map.set(FAILED, false);
        map.set(VERIFYING, false);
      });
    }
    default:
      return state;
  }
};

export default paymentReducer;
