import React from "react";
import * as styles from "./styles.module.sass";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { stopPropagationWrapper } from "../../Components/CampaignRequests/CampaignRequest";

type afterConfirmCb = (...args: any[]) => Promise<any>;

type toggleCollapse = (confirmAction: afterConfirmCb) => void;

interface IState {
  collapse: boolean;
  onConfirmAction: afterConfirmCb;
  confirmDisabled: boolean;
}

interface IProps {
  renderButtons: (
    toggleCollapse: toggleCollapse
  ) => React.ReactElement<HTMLElement>;
  renderCollapseContent?: () => React.ReactElement<HTMLElement>;
}

class ConfirmSelections extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      confirmDisabled: false,
      collapse: false,
      onConfirmAction: () => Promise.resolve(true),
    };
  }

  public toggle = (onConfirmAction) => {
    this.setState((prevState) => ({
      collapse: !prevState.collapse,
      onConfirmAction: stopPropagationWrapper(onConfirmAction),
    }));
  };
  public toggleCollapse = () => {
    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  public toggleDisabledConfirm = () => {
    this.setState({
      confirmDisabled: !this.state.confirmDisabled,
    });
  };

  public handleConfirm = (e) => {
    this.toggleDisabledConfirm();
    this.state.onConfirmAction(e).finally(() => {
      this.toggleCollapse();
      this.toggleDisabledConfirm();
    });
  };

  public render() {
    const duration = 500;

    const defaultStyle = {
      transition: `all ${duration}ms ease-in-out`,
      opacity: 0,
    };

    const transitionStyles = {
      false: { opacity: 0 },
      true: { opacity: 1 },
    };
    return (
      <div className={styles.wrapper}>
        {!this.state.collapse ? this.props.renderButtons(this.toggle) : ""}
        <Collapse
          style={{
            ...defaultStyle,
            ...transitionStyles[this.state.collapse ? "true" : "false"],
          }}
          in={this.state.collapse}
          timeout={duration}
          className={styles.body}
          isOpen={this.state.collapse}
        >
          <Card className={styles.card}>
            <CardBody className={styles.cardBody}>
              <div className={styles.text}>Confirm Request?</div>
              <Button
                onClick={this.handleConfirm}
                disabled={this.state.confirmDisabled}
                className={styles.confirmBtn}
              >
                &#10003;
              </Button>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}

export default ConfirmSelections;
