import { useQuery } from "react-query";
import { getActiveLocationsStatsApi } from "../../axios/merchants";
import { store } from "../../redux-store/configureStore";
export const getActiveLocationsStats = () => {
  const token = store.getState().authReducer.token;
  const result = useQuery(
    "active-locations-stats",
    async () => await getActiveLocationsStatsApi(token),
    {
      retry: 2,
    }
  );
  return result;
};
