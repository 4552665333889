import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  createBrandPosEntity,
  createBrandPosEntitySuccess,
  createBrandPosEntityFailure,
  getBrandPosEntitiesReset,
} from "../actions";
import { createBrandPosEntityAction } from "../../constants";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import { createBrandPosEntityApi } from "../../axios/pos";
import { customNotify } from "../../UIComponents/customNotification/customNotification";

const actionTypes = union({ createBrandPosEntity });
function* createBrandPosEntitySaga({ payload }: typeof actionTypes) {
  try {
    yield put(getBrandPosEntitiesReset());
    const token = yield select(selectToken);
    const res = yield call(createBrandPosEntityApi, token, payload);
    yield put(
      createBrandPosEntitySuccess({ ...res.data.pos, mode: undefined })
    );
    customNotify.success("Your changes have been saved", 2000);
  } catch (error) {
    customNotify.error("Something went wrong, please try again", 2000);
    yield put(createBrandPosEntityFailure(error));
  }
}

export function* watchCreateBrandPosEntitySaga() {
  yield takeLatest(
    createBrandPosEntityAction.requested,
    createBrandPosEntitySaga
  );
}
