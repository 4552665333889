import { Modal } from "@material-ui/core";
import React from "react";
import styles from "./styles.module.sass";

interface IProps {
  onClose: () => void;
  open: boolean;
  errors: any[];
}
export const CsvUploadErrorsPopup: React.FC<IProps> = ({
  open,
  onClose,
  errors,
}) => {
  return (
    <Modal open={open} onClose={onClose} className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <span className={styles.label}>CSV upload errors</span>
          <div onClick={onClose} className={styles.closeWrapper}>
            <img
              className={styles.close}
              src={require("../../../../../assets/icon-close.svg")}
            />
          </div>
        </div>
        <div className={styles.errorsWrapper}>
          {errors.map((error, index) => (
            <span key={index} className={styles.errors}>
              {index + 1} - {error}
            </span>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default CsvUploadErrorsPopup;
