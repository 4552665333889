import {
  OrderingTypes,
  PromoMerchantVisibility,
  ToggleStatus,
} from "../../types";

export const setPromocodeInitialValues = (initialValues) => {
  return {
    ...initialValues,
    splitExpireAfter: false,
    splitMaxCashback: false,
    splitMinEligibleInvoice: false,
    splitCapping: false,
    splitMerchant: false,
    stores: initialValues?.stores,
    suggested:
      initialValues?.suggested === 1
        ? ToggleStatus.enable
        : ToggleStatus.disable,
    ordering_type: initialValues?.ordering_type
      ? initialValues?.ordering_type
      : OrderingTypes.ordering,
    type: initialValues?.type || initialValues?.category,
    stores_ids: initialValues?.stores_id || [],
    promo_merchant_visibility:
      typeof initialValues?.promo_merchant_visibility !== "undefined"
        ? initialValues?.promo_merchant_visibility
        : PromoMerchantVisibility.visible,
    promo_code: initialValues?.promo_code,
    rank: initialValues?.rank,
    target_customers: initialValues?.target_customers,
    discount_value:
      initialValues?.type === "discount_percentage"
        ? initialValues?.discount_value * 100
        : initialValues?.discount_value,
    cashback_value:
      initialValues?.type === "cashback_percentage"
        ? initialValues?.cashback_value * 100
        : initialValues?.cashback_value,
  };
};
