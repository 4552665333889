import { Mutations } from "../../queries";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeMerchantDealsAtom } from "../../atoms";

export const useQRPortalDeal = (store_id, data) => {
  const [activeMerchantDeals, setActiveMerchantDeals] = useRecoilState(
    activeMerchantDealsAtom
  );

  const {
    mutate: mutateQRPortalDeal,
    variables: mutateQRPortalDealVariables,
    reset: mutationQRPortalDealReset,
    status: mutateQRPortalDealStatus,
  } = Mutations.editActiveMerchantDeals(store_id, "qr-portal-status");
  const qrPortalToggle = (checked: boolean) => {
    setActiveMerchantDeals((prevState) => ({
      ...prevState,
      qr_portal_status: {
        loading: "loading",
        status: checked ? "service_enabled" : "service_disabled",
      },
    }));
    mutateQRPortalDeal(checked ? "service_enabled" : "service_disabled");
  };

  useEffect(() => {
    if (mutateQRPortalDealStatus === "success") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        qr_portal_status: {
          status: prevState.qr_portal_status.status,
          loading: "success",
        },
      }));
      mutationQRPortalDealReset();
    } else if (mutateQRPortalDealStatus === "error") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        qr_portal_status: {
          status:
            data.qr_portal_status === "service_enabled_unpublished" ||
            data.qr_portal_status === "service_enabled_published"
              ? "service_enabled"
              : "service_disabled",
          loading: "error",
        },
      }));
    }
  }, [mutateQRPortalDealVariables, mutateQRPortalDealStatus, data]);

  return {
    mutateQRPortalDeal,
    qrPortalToggle,
    activeMerchantDeals,
  };
};
