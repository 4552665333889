import axios from "./axios";
import * as Requests from "./requests";

export const expirePromoCodeAPI = (token: string, promoId: string) => {
  return axios.put(
    Requests.expirePromoCodeUrl(promoId),
    {},
    {
      headers: { token },
      baseURL: process.env.PICKUP_ENDPOINT,
    }
  );
};
