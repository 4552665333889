import React, { useEffect } from "react";
import styles from "./styles.module.sass";
import { PromotedSectionTitle } from "./components/SectionTitleModal";
import BrandsTable from "./components/BrandsTable";
import { useGetMerchants } from "../../../hooks";
import { useRecoilState } from "recoil";
import { filtersAtom } from "../../../atoms";
import { useSelect } from "../../../hooks/useSelect";
import { useDeepCompareEffect } from "use-deep-compare";
import FiltersHeader from "./components/FiltersHeader";
import { useDispatch } from "react-redux";
import { getPromotedBrandsTitle } from "../../../redux-store/actions/promotedSectionTitleAction";

const PromotedSection: React.FC = () => {
  const dispatch = useDispatch();
  const { merchantById } = useGetMerchants();
  const [openTitleModal, setOpenTitleModal] = React.useState<boolean>(false);
  const [, setFilters] = useRecoilState(filtersAtom);
  const { addBrandSubmitted, title } = useSelect((state) => ({
    addBrandSubmitted: state.brandsReducer.addBrandSubmitted,
    title: state.promotedSectionTitleReducer,
  }));

  useDeepCompareEffect(() => {
    if (addBrandSubmitted) {
      setFilters((prev) => ({
        params: {
          ...prev.params,
        },
        queryEnabled: true,
      }));
    }
  }, [addBrandSubmitted]);
  useEffect(() => {
    setFilters((prev) => ({
      params: {
        page: 0,
        activity: undefined,
        country: undefined,
        merchant_status: undefined,
        payment_status: undefined,
        search: undefined,
      },
      queryEnabled: true,
    }));
    dispatch(getPromotedBrandsTitle());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>Promoted Brands</h2>
      </div>
      {title.isLoading === "suceess" && (
        <div className={styles.sectionTitleWrapper}>
          <h3>
            <span
              className={styles.sectionTitle}
              onClick={() => setOpenTitleModal(true)}
            >
              {title.en_title} - {title.ar_title}
              <img src={require("../../../assets/edit.svg")} />
            </span>
          </h3>
        </div>
      )}
      <FiltersHeader brands={merchantById} setFilters={setFilters} />
      <BrandsTable />
      {openTitleModal && (
        <PromotedSectionTitle
          open={openTitleModal}
          onClose={() => setOpenTitleModal(false)}
        />
      )}
    </div>
  );
};

export default PromotedSection;
