import { IConfirmPaymentAction } from "../../types";
import { selectForm, selectToken } from "../selectors";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { confirmPaymentFailure, confirmPaymentSuccess } from "../actions";
import { confirmPaymentAction } from "../../constants";
import { IPaymentRequest } from "../../axios/verifyPayment";
import { setSubmitFailed, setSubmitSucceeded, startSubmit } from "redux-form";
import { IFormState } from "../rootReducer";
import { confirmPayment } from "../../axios/confirmPayment";
import { notify } from "react-notify-toast";
import history from "../../helpers/history";

function* confirmPaymentSaga(action: IConfirmPaymentAction) {
  try {
    yield put(startSubmit("confirmationForm"));
    const form: IFormState = yield select(selectForm);
    const { password } = form.confirmationForm.values as any;
    const { payment_term, payment_date, comment } = form.paymentForm
      .values as any;
    const token = yield select(selectToken);
    const data: IPaymentRequest = {
      password,
      payment_date: payment_date.format("x"),
      payment_term,
      comment,
      store_id: action.payload,
    };
    yield call(confirmPayment, token, data);
    yield put(confirmPaymentSuccess());
    yield put(setSubmitSucceeded("confirmationForm"));
    notify.show("Payment confirmed successfully.", "success");
    yield call(history.push as any, `/profile/${data.store_id}`);
  } catch (e) {
    yield put(setSubmitFailed("confirmationForm"));
    yield put(confirmPaymentFailure(e));
  }
}

export function* watchConfirmPaymentSaga() {
  yield takeLatest(confirmPaymentAction.requested, confirmPaymentSaga);
}
