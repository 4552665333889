import React from "react";
import * as styles from "../BannerCards/styles.module.sass";
import { GenericButton } from "../../SharedComponent/GenericButtonWithIcon";
import { AnnouncementItem } from "./AnnouncementItem";
import { useSelect } from "../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { getAnnouncements } from "../redux-store/actions/announcementsActions";
import AddAnnouncement from "./AddAnnouncement";

export const Announcements: React.FC = () => {
  const { announcements, announcementsById } = useSelect(
    (state) => state.announcementsReducer
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAnnouncements());
  }, []);
  const [isAddFormOpen, setIsAddFormOpen] = React.useState(false);
  const closeAddForm = React.useCallback(() => setIsAddFormOpen(false), []);
  const openAddForm = React.useCallback(() => setIsAddFormOpen(true), []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>
          Total popup announcement ({announcements.length})
        </div>
        <GenericButton
          onClick={openAddForm}
          label="Add popup announcement"
          className={styles.addCardCTA}
          IconClass={styles.addIcon}
          iconPath={require("../../../assets/add.svg")}
        />
      </div>
      <div className={styles.cardsWrapper}>
        {announcements.map((ann, index) => {
          const item = announcementsById[ann];
          return <AnnouncementItem key={index} announcementData={item} />;
        })}
      </div>
      <AddAnnouncement closeForm={closeAddForm} open={isAddFormOpen} />
    </div>
  );
};
