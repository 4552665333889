import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../selectors";
import { AxiosPromise } from "axios";
import { IStatsFilters } from "../../axios/types";

interface IGenericAction {
  requested: string;
  fulfilled: string;
  rejected: string;
}

export interface IGenericActionCreator {
  success: (
    number: number
  ) => {
    type: string;
    payload: number;
  };
  failure: (
    error: Error
  ) => {
    type: string;
    payload: Error;
  };
  request: (
    store_id: string,
    filters: IStatsFilters
  ) => {
    type: string;
    payload: { store_id: string; filters: IStatsFilters };
  };
}

type AxiosRequester = (
  token: string,
  store_id: string,
  filters: IStatsFilters
) => AxiosPromise;

export function generateStatsSaga(
  genericAction: IGenericAction,
  genericActionCreator: IGenericActionCreator,
  axiosRequest: AxiosRequester
) {
  function* saga(action) {
    try {
      const token: string = yield select(selectToken);
      const res = yield call(
        axiosRequest,
        token,
        action.payload.store_id,
        action.payload.filters
      );
      yield put(
        genericActionCreator.success(res.data[Object.keys(res.data)[0]])
      );
    } catch (e) {
      yield put(genericActionCreator.failure(e));
    }
  }

  return function* () {
    yield takeLatest(genericAction.requested, saga);
  };
}
