import React from "react";
import styles from "./styles.module.sass";
import closeIcon from "../../../assets/close_icon.svg";
import { CheckIcon } from "../../../assets/components";
import ic_back from "../../../assets/ic_back.svg";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useSelect } from "../../../hooks/useSelect";
import moment from "moment";
import { resolveTasksReset } from "../../../redux-store/actions";
import { useDispatch } from "react-redux";

export const ActionSummary: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { messages } = useSelect((state) => ({ ...state.resolveTasksReducer }));
  const goBackToActionCenter = () => {
    dispatch(resolveTasksReset());
    history.push("/account-managers/action-center");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <img
          onClick={goBackToActionCenter}
          src={closeIcon}
          className={styles.closeIcon}
        />
      </div>
      <div className={styles.successfullyIcon}>
        <CheckIcon width={40} height={40} stroke="#38C172" />
      </div>
      <span className={styles.successfullyText}>Message sent successfully</span>
      <span className={styles.toAllPoc}>to All POC</span>
      <div className={styles.summaryOfActionsWrapper}>
        <span className={styles.summaryOfActionsTitle}>
          Here is summary of your actions
        </span>{" "}
        <div className={styles.messagesWrapper}>
          {messages.map((element) => (
            <div className={styles.messageWrapper} key={element.id}>
              <span className={styles.date}>
                {moment(element.created_at).calendar()}
              </span>
              <span className={styles.message}>{element.message}</span>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.goBackToActionCenterWrapper}>
        <img
          onClick={goBackToActionCenter}
          className={styles.goBackToActionCenterIcon}
          src={ic_back}
        />
        <span className={styles.goBackToActionCenterTitle}>
          Go back to action center
        </span>
      </div>
    </div>
  );
};

export default withRouter(ActionSummary);
