import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  IGetCountryStoresRequestedAction,
  getCountryStoresAction,
} from "../../constants";
import { selectToken } from "../selectors";
import {
  getCountryStoresSuccess,
  getCountryStoresFailure,
  getAllCountryStores,
} from "../actions/getCountryStoresActions";
import { getCountriesStoresAPI } from "../../axios/get-countries-stores";

function* getCountriesStoresSaga(action: IGetCountryStoresRequestedAction) {
  try {
    const token: string = yield select(selectToken);
    const res = yield call(getCountriesStoresAPI, token, action.payload);
    if (!action.payload.country_code) {
      yield put(getAllCountryStores(res.data.stores ?? []));
    } else {
      yield put(getCountryStoresSuccess(res.data.stores ?? []));
    }
  } catch (e) {
    yield put(getCountryStoresFailure(e));
  }
}

export function* watchGetCountriesStoresSaga() {
  yield takeLatest(getCountryStoresAction.requested, getCountriesStoresSaga);
}
