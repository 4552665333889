import axios from "../axios";
import { posEntitiesURL } from "../requests";
export const getBrandPosEntitiesApi = (token: string, store_id: string) => {
  return axios.get(posEntitiesURL, {
    baseURL: process.env.POS_ENDPOINT,
    headers: {
      token,
    },
    params: {
      store_id,
    },
  });
};
