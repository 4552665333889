import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export const logDashboardPreviewAPI = (token, storeId): AxiosReturn<{}> =>
  axios.post(
    Requests.logDashboardPreview,
    { store_id: storeId },
    {
      baseURL: process.env.LUMEN_ENDPOINT,
      headers: {
        token,
      },
    }
  );
