import moment from "moment-timezone";
import momentJS from "moment";
import { ISelectItemData } from "../../../SharedComponent/RenderDropDownSelect";
import { PromoCodesUniversalToggleStatues } from "../../../SharedComponent/ToggleSwitch";

export const processEditingPromoCodesValues = (
  values,
  activeScreen: PromoScreens,
  country_iso_code: string,
  reactive?: number
) => {
  const formData = new FormData();
  formData.append("type", activeScreen);
  if (reactive) {
    formData.append("reactivate", reactive as any);
  }
  values.stores_ids?.forEach((store) => {
    formData.append("stores_ids[]", store);
  });
  values.stores_ids.forEach((store) =>
    formData.append(
      "stores_fund_percentage[]",
      values.stores_fund_percentage[store].brand
    )
  );
  formData.append("rank", values.rank);
  const isMultiplier = Number(activeScreen === PromoScreens.multiplier);
  formData.append("title", values.title);
  formData.append("desc", values.desc);
  formData.append("ar_desc", values.ar_desc);
  formData.append("ar_title", values.ar_title);
  formData.append("min_threshold", !isMultiplier ? values.min_threshold : 0);
  formData.append("max_cashback_value", values.max_cashback_value);

  formData.append(
    "promo_merchant_visibility",
    values.promo_merchant_visibility
  );
  formData.append("allowed_payment_method", values.allowed_payment_method);

  formData.append(
    "end_time",
    momentJS.isMoment(values.end_time)
      ? +values.end_time.format("x")
      : values.end_time
  );
  // formData.append("targeting_method",values.targeting_method)
  formData.append("max_usage_times", values.max_usage_times);
  if (!values.target_customers) {
    formData.delete("target_customers");
  } else {
    formData.append("target_customers", values.target_customers);
  }
  formData.append("country_iso_code", country_iso_code);
  return formData;
};

export const processPromoCodesValues = (
  values,
  activeScreen: PromoScreens,
  activePromoTypes: PromoTypes,
  country_iso_code: string,
  is_compensational: boolean
) => {
  const [currentTimeZone] =
    moment.tz.zonesForCountry(country_iso_code?.toUpperCase()) || [];
  const formData = new FormData();
  formData.append("type", activeScreen);
  values.stores_ids?.forEach((store) => {
    formData.append("stores_ids[]", store);
  });
  const isMultiplier = Number(activeScreen === PromoScreens.multiplier);
  if (is_compensational) {
    formData.append("is_compensational", `${is_compensational}`);
    formData.append("expiry_days", values.expiry_days);
  }

  // formData.append("stores_ids", values.stores_ids);
  formData.append("title", values.title);

  formData.append("ordering_type", activePromoTypes);

  values.stores_ids.forEach((store) =>
    formData.append(
      "stores_fund_percentage[]",
      values.stores_fund_percentage[store].brand
    )
  );
  formData.append("cashback_value", values.cashback_value);
  formData.append("max_cashback_value", values.max_cashback_value);
  formData.append("desc", values.desc);
  formData.append("ar_desc", values.ar_desc);
  formData.append("ar_title", values.ar_title);
  formData.append("min_threshold", !isMultiplier ? values.min_threshold : 0);
  formData.append("multiplier_value", values.multiplier_value);
  formData.append("discount_value", values.discount_value);

  formData.append("max_discount_value", values.max_discount_value);
  formData.append(
    "start_time",
    moment(values.start_time).tz(currentTimeZone).startOf("day").format("x")
  );
  formData.append(
    "end_time",
    moment(values.end_time).tz(currentTimeZone).endOf("day").format("x")
  );
  formData.append("rank", values.rank);
  formData.append("max_usage_times", values.max_usage_times);
  formData.append("target_customers", values.target_customers);
  formData.append(
    "suggested",
    `${Number(values.suggested === PromoCodesUniversalToggleStatues.enable)}`
  );
  formData.append(
    "promo_merchant_visibility",
    values.promo_merchant_visibility
  );
  formData.append("allowed_payment_method", values.allowed_payment_method);
  formData.append("multiplier_value", values.multiplier_value);
  formData.append("promo_code", values.promo_code);
  return formData;
};

export const processUniversalPromoCodesValues = (
  values,
  activeScreen: PromoScreens,
  activePromoTypes: PromoTypes,
  country_iso_code
) => {
  const [currentTimeZone] =
    moment.tz.zonesForCountry(country_iso_code?.toUpperCase()) || [];
  // const stores = values.stores_ids.forEach(storeId =>({
  //   stores_ids: storeId,
  // }));
  const isMultiplier = Number(activeScreen === PromoScreens.multiplier);
  return {
    max_cashback_value: values.max_cashback_value,
    cashback_value: values.cashback_value,
    stores_ids: [...values.stores_ids],
    title: values.title,
    desc: values.desc,
    ar_desc: values.ar_desc,
    ar_title: values.ar_title,
    type: activeScreen,
    min_threshold: !isMultiplier ? values.min_threshold : 0,
    discount_value: values.discount_value,
    max_discount_value: values.max_discount_value,
    ordering_type: activePromoTypes,
    start_time: moment(values.start_time)
      .tz(currentTimeZone)
      .startOf("day")
      .format("x"),
    end_time: moment(values.end_time)
      .tz(currentTimeZone)
      .endOf("day")
      .format("x"),
    max_usage_times: values.max_usage_times,
    promo_code: values.promo_code,
    targeting_method: values.targeting_method,
    rank: Number(values.rank),
    suggested: Number(
      values.suggested === PromoCodesUniversalToggleStatues.enable
    ),
    promo_merchant_visibility: values.promo_merchant_visibility,
    allowed_payment_method: values.allowed_payment_method,
    // "stores_ids[]": values.storeId,
    stores_fund_percentage:
      values?.stores_ids.map(
        (store) => values?.stores_fund_percentage[store]?.brand
      ) || [],
    multiplier_value: values.multiplier_value,
  };
};

export enum PromoScreens {
  def = "",
  percentage = "discount_percentage",
  discount = "discount_value",
  multiplier = "points_multiplier",
  cashback_percentage = "cashback_percentage",
}

export enum PromoTypes {
  def = "",
  ordering = "ordering",
  pickup = "pickup",
  delivery = "delivery",
}

export const pointsSelectItems: ISelectItemData[] = [
  { label: "2X", value: 2 },
  { label: "3X", value: 3 },
  { label: "4X", value: 4 },
  { label: "5X", value: 5 },
];

export const ordering_type: ISelectItemData[] = [
  { label: "Ordering", value: "ordering" },
  { label: "Pickup", value: "pickup" },
  { label: "Delivery", value: "delivery" },
];

export enum PromocodeAppearanceTypes {
  def = "",
  normal = "Normal",
  universal = "Universal",
  compensational = "Compensational",
}

export const CATEGORIES = {
  cashback_percentage: "Cashback",
  discount_percentage: "Percentage",
};

export const PROMO_TYPE_LIST = [
  {
    value: PromoScreens.percentage,
    label: "Percentage",
  },
  {
    value: PromoScreens.discount,
    label: "Discount value",
  },
  {
    value: PromoScreens.multiplier,
    label: "Points multiplier",
  },
  {
    value: PromoScreens.cashback_percentage,
    label: "Cashback",
  },
];

export const setStoresFundPercentageById = (stores) => {
  return stores?.reduce((acc, next) => {
    const map = { ...acc };
    map[next?.id] = {
      koinz: next?.store_fund_percentage
        ? Number(Math.abs(100 - Number(next?.store_fund_percentage)))
        : 100,
      brand: next?.store_fund_percentage
        ? Number(next?.store_fund_percentage)
        : 0,
    };
    return map;
  }, {});
};

export const setStoreShares = (stores) => {
  return stores?.reduce((acc, next) => {
    return {
      ...acc,
      store_fund_percentage: {
        ...acc.store_fund_percentage,
        [next.id]: {
          koinz: Number(Math.abs(100 - Number(next.store_fund_percentage))),
          brand: Number(next.store_fund_percentage),
        },
      },
      masked_value: {
        ...acc.masked_value,
        [next.id]:
          next.masked_value < 1
            ? (next.masked_value * 100).toFixed()
            : next.masked_value
            ? next?.masked_value
            : 0,
      },
      masked_promo_code: {
        ...acc.masked_promo_code,
        [next.id]: next.masked_promo_code ? next.masked_promo_code : "",
      },
    };
  }, {});
};
export const setStores = (stores, country_iso_code) => {
  return stores.map((store) => ({
    name: store?.name,
    id: store?.id,
    store_fund_percentage: store?.store_fund_percentage,
    country_iso_code,
    masked_value: store?.masked_value,
    masked_promo_code: store?.masked_promo_code,
    max_discount_value: store?.max_discount_value,
    min_threshold: stores?.min_threshold,
  }));
};
