import { on, reducer } from "ts-action";
import {
  resetAppState,
  resolveTasksReset,
  resolveTasksSuccess,
} from "../actions";
import { resolveTasksAction, contactMethodLabels } from "./../../constants";
import { withLoadingReducer } from "./withLoadingState";

interface IResolveTasksState {
  messages: Array<{ id: string | number; message: string; created_at: number }>;
}
const actionCenterAlerts = {
  0: "transaction",
  1: "orders",
  2: "fulfilment",
};
export const resolveTasksReducer = withLoadingReducer<IResolveTasksState>(
  reducer<IResolveTasksState>(
    [
      on(resetAppState, () => ({ messages: [] })),
      on(resolveTasksReset, () => ({ messages: [] })),
      on(
        resolveTasksSuccess,
        (resolveTasksState, { payload: resolveTasksPayload }) => {
          const { stores } = resolveTasksPayload;

          const messages = stores.flatMap(
            ({
              id: storeId,
              store_name: storeName,
              tasks,
              contact_templates,
            }) => {
              const alertTypes = tasks.map(
                (task) => actionCenterAlerts[task.alert_type]
              );
              const contactMethods = contact_templates.map(
                (contactMethod) => contactMethodLabels[contactMethod.id]
              );

              const branchText =
                tasks.length === 1 ? "1 branch" : `${tasks.length} branches`;
              const alertTypesText =
                alertTypes.length === 1
                  ? alertTypes[0]
                  : `${alertTypes
                      .slice(0, -1)
                      .join(", ")}, and ${alertTypes.slice(-1)}`;
              const viaText =
                contactMethods.length === 1
                  ? contactMethods[0]
                  : `${contactMethods
                      .slice(0, -1)
                      .join(", ")}, and ${contactMethods.slice(-1)}`;

              const message = `${storeName} ${branchText} have been notified about their ${alertTypesText} via ${viaText}.`;
              const id = storeId.toString();

              return { id, message, created_at: Date.now() };
            }
          );

          return {
            ...resolveTasksState,
            messages: [...resolveTasksState.messages, ...messages],
          };
        }
      ),
    ],
    { messages: [] }
  ),
  resolveTasksAction
);
