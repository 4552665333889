import * as React from "react";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";

interface IProps {
  readOnly: boolean;
  label: string;
  type: string;
  className?: string;
  fullWidth: boolean;
}
export class RenderGenericField extends React.Component<
  WrappedFieldProps & IProps
> {
  public render() {
    const {
      input,
      label,
      type,
      readOnly,
      fullWidth,
      meta: { touched, error, warning },
    } = this.props;
    return (
      <div
        className={`${fullWidth ? styles.fullWidthInput : styles.inputWrapper}`}
      >
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
          {touched &&
            ((error && <span className={styles.formError}>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </label>
        <input
          autoComplete="off"
          className={styles.input}
          readOnly={readOnly}
          id={input.name}
          {...input}
          placeholder={label}
          type={type}
        />
      </div>
    );
  }
}
