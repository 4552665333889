import * as types from "../../types";
import * as constants from "../../constants";
import {
  IGetBrandConversionRateAction,
  IGetBrandDetailsAction,
  IExportFoodicsMenuAction,
  IGetBrandDetailsFailureAction,
  IGetBrandDetailsSuccessAction,
  IGetBrandRedemptionNumberAction,
  IGetBrandRedemptionRateAction,
  IGetBrandRegisteredCustomerNumberAction,
  IGetBrandReviewsNumberAction,
  IGetBrandTempCustomersNumberAction,
  IGetBrandUnresolvedIssuesAction,
  IGetBrandVisitsNumberAction,
  IStoreStatsData,
} from "../../types";
import { IStatsFilters } from "../../axios/types";
import { REVIEWS_EPISODE_VII_A_NEW_DESIGN } from "../../constants";

export const getBrandDetails = (store_id: string): IGetBrandDetailsAction => ({
  type: constants.getBrandDetailsAction.requested,
  payload: {
    store_id,
  },
});

export const getBrandDetailsSuccess = (
  data: IStoreStatsData[]
): IGetBrandDetailsSuccessAction => ({
  type: constants.getBrandDetailsAction.fulfilled,
  payload: data,
});

export const getBrandDetailsFailure = (
  error
): IGetBrandDetailsFailureAction => ({
  type: constants.getBrandDetailsAction.rejected,
  payload: error,
});

// export foodics menu actions
export const exportFoodicsMenu = (
  api_key: string
): IExportFoodicsMenuAction => ({
  type: constants.exportFoodicsMenuAction.requested,
  payload: {
    api_key,
  },
});

export const exportFoodicsMenuSuccess = (response: any): any => ({
  type: constants.exportFoodicsMenuAction.fulfilled,
  payload: response,
});

export const exportFoodicsMenuFailure = (error): any => ({
  type: constants.exportFoodicsMenuAction.rejected,
  payload: error,
});

export const getBrandFailedOperationsNumber = (
  store_id: string,
  filters: IStatsFilters
): types.IGetBrandFailedOperationsNumberAction => ({
  type: constants.getBrandFailedOperationsNumberAction.requested,
  payload: { store_id, filters },
});

export const getBrandFailedOperationsNumberSuccess = (
  data: number
): types.IGetBrandFailedOperationsNumberSuccessAction => ({
  type: constants.getBrandFailedOperationsNumberAction.fulfilled,
  payload: data,
});

export const getBrandFailedOperationsNumberFailure = (
  error
): types.IGetBrandFailedOperationsNumberFailureAction => ({
  type: constants.getBrandFailedOperationsNumberAction.rejected,
  payload: error,
});

export const getBrandRegisteredCustomerNumber = (
  store_id: string,
  filters: IStatsFilters
): IGetBrandRegisteredCustomerNumberAction => ({
  type: constants.getBrandRegisteredCustomerNumberAction.requested,
  payload: { store_id, filters },
});

export const getBrandRegisteredCustomerNumberSuccess = (
  data: number
): types.IGetBrandRegisteredCustomerNumberSuccessAction => ({
  type: constants.getBrandRegisteredCustomerNumberAction.fulfilled,
  payload: data,
});

export const getBrandRegisteredCustomerNumberFailure = (
  error
): types.IGetBrandRegisteredCustomerNumberFailureAction => ({
  type: constants.getBrandRegisteredCustomerNumberAction.rejected,
  payload: error,
});

export const getBrandReviewsNumber = (
  store_id: string,
  filters: IStatsFilters
): IGetBrandReviewsNumberAction => ({
  type: constants.getBrandReviewsNumberAction.requested,
  payload: { store_id, filters },
});

export const getBrandReviewsNumberSuccess = (
  data: number
): types.IGetBrandReviewsNumberSuccessAction => ({
  type: constants.getBrandReviewsNumberAction.fulfilled,
  payload: data,
});

export const getBrandReviewsNumberFailure = (
  error
): types.IGetBrandReviewsNumberFailureAction => ({
  type: constants.getBrandReviewsNumberAction.rejected,
  payload: error,
});

export const getBrandConversionRate = (
  store_id: string,
  filters: IStatsFilters
): IGetBrandConversionRateAction => ({
  type: constants.getBrandConversionRateAction.requested,
  payload: { store_id, filters },
});

export const getBrandConversionRateSuccess = (
  data: number
): types.IGetBrandConversionRateSuccessAction => ({
  type: constants.getBrandConversionRateAction.fulfilled,
  payload: data,
});

export const getBrandConversionRateFailure = (
  error
): types.IGetBrandConversionRateFailureAction => ({
  type: constants.getBrandConversionRateAction.rejected,
  payload: error,
});

export const getBrandRedemptionRate = (
  store_id: string,
  filters: IStatsFilters
): IGetBrandRedemptionRateAction => ({
  type: constants.getBrandRedemptionRateAction.requested,
  payload: { store_id, filters },
});

export const getBrandRedemptionRateSuccess = (
  data: number
): types.IGetBrandRedemptionRateSuccessAction => ({
  type: constants.getBrandRedemptionRateAction.fulfilled,
  payload: data,
});

export const getBrandRedemptionRateFailure = (
  error
): types.IGetBrandRedemptionRateFailureAction => ({
  type: constants.getBrandRedemptionRateAction.rejected,
  payload: error,
});

export const getBrandRedemptionNumber = (
  store_id: string,
  filters: IStatsFilters
): IGetBrandRedemptionNumberAction => ({
  type: constants.getBrandRedemptionNumberAction.requested,
  payload: { store_id, filters },
});

export const getBrandRedemptionNumberSuccess = (
  data: number
): types.IGetBrandRedemptionNumberSuccessAction => ({
  type: constants.getBrandRedemptionNumberAction.fulfilled,
  payload: data,
});

export const getBrandRedemptionNumberFailure = (
  error
): types.IGetBrandRedemptionNumberFailureAction => ({
  type: constants.getBrandRedemptionNumberAction.rejected,
  payload: error,
});

export const getBrandTempCustomersNumber = (
  store_id: string,
  filters: IStatsFilters
): IGetBrandTempCustomersNumberAction => ({
  type: constants.getBrandTempCustomersNumberAction.requested,
  payload: { store_id, filters },
});

export const getBrandTempCustomersNumberSuccess = (
  data: number
): types.IGetBrandTempCustomersNumberSuccessAction => ({
  type: constants.getBrandTempCustomersNumberAction.fulfilled,
  payload: data,
});

export const getBrandTempCustomersNumberFailure = (
  error
): types.IGetBrandTempCustomersNumberFailureAction => ({
  type: constants.getBrandTempCustomersNumberAction.rejected,
  payload: error,
});

export const getBrandVisitsNumber = (
  store_id: string,
  filters: IStatsFilters
): IGetBrandVisitsNumberAction => ({
  type: constants.getBrandVisitsNumberAction.requested,
  payload: { store_id, filters },
});

export const getBrandVisitsNumberSuccess = (
  data: number
): types.IGetBrandVisitsNumberSuccessAction => ({
  type: constants.getBrandVisitsNumberAction.fulfilled,
  payload: data,
});

export const getBrandVisitsNumberFailure = (
  error
): types.IGetBrandVisitsNumberFailureAction => ({
  type: constants.getBrandVisitsNumberAction.rejected,
  payload: error,
});

export const getUnresolvedIssuesNumber = (
  store_id: string,
  filters: IStatsFilters
): IGetBrandUnresolvedIssuesAction => ({
  type: constants.getBrandUnresolvedIssuesNumberAction.requested,
  payload: {
    store_id,
    filters: {
      ...filters,
      start_time: filters.start_time || REVIEWS_EPISODE_VII_A_NEW_DESIGN,
    },
  },
});

export const getUnresolvedIssuesNumberSuccess = (
  data: number
): types.IGetBrandUnresolvedIssuesSuccessAction => ({
  type: constants.getBrandUnresolvedIssuesNumberAction.fulfilled,
  payload: data,
});

export const getUnresolvedIssuesNumberFailure = (
  error
): types.IGetBrandUnresolvedIssuesFailureAction => ({
  type: constants.getBrandUnresolvedIssuesNumberAction.rejected,
  payload: error,
});
