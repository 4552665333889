import React from "react";
import styles from "./styles.module.sass";
import { SettingsSwitch } from "../../../../SharedComponent/settingsSwitch";
import { Controller } from "react-hook-form";
import { DefaultInput } from "../../../../FormElements/NoRedux/DefaultInput/index";
import {
  PromoMerchantVisibility,
  PromoTypes,
} from "../../../../TargetedTools/types";
import { useCurrency } from "../../hooks/useCurrency";
interface IProps {
  promocodeSplit: boolean;
  setPromocodeSplit: React.Dispatch<React.SetStateAction<boolean>>;
  applyCapping: boolean;
  setApplyCapping: React.Dispatch<React.SetStateAction<boolean>>;
  applyMinEligibleInvoice: boolean;
  setApplyMinEligibleInvoice: React.Dispatch<React.SetStateAction<boolean>>;
  setApplyMaxCashbackValue: React.Dispatch<React.SetStateAction<boolean>>;
  applyMaxCashbackValue: boolean;
  setApplyExpiresAfter: React.Dispatch<React.SetStateAction<boolean>>;
  applyExpiresAfter: boolean;
  watchers: any;
  control: any;
  setValue: any;
}
export const SplitAll: React.FC<IProps> = ({
  control,
  watchers,
  promocodeSplit,
  setPromocodeSplit,
  applyCapping,
  setApplyCapping,
  applyMinEligibleInvoice,
  setApplyMinEligibleInvoice,
  setApplyMaxCashbackValue,
  applyMaxCashbackValue,
  applyExpiresAfter,
  setApplyExpiresAfter,
  setValue
}) => {
  const currency = useCurrency(watchers.country_iso_code)
  return (
    <div className={styles.container}>
      <span className={styles.promocodeSplitTitle}>Promo code Split</span>
      <div
        style={!promocodeSplit ? { backgroundColor: "#F2F2F7" } : {}}
        className={styles.splitPromocodeWrapper}
      >
        <div className={styles.splitAllPromocodeSwitchWrapper}>
          <label
            htmlFor="apply-split-for-all"
            className={styles.splitAllPromocodeSwitcherTitle}
          >
            Apply Split for All
          </label>
          <SettingsSwitch
            className={styles.applySplitForAllToggle}
            checked={promocodeSplit}
            onChange={(checked) => {
              setPromocodeSplit(checked);
              setValue("splitMerchant", true)
              if (checked) {
                setValue("applySplitForAllParameterValue", {
                  store_fund_percentage: {
                    koinz: 100,
                    brand: 0,
                  },
                });
                if(watchers.promo_merchant_visibility === PromoMerchantVisibility.masked){
                  setValue("applySplitForAllParameterValue.masked_value", 0)
                }
              }
            }}
            id="apply-split-for-all"
          />
        </div>
        {promocodeSplit && (
          <Controller
            name={"applySplitForAllParameterValue"}
            rules={{
              required: true,
            }}
            render={({
              field: { onChange, value, ref, onBlur },
              fieldState: { invalid, error, isTouched },
            }) => (
              <div className={styles.main}>
                <div className={styles.wrapper}>
                  <DefaultInput
                    touched={isTouched}
                    handleClickWrapper={() => {}}
                    min={"0"}
                    max={"100"}
                    pattern="^[0-100]"
                    postFix="%"
                    type="number"
                    label={"Koinz percentage"}
                    placeholder={"$0.00"}
                    onBlur={() => {
                      setValue("splitMerchant", true)
                      onBlur()
                    }}
                    onFocus={() => {
                      setValue("splitMerchant", true)
                    }}
                    autoComplete="off"
                    onChange={(e) => {
                      onChange({
                        store_fund_percentage: {
                          koinz:
                            Number(e.target.value) <= 100
                              ? Number(e.target.value)
                              : 0,
                          brand:
                            Number(e.target.value) <= 100
                              ? 100 - Number(e.target.value)
                              : 100,
                        },
                      });
                    }}
                    name={`applySplitForAllParameterValue.store_fund_percentage.koinz`}
                    id={"applySplitForAllParameterValue"}
                    value={value?.store_fund_percentage?.koinz}
                  />
                  <div style={{ margin: "0 10px" }} />
                  <DefaultInput
                    touched={isTouched}
                    handleClickWrapper={() => {}}
                    min={"0"}
                    max={"100"}
                    pattern="^[0-100]"
                    postFix="%"
                    type="number"
                    label={"Brand percentage"}
                    placeholder={"$0.00"}
                    onBlur={() => {
                      setValue("splitMerchant", true)
                      onBlur()
                    }}
                    onFocus={() => {
                      setValue("splitMerchant", true)
                    }}                    autoComplete="off"
                    id={"applySplitForAllParameterValue"}
                    onChange={(e) => {
                      onChange({
                        store_fund_percentage: {
                          brand:
                            Number(e.target.value) <= 100
                              ? Number(e.target.value)
                              : 0,
                          koinz:
                            Number(e.target.value) <= 100
                              ? 100 - Number(e.target.value)
                              : 100,
                        },
                      });
                    }}
                    name={`applySplitForAllParameterValue.store_fund_percentage.brand`}
                    value={value?.store_fund_percentage?.brand}
                  />
                </div>
                {watchers.promo_merchant_visibility ===
                  PromoMerchantVisibility.masked && (
                  <div className={styles.maskedPromocodeWrapper}>
                    <span className={styles.maskedPromocodeTitle}>
                      Masked promo
                    </span>
                    <div className={styles.maskedPromocodeInputsWrapper}>
                      <DefaultInput
                        touched={isTouched}
                        handleClickWrapper={() => {}}
                        type="text"
                        id={"applySplitForAllParameterValue"}
                        label={"Masked promocode"}
                        placeholder={"Ex: Koinz-20"}
                        onBlur={() => {
                          setValue("splitMerchant", true)
                          onBlur()
                        }}
                        onFocus={() => {
                          setValue("splitMerchant", true)
                        }}                        autoComplete="off"
                        onChange={(e) => {
                          onChange({
                            ...watchers.applySplitForAllParameterValue,
                            masked_promo_code: e.target.value,
                          });
                        }}
                        name={`applySplitForAllParameterValue.masked_promo_code`}
                        value={value?.masked_promo_code}
                      />
                      <div style={{ margin: "0 10px" }} />
                      <DefaultInput
                        touched={isTouched}
                        handleClickWrapper={() => {}}
                        id={"applySplitForAllParameterValue"}
                        error={error || (invalid as any)}
                        min={"0"}
                        max={"100"}
                        pattern="^[0-100]"
                        postFix="%"
                        type="number"
                        label={"Brand share"}
                        onBlur={() => {
                          setValue("splitMerchant", true)
                          onBlur()
                        }}
                        onFocus={() => {
                          setValue("splitMerchant", true)
                        }}                        autoComplete="off"
                        onChange={(e) => {
                          onChange({
                            ...watchers.applySplitForAllParameterValue,
                            masked_value:
                              Number(e.target.value) <= 100
                                ? Number(e.target.value)
                                : 0,
                          });
                        }}
                        name={`applySplitForAllParameterValue.masked_value`}
                        value={value?.masked_value ?? 0}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            control={control}
          />
        )}
      </div>

      {(watchers.type === PromoTypes.percentage || watchers.type === PromoTypes.cashback_percentage) &&
        watchers.promo_merchant_visibility !==
          PromoMerchantVisibility.masked && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {
              watchers.type === PromoTypes.percentage && (
                <div
              style={!applyCapping ? { backgroundColor: "#F2F2F7" } : {}}
              className={styles.splitPromocodeWrapper}
            >
              <div className={styles.splitAllPromocodeSwitchWrapper}>
                <label
                  htmlFor="apply-capping-for-all"
                  className={styles.splitAllPromocodeSwitcherTitle}
                >
                  Apply Capping for All
                </label>
                <SettingsSwitch
                  className={styles.applySplitForAllToggle}
                  checked={applyCapping}
                  onChange={(checked) => {
                    setApplyCapping(checked);
                    setValue("splitCapping", true)
                    if(checked){
                      setValue("applyCappingForAllBrands.max_discount_value", 0);
                    }
                  }}
                  id="apply-capping-for-all"
                />
              </div>
              {applyCapping && (
                <Controller
                  control={control}
                  name={"applyCappingForAllBrands"}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, value, ref, onBlur },
                    fieldState: { invalid, error, isTouched },
                  }) => (
                    <DefaultInput
                      touched={isTouched}
                      handleClickWrapper={() => {}}
                      type="number"
                      min={"0"}
                      id={"applyCappingForAllBrands"}
                      label={"Capping"}
                      placeholder={"Ex. 50"}
                      postFix={currency}
                      onBlur={() => {
                        onBlur();
                        setValue("splitCapping", true)
                      }}
                      onFocus={() => {
                        setValue("splitCapping", true)

                      }}
                      autoComplete="off"
                      onChange={(e) => {
                        onChange({
                          max_discount_value: e.target.value,
                        });
                      }}
                      name={`applyCappingForAllBrands.max_discount_value`}
                      value={value?.max_discount_value ?? undefined}
                    />
                  )}
                />
              )}
            </div>
              )
            }
            <div
              style={
                !applyMinEligibleInvoice ? { backgroundColor: "#F2F2F7" } : {}
              }
              className={styles.splitPromocodeWrapper}
            >
              <div className={styles.splitAllPromocodeSwitchWrapper}>
                <label
                  htmlFor="apply-min-eligible-invoice-for-all"
                  className={styles.splitAllPromocodeSwitcherTitle}
                >
                  Apply Min eligible invoice for All
                </label>
                <SettingsSwitch
                  className={styles.applySplitForAllToggle}
                  checked={applyMinEligibleInvoice}
                  onChange={(checked) => {
                    setApplyMinEligibleInvoice(checked);
                    setValue("splitMinEligibleInvoice", true)
                    if(checked){
                      setValue("applyMinEligibleInvoiceForAllBrands.min_threshold", 0);
                    }
                  }}
                  id="apply-min-eligible-invoice-for-all"
                />
              </div>
              {applyMinEligibleInvoice && (
                <Controller
                  control={control}
                  name={"applyMinEligibleInvoiceForAllBrands"}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, value, ref, onBlur },
                    fieldState: { invalid, error, isTouched },
                  }) => (
                    <DefaultInput
                      touched={isTouched}
                      handleClickWrapper={() => {}}
                      type="number"
                      id={"applyMinEligibleInvoiceForAllBrands"}
                      label={"Min eligible invoice"}
                      placeholder={"Ex. 50"}
                      min={"0"}
                      postFix={currency}
                      onBlur={() => {
                        onBlur();
                        setValue("splitMinEligibleInvoice", true)
                      }}
                      onFocus={() => {
                        setValue("splitMinEligibleInvoice", true)
                      }}
                      autoComplete="off"
                      onChange={(e) => {
                        onChange({
                          min_threshold: e.target.value,
                        });
                      }}
                      name={`applyMinEligibleInvoiceForAllBrands.min_threshold`}
                      value={value?.min_threshold ?? 0}
                    />
                  )}
                />
              )}
            </div>
           {
            watchers.type === PromoTypes.cashback_percentage && (
              <div
              style={
                !applyMaxCashbackValue ? { backgroundColor: "#F2F2F7" } : {}
              }
              className={styles.splitPromocodeWrapper}
            >
              <div className={styles.splitAllPromocodeSwitchWrapper}>
                <label
                  htmlFor="apply-max-cashback-value-for-all"
                  className={styles.splitAllPromocodeSwitcherTitle}
                >
                  Apply Max cashback value for All
                </label>
                <SettingsSwitch
                  className={styles.applySplitForAllToggle}
                  checked={applyMaxCashbackValue}
                  onChange={(checked) => {
                    setApplyMaxCashbackValue(checked);
                    setValue("splitMaxCashback", true)
                    if(checked){
                      setValue("applyMaxCashbackValueForAllBrands.max_cashback_value", 0)
                    }
                  }}
                  id="apply-max-cashback-value-for-all"
                />
              </div>
              {applyMaxCashbackValue && (
                <Controller
                  control={control}
                  name={"applyMaxCashbackValueForAllBrands"}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, value, ref, onBlur },
                    fieldState: { invalid, error, isTouched },
                  }) => (
                    <DefaultInput
                      touched={isTouched}
                      handleClickWrapper={() => {}}
                      type="number"
                      id={"applyMaxCashbackValueForAllBrands"}
                      label={"Max cashback value"}
                      placeholder={"Ex. 50"}
                      min={"0"}
                      postFix={currency}
                      onBlur={() => {
                        onBlur();
                          setValue("splitMaxCashback", true)
                      }}
                      onFocus={() => {
                          setValue("splitMaxCashback", true)
                      }}
                      autoComplete="off"
                      onChange={(e) => {
                        onChange({
                          max_cashback_value: e.target.value,
                        });
                      }}
                      name={`applyMaxCashbackValueForAllBrands.max_cashback_value`}
                      value={value?.max_cashback_value ?? 0}
                    />
                  )}
                />
              )}
            </div>
            )
           }
           {
            watchers.type === PromoTypes.cashback_percentage && (
              <div
              style={
                !applyExpiresAfter ? { backgroundColor: "#F2F2F7" } : {}
              }
              className={styles.splitPromocodeWrapper}
            >
              <div className={styles.splitAllPromocodeSwitchWrapper}>
                <label
                  htmlFor="apply-expires-after-value-for-all"
                  className={styles.splitAllPromocodeSwitcherTitle}
                >
                  Apply Expired after for All
                </label>
                <SettingsSwitch
                  className={styles.applySplitForAllToggle}
                  checked={applyExpiresAfter}
                  onChange={(checked) => {
                    setApplyExpiresAfter(checked);
                    setValue("splitExpireAfter", true)
                    if(checked){
                      setValue("applyExpiresAfterValueForAllBrands.expires_after", 72)
                    }
                  }}
                  id="apply-expires-after-value-for-all"
                />
              </div>
              {applyExpiresAfter && (
                <Controller
                  control={control}
                  name={"applyExpiresAfterValueForAllBrands"}
                  rules={
                    {
                      required: {
                        value: true,
                        message: "Expired after is required",
                      },
                      min: {
                        value: 1,
                        message: "Expired after should be greater than or equal 1",
                      },
                    }
                  }
                  render={({
                    field: { onChange, value, ref, onBlur },
                    fieldState: { invalid, error, isTouched },
                  }) => (
                    <DefaultInput
                      touched={isTouched}
                      handleClickWrapper={() => {}}
                      type="number"
                      id={"applyExpiresAfterValueForAllBrands"}
                      label={"Expired After"}
                      placeholder={"Ex. 72"}
                      min={"0"}
                      postFix={"Hours"}
                      onBlur={() => {
                        onBlur();
                        setValue("splitExpireAfter", true)
                      }}
                      onFocus={() => {
                        setValue("splitExpireAfter", true)
                      }}
                      autoComplete="off"
                      onChange={(e) => {
                        onChange({
                          expires_after: e.target.value ? e.target.value : 0,
                        });
                      }}
                      name={`applyExpiresAfterValueForAllBrands.expires_after`}
                      value={value?.expires_after ?? 72}
                    />
                  )}
                />
              )}
            </div>
            )
           }
          </div>
        )}
    </div>
  );
};

export default SplitAll;
