import { on, reducer } from "ts-action";
import { IBannerCardBase, IGetBannerCardItem } from "../../types";
import {
  createBannerCard,
  createBannerCardSuccess,
  deleteBannerCard,
  getBannerCardsSuccess,
  reOrderBannerCard,
} from "../actions/bannerCardsActions";
import { convertToIds, mapById, updateItemOrdering } from "./lib";
import {
  ILoadingState,
  withLoadingReducer,
} from "../../../../redux-store/reducers/withLoadingState";
import { getBannerCardsAction } from "../actions/constants";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";

interface IState {
  cards: string[];
  cardsById: { [x: string]: IBannerCardBase };
}

export const bannerCardsReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(getBannerCardsSuccess, (state, { payload }) => ({
          ...state,
          cards: payload.sort((a, b) => a.order - b.order).map((c) => c.id),
          cardsById: mapById(payload),
        })),
        on(createBannerCard, (state, action) => {
          const id = action.meta.optimistic.id.toString();
          return {
            ...state,
            cards: [...state.cards, id],
            cardsById: {
              ...state.cardsById,
              [id]: action.payload,
            },
          };
        }),
        on(reOrderBannerCard, (state, action) => {
          const { payload } = action;
          const oldItemData = state.cardsById[payload.id] as IGetBannerCardItem;

          const data = updateItemOrdering(
            state.cards.map(
              (id) => state.cardsById[id]
            ) as IGetBannerCardItem[],
            { ...oldItemData, order: payload.order },
            oldItemData
          );
          return {
            ...state,
            cardsById: mapById(data),
            cards: convertToIds(data),
          };
        }),
        on(deleteBannerCard, (state, action) => {
          return {
            ...state,
            cards: state.cards.filter((item) => item !== action.payload.id),
          };
        }),
        on(createBannerCardSuccess, (state, { payload, meta }) => {
          const optId = meta.optimistic.id.toString();
          return {
            ...state,
            cardsById: {
              ...state.cardsById,
              [payload.id]: payload,
            },
            cards: state.cards.map((id) => (optId === id ? payload.id : id)),
          };
        }),
      ],
      { cards: [], cardsById: {} }
    ),
    getBannerCardsAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
