import {
  addBrandActionType,
  editBrandActionType,
  getBrandsAction /* IAddBrandSuccessAction,*/,
  IEditBrandSuccessAction,
  IFilterBrandsAction,
  IGetBrandsStatisticsSuccessAction,
  IGetBrandsSuccessAction,
  IResetFilterBrandsAction,
} from "../../types";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";
import { IStore } from "../../axios/getBrands";
import { ISelectItemData } from "../../Components/FormElements/RenderDropDownSelect";


export interface IBrandsReducerState {
  brands: any;
  allBrands: any;
  country: string;
  name: string;
  payment: string;
  group: string;
  statisticsLoading: boolean;
  statisticsLoaded: boolean;
  statisticsFailed: boolean;
  loading: boolean;
  loadMore: boolean;
  loaded: boolean;
  failed: boolean;
  nextGroupingCriteria: ISelectItemData;
  currentGroupingCriteria: ISelectItemData;
  page: number;
  addBrandSubmitted: boolean;
}

const brands = "brands";
const group = "group";
const nextGroupingCriteria = "nextGroupingCriteria";
const currentGroupingCriteria = "currentGroupingCriteria";
const ALL_BRANDS = "allBrands";
const COUNTRY = "country";
const LOAD_MORE = "loadMore";
const NAME = "name";
const PAYMENT = "payment";
const STATISTICS_LOADING = "statisticsLoading";
const STATISTICS_LOADED = "statisticsLoaded";
const STATISTICS_FAILED = "statisticsFailed";
const LOADING = "loading";
const LOADED = "loaded";
const FAILED = "failed";
const page = "page";
const addBrandSubmitted = "addBrandSubmitted";

const initialState: IBrandsReducerState = {
  [ALL_BRANDS]: [],
  [page]: 0,
  [group]: "all",
  [brands]: [],
  [nextGroupingCriteria]: { value: "all", label: "All groups" },
  [currentGroupingCriteria]: { value: "all", label: "All groups" },
  [COUNTRY]: "",
  [NAME]: "",
  [PAYMENT]: "",
  [LOADED]: false,
  [LOADING]: false,
  [FAILED]: false,
  [STATISTICS_FAILED]: false,
  [STATISTICS_LOADED]: false,
  [STATISTICS_LOADING]: false,
  [LOAD_MORE]: true,
  [addBrandSubmitted]: true,
};
const contains = (str: string = "", strToSearchIn: string = ""): boolean =>
  strToSearchIn.toLowerCase().includes(str.toLowerCase());

export const brandsReducer = (
  state = initialState,
  action:
    | IResetFilterBrandsAction
    | getBrandsAction
    | editBrandActionType
    | addBrandActionType
    | IGetBrandsStatisticsSuccessAction
): IBrandsReducerState => {
  switch (action.type) {
    case constants.addBrandAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(addBrandSubmitted, true);
      });
    }
    case constants.addBrandAction.rejected: {
      return mutateState(state, (map) => {
        map.set(addBrandSubmitted, false);
      });
    }
    case constants.ACTION_CLEAR_PREV_STATE: {
      return mutateState(state, (map) => {
        map.set(addBrandSubmitted, false);
      });
    }
    case constants.addBrandAction.requested: {
      return mutateState(state, (map) => {
        map.set(addBrandSubmitted, false);
      });
    }
    case constants.ACTION_ADD_PAGE: {
      return mutateState(state, (map) => {
        const lastPage = map.get(page) as number;
        map.set(page, lastPage + 1);
      });
    }
    case constants.ACTION_SUB_PAGE: {
      return mutateState(state, (map) => {
        const lastPage = map.get(page) as number;
        map.set(page, lastPage - 1);
      });
    }
    case constants.ACTION_RESET_PAGE: {
      return mutateState(state, (map) => {
        map.set(page, 0);
      });
    }
    case constants.getBrandsAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADED, false);
        map.set(LOADING, true);
        map.set(FAILED, false);
        map.set(STATISTICS_LOADED, false);
      });
    }
    case constants.getBrandsAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADED, false);
        map.set(LOADING, false);
        map.set(FAILED, true);
      });
    }
    case constants.ACTION_GET_BRANDS_STATISTICS_SUCCESS: {
      return mutateState(state, (map) => {
        const oldBrands = map.get(brands) as any;
        const statisticsBrands = oldBrands.map((groupData) => {
          return {
            grouping_criteria: groupData.grouping_criteria,
            stores: groupData.stores.map((store) => {
              const statisticsBrand = (action as IGetBrandsStatisticsSuccessAction).payload.filter(
                (stat) => {
                  return stat.id === store.id;
                }
              );
              return {
                ...store,
                ...statisticsBrand[0],
              };
            }),
          };
        });
        map.set(ALL_BRANDS, statisticsBrands);
        map.set(brands, statisticsBrands);
        map.set(STATISTICS_LOADING, false);
        map.set(STATISTICS_LOADED, true);
        map.set(STATISTICS_FAILED, false);
      });
    }
    case constants.getBrandsAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(ALL_BRANDS, (action as IGetBrandsSuccessAction).payload);
        map.set(brands, (action as IGetBrandsSuccessAction).payload);
        map.set(LOADED, true);
        map.set(LOADING, false);
        map.set(FAILED, false);
      });
    }
    case constants.ACTION_FILTER_BRANDS: {
      const filters = (action as IFilterBrandsAction).payload;
      return mutateState(state, (map) => {
        const allBrands = map.get(ALL_BRANDS) as any;
        const filteredBrands = allBrands.map((groupData) => {
          return {
            grouping_criteria: groupData.grouping_criteria,
            stores: groupData.stores.reduce((acc, brand) => {
              let willShowThisBrand = true;
              if (filters.name && brand.name) {
                willShowThisBrand =
                  willShowThisBrand && contains(filters.name, brand.name);
              }
              if (willShowThisBrand) {
                return [...acc, brand];
              }
              return acc;
            }, []),
          };
        });
        map.set(brands, filteredBrands);
        map.set(COUNTRY, filters.country);
        map.set(NAME, filters.name);
        map.set(PAYMENT, filters.payment);
      });
    }
    case constants.ACTION_RESET_FILTER_BRANDS: {
      return mutateState(state, (map) => {
        const allBrands = map.get(ALL_BRANDS);
        map.set(brands, allBrands);
        map.set(COUNTRY, "");
        map.set(NAME, "");
        map.set(PAYMENT, "");
      });
    }
    case constants.editBrandAction.fulfilled: {
      return mutateState(state, (map) => {
        const editedBrand = (action as IEditBrandSuccessAction).payload;
        const filteredBrands = map.get(brands) as IStore[];
        const updatedBrands = filteredBrands.map((brand) => {
          if (brand.id === editedBrand.id) {
            return { ...brand, ...editedBrand };
          }
          return brand;
        });
        map.set(brands, updatedBrands);
      });
    }
    case constants.ACTION_ENABLE_LOAD_MORE: {
      return mutateState(state, (map) => {
        map.set(LOAD_MORE, true);
      });
    }
    case constants.ACTION_DISABLE_LOAD_MORE: {
      return mutateState(state, (map) => {
        map.set(LOAD_MORE, false);
      });
    }
    default:
      return state;
  }
};
