import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  editBrandFailure,
  editBrandSuccess,
} from "../actions/editBrandActions";
import { editBrandAction } from "../../constants";
import { selectForm, selectToken } from "../selectors";
import { IFormState } from "../rootReducer";
import { compareObjects } from "../../../Utils/deepCompare";
import { notify } from "react-notify-toast";
import { difference } from "../../../Utils/getDifference";
import { editBrand } from "../../axios/editBrand";
import { setSubmitFailed, setSubmitSucceeded } from "redux-form";
import { editManager } from "../../axios/editManager";
import { IAddStoreInfo } from "../../axios/getBrands";

const trimAllProps = (obj: { [x: string]: string }) =>
  Object.keys(obj).reduce((acc, next) => {
    if (typeof obj[next] === "string") {
      return {
        ...acc,
        [next]: obj[next].trim(),
      };
    }
    return {
      ...acc,
      [next]: obj[next],
    };
  }, {});

// emails are not case sensitive, but our db is, so we gotta lower case it
export const lowerCaseEmailProp = (brandData: IAddStoreInfo) => {
  const modifiedData = { ...brandData };
  if (brandData.email) {
    modifiedData.email = modifiedData.email.toLowerCase();
  }
  return modifiedData;
};

function* editBrandSaga() {
  try {
    const formData: IFormState = yield select(selectForm);
    const oldStoreData = (formData.editBrand as any).initial;
    const editedStoreData = formData.editBrand.values!;
    editedStoreData.country_code =
      editedStoreData.country_iso_code.country_code;
    editedStoreData.country_iso_code =
      editedStoreData.country_iso_code.ISO_code;
    editedStoreData.email = editedStoreData.email.toLowerCase();
    const nothingChanged = compareObjects(oldStoreData, editedStoreData);
    if (nothingChanged) {
      notify.show("You have not made any changes", "warning");
      return;
    }
    const token = yield select(selectToken);
    const changedValues = difference(editedStoreData, oldStoreData) as any;
    changedValues.store_id = oldStoreData.id;
    changedValues.manager_id = oldStoreData.manager_id;
    yield call(
      editManager,
      token,
      trimAllProps(lowerCaseEmailProp(changedValues))
    );
    yield call(editBrand, token, trimAllProps(changedValues));
    yield put(
      editBrandSuccess({
        ...oldStoreData,
        ...trimAllProps(lowerCaseEmailProp(changedValues)),
      })
    );
    yield put(setSubmitSucceeded("editBrand"));
  } catch (e) {
    yield put(setSubmitFailed("editBrand"));
    if (e.response.status === 403) {
      notify.show("This email is already used.", "error");
    } else {
      notify.show("An error has occurred", "error");
    }
    yield put(editBrandFailure(e));
  }
}

export function* watchEditBrandSaga() {
  yield takeLatest(editBrandAction.requested, editBrandSaga);
}
