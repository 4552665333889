import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  deleteStoreTag,
  deleteStoreTagFailure,
  deleteStoreTagSuccess,
} from "../actions/tags-actions";
import { deleteStoreTagAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { deleteStoreTagAPI } from "../../axios/deleteStoreTag";
import { customNotify } from "../../../../UIComponents/customNotification/customNotification";

const actionType = union({ deleteStoreTag });

function* deleteStoreTagSaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  yield call(deleteStoreTagAPI, token, payload.id);
  return (
    payload &&
    customNotify.error(
      `[${payload.name_en}-${payload.name_ar}] Tag deleted successfully`
    )
  );
}

export function* watchDeleteStoreTagSaga() {
  yield takeLatest(
    deleteStoreTagAction.requested,
    handleOptimism(deleteStoreTagSaga, {
      success: deleteStoreTagSuccess,
      failure: deleteStoreTagFailure,
    })
  );
}
