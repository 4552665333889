import React, { useEffect } from "react";
import {
  AllowedPaymentMethod,
  PromoMerchantVisibility,
  PromoTypes,
} from "../../../../TargetedTools/types";
import Tabs from "../Tabs/index";
import Tab from "../Tab/index";

interface IProps {
  watchers: any;
  control: any;
  isEditing: boolean;
  isDuplicating: boolean;
  setValue: (
    name: string,
    value: any,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
  promoType: PromoTypes;
  isReactivating: boolean;
}
const PromocodeVisibility: React.FC<IProps> = ({
  watchers,
  isEditing,
  isDuplicating,
  setValue,
  isReactivating,
}) => {
  // setting promo merchant visibility and allowed payment method
  // hidden ->  invisible | masked
  // normal -> visible

  useEffect(() => {
    if (
      watchers.promo_merchant_visibility === PromoMerchantVisibility.visible
    ) {
      setValue("promo_merchant_visibility", PromoMerchantVisibility.visible);
      setValue("allowed_payment_method", AllowedPaymentMethod.all);
    } else {
      if (
        watchers.promo_merchant_visibility === PromoMerchantVisibility.masked
      ) {
        setValue("promo_merchant_visibility", PromoMerchantVisibility.masked);
      } else {
        setValue(
          "promo_merchant_visibility",
          PromoMerchantVisibility.invisible
        );
      }
      setValue("allowed_payment_method", AllowedPaymentMethod.online);
    }
  }, [watchers.promo_merchant_visibility]);

  const handleNormalPromo = () => {
    if (!isReactivating && !isEditing) {
      setValue("promo_merchant_visibility", PromoMerchantVisibility.visible);
      setValue("allowed_payment_method", AllowedPaymentMethod.online);
    }
  };
  const handleHiddenPromo = () => {
    if (!isReactivating && !isEditing) {
      setValue("promo_merchant_visibility", PromoMerchantVisibility.invisible);
      setValue("allowed_payment_method", AllowedPaymentMethod.all);
    }
  };
  return (
    <div style={{ padding: "0 24px" }}>
      <Tabs disabled={isReactivating || isEditing}>
        <Tab
          disabled={isReactivating || isEditing}
          onClick={handleNormalPromo}
          title="Normal"
          state={watchers.promo_merchant_visibility === "visible"}
        />
        <Tab
          disabled={isReactivating || isEditing}
          onClick={handleHiddenPromo}
          title="Hidden"
          state={["invisible", "masked"].includes(
            watchers.promo_merchant_visibility
          )}
        />
      </Tabs>
    </div>
  );
};

export default PromocodeVisibility;
