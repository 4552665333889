import React from "react";
import styles from "./styles.module.sass";
interface IProps {
  errors: any;
  register: any;
  getFieldState: any;
  className?: string;
  placeholder?: string;
  input: {
    name: string;
    type: string;
    [name: string]: any;
  };
}
const NewInputField: React.FC<IProps> = ({
  input,
  className,
  errors,
  register,
  getFieldState,
}) => {
  return (
    <input
      style={{
        ...(getFieldState(input.name).isTouched
          ? { borderColor: "#38c172" }
          : {}),
        ...(errors[input.name] ? { borderColor: "#db3030" } : {}),
      }}
      {...input}
      {...register(input.name)}
      placeholder={input.placeholder ?? ""}
      className={`${styles.input} ${className}`}
      onBlur={
        input.onBlur ? input.onBlur : (e) => register(input.name).onBlur(e)
      }
    />
  );
};

export default NewInputField;
