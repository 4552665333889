import { useState } from "react";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { Queriers } from "../../queries";

export const useGetActiveLocationsStats = () => {
  const { data, status } = Queriers.getActiveLocationsStats();
  const [activeLocations, setActiveLocations] = useState<
    Record<"eg" | "sa", number>
  >({
    eg: 0,
    sa: 0,
  });
  const countryData = useDeepCompareMemo(() => {
    return (data || [])?.reduce(
      (acc, next) => ({
        ...acc,
        [next.country]: next.active_branches,
      }),
      {
        eg: 0,
        sa: 0,
      }
    );
  }, [data]);
  useDeepCompareEffect(() => {
    if (status === "success") {
      setActiveLocations({
        eg: countryData.eg,
        sa: countryData.sa,
      });
    }
  }, [countryData]);
  return {
    activeLocations,
  };
};
