import { action, payload } from "ts-action";
import { getAccountManagerAction } from "./constants";
export interface IAccountManager {
  name: string;
  email: string;
  id: string;
  status: string;
}

export const getAccountManager = action(
  getAccountManagerAction.requested,
  payload<{
    data: {
      store_id: string;
    };
  }>()
);

export const getAccountManagerSuccess = action(
  getAccountManagerAction.fulfilled,
  payload<{
    store_id: string;
    account_manager: string;
  }>()
);
export const getAccountManagerFailure = action(
  getAccountManagerAction.rejected,
  payload<Error>()
);