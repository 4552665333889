import { call, put, select, takeLatest } from "redux-saga/effects";
import { getReachCampaignsAction } from "../../constants";
import { union } from "ts-action";
import {
  getReachCampaigns,
  getReachCampaignsFailure,
  getReachCampaignsSuccess,
  IReachCampaignsResponse,
} from "../actions/getReachCampaignsActions";
import { selectToken } from "../selectors";
import { getCampaigns } from "../../axios/get-campaigns";
import { AxiosResponse } from "axios";

const getReachCampaignsActionType = union({ getReachCampaigns });
type getReachCampaignsActionType = typeof getReachCampaignsActionType;
function* getReachCampaignsSaga(action: getReachCampaignsActionType) {
  try {
    const token = yield select(selectToken);
    const res: AxiosResponse<{
      campaigns: IReachCampaignsResponse[];
      total_campaigns: number;
    }> = yield call(getCampaigns, token, action.payload);
    yield put(getReachCampaignsSuccess(res.data));
  } catch (e) {
    yield put(getReachCampaignsFailure(e));
  }
}

export function* watchGetReachCampaignsSaga() {
  yield takeLatest(getReachCampaignsAction.requested, getReachCampaignsSaga);
}
