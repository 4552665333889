import { ContactMethodType } from "./../types/account_managers.types";
import { selector } from "recoil";
import { store } from "../redux-store/configureStore";
import { selectedContactMethodsAtom } from "../atoms";

const initialChannelErrorMessage = {
  [ContactMethodType.Whatsapp]: {
    channel: "Please select at least one channel to send the message",
  },
  [ContactMethodType.SMS]: {
    channel: "Please select at least one channel to send the message",
  },
  [ContactMethodType.Email]: {
    channel: "Please select at least one channel to send the message",
  },
};
export const validateTakeActions = selector({
  key: "validateTakeActions",
  get({ get }) {
    const contactTemplatesById = store.getState().getContactTemplatesReducer
      .contactTemplatesById;
    const { contact_methods_view, selected_contact_methods } = get(
      selectedContactMethodsAtom
    );
    const errors: {
      valid: boolean;
      messages?: { [x: string]: { channel?: string; template?: string } };
    } = { valid: false };
    if (
      typeof contact_methods_view === "undefined" ||
      typeof selected_contact_methods === "undefined"
    ) {
      errors.messages = initialChannelErrorMessage;
      errors.valid = false;
      return { ...errors };
    }

    for (const [id, methods] of Object.entries(selected_contact_methods)) {
      const hasSelectedMethod = Object.values(methods).includes(true);
      if (!hasSelectedMethod) {
        errors.messages = initialChannelErrorMessage;
      }
      for (const [key, value] of Object.entries(methods)) {
        const contactMethodView = contact_methods_view?.[id]?.[key];
        if (value && !contactMethodView?.message) {
          errors.valid = false;
          errors.messages = {
            [key]: {
              template: "*Required",
            },
          };
          continue;
        }
        if (value && contactMethodView?.message) {
          const parameterKeys =
            contactTemplatesById[Number(key)].parameters_keys;
          const missingParameterKeys = parameterKeys.filter(
            (parameterKey) => !contactMethodView.message.includes(parameterKey)
          );
          if (missingParameterKeys.length > 0) {
            const plural = missingParameterKeys.length > 1 ? "s" : "";
            errors.valid = false;
            errors.messages = {
              [key]: {
                template: `Please include the ${missingParameterKeys.join(
                  " and "
                )} parameter${plural} in the message`,
              },
            };
            continue;
          }
          errors.valid = true;
        }
      }
    }
    return { ...errors };
  },
});
