import axios from "./axios";
import * as Requests from "./requests";

export const PromoCodesAPI = (
  token,
  data,
  type: "discount" | "points-multiplier" | "cashback"
) => {
  return axios.post(Requests.PromoCodesURL(type), data, {
    headers: {
      "Content-Type": "multipart/form-data",
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
