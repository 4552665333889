import React from "react";
import IcoMoon from "react-icomoon";

import classes from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import { hot } from "react-hot-loader";
import CollapseNavItem from "../../../UIComponents/CollapseNavitem";

const navigationItems = () => (
  <ul className={classes.NavigationItems}>
    <NavigationItem link="/" exact>
      <div className={classes.bar}>
        <IcoMoon icon="price-tag" />
        <span className={classes.title}>Brands</span>
      </div>
    </NavigationItem>
    <NavigationItem link="/watch-tower" exact>
      <div className={classes.bar}>
        <IcoMoon icon="price-tag" />
        <span className={classes.title} style={{ fontSize: "1.6rem" }}>
          Watch tower
        </span>
      </div>
    </NavigationItem>
    <CollapseNavItem />

    <NavigationItem link="/shopx-settings" exact>
      <div className={classes.bar}>
        <IcoMoon icon="cogs" />
        <span className={classes.title}>Settings</span>
      </div>
    </NavigationItem>
    {/*<NavigationItem link="/history" exact>*/}
    {/*<div className={classes.bar}>*/}
    {/*<IcoMoon icon="stats-bars2" />*/}
    {/*<span>Analytics</span>*/}
    {/*</div>*/}
    {/*</NavigationItem>*/}
  </ul>
);

export default hot(module)(navigationItems);
