import * as constants from "../../constants";
interface ISendSmsMessageReducer {
  is_loading: boolean;
  err: string;
  is_success: boolean;
  successNumber: number;
  failedNumber: number;
}

const InitialState: ISendSmsMessageReducer = {
  is_loading: false,
  err: "",
  is_success: false,
  successNumber: 0,
  failedNumber: 0,
};

const SendSmsMessageReducer = (
  state = InitialState,
  action
): ISendSmsMessageReducer => {
  switch (action.type) {
    case constants.sendSmsMessageAction.requested:
      return {
        ...state,
        is_loading: true,
        is_success: false,
      };
    case constants.sendSmsMessageAction.fulfilled:
      return {
        ...state,
        is_loading: false,
        is_success: true,
        successNumber: action.payload.success,
        failedNumber: action.payload.failed,
      };
    case constants.sendSmsMessageAction.rejected:
      return {
        ...state,
        is_loading: false,
        is_success: false,
        err: action.payload,
      };
    default:
      return state;
  }
};
export default SendSmsMessageReducer;
