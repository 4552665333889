import React, { useState, useCallback } from "react";
import * as styles from "./styles.module.sass";
import PushNotificationHeader from "./PushNotificationHeader";
import { hot } from "react-hot-loader";
import PushNotificationTable from "./PushNotificationTable";
import { useSelect } from "../../../hooks/useSelect";
import InfiniteScroll from "react-infinite-scroll-component";
import { getPushNotifications } from "../redux-store/actions/pushNotification";
import { DotLoader } from "react-spinners";
import { useDispatch } from "react-redux";
const PushNotification: React.FC = () => {
  const [page, setPage] = useState(0);

  const handleNext = useCallback(() => {
    setPage((n) => n + 1);
  }, []);

  const { notifications, notificationsById, campaigns_count } = useSelect(
    (state) => state.pushNotificationsReducer
  );

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getPushNotifications({ page, per_page: 25 }));
  }, [page, campaigns_count]);

  // const toUTF16 = (codePoint) => {
  //   const TEN_BITS = parseInt('1111111111', 2);
  //   function u(codeUnit) {
  //     return '\\u'+codeUnit.toString(16).toUpperCase();
  //   }

  //   if (codePoint <= 0xFFFF) {
  //     return u(codePoint);
  //   }
  //   codePoint -= 0x10000;

  //   // Shift right to get to most significant 10 bits
  //   // tslint:disable-next-line: no-bitwise
  //   const leadSurrogate = 0xD800 + (codePoint >> 10);

  //   // Mask to get least significant 10 bits
  //   // tslint:disable-next-line: no-bitwise
  //   const tailSurrogate = 0xDC00 + (codePoint & TEN_BITS);

  //   return u(leadSurrogate) + u(tailSurrogate);
  // }

  // React.useEffect( () =>{
  //    // tslint:disable-next-line: no-console
  // console.log('Data we want are ======>' , notifications, notificationsById );
  // // tslint:disable-next-line: no-console
  // const decoded = punycode.ucs2.decode('👍');
  // // tslint:disable-next-line: no-console
  // console.log('Urgent =============> ', decoded , toUTF16(decoded));
  // } , [notifications])

  return (
    <div>
      <PushNotificationHeader length={campaigns_count} />
      <InfiniteScroll
        dataLength={notifications.length}
        next={handleNext}
        hasMore={campaigns_count > page * 25 ? true : false}
        loader={
          <div className={styles.loader}>
            <DotLoader color={"#f09440"} />
          </div>
        }
      >
        {notifications.map((notify, key) => (
          <PushNotificationTable
            key={key}
            notification={notificationsById[notify]}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default hot(module)(PushNotification);
