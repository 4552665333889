import { IBrandPosPaymentMethod } from "../../pages/PosIntegration/types";
import axios from "../axios";
import { genBrandPosPaymentMethodURL } from "../requests";
export const genBrandPosPaymentMethodApi = (
  token: string,
  payload: IBrandPosPaymentMethod
) => {
  return axios.post(
    genBrandPosPaymentMethodURL,
    {
      token: payload.ordering_access_token,
      pos_entity_id: payload.pos_entity_id,
      store_id: payload.store_id,
      pos_vendor: payload.pos_vendor,
    },
    {
      baseURL: process.env.POS_ENDPOINT,
      headers: {
        token,
      },
    }
  );
};
