import axios from "./axios";
import * as Requests from "./requests";

export const getBrandWizardToken = (token, store_id): any => {
  return axios.get(Requests.getBrandWizardToken(store_id), {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
