import { mutateState } from "../../helpers/mutate-state";

const LOADING = "loading";
const LOADED = "loaded";
const FAILED = "failed";

export interface ILoadingReducerGenerator {
  [LOADED]: boolean;
  [LOADING]: boolean;
  [FAILED]: boolean;
}

export function loadingReducerGenerator<S, A>(
  genericAction,
  reducer = (state, action) => state
) {
  const initialState: ILoadingReducerGenerator = {
    [LOADED]: false,
    [LOADING]: false,
    [FAILED]: false,
  };

  const loadingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
      case genericAction.requested: {
        return mutateState(state, (map) => {
          map.set(LOADED, false);
          map.set(LOADING, true);
          map.set(FAILED, false);
        });
      }
      case genericAction.rejected: {
        return mutateState(state, (map) => {
          map.set(LOADED, false);
          map.set(LOADING, false);
          map.set(FAILED, true);
        });
      }
      case genericAction.fulfilled: {
        return mutateState(state, (map) => {
          map.set(LOADED, true);
          map.set(LOADING, false);
          map.set(FAILED, false);
        });
      }
      default:
        return state;
    }
  };
  return (state: S, action: A) => {
    return reducer(loadingReducer(state as any, action), action);
  };
}
