import { action, payload } from "ts-action";
import {
  ACTION_SET_ACCOUNT_MANAGER,
  assignAccountManagerAction,
} from "./constants";
export interface IAccountManager {
  name: string;
  email: string;
  id: string;
  status: string;
}

export const assignAccountManager = action(
  assignAccountManagerAction.requested,
  payload<{
    data: {
      store_id: string;
      account_manager_id: string;
    };
  }>()
);
export const setAccountManager = action(
  ACTION_SET_ACCOUNT_MANAGER,
  payload<{
    data: {
      store_account_manager: string;
      prev_store_account_manager: string;
    };
  }>()
);
export const assignAccountManagerSuccess = action(
  assignAccountManagerAction.fulfilled,
  payload<any>()
);
export const assignAccountManagerFailure = action(
  assignAccountManagerAction.rejected,
  payload<Error>()
);