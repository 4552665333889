import { useMutation } from "react-query";
import { OrderingGMVTypes } from "../../atoms/merchantProfile";
import { editOrderingGMVTypeApi } from "../../axios/merchantProfile";
import { useSelect } from "../../hooks/useSelect";

export const editOrderingGMVType = (store_id) => {
  const token = useSelect((state) => state.authReducer.token);
  return useMutation(
    (gmv_type: OrderingGMVTypes) =>
      editOrderingGMVTypeApi(token, store_id, gmv_type),
    { retry: 2 }
  );
};
