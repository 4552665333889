import * as React from "react";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import { DirectedText } from "../DirectedText";
interface IProps {
  placeholder: string;
  disabled?: boolean;
  className: string;
}
export class RenderTextAreaGenericField extends React.Component<
  WrappedFieldProps & IProps & { label: string; type: string }
> {
  public render() {
    const {
      disabled,
      input,
      label,
      meta: { touched, error, valid, invalid, active },

      className,
      placeholder,
    } = this.props;

    const isInvalid = touched && invalid;
    const isValid = touched && valid;
    return (
      <div className={`${styles.textAreaWrapper} ${className}`}>
        <label htmlFor={input.name} className={styles.textAreaLabel}>
          <span>{label}</span>
        </label>
        <DirectedText
          // style={{ width: "100%" }}
          text={input.value}
          aria-disabled={disabled}
        >
          <textarea
            disabled={disabled}
            className={styles.textArea}
            autoComplete="off"
            style={{
              ...(active ? { borderColor: "#fdbb11" } : {}),
              ...(isValid ? { borderColor: "#38c172" } : {}),
              ...(isInvalid ? { borderColor: "#db3030" } : {}),
            }}
            id={input.name}
            {...input}
            placeholder={placeholder}
          />
        </DirectedText>
        {touched && error && <span className={styles.formError}>*{error}</span>}
      </div>
    );
  }
}
