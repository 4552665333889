import {
  IGetPaymentLogsAction,
  IGetPaymentLogsFailureAction,
  IGetPaymentLogsSuccessAction,
  IPaymentLogs,
  IResetPaymentLogsAction,
} from "../../types";
import {
  ACTION_RESET_PAYMENT_LOGS,
  getPaymentLogsAction,
} from "../../constants";

export const getPaymentLogs = (store_id: string): IGetPaymentLogsAction => ({
  type: getPaymentLogsAction.requested,
  payload: store_id,
});

export const getPaymentLogsSuccess = (
  logs: IPaymentLogs[]
): IGetPaymentLogsSuccessAction => ({
  type: getPaymentLogsAction.fulfilled,
  payload: logs,
});

export const getPaymentLogsFailure = (error): IGetPaymentLogsFailureAction => ({
  type: getPaymentLogsAction.rejected,
  payload: error,
});
export const resetPaymentLogs = (): IResetPaymentLogsAction => ({
  type: ACTION_RESET_PAYMENT_LOGS,
});
