import * as React from "react";
import {
  DragSource,
  DropTarget,
  ConnectDragSource,
  ConnectDropTarget,
  DropTargetMonitor,
  ConnectDragPreview,
  DropTargetSpec,
  DragSourceSpec,
  XYCoord,
} from "react-dnd";

const cardSource: DragSourceSpec<ICardProps, any> = {
  beginDrag(props: ICardProps) {
    return { id: props.id };
  },
};

const cardTarget: DropTargetSpec<ICardProps> = {
  hover(props: ICardProps, monitor: DropTargetMonitor) {
    // console.log(monitor.getItem(), props);

    const draggedId = monitor.getItem().id;

    if (draggedId !== props.id) {
      props.moveCard(draggedId, props.id);
    }
  },
  drop(props: ICardProps, monitor: DropTargetMonitor) {
    const draggedId = monitor.getItem().id;
    props.onDrop(draggedId, props.id);
  },
};

export interface ICardProps {
  id: any;
  moveCard: (draggedId: string, id: string) => void;
  onDrop: (draggedId: string, droppedId: string) => void;
  children: (
    isDragging: boolean,
    connectDragSource: ConnectDragSource,
    connectDragPreview: ConnectDragPreview,
    connectDropTarget: ConnectDropTarget,
    position: XYCoord | null
  ) => React.ReactChild;
}

interface ICardSourceCollectedProps {
  isDragging: boolean;
  position: XYCoord | null;
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
}

interface ICardTargetCollectedProps {
  connectDropTarget: ConnectDropTarget;
}

class Card extends React.Component<
  ICardProps & ICardSourceCollectedProps & ICardTargetCollectedProps
> {
  public render() {
    const {
      isDragging,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      position,
      children,
    } = this.props;

    return children(
      isDragging,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      position
    );
  }
}

export const constructDraggableContainer = (
  itemType: string,
  dropAbleItems?: string[]
) =>
  DropTarget<ICardProps, ICardTargetCollectedProps>(
    [itemType, ...(dropAbleItems || [])],
    cardTarget,
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
    })
  )(
    DragSource<ICardProps, ICardSourceCollectedProps>(
      itemType,
      cardSource,
      (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
        position: monitor.getClientOffset(),
      })
    )(Card)
  );
