import React, { useState } from "react";
import styles from "./styles.module.sass";
import AddIcon from "../../../../assets/ic_plus.svg";
import EditIcon from "../../../../assets/feather _ edit.svg";
import CashbackDetailsPopup from "../CashbackDetailsPopup";
import { useSelect } from "../../../../hooks/useSelect";
import { useDeepCompareMemo } from "use-deep-compare";
import moment from "moment";
const CashbackDetails: React.FC<{ store_id: string }> = ({ store_id }) => {
  const [openCashbackDetailsPopup, setOpenCashbackDetailsPopup] = useState(
    false
  );
  const handleOpenCashbackDetailsPopup = () =>
    setOpenCashbackDetailsPopup(true);
  const handleCloseCashbackDetailsPopup = () =>
    setOpenCashbackDetailsPopup(false);
  const {
    cashbackDetailsReducer: { cashback_details },
  } = useSelect((state) => state);
  const isEditing = useDeepCompareMemo(
    () => typeof cashback_details !== "undefined",
    [cashback_details]
  );

  const cashbackExpiration = useDeepCompareMemo(() => {
    return (
      <React.Fragment>
        <div className={styles.line} />
        <div className={styles.elementWrapper}>
          <span className={styles.elementTitle}>End date</span>
          <span className={styles.elementSubtitle}>
            {typeof cashback_details?.cashback_merchant_share_end_date !==
            "undefined"
              ? moment(
                  cashback_details?.cashback_merchant_share_end_date
                ).format("DD MMM yyyy")
              : "-"}
          </span>
        </div>
        <div className={styles.separator} />
        <div className={styles.elementWrapper}>
          <span className={styles.elementTitle}>Start date</span>
          <span className={styles.elementSubtitle}>
            {typeof cashback_details?.cashback_merchant_share_start_date !==
            "undefined"
              ? moment(
                  cashback_details?.cashback_merchant_share_start_date
                ).format("DD MMM yyyy")
              : "-"}
          </span>
        </div>
      </React.Fragment>
    );
  }, [
    cashback_details?.cashback_merchant_share_end_date,
    cashback_details?.cashback_merchant_share_start_date,
  ]);
  
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <span className={styles.title}>Cashback Details</span>
        <button
          className={isEditing ? styles.edit : styles.btn}
          onClick={handleOpenCashbackDetailsPopup}
        >
          <img src={isEditing ? EditIcon : AddIcon} className={styles.icon} />{" "}
          {isEditing ? null : "Add Cashback details"}
        </button>
      </div>
      {isEditing && (
        <div className={styles.contentWrapper}>
          <div className={styles.elementWrapper}>
            <span className={styles.elementTitle}>Koinz Share</span>
            <span className={styles.elementSubtitle}>
              {cashback_details?.cashback_koinz_share}%
            </span>
          </div>
          <div className={styles.separator} />
          <div className={styles.elementWrapper}>
            <span className={styles.elementTitle}>Merchant Share</span>
            <span className={styles.elementSubtitle}>
              {cashback_details?.cashback_merchant_share}%
            </span>
          </div>
          {cashbackExpiration}
          {cashback_details?.promo_share_max_cpo && (
            <React.Fragment>
              <div className={styles.line} />
              <div className={styles.elementWrapper}>
                <span className={styles.elementTitle}>Maximum CPO</span>
                <span className={styles.elementSubtitle}>
                  {cashback_details?.promo_share_max_cpo}%
                </span>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      <CashbackDetailsPopup
        store_id={store_id}
        onClose={handleCloseCashbackDetailsPopup}
        open={openCashbackDetailsPopup}
      />
    </div>
  );
};

export default CashbackDetails;