import {
  Field,
  reduxForm,
  InjectedFormProps,
  formValueSelector,
} from "redux-form";
import { Drawer } from "@material-ui/core";
import React, { useEffect } from "react";
import * as styles from "./styles.module.sass";
import { required } from "../../../../redux-store/validators";
import { ISelectItemData } from "../../../FormElements/RenderDropDownSelect";
import { DeviceType, SystemAnnouncementTargeting } from "../../types";
import { useDispatch } from "react-redux";
import {
  createAnnouncement,
  createAnnouncementReset,
} from "../../redux-store/actions/announcementsActions";
import { useSelect } from "../../../../hooks/useSelect";
import { RenderGenericFileUploadField } from "../../BannerCards/AddBannerCard/RenderGenericFileUploadField";
import { RenderMultipleRadioButtonsField } from "../../BannerCards/AddBannerCard/RenderMultipleRadioButtonsField";
import { CustomDropZoneField } from "../../BannerCards/AddBannerCard/CustomDropZoneField";
import {
  isScreenRequiresStoreId,
  RegularScreens,
  StoreScreens,
  TARGET_SCREENS,
} from "../../BannerCards/lib/mobile-app-screens";
import { SelectStoresByCountry } from "../../AddPromoCode/PromoCodeForm/SelectStoresByCountry";
import { getStoreMetaData, maxLength1 } from "../../BannerCards/AddBannerCard";
import { RenderDropDownSelect } from "../../../SharedComponent/RenderDropDownSelect";
import { RenderGenericField } from "../../BannerCards/AddBannerCard/RenderGenericField";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
import { ClipLoader } from "react-spinners";
interface IExtraProps {
  open: boolean;
  closeForm: () => void;
}

const VisibilityOptions: ISelectItemData<SystemAnnouncementTargeting>[] = [
  {
    value: SystemAnnouncementTargeting.public,
    label: "All users",
  },
  {
    value: SystemAnnouncementTargeting.private,
    label: "Selected users",
  },
];

const DeviceTypeOptions: ISelectItemData<DeviceType>[] = [
  {
    value: DeviceType.all,
    label: "All",
  },
  {
    value: DeviceType.ios,
    label: "iOS",
  },
  {
    value: DeviceType.android,
    label: "Android",
  },
];

const addAnnouncementStateSelector = formValueSelector("addAnnouncement");

type IProps = IExtraProps & InjectedFormProps;

const AddAnnouncement: React.FC<IProps> = ({
  open,
  closeForm,
  handleSubmit,
  invalid,
  destroy,
  form,
  change,
}) => {
  const dispatch = useDispatch();
  const { mutationStatus } = useSelect((state) => state.announcementsReducer);
  const submitCard = React.useCallback((values) => {
    const routing_meta_data = getStoreMetaData(values);
    dispatch(
      createAnnouncement({
        ...values,
        routing_meta_data,
      })
    );
  }, []);
  const handleClose = React.useCallback(() => {
    destroy();
    closeForm();
  }, [closeForm, destroy]);
  const { visibility, routing_value } = useSelect((state) =>
    addAnnouncementStateSelector(state, "visibility", "routing_value")
  );

  const {
    screen_tab_store_my_points,
    screen_tab_store_menu_items,
  } = StoreScreens;
  const { feed_tab, influencer_profile } = RegularScreens;
  const screensRequiresStoreID = {
    screen_tab_store_my_points,
    screen_tab_store_menu_items,
  };
  const socialScreens = {
    feed_tab,
    influencer_profile,
  };

  const handleTargetScreenChange = (value) => {
    change("reel_id", "");
    change("influencer_profile_id", "");
    if (value in socialScreens) {
      change("ordering_promotion_id", "");
    }
    if (!(value in screensRequiresStoreID)) {
      change("stores_ids", undefined);
      change("country_iso_code", "");
    }
  };

  useEffect(() => {
    if (mutationStatus === LoadingStatus.success) {
      handleClose();
      dispatch(createAnnouncementReset());
    }
    if (mutationStatus === LoadingStatus.failed) {
      dispatch(createAnnouncementReset());
    }
  }, [mutationStatus]);

  return (
    <Drawer anchor={"right"} open={open}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Add popup announcement
          <span className={"icon-close"} onClick={handleClose} />
        </div>
        <Field
          name="image"
          wrapperClassName={styles.imageUploadWrapper}
          imagePreviewClassName={styles.imagePreviewWrapper}
          dropZoneClassName={styles.dropZoneWrapper}
          imageSize={"1200px * 312px"}
          component={CustomDropZoneField}
          validate={[required]}
        />
        <Field
          validate={[required]}
          name={"routing_value"}
          label={"Target screen"}
          options={TARGET_SCREENS}
          onChange={handleTargetScreenChange}
          className={true}
          component={RenderDropDownSelect as any}
        />

        {isScreenRequiresStoreId(routing_value) && (
          <SelectStoresByCountry
            validateCountry={[required]}
            validateStores={[required, maxLength1]}
            onlyOne={true}
            formName={form}
          />
        )}

        {routing_value === RegularScreens.feed_tab && (
          <Field
            component={RenderGenericField}
            optional={true}
            label={"Reel ID"}
            name={"reel_id"}
          />
        )}

        {routing_value === RegularScreens.influencer_profile && (
          <Field
            component={RenderGenericField}
            label={"Influencer profile ID"}
            name={"influencer_profile_id"}
            validate={[required]}
          />
        )}
        {!["influencer_profile", "feed_tab"].includes(routing_value) && (
          <Field
            component={RenderGenericField}
            optional={true}
            label={"Target promo code ID"}
            name={"ordering_promotion_id"}
          />
        )}

        <Field
          validate={[required]}
          label={"Target users"}
          component={RenderMultipleRadioButtonsField}
          options={VisibilityOptions}
          name="visibility"
        />
        {visibility === SystemAnnouncementTargeting.private && (
          <Field
            validate={[required]}
            buttonText={"Upload CSV file"}
            label={"CSV upload"}
            component={RenderGenericFileUploadField}
            name={"customers_file"}
          />
        )}

        <Field
          validate={[required]}
          label={"Device type"}
          name={"device_type"}
          options={DeviceTypeOptions}
          component={RenderMultipleRadioButtonsField}
        />

        <button
          disabled={invalid}
          onClick={
            mutationStatus === LoadingStatus.loading
              ? () => {}
              : handleSubmit(submitCard)
          }
          className={styles.submit}
        >
          {mutationStatus === LoadingStatus.loading ? (
            <ClipLoader size={20} color={"#fff"} />
          ) : (
            "Add popup"
          )}
        </button>
      </div>
    </Drawer>
  );
};

export default reduxForm<{}, IExtraProps>({
  form: "addAnnouncement",
})(AddAnnouncement as React.FC<IExtraProps>);