import { IGetBrandWizardInfoSuccessAction, IStep } from "../../types";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";

const CURRENT_STEP = "currentStep";
const WIZARD_TOKEN = "wizardToken";
const LOADING = "loading";
const LOADED = "loaded";
const FAILED = "failed";

export interface IBrandWizardInfoReducerState {
  [CURRENT_STEP]: IStep | undefined;
  [LOADED]: boolean;
  [LOADING]: boolean;
  [FAILED]: boolean;
  [WIZARD_TOKEN]: string;
}

const initialState: IBrandWizardInfoReducerState = {
  [CURRENT_STEP]: undefined,
  [LOADED]: false,
  [LOADING]: false,
  [FAILED]: false,
  [WIZARD_TOKEN]: "",
};

export const brandWizardInfoReducer = (
  state = initialState,
  action: IGetBrandWizardInfoSuccessAction
): IBrandWizardInfoReducerState => {
  switch (action.type) {
    case constants.getBrandWizardInfoAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADED, false);
        map.set(LOADING, true);
        map.set(FAILED, false);
      });
    }
    case constants.getBrandWizardInfoAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADED, false);
        map.set(LOADING, false);
        map.set(FAILED, true);
      });
    }
    case constants.getBrandWizardInfoAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(
          CURRENT_STEP,
          (action as IGetBrandWizardInfoSuccessAction).payload.currentStep
        );
        map.set(
          WIZARD_TOKEN,
          (action as IGetBrandWizardInfoSuccessAction).payload.wizardToken
        );
        map.set(LOADED, true);
        map.set(LOADING, false);
        map.set(FAILED, false);
      });
    }
    default:
      return state;
  }
};
