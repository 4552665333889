
import * as yup from "yup";

export const SearchSchema = yup.object().shape({
  search: yup
    .string()
    .optional()
    .nullable()
    .test("is_optional", "this field is optional", (value, context) => {
      return true;
    }),
});