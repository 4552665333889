import React, { useEffect } from "react";
import styles from "./styles.module.sass";
import { useRecoilState } from "recoil";
import { filtersAtom } from "../../../../atoms";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetMerchants } from "../../../../hooks";
import { useDeepCompareEffect } from "use-deep-compare";
import { useSelect } from "../../../../hooks/useSelect";
import { MerchantsTableBody, MerchantsTableHeader } from "../index";
import { ClipLoader } from "react-spinners";

export const Merchants: React.FC = () => {
  const [, setFilters] = useRecoilState(filtersAtom);
  const addBrandSubmitted = useSelect(
    (state) => state.brandsReducer.addBrandSubmitted
  );
  useDeepCompareEffect(() => {
    if (addBrandSubmitted) {
      setFilters((prev) => ({
        params: {
          ...prev.params,
        },
        queryEnabled: true,
      }));
    }
  }, [addBrandSubmitted]);
  useEffect(() => {
    setFilters((prev) => ({
      params: {
        page: 0,
        activity: undefined,
        country: undefined,
        merchant_status: undefined,
        payment_status: undefined,
        search: undefined,
      },
      queryEnabled: true,
    }));
  }, []);
  const handleNext = () => {
    setFilters((prev) => ({
      params: {
        ...prev.params,
        page: prev.params.page + 1,
      },
      queryEnabled: true,
    }));
  };
  const {
    merchantsIds,
    merchantById,
    hasMore,
    status,
    total_stores_count,
  } = useGetMerchants();

  return (
    <div className={styles.cardsWrapper}>
      <MerchantsTableHeader brandsCount={total_stores_count} />
      <InfiniteScroll
        next={handleNext}
        dataLength={merchantsIds.length}
        hasMore={hasMore}
        loader={
          <div
            style={{
              width: "100%",
              padding: "24px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <ClipLoader color=" #f09440" size={50} />
          </div>
        }
        style={{}}
      >
        <div className={styles.cardsWrapper}>
          {!merchantsIds.length && status === "success" ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "350px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
              }}
            >
              {" "}
              <img
                style={{
                  width: "82px",
                  height: "81.9px",
                  marginBottom: "12.1px",
                }}
                src={require("../../../../assets/alert.svg")}
              />
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#1c1c1c",
                }}
              >
                Brands not found
              </span>
            </div>
          ) : !merchantsIds.length && status === "error" ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "350px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
              }}
            >
              {" "}
              <img
                style={{
                  width: "82px",
                  height: "81.9px",
                  marginBottom: "12.1px",
                }}
                src={require("../../../../assets/alert.svg")}
              />
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#1c1c1c",
                }}
              >
                Something went wrong!
              </span>
            </div>
          ) : (
            <React.Fragment>
              {merchantsIds.map((merchantId, index) => (
                <MerchantsTableBody
                  key={index}
                  merchant={merchantById[merchantId]}
                />
              ))}
            </React.Fragment>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default Merchants;
