import { adminsByRoleURL } from "./requests";
import axios from "./axios";

export const getAdminByRoleApi = (token: string, role: string) => {
  return axios.get(adminsByRoleURL, {
    headers: {
      token,
    },
    params: {
      role,
    },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
