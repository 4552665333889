import React from "react";
import { WrappedFieldProps } from "redux-form";
import { FieldUIWrapper } from "../RenderMultipleRadioButtonsField";
import * as styles from "./styles.module.sass";
export const defaultCSVExample = require("../../../../../../public/exmples/text.csv")
  .default;
const DownloadExample: React.FC = () => {
  return (
    <a className={styles.downloadTemplate} href={defaultCSVExample}>
      Download CSV template
    </a>
  );
};

interface IProps extends WrappedFieldProps {
  label: string;
  buttonText: string;
  disabled?: boolean;
}

export const RenderGenericFileUploadField: React.FC<IProps> = ({
  meta,
  input,
  label,
  buttonText,
  disabled,
}) => {
  const isInvalid = meta.touched && meta.invalid;
  const handleChangeFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length) {
        input.onChange(e.target.files[0]);
      }
    },
    []
  );

  return (
    <FieldUIWrapper label={label} isInvalid={isInvalid} error={meta.error}>
      <div className={styles.wrapper}>
        <label
          htmlFor={input.name}
          className={disabled ? styles.dimmedButton : styles.uploadButton}
        >
          {buttonText}
          <input
            onChange={handleChangeFile}
            type={"file"}
            disabled={disabled}
            id={input.name}
            hidden={true}
            accept={".csv"}
          />
        </label>
        <DownloadExample />
      </div>
      <span className={styles.fileName}>
        {input.value instanceof File && input.value.name}
      </span>
    </FieldUIWrapper>
  );
};
