import { takeLatest, retry, put, select } from "redux-saga/effects";
import {
  getBannerCards,
  getBannerCardsFailure,
  getBannerCardsSuccess,
} from "../actions/bannerCardsActions";
import { selectToken } from "../../../../redux-store/selectors";
import { getBannerCardsAPI } from "../../axios/getBannerCards";

function* getBannerCardsSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield retry(5, 5000, getBannerCardsAPI, token);
    yield put(getBannerCardsSuccess(res.data));
  } catch {
    yield put(getBannerCardsFailure());
  }
}
export function* watchGetBannerCardsSaga() {
  yield takeLatest(getBannerCards.type, getBannerCardsSaga);
}
