export const selectPromocodeWatchers = (watch) => {
  const isExclusivePromocode = watch("is_exclusive");
  const promo_merchant_visibility = watch("promo_merchant_visibility");
  const suggested = watch("suggested");
  const promo_code = watch("promo_code");
  const applyCappingForAllBrands = watch("applyCappingForAllBrands");
  const applyMinEligibleInvoiceForAllBrands = watch(
    "applyMinEligibleInvoiceForAllBrands"
  );
  const stores_ids = watch("stores_ids");
  const target_customers = watch("target_customers");
  const targeting_method = watch("targeting_method");
  const expiry_days = watch("expiry_days");
  const start_time = watch("start_time");
  const end_time = watch("end_time");
  const rank = watch("rank");
  const type = watch("type");
  const applySplitForAllParameterValue = watch(
    "applySplitForAllParameterValue"
  );
  const ordering_type = watch("ordering_type");
  const stores = watch("stores");
  const stores_fund_percentage = watch("stores_fund_percentage");
  const masked_values = watch("masked_values");
  const masked_promo_codes = watch("masked_promo_codes");
  const country_iso_code = watch("country_iso_code");
  const csv_values = watch("csv_values");
  const max_discount_values = watch("max_discount_values");
  const max_discount_value = watch("max_discount_value");
  const min_threshold_values = watch("min_threshold_values");
  const discount_value = watch("discount_value");
  const min_threshold = watch("min_threshold");
  const cashback_value = watch("cashback_value");
  const max_cashback_value = watch("max_cashback_value");
  const max_cashback_values = watch("max_cashback_values");
  const applyMaxCashbackValueForAllBrands = watch(
    "applyMaxCashbackValueForAllBrands"
  );
  const applyExpiresAfterValueForAllBrands = watch(
    "applyExpiresAfterValueForAllBrands"
  );
  const expires_after_values = watch("expires_after_values");
  const splitMerchant = watch("splitMerchant");
  const splitCapping = watch("splitCapping");
  const splitMinEligibleInvoice = watch("splitMinEligibleInvoice");
  const splitMaxCashback = watch("splitMaxCashback");
  const splitExpireAfter = watch("splitExpireAfter");
  return {
    splitExpireAfter,
    splitMaxCashback,
    splitMinEligibleInvoice,
    splitMerchant,
    splitCapping,
    expires_after_values,
    applyExpiresAfterValueForAllBrands,
    max_cashback_value,
    cashback_value,
    discount_value,
    promo_code,
    promo_merchant_visibility,
    suggested,
    stores_ids,
    target_customers,
    targeting_method,
    expiry_days,
    start_time,
    end_time,
    rank,
    applySplitForAllParameterValue,
    stores_fund_percentage,
    stores,
    masked_values,
    masked_promo_codes,
    type,
    ordering_type,
    country_iso_code,
    csv_values,
    applyCappingForAllBrands,
    max_discount_values,
    max_discount_value,
    min_threshold_values,
    applyMinEligibleInvoiceForAllBrands,
    min_threshold,
    max_cashback_values,
    applyMaxCashbackValueForAllBrands,
    isExclusivePromocode:
      typeof isExclusivePromocode !== "undefined"
        ? Number(isExclusivePromocode)
          ? 1
          : 0
        : 0,
  };
};