import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as styles from "./styles.module.sass";
import { Field, Form, reduxForm, InjectedFormProps } from "redux-form";

import { GenericButtonWithoutIcon } from "../../../SharedComponent/GenericButtonWithoutIcon";
// import { useDispatch } from "react-redux";
import { required } from "../../../../redux-store/validators";
import { RenderGenericField } from "../../../SharedComponent/RenderGenericField/RenderGenericField";
import { CustomDropZoneField } from "../../BannerCards/AddBannerCard/CustomDropZoneField";
import {
  createCategory,
  editCategory,
} from "../../redux-store/actions/categoriesAction";
import { useDispatch } from "react-redux";
import { hot } from "react-hot-loader";

export interface IProps {
  is_create: boolean;
  name_ar?: string;
  name_en?: string;
  active_image?: File;
  inactive_image?: File;
  id?: string;
  open: boolean;
  onClose: () => void;
}

const CreateCategory: React.FC<IProps & InjectedFormProps> = (props) => {
  const { reset, is_create, id, open, onClose } = props;
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (is_create) {
      dispatch(createCategory(values));
    } else {
      dispatch(editCategory({ ...values, id }));
    }
    onClose();
    reset();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Form
            autoComplete={"off"}
            onSubmit={props.handleSubmit(handleSubmit)}
            className={styles.main}
          >
            <div className={styles.paper}>
              <h2>Add New Category</h2>
              <div>
                <div className={styles.menuName}>
                  <Field
                    name="name_en"
                    type="text"
                    placeholder={"Ex. Breakfast"}
                    component={RenderGenericField}
                    label={"Category name"}
                    validate={[required]}
                  />
                </div>
                <div className={styles.menuName}>
                  <Field
                    name="name_ar"
                    type={"text"}
                    placeholder={"الفطار"}
                    validate={[required]}
                    component={RenderGenericField}
                    label={"Category name in arabic"}
                  />
                </div>
                <span className={styles.label}>Category On icon</span>
                <Field
                  name="active_image"
                  wrapperClassName={styles.imageUploadWrapper}
                  imagePreviewClassName={styles.imagePreviewWrapper}
                  dropZoneClassName={styles.dropZoneWrapper}
                  imageSize={"1200px * 312px"}
                  component={CustomDropZoneField}
                  customBaseUrl={process.env.WIZARD_LUMEN_ENDPOINT}
                />
                <span className={styles.label}>Category Off icon</span>
                <Field
                  name="inactive_image"
                  wrapperClassName={styles.imageUploadWrapper}
                  imagePreviewClassName={styles.imagePreviewWrapper}
                  dropZoneClassName={styles.dropZoneWrapper}
                  imageSize={"1200px * 312px"}
                  component={CustomDropZoneField}
                  customBaseUrl={process.env.WIZARD_LUMEN_ENDPOINT}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <GenericButtonWithoutIcon
                label={"Cancel"}
                onClick={onClose}
                className={styles.Cancel}
              />
              <GenericButtonWithoutIcon
                type="submit"
                label={is_create ? "Create Category" : "Save Changes"}
                className={styles.Create}
              />
            </div>
          </Form>
        </Fade>
      </Modal>
    </div>
  );
};

export default reduxForm<{}, IProps>({
  form: "CreateCategoryForm",
})(hot(module)(CreateCategory as any));
