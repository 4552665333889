import React from "react";
import * as styles from "./styles.module.sass";
import { Aux } from "../../../App";
import { Button, Collapse } from "reactstrap";
import ConfirmSelections from "../../../UIComponents/DeleteRequest";
import { CampaignData } from "./campaignData";
import { countries } from "country-data";
import { IReachCampaignsResponse } from "../../../redux-store/actions/getReachCampaignsActions";
import * as moment from "moment";

type genericFn = (...args: any[]) => any;

export const stopPropagationWrapper = (fn: genericFn) => (
  e: React.MouseEvent
) => {
  e.stopPropagation();
  return fn();
};

interface IProps {
  campaign: IReachCampaignsResponse;
  readonly onReject: (id: string) => Promise<any>;
  readonly onAccept: (id: string) => Promise<any>;
}

interface IState {
  dataIsOpen: boolean;
}

export class CampaignRequest extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataIsOpen: false,
    };
  }

  public toggleCollapse = () => {
    this.setState({
      dataIsOpen: !this.state.dataIsOpen,
    });
  };
  public deleteRequest = () => this.props.onReject(this.props.campaign.id);
  public acceptRequest = () => this.props.onAccept(this.props.campaign.id);
  public renderToggleButtons = (toggleCollapse) => {
    const toggleCollapseDelete = stopPropagationWrapper(() =>
      toggleCollapse(this.deleteRequest)
    );
    const toggleCollapseAccept = stopPropagationWrapper(() =>
      toggleCollapse(this.acceptRequest)
    );
    return (
      <Aux>
        <span onClick={toggleCollapseDelete} className={styles.deleteBtn}>
          Reject
        </span>
        <Button onClick={toggleCollapseAccept} className={styles.btn}>
          Accept
        </Button>
      </Aux>
    );
  };

  public renderStatusActions = () => {
    const renderStatus = (text: string, color: string) => (
      <div
        style={{
          fontSize: "1.8rem",
          display: "flex",
          justifyContent: "center",
          width: "20%",
          color,
        }}
      >
        {text}
      </div>
    );
    const {
      campaign: { status },
    } = this.props;
    switch (status) {
      case 0:
      case 1:
        return (
          <div style={{ width: "20%" }}>
            <ConfirmSelections renderButtons={this.renderToggleButtons} />
          </div>
        );
      case 2:
        return renderStatus("Rejected", "red");
      case 3:
        return renderStatus("Scheduled", "orange");
      case 4:
        return renderStatus("Running", "orange");
      case 5:
        return renderStatus("Completed", "green");
      default:
        return null;
    }
  };

  public render() {
    const currency =
      countries[
        (this.props.campaign.store.country_iso_code || "EG").toUpperCase()
      ].currencies[0];
    const {
      campaign: {
        launch_timestamp,
        store,
        sms_number,
        manager,
        criteria,
        sms_content,
        total_users_count,
        created_at,
        id,
      },
    } = this.props;
    return (
      <Aux>
        <div className={styles.wrapper} onClick={this.toggleCollapse}>
          <div className={styles.wrapper2}>
            <div className={styles.date}>
              {moment(created_at, "x").format("MMM DD, hh:mm")}
            </div>
            <div className={styles.user}>
              <img
                src={process.env.ENDPOINT + store.store_image}
                className={styles.userImg}
              />
              <span className={styles.userName}>{store.name}</span>
            </div>
            {manager.phoneNumber ? (
              <div className={styles.phone}>
                <img
                  className={styles.logo}
                  src={require("../../../assets/phone.svg")}
                />
                {manager.phoneNumber}
              </div>
            ) : (
              <div className={styles.phone} />
            )}
            <div className={styles.date}>
              <img
                className={styles.logo}
                src={require("../../../assets/icon-time.svg")}
              />
              {moment(launch_timestamp, "x").format("MMM DD, hh:mm a")}
            </div>
            <div className={styles.userCount}>
              {total_users_count || 0} users
            </div>
            <div className={styles.smsCount}>{sms_number} sms</div>
          </div>
          {this.renderStatusActions()}
          <span
            className={
              this.state.dataIsOpen ? styles.openArrow : styles.closeArrow
            }
          />
        </div>
        <Collapse className={styles.body} isOpen={this.state.dataIsOpen}>
          <CampaignData
            campaignId={id}
            smsContent={sms_content}
            criteria={JSON.parse(criteria)}
            currency={currency}
          />
        </Collapse>
      </Aux>
    );
  }
}
