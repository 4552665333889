import axios from "./axios";
import * as Requests from "./requests";

export interface IPushNotification {
  push_campaigns: IPushCampaign[];
}
export enum PushNotificationStatus {
  pending_for_creation = 0,
  scheduled = 1,
  running = 2,
  success = 3,
  complete = 4,
  failure = 5,
}

export interface IPushCampaign {
  title: string; // Done
  total_users_count: number; // Done
  created_users_count: number; // Done
  status: PushNotificationStatus; // Done
  created_at: number; // Done
  scheduled_at: number; // Done
  sent_users_count: number; // Done
  id: string; // Done
  message: string; // Done
  image: string;
}
export const getPushNotificationAPI = (
  token: string,
  page: number,
  per_page: number
) => {
  return axios.get(Requests.getPushNotificationURL, {
    params: {
      page,
      per_page,
    },
    headers: {
      token,
    },
    baseURL: process.env.NOTIFICATIONS_ENDPOINT,
  });
};
