import axios from "./axios";
import * as Requests from "./requests";
import { AxiosReturn } from "./types";
import { IAddWatchers } from "../types";

export const addWatchers = (token, data): AxiosReturn<IAddWatchers> => {
  return axios.post(Requests.addWatchers, data, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
