import { call, put, select, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { getAccountManagerApi } from "../../../../axios/getAccountManagerApi";
import { selectToken } from "../../../../redux-store/selectors";
import {
  getAccountManager,
  getAccountManagerFailure,
  getAccountManagerSuccess,
} from "../actions/getAccountManager";
import { getAccountManagerAction } from "../actions/constants";

const actionTypes = union({ getAccountManager });
function* getAccountManagerSaga({ payload }: typeof actionTypes) {
  try {
    const token: string = yield select(selectToken);
    const res = yield call(getAccountManagerApi, token, payload.data);
    yield put(
      getAccountManagerSuccess({
        store_id: payload.data.store_id,
        account_manager: res.data.account_manager?.id,
      })
    );
  } catch (error) {
    yield put(getAccountManagerFailure(error));
  }
}

export function* watchGetAccountManagerSaga() {
  yield takeLatest(getAccountManagerAction.requested, getAccountManagerSaga);
}
