import {
  getPromotedBrandsTitleAction,
  editPromotedBrandsTitleAction,
} from "../../constants";

export interface IPromotedBrandsSectionTitle {
  en_title: string;
  ar_title: string;
}

import { action, payload } from "ts-action";

export const getPromotedBrandsTitle = action(
  getPromotedBrandsTitleAction.requested
);

export const getPromotedBrandsTitleSuccess = action(
  getPromotedBrandsTitleAction.fulfilled,
  payload<IPromotedBrandsSectionTitle>()
);

export const getPromotedBrandsTitleFailure = action(
  getPromotedBrandsTitleAction.rejected,
  payload<Error>()
);

export const editPromotedBrandsTitle = action(
  editPromotedBrandsTitleAction.requested,
  payload<IPromotedBrandsSectionTitle>()
);

export const editPromotedBrandsTitleSuccess = action(
  editPromotedBrandsTitleAction.fulfilled,
  payload<IPromotedBrandsSectionTitle>()
);

export const editPromotedBrandsTitleFailure = action(
  editPromotedBrandsTitleAction.rejected,
  payload<Error>()
);
