import { call, put, select, takeLatest } from "redux-saga/effects";
import { getCountriesAction } from "../../constants";
import { getCountriesFailure, getCountriesSuccess } from "../actions";
import { selectToken } from "../selectors";
import { getCountries } from "../../axios";

function* getCountriesSaga() {
  try {
    const token: string = yield select(selectToken);
    const res = yield call(getCountries, token);
    yield put(
      getCountriesSuccess(
        res.data.countries.map((c) => ({
          ...c,
          ISO_code: c.ISO_code.toLowerCase(),
        }))
      )
    );
  } catch (e) {
    yield put(getCountriesFailure(e));
  }
}

export function* watchGetCountriesSaga() {
  yield takeLatest(getCountriesAction.requested, getCountriesSaga);
}
