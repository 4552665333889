export const compareObjects = (o1, o2) => {
  for (const property in o1) {
    if (o1.hasOwnProperty(property)) {
      if (o1[property] !== o2[property]) {
        return false;
      }
    }
  }
  for (const property in o2) {
    if (o2.hasOwnProperty(property)) {
      if (o1[property] !== o2[property]) {
        return false;
      }
    }
  }
  return true;
};
