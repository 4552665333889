import axios from "../axios";
import { getResolvedActionsURL } from "../requests";

export const getResolvedActionsApi = (token: string, page: number, account_manager_id: string) => {
  return axios.get(getResolvedActionsURL, {
    headers: { token },
    params: { page, account_manager_id },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
