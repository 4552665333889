import * as React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import Aux from "../../UIComponents/AuxComponent";

export interface ISelectItemData<DataType = any> {
  label: string;
  value: DataType;
}

interface ISelectItemProps {
  item: ISelectItemData;
  onSelect: (item: any) => any;
}

const SelectItem: React.FC<ISelectItemProps> = ({ item, onSelect }) => {
  const handleSelect = () => onSelect(item.value);
  return (
    <DropdownItem className={styles.item} onClick={handleSelect}>
      {item.label}
    </DropdownItem>
  );
};

interface IRenderDropDownSelectState {
  isOpen: boolean;
}

type DefaultProps = Readonly<typeof defaultProps>;
interface IRenderDropDownSelectProps extends DefaultProps {
  label: string;
  type: string;
  className: string;
  disabled: boolean;
  options: ISelectItemData[];
  showArrow?: boolean;
}

const defaultProps = {
  renderSelectToggle: (item: ISelectItemData) => <Aux>{item.label}</Aux>,
};

export class RenderDropDownSelect extends React.Component<
  WrappedFieldProps & IRenderDropDownSelectProps,
  IRenderDropDownSelectState
> {
  public static defaultProps = defaultProps;
  public state = {
    isOpen: false,
  };

  public toggleSelectMenu = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  public handleBlur = () => {
    const { input } = this.props;
    input.onBlur("");
    input.onChange(input.value);
  };
  public render() {
    const {
      input,
      label,
      meta: { touched, error, warning },
      options,
      className,
    } = this.props;
    const selectedLabel = options.find((opt) => opt.value === input.value) || {
      label,
      value: "",
    };
    return (
      <div className={className ? styles.fullWidthDrop : styles.dropDownPart}>
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
          {touched &&
            ((error && <span className={styles.formError}>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </label>
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggleSelectMenu}>
          <DropdownToggle
            disabled={this.props.disabled}
            onBlur={this.handleBlur}
            className={styles.switch}
          >
            {this.props.showArrow ? (
              <div className={styles.showArrowWrapper}>
                {this.props.renderSelectToggle(selectedLabel)}
                <img src={require("../../assets/chevron-down.svg")}/>
              </div>
            ) : (
              this.props.renderSelectToggle(selectedLabel)
            )}
          </DropdownToggle>

          <DropdownMenu className={styles.menuWrapper}>
            {options.map((option, index) => (
              <SelectItem key={index} item={option} onSelect={input.onChange} />
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
