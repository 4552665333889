import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IStore } from "./getBrands";

export const editBrand = (token, store): AxiosReturn<IStore> => {
  return axios.put(Requests.editBrand(store.store_id), store, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
