import React, { useCallback } from "react";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";

const RadioButtonField: React.FC<
  WrappedFieldProps & {
    label: string;
    radioValue: string;
  }
> = ({ label, input, radioValue }) => {
  const checked = input.value === radioValue;
  const handleClick = useCallback(
    (e) => {
      input.onChange(e.target.value);
    },
    [radioValue]
  );
  return (
    <label className={styles.wrapper}>
      <input
        type="radio"
        name={input.name}
        checked={checked}
        {...input}
        value={radioValue}
        onChange={handleClick}
        className={styles.input}
      />
      <span className={styles.checkWrapper}>
        {checked && <span className={styles.check} />}
      </span>
      <div className={styles.labelWrapper}>
        <p className={styles.title}>{label}</p>
      </div>
    </label>
  );
};

export default RadioButtonField;
