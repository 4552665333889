import React, { useState } from "react";
import * as styles from "./styles.module.sass";
import { GenericButton } from "../../../SharedComponent/GenericButtonWithIcon";
import AddPushNotification from "../AddPushNotification";
interface IProps {
  length: number;
}
const PushNotificationHeader: React.FC<IProps> = ({ length }) => {
  const [open, setOpen] = useState(false);
  const handleOpenDrawer = () => setOpen(true);
  const handleCloseDrawer = () => setOpen(false);
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.headWrapper}>
          <span className={styles.pushNotificationCount}>
            Total Push Notification ({length})
          </span>
          <GenericButton
            iconPath={require("../../../../assets/add.svg")}
            className={styles.createBtn}
            IconClass={styles.icon}
            onClick={handleOpenDrawer}
            label={"Add Push Notification"}
          />
          {open && (
            <AddPushNotification open={open} handleClose={handleCloseDrawer} />
          )}
        </div>
        <div className={styles.header}>
          <span className={styles.date}>Date</span>

          <span className={styles.body}>Body</span>
          <span className={styles.success}>Success rate</span>
          {/* <span className={styles.open}>Open rate</span> */}
          <span className={styles.status}>Status</span>
        </div>
      </div>
    </div>
  );
};

export default PushNotificationHeader;
