import * as styles from "./styles.module.sass";
import React from "react";
import { getStatus } from "../../../Utils/status";

export const Status: React.FC<{ status: number }> = ({ status }) => {
  const statusesClass = [
    styles.inActive,
    styles.active,
    styles.inProgress,
    styles.closed,
    styles.churned,
  ];

  return (
    <div className={statusesClass[status]}>
      <span className={styles.dot} />
      {getStatus(status)}
    </div>
  );
};
