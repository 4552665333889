import React from "react";
import { useGetActiveLocationsStats } from "../../../../hooks";
import styles from "./styles.module.sass";
export const ConnectedActiveLocationsStats: React.FC = () => {
  const flags = {
    eg: require("../../../../assets/eg-flag.svg"),
    sa: require("../../../../assets/sa-flag.svg"),
  };
  const texts = {
    eg: "Egypt's Active Locations",
    sa: "Saudi Arabia's Active Locations",
  };
  const { activeLocations } = useGetActiveLocationsStats();
  return (
    <div className={styles.wrapper}>
      {Object.keys(activeLocations).map((i) => (
        <div className={styles.card} key={i}>
          <div className={styles.countryCard}>
            <img className={styles.countryFlag} src={flags[i]} />
          </div>
          <span className={styles.activeLocation}>{texts[i]}</span>
          <span className={styles.count}>{activeLocations[i]}</span>
        </div>
      ))}
    </div>
  );
};

export default ConnectedActiveLocationsStats;
