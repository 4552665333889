import { IBrandPosEntity } from "../../redux-store/reducers/getBrandPosEntitiesReducer";

export const updateExportBrandPosMenu = (
  state,
  entityId,
  JSONFileContent,
  JSONFileLoadingStatus
): {[key: string]: IBrandPosEntity} => {
  return {
    ...state.brandPosEntitiesById,
     [entityId]: {
      ...state.brandPosEntitiesById[entityId],
       JSONFileContent,
       JSONFileLoadingStatus
     }
  }
};