import { Action as ReduxAction, AnyAction } from "redux";

export enum LoadingStatus {
  success = "success",
  failed = "failed",
  loading = "loading",
  idle = "idle"
}

/*
The loading status initially would be undefined
as no action has changed its state 
when an action is fired it means that things are in motion
having an undefined initial state maintains a pristine state for all components
awaiting for that pristine state to be changed through a IGenericAction
*/
export interface ILoadingState {
  loadingStatus: LoadingStatus | undefined;
}

/*
A generic action is one that has the three parts that constitute
a loading/success/failure cycle it is the corner stone of everything here
*/
export interface IGenericAction {
  requested: string;
  fulfilled: string;
  rejected: string;
  reset: string;
}

/*
The reducer that is returned from withLoadingReducer
this reducer would pass the original state with the loading state
and wrap the original reducer only augmenting its data with the loading state
this should serve as an interface for the reducer or as reference
*/
type IReducerWithLoadingState<ReducerState, Action> = (
  state?: ReducerState & ILoadingState,
  action?: Action
) => ReducerState & ILoadingState;

export const withLoadingReducer = <
  ReducerState,
  Action extends ReduxAction = AnyAction
>(
  reducer: (state?: ReducerState, action?: Action) => ReducerState,
  genericLoadingAction: IGenericAction
): IReducerWithLoadingState<ReducerState, Action> => {
  const loadingStateMap = {
    [genericLoadingAction.requested]: LoadingStatus.loading,
    [genericLoadingAction.fulfilled]: LoadingStatus.success,
    [genericLoadingAction.rejected]: LoadingStatus.failed,
    [genericLoadingAction.reset]: LoadingStatus.idle,
  };
  return (
    state = {
      ...reducer(undefined, { type: "" } as Action),
      loadingStatus: undefined,
    },
    action = { type: "" } as Action
  ) => {
    const rawState = reducer(state, action);
    return {
      ...rawState,
      loadingStatus: loadingStateMap[action.type] || state.loadingStatus,
    };
  };
};
