import React, { useEffect } from "react";
import Branches from "./Branches";
import ActionSummary from "./ActionSummary";
import styles from "./styles.module.sass";
import { useSelect } from "../../hooks/useSelect";
import { LoadingStatus } from "../../redux-store/reducers/withLoadingState";
import BrandMessage from "./BrandMessage";
import { getSelectedActions } from "../../redux-store/actions";
import { useDispatch } from "react-redux";

export const AmsTakeAction: React.FC = () => {
  const dispatch = useDispatch();
  const { reslove_loading_status } = useSelect((state) => ({
    reslove_loading_status: state.resolveTasksReducer.loadingStatus,
  }));
  useEffect(() => {
    dispatch(getSelectedActions());
  }, []);

  return (
    <div className={styles.main}>
      <Branches />
      {reslove_loading_status === LoadingStatus.success ? (
        <ActionSummary />
      ) : (
        <BrandMessage />
      )}
    </div>
  );
};

export default AmsTakeAction;
