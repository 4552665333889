import { call, put, select, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { getAdminByRoleApi } from "../../../../axios";
import { selectToken } from "../../../../redux-store/selectors";
import {
  getAdminsByRoleSuccess,
  getAdminsByRoleFailure,
  getAdminsByRole,
} from "../actions/admins-by-role-actions";
import { getAdminsByRoleAction } from "../actions/constants";

const actionTypes = union({ getAdminsByRole });
function* getAdminsByRoleSaga({ payload }: typeof actionTypes) {
  try {
    const token: string = yield select(selectToken);
    const res = yield call(getAdminByRoleApi, token, payload.role);
    yield put(
      getAdminsByRoleSuccess({
        data: res.data,
        role: payload.role,
      })
    );
  } catch (error) {
    yield put(getAdminsByRoleFailure(error));
  }
}

export function* watchGetAdminsByRoleSaga() {
  yield takeLatest(getAdminsByRoleAction.requested, getAdminsByRoleSaga);
}
