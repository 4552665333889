import { Modal } from "@material-ui/core";
import React, { useEffect } from "react";
import styles from "./styles.module.sass";
import CloseIcon from "../../../assets/close_icon.svg";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { genBrandPosPaymentMethod } from "../../../redux-store/actions";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import { IBrandPosEntity } from "src/redux-store/reducers/getBrandPosEntitiesReducer";

interface IGenPaymentConfModalProps {
  onClose: () => void;
  open: boolean;
  store_id: string;
  brandPosEntity: IBrandPosEntity;
};

const GenPaymentConfModal: React.FC<IGenPaymentConfModalProps> = ({
  onClose,
  open,
  store_id,
  brandPosEntity: {
    id: entity_id,
    pos_vendor,
    ordering_access_token,
    pos_online_payment_method_id,
    loading_status,
  },
}) => {
  const dispatch = useDispatch();

  const handleGeneratePaymentMethod = () => {
    if (store_id && entity_id && pos_vendor) {
      dispatch(
        genBrandPosPaymentMethod({
          store_id,
          pos_entity_id: entity_id,
          pos_vendor,
          ordering_access_token,
        })
      );
    }
  };
  useEffect(() => {
    if (loading_status === LoadingStatus.success) {
      onClose();
    }
  }, [loading_status]);

  return (
    <Modal className={styles.wrapper} open={open} onClose={onClose}>
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.title}>
            {pos_online_payment_method_id
              ? "Regenerate Payment Method"
              : "Generate Payment Method"}
          </span>
          <img
            onClick={onClose}
            className={styles.closeIcon}
            src={CloseIcon}
            alt="close icon"
          />
        </div>
        <div className={styles.description}>
          Are you sure you want to{" "}
          {pos_online_payment_method_id ? "regenerate" : "generate"} a new
          payment method for this POS?
        </div>
        <div className={styles.footer}>
          <button onClick={onClose} className={styles.keepBtn}>
            Cancel
          </button>
          <button
            onClick={handleGeneratePaymentMethod}
            className={styles.confirmBtn}
          >
            {loading_status === LoadingStatus.loading ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default GenPaymentConfModal;
