import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getBrandPosEntitiesReset,
  updateBrandPosEntity,
  updateBrandPosEntityFailure,
  updateBrandPosEntitySuccess,
} from "../actions";
import { updateBrandPosEntityAction } from "../../constants";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import { updateBrandPosEntityApi } from "../../axios/pos";
import { customNotify } from "../../UIComponents/customNotification/customNotification";

const actionTypes = union({ updateBrandPosEntity });
function* updateBrandPosEntitySaga({ payload }: typeof actionTypes) {
  try {
    yield put(getBrandPosEntitiesReset());
    const token = yield select(selectToken);
    const res = yield call(updateBrandPosEntityApi, token, payload);
    yield put(
      updateBrandPosEntitySuccess({ ...res.data.pos, mode: undefined })
    );
    customNotify.success("Your changes have been saved", 2000);
  } catch (error) {
    customNotify.error("Something went wrong, please try again", 2000);
    yield put(updateBrandPosEntityFailure(error));
  }
}

export function* watchUpdateBrandPosEntitySaga() {
  yield takeLatest(
    updateBrandPosEntityAction.requested,
    updateBrandPosEntitySaga
  );
}
