import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteWatcherAction } from "../../constants";
import { deleteWatcherAPI } from "../../axios/deleteWatcherApi";
import { selectToken } from "../selectors";
import { deleteWatcherFailure, deleteWatcherSuccess } from "../actions";
import { customNotify } from "../../UIComponents/customNotification/customNotification";

function* deleteWatcherSaga(action) {
  try {
    const token = yield select(selectToken);
    yield call(deleteWatcherAPI, token, action.payload.id);
    customNotify.success("Watcher deleted successfully", 2000);
    yield put(deleteWatcherSuccess({ id: action.payload.id }));
  } catch (e) {
    customNotify.error("Something went wrong, please try again", 2000);
    yield put(deleteWatcherFailure(e));
  }
}

export function* watchDeleteWatcherSaga() {
  yield takeLatest(deleteWatcherAction.requested, deleteWatcherSaga);
}