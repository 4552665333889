import { IExportFoodicsMenuAction } from "../../types";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";

const LOADING = "loading";
const LOADED = "loaded";
const FAILED = "failed";
const JSONFileContent = "JSONFileContent";

export interface IExportFoodicsMenuReducer {
  [key: string]: any;
}

const initialState: IExportFoodicsMenuReducer = {
  [LOADED]: false,
  [LOADING]: false,
  [FAILED]: false,
  [JSONFileContent]: {},
};

export const exportFoodicsMenuReducer = (
  state = initialState,
  action: IExportFoodicsMenuAction
): any => {
  switch (action.type) {
    case constants.exportFoodicsMenuAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADED, false);
        map.set(LOADING, true);
        map.set(FAILED, false);
        map.set(JSONFileContent, {});
      });
    }
    case constants.exportFoodicsMenuAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADED, false);
        map.set(LOADING, false);
        map.set(FAILED, true);
        map.set(JSONFileContent, {});
      });
    }
    case constants.exportFoodicsMenuAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(LOADED, true);
        map.set(LOADING, false);
        map.set(FAILED, false);
        map.set(JSONFileContent, action.payload);
      });
    }

    default:
      return state;
  }
};
