import React, { useMemo, useState } from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";
import { hot } from "react-hot-loader";
import { reduxForm, Form, Field, InjectedFormProps } from "redux-form";
import { RenderPostFixedField } from "./RenderPostfixField/RenderPostfixField";
import { GenericButtonWithoutIcon } from "../../../../SharedComponent/GenericButtonWithoutIcon";
import {
  percentageCommissions,
  required,
  greaterThanZero,
} from "../../../../../redux-store/validators";
import { useDispatch } from "react-redux";
import { editCommissions } from "../../../../TargetedTools/redux-store/actions/commissions";
import { Select } from "../../../../FormElements/Select";
import { ICommissions } from "src/Components/TargetedTools/redux-store/reducers/lib";

interface IProps {
  open: boolean;
  onClose: () => void;
  store_id: string;
}

const EditCommission: React.FC<IProps & InjectedFormProps> = ({
  onClose,
  store_id,
  open,
  handleSubmit,
  initialValues,
}) => {
  const dispatch = useDispatch();
  const options = useMemo(
    () => [
      {
        value: "percentage",
        text: "Percentage",
      },
      {
        value: "value",
        text: "Value",
      },
    ],
    []
  );
  const [deliveryShareType, setDeliveryShareType] = useState<string>(
    options.find(
      (option) =>
        option.value === (initialValues as ICommissions).delivery_share_type
    )?.value || options[0].value
  );
  const editCommission = (values) => {
    dispatch(
      editCommissions({
        commissions: { ...values, delivery_share_type: deliveryShareType },
        id: store_id,
      })
    );
    onClose();
  };
  const onChange = (e: { text: string; value: string }) => {
    setDeliveryShareType(e.value);
  };
  const deliveryShareValidator = useMemo(
    () =>
      deliveryShareType === "value"
        ? [required, greaterThanZero]
        : [required, percentageCommissions],
    [deliveryShareType]
  );
  const deliverySharePostFix = useMemo(
    () => (deliveryShareType === "percentage" ? "%" : " EGP"),
    [deliveryShareType]
  );
  // === "percentage" ? "%" : " EGP"
  return (
    <Modal className={styles.main} open={open} onClose={onClose}>
      <div className={styles.wrapper}>
        <span className={styles.title}>Edit ordering commission</span>
        <Form onSubmit={handleSubmit(editCommission)}>
          <div className={styles.form}>
            <Field
              type={"number"}
              component={RenderPostFixedField}
              name={"pickup_commission"}
              postFix={"%"}
              label={"Pickup commission"}
              placeholder={"20"}
              validate={[percentageCommissions, required]}
            />
            <div className={styles.separator} />
            <Field
              type={"number"}
              component={RenderPostFixedField}
              name={"delivery_commission"}
              postFix={"%"}
              validate={[percentageCommissions, required]}
              label={"Delivery commission"}
              placeholder={"Ex. 20"}
            />
          </div>
          <div className={styles.form}>
            <Select
              label={"Delivery share type"}
              className={styles.flex1}
              options={options}
              placeholder={"Select delivery share type"}
              onChange={onChange}
              initialValue={
                options.find(
                  (option) =>
                    option.value ===
                    (initialValues as ICommissions).delivery_share_type
                ) || options[0]
              }
            />
            <div className={styles.separator} />

            <Field
              className={styles.flex1}
              type={"number"}
              component={RenderPostFixedField}
              name={"delivery_share"}
              postFix={deliverySharePostFix}
              validate={deliveryShareValidator}
              label={"Delivery Share"}
              placeholder={"Ex. 20"}
            />
          </div>
          <div className={styles.btnWrapper}>
            <GenericButtonWithoutIcon
              className={styles.cancel}
              label={"Cancel"}
              onClick={onClose}
            />
            <GenericButtonWithoutIcon
              type={"submit"}
              disabled={!deliveryShareType}
              className={styles.save}
              label={"Save"}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default reduxForm<{}, IProps>({
  form: "commissionForm",
})(hot(module)(EditCommission as any));
