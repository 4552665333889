import * as React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import * as styles from "./styles.module.sass";
import Aux from "../../../../UIComponents/AuxComponent";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export interface ISelectItemData {
  label: string;
  value: any;
}

interface ISelectItemProps {
  item: ISelectItemData;
  onSelect: (item: any) => any;
}

const SelectItem: React.SFC<ISelectItemProps> = ({ item, onSelect }) => {
  const handleSelect = (e) => {
    onSelect(item.value);
  };
  return (
    <DropdownItem className={styles.item} onClick={handleSelect}>
      {item.label}
    </DropdownItem>
  );
};

interface IRenderDropDownSelectState {
  isOpen: boolean;
}

type DefaultProps = Readonly<typeof defaultProps>;
interface IRenderDropDownSelectProps extends DefaultProps {
  label: string;
  className: string;
  disabled?: boolean;
  options: ISelectItemData[];
  value?: string;
  touched?: boolean;
  error?: any;
  name?: string;
  onChange: (e) => void;
  onBlur: (e) => void;
  toggleStyles?: string;
}

const defaultProps = {
  renderSelectToggle: (item: ISelectItemData) => <Aux>{item.label}</Aux>,
};

export class DropDownSelect extends React.Component<
  IRenderDropDownSelectProps,
  IRenderDropDownSelectState
> {
  public static defaultProps = defaultProps;
  public state = {
    isOpen: false,
  };

  public toggleSelectMenu = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  public handleChange = (e) => {
    this.props.onChange(e.target.value);
  };

  public handleBlur = () => {
    const { onChange, onBlur } = this.props;
    onBlur("");
    onChange(this.props.value);
  };
  public render() {
    const { label, options, className } = this.props;
    const selectedLabel = options.find(
      (opt) => opt.value === this.props.value
    ) || {
      label,
      value: "",
    };
    return (
      <div className={className ? styles.fullWidthDrop : styles.dropDownPart}>
        <label htmlFor={this.props.name} className={styles.passwrodLabel}>
          <span>{label}</span>
          {this.props.touched && this.props.error && (
            <span className={styles.formError}>{this.props.error.message}</span>
          )}
        </label>
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggleSelectMenu}>
          <DropdownToggle
            disabled={this.props.disabled}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            className={
              this.props.toggleStyles
                ? this.props.toggleStyles
                : styles.switchForUsers
            }
            style={{
              ...(this.props.disabled ? { cursor: "not-allowed" } : {}),
            }}
          >
            <span>{this.props.renderSelectToggle(selectedLabel)}</span>
            <ArrowDropDownIcon style={{ width: "2.5rem", height: "2.5rem" }} />
          </DropdownToggle>

          <DropdownMenu className={styles.menuWrapper}>
            {options.map((option, index) => (
              <SelectItem
                key={index}
                item={option}
                onSelect={this.props.onChange}
              />
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
