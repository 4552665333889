import React, { useMemo } from "react";
import styles from "./styles.module.sass";
import alert from "../../../assets/ic_alert_triangle.svg";
import info from "../../../assets/info.svg";
import chevroRight from "../../../assets/ic_chevron_right.svg";
import BrandActionsItem from "../BrandActionsItem";
import { useSelect } from "../../../hooks/useSelect";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import {
  selectStoreIdAction,
  setSelectedTasksAction,
} from "../../../redux-store/actions";
import { CheckIcon } from "../../../assets/components";
import { RouteComponentProps, withRouter } from "react-router-dom";
export const BrandActionsCard: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const {
    shownBrandsTasksIds,
    loadingStatus,
    shownBrandsTasksById,
    selectedBrandsById,
    selectedBrandsTasksIds,
  } = useSelect((state) => state.actionCenterBrandsReducer);
  const dispatch = useDispatch();
  const brandsRender = useMemo(() => {
    let jsx;
    if (loadingStatus === LoadingStatus.loading) {
      jsx = (
          <div className={styles.loadingContainer}>
            <ClipLoader color=" #f09440" size={50} />
          </div>
      );
    } else if (
      (loadingStatus === LoadingStatus.success ||
        loadingStatus === LoadingStatus.failed) &&
      !shownBrandsTasksIds.length
    ) {
      jsx = (
        <div className={styles.emptyContainer}>
          <img className={styles.emptyIcon} src={info} />
          <span className={styles.emptyDescription}>
            There are no tasks available to be resolved. Please visit the
            dashboard to create tasks
          </span>
          <a href={process.env.TABLEAU_URL} target="_blank" className={styles.visitDashboardBtn}>
            <span className={styles.visitDashboardText}>Visit Dashboard</span>
            <img className={styles.chevroRight} src={chevroRight} />
          </a>
        </div>
      );
    } else {
      jsx = (
        <div className={styles.contentWrapper}>
          <BrandActionsItem />
        </div>
      );
    }

    return jsx;
  }, [shownBrandsTasksIds, loadingStatus]);

  const allSelected = useMemo(
    () =>
      !!selectedBrandsTasksIds.length &&
      JSON.stringify(shownBrandsTasksById) ===
        JSON.stringify(selectedBrandsById),
    [shownBrandsTasksById, selectedBrandsById, selectedBrandsTasksIds]
  );
  const handleSelectAllBrands = () => {
    dispatch(
      setSelectedTasksAction({ selected: !allSelected, page: "action-center" })
    );
  };

  const handleBlukActions = () => {
    history.push("/account-managers/action-center/take-action");
    if (selectedBrandsTasksIds.length === 1) {
      dispatch(selectStoreIdAction(selectedBrandsTasksIds[0]));
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.headerWrapper}>
        <div className={styles.titleWrapper}>
          {shownBrandsTasksIds.length > 1 && (
            <div
              onClick={handleSelectAllBrands}
              style={{
                backgroundColor:
                  loadingStatus === LoadingStatus.success && allSelected
                    ? "#1C1C1E"
                    : "",
              }}
              className={styles.checkboxWrapper}
            >
              {allSelected && (
                <CheckIcon
                  style={{ width: "14px", height: "14px" }}
                  stroke="#fff"
                />
              )}
            </div>
          )}

          <img src={alert} />
          <span className={styles.title}>
            Brands need action{" "}
            <span style={{ color: "#65656C" }}>
              ({shownBrandsTasksIds.length})
            </span>
          </span>
        </div>
        <button
          style={{
            cursor: selectedBrandsTasksIds.length ? "pointer" : "not-allowed",
          }}
          disabled={!selectedBrandsTasksIds.length}
          onClick={handleBlukActions}
          className={styles.bulkActionsBtn}
        >
          Bulk actions
        </button>
      </div>
      {brandsRender}
    </div>
  );
};

export default withRouter(BrandActionsCard);
