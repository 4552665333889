import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  deleteBannerCard,
  deleteBannerCardFailure,
  deleteBannerCardSuccess,
} from "../actions/bannerCardsActions";
import { deleteBannerCardAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { deleteBannerCardAPI } from "../../axios/deleteBannerCard";

const actionType = union({ deleteBannerCard });

function* deleteBannerCardSaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  yield call(deleteBannerCardAPI, token, payload.id);
  return payload;
}

export function* watchDeleteBannerCardSaga() {
  yield takeLatest(
    deleteBannerCardAction.requested,
    handleOptimism(deleteBannerCardSaga, {
      success: deleteBannerCardSuccess,
      failure: deleteBannerCardFailure,
    })
  );
}
