import React from "react";
import styles from "./styles.module.sass";
export interface IErrorMessage {
  message: string;
  type: string;
  style?: { [key: string]: string };
}
const ErrorMessage: React.FC<IErrorMessage> = ({
  message,
  style,
}: IErrorMessage) => {
  return (
    <span style={style ? style : {}} className={styles.error}>
      {message}
    </span>
  );
};

export default ErrorMessage;
