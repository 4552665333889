import { atom } from "recoil";
export const gmvOptions = [
  {
    text: "Total GMV",
    value: "total_gmv",
  },
  {
    text: "After Vat",
    value: "after_vat",
  },
  {
    text: "After merchant promo share",
    value: "after_merchant_promo_share",
  },
  {
    text: "After Vat and merchant promo share",
    value: "after_vat_and_merchant_promo_share",
  },
];
export type OrderingGMVTypes =
  | "total_gmv"
  | "after_vat"
  | "after_merchant_promo_share"
  | "after_vat_and_merchant_promo_share";

export type ServiceStatus = "service_enabled" | "service_disabled";
export type WebPortalStatus =
  | "service_disabled"
  | "service_enabled"
  | "service_enabled_published"
  | "service_enabled_unpublished";

export interface IStatus {
  status:
    | ServiceStatus
    | "service_enabled_unpublished"
    | "service_enabled_published";
  loading: "idle" | "success" | "error" | "loading";
}
export interface IStoreActiveDeals {
  pickup_status: IStatus;
  delivery_status: IStatus;
  loyalty_status: IStatus;
  web_portal_status: IStatus;
  qr_portal_status: IStatus;
  drive_thru_status: IStatus
}
export const orderingGMVTypeAtom = atom<string>({
  default: "Select GMV calculation type",
  key: "orderingGMVTypeAtom",
});

export const activeMerchantDealsAtom = atom<IStoreActiveDeals>({
  default: {
    delivery_status: {
      status: "service_disabled",
      loading: "idle",
    },
    loyalty_status: {
      status: "service_disabled",
      loading: "idle",
    },
    pickup_status: {
      status: "service_disabled",
      loading: "idle",
    },
    web_portal_status: {
      status: "service_disabled",
      loading: "idle",
    },
    qr_portal_status: {
      status: "service_disabled",
      loading: "idle",
    },
    drive_thru_status: {
      status: "service_disabled",
      loading: "idle",
    }
  },
  key: "activeMerchantDealsAtom",
});
