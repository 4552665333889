import { useEffect, useState } from "react";
import { UseFormWatch } from "react-hook-form";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { useSelect } from "../../../../hooks/useSelect";
import { selectPromocodeWatchers } from "../helpers/selectPromocodeWatchers";
import Papa from "papaparse";
import { PromoMerchantVisibility, SetReactHookFormValue } from "../../types";
import { setStores } from "../PromoCodeForm/lib";
import {
  allEqStoresMinEligibleInvoice,
  allStoresCappingEg,
  allStoresPercentageEqWithMasked,
  allStoresPercentageEqWithWithoutMasked,
} from "../helpers/lib";

export const useUploadBrands = ({
  watch,
  setValue,
  setSplit,
  isEditing,
  setApplyCapping,
  setApplyMinEligibleInvoice,
}: {
  watch?: UseFormWatch<any>;
  setValue?: SetReactHookFormValue;
  setSplit: (value: boolean) => void;
  isEditing: boolean;
  setApplyCapping: (value: boolean) => void;
  setApplyMinEligibleInvoice: (value: boolean) => void;
}) => {
  /*STATES*/
  const [csvJson, setCsvJson] = useState<any[]>([]);
  const [{ file, name }, setFile] = useState<{
    name?: string;
    file: string;
  }>({
    file: "",
    name: undefined,
  });
  const { stores: currentStores } = useSelect((state) => ({
    ...state.CountryStoresReducer,
    ...state.countriesReducer,
  }));
  const watchers = selectPromocodeWatchers(watch);
  // get current stores
  const getCurrentStores = (data) => {
    return [
      ...currentStores
        ?.filter((store) => (watchers?.stores_ids || []).includes(store.value))
        ?.map((store) => ({
          store_name: store.label,
          store_id: store.value,
          merchant_share: watchers?.stores_fund_percentage
            ? watchers?.stores_fund_percentage[store.value]?.brand
            : "",
          masked_value: watchers.masked_values
            ? watchers.masked_values[store.value]
            : "",
          masked_promo_code: watchers.masked_promo_codes
            ? watchers.masked_promo_codes[store.value]
            : "",
          max_discount_value: watchers.max_discount_values
            ? watchers.max_discount_values[store.value]
            : "",
          min_threshold: watchers.min_threshold_values
            ? watchers.min_threshold_values[store.value]
            : "",
        }))
        .filter(
          (current_store) =>
            !data.some(
              (csv_store) =>
                csv_store?.store_id.replace(/\s/g, "") ===
                  current_store.store_id.replace(/\s/g, "") ||
                csv_store?.store_name?.replace(/\s/g, "") ===
                  current_store.store_name.replace(/\s/g, "")
            )
        ),
    ];
  };
  // convert csv file to JSON
  const convertFileToJson = async () => {
    const response = await fetch(file);
    const reader = (response as any)?.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder("utf-8");
    const csv = decoder.decode(result.value);
    const results = Papa.parse(csv, { header: true });
    const data = results.data;
    if (data[0].store_name) {
      if (setValue) {
        setValue("csv_values", data);
        setValue("applySplitForAllParameterValue", undefined);
        setValue("applyMinEligibleInvoiceForAllBrands", undefined);
        setValue("applyCappingForAllBrands", undefined);
      }
      setCsvJson([
        ...getCurrentStores(
          data.filter((store) => store.store_name || store.store_id)
        ),
        ...data
          .map((store) => ({
            ...store,
            merchant_share:
              store?.merchant_share?.replace(/\s/g, "").length > 0
                ? Number(store?.merchant_share)
                : "",
            masked_value:
              store?.masked_value?.replace(/\s/g, "").length > 0
                ? Number(store?.masked_value)
                : "",
            masked_promo_code:
              store?.masked_promo_code?.replace(/\s/g, "").length > 0
                ? store?.masked_promo_code
                : "",
            max_discount_value:
              store?.max_discount_value?.replace(/\s/g, "").length > 0
                ? Number(store?.max_discount_value)
                : "",
            min_threshold:
              store?.min_threshold?.replace(/\s/g, "").length > 0
                ? Number(store?.min_threshold)
                : "",
          }))
          .filter((store) => store.store_name || store.store_id),
      ]);
    } else {
      setCsvJson([]);
    }
  };
  useDeepCompareEffect(() => {
    if (file) {
      convertFileToJson();
    }
  }, [file]);
  const csvStores: any = useDeepCompareMemo(() => {
    return csvJson
      .map((csv) => {
        const stores = currentStores.find(
          (current_store) =>
            csv.store_name.toLowerCase().replace(/\s/g, "") ===
              current_store.label.toLowerCase().replace(/\s/g, "") ||
            csv.store_id === current_store.value
        );
        if (stores?.label && stores?.value) {
          return {
            name: stores.label,
            id: stores.value,
            store_fund_percentage: csv.merchant_share
              ? Math.abs(Number(csv?.merchant_share))
              : 0,
            masked_value: csv.masked_value
              ? Math.abs(Number(csv.masked_value || 0))
              : undefined,
            masked_promo_code:
              csv?.masked_promo_code?.replace(/\s/g, "").length > 0
                ? csv?.masked_promo_code?.replace(/\s/g, "")
                : "",
            max_discount_value: Math.abs(csv?.max_discount_value || 0),
            min_threshold: Math.abs(csv?.min_threshold || 0),
          };
        } else {
          return {
            id: undefined,
            name: undefined,
            store_fund_percentage: undefined,
            masked_value: undefined,
            masked_promo_code: undefined,
            max_discount_value: undefined,
            min_threshold: undefined,
          };
        }
      })
      .filter((item) => item?.name && item?.id);
  }, [currentStores, csvJson]);
  // stores_ids
  const storesIds = useDeepCompareMemo(
    () => csvStores.map((csv_store) => csv_store?.id),
    [csvStores]
  );

  useDeepCompareEffect(() => {
    if (storesIds.length > 0 && setValue) {
      setValue("stores", setStores(csvStores, watchers.country_iso_code));
      setValue("stores_ids", storesIds);
      csvStores.forEach((element) => {
        setValue(
          `max_discount_values.${element.id}`,
          element.max_discount_value
        );
        setValue(`min_threshold_values.${element.id}`, element.min_threshold);
        setValue(
          `stores_fund_percentage.${element.id}.brand`,
          element.store_fund_percentage
        );
        setValue(
          `stores_fund_percentage.${element.id}.koinz`,
          100 - element.store_fund_percentage
        );
        setValue(`masked_values.${element.id}`, element.masked_value);
        setValue(`masked_promo_codes.${element.id}`, element.masked_promo_code);
      });
    }
  }, [file, csvStores, storesIds, setValue, watchers.country_iso_code]);
  const isMaskedPromocode = useDeepCompareMemo(
    () =>
      Boolean(
        !isEditing &&
          csvStores.some(
            (value) => value.masked_value || value.masked_promo_code
          )
      ),
    [csvStores, isEditing]
  );
  const promocodeIsPercentageType = useDeepCompareMemo(
    () =>
      csvStores.some(
        (store) =>
          typeof store.max_discount_value === "number" ||
          typeof store.min_threshold === "number"
      ),
    [csvStores]
  );

  useEffect(() => {
    if (
      setValue &&
      promocodeIsPercentageType &&
      !isMaskedPromocode &&
      watchers.promo_merchant_visibility !== PromoMerchantVisibility.masked
    ) {
      setValue("type", "discount_percentage");
      setValue("promo_merchant_visibility", PromoMerchantVisibility.visible);
    }
  }, [
    promocodeIsPercentageType,
    setValue,
    isMaskedPromocode,
    watchers.promo_merchant_visibility,
  ]);

  useEffect(() => {
    if (isMaskedPromocode && setValue) {
      setValue("promo_merchant_visibility", PromoMerchantVisibility.masked);
    }
  }, [isMaskedPromocode, setValue]);

  useDeepCompareEffect(() => {
    if (
      setValue &&
      csvStores.length > 1 &&
      watchers.promo_merchant_visibility !== PromoMerchantVisibility.masked
    ) {
      if (allStoresCappingEg(csvStores)) {
        setApplyCapping(true);
        setValue("applyCappingForAllBrands", {
          max_discount_value: Number(csvStores[0].max_discount_value),
        });
      } else {
        setApplyCapping(false);
        setValue("applyCappingForAllBrands", undefined);
      }
    }
  }, [csvStores, file, setApplyCapping, watchers.promo_merchant_visibility]);
  useDeepCompareEffect(() => {
    if (
      setValue &&
      csvStores.length > 1 &&
      watchers.promo_merchant_visibility !== PromoMerchantVisibility.masked
    ) {
      if (allEqStoresMinEligibleInvoice(csvStores)) {
        setApplyMinEligibleInvoice(true);
        setValue("applyMinEligibleInvoiceForAllBrands", {
          min_threshold: Number(csvStores[0].min_threshold),
        });
      } else {
        setApplyMinEligibleInvoice(false);
        setValue("applyMinEligibleInvoiceForAllBrands", undefined);
      }
    }
  }, [
    csvStores,
    file,
    setApplyMinEligibleInvoice,
    watchers.promo_merchant_visibility,
  ]);

  useDeepCompareEffect(() => {
    if (setValue && csvStores.length > 1) {
      if (allStoresPercentageEqWithMasked(csvStores)) {
        setSplit(true);
        setValue("promo_merchant_visibility", PromoMerchantVisibility.masked);
        setValue("applySplitForAllParameterValue", {
          store_fund_percentage: {
            koinz: Number(Math.abs(100 - csvStores[0].store_fund_percentage)),
            brand: Number(csvStores[0].store_fund_percentage),
          },
          masked_value: csvStores[0].masked_value
            ? csvStores[0].masked_value < 1
              ? csvStores[0].masked_value * 100
              : csvStores[0].masked_value
            : 0,
          masked_promo_code: csvStores[0].masked_promo_code,
        });
      } else if (
        allStoresPercentageEqWithWithoutMasked(csvStores) &&
        watchers.promo_merchant_visibility !== PromoMerchantVisibility.masked &&
        !allStoresPercentageEqWithMasked(csvStores)
      ) {
        setSplit(true);
        setValue("applySplitForAllParameterValue", {
          store_fund_percentage: {
            koinz: Number(Math.abs(100 - csvStores[0].store_fund_percentage)),
            brand: Number(csvStores[0].store_fund_percentage),
          },
        });
      } else {
        setValue("applySplitForAllParameterValue", undefined);
        setSplit(false);
      }
    }
  }, [csvStores, file, setValue, setSplit]);

  return {
    csvJson,
    name,
    setFile,
  };
};
