import { useHasTruthyValue } from "./../../../../hooks/useHasTruthyValue";
import { store } from "../../../../redux-store/configureStore";
import {
  AllowedPaymentMethod,
  IUsePromocodeForm,
  OrderingTypes,
  PromocodeTarget,
  PromoMerchantVisibility,
  PromoTypes,
} from "../../../TargetedTools/types";
import { clearCurrentPromotionStore } from "../../../TargetedTools/redux-store/actions/promoCodeActions";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import debounce from "lodash/debounce";
import { getPromoCode } from "../../../../redux-store/actions/getPromoCodesActions";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
import { useDeepCompareEffect, useDeepCompareMemo } from "use-deep-compare";
import { setValuesById } from "../helpers/lib";
import { useSelect } from "../../../../hooks/useSelect";
import { allEq } from "../../../../helpers/allEq";

export const usePromocodeForm = ({
  setValue,
  setError,
  reset,
  close,
  watchers,
  clearErrors,
  stores_ids,
  initialValues,
  isDuplicating,
  isReactivating,
  isEditing,
  masked,
  countryStores,
  country_iso_code,
}: IUsePromocodeForm) => {
  /* submit button disabled */
  /****Actions****/
  const checkPromoValidity = useCallback(
    debounce<any>((code, stores_id) => {
      store.dispatch(getPromoCode({ promo_code: code, stores_id }));
    }, 500),
    []
  );

  /*close drawer and reset values*/

  const handleDrawerClose = () => {
    setValue("stores_ids", []);
    setValue("country_iso_code", "");
    setValue("applySplitForAllParameterValue", undefined);
    reset();
    store.dispatch(clearCurrentPromotionStore());
    close();
  };

  /****States****/
  /*promocode split */
  const [promocodeSplit, setPromocodeSplit] = useState<boolean>(false);
  const [applyCapping, setApplyCapping] = useState<boolean>(false);
  const [applyMinEligibleInvoice, setApplyMinEligibleInvoice] = useState<
    boolean
  >(false);
  const [applyMaxCashbackValue, setApplyMaxCashbackValue] = useState<boolean>(
    false
  );
  const [applyExipresAfter, setApplyExpiresAfter] = useState<boolean>(false);
  /* select ordering type */
  const handleOrderingTypeChange = (ordering_type: OrderingTypes) =>
    setValue("ordering_type", ordering_type);
  /* select promo type */

  const handleChangePromoType = (v: PromoTypes) => {
    setValue("type", v as PromoTypes);
  };
  /**
   * Masked Promocode
   */
  const handleMaskedPromocode = (value) => {
    setValue(
      "promo_merchant_visibility",
      watchers.promo_merchant_visibility === PromoMerchantVisibility.invisible
        ? PromoMerchantVisibility.masked
        : PromoMerchantVisibility.invisible
    );
    setValue("allowed_payment_method", AllowedPaymentMethod.online);
  };

  const handleExclusivePromo = (is_exclusive): void => { 
    setValue("is_exclusive", is_exclusive ? 1 : 0);
  };
  /* promocode target */
  const [promocodeTarget, setPromocodeTarget] = useState<PromocodeTarget>(
    PromocodeTarget.normal
  );
  useEffect(() => {
    if (initialValues?.label === "universal") {
      setPromocodeTarget(PromocodeTarget.universal);
    } else if (initialValues?.is_compensational) {
      setPromocodeTarget(PromocodeTarget.compensational);
    } else {
      setPromocodeTarget(PromocodeTarget.normal);
    }
  }, [initialValues?.label, initialValues?.is_compensational]);
  const handlePromocodeTargetChange = (type: PromocodeTarget) => {
    setPromocodeTarget(type);
  };

  /****Stores****/
  const { storesCountry } = useSelect((state) => ({
    storesCountry: state.CountryStoresReducer.stores,
  }));
  const initialStoresValues = useDeepCompareMemo(() => {
    return (initialValues?.stores_ids || []).filter((store_id) =>
      storesCountry.some((element) => element.value.includes(store_id))
    );
  }, [initialValues?.stores_ids, storesCountry]);

  useEffect(() => {
    if (isDuplicating) {
      setValue("stores_ids", initialStoresValues);
    } else if (isEditing) {
      setValue("stores_ids", initialValues.stores_ids);
    }
  }, [
    isEditing,
    isDuplicating,
    initialStoresValues,
    initialValues?.stores_ids,
  ]);

  useEffect(() => {
    if (stores_ids) {
      setValue("stores_ids", stores_ids, { shouldDirty: true });
    }
  }, [stores_ids]);

  // validate promo code
  useEffect(() => {
    if (watchers.promo_code) {
      checkPromoValidity(watchers.promo_code, watchers.stores_ids || []);
    }
  }, [watchers.promo_code, watchers.stores_ids]);

  useEffect(() => {
    if (isDuplicating) {
      setValue("promo_code", "");
    }
  }, [isDuplicating]);
  useEffect(() => {
    if (watchers.type === PromoTypes.discount) {
      setValue("max_discount_value", watchers.discount_value);
    }
  }, [watchers.discount_value, watchers.type]);
  // validate promo time
  useEffect(() => {
    if (watchers.start_time && watchers.end_time) {
      if (
        new Date(watchers.start_time).getTime() >
        new Date(watchers.end_time).getTime()
      ) {
        setError("end_time", {
          message: "Invalid start & end dates",
        });
        setError("start_time", {
          message: "Invalid start & end dates",
        });
      } else {
        clearErrors(["start_time", "end_time"]);
      }
    } else {
      setValue("start_time", moment().startOf("day").toDate());
      setValue("end_time", moment().endOf("day").toDate());
    }
  }, [watchers.start_time, watchers.end_time]);

  useEffect(() => {
    if (isReactivating) {
      setValue("end_time", moment().endOf("day").valueOf());
    }
  }, [isReactivating]);

  useEffect(() => {
    if (isDuplicating) {
      if (Number(new Date().getTime()) > Number(initialValues?.end_time)) {
        setValue("end_time", moment().endOf("day").valueOf());
      }
      if (Number(new Date().getTime()) > Number(initialValues?.start_time)) {
        setValue("start_time", moment().startOf("day").valueOf());
      }
    }
  }, [isDuplicating, initialValues?.end_time, initialValues?.start_time]);

  const submitButtonDisabled = ({ formState, loading }) => {
    return (
      (watchers.promo_merchant_visibility !== PromoMerchantVisibility.masked &&
        applyCapping &&
        typeof watchers?.applyCappingForAllBrands?.max_discount_value ===
          "undefined") ||
      (watchers.promo_merchant_visibility !== PromoMerchantVisibility.masked &&
        applyMinEligibleInvoice &&
        typeof watchers?.applyMinEligibleInvoiceForAllBrands?.min_threshold ===
          "undefined") ||
      (watchers.type === PromoTypes.cashback_percentage &&
        watchers.promo_merchant_visibility !== PromoMerchantVisibility.masked &&
        ((applyExipresAfter &&
          typeof watchers?.applyExpiresAfterValueForAllBrands?.expires_after ===
            "undefined") ||
          (applyMaxCashbackValue &&
            typeof watchers?.applyMaxCashbackValueForAllBrands
              ?.max_cashback_value === "undefined"))) ||
      watchers.type === PromoTypes.default ||
      !formState.isValid ||
      !watchers.rank ||
      !(watchers.stores_ids && watchers.stores_ids.length) ||
      loading === LoadingStatus.loading ||
      (promocodeTarget === PromocodeTarget.universal &&
        !watchers.targeting_method) ||
      ([PromocodeTarget.compensational, PromocodeTarget.normal].includes(
        promocodeTarget
      ) &&
        !watchers.target_customers &&
        !isReactivating &&
        !isEditing)
    );
  };
  /**
   * stores
   */
  const initialStoreById = useDeepCompareMemo(() => {
    return initialValues?.stores?.reduce(
      (acc, next) => ({
        ...acc,
        [next.id]: next,
      }),
      {}
    );
  }, [initialValues]);
  const hasTruthyValue = (value) => useHasTruthyValue(value);

  const storesOutOfCountryStores = useDeepCompareMemo(() => {
    return initialValues?.stores
      ?.filter(
        (filteredStore) =>
          !countryStores?.some((element) => element.value === filteredStore.id)
      )
      .map((mappedStore) => ({
        label: mappedStore.name,
        value: mappedStore.id,
        img: "",
      }));
  }, [initialValues?.store, countryStores]);
  const currentStoresValues = useMemo(
    () => ({
      max_cashback_values: watchers.max_cashback_values,
      min_threshold_values: watchers.min_threshold_values,
      expires_after_values: watchers.expires_after_values,
      max_discount_values: watchers.max_discount_values,
      stores_fund_percentage: watchers.stores_fund_percentage,
      masked_values: watchers.masked_values,
      masked_promo_codes: watchers.masked_promo_codes,
    }),
    [
      watchers.max_cashback_values,
      watchers.masked_values,
      watchers.masked_promo_codes,
      watchers.stores_fund_percentage,
      watchers.expires_after_values,
      watchers.max_discount_values,
      watchers.min_threshold_values,
    ]
  );
  const stores = useDeepCompareMemo(() => {
    return [
      ...countryStores.filter((element) => stores_ids?.includes(element.value)),
      ...(isEditing || isReactivating ? storesOutOfCountryStores : []),
    ].map((s) => ({
      id: s.value,
      name: s.label,
      country_iso_code,
      max_cashback_value: currentStoresValues.max_cashback_values
        ? currentStoresValues.max_cashback_values[s.value]
        : initialStoreById &&
          hasTruthyValue(initialStoreById[s.value]?.max_cashback_value)
        ? initialStoreById[s.value]?.max_cashback_value
        : 0,
      expires_after: currentStoresValues.expires_after_values
        ? currentStoresValues.expires_after_values[s.value]
        : initialStoreById &&
          hasTruthyValue(initialStoreById[s.value]?.expires_after)
        ? initialStoreById[s.value]?.expires_after
        : 72,
      min_threshold: currentStoresValues.min_threshold_values
        ? currentStoresValues.min_threshold_values[s.value]
        : initialStoreById &&
          hasTruthyValue(initialStoreById[s.value]?.min_threshold)
        ? initialStoreById[s.value]?.min_threshold
        : 0,
      max_discount_value: currentStoresValues.max_discount_values
        ? currentStoresValues.max_discount_values[s.value]
        : initialStoreById &&
          hasTruthyValue(initialStoreById[s.value]?.max_discount_value)
        ? initialStoreById[s.value]?.max_discount_value
        : 0,
      store_fund_percentage: currentStoresValues.stores_fund_percentage
        ? currentStoresValues.stores_fund_percentage[s.value]?.brand
        : initialStoreById &&
          hasTruthyValue(initialStoreById[s.value]?.store_fund_percentage)
        ? initialStoreById[s.value]?.store_fund_percentage
        : 0,
      masked_value: currentStoresValues.masked_values
        ? currentStoresValues.masked_values[s.value]
        : initialStoreById &&
          hasTruthyValue(initialStoreById[s.value]?.masked_value)
        ? initialStoreById[s.value]?.masked_value < 1
          ? (initialStoreById[s.value]?.masked_value * 100).toFixed()
          : initialStoreById[s.value]?.masked_value
        : 0,

      masked_promo_code:
        currentStoresValues.masked_promo_codes &&
        currentStoresValues?.masked_promo_codes[s.value]
          ? currentStoresValues?.masked_promo_codes[s.value]
          : initialStoreById && initialStoreById[s.value]?.masked_promo_code
          ? initialStoreById[s.value]?.masked_promo_code
          : "",
    }));
  }, [
    stores_ids,
    currentStoresValues,
    countryStores,
    initialStoreById,
    isEditing,
    isReactivating,
    storesOutOfCountryStores,
  ]);

  useDeepCompareEffect(() => {
    setValue("stores", stores);
  }, [stores, setValue]);
  useDeepCompareEffect(() => {
    watchers?.stores?.forEach((element) => {
      setValue(
        `stores_fund_percentage.${element.id}.brand`,
        element.store_fund_percentage ?? 0
      );
      setValue(
        `stores_fund_percentage.${element.id}.koinz`,
        100 - (element.store_fund_percentage ?? 0)
      );
      setValue(`max_cashback_values.${element.id}`, element.max_cashback_value ?? 0);
      setValue(`expires_after_values.${element.id}`, element.expires_after ?? 72);
      setValue(`max_discount_values.${element.id}`, element.max_discount_value ?? 0);
      setValue(`min_threshold_values.${element.id}`, element.min_threshold ?? 0);
      setValue(`masked_values.${element.id}`, element.masked_value ?? 0);
      setValue(`masked_promo_codes.${element.id}`, element.masked_promo_code);
    });
  }, [watchers?.stores, setValue]);
  /* handling stores fund percentage and masked promo code */
  // store values by id
  const storeValuesById = useDeepCompareMemo(() => {
    return watchers?.stores?.reduce(
      (acc, next) => ({
        ...acc,
        [next.id]: next,
      }),
      {}
    );
  }, [watchers?.stores]);

  // all stores fund percentage is equal
  useDeepCompareEffect(() => {
    if (
      watchers.stores?.length > 1 && 
      masked &&
      (isEditing || isDuplicating || isReactivating) && !watchers.splitCapping && !watchers.splitExpireAfter && !watchers.splitMaxCashback && !watchers.splitMinEligibleInvoice
    ) {
      setValue("promo_merchant_visibility", PromoMerchantVisibility.masked);
      if (
        allEq("store_fund_percentage", watchers.stores) &&
        allEq("masked_value", watchers.stores) &&
        allEq("masked_promo_code", watchers.stores)
      ) {
        setPromocodeSplit(true);
        setValue("applySplitForAllParameterValue", {
          store_fund_percentage: {
            koinz: Number(
              Math.abs(100 - Number(watchers.stores[0].store_fund_percentage))
            ),
            brand: Number(watchers.stores[0].store_fund_percentage),
          },
          masked_value: watchers.stores[0].masked_value
            ? Number(watchers.stores[0].masked_value) <= 1
              ? Number(watchers.stores[0].masked_value) * 100
              : Number(watchers.stores[0].masked_value)
            : 0,
          masked_promo_code: watchers.stores[0].masked_promo_code,
        });
      } else {
        setValue("applySplitForAllParameterValue", undefined);
        setPromocodeSplit(false);
      }
    }
  }, [
    watchers.stores,
    masked,
    isEditing,
    isDuplicating,
    isReactivating,
    watchers.splitCapping,
    watchers.splitExpireAfter,
    watchers.splitMaxCashback,
    watchers.splitMinEligibleInvoice
  ]);
  /* SPLITTTTTTTTT */
  useDeepCompareEffect(() => {
    if (
      watchers.stores?.length > 1 && 
      !masked &&
      (isEditing || isDuplicating || isReactivating) && !watchers.splitCapping && !watchers.splitExpireAfter && !watchers.splitMaxCashback && !watchers.splitMinEligibleInvoice
    ) {
      if (allEq("store_fund_percentage", watchers.stores)) {
        setPromocodeSplit(true);
        setValue("applySplitForAllParameterValue", {
          store_fund_percentage: {
            koinz: Number(
              Math.abs(100 - Number(watchers.stores[0].store_fund_percentage))
            ),
            brand: Number(watchers.stores[0].store_fund_percentage),
          },
        });
      } else {
        setValue("applySplitForAllParameterValue", undefined);
        setPromocodeSplit(false);
      }
    }
  }, [
    watchers.stores,
    masked,
    isEditing,
    isDuplicating,
    isReactivating,
    watchers.splitCapping,
    watchers.splitExpireAfter,
    watchers.splitMaxCashback,
    watchers.splitMinEligibleInvoice
  ]);
  useDeepCompareEffect(() => {
    if (watchers.stores?.length > 1 && 
       (isEditing || isDuplicating || isReactivating) && !watchers.splitMerchant && !watchers.splitMinEligibleInvoice) {
      if (
        allEq("max_discount_value", watchers.stores) &&
        watchers.promo_merchant_visibility !== PromoMerchantVisibility.masked
      ) {
        setApplyCapping(true);
        setValue("applyCappingForAllBrands", {
          max_discount_value: watchers.stores[0].max_discount_value
            ? Number(watchers.stores[0].max_discount_value)
            : 0,
        });
      } else {
        setValue("applyCappingForAllBrands", undefined);
        setApplyCapping(false);
      }
    }
  }, [
    watchers.stores,
    watchers.promo_merchant_visibility,
    isEditing,
    isDuplicating,
    isReactivating,
    watchers.splitMinEligibleInvoice,
    watchers.splitMerchant
  ]);

  useDeepCompareEffect(() => {
    if (watchers.stores?.length > 1 && 
      (isEditing || isDuplicating || isReactivating) && !watchers.splitMerchant && !watchers.splitMaxCashback && !watchers.splitExpireAfter && !watchers.applyCapping) {
      if (allEq("min_threshold", watchers.stores)) {
        
        setApplyMinEligibleInvoice(true);
        setValue("applyMinEligibleInvoiceForAllBrands", {
          min_threshold: watchers.stores[0]?.min_threshold
            ? Number(watchers.stores[0].min_threshold)
            : 0,
        });
      } else {
        setValue("applyMinEligibleInvoiceForAllBrands", undefined);
        setApplyMinEligibleInvoice(false);
      }
    }
  }, [
    watchers.stores,
    isEditing,
    isDuplicating,
    isReactivating,
    watchers.splitMerchant,
    watchers.splitExpireAfter,
    watchers.splitMaxCashback,
    watchers.applyCapping
  ]);
  useDeepCompareEffect(() => {
    if (watchers.stores?.length > 1 && 
      (isEditing || isDuplicating || isReactivating) && !watchers.splitMerchant && !watchers.splitMaxCashback && !watchers.splitMinEligibleInvoice) {
      if (allEq("expires_after", watchers.stores)) {
        setApplyExpiresAfter(true);
        setValue("applyExpiresAfterValueForAllBrands", {
          expires_after: watchers.stores[0].expires_after
            ? Number(watchers.stores[0].expires_after)
            : 72,
        });
      } else {
        setValue("applyExpiresAfterValueForAllBrands", undefined);
        setApplyExpiresAfter(false);
      }
    }
  }, [
    watchers.stores,
    isEditing,
    isDuplicating,
    isReactivating,
    watchers.splitMaxCashback,
    watchers.splitMerchant,
    watchers.splitMinEligibleInvoice,
  ]);
  useDeepCompareEffect(() => {
    if (watchers.stores?.length > 1 && 
      (isEditing || isDuplicating || isReactivating) && !watchers.splitMerchant && !watchers.splitExpireAfter && !watchers.splitMinEligibleInvoice) {
      if (allEq("max_cashback_value", watchers.stores)) {
        setApplyMaxCashbackValue(true);
        setValue("applyMaxCashbackValueForAllBrands", {
          max_cashback_value: watchers.stores[0].max_cashback_value
            ? Number(watchers.stores[0].max_cashback_value)
            : 0,
        });
      } else {
        setValue("applyMaxCashbackValueForAllBrands", undefined);
        setApplyMaxCashbackValue(false);
      }
    }
  }, [
    watchers.stores,
    isEditing,
    isDuplicating,
    isReactivating,
    watchers.splitExpireAfter,
    watchers.splitMinEligibleInvoice,
    watchers.splitMerchant,
  ]);
  useDeepCompareEffect(() => {
    if (applyMinEligibleInvoice) {
      setValue(
        "min_threshold_values",
        setValuesById(
          watchers.stores_ids || [],
          watchers?.applyMinEligibleInvoiceForAllBrands?.min_threshold ?? 0
        )
      );
    } else {
      setValue("applyMinEligibleInvoiceForAllBrands", undefined);
      setApplyMinEligibleInvoice(false);
    }
  }, [
    applyMinEligibleInvoice,
    watchers.stores_ids,
    watchers?.applyMinEligibleInvoiceForAllBrands,
  ]);
  useDeepCompareEffect(() => {
    if (applyCapping) {
      setValue(
        "max_discount_values",
        setValuesById(
          watchers.stores_ids || [],
          watchers?.applyCappingForAllBrands?.max_discount_value ?? 0
        )
      );
    } else {
      setValue("applyCappingForAllBrands", undefined);
      setApplyCapping(false);
    }
  }, [
    applyCapping,
    setApplyCapping,
    watchers.stores_ids,
    watchers?.applyCappingForAllBrands,
  ]);
  // apply max cashback value for all stores
  useDeepCompareEffect(() => {
    if (applyMaxCashbackValue) {
      setValue(
        "max_cashback_values",
        setValuesById(
          watchers.stores_ids || [],
          watchers?.applyMaxCashbackValueForAllBrands?.max_cashback_value ?? 0
        )
      );
    } else {
      setValue("applyMaxCashbackValueForAllBrands", undefined);
      setApplyMaxCashbackValue(false);
    }
  }, [
    applyMaxCashbackValue,
    watchers.stores_ids,
    watchers?.applyMaxCashbackValueForAllBrands,
  ]);
  // apply expired after for all brands
  useDeepCompareEffect(() => {
    if (applyExipresAfter) {
      setValue(
        "expires_after_values",
        setValuesById(
          watchers.stores_ids || [],
          watchers?.applyExpiresAfterValueForAllBrands?.expires_after ?? 72
        )
      );
    } else {
      setValue("applyExpiresAfterValueForAllBrands", undefined);
      setApplyExpiresAfter(false);
    }
  }, [
    applyExipresAfter,
    watchers.stores_ids,
    watchers?.applyExpiresAfterValueForAllBrands,
  ]);
  // apply split for all stores
  useDeepCompareEffect(() => {
    if (promocodeSplit) {
      setValue(
        "stores_fund_percentage",
        setValuesById(
          watchers.stores_ids || [],
          watchers?.applySplitForAllParameterValue?.store_fund_percentage ?? 0
        )
      );
      if (masked) {
        setValue(
          "masked_values",
          setValuesById(
            watchers.stores_ids || [],
            watchers?.applySplitForAllParameterValue?.masked_value
          )
        );
        setValue(
          "masked_promo_codes",
          setValuesById(
            watchers.stores_ids || [],
            watchers?.applySplitForAllParameterValue?.masked_promo_code
          )
        );
      }
    } else {
      setValue("applySplitForAllParameterValue", undefined);
      setPromocodeSplit(false);
    }
  }, [
    promocodeSplit,
    watchers.stores_ids,
    watchers?.applySplitForAllParameterValue,
    masked,
  ]);

  const selectedStoresMadeForSplit = useDeepCompareMemo(() => {
    return watchers.stores_ids
      ? [
          ...countryStores,
          ...(isEditing || isReactivating ? storesOutOfCountryStores : []),
        ].filter((singleStore) => {
          return (
            Array.isArray(watchers.stores_ids) &&
            watchers.stores_ids.includes(singleStore.value)
          );
        })
      : [];
  }, [
    watchers.stores_ids,
    promocodeSplit,
    countryStores,
    isEditing,
    isReactivating,
    storesOutOfCountryStores,
  ]);
  return {
    handleDrawerClose,
    /* ordering type*/
    handleOrderingTypeChange,
    /*promo type */
    handleChangePromoType,
    /*promocode target*/
    promocodeTarget,
    handlePromocodeTargetChange,
    /*promocode split*/
    promocodeSplit,
    setPromocodeSplit,
    /*submitButtonDisabled */
    submitButtonDisabled,
    /*Masked Promocode */
    handleMaskedPromocode,
    handleExclusivePromo,
    /*set store values by id */
    storeValuesById,
    selectedStoresMadeForSplit,
    /*capping*/
    setApplyCapping,
    applyCapping,
    /*split min eligible invoice*/
    setApplyMinEligibleInvoice,
    applyMinEligibleInvoice,
    /*split max cashback values */
    applyMaxCashbackValue,
    setApplyMaxCashbackValue,
    /*split expired after for all stores */
    applyExipresAfter,
    setApplyExpiresAfter,
  };
};
