import Datetime from "react-datetime";
import * as React from "react";
import * as styles from "../styles.module.sass";
import IcoMoon from "react-icomoon";
import "react-datetime/css/react-datetime.css";
import moment, { Moment } from "moment";

interface IProps {
  label: string;
  className: any;
  disabled?: boolean;
  onlyTodayAndAfter?: boolean;
  value?: string;
  touched?: boolean;
  error?: any;
  name?: string;
  onChange?: (e) => void;
  onBlur?: (e) => void;
  timeFormat?: boolean;
}

export class DatePicker extends React.Component<IProps> {
  public render() {
    const onlyTodayAndAfter = (current: Moment) => {
      return current.isAfter(moment().startOf("day").subtract(1, "day"));
    };
    const {
      label,
      className,
      error,
      name,
      onChange,
      onBlur,
      touched,
      value,
    } = this.props;
    const isInvalid = touched && error;
    const isValid = touched && !error;

    return (
      <div
        className={
          className
            ? `${styles.fullWidthDate} ${
                typeof className === "string" ? className : ""
              }`
            : styles.datePickerWrapper
        }
      >
        <label htmlFor={name} className={styles.label}>
          <span>{label}</span>
          {touched && error && (
            <span className={styles.formError}>{error}</span>
          )}
        </label>
        <div
          className={`${styles.datePicker} ${isValid ? styles.valid : ""}${
            isInvalid ? styles.invalid : ""
          }`}
        >
          <Datetime
            onBlur={onBlur}
            className={` ${isValid ? styles.valid : ""} ${
              isInvalid ? styles.invalid : ""
            }`}
            disableOnClickOutside={true}
            inputProps={{
              readOnly: true,
              className: styles.dateInput,
              disabled: this.props.disabled,
            }}
            timeFormat={this.props.timeFormat}
            isValidDate={
              this.props.onlyTodayAndAfter ? onlyTodayAndAfter : undefined
            }
            value={value}
            defaultValue={"MM/DD/YYYY"}
            onChange={onChange}
          />
          <div className={styles.icon}>
            <IcoMoon icon="calendar" />
          </div>
        </div>
      </div>
    );
  }
}
