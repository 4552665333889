import { IPosEntity } from "../../pages/PosIntegration/types";
import axios from "../axios";
import { posEntitiesURL } from "../requests";
export const createBrandPosEntityApi = (
  token: string,
  payload: Pick<IPosEntity, "pos_vendor"> & {
    store_id: string;
    name?: string;
  }
) => {
  return axios.post(posEntitiesURL, payload, {
    baseURL: process.env.POS_ENDPOINT,
    headers: {
      token,
    },
  });
};
