import * as constants from "../../constants";
interface ICreatePromoCodeReduce {
  is_loading: boolean;
  err: string;
  is_success: boolean;
}

const InitialState: ICreatePromoCodeReduce = {
  is_loading: false,
  err: "",
  is_success: false,
};

const CreatePromoCodeReducer = (
  state = InitialState,
  action
): ICreatePromoCodeReduce => {
  switch (action.type) {
    case constants.createPromoCodeAction.requested:
      return {
        ...state,
        is_loading: true,
        is_success: false,
      };
    case constants.createPromoCodeAction.fulfilled:
      return {
        ...state,
        is_loading: false,
        is_success: true,
      };
    case constants.createPromoCodeAction.rejected:
      return {
        ...state,
        is_success: false,
        err: action.payload,
      };
    default:
      return state;
  }
};
export default CreatePromoCodeReducer;
