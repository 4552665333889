import * as React from "react";
import * as styles from "../styles.module.sass";

interface IProps {
  value: string;
  onChange: (e) => void;
}

const CustomSelect: React.FC<IProps> = (props) => {
  const { onChange, value } = props;
  const [active, setActive] = React.useState(value ?? "");

  const handleNewClick = () => {
    onChange("new_customers");
    setActive("new_customers");
  };
  const handleAllClick = () => {
    onChange("all_customers");
    setActive("all_customers");
  };
  const handleOrderingUsersClick = () => {
    onChange("ordering_customers");
    setActive("ordering_customers");
  };
  return (
    <ul className={styles.customSelectWrapper}>
      <li
        className={`${styles.allUsers} ${
          active === "all_customers" ? styles.active : styles.inactive
        }`}
        onClick={handleAllClick}
      >
        All users
      </li>
      <li
        className={`${styles.newUsers} ${
          active === "new_customers" ? styles.active : styles.inactive
        }`}
        onClick={handleNewClick}
      >
        New users only{" "}
      </li>
      <li
        className={`${styles.orderingUsers} ${
          active === "ordering_customers" ? styles.active : styles.inactive
        }`}
        onClick={handleOrderingUsersClick}
      >
        Ordering users
      </li>
    </ul>
  );
};

export default CustomSelect;
