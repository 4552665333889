import { Modal } from "@material-ui/core";
import React, { memo, useEffect } from "react";
import styles from "./styles.module.sass";
import CloseIcon from "../../../assets/close_icon.svg";
import { useSelect } from "../../../hooks/useSelect";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { deleteBrandPosEntity } from "../../../redux-store/actions";
export interface IDeletePosEntityModalProps {
  onClose: () => void;
  open: boolean;
  store_id: string;
  entity_id: string;
}
export const DeletePosEntityModal: React.FC<IDeletePosEntityModalProps> = ({
  onClose,
  open,
  store_id,
  entity_id,
}) => {
  const { status, brandPosEntitiesById } = useSelect(
    (state) => state.getBrandPosEntitiesReducer
  );
  const dispatch = useDispatch();

  const handleDeleteBrandPosVendor = () => {
    if (store_id && entity_id) {
      dispatch(deleteBrandPosEntity({ id: entity_id, store_id }));
    }
  };
  useEffect(() => {
    if (status === LoadingStatus.success) {
      onClose();
    }
  }, [status]);
  return (
    <Modal className={styles.wrapper} open={open} onClose={onClose}>
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.title}>Delete POS</span>
          <img onClick={onClose} className={styles.closeIcon} src={CloseIcon} />
        </div>
        <span className={styles.description}>
          Are you sure you want to delete this POS?
        </span>
        <div style={{ padding: "16px 24px 24px 24px" }}>
          <div className={styles.posInfo}>
            <span className={styles.posName}>
              {brandPosEntitiesById[entity_id]?.name}
            </span>
            <span className={styles.posVendor}>
              {brandPosEntitiesById[entity_id]?.pos_vendor}
            </span>
          </div>
        </div>

        <div className={styles.footer}>
          <button onClick={onClose} className={styles.keepBtn}>
            No, Keep it
          </button>
          <button
            onClick={handleDeleteBrandPosVendor}
            className={styles.deleteBtn}
          >
            {status === LoadingStatus.loading ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              "Yes, delete it"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(DeletePosEntityModal);
