import React, { memo, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.sass";
import "./styles.less";
import CopyIcon from "../../../assets/ic__copy.svg";
import EditIcon from "../../../assets/feather_edit.svg";
import TrashIcon from "../../../assets/ic_trash.svg";
import DeletePosEntityModal from "../DeletePosEntityModal";
import { Select } from "../../../Components/FormElements/Select";
import { PosStatusCardStyleKeys } from "../types";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import { ClipLoader, BeatLoader } from "react-spinners";
import copy from "copy-to-clipboard";
import { useBrandPosEntityHandlers } from "./hooks";
import { useDispatch } from "react-redux";
import {
  exportBrandPosMenuReset,
  getBrandPosLoyaltyIntegrationStatus,
  getBrandPosOrderingIntegrationStatus,
  setBrandPosEntity,
} from "../../../redux-store/actions";
import { Tooltip } from "antd";
import { useSelect } from "../../../hooks/useSelect";
import { IBrandPosEntity } from "../../../redux-store/reducers/getBrandPosEntitiesReducer";
import GenPaymentConfModal from "../GenPaymentConfModal";
interface IPosIntegrationCardProps {
  brandPosEntity: IBrandPosEntity;
  store_id: string;
  cancelChanges: () => void;
}
export const PosIntegrationCard: React.FC<IPosIntegrationCardProps> = ({
  brandPosEntity,
  store_id,
  cancelChanges,
}) => {
  const dispatch = useDispatch();
  const {
    brandPosEntityValues,
    posEntityInitialValue,
    posEntitiesOptions,
    handleSaveChanges,
    handlePosNameChange,
    handlePosEntityChange,
    handleBrandPosMenuExport,
    status,
    setBrandPosEntityValues,
  } = useBrandPosEntityHandlers({
    brandPosEntity,
    store_id,
  });
  const {
    getBrandPosEntitiesReducer: { loadingStatus },
  } = useSelect((state) => ({
    getBrandPosEntitiesReducer: state.getBrandPosEntitiesReducer,
    ordering_access_token:
      state.brandDetailsReducer.BRAND_DETAILS?.ordering_access_token,
  }));
  const [copyState, setCopyState] = useState(false);
  const [tooltipState, setTooltipState] = useState(false);
  const [openDeletePosModal, setOpenDeletePosModal] = useState(false);
  const [openGenPaymentMethodModal, setOpenGenPaymentMethodModal] = useState(
    false
  );
  const generatePaymentMethodModalRender = useMemo(
    () => (
      <GenPaymentConfModal
        open={openGenPaymentMethodModal}
        store_id={store_id}
        brandPosEntity={brandPosEntity}
        onClose={() => {
          setOpenGenPaymentMethodModal(false);
        }}
      />
    ),
    [store_id, brandPosEntity, openGenPaymentMethodModal]
  );

  const deletePosEntityModalRender = useMemo(
    () =>
      brandPosEntity.mode === "delete" && (
        <DeletePosEntityModal
          store_id={store_id}
          entity_id={brandPosEntity.id}
          onClose={() => {
            dispatch(
              setBrandPosEntity({ id: brandPosEntity.id, mode: undefined })
            );
            setOpenDeletePosModal(false);
          }}
          open={brandPosEntity.mode === "delete" && openDeletePosModal}
        />
      ),
    [brandPosEntity.mode, brandPosEntity.id, store_id, openDeletePosModal]
  );
  useEffect(() => {
    if (
      store_id &&
      brandPosEntity.id &&
      loadingStatus === LoadingStatus.success
    ) {
      dispatch(
        getBrandPosLoyaltyIntegrationStatus({
          store_id,
          pos_entity_id: brandPosEntity.id,
        })
      );
      dispatch(
        getBrandPosOrderingIntegrationStatus({
          store_id,
          pos_entity_id: brandPosEntity.id,
        })
      );
    }
  }, [store_id, brandPosEntity.id, loadingStatus]);

  useEffect(() => {
    if (
      brandPosEntity.JSONFileLoadingStatus === LoadingStatus.success &&
      brandPosEntity.id
    ) {
      dispatch(exportBrandPosMenuReset(brandPosEntity.id));
    }
  }, [brandPosEntity.JSONFileLoadingStatus, brandPosEntity.id]);
  const posStatusCardStyle: Record<PosStatusCardStyleKeys, string> = {
    Loyalty_Active: styles.posActive,
    Ordering_Active: styles.posActive,
    Loyalty_InActive: styles.posInactive,
    Ordering_InActive: styles.posInactive,
    Loyalty_Status_Unavailable: styles.posUnavailable,
    Ordering_Status_Unavailable: styles.posUnavailable,
  };

  return (
    <div
      className={`${
        brandPosEntity.mode && brandPosEntity.mode !== "delete"
          ? styles.mode
          : styles.wrapper
      }`}
    >
      {!brandPosEntity.mode && (
        <div className={styles.posStatusesWrapper}>
          {brandPosEntity?.loyalty_integration_status && (
            <span
              className={
                posStatusCardStyle[
                  brandPosEntity.loyalty_integration_status as string
                ]
              }
            >
              {brandPosEntity.loyalty_integration_status}
            </span>
          )}
          {brandPosEntity?.ordering_integration_status && (
            <span
              className={
                posStatusCardStyle[
                  brandPosEntity.ordering_integration_status as string
                ]
              }
            >
              {brandPosEntity.ordering_integration_status}
            </span>
          )}
        </div>
      )}
      <div className={styles.fieldsWrapper}>
        <div className={styles.field}>
          <span className={styles.label}>POS name</span>
          <input
            disabled={!brandPosEntity.mode}
            placeholder="Enter POS name"
            className={styles.input}
            type="text"
            name="name"
            onChange={handlePosNameChange}
            value={brandPosEntityValues.name}
          />
        </div>
        <div className={styles.field}>
          <Select
            disabled={!brandPosEntity.mode}
            label={"POS vendor"}
            options={posEntitiesOptions}
            initialValue={{
              value: posEntityInitialValue?.value,
              text: posEntityInitialValue?.label ?? "",
            }}
            placeholder="Select pos vendor"
            className={"pos-vendor"}
            onChange={handlePosEntityChange}
          />
        </div>
      </div>
      {deletePosEntityModalRender}
      {generatePaymentMethodModalRender}
      <div className={styles.apiKeyWrapper}>
        <span className={styles.label}>Api key</span>
        <div className={styles.apiKey}>
          <span
            className={styles.apiKeyText}
            style={brandPosEntity.mode ? { color: "#8E8E93" } : {}}
          >
            {brandPosEntity.api_key}
          </span>

          {brandPosEntity.api_key && !brandPosEntity.mode && (
            <Tooltip
              open={tooltipState}
              onOpenChange={() => {
                setTooltipState(!tooltipState);
                if (!tooltipState) {
                  setCopyState(false);
                }
              }}
              title={copyState ? "Copied" : "Copy"}
            >
              <img
                className={styles.copyIcon}
                onClick={() => {
                  copy(brandPosEntity.api_key);
                  setCopyState(true);
                }}
                src={CopyIcon}
              />
            </Tooltip>
          )}
        </div>
      </div>

      {brandPosEntity.mode && brandPosEntity.mode !== "delete" ? (
        <div className={styles.saveChangesWrapper}>
          <button
            onClick={() => {
              cancelChanges();
              setBrandPosEntityValues({
                name: brandPosEntity.name,
                pos_vendor: brandPosEntity.pos_vendor,
              });
              dispatch(
                setBrandPosEntity({ id: brandPosEntity.id, mode: undefined })
              );
            }}
            className={styles.cancelBtn}
          >
            Cancel
          </button>
          <button
            disabled={
              status === LoadingStatus.loading ||
              brandPosEntityValues.name === ""
            }
            onClick={handleSaveChanges}
            className={
              !brandPosEntityValues.name
                ? styles.disabledSaveChangesBtn
                : styles.saveChangesBtn
            }
          >
            {status === LoadingStatus.loading ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              "Save changes"
            )}
          </button>
        </div>
      ) : (
        <div className={styles.actionsWrapper}>
          <a id="downloadAnchorElem" style={{ display: "none" }} />
          {brandPosEntity.pos_vendor === "foodics" && (
            <React.Fragment>
              {brandPosEntity.JSONFileLoadingStatus ===
              LoadingStatus.loading ? (
                <div className={styles.exportLoadingIndicator}>
                  <BeatLoader size={16} color={"#FF9000"} />
                </div>
              ) : (
                <button
                  onClick={handleBrandPosMenuExport}
                  className={styles.button}
                >
                  Export Pos Menu
                </button>
              )}
              <div className={styles.separate} />
            </React.Fragment>
          )}

          <button
            onClick={() => {
              setOpenGenPaymentMethodModal(true);
            }}
            className={styles.button}
            disabled={brandPosEntity.loading_status === LoadingStatus.loading}
          >
            {!brandPosEntity.pos_online_payment_method_id
              ? "Generate Payment Method"
              : "Regenerate Payment Method"}
          </button>

          <div className={styles.separate} />
          <button
            onClick={() => {
              dispatch(
                setBrandPosEntity({
                  ...brandPosEntity,
                  mode: "edit",
                })
              );
            }}
            className={styles.button}
          >
            <img className={styles.icon} src={EditIcon} />
            Edit
          </button>
          <button
            onClick={() => {
              dispatch(
                setBrandPosEntity({
                  ...brandPosEntity,
                  mode: "delete",
                })
              );
              setOpenDeletePosModal(true);
            }}
            className={styles.button}
          >
            <img className={styles.icon} src={TrashIcon} />
            Delete
          </button>
          {brandPosEntity?.reference && !brandPosEntity.mode && (
            <span className={styles.account}>
              Account:{" "}
              <span className={styles.reference}>
                {brandPosEntity.reference}
              </span>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(PosIntegrationCard);