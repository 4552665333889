import React, { useMemo } from "react";
import styles from "./styles.module.sass";
import copyIcon from "../../../assets/ic_copy.svg";
import ic_refresh from "../../../assets/ic_refresh.svg";
import { useSelect } from "../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import {
  getPointsOfContacts,
  logDashboardPreviewRequest,
} from "../../../redux-store/actions";
import copy from "copy-to-clipboard";
import moment from "moment";
import { PocRoles } from "../../../constants";
import { notify } from "react-notify-toast";

const BrandPoc: React.FC = () => {
  const { selected_store_id, selectedBrandsById, token } = useSelect(
    (state) => ({
      ...state.actionCenterBrandsReducer,
      ...state.authReducer,
    })
  );
  const dispatch = useDispatch();
  const syncPOC = () =>
    dispatch(getPointsOfContacts({ store_id: selected_store_id }));

  const openDashboardPreview = () => {
    try {
      const url = new URL(`${process.env.DASHBOARD_FRONTEND_APP_URL}`);
      url.searchParams.append("token", token);
      url.searchParams.append("store_id", selected_store_id);
      url.searchParams.append("is_remote_authentication", "true");
      url.searchParams.append("email", "Admin");
      url.searchParams.append("role", "account_manager");
      return `${url.origin}/#/preview/auth${url.search}`;
    } catch (e) {
      // throw error
      notify.show("Invalid dashboard URL", "error");
      return "";
    }
  };
  const recordDashboardPreviewRequest = () => {
    dispatch(logDashboardPreviewRequest({ store_id: selected_store_id }));
  };
  const isOutdated = useMemo(
    () =>
      selectedBrandsById[selected_store_id].points_of_contacts.some(
        (poc) => poc.is_out_dated
      ),
    []
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContact}>
          <span className={styles.title}>POC</span>
          {isOutdated && (
            <a
              target="_blank"
              onClick={recordDashboardPreviewRequest}
              href={openDashboardPreview()}
              className={styles.updatePocBtn}
            >
              Update
            </a>
          )}
        </div>
        <img className={styles.ic_refresh} src={ic_refresh} onClick={syncPOC} />
      </div>
      <div className={styles.pocsWrapper}>
        {selectedBrandsById[selected_store_id].points_of_contacts.map((poc) => (
          <div key={poc.id} className={styles.pocDetailsWrapper}>
            <div className={styles.pocContentWrapper}>
              <div className={styles.pocNameAndTitle}>
                <span className={styles.pocName}>{poc.name ?? "Manager"}</span>
                <React.Fragment>
                  <div className={styles.separator} />
                  <span className={styles.pocName} style={{ color: "#65656C" }}>
                    {PocRoles[poc.role] ?? "Manager"}
                  </span>
                </React.Fragment>
              </div>
              <div
                className={styles.pocNameAndTitle}
                style={{ marginTop: "12px" }}
              >
                {poc.phone_number && (
                  <React.Fragment>
                    <img
                      onClick={() => {
                        copy(poc.phone_number);
                      }}
                      className={styles.copyIcon}
                      src={copyIcon}
                    />
                    <span
                      className={styles.pocName}
                      style={{ color: "#65656C", marginRight: "13.33px" }}
                    >
                      {poc.phone_number}
                    </span>
                  </React.Fragment>
                )}
                {poc.email && (
                  <React.Fragment>
                    <img
                      onClick={() => {
                        copy(poc.email);
                      }}
                      className={styles.copyIcon}
                      src={copyIcon}
                    />
                    <span
                      className={styles.pocName}
                      style={{ color: "#65656C" }}
                    >
                      {poc.email}
                    </span>
                  </React.Fragment>
                )}
              </div>
            </div>
            <span className={styles.updateAt}>
              Updated {moment(poc.updated_at).fromNow()}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandPoc;
