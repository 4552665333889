import axios from "axios";
import * as Requests from "../requests";

export const getActiveMerchantDealsApi = async (token, store_id) => {
  const { data } = await axios.get(
    Requests.getActiveMerchantDealsURL(store_id),
    {
      headers: { token },
      baseURL: process.env.LUMEN_ENDPOINT,
    }
  );
  return data;
};
