import { takeLatest, retry, put, select } from "redux-saga/effects";
import {
  getAnnouncements,
  getAnnouncementsFailure,
  getAnnouncementsSuccess,
} from "../actions/announcementsActions";
import { selectToken } from "../../../../redux-store/selectors";
import { getAnnouncementsAPI } from "../../axios/getAnnouncements";

function* getAnnouncementsSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield retry(5, 5000, getAnnouncementsAPI, token);
    yield put(getAnnouncementsSuccess(res.data));
  } catch {
    yield put(getAnnouncementsFailure());
  }
}
export function* watchGetAnnouncementsSaga() {
  yield takeLatest(getAnnouncements.type, getAnnouncementsSaga);
}
