import React, { useEffect, useMemo, useState } from "react";
import * as styles from "./styles.module.sass";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { GenericButtonWithoutIcon } from "../GenericButtonWithoutIcon";
import SingleChip from "./Chip";
import { ISelectItemData } from "src/Components/FormElements/RenderDropDownSelect";

interface ISelectedValue {
  label: string;
  value: string;
}
interface IProps {
  disabled?: boolean;
  label: string;
  className: string;
  options: any[];
  input: {
    [key: string]: any;
  };
  meta: {
    [key: string]: any;
  };
  defaultLabel: string;
  onlyOne?: boolean;
  initialValue?: ISelectItemData;
  countryISOCode?: string;
  alreadySetValue?: ISelectedValue[];
  selectedStoresMap: {
    [key: string]: {
      label: string;
      value: string;
    };
  };
}

interface IState {
  autoCompleteTextInput: string;
  currentSelectedElement: {
    label: string;
    value: string | string[];
  };
}

const RenderDropDownSelectWithSearch: React.FC<IProps> = (props) => {
  const {
    input,
    label,
    className,
    meta: { touched, error, warning },
    options,
    onlyOne,
    alreadySetValue,
    selectedStoresMap,
    disabled,
  } = props;

  const [state, setState] = useState<IState>({
    autoCompleteTextInput: "",
    currentSelectedElement: {
      label: "",
      value: "",
    },
  });

  const { autoCompleteTextInput, currentSelectedElement } = state;

  // setting values preset values in case of editing prmocode
  useEffect(() => {
    if (alreadySetValue && input && input.onChange) {
      input.onChange([...alreadySetValue]);
    }
  }, []);

  // handling auto complete text input change
  const onAutoCompleteTextChange = (event, value) => {
    setState((prevState) => ({ ...prevState, autoCompleteTextInput: value }));
  };

  // options list
  const optionsLabels = useMemo(() => {
    return options && Array.isArray(options)
      ? [...options.map((option) => option.label).filter(Boolean)]
      : [];
  }, [options]);

  // handling selecting a value
  const onSelectInputChange = (event, value) => {
    const selectedStore = options.filter(
      (element) => element.label === value
    )[0];
    // if 'onlyOne' => component is used by push notifications screen
    // --> or another screen that doesn't support multiple selection values
    // --> then we need to handle input.onChange here instead of handling it
    // --> inside 'onAdd' method
    if (onlyOne) {
      input.onChange(selectedStore?.value ? [selectedStore.value] : "");
    }
    setState((prevState) => ({
      ...prevState,
      currentSelectedElement: selectedStore
        ? {
            label: selectedStore.label,
            value: selectedStore.value,
          }
        : {
            label: "",
            value: "",
          },
    }));
  };

  // handling add button click
  const onAddButtonClick = () => {
    // if value is value of 'SelectAll' option (Array of Strings)
    if (currentSelectedElement && Array.isArray(currentSelectedElement.value)) {
      input.onChange([
        ...new Set([
          ...Object.keys(selectedStoresMap),
          ...currentSelectedElement.value,
        ]),
      ]);
    } else {
      // if value is a value of a single store (string)
      input.onChange([
        ...new Set([
          ...Object.keys(selectedStoresMap),
          currentSelectedElement.value,
        ]),
      ]);
    }

    setState((prevState) => ({
      ...prevState,
      currentSelectedElement: { label: "", value: "" },
    }));
  };

  // rendering selected stores chips
  const mapSelectionValuesIntoChips = () => {
    return Object.keys(selectedStoresMap).map((element) => (
      <SingleChip
        disabled={disabled}
        clicked={onDeleteStore}
        chip={selectedStoresMap[element]}
        key={selectedStoresMap[element].value}
      />
    ));
  };

  // handle store selection deletion
  const onDeleteStore = (labelValue: string, valueOfDeletionItem: string) => {
    const selectedStoresArrCopy = [...Object.keys(selectedStoresMap)];
    const filteredSelectedStores = selectedStoresArrCopy.filter((element) => {
      return element !== valueOfDeletionItem;
    });
    input.onChange(filteredSelectedStores);
  };

  const renderAutoCompleteTextInput = (params) => (
    <TextField
      {...params}
      placeholder="Select targeted brand"
      className={className}
      variant="outlined"
      fullWidth={true}
      disabled={disabled}
    />
  );

  const renderOptionNodes = (option, optionState) => (
    <div className={styles.item}>{option}</div>
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div className={className ? styles.fullWidthDrop : styles.dropDownPart}>
          <label htmlFor={input.name} className={styles.label}>
            <span>{label}</span>
            {touched &&
              ((error && <span className={styles.formError}>{error}</span>) ||
                (warning && <span>{warning}</span>))}
          </label>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyItems: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Autocomplete
              value={currentSelectedElement?.label}
              onChange={onSelectInputChange}
              inputValue={autoCompleteTextInput}
              onInputChange={onAutoCompleteTextChange}
              id={input.name}
              options={optionsLabels}
              renderInput={renderAutoCompleteTextInput}
              className={className}
              renderOption={renderOptionNodes}
              style={{ width: "100%" }}
              blurOnSelect={true}
              disabled={disabled}
            />
            {onlyOne || (
              <GenericButtonWithoutIcon
                className={disabled ? styles.addDimmedBtn : styles.addBtn}
                onClick={onAddButtonClick}
                label={"Add"}
                disabled={!currentSelectedElement.value}
              />
            )}
          </div>
        </div>
      </div>
      {onlyOne || (
        <div className={styles.chipsWrapper}>
          {mapSelectionValuesIntoChips()}
        </div>
      )}
    </>
  );
};

export default RenderDropDownSelectWithSearch;
