import axios from "./axios";
import * as Requests from "./requests";

export const deleteWatcherAPI = (token, id) => {
  return axios.delete(Requests.getWatchers + `/${id}`, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
