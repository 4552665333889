import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  deleteCategory,
  deleteCategoryFailure,
  deleteCategorySuccess,
} from "../actions/categoriesAction";
import { deleteCategoryAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { deleteCategoryAPI } from "../../axios/deleteCategory";
import { customNotify } from "../../../../UIComponents/customNotification/customNotification";

const actionType = union({ deleteCategory });

function* deleteCategorySaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  // console.log("Before i sent the data the id is :", payload.id);
  const res = yield call(deleteCategoryAPI, token, payload.id);
  res.status === 200
    ? customNotify.success(
        `[${payload.name_en}-${payload.name_ar}] deleted successfully`
      )
    : customNotify.error("There is something wrong");
  return payload;
}

export function* watchDeleteCategorySaga() {
  yield takeLatest(
    deleteCategoryAction.requested,
    handleOptimism(deleteCategorySaga, {
      success: deleteCategorySuccess,
      failure: deleteCategoryFailure,
    })
  );
}
