import * as actions from "../../constants";
import {
  ILoginAction,
  ILoginFailureAction,
  ILoginResponse,
  ILoginSuccessAction,
  ILogoutAction,
} from "../../types";

export const login = (payload: ILoginAction["payload"]): ILoginAction => ({
  type: actions.loginAction.requested,
  payload,
});

export const loginSuccess = (data: ILoginResponse): ILoginSuccessAction => ({
  type: actions.loginAction.fulfilled,
  payload: data,
});

export const loginFailure = (error: Error): ILoginFailureAction => ({
  type: actions.loginAction.rejected,
  payload: error,
});

export const logout = (): ILogoutAction => ({
  type: actions.logoutAction.requested,
});

export const logoutSuccess = (): ILogoutAction => ({
  type: actions.logoutAction.fulfilled,
});
