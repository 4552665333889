import React, { useMemo } from "react";
import * as styles from "./styles.module.sass";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ic_back from "../../assets/ic_back.svg";

const Crump = ({ title, subtitle }) => (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    <span
      style={
        subtitle
          ? { fontSize: "16px", color: "#9b9b9b", fontWeight: 500 }
          : {
              fontSize: "16px",
              color: "#2d2d2d",
              fontWeight: 900,
            }
      }
    >
      {title}
    </span>
    {subtitle && (
      <React.Fragment>
        <img
          style={{
            width: "11px",
            height: "11px",
            marginLeft: "8px",
          }}
          src={require("../../assets/feather _ chevron-right.svg")}
        />
        <span
          style={{
            fontSize: "16px",
            color: "#2d2d2d",
            fontWeight: 900,
            marginLeft: "8px",
          }}
        >
          {subtitle}
        </span>
      </React.Fragment>
    )}
  </div>
);
export const Header: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const store_id = history.location.pathname.split("/")[2];
  const crumps: Record<string, JSX.Element> = useMemo(
    () => ({
      "/account-managers/activity-log": (
        <span className={styles.title}>Activity log</span>
      ),
      "/account-managers/action-center": (
        <span className={styles.title}>Action Center</span>
      ),
      "/account-managers/action-center/take-action": (
        <div className={styles.takeActionWrapper}>
          <img
            onClick={() => history.goBack()}
            className={styles.icon}
            src={ic_back}
          />
          <span className={styles.title}>Take action</span>
        </div>
      ),
      "/reach/campaigns": <Crump title={"Reach"} subtitle={"Campaigns"} />,
      "/reach/payment": <Crump title={"Reach"} subtitle={"Payment"} />,
      "/": <Crump title={"Brands"} subtitle={""} />,
      "/watch-tower": <Crump title={"Watch Tower"} subtitle={""} />,
      "/shopx-settings": <Crump title={"Settings"} subtitle={""} />,
      "/targeting/promo-codes": (
        <Crump title={"Targeting"} subtitle={"Promo codes"} />
      ),
      "/reach/sms": <Crump title={"Targeting"} subtitle={"SMS"} />,
      "/reach/push-notifications": (
        <Crump title={"Targeting"} subtitle={"Push Notifications"} />
      ),
      "/targeting/categories": (
        <Crump title={"Targeting"} subtitle={"Categories"} />
      ),
      "/targeting/banner-cards": (
        <Crump title={"Targeting"} subtitle={"Banner Cards"} />
      ),
      "/targeting/promoted-brands": (
        <Crump title={"Targeting"} subtitle={"Promoted Brands"} />
      ),
      "/targeting/announcements": (
        <Crump title={"Targeting"} subtitle={"Popup Announcements"} />
      ),
      "/targeting/tags": <Crump title={"Targeting"} subtitle={"Tags"} />,
    }),
    [location.pathname]
  );

  return (
    <header className={styles.header}>
      {crumps[location.pathname]}
      {location.pathname === `/profile/${store_id}/pos-integration` && (
        <Crump title={"Pos integration"} subtitle={""} />
      )}
      <div className={styles.user}>
        <img
          src={require("../../assets/pasqua.jpg")}
          className={styles.userImg}
        />
        <span className={styles.userName}>Admin</span>
      </div>
    </header>
  );
};

export default withRouter(Header);
