import * as Requests from "./requests";
import axios from "./axios";

export interface ISendSmsMessageType {
  title: string;
  message: string;
  date: any;
}

export const sendSmsMessageAPI = (token: string, data: FormData) => {
  return axios.post(Requests.sendSmsURL, data, {
    headers: {
      token,
    },
    baseURL: process.env.NOTIFICATIONS_ENDPOINT,
  });
};
