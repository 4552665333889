import React, { useState } from 'react'
import { SystemAnnouncementTargeting, IGetBannerCardItem } from '../../types'
import * as styles from './styles.module.sass'
import { IDndIntegrationProps } from '../../SortableDnDList'
import { confirmDanger } from '../lib/confirmDanger'
import { useDispatch } from 'react-redux'
import { deleteBannerCard } from '../../redux-store/actions/bannerCardsActions'
import { useProcessImageResource } from '../lib/useProcessImageResource'
import ReactTooltip from 'react-tooltip'
import copy from 'copy-to-clipboard'

interface IProps {
  dndIntegration: IDndIntegrationProps
  cardData: IGetBannerCardItem
}

export const BannerCardItem: React.FC<IProps> = ({
  cardData,
  dndIntegration: { connectDragPreview, connectDragSource, connectDropTarget }
}) => {
  const dispatch = useDispatch()
  const handleDeleteItem = React.useCallback(async () => {
    const result = await confirmDanger(
      'Are you sure you want to delete this card?'
    )
    if (result.value) {
      dispatch(deleteBannerCard(cardData))
    }
  }, [cardData.id])
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  connectDragSource(connectDragPreview(connectDropTarget(wrapperRef)))
  const bannerCardImage = useProcessImageResource(cardData.image)
  const targetUsersCount = React.useMemo(() => {
    if (cardData.visibility === SystemAnnouncementTargeting.private) {
      return cardData.target_users || '-'
    }
    return 'Universal'
  }, [cardData.target_users])
  const [state, setState] = useState("")
  const copyBannerCardID = React.useCallback(() => {
    copy(cardData.id)
    setState(cardData.id);
  }, [cardData.id])
  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <img src={bannerCardImage} className={styles.bannerImage} />
      <div className={styles.targetedUsers}>
        <div className={styles.titleNumbers}>
          <div className={styles.title}>Targeted users</div>
          <div className={styles.numbers}>{targetUsersCount}</div>
        </div>
        <div className={styles.btnsWrapper}>
          <img
            data-tip={true}
            data-for={`id${cardData.id}`}
            className={styles.icon}
            onClick={copyBannerCardID}
            src={require('../../../../assets/copy.svg')}
          />
          <ReactTooltip
            id={`id${cardData.id}`}
            place={'left'}
            className={styles.tooltipWrapper}
          >
            <div>{state.trim() ? 'Copied!' : 'Copy'}</div>
          </ReactTooltip>
          <span onClick={handleDeleteItem} className='icon-trash'>
            <span className='path1' />
            <span className='path2' />
            <span className='path3' />
          </span>
        </div>
      </div>
    </div>
  )
}
