import * as React from "react";
import * as styles from "./styles.module.sass";
import { connect } from "react-redux";
import { IRootReducerState } from "../../../redux-store/rootReducer";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getCampaignBranches } from "../../../axios/get-campaign-branches";
import { LoadingPageInfo } from "../../LoadingPageInfo";
import { Aux } from "../../../App";

interface IState {
  branches: Array<{ name: string }>;
  loaded: boolean;
  failed: boolean;
}

interface IProps {
  isOpen: boolean;
  token: string;
  toggle: () => void;
  campaignId: string;
}

class BranchesModal extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      branches: [],
      loaded: false,
      failed: false,
    };
  }

  public componentDidMount() {
    const { token, campaignId } = this.props;
    getCampaignBranches(token, campaignId)
      .then(({ data }) => {
        this.setState({
          branches: data.branches.sort((a, b) => a.name.localeCompare(b.name)),
          loaded: true,
        });
      })
      .catch(() => this.setState({ failed: true }));
  }

  public render() {
    const { isOpen, toggle } = this.props;
    const { failed, loaded } = this.state;
    let render = <LoadingPageInfo />;
    if (loaded) {
      render = (
        <Aux>
          {this.state.branches.map((branch) => (
            <div key={branch.name} className={styles.chip}>
              {branch.name}
            </div>
          ))}
        </Aux>
      );
    }
    if (failed) {
      render = (
        <div style={{ margin: "auto", fontSize: "2rem" }}>
          Failed to load branches!
        </div>
      );
    }
    return (
      <Modal isOpen={isOpen} size={"lg"} toggle={toggle}>
        <ModalHeader toggle={toggle} className={styles.modalHeader}>
          Campaign Branches
        </ModalHeader>
        <ModalBody className={styles.modalBody}>{render}</ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  token: state.authReducer.token,
});

export default connect(mapStateToProps, () => ({}))(BranchesModal);
