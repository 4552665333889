import { call, put, select, takeLatest } from "redux-saga/effects";
import { getCashbackDetailsApi } from "../../axios";
import { union } from "ts-action";
import { getCashbackDetailsAction } from "../../constants";
import {
  getCashbackDetails,
  getCashbackDetailsFailure,
  getCashbackDetailsSuccess,
} from "../actions";
import { selectToken } from "../selectors";
const actionTypes = union({ getCashbackDetails });
function* getCashbackDetailsSaga({
  payload: { store_id },
}: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getCashbackDetailsApi, token, store_id);
    yield put(
      getCashbackDetailsSuccess({
        cashback_details: res.data
          ? {
              ...res.data,
              cashback_koinz_share: Math.abs(
                100 - Number(res.data.cashback_merchant_share)
              ),
            }
          : undefined,
      })
    );
  } catch (error) {
    yield put(getCashbackDetailsFailure(error));
  }
}

export function* watchGetCashbackDetailsSaga() {
  yield takeLatest(getCashbackDetailsAction.requested, getCashbackDetailsSaga);
}
