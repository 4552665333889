import { IBrandTasks } from "../../types";

export const mapStoresById = (stores: IBrandTasks[]) => {
  return stores.reduce((acc, next) => {
    return {
      ...acc,
      [next.store_id]: next,
    };
  }, {} as Record<string, IBrandTasks>);
};
