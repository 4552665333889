import { action, payload } from "ts-action";
import { getSMSCampaignsAction } from "./constants";
import { smsStatus } from "../../SMS/SMSTable";

export interface ISMSCampaigns {
  sms_campaigns: ISMSCampaign[];
  total_sms_campaigns: number;
}

export interface ISMSCampaign {
  total_users_count: number;
  sent_users_count: number;
  status: smsStatus;
  created_at: number;
  scheduled_at: number;
  id: string;
  message: string;
}
export const getSMSCampaigns = action(
  getSMSCampaignsAction.requested,
  payload<{ page: number; per_page: number }>()
);

export const getSMSCampaignsSuccess = action(
  getSMSCampaignsAction.fulfilled,
  payload<ISMSCampaigns>()
);

export const getSMSCampaignsFailure = action(
  getSMSCampaignsAction.rejected,
  payload<Error>()
);
