import axios from "./axios";
import * as Requests from "./requests";

export const setUXCamStatus = (token: string, status: 0 | 1) => {
  return axios.put<{}>(
    Requests.changeUXCamStatus,
    { status },
    {
      baseURL: process.env.LUMEN_ENDPOINT,
      headers: {
        token,
      },
    }
  );
};
