import React, { useEffect } from "react";
import styles from "./styles.module.sass";
import Item from "./components/Item";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { getPromotedBrands } from "../../../../../redux-store/actions/promotedBrandsAction";
const BrandsTable: React.FC = () => {
  const dispatch = useDispatch();
  const {
    brands: { isLoading, brandsIds, brandsById },
  } = useSelector((state: any) => ({ brands: state.promotedBrandsReducer }));
  useEffect(() => {
    dispatch(getPromotedBrands());
  }, []);
  return (
    <div className={styles.tableWrapper}>
      {/* header */}
      <div className={styles.tableRow}>
        <div className={styles.brandInfo}>
          <p className={`${styles.tableHeader}`}>Brand Info</p>
        </div>
        <div className={styles.dateAdded}>
          <p className={`${styles.tableHeader}`}>Date Added</p>
        </div>
        <div className={styles.actions}>
          <p className={`${styles.tableHeader}`}>Actions</p>
        </div>
      </div>
      {!brandsIds.length && isLoading === "loading" && (
        <div className={styles.loaderWrapper}>
          <ClipLoader color=" #f09440" size={50} />
        </div>
      )}

      {!brandsIds.length && isLoading === "success" ? (
        <div className={styles.tableNotFoundWrapper}>
          <img src={require("../../../../../assets/alert.svg")} />
          <span>Brands not found</span>
        </div>
      ) : !brandsIds.length && isLoading === "error" ? (
        <div className={styles.tableNotFoundWrapper}>
          {" "}
          <img src={require("../../../../../assets/alert.svg")} />
          <span>Something went wrong!</span>
        </div>
      ) : (
        <>
          {Object.values(brandsById).map((brand: any, index: number) => (
            <Item key={brand.id} brand={brand} />
          ))}
        </>
      )}
      {/* content */}
    </div>
  );
};

export default BrandsTable;
