import axios from "./axios";
import * as Requests from "./requests";

export const getHighestRankApi = async (token: string) => {
  const { data } = await axios.get(Requests.getHighestRankURL, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
  return data;
};
