import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.sass";
import ic_store from "../../../assets/ic_store.svg";
import "../styles.less";
import BranchCollapseHeader from "../BranchCollapseHeader";
import { useSelect } from "../../../hooks/useSelect";
import { CheckIcon } from "../../../assets/components";
import { useDispatch } from "react-redux";
import {
  getPointsOfContacts,
  selectStoreIdAction,
  setSelectedTasksAction,
} from "../../../redux-store/actions";
import { actionCenterAlerts } from "../../../constants";
import moment from "moment";

export const Branches = () => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const {
    selectedBrandsTasksIds,
    selectedBrandsById,
    selected_store_id,
  } = useSelect((state) => ({
    ...state.actionCenterBrandsReducer,
  }));
  useEffect(() => {
    const selected_store_id_index = selectedBrandsTasksIds.findIndex(
      (element) => element === selected_store_id
    );
    if (selected_store_id) {
      setActiveKey([selected_store_id_index.toString()]);
    }
  }, [selected_store_id]);

  const handleExpand = (index: number) => {
    const expandedKeys = [...activeKey];
    if (expandedKeys.includes(index.toString())) {
      setActiveKey(expandedKeys.filter((key) => key !== index.toString()));
    } else {
      setActiveKey([...expandedKeys, index.toString()]);
    }
  };
  const handleForceExpand = (index: number, brandId: string) => {
    if (activeKey.includes(index.toString()) && activeKey.length === 1) {
      setActiveKey([]);
      dispatch(selectStoreIdAction(""));
    } else {
      setActiveKey([index.toString()]);
      dispatch(selectStoreIdAction(brandId));
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerIcon}>
          <img className={styles.storeIcon} src={ic_store} />
        </div>
        <span className={styles.headerTitle}>Branches</span>
      </div>

      {selectedBrandsTasksIds.length > 1 && (
        <BranchCollapseHeader
          expanded={false}
          title={"Resolve All"}
          resolveAll={true}
          onExpand={() => {
            setActiveKey([]);
            dispatch(selectStoreIdAction(""));
          }}
          className={styles.branchCollapseHeader}
          style={
            !!activeKey.length
              ? { borderLeft: "none", backgroundColor: "#ffff" }
              : { borderLeft: "3px solid #8E82BF", backgroundColor: "#F9F9FB" }
          }
        />
      )}
      <Collapse activeKey={activeKey} bordered={false}>
        {selectedBrandsTasksIds.map((brandId, index) => {
          const allTasksSelected = selectedBrandsById[brandId].tasks
            .filter((task) => task.page === "take-action")
            .every((task) => task.selected);
          return (
            <Collapse.Panel
              style={{
                background: activeKey?.includes(`${index}`)
                  ? "#F9F9FB"
                  : "#fff",
              }}
              showArrow={false}
              key={`${index}`}
              header={
                <BranchCollapseHeader
                  onForceExpand={() => {
                    handleForceExpand(index, brandId);
                    if (
                      !(selectedBrandsById[brandId].points_of_contacts || [])
                        .length
                    ) {
                      dispatch(getPointsOfContacts({ store_id: brandId }));
                    }
                  }}
                  onSelect={() => {
                    dispatch(
                      setSelectedTasksAction({
                        page: "take-action",
                        selected: !allTasksSelected,
                        store_id: brandId,
                      })
                    );
                  }}
                  resolveAll={false}
                  expanded={Boolean(activeKey?.includes(`${index}`))}
                  onExpand={() => {
                    handleExpand(index);
                  }}
                  selector={allTasksSelected}
                  title={selectedBrandsById[brandId].store_name}
                />
              }
              className={"ant-selected-branches"}
            >
              <div className={styles.tasksWrapper}>
                {selectedBrandsById[brandId].tasks
                  .filter((task) => task.page === "take-action")
                  .map((task) => (
                    <div className={styles.branchNameWrapper} key={task.id}>
                      <div
                        onClick={() => {
                          dispatch(
                            setSelectedTasksAction({
                              page: "take-action",
                              selected: !task.selected,
                              store_id: brandId,
                              task: { ...task, selected: !task.selected },
                            })
                          );
                          // handleExpand(index);
                        }}
                        style={{
                          backgroundColor: task.selected
                            ? "#1C1C1E"
                            : "#F2F2F7",
                        }}
                        className={styles.checkboxWrapper}
                      >
                        {task.selected && (
                          <CheckIcon
                            style={{ width: "12px", height: "12px" }}
                            stroke="#fff"
                          />
                        )}
                      </div>
                      <div className={styles.branchDetailsWrapper}>
                        <span className={styles.branchName}>
                          {task.branch_name}
                        </span>
                        <span className={styles.branchStatus}>
                          {task.alert_percentage}%{" "}
                          {actionCenterAlerts[task.alert_type]}.{" "}
                          {moment(task.drop_from).format("d MMM")} -{" "}
                          {moment(task.drop_to).format("d MMM")}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Branches;