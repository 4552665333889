import { call, put, select, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { assiginAccountManagerApi } from "../../../../axios/reassiginAccountManagerApi";
import { selectToken } from "../../../../redux-store/selectors";
import {
  assignAccountManager,
  assignAccountManagerFailure,
  assignAccountManagerSuccess,
} from "../actions/assignAccountManager";
import { notify } from "react-notify-toast";

import { assignAccountManagerAction } from "../actions/constants";
import { getAccountManager } from "../actions/getAccountManager";

const actionTypes = union({ assignAccountManager });
function* assignAccountManagerSaga({ payload }: typeof actionTypes) {
  try {
    const token: string = yield select(selectToken);
    yield call(assiginAccountManagerApi, token, payload.data);
    yield put(assignAccountManagerSuccess(payload.data.account_manager_id));
    yield put(getAccountManager({ data: { store_id: payload.data.store_id } }));
    notify.show(
      "Brand's account manager has been updated successfully",
      "success"
    );
  } catch (error) {
    notify.show(
      "something went wrong updating brand's account manager",
      "error"
    );
    yield put(assignAccountManagerFailure(error));
  }
}

export function* watchAssignAccountManagerSaga() {
  yield takeLatest(
    assignAccountManagerAction.requested,
    assignAccountManagerSaga
  );
}
