import { action, payload } from "ts-action";
import * as constants from "../../constants";
import { IUser } from "../../types";
import { IAddWatchers } from "../../types";
import { optAction, optReaction } from "./lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const getWatchers = action(
  constants.getWatchersAction.requested,
  payload
);
export const getWatchersSuccess = action(
  constants.getWatchersAction.fulfilled,
  payload<IUser[]>()
);
export const getWatchersFailure = action(
  constants.getWatchersAction.rejected,
  payload<Error>()
);

export const addWatchers = optAction(
  constants.addWatchersAction.requested,
  payload<IAddWatchers>()
);
export const adddWatchersSuccess = optReaction(
  constants.addWatchersAction.fulfilled,
  payload<IAddWatchers>(),
  COMMIT
);
export const addWatchersFailure = optReaction(
  constants.addWatchersAction.rejected,
  payload<Error>(),
  REVERT
);

export const deleteWatcher = action(
  constants.deleteWatcherAction.requested,
  payload<{ id: string }>()
);
export const deleteWatcherSuccess = action(
  constants.deleteWatcherAction.fulfilled,
  payload<{ id: string }>()
);
export const deleteWatcherFailure = action(
  constants.deleteWatcherAction.rejected,
  payload<Error>()
);
