import * as React from "react";
import * as styles from "./style.module.sass";
import SingleCategories from "./singleCategory";
import ViewCategoriesHeader from "./viewCategoriesHeader";
import { useDispatch } from "react-redux";
import { getCategories } from "../../redux-store/actions/categoriesAction";
import { useSelect } from "../../../../hooks/useSelect";

const ViewCategories: React.FC = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getCategories());
  }, []);
  const { categoriesById, categories } = useSelect(
    (state) => state.categoriesReducer
  );
  return (
    <div className={styles.wrapper}>
      <ViewCategoriesHeader totalCategories={categories.length} />
      <div className={styles.CategoriesWrapper}>
        {categories.length ? (
          categories.map((catItem) => (
            <SingleCategories
              key={categoriesById[catItem].id}
              Cat={categoriesById[catItem]}
            />
          ))
        ) : (
          <span className={styles.header}>There are no categories to show</span>
        )}
      </div>
    </div>
  );
};

export default ViewCategories;
