import * as React from "react";
import * as styles from "./style.module.sass";
import { confirmDanger } from "../../../BannerCards/lib/confirmDanger";
import { useDispatch } from "react-redux";
import { deleteCategory } from "../../../redux-store/actions/categoriesAction";
import { ICreateCategory } from "../../../types";
import CreateCategory from "../../CreateCategory";

interface IProps {
  Cat: ICreateCategory;
}
const SingleCategories: React.FC<IProps> = (props) => {
  const { Cat } = props;

  const [open, setOpen] = React.useState(false);
  const openModalHandler = () => setOpen(true);
  const closeModalHandler = () => setOpen(false);

  const dispatch = useDispatch();

  const handleDeleteItem = React.useCallback(async () => {
    const result = await confirmDanger(
      `Are you sure you want to delete ${Cat.name_en}`
    );
    if (result.value) {
      dispatch(deleteCategory(Cat));
    }
  }, [Cat.id]);
  const handelEditCategory = () => openModalHandler();
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <img src={require("../../../../../assets/food.svg")} />
        <span>{Cat.name_en}</span>
      </div>
      <div className={`${styles.controllers}`}>
        <div className={styles.editWrapper} onClick={handelEditCategory}>
          <img
            className={styles.editIcon}
            src={require("../../../../../assets/edit.svg")}
          />
        </div>
        <div className={styles.deleteWrapper} onClick={handleDeleteItem}>
          <img
            className={styles.deleteIcon}
            src={require("../../../../../assets/trash.svg")}
          />
        </div>
      </div>

      {open && (
        <CreateCategory
          is_create={false}
          initialValues={{ ...Cat }}
          open={open}
          id={Cat.id}
          onClose={closeModalHandler}
        />
      )}
    </div>
  );
};

export default SingleCategories;
