import { call, put, select, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import {
  editCommissionsSuccess,
  editCommissions,
  editCommissionsFailure,
} from "../actions/commissions";
import { editCommissionsApi } from "../../../../axios/editCommissions";
import { selectToken } from "../../../../redux-store/selectors";
import { editCommissionsAction } from "../actions/constants";
import { notify } from "react-notify-toast";
const actionType = union({ editCommissions });
function* editCommissionsSaga({
  payload: {
    commissions: {
      delivery_commission,
      pickup_commission,
      delivery_share,
      delivery_share_type,
    },
    id,
  },
}: typeof actionType) {
  const token = yield select(selectToken);
  try {
    const res = yield call(
      editCommissionsApi,
      token,
      id,
      pickup_commission,
      delivery_commission,
      delivery_share,
      delivery_share_type
    );
    yield put(editCommissionsSuccess(res.data));
    notify.show(
      "Delivery and Pickup commissions added successfully ",
      "success"
    );
  } catch (e) {
    yield put(editCommissionsFailure(e));
    notify.show("Something went wrong, please try again", "error");
  }
}
export function* watchEditCommissionsSaga() {
  yield takeLatest(editCommissionsAction.requested, editCommissionsSaga);
}
