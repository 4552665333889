import React from "react";
import SettingsSwitch from "../../../../../Components/ShopXSettings/SettingsSwitch";
import styles from "./styles.module.sass";
import InfoIcon from "../../../../../assets/info_icon.svg";
import ReactTooltip from "react-tooltip";

interface IProps {
  disabled?: boolean;
  checked: boolean;
  onChange: (value) => void;
  label: string;
  showInfoIcon?: boolean;
  tooltipText?: string;
}
export const MaskedPromocodeToggle: React.FC<IProps> = ({
  label,
  disabled,
  checked,
  onChange,
  showInfoIcon,
  tooltipText,
}) => {
  return (
    <div className={styles.main}>
      <div
        style={
          checked
            ? { backgroundColor: "#FFF4EA" }
            : { backgroundColor: "#F2F2F7" }
        }
        className={styles.wrapper}
      >
        <div className={styles.labelWrapper}>
          <span className={styles.maskedPromoTitle}>{label}</span>
          {showInfoIcon && (
            <img
              data-tip={true}
              data-for={`##${label}`}
              src={InfoIcon}
              alt=""
              className={styles.infoIcon}
            />
          )}
        </div>

        <ReactTooltip
          id={`##${label}`}
          place={"top"}
          className={styles.tooltipWrapper}
        >
          {tooltipText}
        </ReactTooltip>
        <SettingsSwitch
          disabled={disabled}
          onColor={"#f09440"}
          offColor={"#8E8E93"}
          height={32}
          width={56}
          handleDiameter={24}
          className={styles.switch}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default MaskedPromocodeToggle;
