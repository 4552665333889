import { required } from "../../../../redux-store/validators";

export enum RegularScreens {
  screen_tab_customer_order = "screen_tab_customer_order",
  feed_tab = "feed_tab",
  influencer_profile = "influencer_profile",
}

export enum StoreScreens {
  screen_tab_store_my_points = "screen_tab_store_my_points",
  screen_tab_store_menu_items = "screen_tab_store_menu_items",
  screen_dynamic_stores = "screen_dynamic_stores",
}

export const TARGET_SCREENS = [
  {
    label: "Opens ordering tab",
    value: RegularScreens.screen_tab_customer_order,
  },
  {
    label: "Opens map (requires store_id)",
    value: StoreScreens.screen_tab_store_my_points,
  },
  {
    label: "Opens menu (requires store_id)",
    value: StoreScreens.screen_tab_store_menu_items,
  },

  {
    label: "Dynamic stores screen",
    value: StoreScreens.screen_dynamic_stores,
  },
  {
    label: "Feed Tab",
    value: RegularScreens.feed_tab,
  },
  {
    label: "Influencer Profile (requires influencer_profile_id)",
    value: RegularScreens.influencer_profile,
  },
];

export const isScreenRequiresStoreId = (
  screen: StoreScreens | RegularScreens
) => {
  return screen in StoreScreens;
};

export const useValidateBrandRequired = (
  selectedScreen: StoreScreens | RegularScreens
) => {
  if (isScreenRequiresStoreId(selectedScreen)) {
    return [required];
  }
  return undefined;
};
