import { Mutations } from "../../queries";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeMerchantDealsAtom } from "../../atoms";

export const usePortalDeal = (store_id, data) => {
  const [activeMerchantDeals, setActiveMerchantDeals] = useRecoilState(
    activeMerchantDealsAtom
  );

  const {
    mutate: mutatePortalDeal,
    variables: mutatePortalDealVariables,
    reset: mutationPortalDealReset,
    status: mutatePortalDealStatus,
  } = Mutations.editActiveMerchantDeals(store_id, "web-portal-status");
  const portalToggle = (checked: boolean) => {
    setActiveMerchantDeals((prevState) => ({
      ...prevState,
      web_portal_status: {
        loading: "loading",
        status: checked ? "service_enabled" : "service_disabled",
      },
    }));
    mutatePortalDeal(checked ? "service_enabled" : "service_disabled");
  };

  useEffect(() => {
    if (mutatePortalDealStatus === "success") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        web_portal_status: {
          status: prevState.web_portal_status.status,
          loading: "success",
        },
      }));
      mutationPortalDealReset();
    } else if (mutatePortalDealStatus === "error") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        web_portal_status: {
          status:
            data.web_portal_status === "service_enabled_unpublished" ||
            data.web_portal_status === "service_enabled_published"
              ? "service_enabled"
              : "service_disabled",
          loading: "error",
        },
      }));
    }
  }, [mutatePortalDealVariables, mutatePortalDealStatus, data]);

  return {
    portalToggle,
    mutatePortalDeal,
    activeMerchantDeals,
  };
};
