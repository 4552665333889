import moment from "moment";
import {
  createPromoCode,
  createUniversalPromoCode,
  editPromoCode,
} from "../../../TargetedTools/redux-store/actions/promoCodeActions";
import { store } from "../../../../redux-store/configureStore";
import {
  processEditingPromoCodesValues,
  processPromoCodesValues,
  processUniversalPromoCodesValues,
} from "./lib";
import {
  ActionTypes,
  IMutationArguments,
  ToggleStatus,
  PromocodeTarget,
} from "../../../TargetedTools/types";

// edit or reactivate
const mutateEditPromocode = (
  { initialValues, values, promo_id, country_iso_code }: IMutationArguments,
  reactivate?: boolean
) => {
  const formData = processEditingPromoCodesValues(
    {
      ...values,
      end_time: reactivate
        ? values.end_time
        : Number(new Date().getTime()) > Number(values.end_time)
        ? moment().valueOf()
        : values.end_time,
    },
    country_iso_code as string,
    reactivate ? 1 : undefined
  );
  if (
    initialValues.is_compensational ||
    +initialValues.max_usage_times === +values.max_usage_times
  ) {
    formData.delete("max_usage_times");
  }
  if (promo_id) {
    store.dispatch(
      editPromoCode({
        data: formData as any,
        id: promo_id,
        reactivate,
        end_time: values.end_time,
      })
    );
  }
};

// create or duplicate

const mutateCreatePromocode = ({
  toggle_users,
  values,
  promocode_target,
  country_iso_code,
}: IMutationArguments) => {
  const isUniversal = toggle_users === ToggleStatus.enable;
  const processingFn = isUniversal
    ? processUniversalPromoCodesValues
    : processPromoCodesValues;
  const is_compensational: boolean =
    promocode_target === PromocodeTarget.compensational;

  const formData = processingFn(
    values,
    country_iso_code as string,
    is_compensational
  );
  if (isUniversal) {
    store.dispatch(createUniversalPromoCode(formData as any));
  } else {
    store.dispatch(createPromoCode(formData as any));
  }
};

const setArgs = ({ values, ...args }: IMutationArguments) => ({
  ...args,
  values: {
    ...values,
    is_exclusive: values.is_exclusive ? 1 : 0,
  },
});
export const mutations: Record<
  ActionTypes,
  (args: IMutationArguments) => void
> = {
  edit: (args) => mutateEditPromocode(setArgs(args)),
  create: (args) => mutateCreatePromocode(setArgs(args)),
  duplicate: (args) => mutateCreatePromocode(setArgs(args)),
  reactivate: (args) => mutateEditPromocode(setArgs(args), true),
};
