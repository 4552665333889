import axios from "../axios";
import { cashbackDetailsURL } from "../requests";

export const getCashbackDetailsApi = (token: string, store_id: string) => {
  return axios.get(cashbackDetailsURL, {
    headers: { token },
    baseURL: process.env.PICKUP_ENDPOINT,
    params: { store_id },
  });
};
