import { takeLatest, put, select, call } from "redux-saga/effects";
import {
  getCategoriesFailure,
  getCategoriesSuccess,
} from "../actions/categoriesAction";
import { selectToken } from "../../../../redux-store/selectors";
import { getCategoriesAPI } from "../../axios/getCategories";
import { getCategoriesAction } from "../actions/constants";

function* getCategoriesSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getCategoriesAPI, token);
    yield put(getCategoriesSuccess(res.data));
  } catch {
    yield put(getCategoriesFailure());
  }
}
export function* watchGetCategoriesSaga() {
  yield takeLatest(getCategoriesAction.requested, getCategoriesSaga);
}
