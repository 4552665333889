import axios from "./axios";
import * as Request from "./requests";

export const getStoreInfo = (token: string, store_id: string) => {
  return axios.get(Request.getStoreInfo(store_id), {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
