import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { useSelect } from "../../../../hooks/useSelect";
import BrandTags from "./BrandTags";
const BrandTagsWrapper: React.FC = () => {
  const { tags, tagsById } = useSelect((state) => state.tagsReducer);

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchWrapper}>
        <input
          type={"text"}
          className={styles.input}
          placeholder={"Search for tags"}
        />
        <img
          src={require("../../../../assets/search.svg")}
          className={styles.icon}
        />
      </div>
      <div className={styles.separator} />
      <div className={styles.tagsCard}>
        {tags.map((i) => (
          <BrandTags key={tagsById[i].id} tags={tagsById[i]} />
        ))}
      </div>
      <div className={styles.separator} />
    </div>
  );
};

export default hot(module)(BrandTagsWrapper);
