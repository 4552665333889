import React, { useState, useCallback } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import SMSHeader from "./SMSHeader";
import SMSTable from "./SMSTable";
import { useSelect } from "../../../hooks/useSelect";
import { getSMSCampaigns } from "../redux-store/actions/sms-campaigns-actions";
import { DotLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";

const SMS: React.FC = () => {
  const [pageNumber, setPageNumber] = useState(0);

  const { smsCampaigns, smsCampaignsById, total_sms_campaigns } = useSelect(
    (state) => state.smsCampaignsReducer
  );
  const handleNext = useCallback(() => {
    setPageNumber((n) => n + 1);
  }, []);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // tslint:disable-next-line: no-console
    console.log("Iam Here <===>", smsCampaigns);
    dispatch(getSMSCampaigns({ page: pageNumber, per_page: 25 }));
  }, []);

  return (
    <div>
      <SMSHeader length={total_sms_campaigns} />
      <InfiniteScroll
        dataLength={smsCampaigns.length}
        next={handleNext}
        hasMore={total_sms_campaigns > pageNumber * 25}
        loader={
          <div className={styles.loader}>
            <DotLoader color={"#f09440"} />
          </div>
        }
      >
        {smsCampaigns.map((sms, key) => (
          <SMSTable key={key} sms={smsCampaignsById[sms]} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default hot(module)(SMS);
