import * as React from "react";
import { CardBody } from "reactstrap";
import Card from "reactstrap/lib/Card";
import * as styles from "./styles.module.sass";
import { SeeMore } from "./SeeMore";
import BranchesModal from "./BranchesModal";

export interface ICampaignCriteria {
  branch_ids: string[];
  last_visit_timestamps_range: number[];
  visits_number: string;
  averge_ticket_sizes_range: number[];
  last_visit_timestamps_text: string;
}

interface IProps {
  smsContent: string;
  currency: string;
  criteria: ICampaignCriteria;
  campaignId: string;
}

interface IState {
  isViewingBranches: boolean;
}

export class CampaignData extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isViewingBranches: false,
    };
  }

  public toggleViewBranches = () => {
    this.setState({
      isViewingBranches: !this.state.isViewingBranches,
    });
  };

  public render() {
    const {
      currency,
      smsContent,
      criteria: {
        branch_ids,
        last_visit_timestamps_range,
        visits_number,
        averge_ticket_sizes_range,
        last_visit_timestamps_text,
      },
      campaignId,
    } = this.props;
    const { isViewingBranches } = this.state;
    return (
      <Card style={{ border: "none" }}>
        <CardBody className={styles.cardBody}>
          <div style={{ width: "36%" }} />
          <div className={styles.bodyWrapper}>
            <div className={styles.dataWrapper}>
              {branch_ids ? (
                <div className={styles.branches}>
                  <h1 className={styles.title}>Branches</h1>
                  <span className={styles.text}>{branch_ids.length}</span>
                  <span
                    onClick={this.toggleViewBranches}
                    className={styles.allBranches}
                  >
                    See all branches
                  </span>
                </div>
              ) : (
                <div className={styles.branches}>
                  <h1 className={styles.title}>Branches</h1>
                  <span
                    onClick={this.toggleViewBranches}
                    className={styles.allBranches}
                  >
                    See all branches
                  </span>
                </div>
              )}
              {last_visit_timestamps_range ? (
                <div className={styles.lastVisit}>
                  <h1 className={styles.title}>Last visit</h1>
                  <span className={styles.text}>
                    {last_visit_timestamps_text}
                  </span>
                </div>
              ) : (
                <div className={styles.lastVisit}>
                  <h1 className={styles.title}>Last visit</h1>
                  <span className={styles.text}>Overtime</span>
                </div>
              )}
              {visits_number && (
                <div className={styles.visitsCount}>
                  <h1 className={styles.title}>No. of visits</h1>
                  <span className={styles.text}>{visits_number}</span>
                </div>
              )}
              {averge_ticket_sizes_range && (
                <div className={styles.ticketSize}>
                  <h1 className={styles.title}>Ticket size</h1>
                  <span className={styles.text}>
                    {averge_ticket_sizes_range[0]}
                    {currency} - {averge_ticket_sizes_range[1]}
                    {currency}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.noIdea}>
              <SeeMore text={smsContent} limit={100} />
            </div>
          </div>
          {isViewingBranches && (
            <BranchesModal
              toggle={this.toggleViewBranches}
              campaignId={campaignId}
              isOpen={isViewingBranches}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}
