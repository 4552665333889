import { useRecoilState } from "recoil";
import { filtersAtom, merchantsAtom } from "../../atoms";
import { useDeepCompareEffect } from "use-deep-compare";
import { useEffect } from "react";
import { Queriers } from "../../queries";
import { clearPrevStateAction } from "../../constants";
import { useDispatch } from "react-redux";
export const useGetMerchants = () => {
  const [{ params, queryEnabled }, setFilters] = useRecoilState(filtersAtom);
  const [
    { status, merchantById, merchantsIds, hasMore, total_stores_count },
    setState,
  ] = useRecoilState(merchantsAtom);
  const { data, status: queryStatus } = Queriers.getMerchants({
    params: { ...params },
    queryEnabled,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      status: queryStatus,
      hasMore: queryStatus === "loading" || !!(data?.stores || [])?.length,
    }));
  }, [queryStatus, data]);
  useDeepCompareEffect(() => {
    if (queryStatus === "success") {
      setState((prev) => ({
        total_stores_count:
          params.page === 0 ? data.total_stores_count : prev.total_stores_count,
        merchantById: {
          ...(params.page !== 0 ? prev.merchantById : {}),
          ...data?.stores?.reduce(
            (acc, next) => ({
              ...acc,
              [next.id]: next,
            }),
            {}
          ),
        },
        merchantsIds: Array.from(
          new Set([
            ...(params.page !== 0 ? prev.merchantsIds : []),
            ...(data?.stores || [])?.map((merchant) => merchant.id),
          ])
        ),
        status: "success",
        hasMore:
          !!(data?.stores || [])?.length && (data?.stores || [])?.length >= 20,
      }));
      setFilters((prev) => ({
        params: {
          ...prev.params,
        },
        queryEnabled: false,
      }));
      dispatch(clearPrevStateAction());
    }
  }, [queryStatus, data, params]);
  return {
    status,
    merchantById,
    merchantsIds,
    hasMore,
    total_stores_count,
  };
};

export default useGetMerchants;
