import * as constants from "../../constants";
import {
  IGetBrandWizardInfoAction,
  IGetBrandWizardInfoFailureAction,
  IGetBrandWizardInfoSuccessAction,
  IStep,
} from "../../types";

export const getBrandWizardInfo = (
  store_id: string
): IGetBrandWizardInfoAction => ({
  type: constants.getBrandWizardInfoAction.requested,
  payload: store_id,
});

export const getBrandWizardInfoSuccess = (
  currentStep: IStep,
  wizardToken: string
): IGetBrandWizardInfoSuccessAction => ({
  type: constants.getBrandWizardInfoAction.fulfilled,
  payload: { currentStep, wizardToken },
});

export const getBrandWizardInfoFailure = (
  error
): IGetBrandWizardInfoFailureAction => ({
  type: constants.getBrandWizardInfoAction.rejected,
  payload: error,
});
