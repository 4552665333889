import { useQuery } from "react-query";
import {
  getPromocodeByRankApi,
  IParams,
} from "../../axios/getPromocodeByRankApi";
import { getHighestRankApi } from "../../axios/getHighestRankApi";
import { PromocodeInterfaces } from "../../types";

export const useGetPromocodeByRank = (
  token: string,
  { country_iso_code, rank }: IParams
) => {
  return useQuery<PromocodeInterfaces.IPromocodeByRank | null, Error>(
    ["rank", rank],
    () => getPromocodeByRankApi(token, { rank, country_iso_code }),
    {
      enabled: !!rank && !!country_iso_code,
    }
  );
};

export const useGetHighestRank = (token) => {
  return useQuery<any, Error>(
    "highest-rank",
    async () => await getHighestRankApi(token)
  );
};
