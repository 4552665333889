import Switch, { ReactSwitchProps } from "react-switch";
import * as styles from "./style.module.sass";
import React from "react";

export const SettingsSwitch = (props: ReactSwitchProps) => {
  return (
    <Switch
      onHandleColor="#fff"
      handleDiameter={28}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05)"
      activeBoxShadow="0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05)"
      height={31}
      width={51}
      onColor="#f09440"
      {...props}
      className={`${styles.settingSwitch} ${props.className || ""}`}
    />
  );
};
