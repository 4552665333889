import React, { useCallback } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Modal } from "@material-ui/core";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { RenderGenericField } from "../RenderGenericField/RenderGenericField";
import { GenericButtonWithoutIcon } from "../../../SharedComponent/GenericButtonWithoutIcon";
import { isEmpty } from "../../../../redux-store/validators";
import {
  createStoreTag,
  editStoreTag,
} from "../../redux-store/actions/tags-actions";
import { useDispatch } from "react-redux";

interface IProps {
  open: boolean;
  onClose: () => void;
  isEditing?: boolean;
  id?: string;
}
const AddNewTags: React.FC<IProps & InjectedFormProps> = (props) => {
  const { onClose, open, handleSubmit, isEditing, id } = props;
  const dispatch = useDispatch();
  const addNewTags = useCallback((values) => {
    if (isEditing) {
      dispatch(editStoreTag({ ...values, id }));
      onClose();
    } else {
      dispatch(createStoreTag(values));
      onClose();
    }
  }, []);
  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={open}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <div className={styles.addNewTagsWrapper}>
          <span className={styles.title}>
            {isEditing ? "Edit Tag" : "Add New Tags"}
          </span>
          <div className={styles.separator} />
          <form>
            <Field
              component={RenderGenericField as any}
              label={"Tag name"}
              placeholder={"Ex. Breakfast"}
              name={"name_en"}
              validate={isEmpty}
            />
            <Field
              component={RenderGenericField as any}
              label={"Tag name in arabic"}
              placeholder={"الفطار"}
              name={"name_ar"}
              inputClass={styles.input}
              validate={isEmpty}
            />
          </form>
        </div>
        <div className={styles.btnWrapper}>
          <GenericButtonWithoutIcon
            onClick={onClose}
            className={styles.cancel}
            label={"Cancel"}
          />
          <div style={{ margin: "0 0.5rem" }} />
          <GenericButtonWithoutIcon
            className={styles.add}
            label={isEditing ? "Edit Tag" : "Create Tag"}
            onClick={handleSubmit(addNewTags)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default reduxForm<{}, IProps>({
  form: "addNewTagsForm",
})(hot(module)(AddNewTags as any));
