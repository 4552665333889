import axios from "./axios";
import * as Requests from "./requests";

export const getSMSCampaignsAPI = (
  token: string,
  page: number,
  per_page: number
) => {
  return axios.get(Requests.getSMSCampaignsURL, {
    params: {
      page,
      per_page,
    },
    headers: {
      token,
    },
    baseURL: process.env.NOTIFICATIONS_ENDPOINT,
  });
};
