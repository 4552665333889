import { store } from "../../../../redux-store/configureStore";
import { useDeepCompareMemo } from "use-deep-compare";

export const selectCountryISOCode = () => {
  const values = store.getState().form?.AddPromoCodeForm?.values;
  const { countries } = store.getState().countriesReducer;
  return useDeepCompareMemo(() => {
    if (values?.country_iso_code) {
      return countries
        .find((country) => country.country_code === values?.country_iso_code)
        ?.ISO_code.toLowerCase();
    } else {
      return;
    }
  }, [values?.country_iso_code, countries]);
};
