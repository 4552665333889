import Axios from "../axios";
import { getPointsOfContactsURL } from "../requests";

export const getPointsOfContactsApi = (token: string, store_id: string) => {
  return Axios.get(getPointsOfContactsURL, {
    headers: {
      token,
    },
    params: { store_id },
    baseURL: process.env.LUMEN_ENDPOINT, 
  });
};
