import Axios from "../axios";
import { getBrandsWithTasksURL } from "../requests";

export const getBrandsWithTasksApi = (
  token: string,
  account_manager_id: string
) => {
  return Axios.get(getBrandsWithTasksURL, {
    headers: {
      token,
    },
    params: { account_manager_id },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
