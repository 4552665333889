import axios from "axios";
import * as Request from "./requests";

export const editPromoCodeAPI = (token, id, data) => {
  return axios.post(Request.editPromoCodeURL(id), data, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
