import React, { useMemo, useState } from "react";
import * as styles from "./styles.module.sass";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Icon from "./icon";
import AmsIcon from "./AmsIcon";
import { useSelect } from "../../hooks/useSelect";

interface ICollapseNavItemState {
  ReachCollapse: boolean;
  TargetCollapse: boolean;
  TargetCollapseHover: boolean;
  AMSCollapse: boolean;
}

const CollapseNavItem: React.FC<RouteComponentProps> = ({ location }) => {
  const [state, setState] = useState<ICollapseNavItemState>({
    AMSCollapse: false,
    ReachCollapse: false,
    TargetCollapse: false,
    TargetCollapseHover: false,
  });
  const account_managers = useSelect(
    (rstate) => rstate.getAdminsByRoleReducer.account_manager
  );
  const is_account_manager_logged_in = React.useMemo(
    () => account_managers.find((am) => am.logged_in)?.logged_in,
    [account_managers]
  );
  const reachToggle = () =>
    setState((prevState) => ({
      ...prevState,
      ReachCollapse: !prevState.ReachCollapse,
    }));
  const targeToggle = () =>
    setState((prevState) => ({
      ...prevState,
      TargetCollapse: !prevState.TargetCollapse,
    }));
  const amsToggle = () =>
    setState((prevState) => ({
      ...prevState,
      AMSCollapse: !prevState.AMSCollapse,
    }));
  const handleMouseOver = () => {
    setState((prevState) => ({
      ...prevState,
      TargetCollapseHover: true,
    }));
  };
  const handleMouseLeave = () => {
    setState((prevState) => ({
      ...prevState,
      TargetCollapseHover: false,
    }));
  };
  const duration = 1000;

  const defaultStyle = {
    transition: `all ${duration}ms ease`,
    opacity: 0,
  };

  const transitionStyles = {
    false: { opacity: 0 },
    true: { opacity: 1 },
  };
  const amsActive = useMemo(
    () =>
      location.pathname === "/account-managers/action-center" ||
      location.pathname === "/account-managers/activity-log",
    [location.pathname]
  );
  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <Button
          onClick={reachToggle}
          className={
            location.pathname === "/reach/payment" ||
            location.pathname === "/reach/campaigns"
              ? styles.btnActive
              : styles.btn
          }
        >
          <div
            className={styles.bar}
            style={{ marginLeft: "2.2rem", marginRight: "6.9rem" }}
          >
            <span style={{ margin: 0, fontSize: "2rem" }}>R</span>
            <span>Reach</span>
          </div>
          <span
            className={state.ReachCollapse ? styles.arrow : styles.arrowOpen}
          />
        </Button>
        <Collapse
          style={{
            ...defaultStyle,
            ...transitionStyles[state.ReachCollapse ? "true" : "false"],
          }}
          in={state.ReachCollapse}
          timeout={duration}
          className={styles.body}
          isOpen={state.ReachCollapse}
        >
          <Card className={styles.card}>
            <CardBody>
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/reach/campaigns"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Campaign</span>
                </NavLink>
              </li>

              <li className={styles.NavigationItem}>
                <NavLink
                  to="/reach/payment"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Payment</span>
                </NavLink>
              </li>
            </CardBody>
          </Card>
        </Collapse>
      </div>
      {is_account_manager_logged_in && (
        <div
          className={styles.wrapper}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <Button
            onClick={amsToggle}
            style={{ justifyContent: "space-between" }}
            className={amsActive ? styles.btnActive : styles.btn}
          >
            <div className={styles.bar}>
              <span style={{ margin: 0, marginRight: "1rem" }}>
                <AmsIcon is_active={amsActive} />
              </span>
              <span style={{ margin: 0, paddingTop: "1rem" }}>AMs</span>
            </div>
            <span
              style={{
                margin: 0,
                marginTop: "0.8rem",
                borderColor: amsActive ? "#FFA351" : "black",
              }}
              className={state.AMSCollapse ? styles.arrow : styles.arrowOpen}
            />
          </Button>
          <Collapse
            style={{
              ...defaultStyle,
              ...transitionStyles[state.AMSCollapse ? "true" : "false"],
            }}
            in={state.AMSCollapse}
            timeout={duration}
            className={styles.body}
            isOpen={state.AMSCollapse}
          >
            <Card className={styles.card}>
              <CardBody>
                <li className={styles.NavigationItem}>
                  <NavLink
                    to="/account-managers/action-center"
                    exact={true}
                    activeClassName={styles.active}
                  >
                    <span>Action center</span>
                  </NavLink>
                </li>
                <li className={styles.NavigationItem}>
                  <NavLink
                    to="/account-managers/activity-log"
                    exact={true}
                    activeClassName={styles.active}
                  >
                    <span>Activity log</span>
                  </NavLink>
                </li>
              </CardBody>
            </Card>
          </Collapse>
        </div>
      )}
      <div
        className={styles.wrapper}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <Button
          onClick={targeToggle}
          className={
            location.pathname === "/targeting/promoted-brands" ||
            location.pathname === "/targeting/promo-codes" ||
            location.pathname === "/reach/sms" ||
            location.pathname === "/reach/push-notifications" ||
            location.pathname === "/reach/popup-announcement"
              ? styles.btnActive
              : styles.btn
          }
        >
          <div className={styles.bar}>
            <span style={{ margin: 0 }}>
              <Icon
                is_active={
                  location.pathname === "/targeting/promoted-brands" ||
                  location.pathname === "/targeting/promo-codes" ||
                  location.pathname === "/reach/sms" ||
                  location.pathname === "/reach/push-notifications" ||
                  location.pathname === "/reach/popup-announcement"
                }
              />
            </span>
            <span style={{ margin: 0, paddingTop: "1rem" }}>
              Targeting tools
            </span>
          </div>
          <span
            style={{ margin: 0, marginTop: "0.8rem" }}
            className={state.TargetCollapse ? styles.arrow : styles.arrowOpen}
          />
        </Button>
        <Collapse
          style={{
            ...defaultStyle,
            ...transitionStyles[state.TargetCollapse ? "true" : "false"],
          }}
          in={state.TargetCollapse}
          timeout={duration}
          className={styles.body}
          isOpen={state.TargetCollapse}
        >
          <Card className={styles.card}>
            <CardBody>
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/targeting/promo-codes"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Promo codes</span>
                </NavLink>
              </li>

              <li className={styles.NavigationItem}>
                <NavLink
                  to="/reach/sms"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Sms</span>
                </NavLink>
              </li>
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/reach/push-notifications"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Push Notifications</span>
                </NavLink>
              </li>
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/targeting/categories"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Categories</span>
                </NavLink>
              </li>
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/targeting/banner-cards"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Banner cards</span>
                </NavLink>
              </li>
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/targeting/announcements"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Popup announcement</span>
                </NavLink>
              </li>
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/targeting/tags"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Tags</span>
                </NavLink>
              </li>
              <li className={styles.NavigationItem}>
                <NavLink
                  to="/targeting/promoted-brands"
                  exact={true}
                  activeClassName={styles.active}
                >
                  <span>Promoted Brands</span>
                </NavLink>
              </li>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CollapseNavItem);
