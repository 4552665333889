import { call, put, select, takeLatest } from "redux-saga/effects";
import { logoutAction } from "../../constants";
import * as actions from "../actions";
import { logout } from "../../axios/logout";
import { notify } from "react-notify-toast";
import { selectToken } from "../selectors";

function* logoutSaga() {
  try {
    const token = yield select(selectToken);
    yield call(logout, token);
    yield put(actions.logoutSuccess());
    yield put(actions.resetAppState())
  } catch (e) {
    notify.show("Please try again", "error");
  }
}

export function* watchLogoutSaga() {
  yield takeLatest(logoutAction.requested, logoutSaga);
}
