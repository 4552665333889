import { IGetBrandDetailsAction } from "../../types";
import { selectToken } from "../selectors";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { getBrand } from "../../axios/getBrand";
import { getBrandDetailsFailure, getBrandDetailsSuccess } from "../actions";
import { getBrandDetailsAction } from "../../constants";
import { Error } from "tslint/lib/error";

function* brandDetailsSaga(action: IGetBrandDetailsAction) {
  try {
    const token = yield select(selectToken);
    const res: AxiosResponse<any> = yield call(
      getBrand,
      token,
      action.payload.store_id
    );
    const brand = res.data.store;
    if (!brand) {
      throw Error;
    }
    yield put(
      getBrandDetailsSuccess({
        ...brand,
        phone_number: res.data.manager.phoneNumber,
        manager_id: res.data.manager.id,
      })
    );
  } catch (e) {
    yield put(getBrandDetailsFailure(e));
  }
}

export function* watchBrandDetailsSaga() {
  yield takeLatest(getBrandDetailsAction.requested, brandDetailsSaga);
}
