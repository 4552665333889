import React from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { ISMSCampaign } from "../../redux-store/actions/sms-campaigns-actions";
import moment from "moment-timezone";
export enum smsStatus {
  pending_for_creation = 0,
  scheduled = 1,
  running = 2,
  complete = 3,
  success = 4,
  failure = 5,
}
interface IProps {
  sms: ISMSCampaign;
}
const SMSTable: React.FC<IProps> = ({
  sms: {
    status,
    message,
    sent_users_count,
    total_users_count,
    created_at,
    scheduled_at,
  },
}) => {
  const percentage = (userCount, totalUsersCount) => {
    return Number(100 * userCount) / Number(totalUsersCount) + "%";
  };
  const date = moment.unix(created_at).format("LL");
  const time = moment.unix(created_at).format("LT");
  const date_scheduled_at = moment.unix(scheduled_at).format("LL");
  const time_scheduled_at = moment.unix(scheduled_at).format("LT");
  return (
    <div className={styles.wrapper}>
      <div className={styles.dateWrapper}>
        <span className={styles.dateHeader}>{date}</span>
        <span className={styles.dateTitle}>{time}</span>
      </div>
      <div className={styles.bodyTextWrapper}>
        <span className={styles.bodyText}>Title</span>
        <span className={styles.bodySubText}>{message}</span>
      </div>
      <div className={styles.successRate}>
        <span className={styles.dateHeader}>
          {percentage(sent_users_count, total_users_count)}
        </span>
        <span className={styles.dateTitle}>
          {sent_users_count} / {total_users_count}
        </span>
      </div>

      <div className={styles.statusWrapper}>
        {status === smsStatus.complete ||
          (status === smsStatus.success && (
            <>
              <div className={styles.completedWrapper}>
                <span className={styles.completedTitle}>Completed</span>
              </div>
              <span className={styles.statusTitle}>Pushing completed</span>
            </>
          ))}
        {status === smsStatus.scheduled && (
          <>
            <div className={styles.scheduledWrapper}>
              <span className={styles.scheduledTitle}>Scheduled</span>
            </div>
            <div className={styles.scheduledDateWrapper}>
              {date_scheduled_at} , {time_scheduled_at}
            </div>
          </>
        )}
        {status === smsStatus.running && (
          <div className={styles.runningWrapper}>
            <span className={styles.runningTitle}>Running</span>
          </div>
        )}
        {status === smsStatus.failure && (
          <div className={styles.failureWrapper}>
            <span className={styles.failureTitle}>Failure</span>
          </div>
        )}
        {status === smsStatus.pending_for_creation && (
          <>
            <div className={styles.pendingWrapper}>
              <span className={styles.pendingTitle}>Pending Creation</span>
            </div>
            <span className={styles.per}>
              {percentage(sent_users_count, total_users_count)}...
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default hot(module)(SMSTable);
