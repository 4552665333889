// promoted section title
import React, { useEffect } from "react";
import { Modal } from "@material-ui/core";
import promotredStyles from "./styles.module.sass";
import { ClipLoader } from "react-spinners";
import { PromotedSectionTitleSchema } from "./validator";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IPromotedSectionTitle } from "./types";
import NewInputField from "../../../../../Components/SharedComponent/NewInputField";
import { useSelect } from "../../../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { editPromotedBrandsTitle } from "../../../../../redux-store/actions/promotedSectionTitleAction";
// props types

export const PromotedSectionTitle: React.FC<IPromotedSectionTitle> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { title } = useSelect((state) => ({
    title: state.promotedSectionTitleReducer,
  }));
  const {
    handleSubmit,
    getFieldState,
    formState: { errors, isSubmitting },
    register,
    reset,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      en_title: "",
      ar_title: "",
    },
    resolver: yupResolver(PromotedSectionTitleSchema),
  });

  const handleFormSubmit = (values: { en_title: string; ar_title: string }) => {
    dispatch(editPromotedBrandsTitle(values));
    onClose();
  };
  useEffect(() => {
    reset({
      ...title,
    });
  }, [title]);
  return (
    <Modal onClose={onClose} open={open}>
      <div className={promotredStyles.roundWrapper}>
        <div
          className={promotredStyles.pincodeWrapper}
          style={{ borderRadius: "5px" }}
        >
          <div className={promotredStyles.closeWrapper}>
            <span className={promotredStyles.title}>Section Title</span>
            <img
              onClick={onClose}
              style={{ height: "14px", width: "14px", paddingTop: "3px" }}
              src={require("../../../../../assets/icon-close.svg")}
            />
          </div>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(handleFormSubmit)}
            className={promotredStyles.form}
          >
            <div
              style={{
                padding: "10px 24px 0px",
                width: "100%",
              }}
            >
              {/* order id */}
              <div className={promotredStyles.inputWrapper}>
                <label className={promotredStyles.label}>English</label>
                <NewInputField
                  input={{
                    name: "en_title",
                    type: "text",
                    placeholder: "selected title",
                    label: "en_title",
                  }}
                  getFieldState={getFieldState}
                  register={register}
                  errors={errors}
                />
              </div>
              {/* Arabic */}
              <div className={promotredStyles.inputWrapper}>
                <label className={promotredStyles.label}>Arabic</label>
                <NewInputField
                  input={{
                    name: "ar_title",
                    type: "text",
                    placeholder: "selected title",
                    label: "ar_title",
                  }}
                  getFieldState={getFieldState}
                  register={register}
                  errors={errors}
                />
              </div>
              <div style={{ marginTop: "8px" }} />
            </div>
            <div
              className={`${promotredStyles.footer} ${promotredStyles.submit}`}
            >
              <button
                className={
                  !!Object.keys(errors).length
                    ? promotredStyles.disabled
                    : promotredStyles.Btn
                }
                disabled={!!Object.keys(errors).length}
                type="submit"
              >
                {isSubmitting ? <ClipLoader size={30} color="#fff" /> : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
