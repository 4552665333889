import React, { useEffect } from "react";
import styles from "../../styles.module.sass";
import NewInputField from "../../../../../Components/SharedComponent/NewInputField";
import { useForm } from "react-hook-form";
import SelectionList from "../SelectionList";
import { debounce } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { SearchSchema } from "./validator";
import { useDeepCompareCallback } from "use-deep-compare";
import { IFilterHeader } from "../../types";
import { useSelect } from "../../../../../hooks/useSelect";
import { IRootReducerState } from "../../../../../redux-store/rootReducer";

const FiltersHeader: React.FC<IFilterHeader> = ({
  brands: filteredBrands,
  setFilters,
}) => {
  const { brands } = useSelect((state: IRootReducerState) => ({
    brands: state.promotedBrandsReducer.brandsIds,
  }));
  const [open, setOpen] = React.useState<boolean>(false);
  const {
    formState: { errors },
    getFieldState,
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      search: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(SearchSchema),
  });

  useEffect(() => {
    handleSearch(watch("search"));
    if (watch("search")) {
      setOpen(true);
    }else {
      setOpen(false)
    }
  }, [watch("search")]);

  const handleSearch = useDeepCompareCallback(
    debounce((value: string) => {
      setFilters((prev) => ({
        params: {
          ...prev.params,
          search: value === "" ? undefined : value.trim(),
          page: 0,
        },
        queryEnabled: true,
      }));
    }, 300),
    [setFilters]
  );
  return (
    <div className={styles.searchBarWrapper}>
      <div className={styles.brandsCountWrapper}>
        <h3 className={styles.brandsCount}>Brands ( {brands.length} )</h3>
      </div>
      <div className={styles.searchbar}>
        <form onSubmit={handleSubmit(({ search }: { search: string }) => {})}>
          <div className={styles.inputWrapper}>
            <div className={styles.iconWrapper}>
              <img
                className={styles.icon}
                src={require("../../../../../assets/search.svg")}
              />
              {watch("search") && (
                <span
                  className={`${styles.icon} ${styles.clickable} ${styles.inputIcon}`}
                  onClick={() => {
                    setValue("search", "");
                  }}
                >
                  &times;
                </span>
              )}
            </div>
            <SelectionList open={open} list={Object.values(filteredBrands)} />
            <NewInputField
              errors={errors}
              getFieldState={getFieldState}
              input={{
                name: "search",
                type: "text",
                placeholder: "Search by brand name ....",
              }}
              register={register}
              className={`${styles.iconPadding} ${styles.greyBg}`}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FiltersHeader;
