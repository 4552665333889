import { action, payload } from "ts-action";
import { getCommissionsAction, editCommissionsAction } from "./constants";
import { ICommissions } from "../reducers/lib";

export const getCommissions = action(
  getCommissionsAction.requested,
  payload<string>()
);
export const getCommissionsSuccess = action(
  getCommissionsAction.fulfilled,
  payload<ICommissions>()
);
export const getCommissionsFailure = action(
  getCommissionsAction.rejected,
  payload<Error>()
);

export const editCommissions = action(
  editCommissionsAction.requested,
  payload<{ id: string; commissions: ICommissions }>()
);
export const editCommissionsSuccess = action(
  editCommissionsAction.fulfilled,
  payload<ICommissions>()
);
export const editCommissionsFailure = action(
  editCommissionsAction.rejected,
  payload<Error>()
);
