import * as React from "react";
import * as styles from "./styles.module.sass";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { required } from "../../redux-store/validators";
import { hot } from "react-hot-loader";
import {
  ISelectItemData,
  RenderDropDownSelect,
} from "../../Components/FormElements/RenderDropDownSelect";
import { RenderDatePicker } from "../../Components/FormElements/RenderDatePicker";
import { normalizeDate } from "../../redux-store/transformers";

const trialDurationSelectItems: ISelectItemData[] = [
  {
    label: "1 month",
    value: 1,
  },
  {
    label: "2 month",
    value: 2,
  },
  {
    label: "3 month",
    value: 3,
  },
  {
    label: "4 month",
    value: 4,
  },
  {
    label: "5 month",
    value: 5,
  },
  {
    label: "6 month",
    value: 6,
  },
];

class TrialForm extends React.Component<{} & InjectedFormProps> {
  public render() {
    return (
      <form autoComplete="off" className={styles.wrapper}>
        <h1 className={styles.title}>Add trial Plan</h1>
        <div className={styles.fieldsWrapper}>
          <Field
            name="start_date"
            component={RenderDatePicker as any}
            label="Start date"
            validate={[required]}
            normalize={normalizeDate}
          />
          <Field
            name="trial_duration"
            component={RenderDropDownSelect as any}
            options={trialDurationSelectItems}
            label="Trial duration"
            validate={[required]}
          />
        </div>
      </form>
    );
  }
}

const TrialFormComponent = reduxForm({
  form: "trialForm",
})(TrialForm);
export default hot(module)(TrialFormComponent) as any;
