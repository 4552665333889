import { reassiginAccountManagerURL } from "./requests";
import axios from "./axios";

export const assiginAccountManagerApi = (
  token: string,
  body: {
    store_id: string;
    account_manager_id: string;
  }
) => {
  return axios.post(reassiginAccountManagerURL, body, {
    headers: {
      token,
    },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
