import React from "react";
import {
  PromoMerchantVisibility,
  PromoTypes,
} from "../../../../TargetedTools/types";
import { Input } from "..";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useCurrency } from "../../hooks/useCurrency";
interface IProps {
  watchers: any;
  isEditing: boolean;
  initialValues: any;
  control: any;
  isReactivating: boolean;
  setError: UseFormSetError<FieldValues>;
}
export const PromoTypesInputs: React.FC<IProps> = ({
  isEditing,
  control,
  initialValues,
  isReactivating,
  setError,
  watchers,
}) => {
  const currency = useCurrency(watchers.country_iso_code);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0 24px",
        }}
      >
        {
          <>
            {watchers.type === PromoTypes.discount && (
              <React.Fragment>
                <Input
                  setError={setError}
                  name={"discount_value"}
                  control={control}
                  rules={{
                    required: {
                      value: watchers.type === PromoTypes.discount,
                      message: "Discount is required",
                    },
                  }}
                  type="number"
                  currency={currency}
                  label="Discount value"
                  placeholder={"Ex. 20"}
                  disabled={isEditing}
                />
                <div style={{ marginRight: "20px" }} />
              </React.Fragment>
            )}
            {(watchers.type === PromoTypes.percentage ||
              watchers.type === PromoTypes.cashback_percentage) && (
              <React.Fragment>
                <Input
                  setError={setError}
                  name={
                    watchers.type === PromoTypes.percentage
                      ? "discount_value"
                      : "cashback_value"
                  }
                  initialValue={
                    watchers.type === PromoTypes.percentage
                      ? watchers.discount_value
                      : watchers.cashback_value
                  }
                  control={control}
                  percentage={true}
                  rules={{
                    required: {
                      value: watchers.type === PromoTypes.percentage,
                      message: "Discount is required",
                    },
                    min: {
                      value: 0,
                      message: "Discount should be greater than or equal 0",
                    },
                    max: {
                      value: 100,
                      message: "Discount should be less than or equal 100",
                    },
                  }}
                  disabled={isReactivating || isEditing}
                  type={"number"}
                  label={
                    watchers.type === PromoTypes.percentage
                      ? "Discount percentage"
                      : "Cashback percentage"
                  }
                  placeholder={"Ex. 20"}
                />
                {(
                  (watchers.type === PromoTypes.percentage &&
                    watchers.promo_merchant_visibility ===
                      PromoMerchantVisibility.masked)) && (
                  <div style={{ marginRight: "20px" }} />
                )}
              </React.Fragment>
            )}
          </>
        }
        {(
          watchers.type === PromoTypes.discount ||
          (watchers.type === PromoTypes.percentage &&
            watchers.promo_merchant_visibility ===
              PromoMerchantVisibility.masked)) && (
          <Input
            setError={setError}
            name={
              watchers.type === PromoTypes.cashback_percentage
                ? "max_cashback_value"
                : "max_discount_value"
            }
            control={control}
            rules={{
              required: {
                value: watchers.type === PromoTypes.discount ? false : true,
                message:
                  watchers.type === PromoTypes.cashback_percentage
                    ? "Max cashback is required"
                    : "Max discount is required",
              },
            }}
            disabled={
              isReactivating ||
              isEditing ||
              watchers.type === PromoTypes.discount
            }
            type="number"
            currency={currency}
            initialValue={
              watchers.type === PromoTypes.cashback_percentage
                ? watchers.max_cashback_value
                : watchers.max_discount_value
            }
            label={
              watchers.type === PromoTypes.cashback_percentage
                ? "Max cashback value"
                : "Max discount value"
            }
            placeholder={"Ex. 50"}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0 24px",
        }}
      >
        {((watchers.type !== PromoTypes.multiplier &&
          watchers.promo_merchant_visibility ===
            PromoMerchantVisibility.masked) ||
          [PromoTypes.discount].includes(
            watchers.type
          )) && (
          <Input
            setError={setError}
            type="number"
            currency={currency}
            label="Min eligible invoice"
            placeholder={"Ex. 50"}
            disabled={isReactivating}
            name={"min_threshold"}
            control={control}
            initialValue={watchers?.min_threshold ?? 0}
            rules={{
              required: {
                value: true,
                message: "Min eligible invoice is required",
              },
            }}
          />
        )}
        {/* max usage times is not sent in case of editing a compensational promotion */}
        {isEditing && initialValues?.is_compensational ? null : (
          <React.Fragment>
            {((watchers.type !== PromoTypes.multiplier &&
              watchers.promo_merchant_visibility ===
                PromoMerchantVisibility.masked) ||
              [PromoTypes.discount].includes(
                watchers.type
              )) && <div style={{ marginRight: "20px" }} />}
            <Input
              setError={setError}
              disabled={isReactivating}
              label="Max usages times"
              placeholder={"Ex. 1"}
              name={"max_usage_times"}
              control={control}
              type="number"
              rules={{
                required: {
                  value: true,
                  message: "Max usage times is required",
                },
              }}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default PromoTypesInputs;
