import { useEffect, useMemo } from "react";
import { Mutations, Queriers } from "../../queries";
import { useRecoilState } from "recoil";
import { orderingGMVTypeAtom } from "../../atoms";
import { gmvOptions } from "../../atoms/merchantProfile";
import { OrderingGMVTypes } from "../../atoms/merchantProfile";
import { customNotify } from "../../UIComponents/customNotification/customNotification";
export const useOrderingGMVType = (store_id) => {
  const {
    data,
    status: getOrderingGMVTypeStatus,
  } = Queriers.getOrderingGMVType(store_id);
  const {
    variables,
    status: mutationStatus,
    mutate,
    error,
  } = Mutations.editOrderingGMVType(store_id);
  const [orderingGMVType, setOrderingGMVType] = useRecoilState(
    orderingGMVTypeAtom
  );
  const texts = useMemo(() => {
    return gmvOptions.reduce(
      (acc, next) => ({
        ...acc,
        [next.value]: next.text,
      }),
      {}
    );
  }, [gmvOptions]);
  useEffect(() => {
    if (getOrderingGMVTypeStatus === "success" && data?.gmv_type) {
      setOrderingGMVType(texts[data.gmv_type]);
    }
  }, [getOrderingGMVTypeStatus, data?.gmv_type, texts]);
  useEffect(() => {
    if (mutationStatus === "success") {
      setOrderingGMVType(texts[variables as OrderingGMVTypes]);
      customNotify.success("GMV Type edited successfully");
    } else if (mutationStatus === "error" && data?.gmv_type) {
      setOrderingGMVType(texts[data?.gmv_type]);
      if (error && (error as any).response.data.message) {
        customNotify.error((error as any).response.data.message);
      }
    }
  }, [mutationStatus, variables, texts, data?.gmv_type, error]);
  return {
    orderingGMVType,
    mutate,
    status: mutationStatus,
    gmv_data: data?.gmv_type,
  };
};
