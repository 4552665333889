import * as React from "react";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import { BeatLoader } from "react-spinners";

interface IProps {
  label: string;
  type: string;
  placeholder: string;
  autoFocus?: boolean;
  viewOnly?: boolean;
  className?: string;
  inputClass?: string;
  noValidationMessages?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  validationStyles?: {
    valid: React.CSSProperties;
    invalid: React.CSSProperties;
  };
  validationClassNames?: {
    valid: string;
    invalid: string;
  };
}

export const RenderGenericField: React.FC<WrappedFieldProps & IProps> = (
  props
) => {
  const {
    input,
    viewOnly,
    label,
    type,
    placeholder,
    meta: { touched, error, valid, asyncValidating, invalid, active },
    noValidationMessages,
    className,
    disabled,
    loading,
    inputClass,
  } = props;

  const shouldShowLoading = asyncValidating || loading;

  const isInvalid = !shouldShowLoading && touched && invalid;
  const isValid = !shouldShowLoading && touched && valid;
  return (
    <div className={`${styles.inputWrapper} ${className || ""}`}>
      <label htmlFor={input.name} className={styles.label}>
        <span>{label}</span>
      </label>
      <div
        style={{
          ...(active ? { borderColor: "#fdbb11" } : {}),
          ...(isValid ? { borderColor: "#38c172" } : {}),
          ...(isInvalid ? { borderColor: "#db3030" } : {}),
        }}
        className={`${styles.inputAndLoadingWrapper}`}
      >
        <input
          disabled={viewOnly || disabled}
          autoComplete="off"
          placeholder={placeholder}
          className={`${styles.input} ${inputClass || ""}`}
          id={input.name}
          {...input}
          type={type}
        />
        {/* {
          isValid &&
          <img src={require("../../../../assets/tick.svg")} />
        }
        {
          isInvalid &&
          <img src={require("../../../../assets/inputError.svg")} />
        } */}
        {shouldShowLoading && (
          <BeatLoader loading={true} size={7} color={"#FEBE19"} />
        )}
      </div>
      {!noValidationMessages && touched && error && (
        <p
          style={{
            margin: 0,
            padding: "1rem",
            color: "#db3030",
            fontSize: "1.2rem",
          }}
        >
          *{error}
        </p>
      )}
    </div>
  );
};
