import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  getSMSCampaigns,
  getSMSCampaignsFailure,
  getSMSCampaignsSuccess,
} from "../actions/sms-campaigns-actions";
import { selectToken } from "../../../../redux-store/selectors";
import { getSMSCampaignsAPI } from "../../axios/getSMSCampaigns";
import { getSMSCampaignsAction } from "../actions/constants";
import { union } from "ts-action";
const actionType = union({ getSMSCampaigns });

function* getSMSCampaignsSaga({ payload }: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const { page, per_page } = payload;
    const res = yield call(getSMSCampaignsAPI, token, page, per_page);
    yield put(
      getSMSCampaignsSuccess({
        sms_campaigns: res.data.sms_campaigns,
        total_sms_campaigns: res.data.total_sms_campaigns,
      })
    );
  } catch (e) {
    yield put(getSMSCampaignsFailure(e));
  }
}
export function* watchGetSMSCampaignsSaga() {
  yield takeLatest(getSMSCampaignsAction.requested, getSMSCampaignsSaga);
}
