import { put, takeLatest } from "redux-saga/effects";
import { editPromotedBrandsTitleAction } from "../../constants";
import { notify } from "react-notify-toast";
// import { setSubmitFailed, setSubmitSucceeded } from "redux-form";
// import { IAddStoreInfo, IStore } from "../../axios/getBrands";
// import { changeStorePromotedStatus } from "../../axios/changeStorePromotedStatus";
// import { editBrandFailure } from "../actions/editBrandActions";
import {
  editPromotedBrandsTitleSuccess,
  editPromotedBrandsTitleFailure,
} from "../actions/promotedSectionTitleAction";

// emails are not case sensitive, but our db is, so we gotta lower case it

function* editPromotedSectionTitleSaga({
  payload,
}: {
  type: string;
  payload: {
    ar_title: string;
    en_title: string;
  };
}) {
  try {
    // const token = yield select(selectToken);
    // yield call(getPromotedSectionTitle, token);
    yield put(
      editPromotedBrandsTitleSuccess({
        en_title: payload.en_title,
        ar_title: payload.ar_title,
      })
    );
    // yield put(editPromotedBrandsTitleSuccess(payload));
    notify.show("Title has been updated successfully ", "success");
  } catch (e) {
    // yield put(setSubmitFailed("editBrand"));
    notify.show("An error has occurred", "error");
    yield put(editPromotedBrandsTitleFailure(e));
  }
}

export function* watchEditPromotedSectionTitleSaga() {
  yield takeLatest(
    editPromotedBrandsTitleAction.requested,
    editPromotedSectionTitleSaga
  );
}
