import { on, reducer } from "ts-action";
import {
  IAccountManager,
  getAdminsByRoleSuccess,
} from "../actions/admins-by-role-actions";
import { getAdminsByRoleAction } from "../actions/constants";
import { withLoadingReducer } from "../../../../redux-store/reducers/withLoadingState";
import {
  assignAccountManagerFailure,
  assignAccountManagerSuccess,
  setAccountManager,
} from "../actions/assignAccountManager";
import { getAccountManagerSuccess } from "../actions/getAccountManager";
import { resetAppState } from "../../../../redux-store/actions";

interface IGetAccountManagersState {
  prev_store_account_manager: string;
  store_account_manager: string;
  store_id: string;
  account_manager: IAccountManager[];
}
const initialState: IGetAccountManagersState = {
  prev_store_account_manager: "",
  store_account_manager: "",
  account_manager: [],
  store_id: "",
};
export const getAdminsByRoleReducer = withLoadingReducer<
  IGetAccountManagersState
>(
  reducer<IGetAccountManagersState>(
    [
      on(resetAppState, (state, {}) => ({
        ...initialState,
      })),
      on(getAdminsByRoleSuccess, (state, { payload }) => ({
        ...state,
        account_manager: payload.data,
      })),
      on(setAccountManager, (state, { payload }) => ({
        ...state,
        prev_store_account_manager: payload.data.prev_store_account_manager,
        store_account_manager: payload.data.store_account_manager,
      })),
      on(assignAccountManagerSuccess, (state, { payload }) => {
        return {
          ...state,
          store_account_manager: payload,
        };
      }),
      on(assignAccountManagerFailure, (state, { payload }) => {
        return {
          ...state,
          store_account_manager: state.prev_store_account_manager,
        };
      }),
      on(getAccountManagerSuccess, (state, { payload }) => {
        return {
          ...state,
          prev_store_account_manager: "",
          store_id: payload.store_id,
          store_account_manager: payload.account_manager,
        };
      }),
    ],
    initialState
  ),
  getAdminsByRoleAction
);