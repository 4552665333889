import { ICashbackDetails } from "../../redux-store/actions";
import axios from "../axios";
import { cashbackDetailsURL } from "../requests";

export const editCashbackDetailsApi = (
  token: string,
  payload: Omit<ICashbackDetails, "id"> & { store_id: string }
) => {
  return axios.put(cashbackDetailsURL, payload, {
    headers: { token },
    baseURL: process.env.PICKUP_ENDPOINT
  });
};
