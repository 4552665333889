import React, { FormEvent } from "react";
import * as styles from "./styles.module.sass";
import "react-datetime/css/react-datetime.css";
import { RenderDatePicker } from "../../Components/FormElements/RenderDatePicker";
import { required } from "../../redux-store/validators";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { hot } from "react-hot-loader";
import { RenderDropDownSelect } from "../../Components/FormElements/RenderDropDownSelect";
import { paymentTermsSelectItems } from "../../constants";
import { RenderTextAreaGenericField } from "../../Components/FormElements/RenderTextAreaGenericField";
import PaymentModal from "../ConfirmationModal/index";
import { IStore } from "../../axios/getBrands";

interface IProps {
  id: string;
  title: string;
  disabled?: boolean;
  verifyPayment: () => void;
  initialValues: IStore;
  brand: IStore;
}

interface IState {
  modal: boolean;
}
class PaymentForm extends React.Component<IProps & InjectedFormProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  public openPasswordModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  public handleSubmit = (e: FormEvent) => {
    if (!this.props.submitting) {
      e.preventDefault();
      this.props.touch("payment_date", "payment_term", "comment");
      if (this.props.valid) {
        this.openPasswordModal();
      }
    }
  };

  public render() {
    return (
      <form
        autoComplete="off"
        onSubmit={this.handleSubmit}
        className={styles.wrapper}
      >
        <div className={styles.card}>
          <h1>{this.props.title} payment</h1>
          <Field
            disabled={this.props.disabled}
            className="fullWidth"
            name="payment_date"
            component={RenderDatePicker as any}
            label="Payment date"
            validate={[required]}
          />

          <Field
            disabled={this.props.disabled}
            className="fullWidth"
            name="payment_term"
            component={RenderDropDownSelect as any}
            options={paymentTermsSelectItems}
            label="Payment term"
            validate={[required]}
          />
          <Field
            name="comment"
            component={RenderTextAreaGenericField as any}
            label="comment(Optional)"
          />
          <button type="submit" className={styles.btn}>
            {this.props.title.split(" ")[0]} Payment
          </button>
        </div>
        <PaymentModal
          verifyPayment={this.props.verifyPayment}
          title={this.props.title.split(" ")[0]}
          brandName={this.props.brand.name}
          modal={this.state.modal}
          toggle={this.openPasswordModal}
        />
      </form>
    );
  }
}
const Payment = reduxForm({
  form: "paymentForm",
})(PaymentForm);
export default hot(module)(Payment) as any;
