import * as constants from "../../constants";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";
import { union } from "ts-action";
import {
  getReachCampaigns,
  getReachCampaignsFailure,
  getReachCampaignsSuccess,
  IReachCampaignsResponse,
} from "../actions/getReachCampaignsActions";

const COMPLETED = "completed";
const IN_PROGRESS = "inProgress";
const FAILED = "failed";
const CAMPAIGNS = "CAMPAIGNS";
const CAMPAIGNS_COUNT = "CAMPAIGNS_COUNT";

export interface IReachCampaignsReducerState {
  [COMPLETED]: boolean;
  [IN_PROGRESS]: boolean;
  [FAILED]: boolean;
  [CAMPAIGNS]: IReachCampaignsResponse[];
  [CAMPAIGNS_COUNT]: number;
}

const initialState: IReachCampaignsReducerState = Map({
  [COMPLETED]: false,
  [IN_PROGRESS]: false,
  [FAILED]: false,
  [CAMPAIGNS]: [],
}).toJS();

const allActions = union({
  getReachCampaigns,
  getReachCampaignsSuccess,
  getReachCampaignsFailure,
});
type actions = typeof allActions;
const paymentLogsReducer = (
  state: IReachCampaignsReducerState = initialState,
  action: actions
): IReachCampaignsReducerState => {
  switch (action.type) {
    case constants.getReachCampaignsAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(COMPLETED, true);
        map.set(FAILED, false);
        map.set(IN_PROGRESS, false);
        const data = action.payload as {
          campaigns: IReachCampaignsResponse[];
          total_campaigns: number;
        };
        map.set(CAMPAIGNS, data.campaigns);
        map.set(CAMPAIGNS_COUNT, data.total_campaigns);
      });
    }
    case constants.getReachCampaignsAction.requested: {
      return mutateState(state, (map) => {
        map.set(COMPLETED, false);
        map.set(FAILED, false);
        map.set(IN_PROGRESS, true);
      });
    }
    case constants.getReachCampaignsAction.rejected: {
      return mutateState(state, (map) => {
        map.set(COMPLETED, false);
        map.set(FAILED, true);
        map.set(IN_PROGRESS, false);
      });
    }
    default:
      return state;
  }
};

export default paymentLogsReducer;
