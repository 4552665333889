import React from "react";
import * as styles from "./styles.module.sass";
import { GenericButton } from "../../SharedComponent/GenericButtonWithIcon";
import { BannerCardItem } from "./BannerCardItem";
import {
  // BannerCardTargeting,
  // BannerCardType,
  IBannerCardBase,
  IGetBannerCardItem,
} from "../types";
import ConstructSortableDnDList, {
  ISortableDnDListProps,
} from "../SortableDnDList";
import { useSelect } from "../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import {
  getBannerCards,
  reOrderBannerCard,
} from "../redux-store/actions/bannerCardsActions";
import AddBannerCard from "./AddBannerCard";

// const tempCard = {
//   image: "https://placekeanu.com/340/120",
//   id: "adfad",
//   order: 1,
//   target_users: "122",
//   type: BannerCardType.screen,
//   value: "23123",
//   visibility: BannerCardTargeting.public
// };

const SortableDnDList = ConstructSortableDnDList<IBannerCardBase>(
  "orderingItem"
);

const renderItem: ISortableDnDListProps<IGetBannerCardItem>["renderItem"] = (
  item,
  isDragging,
  connectDragSource,
  connectDragPreview,
  connectDropTarget,
  position
) => {
  return (
    <>
      <div className={styles.secondWrapper} key={item.id}>
        <BannerCardItem
          cardData={item}
          dndIntegration={{
            connectDragPreview,
            connectDragSource,
            connectDropTarget,
            isDragging,
            position,
          }}
        />
      </div>
    </>
  );
};

export const BannerCards: React.FC = () => {
  const { cards, cardsById } = useSelect((state) => state.bannerCardsReducer);
  const dispatch = useDispatch();
  const cardsData = React.useMemo(() => cards.map((id) => cardsById[id]), [
    cards,
    cardsById,
  ]);
  const handleReorder = React.useCallback(
    (item: IGetBannerCardItem, nextIndex: number) => {
      dispatch(reOrderBannerCard({ ...item, order: nextIndex + 1 }));
    },
    []
  );
  React.useEffect(() => {
    dispatch(getBannerCards());
  }, []);
  const [isAddFormOpen, setIsAddFormOpen] = React.useState(false);
  const closeAddForm = React.useCallback(() => setIsAddFormOpen(false), []);
  const openAddForm = React.useCallback(() => setIsAddFormOpen(true), []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>Total banner cards ({cards.length})</div>
        <GenericButton
          onClick={openAddForm}
          label="Add banner card"
          className={styles.addCardCTA}
          IconClass={styles.addIcon}
          iconPath={require("../../../assets/add.svg")}
        />
      </div>
      <div className={styles.cardsWrapper}>
        <div className={styles.addNewCardPlaceholder} onClick={openAddForm}>
          <div className={styles.addImg}>
            <i className={"icon-add"} />
          </div>
        </div>
        <SortableDnDList
          data={cardsData}
          renderItem={renderItem}
          onReOrder={handleReorder}
        />
      </div>
      <AddBannerCard closeForm={closeAddForm} open={isAddFormOpen} />
    </div>
  );
};
