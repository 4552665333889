import axios from "./axios";
import { redemptionNumber } from "./requests";
import { IStatsFilters } from "./types";

export const getStoreRedemptionNumber = (
  token: string,
  store_id: string,
  filters: IStatsFilters
) => {
  return axios.get(`${redemptionNumber}/${store_id}`, {
    baseURL: process.env.LUMEN_ENDPOINT,
    params: filters,
    headers: {
      token,
    },
  });
};
