import * as React from "react";
import * as styles from "./styles.module.sass";
import IcoMoon from "react-icomoon";
import NavigationItems from "./NavigationItems/NavigationItems";
import { hot } from "react-hot-loader";

interface IProps {
  logout: () => void;
}

class NavBar extends React.Component<IProps, {}> {
  public render() {
    return (
      <nav className={styles.navBar}>
        <div className={styles.logo}>
          <img src={require("../../assets/logo.svg")} />
        </div>
        <div className={styles.items}>
          <NavigationItems />
          <button onClick={this.props.logout} className={styles.btn}>
            <IcoMoon icon="exit" /> Logout
          </button>
        </div>
      </nav>
    );
  }
}

export default hot(module)(NavBar);
