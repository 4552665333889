import React, { useRef } from "react";
import { Modal } from "@material-ui/core";
import styles from "./styles.module.sass";
import { useMemo } from "react";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { useEffect } from "react";
import {
  Select,
  ISelectItemData,
} from "../../../../Components/FormElements/Select";
import Datetime from "react-datetime";
import moment from "moment";

interface IProps {
  onClose: () => void;
  open: boolean;
  loading: boolean;
  title: string;
  dropdownType: "single-select" | "date-picker";
  options?: ISelectItemData[];
  dropdownLabel: string;
  dropdownPlaceholder?: string;
  mutate: any;
  initialValue?: any;
  name?: string
}

export const DropdownsPopup: React.FC<IProps> = ({
  onClose,
  open,
  loading,
  title,
  dropdownLabel,
  options,
  dropdownPlaceholder,
  mutate,
  initialValue,
  dropdownType,
  name
}) => {
  const [value, setValue] = useState<any>(
    (options || [])[0]?.value ?? initialValue
  );
  const handleChange = (newValue) => {
    if (newValue) {
      setValue(dropdownType === "single-select" ? newValue : newValue._d);
    }
  };

  const initialValues = useMemo(
    () =>
      (options || []).reduce(
        (acc, next) => ({ ...acc, [next.value]: next }),
        {}
      ),
    [options]
  );

  useEffect(() => {
    if (initialValues[initialValue]?.value) {
      setValue(initialValues[initialValue]?.value);
    }
    if (dropdownType === "date-picker") {
      setValue(initialValue ? initialValue : moment().utc());
    }
  }, [initialValues, initialValue, dropdownType]);

  const submitBtnIsDisabled = useMemo(() => {
    let status: boolean = false;
    if (value) {
      if (dropdownType === "date-picker") {
        if (
          loading ||
          (initialValue &&
            initialValue === Number(moment.utc(value).format("x")))
        ) {
          status = true;
        } else {
          status = false;
        }
      } else if (
        loading ||
        [value, value?.value].includes(initialValues[initialValue]?.value)
      ) {
        status = true;
      } else {
        status = false;
      }
    } else {
      status = false;
    }
    return status;
  }, [dropdownType, loading, initialValue, value]);

  const ref = useRef<any>(null);
  return (
    <Modal className={styles.main} onClose={onClose} open={open}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          <div onClick={onClose} className={styles.closeWrapper}>
            <img
              className={styles.closeIcon}
              src={require("../../../../assets/feather _ cross.svg")}
            />
          </div>
        </div>
        <div className={styles.select}>
          {dropdownType === "date-picker" ? (
            <React.Fragment>
              <span className={styles.dropdownLabel}>{dropdownLabel}</span>
              <div className={styles.dateTimeWrapper}>
                <Datetime
                  onChange={handleChange}
                  disableOnClickOutside={true}
                  timeFormat={false}
                  ref={ref}
                  dateFormat={"D MMM YYYY"}
                  className={styles.datePicker}
                  value={value}
                />
                <img
                  className={styles.dropdownIcon}
                  onClick={() => ref.current.openCalendar(true)}
                  src={require("../../../../assets/chevron-down.svg")}
                />
              </div>
            </React.Fragment>
          ) : (
            <Select
              label={dropdownLabel}
              options={(options as ISelectItemData[]) || []}
              placeholder={dropdownPlaceholder}
              onChange={handleChange}
              initialValue={initialValues[initialValue]}
            />
          )}
        </div>

        <div className={styles.buttonWrapper}>
          <button
            disabled={submitBtnIsDisabled}
            onClick={() => {
              mutate(
                dropdownType === "single-select" && name
                  ? { [name]: value.value }
                  : {
                      operating_start_date: Number(
                        moment.utc(value).format("x")
                      ),
                    }
              );
            }}
            className={
              submitBtnIsDisabled ? styles.buttonDisabled : styles.button
            }
          >
            {loading ? <ClipLoader size={24} color="#fff" /> : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DropdownsPopup;
