import React from "react";
import styles from "./styles.module.sass";

const Tabs: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { disabled?: boolean }
> = ({ children, className, disabled, style }) => {
  return (
    <div
      className={`${
        disabled ? styles.disabledWrapper : styles.wrapper
      } ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};
export default Tabs;
