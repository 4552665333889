import * as React from "react";
import * as styles from "../../../../../FormElements/styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import { BeatLoader } from "react-spinners";

interface IProps {
  label: string;
  type: string;
  placeholder: string;
  viewOnly?: boolean;
  className?: string;
  noValidationMessages?: boolean;
  disabled?: boolean;
  loading?: boolean;
  validationStyles?: {
    valid: React.CSSProperties;
    invalid: React.CSSProperties;
  };
  validationClassNames?: {
    valid: string;
    invalid: string;
  };
  postFix: string;
}

export const RenderPostFixedField: React.FC<WrappedFieldProps & IProps> = (
  props
) => {
  const {
    input,
    viewOnly,
    label,
    type,
    placeholder,
    meta: { touched, error, valid, asyncValidating, invalid, active },
    noValidationMessages,
    className,
    disabled,
    loading,
    postFix,
  } = props;

  const shouldShowLoading = asyncValidating || loading;

  const canValidate = !shouldShowLoading && touched;
  const isInvalid = canValidate && invalid;
  const isValid = canValidate && valid;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleClickWrapper = React.useCallback(() => {
    inputRef.current?.focus();
  }, []);
  return (
    <div
      onClick={handleClickWrapper}
      className={`${styles.commissionWrapper}  ${className || ""}`}
    >
      {label && (
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
        </label>
      )}
      <div
        style={{
          ...(active ? { borderColor: "#fdbb11" } : {}),
          ...(isValid ? { borderColor: "#38c172" } : {}),
          ...(isInvalid ? { borderColor: "#db3030" } : {}),
        }}
        className={`${styles.inputAndCommissionLoadingWrapper}`}
      >
        <input
          ref={inputRef}
          disabled={viewOnly || disabled}
          autoComplete="off"
          placeholder={placeholder}
          className={styles.input}
          id={input.name}
          {...input}
          value={String(input.value)}
          type={type}
        />
        {shouldShowLoading && (
          <BeatLoader loading={true} size={7} color={"#FEBE19"} />
        )}
        <div className={styles.postfixWrapper}>
          <span className={styles.postfix}>{postFix}</span>
        </div>
      </div>
      {!noValidationMessages && touched && error && (
        <p
          style={{
            margin: 0,
            padding: "1rem",
            color: "#db3030",
            fontSize: "1.2rem",
          }}
        >
          *{error}
        </p>
      )}
    </div>
  );
};
