import React, { useEffect, useMemo, useState } from "react";
import styles from "../../styles.module.sass";
import { IItem } from "../../../../types";
import { LazyImage } from "../../../../../../../UIComponents/LazyImage/index";
import { prefixImage } from "../../../../../../../Components/Profile";
import * as moment from "moment";
import { useDispatch } from "react-redux";
import { editPromotedBrand } from "../../../../../../../redux-store/actions/edtitPromotedBrandActions";
import { IStore } from "../../../../../../../axios/getBrands";
import { useSelect } from "../../../../../../../hooks/useSelect";
import { IRootReducerState } from "../../../../../../../redux-store/rootReducer";
import { ClipLoader } from "react-spinners";
const countries = {
  ae: "🇦🇪 United Arab Emirates",
  eg: "🇪🇬 Egypt",
  sa: "🇸🇦 Saudi Arabia",
  om: "🇴🇲 Oman",
};
const Item: React.FC<IItem> = ({ brand }: IItem) => {
  const dispatch = useDispatch();
  const makeBrandPromoted = ({
    body,
  }: {
    store: IStore;
    body: {
      promoted_status: "add" | "remove";
    };
  }) => {
    dispatch(
      editPromotedBrand({
        store: brand,
        body,
      })
    );
  };
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const { isLoading } = useSelect((state: IRootReducerState) => ({
    isLoading: state.promotedBrandsReducer.isLoading,
  }));
  const isActionLoading = useMemo(() => isLoading === "loading" && isSelected, [
    isLoading,
    isSelected,
  ]);
  useEffect(() => {
    if (isLoading !== "loading") {
      setIsSelected(false);
    }
  }, [isLoading]);
  return (
    <div className={styles.tableRow}>
      <div className={styles.brandInfo}>
        <div className={styles.image}>
          <LazyImage
            src={
              brand?.store_image
                ? prefixImage(brand?.store_image)
                : require("../../../../../../../assets/admin-placeholder-profile.svg")
            }
            className={styles.brandIcon}
            placeholder={require("../../../../../../../assets/admin-placeholder-profile.svg")}
          />
        </div>
        <div className={styles.content}>
          <p className={styles.brandName}>{brand.en_name}</p>
          <p className={styles.country}>{countries[brand.country_iso_code]}</p>
        </div>
      </div>
      <div className={styles.dateAdded}>
        <span>{moment(brand?.promoted_at).format("L")}</span>
      </div>
      <div className={styles.actions}>
        <button
          className={`${styles.btn}`}
          type={"button"}
          disabled={isActionLoading}
          onClick={() => {
            setIsSelected(true);
            makeBrandPromoted({
              store: brand,
              body: {
                promoted_status: "remove",
              },
            });
          }}
        >
          {isActionLoading ? (
            <ClipLoader color=" #f09440" size={45} />
          ) : (
            <img src={require("../../../../../../../assets/delete.svg")} />
          )}
        </button>
      </div>
    </div>
  );
};

export default Item;
