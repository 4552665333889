import { takeLatest, put, select, call } from "redux-saga/effects";
import {
  getPromotionStoresFailure,
  getPromotionStoresSuccess,
} from "../actions/promoCodeActions";
import { selectToken } from "../../../../redux-store/selectors";
import { getPromotionStoresAPI } from "../../axios/getPromotionStores";
import { getPromotionStoresAction } from "../actions/constants";

function* getPromotionStoresSaga(action) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getPromotionStoresAPI, token, action.payload);
    const data = res.data.map((item) => ({
      id: item.id,
      name: item.name,
    }));
    yield put(getPromotionStoresSuccess(data));
  } catch (e) {
    yield put(getPromotionStoresFailure(e));
  }
}
export function* watchGetPromotionStoresSaga() {
  yield takeLatest(getPromotionStoresAction.requested, getPromotionStoresSaga);
}
