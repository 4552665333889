import * as constants from "../../constants";
import {
  IGetStorePromoCodesAction,
  IGetStorePromoCodesFulfillAction,
  IGetStorePromoCodesRejectedAction,
} from "../../constants";

export const getPromoCode = (data: {
  stores_id: string[];
  promo_code: string;
}): IGetStorePromoCodesAction => ({
  type: constants.getPromoCodeStoresAction.requested,
  payload: data,
});

export const getPromoCodeSuccess = (
  valid_promo_code: boolean
): IGetStorePromoCodesFulfillAction => ({
  type: constants.getPromoCodeStoresAction.fulfilled,
  payload: valid_promo_code,
});

export const getPromoCodeFailed = (
  error: string
): IGetStorePromoCodesRejectedAction => ({
  type: constants.getPromoCodeStoresAction.rejected,
  payload: error,
});
