import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IPointOfContact } from "../../types";
import { union } from "ts-action";
import { getPointsOfContactsApi } from "../../axios";
import { getPointsOfContactsAction } from "../../constants";
import {
  getPointsOfContactsFailure,
  getPointsOfContactsSuccess,
  getPointsOfContacts,
} from "../actions";
import { selectToken } from "../selectors";

const actionType = union({ getPointsOfContacts });
function* getPointsOfContactsSaga({
  payload: { store_id },
}: typeof actionType) {
  try {
    const token: string = yield select(selectToken);
    const res: AxiosResponse<IPointOfContact[]> = yield call(
      getPointsOfContactsApi,
      token,
      store_id
    );
    yield put(
      getPointsOfContactsSuccess({ points_of_contacts: res.data, store_id })
    );
  } catch (error) {
    yield put(getPointsOfContactsFailure(error));
  }
}

export function* watchGetPointsOfContactsSaga() {
  yield takeLatest(
    getPointsOfContactsAction.requested,
    getPointsOfContactsSaga
  );
}
