import {
  getBrandsWithTasksSuccess,
  setSelectedTasksAction,
  updateBrandsWithTasksFilter,
  getPointsOfContactsSuccess,
  getContactTemplatesSuccess,
  selectStoreIdAction,
  updateSelectedBrand,
  resetAppState,
  getSelectedActions,
  getBrandsWithTasksReset,
  getBrandsWithTasksFailure,
} from "../actions";
import {
  getBrandsWithTasksAction,
  actionCenterBrandsInitialState,
} from "../../constants";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import {
  IBrandTasks,
  IActionCenterBrandsState,
  ContactMethodType,
} from "../../types";
import {
  convertStoresToIds,
  mapStoresById,
} from "../../helpers/account-managers";

export const actionCenterBrandsReducer = withLoadingReducer<
  IActionCenterBrandsState
>(
  reducer<IActionCenterBrandsState>(
    [
      on(getBrandsWithTasksReset, () => actionCenterBrandsInitialState),
      on(resetAppState, () => actionCenterBrandsInitialState),
      on(updateSelectedBrand, (state, { payload }) => {
        let selectedBrandsById: Record<string, IBrandTasks> =
          state.selectedBrandsById;
        const updateContactTemplateById = (element) => {
          if (payload.contact_templates) {
            return {
              ...element,
              selected:
                payload.contact_templates?.id === element.id
                  ? payload.contact_templates?.selected
                  : element.selected,
              updated_content:
                payload.contact_templates?.id === element.id
                  ? payload.contact_templates?.updated_content
                  : element.updated_content,
            };
          } else {
            return {
              ...element,
              selected: element.id === ContactMethodType.Whatsapp,
              updated_content:
                state.contactTemplatesById[element.id].updated_content,
            };
          }
        };
        if (payload.store_id) {
          selectedBrandsById = {
            ...selectedBrandsById,
            [payload.store_id]: {
              ...selectedBrandsById[payload.store_id],
              contact_templates: selectedBrandsById[
                payload.store_id
              ].contact_templates.map((contact_template) => ({
                ...updateContactTemplateById(contact_template),
              })),
            },
          };
        } else {
          selectedBrandsById = mapStoresById(
            Object.values(selectedBrandsById).map((brand) => ({
              ...brand,
              contact_templates: brand.contact_templates.map(
                (contact_template) => ({
                  ...updateContactTemplateById({
                    ...contact_template,
                  }),
                })
              ),
            }))
          );
        }
        return {
          ...state,
          selectedBrandsById,
        };
      }),
      on(selectStoreIdAction, (state, { payload }) => ({
        ...state,
        selected_store_id: payload,
        resolve_all: !payload.trim(),
      })),
      on(getSelectedActions, (state, _) => ({
        ...state,
        selectedBrandsById: mapStoresById(
          Object.values(state.selectedBrandsById).map((brand) => ({
            ...brand,
            tasks: brand.tasks.map((task) => ({
              ...task,
              page: task.selected ? "take-action" : task.page,
            })),
          }))
        ),
        shownBrandsTasksById: mapStoresById(
          Object.values(state.shownBrandsTasksById).map((brand) => ({
            ...brand,
            tasks: brand.tasks.map((task) => ({
              ...task,
              page: task.selected ? "take-action" : task.page,
            })),
          }))
        ),
      })),
      on(getContactTemplatesSuccess, (state, { payload }) => {
        const newState = (prevState) => {
          return mapStoresById(
            Object.values(prevState).map((s: IBrandTasks) => ({
              ...s,
              contact_templates: payload.map((ctemplate) => ({
                selected: ctemplate.id === ContactMethodType.Whatsapp,
                id: ctemplate.id,
                updated_content: ctemplate.content,
              })),
            }))
          );
        };
        return {
          ...state,
          contactTemplatesById: payload.reduce(
            (acc, next) => ({
              ...acc,
              [next.id]: {
                id: next.id,
                updated_content: next.content,
              },
            }),
            {}
          ),
          brandsById: newState(state.brandsById),
          shownBrandsTasksById: newState(state.shownBrandsTasksById),
        };
      }),
      on(getPointsOfContactsSuccess, (state, { payload }) => ({
        ...state,
        selectedBrandsById: {
          ...state.selectedBrandsById,
          [payload.store_id]: {
            ...state.selectedBrandsById[payload.store_id],
            points_of_contacts: payload.points_of_contacts,
          },
        },
        shownBrandsTasksById: {
          ...state.shownBrandsTasksById,
          [payload.store_id]: {
            ...state.shownBrandsTasksById[payload.store_id],
            points_of_contacts: payload.points_of_contacts,
          },
        },
      })),
      on(getBrandsWithTasksFailure, (state, { payload }) => ({
        ...state,
        account_manager_id: payload.account_manager_id,
        brandsById: {},
        brandsIds: [],
        shownBrandsTasksById: {},
        shownBrandsTasksIds: [],
        selectedBrandsById: {},
        selectedBrandsTasksIds: [],
      })),
      on(getBrandsWithTasksSuccess, (state, { payload }) => {
        // Convert stores to arrays of ids and map by id for efficient lookups
        const brandsIds = convertStoresToIds(payload.data);
        const brandsById = mapStoresById(payload.data);

        return {
          ...state,
          brandsIds,
          brandsById,
          selectedBrandsTasksIds: [],
          selectedBrandsById: {},
          shownBrandsTasksIds: brandsIds,
          shownBrandsTasksById: brandsById,
          account_manager_id: payload.account_manager_id,
        };
      }),
      on(setSelectedTasksAction, (state, { payload }) => {
        // Create copies of the objects to avoid mutation
        const shownBrandsTasksById: Record<string, IBrandTasks> = {
          ...state.shownBrandsTasksById,
        };
        let selectedBrandsById: Record<string, IBrandTasks> = {
          ...state.selectedBrandsById,
        };
        const selectedBrandsTasksIds: string[] = [
          ...state.selectedBrandsTasksIds,
        ];

        if (payload.store_id) {
          // Get the brand tasks for the selected store
          const brandTasks = shownBrandsTasksById[payload.store_id].tasks;

          if (payload.task) {
            // Update the selected property of the selected task
            const updatedTasks = brandTasks.map((t) =>
              t.id === payload.task?.id
                ? { ...t, selected: payload.selected }
                : t
            );

            // Update the shownBrandsTasksById and selectedBrandsById objects
            shownBrandsTasksById[payload.store_id] = {
              ...shownBrandsTasksById[payload.store_id],
              tasks: updatedTasks,
            };
            selectedBrandsById[payload.store_id] = {
              ...(selectedBrandsById[payload.store_id]
                ? selectedBrandsById[payload.store_id]
                : shownBrandsTasksById[payload.store_id]),
              tasks:
                payload.page === "take-action"
                  ? updatedTasks
                  : updatedTasks.filter((t) => t.selected),
            };

            // Update the selectedBrandsTasksIds array
            if (payload.selected) {
              if (!selectedBrandsTasksIds.includes(payload.store_id)) {
                selectedBrandsTasksIds.push(payload.store_id);
              }
            } else {
              const index = selectedBrandsTasksIds.indexOf(payload.store_id);
              if (
                payload.page === "action-center" &&
                index !== -1 &&
                !selectedBrandsById[payload.store_id].tasks.length
              ) {
                selectedBrandsTasksIds.splice(index, 1);
                delete selectedBrandsById[payload.store_id];
              }
            }
          } else {
            // Update the selected property of all tasks for the selected store
            const updatedTasks = brandTasks.map((t) => ({
              ...t,
              selected: payload.selected,
            }));

            // Update the shownBrandsTasksById and selectedBrandsById objects
            shownBrandsTasksById[payload.store_id] = {
              ...shownBrandsTasksById[payload.store_id],
              tasks: updatedTasks,
            };
            selectedBrandsById[payload.store_id] = {
              ...(selectedBrandsById[payload.store_id]
                ? selectedBrandsById[payload.store_id]
                : shownBrandsTasksById[payload.store_id]),
              tasks:
                payload.page === "take-action"
                  ? updatedTasks
                  : updatedTasks.filter((t) => t.selected),
            };

            // Update the selectedBrandsTasksIds array
            if (payload.selected) {
              if (!selectedBrandsTasksIds.includes(payload.store_id)) {
                selectedBrandsTasksIds.push(payload.store_id);
              }
            } else {
              const index = selectedBrandsTasksIds.indexOf(payload.store_id);
              if (
                payload.page === "action-center" &&
                index !== -1 &&
                !selectedBrandsById[payload.store_id].tasks.length
              ) {
                selectedBrandsTasksIds.splice(index, 1);
                delete selectedBrandsById[payload.store_id];
              }
            }
          }
        } else {
          // Update the selected property of all tasks for all stores
          for (const brandTasks of Object.values(shownBrandsTasksById)) {
            const updatedTasks = brandTasks.tasks.map((t) => ({
              ...t,
              selected: payload.selected,
            }));
            shownBrandsTasksById[brandTasks.store_id] = {
              ...brandTasks,
              tasks: updatedTasks,
            };
            selectedBrandsById[brandTasks.store_id] = {
              ...(selectedBrandsById[brandTasks.store_id]
                ? selectedBrandsById[brandTasks.store_id]
                : shownBrandsTasksById[brandTasks.store_id]),
              tasks:
                payload.page === "take-action"
                  ? updatedTasks
                  : updatedTasks.filter((t) => t.selected),
            };
          }
          // Update the selectedBrandsTasksIds array
          if (payload.selected) {
            const brandIds = Object.keys(shownBrandsTasksById);
            selectedBrandsTasksIds.push(...brandIds);
          } else {
            if (payload.page === "action-center") {
              selectedBrandsTasksIds.length = 0;
              selectedBrandsById = {};
            }
          }
        }

        return {
          ...state,
          shownBrandsTasksById,
          selectedBrandsById,
          selectedBrandsTasksIds: [...new Set(selectedBrandsTasksIds)],
        };
      }),
      on(updateBrandsWithTasksFilter, (state, { payload }) => {
        const { alert_types, account_manager_id, sort } = payload;

        // If no filter criteria are provided, return the original state with all brands and tasks shown
        if (!alert_types.length && !account_manager_id.trim()) {
          return {
            ...state,
            shownBrandsTasksIds: state.brandsIds,
            shownBrandsTasksById: state.brandsById,
            alert_types: [],
          };
        }

        // Otherwise, apply the provided filter criteria and sort the tasks based on the provided sort direction
        const shownBrandsTasksById = state.brandsIds.reduce((acc, brandId) => {
          const brand = state.brandsById[brandId];
          const tasks = brand.tasks.filter(
            (task) =>
              alert_types.includes(task.alert_type) || !alert_types.length
          );
          if (tasks.length) {
            acc[brandId] = { ...brand, tasks };
            if (sort && sort.store_id === brandId) {
              acc[brandId].tasks = tasks
                .slice()
                .sort((a, b) =>
                  sort.direction === "asc"
                    ? a.alert_percentage - b.alert_percentage
                    : b.alert_percentage - a.alert_percentage
                );
            }
          }
          return acc;
        }, {});

        const shownBrandsTasksIds = state.brandsIds.filter((brandId) =>
          shownBrandsTasksById.hasOwnProperty(brandId)
        );

        return {
          ...state,
          account_manager_id,
          alert_types,
          shownBrandsTasksById,
          shownBrandsTasksIds,
          selectedBrandsById: {},
          selectedBrandsTasksIds: [],
        };
      }),
    ],
    actionCenterBrandsInitialState
  ),
  getBrandsWithTasksAction
);
