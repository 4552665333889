import axios from "./axios";
import * as Requests from "./requests";
export interface IParams {
  rank?: number;
  country_iso_code?: string;
}
export const getPromocodeByRankApi = async (token: string, params: IParams) => {
  const { data } = await axios.get(Requests.getPromocodeByRankURl, {
    headers: {
      token,
    },
    params: {
      ...params,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
  return data;
};
