import { call, put, select, takeLatest } from "redux-saga/effects";
import { addBrandFailure, addBrandSuccess } from "../actions/addBrandActions";
import { addBrandAction } from "../../constants";
import { IFormState } from "../rootReducer";
import { selectForm, selectToken } from "../selectors";
import { addBrand } from "../../axios/addBrand";
import { getBrands } from "../actions";
import { addManager } from "../../axios/addManager";
import {
  reset,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
} from "redux-form";
import { notify } from "react-notify-toast";
import { delay } from "redux-saga/effects";
import { IAddBrandAction } from "../../types";
import { isValid, touch } from "redux-form";

export const PAID_FORM_FIELDS = ["activation_date", "payment_term"];
export const TRIAL_FORM_FIELDS = ["start_date", "trial_duration"];

function* addBrandSaga(action: IAddBrandAction) {
  try {
    yield put(startSubmit("addBrand"));
    const form: IFormState = yield select(selectForm);
    const token: string = yield select(selectToken);
    const formName = action.payload ? "trialForm" : "paidForm";
    const formFields = action.payload ? TRIAL_FORM_FIELDS : PAID_FORM_FIELDS;
    const isFormValid = yield select(isValid(formName));
    if (!isFormValid) {
      yield put(touch(formName, ...formFields));
      throw new Error("Forms are not valid");
    } else {
      const {
        brand_name,
        email,
        phone_number,
        country_iso_code,
        timezone,
        account_manager_id
      } = form.addBrand.values as any;
      const brand: any = {
        name: brand_name.trim(),
        country_code: country_iso_code.country_code,
        country_iso_code: country_iso_code.ISO_code,
        timezone,
        account_manager_id
      };
      if (action.payload) {
        const { start_date, trial_duration } = form.trialForm.values as any;
        brand.activation_date = start_date;
        brand.trial_duration = trial_duration;
      } else {
        const { activation_date, payment_term } = form.paidForm.values as any;
        brand.activation_date = activation_date;
        brand.payment_term = payment_term;
      }
      const manager: any = {
        email: email.trim().toLowerCase(),
        phone_number,
        countryCode: country_iso_code.country_code
      };
      const managerRes = yield call(addManager, token, manager);
      const res = yield call(addBrand, token, {
        ...brand,
        manager_id: managerRes.data.manager[0].id,
      });
      if (res.status === 200) {
        yield put(addBrandSuccess(res.data.store));
        yield put(setSubmitSucceeded("addBrand"));
        yield put(getBrands({ group: { label: "All groups", value: -1 } }));
        yield delay(500);
        yield put(reset("addBrand"));
      }
    }
  } catch (e) {
    yield put(setSubmitFailed("addBrand"));
    if (e.response && e.response.status === 403) {
      notify.show("This email is already used.", "error");
    } else {
      notify.show("An error has occurred", "error");
    }
    yield put(addBrandFailure(e));
  }
}

export function* watchAddBrandSaga() {
  yield takeLatest(addBrandAction.requested, addBrandSaga);
}
