import React from "react";
interface IProps {
  color?: string;
  className?: string;
  onClick?: () => void;
}
const KeyboardArrowDownRounded: React.FC<IProps> = ({
  color,
  className,
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <defs>
        <path
          id="zd580141za"
          d="M.17 4.496c-.227-.228-.227-.597 0-.825l3.5-3.5c.229-.228.598-.228.826 0l3.5 3.5c.228.228.228.597 0 .825-.228.228-.597.228-.825 0L4.083 1.408.996 4.496c-.228.228-.597.228-.825 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-793.000000, -307.000000) translate(210.000000, 192.000000) translate(544.000000, 101.000000) translate(39.000000, 0.000000) translate(0.000000, 14.000000) translate(2.916667, 4.666667)">
                    <mask id="84zi0qxtmb" fill="#fff">
                      <use xlinkHref="#zd580141za" />
                    </mask>
                    <use
                      fill={color}
                      transform="translate(4.083329, 2.333328) scale(1, -1) translate(-4.083329, -2.333328)"
                      xlinkHref="#zd580141za"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default KeyboardArrowDownRounded;
