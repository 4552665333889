import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IStore } from "./getBrands";

export const editManager = (token, data): AxiosReturn<IStore> => {
  return axios.put(Requests.editManager(data.manager_id), data, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
