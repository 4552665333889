import moment from "moment";
import * as yup from "yup";
export const cashbackDetailsSchema = yup.object().shape({
  promo_share_max_cpo: yup
    .string()
    .optional()
    .test(
      "within_range",
      "Maximum CPO should have value between 0 and 100.",
      (value, parent) => {
        const isValid: boolean =
          typeof value === "undefined" ||
          (Number(value) <= 100 && Number(value) >= 0);
        return isValid;
      }
    ),

  start_date: yup.number().required("Start date is required."),
  end_date: yup
    .number()
    .required("End date is required.")
    .test(
      "is-after-start-date",
      "End data must be after start date.",
      (value, parent) => {
        return moment(new Date(value || 0)).isAfter(parent.parent.start_date);
      }
    ),
});
