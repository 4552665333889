import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getBrandPosLoyaltyIntegrationStatusAction,
  getBrandPosOrderingIntegrationStatusAction,
} from "../../constants";
import {
  getBrandPosLoyaltyIntegrationStatus,
  getBrandPosOrderingIntegrationStatus,
  getBrandPosOrderingIntegrationStatusFailure,
  getBrandPosLoyaltyIntegrationStatusFailure,
  getBrandPosOrderingIntegrationStatusSuccess,
  getBrandPosLoyaltyIntegrationStatusSuccess,
} from "../actions";
import { selectToken } from "../selectors";
import { getBrandPosIntegrationStatusApi } from "../../axios/pos";

function* getBrandPosIntegrationStatusSaga({
  payload,
  type,
}:
  | ReturnType<typeof getBrandPosLoyaltyIntegrationStatus>
  | ReturnType<typeof getBrandPosOrderingIntegrationStatus>) {
  try {
    const token = yield select(selectToken);
    const apiEndpoint =
      type === getBrandPosLoyaltyIntegrationStatus.type
        ? "loyalty-integration-status"
        : "ordering-integration-status";
    const res = yield call(
      getBrandPosIntegrationStatusApi,
      apiEndpoint,
      token,
      payload.pos_entity_id,
      payload.store_id
    );
    yield put(
      type === getBrandPosLoyaltyIntegrationStatus.type
        ? getBrandPosLoyaltyIntegrationStatusSuccess({
            status: res.data.status,
            pos_entity_id: payload.pos_entity_id,
          })
        : getBrandPosOrderingIntegrationStatusSuccess({
            status: res.data.status,
            pos_entity_id: payload.pos_entity_id,
          })
    );
  } catch (error) {
    yield put(
      type === getBrandPosLoyaltyIntegrationStatus.type
        ? getBrandPosLoyaltyIntegrationStatusFailure(error)
        : getBrandPosOrderingIntegrationStatusFailure(error)
    );
  }
}
export function* watchGetBrandPosIntegrationStatusSaga() {
  yield takeEvery(
    [
      getBrandPosLoyaltyIntegrationStatusAction.requested,
      getBrandPosOrderingIntegrationStatusAction.requested,
    ],
    getBrandPosIntegrationStatusSaga
  );
}
