import React from "react";
import { Modal } from "@material-ui/core";
import styles from "./styles.module.sass";
import { ISelectItemData, Select } from "../../../../../FormElements/Select";
import { UseMutateFunction } from "react-query";
import { AxiosResponse } from "axios";
import {
  gmvOptions,
  OrderingGMVTypes,
} from "../../../../../../atoms/merchantProfile";
import { useMemo } from "react";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { useEffect } from "react";
interface IProps {
  orderingGMVType: string;
  onClose: () => void;
  open: boolean;
  loading: boolean;
  mutate: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    OrderingGMVTypes,
    unknown
  >;
}

export const EditGMVCalculation: React.FC<IProps> = ({
  onClose,
  open,
  mutate,
  orderingGMVType,
  loading,
}) => {
  const [value, setValue] = useState<any>("total_gmv");
  const handleChange = ({ value: itemValue }: ISelectItemData) => {
    if (itemValue) {
      setValue(itemValue);
    }
  };

  const initialValue = useMemo(
    () => gmvOptions.reduce((acc, next) => ({ ...acc, [next.text]: next }), {}),
    [orderingGMVType]
  );
  useEffect(() => {
    if (initialValue[orderingGMVType].value) {
      setValue(initialValue[orderingGMVType].value);
    }
  }, [initialValue[orderingGMVType].value]);
  return (
    <Modal className={styles.main} onClose={onClose} open={open}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <span className={styles.title}>Edit GMV calculation type </span>
          <div onClick={onClose} className={styles.closeWrapper}>
            <img
              className={styles.closeIcon}
              src={require("../../../../../../assets/feather _ cross.svg")}
            />
          </div>
        </div>
        <div className={styles.select}>
          <Select
            label={"GMV calculation type"}
            options={gmvOptions}
            placeholder={"Select GMV calculation type"}
            onChange={handleChange}
            initialValue={
              initialValue[orderingGMVType] ?? {
                text: "Select GMV calculation type",
                value: undefined,
              }
            }
          />
        </div>

        <div className={styles.buttonWrapper}>
          <button
            disabled={loading || initialValue[orderingGMVType].value === value}
            onClick={() => {
              if (initialValue[orderingGMVType].value !== value) {
                mutate(value);
              }
            }}
            className={
              initialValue[orderingGMVType].value === value
                ? styles.buttonDisabled
                : styles.button
            }
          >
            {loading ? <ClipLoader size={24} color="#fff" /> : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditGMVCalculation;
