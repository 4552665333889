import axios from "./axios";
import * as Requests from "./requests";

export const logout = (token) => {
  return axios.post(
    Requests.logout,
    {},
    {
      baseURL: process.env.LUMEN_ENDPOINT,
      headers: {
        token,
      },
    }
  );
};
