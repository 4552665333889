import React, { useEffect, useRef, useState } from "react";

export const LazyImage: React.FC<React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>> = (props) => {
  const [shouldLoad, setShouldLoad] = useState(false);
  const placeholderRef = useRef<any>(null);
  useEffect(() => {
    if (!shouldLoad && placeholderRef.current) {
      const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
        if (intersectionRatio > 0) {
          setShouldLoad(true);
        }
      });
      observer.observe(placeholderRef.current);
      return () => observer.disconnect();
    } else {
      return;
    }
  }, [shouldLoad, placeholderRef]);

  return shouldLoad ? (
    <img {...props} />
  ) : (
    <img
      src={props.placeholder}
      className={props.className}
      ref={placeholderRef}
    />
  );
};
