import React from "react";
import * as styles from "./styles.module.sass";
import SettingsSwitch from "./SettingsSwitch";
import { useShopXSettings } from "./hooks";
import { hot } from "react-hot-loader";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { connect } from "react-redux";
// tslint:disable-next-line: no-empty
export const noop = () => {};

interface IProps {
  token: string;
}

const ShopXSettings: React.FC<IProps> = ({ token }) => {
  const {
    initialLoading,
    submitting,
    toggleUXCam,
    uxCamStatus,
  } = useShopXSettings(token);

  return initialLoading ? (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>ShopX Settings</h1>
      <div className={styles.settingsWrapper}>
        <div onClick={toggleUXCam} className={styles.settingWrapper}>
          <div className={styles.settingText}>
            <div className={styles.settingTitle}>Enable UX Cam recordings</div>
            <div className={styles.settingSubTitle}>
              This option lets you record user sessions for research purposes
            </div>
          </div>
          <SettingsSwitch
            disabled={submitting}
            onChange={noop}
            checked={uxCamStatus}
          />
        </div>
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state: IRootReducerState) => ({
  token: state.authReducer.token,
});

export default hot(module)(connect(mapStateToProps)(ShopXSettings));
