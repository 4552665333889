import {
  ACTION_FILL_EDIT_BRAND,
  ACTION_RESET_EDIT_BRANDS,
} from "../../constants";
import { IStore } from "../../axios/getBrands";
import { mutateState } from "../../helpers/mutate-state";
import { IFillEditBrandAction } from "../../types";

export interface IEditBrandReducerState {
  brand: IStore | undefined;
  filled: boolean;
}

const initialState: IEditBrandReducerState = {
  brand: undefined,
  filled: false,
};

export const editBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_FILL_EDIT_BRAND: {
      return mutateState(state, (map) => {
        map.set("brand", (action as IFillEditBrandAction).payload);
        map.set("filled", true);
      });
    }
    case ACTION_RESET_EDIT_BRANDS: {
      return mutateState(state, (map) => {
        map.set("brand", undefined as any);
        map.set("filled", false);
      });
    }
    default:
      return state;
  }
};
