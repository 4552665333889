import * as React from "react";
import * as styles from "./style.module.sass";
import ViewCategories from "./viewCategories";

const Categories = () => {
  return (
    <div className={styles.wrapper}>
      <ViewCategories />
    </div>
  );
};

export default Categories;
