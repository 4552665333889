import React, { useEffect } from "react";
import { useState } from "react";
import { EditGMVCalculation } from "./components";
import styles from "./styles.module.sass";
import { useOrderingGMVType } from "../../../../hooks/merchantProfile/useOrderingGMVType";

export const GMVCalculation: React.FC<{ store_id: string }> = ({
  store_id,
}) => {
  const { mutate, orderingGMVType, status } = useOrderingGMVType(store_id);
  const [openGMVModal, setOpenGMVModal] = useState(false);
  const handleOpenGMVModal = () => setOpenGMVModal(true);
  const handleCloseGMVModal = () => setOpenGMVModal(false);
  useEffect(() => {
    if (status === "success") {
      handleCloseGMVModal();
    }
  }, [status]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.title}>GMV calculation type</span>
        <div onClick={handleOpenGMVModal} className={styles.buttonWrapper}>
          <img
            src={require("../../../../assets/feather _ edit.svg")}
            className={styles.icon}
          />
        </div>
      </div>
      <span className={styles.type}>{orderingGMVType}</span>
      {openGMVModal && (
        <EditGMVCalculation
          loading={status === "loading"}
          orderingGMVType={orderingGMVType}
          mutate={mutate}
          open={openGMVModal}
          onClose={handleCloseGMVModal}
        />
      )}
    </div>
  );
};

export default GMVCalculation;
