import axios from "./axios";
import * as Requests from "./requests";

export const getCountriesStoresAPI = (token: string, payload: {
  country_code: any,
  promo_id?: string
}) => {
  const countryCodeIsArray = Array.isArray(payload.country_code);
  return axios.get(Requests.getCountriesStoresURL, {
    headers: {
      token,
    },
    params: countryCodeIsArray ? {
      country_codes: payload.country_code,
      promo_id: payload.promo_id
    }: {
      country_code: payload.country_code,
      promo_id: payload.promo_id
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};

// return Axios({
//   method: 'post',
//   url: Requests.getCountriesStoresURL ,
//   data: {
//     country_code: id,
//   },
//   headers: {
//     token
//   },
//   baseURL: process.env.LUMEN_ENDPOINT
// });
