import { call, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import { addWatchers } from "../../axios/addWatcher";
import { selectToken } from "../../redux-store/selectors";
import { addWatchersAction } from "../../constants";
import { handleOptimism } from "./handleOptimism";
function* addWatchersSaga(action) {
  const token = yield select(selectToken);
  const watcher = yield call(addWatchers, token, action.payload);
  return { ...action.payload, watcher: watcher.data };
}

export function* watchAddWatchersSaga() {
  yield takeLatest(
    addWatchersAction.requested,
    handleOptimism(addWatchersSaga, {
      success: actions.adddWatchersSuccess,
      failure: actions.addWatchersFailure,
    })
  );
}
