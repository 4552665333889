import axios from "./axios";
import * as Requests from "./requests";
import { IStoreTag } from "../redux-store/actions/tags-actions";

export const editStoreTagsAPI = (
  token: string,
  data: IStoreTag,
  id: string
) => {
  return axios.put(Requests.deleteStoreTag(id), data, {
    headers: { token },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
