import { IExportFoodicsMenuAction } from "../../types";
import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { exportFoodicsMenuSuccess, exportFoodicsMenuFailure } from "../actions";
import { exportFoodicsMenuAction } from "../../constants";
import { exportFoodicsMenu as exportFoodicsMenuRequest } from "../../axios/exportFoodicsMenu";

function* exportFoodicsMenuSaga(action: IExportFoodicsMenuAction) {
  try {
    const res: AxiosResponse<any> = yield call(
      exportFoodicsMenuRequest,
      action.payload
    );

    yield put(exportFoodicsMenuSuccess(res.data));
  } catch (e) {
    yield put(exportFoodicsMenuFailure(e));
  }
}

export function* watchExportFoodicsMenuSaga() {
  yield takeLatest(exportFoodicsMenuAction.requested, exportFoodicsMenuSaga);
}
