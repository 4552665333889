import React from "react";
import * as styles from "./styles.module.sass";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { connect } from "react-redux";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { ICountry } from "../../types";
import {
  getBrandDetails,
  getCountries,
  exportFoodicsMenu,
  logDashboardPreviewRequest,
} from "../../redux-store/actions";
import { IStore } from "../../axios/getBrands";
import * as moment from "moment";
import Skeleton from "react-skeleton-loader";
import { Status } from "../Card/Status";
import EditModal from "../../UIComponents/EditModal";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from "reactstrap";
import { hot } from "react-hot-loader";
import Cards from "./Cards";
import ScrollToTop from "../../UIComponents/Scroller";
import { HashRouter } from "react-router-dom";
import { getBrand } from "../../axios/getBrand";
import { notify } from "react-notify-toast";
import Payment from "../Payment";
import PaymentLogs from "../PaymentLogs";
import { Aux } from "../../App";
import { paymentStatuses } from "../../helpers/options";
import { DropDownSelect } from "../FormElements/NoRedux/DropDownSelect";
import { getAdminsByRole } from "../TargetedTools/redux-store/actions/admins-by-role-actions";
import { ISelectItemData } from "../FormElements/RenderDropDownSelect";
import {
  assignAccountManager,
  setAccountManager,
} from "../TargetedTools/redux-store/actions/assignAccountManager";
import { getAccountManager } from "../TargetedTools/redux-store/actions/getAccountManager";
import { genericConfirmAction } from "../TargetedTools/BannerCards/lib/confirmDanger";
import { PosIntegration } from "../../pages";

interface IProps {
  brand: IStore;
  getBrandDetails: (brandId: string) => void;
  loaded: boolean;
  failed: boolean;
  loading: boolean;
  token: string;
  countries: ICountry[];
  getCountries: () => void;
  exportFoodicsMenu: (apiKey: string) => void;
  logDashboardPreviewRequest: (store_id: string) => void;
  exportFoodicsMenuFileContent: any;
  exportFoodicsMenuFileIsLoading: boolean;
  exportFoodicsMenuFileIsLoaded: boolean;
  exportFoodicsMenuFileLoadingFailed: boolean;
  getAdminsByRole: (role: string) => void;
  account_managers?: any[];
  store_account_manager: string;
  store_id: string;
  prev_store_account_manager: string;
  assignAccountManager: (data: {
    store_id: string;
    account_manager_id: string;
  }) => void;
  getAccountManager: (data: { store_id: string }) => void;
  setAccountManager: (data: {
    store_account_manager: string;
    prev_store_account_manager: string;
  }) => void;
}

interface IState {
  editModalOpen: boolean;
  cardOptionsOpen: boolean;
  fullData: any;
  tooltipOpen: boolean;
  selectedAccountManager?: ISelectItemData;
}

export const prefixImage = (image: string) => {
  if (image.indexOf("http") === -1) {
    return process.env.IMAGE_BASEURL + image;
  }
  return image;
};

class Profile extends React.Component<
  IProps &
    RouteComponentProps<{
      store_id: string;
    }>,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      editModalOpen: false,
      cardOptionsOpen: false,
      fullData: {},
      tooltipOpen: false,
    };
  }

  public componentDidMount() {
    this.props.setAccountManager({
      store_account_manager: "",
      prev_store_account_manager: "",
    });
    const { store_id } = this.props.match.params;
    this.props.getAdminsByRole("account_manager");
    this.props.getAccountManager({ store_id });
    this.props.getBrandDetails(store_id);
    this.props.getCountries();
  }

  public routeToPayment = () =>
    this.props.history.push(
      `/profile/${this.props.match.params.store_id}/payment`
    );
  public routeToLogs = () =>
    this.props.history.push(
      `/profile/${this.props.match.params.store_id}/logs`
    );
  public toggleEditBrandModal = () => {
    if (!this.state.editModalOpen) {
      this.getBrand(this.props.brand.id)
        .then((res) => {
          const manager = {
            email: res.data.manager.email,
            password: undefined,
            phone_number: res.data.manager.phoneNumber,
            manager_id: res.data.manager.id,
          };
          const store = {
            name: res.data.store.name,
            country_iso_code: this.props.countries.filter(
              (c) => c.ISO_code === res.data.store.country_iso_code
            )[0],
            timezone: res.data.store.timezone,
            status: res.data.store.status,
            activation_date: moment(res.data.store.activation_date, "x"),
            id: res.data.store.id,
            api_key: res.data.store.api_key,
            pos: res.data.store.pos || "none",
          };
          this.setState(
            {
              fullData: { ...store, ...manager },
            },
            () => {
              this.setState((prevState) => ({
                editModalOpen: !prevState.editModalOpen,
              }));
            }
          );
        })
        .catch((e) => {
          notify.show("error happened", "error");
        });
    } else {
      this.setState((prevState) => ({
        editModalOpen: !prevState.editModalOpen,
      }));
    }
  };
  public toggleCardOptions = (e: any) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      cardOptionsOpen: !prevState.cardOptionsOpen,
    }));
  };
  public toggleToolTip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  public getBrand = (store_id: string) => {
    return getBrand(this.props.token, store_id);
  };

  public componentDidUpdate = (prevProps) => {
    const accountManagersOptions: ISelectItemData[] = Array.isArray(
      this.props.account_managers
    )
      ? this.props.account_managers.map((element) => ({
          label: element.name,
          value: element.id,
        }))
      : [];
    if (
      this.props.store_account_manager !== prevProps.store_account_manager &&
      prevProps.store_account_manager !== "" &&
      this.props.store_account_manager !== prevProps.prev_store_account_manager
      //   &&
      // this.props.store_id === this.props.match.params.store_id &&
      // this.props.store_id !== ""
    ) {
      this.setAccountManager(
        this.props.store_account_manager,
        accountManagersOptions
      );
    }
    const {
      exportFoodicsMenuFileContent,
      exportFoodicsMenuFileIsLoading,
      exportFoodicsMenuFileIsLoaded,
      exportFoodicsMenuFileLoadingFailed,
    } = this.props;

    // if a new file content loaded successfully
    if (
      Object.keys(exportFoodicsMenuFileContent).length > 0 &&
      Object.keys(prevProps.exportFoodicsMenuFileContent).length === 0 &&
      !exportFoodicsMenuFileIsLoading &&
      exportFoodicsMenuFileIsLoaded &&
      !exportFoodicsMenuFileLoadingFailed
    ) {
      this.downloadFoodicsMenuJSONFile(exportFoodicsMenuFileContent);
    }
    // if fetching exported json file had some troubles
    if (
      Object.keys(exportFoodicsMenuFileContent).length === 0 &&
      prevProps.exportFoodicsMenuFileIsLoading !==
        exportFoodicsMenuFileIsLoading &&
      !exportFoodicsMenuFileIsLoading &&
      exportFoodicsMenuFileLoadingFailed
    ) {
      notify.show("Something went wrong, please try again later", "error");
    }
  };

  // donwloading (exporting foodics menu json file) by encoding URI Component using file content
  // -> and attaching the encoded data to an anchor tag
  // -> then setting download file name
  // -> then clicking anchor to trigger download
  public downloadFoodicsMenuJSONFile = (fileContent: any) => {
    const { name } = this.props.brand;
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(fileContent));
    const downloadAnchorElem = document.getElementById("downloadAnchorElem");
    if (downloadAnchorElem) {
      downloadAnchorElem.setAttribute("href", dataStr);
      downloadAnchorElem.setAttribute(
        "download",
        `${name} - foodics menu.json`
      );
      downloadAnchorElem.click();
    }
  };

  // fetching foodics menu JSON file content
  public navigateToPosIntegration = () => {
    this.props.history.push(
      `/profile/${this.props.match.params.store_id}/pos-integration`
    );
  };

  // Open dashboard preview
  // using admin token to make authorization using it
  // using store id to send it the the api to let the server know which store is opened
  // using email as placeholder "admin"
  public openDashboardPreview = () => {
    try {
      const url = new URL(`${process.env.DASHBOARD_FRONTEND_APP_URL}`);
      url.searchParams.append("token", this.props.token);
      url.searchParams.append("store_id", this.props.match.params.store_id);
      url.searchParams.append("is_remote_authentication", "true");
      url.searchParams.append("email", "Admin");
      return `${url.origin}/#/preview/auth${url.search}`;
    } catch (e) {
      // throw error
      notify.show("Invalid dashboard URL", "error");
      return "";
    }
  };
  public setAccountManager(
    v: string,
    accountManagersOptions: { value: string; label: string }[]
  ) {
    const newAccountManager: string =
      accountManagersOptions.find((option) => option.value === v)?.label || "";
    genericConfirmAction({
      title: `Are you sure you want to reassign "${this.props.brand.en_name}" on "${newAccountManager}" !!`,
      icon: "question",
      cancelButtonText: "NO",
      confirmButtonText: "Yes",
    })
      .then((res) => {
        if (res.value) {
          this.props.assignAccountManager({
            store_id: this.props.brand.id,
            account_manager_id: v,
          });
        } else {
          this.props.setAccountManager({
            store_account_manager: this.props.prev_store_account_manager,
            prev_store_account_manager: this.props.prev_store_account_manager,
          });
        }
      })
      .catch((e) => {
        // tslint:disable:no-console
        console.log(e);
      });
    return accountManagersOptions.find((option) => v === option.value);
  }
  public recordDashboardPreviewRequest = () => {
    this.props.logDashboardPreviewRequest(this.props.match.params.store_id);
  };

  public render() {
    if (this.props.loaded) {
      const brand = this.props.brand;
      const paymentComponent = (props) => <Payment {...props} brand={brand} />;
      const paymentStatus = paymentStatuses.filter(
        (ps) => ps.value === (brand.payment_status || 0)
      );
      let paymentStatusClass = styles.setup;
      if (paymentStatus[0].value === 1) {
        paymentStatusClass = styles.trial;
      } else if (paymentStatus[0].value === 2) {
        paymentStatusClass = styles.paid;
      } else if (paymentStatus[0].value === 3) {
        paymentStatusClass = styles.waitingForPayment;
      }
      const nextPaymentDate = moment(brand.payment_date, "x").format(
        "DD/MM/YYYY"
      );
      const { editModalOpen } = this.state;
      const accountManagersOptions: ISelectItemData[] = Array.isArray(
        this.props.account_managers
      )
        ? this.props.account_managers.map((element) => ({
            label: element.name,
            value: element.id,
          }))
        : [];

      return (
        <div className={styles.wrapper}>
          <div className={styles.coverContainer}>
            <img
              src={
                brand.cover_image
                  ? prefixImage(brand.cover_image)
                  : require("../../assets/admin-placeholder-cover.svg")
              }
              className={styles.cover}
            />
          </div>
          <div className={styles.profile}>
            <div className={styles.profileData}>
              <img
                src={
                  brand.store_image
                    ? prefixImage(brand.store_image)
                    : require("../../assets/admin-placeholder-profile.svg")
                }
                className={styles.brandImg}
              />
              <div className={styles.brandName}>
                <h6
                  id={`tt${brand.id}`}
                  style={{ fontSize: "2.5rem", margin: "0", color: "white" }}
                >
                  {brand.name.substring(0, 30)}
                  {brand.name.length > 30 && "..."}
                </h6>
                <Tooltip
                  style={{ fontSize: "1.8rem" }}
                  placement="top"
                  target={`#tt${brand.id}`}
                  toggle={this.toggleToolTip}
                  isOpen={this.state.tooltipOpen}
                >
                  {brand.name}
                </Tooltip>
                <p>
                  <i>{brand.location}</i>
                </p>
              </div>
              <Status status={brand.status} />
            </div>
            <a
              href={`${this.openDashboardPreview()}`}
              target="_blank"
              className={styles.goToDashboard}
              onClick={this.recordDashboardPreviewRequest}
            >
              <img
                src={require("../../assets/share.svg")}
                alt=""
                style={{
                  width: "1.6rem",
                  height: "1.6rem",
                  margin: "0rem 0.8rem",
                  marginBottom: "0.5rem",
                }}
              />
              Dashboard preview
            </a>
          </div>

          <div className={styles.postProfileWrapper}>
            <div className={styles.postProfile}>
              <div className={styles.firstPart}>
                <div className={styles.info}>
                  <div className={styles.stats}>
                    <h6>
                      <strong>{brand.branches_count}</strong>
                    </h6>
                    <p>Branches</p>
                  </div>
                  {brand.phone_number && (
                    <div className={styles.phone}>
                      <img src={require("../../assets/noun_Phone.svg")} />
                      <p>{brand.phone_number}</p>
                    </div>
                  )}
                </div>
                <div className={styles.account_manager}>
                  <div className={styles.label}>AM</div>
                  <div className={styles.select}>
                    <DropDownSelect
                      label={""}
                      options={accountManagersOptions}
                      value={this.props.store_account_manager}
                      onBlur={() => {}}
                      className={styles.account_manager}
                      name="account_manager_id"
                      onChange={(v) => {
                        this.props.setAccountManager({
                          store_account_manager: v,
                          prev_store_account_manager: this.props
                            .store_account_manager,
                        });
                        // this.setAccountManager(v, accountManagersOptions);
                      }}
                      toggleStyles={`${styles.toggle} ${styles.fullWidthDrop} ${styles.dropdown}`}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.secondPart}>
                <h6 className={paymentStatusClass}>
                  <strong>{paymentStatus[0]?.text}</strong>
                </h6>
                {brand.payment_status === 0 || brand.payment_status === 1 ? (
                  ""
                ) : (
                  <div className={styles.payment}>
                    <h6>Next payment on</h6>
                    <p>{nextPaymentDate}</p>
                  </div>
                )}
                <button
                  className={styles.btnUpdate}
                  onClick={this.routeToPayment}
                >
                  Update Payment
                </button>

                <>
                  <span style={{ margin: "0 1rem", fontSize: "2rem" }}>
                    &ndash;
                  </span>

                  <button
                    className={styles.btnExport}
                    onClick={this.navigateToPosIntegration}
                  >
                    Pos Entities Panel
                  </button>
                </>

                <Dropdown
                  isOpen={this.state.cardOptionsOpen}
                  toggle={this.toggleCardOptions as any}
                >
                  <DropdownToggle className={this.isOptionsOpen()}>
                    <img
                      src={require("../../assets/profile-drop.svg")}
                      className={styles.drop}
                    />
                  </DropdownToggle>

                  <DropdownMenu className={styles.menuWrapper}>
                    <DropdownItem
                      onClick={this.toggleEditBrandModal}
                      className={styles.item}
                    >
                      <p style={{ margin: 0, fontSize: "1.6rem" }}>Edit...</p>
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.routeToLogs}
                      className={styles.item}
                    >
                      <p style={{ margin: 0, fontSize: "1.6rem" }}>
                        Payment logs
                      </p>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>

          <HashRouter>
            <ScrollToTop {...{}}>
              <Switch>
                {/* {brand.has_wizard && ( */}
                <Aux>
                  <Route
                    exact={true}
                    path="/profile/:store_id/pos-integration"
                    component={PosIntegration}
                  />
                  <Route
                    exact={true}
                    path="/profile/:store_id/payment"
                    render={paymentComponent}
                  />
                  <Route
                    exact={true}
                    path="/profile/:store_id/logs"
                    component={PaymentLogs}
                  />
                  <Redirect
                    exact={true}
                    to={`/profile/${brand.id}/statistics`}
                  />
                  <Route
                    exact={true}
                    path="/profile/:store_id/statistics"
                    component={Cards}
                  />
                  <Route
                    exact={true}
                    path="/profile/:store_id/payment"
                    render={paymentComponent}
                  />
                  <Route
                    exact={true}
                    path="/profile/:store_id/logs"
                    component={PaymentLogs}
                  />
                </Aux>
                {/* )} */}
              </Switch>
            </ScrollToTop>
          </HashRouter>
          {editModalOpen && (
            <EditModal
              brand={this.state.fullData}
              initialValues={{
                ...this.state.fullData,
              }}
              modal={editModalOpen}
              toggle={this.toggleEditBrandModal}
              title="Edit Brand"
            />
          )}
        </div>
      );
    }
    if (this.props.failed) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.rejectedText}>
            <h1>Failed to load profile!</h1>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.wrapper}>
        <Skeleton widthRandomness={0} width={"100%"} height={"22rem"} />
        <div style={{ marginTop: "-9.7rem" }} className={styles.profile}>
          <Skeleton
            widthRandomness={0}
            width={"12rem"}
            borderRadius={"50%"}
            height={"12rem"}
          />
          <div className={styles.brandName}>
            <h6 style={{ fontSize: "2.5rem", margin: "0", color: "white" }}>
              <Skeleton widthRandomness={0} width={"10rem"} height={"2rem"} />
            </h6>
            <p>
              <i>
                <Skeleton widthRandomness={0} width={"5rem"} height={"2rem"} />
              </i>
            </p>
          </div>
          <Skeleton widthRandomness={0} width={"5rem"} height={"2rem"} />
        </div>

        <div className={styles.postProfileWrapper}>
          <div className={styles.postProfile}>
            <div className={styles.firstPart}>
              <div className={styles.stats}>
                <Skeleton widthRandomness={0} width={"4rem"} height={"1rem"} />
                <p>Branches</p>
              </div>
              <div className={styles.phone}>
                <img src={require("../../assets/noun_Phone.svg")} />
                <Skeleton widthRandomness={0} width={"10rem"} height={"2rem"} />
              </div>
            </div>
            <div className={styles.secondPart}>
              <Skeleton widthRandomness={0} width={"8rem"} height={"3rem"} />
              <div style={{ margin: "0 1rem" }} />
              <Skeleton widthRandomness={0} width={"10rem"} height={"3rem"} />
              <span style={{ margin: "0 1rem", fontSize: "2rem" }}>
                &ndash;
              </span>
              <Skeleton widthRandomness={0} width={"3rem"} height={"1rem"} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  private isOptionsOpen() {
    return !this.state.cardOptionsOpen
      ? styles.switch
      : `${styles.switch} ${styles.shadow}`;
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  brand: state.brandDetailsReducer.BRAND_DETAILS,
  loaded: state.brandDetailsReducer.loaded,
  loading: state.brandDetailsReducer.loading,
  failed: state.brandDetailsReducer.failed,
  token: state.authReducer.token,
  countries: state.countriesReducer.countries,
  exportFoodicsMenuFileContent: state.exportFoodicsMenuReducer.JSONFileContent,
  exportFoodicsMenuFileIsLoading: state.exportFoodicsMenuReducer.loading,
  exportFoodicsMenuFileIsLoaded: state.exportFoodicsMenuReducer.loaded,
  exportFoodicsMenuFileLoadingFailed: state.exportFoodicsMenuReducer.failed,
  account_managers: state.getAdminsByRoleReducer.account_manager,
  store_account_manager: state.getAdminsByRoleReducer.store_account_manager,
  prev_store_account_manager:
    state.getAdminsByRoleReducer.prev_store_account_manager,
  store_id: state.getAdminsByRoleReducer.store_id,
});

const mapDispatchToProps = (dispatch) => ({
  getBrandDetails: (brandId: string) => dispatch(getBrandDetails(brandId)),
  getCountries: () => dispatch(getCountries()),
  exportFoodicsMenu: (apiKey: string) => dispatch(exportFoodicsMenu(apiKey)),
  logDashboardPreviewRequest: (store_id: string) =>
    dispatch(logDashboardPreviewRequest({ store_id })),
  getAdminsByRole: (role: string) => dispatch(getAdminsByRole({ role })),
  assignAccountManager: (data: {
    store_id: string;
    account_manager_id: string;
  }) => dispatch(assignAccountManager({ data })),
  getAccountManager: (data: { store_id: string }) =>
    dispatch(getAccountManager({ data })),
  setAccountManager: (data: {
    store_account_manager: string;
    prev_store_account_manager: string;
  }) => dispatch(setAccountManager({ data })),
});

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);

export interface ILogInfo {
  brand: string;
  issued_at: string;
  issued_from: string;
  issued_to: string;
  id: string;
}
export interface IActivityLogProps {
  logs: ILogInfo[];
}

export interface ILogDetailsProps {
  log: ILogInfo;
}

export const ActivityLog: React.FC<IActivityLogProps> = ({
  logs,
}: IActivityLogProps) => {
  return (
    <div className={styles.activity_logs}>
      <div className={styles.header}>Activity Log</div>
      <div className={styles.section}>
        {/* due  tasks */}
        <div className={styles.due_tasks} />
        {/* los */}
        <div className={styles.log}>
          {logs.map((log) => (
            <LogDetails key={log.id} log={log} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const LogDetails: React.FC<ILogDetailsProps> = ({
  log: { brand, issued_at, issued_from, issued_to },
}: ILogDetailsProps) => {
  return (
    <div className={styles.log_details}>
      <div className={styles.details}>
        <span className={styles.issuer}>{issued_from}</span>&nbsp;
        Assigned&nbsp;
        <span className={styles.brand}>{brand}</span>&nbsp;brand&nbsp;
        <strong>to {issued_to}</strong>
      </div>
      <div className={styles.issued_at}>{issued_at}</div>
    </div>
  );
};
