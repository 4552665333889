import * as Requests from "../requests";
import axios from "axios";

export const getOrderingGMVTypeApi = async (token, store_id) => {
  const { data } = await axios.get(Requests.orderingGMVTypeURL(store_id), {
    headers: { token },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
  return data;
};
