import axios from "./axios";
import * as Requests from "./requests";

export const createStoreTags = (token: string, cardData: FormData) => {
  return axios.post<{ name_ar: string; name_en: string }>(
    Requests.getStoreTags,
    cardData,
    {
      headers: { token },
      baseURL: process.env.LUMEN_ENDPOINT,
    }
  );
};
