import { call, put, select, takeLatest } from "redux-saga/effects";

import { selectToken } from "../selectors";
import { getPromotedBrandsTitleAction } from "../../constants";
import { IGetPromotedSectionTitleAction } from "../../types";
import { getPromotedSectionTitle } from "../../axios/getPromotedSectionTitle";
import {
  getPromotedBrandsTitleFailure,
  getPromotedBrandsTitleSuccess,
} from "../actions/promotedSectionTitleAction";

function* promotedSectionTitleSage(action: IGetPromotedSectionTitleAction) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getPromotedSectionTitle, token);
    yield put(getPromotedBrandsTitleSuccess(res));
  } catch (e) {
    yield put(getPromotedBrandsTitleFailure(e));
  }
}

export function* watchPromotedSectionTitleSage() {
  yield takeLatest(
    getPromotedBrandsTitleAction.requested,
    promotedSectionTitleSage
  );
}
