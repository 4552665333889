import { genBrandPosPaymentMethodApi } from "../../axios/pos";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  genBrandPosPaymentMethodSuccess,
  genBrandPosPaymentMethodFailure,
  genBrandPosPaymentMethod,
} from "../actions";
import { genBrandPosPaymentMethodAction } from "../../constants";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import { notify } from "react-notify-toast";

const actionTypes = union({ genBrandPosPaymentMethod });
function* genBrandPosPaymentMethodSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    yield call(genBrandPosPaymentMethodApi, token, payload);
    yield put(
      genBrandPosPaymentMethodSuccess({ pos_entity_id: payload.pos_entity_id })
    );
    notify.show("Your changes have been saved", "success");
  } catch (error) {
    notify.show("Something went wrong, please try again", "error");
    yield put(
      genBrandPosPaymentMethodFailure({ pos_entity_id: payload.pos_entity_id })
    );
  }
}
export function* watchGenBrandPosPaymentMethodSaga() {
  yield takeLatest(
    genBrandPosPaymentMethodAction.requested,
    genBrandPosPaymentMethodSaga
  );
}
