import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  deleteAnnouncement,
  deleteAnnouncementFailure,
  deleteAnnouncementSuccess,
} from "../actions/announcementsActions";
import { deleteAnnouncementAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { deleteAnnouncementAPI } from "../../axios/deleteAnnouncement";

const actionType = union({ deleteAnnouncement });

function* deleteAnnouncementSaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  yield call(deleteAnnouncementAPI, token, payload.id);
  return payload;
}

export function* watchDeleteAnnouncementSaga() {
  yield takeLatest(
    deleteAnnouncementAction.requested,
    handleOptimism(deleteAnnouncementSaga, {
      success: deleteAnnouncementSuccess,
      failure: deleteAnnouncementFailure,
    })
  );
}
