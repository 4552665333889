import * as constants from "../../constants";
import {
  ISendSmsMessage,
  ISendSmsMessageSuccess,
  ISendSmsMessageFailed,
} from "../../constants";

export const sendSmsMessage = (data: FormData): ISendSmsMessage => ({
  type: constants.sendSmsMessageAction.requested,
  payload: data,
});

export const sendSmsMessageSuccess = (res: {
  success: number;
  failed: number;
}): ISendSmsMessageSuccess => ({
  type: constants.sendSmsMessageAction.fulfilled,
  payload: res,
});

export const sendSmsMessageFailed = (err: string): ISendSmsMessageFailed => ({
  type: constants.sendSmsMessageAction.rejected,
  payload: err,
});
