import Datetime from "react-datetime";
import * as React from "react";
import * as styles from "../styles.module.sass";
import IcoMoon from "react-icomoon";
import "react-datetime/css/react-datetime.css";

interface IProps {
  label: string;
  className: string;
  disabled?: boolean;
  is_start: boolean;
  date?: Date;
  noValidation?: boolean;
  value?: string;
  validDate?: boolean;
  touched?: boolean;
  error?: any;
  name?: string;
  onChange: (e) => void;
  onBlur: (e) => void;
  timeFormat?: boolean;
}

export const SpecialDatePicker: React.FC<IProps> = (props) => {
  const {
    label,
    className,
    onBlur,
    onChange,
    error,
    name,
    touched,
    value,
    validDate,
    timeFormat,
  } = props;
  const isInvalid = touched && error;
  const isValid = touched && !error;

  const handelChangeTime = (param) => {
    if (param) {
      onChange(param._d);
    }
  };

  React.useEffect(() => {
    handelChangeTime(value);
  }, []);

  return (
    <div
      className={className ? styles.fullWidthDate : styles.datePickerWrapper}
    >
      <label htmlFor={name} className={styles.label}>
        <span>{label}</span>
        {touched && error && <span className={styles.formError}>{error}</span>}
      </label>
      <div
        className={`${styles.datePicker} ${
          isValid && validDate ? styles.valid : ""
        }${isInvalid && !validDate ? styles.invalid : ""}`}
      >
        <Datetime
          className={` ${validDate ? styles.valid : styles.invalid}`}
          disableOnClickOutside={true}
          inputProps={{
            readOnly: true,
            className: styles.dateInput,
            disabled: props.disabled,
          }}
          timeFormat={timeFormat}
          value={value}
          defaultValue={new Date()}
          dateFormat={"YYYY-MM-DD"}
          onChange={handelChangeTime}
          onBlur={onBlur}
        />
        <div className={styles.icon}>
          <IcoMoon icon="calendar" />
        </div>
      </div>
    </div>
  );
};
