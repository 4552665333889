import { call, select, takeLatest } from "redux-saga/effects";
import { dashboardPreviewLoggerAction } from "../../constants";
import { IDashboardPreviewAction } from "../../types";
import { notify } from "react-notify-toast";
import { logDashboardPreviewAPI } from "../../axios/dashboardPreviewLogger";
import { selectToken } from "../selectors";

function* logDashboardPreview(action: IDashboardPreviewAction) {
  try {
    const token = yield select(selectToken);
    yield call(logDashboardPreviewAPI, token, action.payload.store_id);
  } catch (e) {
    notify.show("Invalid dashboard preview request", "error");
  }
}

export function* watchDashboardPreviewLoggerSaga() {
  yield takeLatest(dashboardPreviewLoggerAction.requested, logDashboardPreview);
}
