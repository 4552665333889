import React from "react";
import { useState } from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { useActiveMerchantDeals } from "../../../../hooks";
import { EditActiveMerchantDeals } from "./components";
import styles from "./styles.module.sass";
const MerchantDealCard: React.FC<{ active: boolean; title: string }> = ({
  active,
  title,
}) => {
  return (
    <div className={active ? styles.activeCard : styles.disabled}>
      <img
        className={styles.serviceIcon}
        src={
          active
            ? require("../../../../assets/feather _ check.svg")
            : require("../../../../assets/crosss.svg")
        }
      />
      <span className={styles.service}>{title}</span>
    </div>
  );
};
export const ActiveMerchantDeals: React.FC<{ store_id: string }> = ({
  store_id,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    pickupToggle,
    activeMerchantDeals,
    deliveryToggle,
    loyaltyToggle,
    portalToggle,
    mutatePortalDeal,
    qrPortalToggle,
    mutateQRPortalDeal,
    setActiveMerchantDeals,
    driveThruToggle,
  } = useActiveMerchantDeals(store_id);
  useDeepCompareEffect(() => {
    if (
      activeMerchantDeals?.delivery_status?.status === "service_disabled" &&
      activeMerchantDeals?.pickup_status?.status === "service_disabled"
    ) {
      if (
        activeMerchantDeals?.web_portal_status?.status === "service_enabled"
      ) {
        mutatePortalDeal("service_disabled");
        setActiveMerchantDeals((prevState) => ({
          ...prevState,
          web_portal_status: {
            status: "service_disabled",
            loading: "loading",
          },
        }));
      }
      if (activeMerchantDeals?.qr_portal_status?.status === "service_enabled") {
        mutateQRPortalDeal("service_disabled");
        setActiveMerchantDeals((prevState) => ({
          ...prevState,
          qr_portal_status: {
            status: "service_disabled",
            loading: "loading",
          },
        }));
      }
    }
  }, [activeMerchantDeals]);
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <span className={styles.title}>Active deal type</span>
        <div onClick={handleOpen} className={styles.editButton}>
          <img src={require("../../../../assets/feather _ edit.svg")} />
        </div>
      </div>
      {open && (
        <EditActiveMerchantDeals
          store_id={store_id}
          driveThruToggle={driveThruToggle}
          qrPortalToggle={qrPortalToggle}
          pickupToggle={pickupToggle}
          loyaltyToggle={loyaltyToggle}
          deliveryToggle={deliveryToggle}
          portalToggle={portalToggle}
          open={open}
          onClose={handleClose}
        />
      )}
      <div className={styles.wrapper}>
        <MerchantDealCard
          active={
            activeMerchantDeals.loyalty_status.status === "service_enabled"
          }
          title={"Loyalty system"}
        />
        <MerchantDealCard
          active={
            activeMerchantDeals.pickup_status.status === "service_enabled"
          }
          title={"Pickup"}
        />
        <MerchantDealCard
          active={
            activeMerchantDeals.drive_thru_status.status === "service_enabled"
          }
          title={"Drive Thru"}
        />
        <MerchantDealCard
          active={
            activeMerchantDeals.delivery_status.status === "service_enabled"
          }
          title={"Delivery"}
        />
        <MerchantDealCard
          active={
            activeMerchantDeals.web_portal_status.status ===
              "service_enabled" ||
            activeMerchantDeals.web_portal_status.status ===
              "service_enabled_unpublished" ||
            activeMerchantDeals.web_portal_status.status ===
              "service_enabled_published"
          }
          title={"Web portal"}
        />
        <MerchantDealCard
          active={
            activeMerchantDeals.qr_portal_status.status === "service_enabled" ||
            activeMerchantDeals.qr_portal_status.status ===
              "service_enabled_unpublished" ||
            activeMerchantDeals.qr_portal_status.status ===
              "service_enabled_published"
          }
          title={"QR portal"}
        />
      </div>
    </div>
  );
};

export default ActiveMerchantDeals;
