import * as React from "react";
import * as styles from "./styles.module.sass";
import * as quotaStyles from "./QuoteRequest/styles.module.sass";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { connect } from "react-redux";
import { QuoteRequest } from "./QuoteRequest";
import { IGetQuotasFilters } from "../../axios/get-quotas";
import {
  getReachQuotas,
  IReachQuotasResponse,
} from "../../redux-store/actions/getReachQuotasActions";
import { ISelectItemData } from "../FormElements/RenderDropDownSelect";
import Pagination from "react-js-pagination";
import { Aux } from "../../App";
import * as WS from "../../axios";
import { notify } from "react-notify-toast";
import Skeleton from "react-skeleton-loader";

interface IState {
  active: ISelectItemData[];
  activePage: number;
}

interface IProps {
  token: string;
  loaded: boolean;
  failed: boolean;
  totalQuotas: number;
  getQuotas: (quotaFilters: IGetQuotasFilters) => void;
  quotas: IReachQuotasResponse[];
}

export const quotasStatuses: ISelectItemData[] = [
  {
    value: 0,
    label: "Rejected",
  },
  {
    value: 1,
    label: "Accepted",
  },
  {
    value: 2,
    label: "Holding",
  },
  {
    value: undefined,
    label: "All",
  },
];

class QuoteRequests extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      active: [quotasStatuses[3]],
      activePage: 1,
    };
  }

  public rejectQuota = (quotaId: string) => {
    return WS.rejectQuota(this.props.token, quotaId)
      .then(() => {
        this.props.getQuotas({
          page: this.state.activePage - 1,
          status: this.state.active[0].value,
        });
        notify.show("Quota was rejected", "success");
      })
      .catch(() =>
        notify.show("Something went wrong, please try again", "error")
      );
  };

  public acceptQuota = (quotaId: string) => {
    return WS.acceptQuota(this.props.token, quotaId)
      .then(() => {
        this.props.getQuotas({
          page: this.state.activePage - 1,
          status: this.state.active[0].value,
        });
        notify.show("Quota credited successfully", "success");
      })
      .catch(() =>
        notify.show("Something went wrong, please try again", "error")
      );
  };

  public onBtnActive = (active) => {
    this.setState(
      {
        active: quotasStatuses.filter(
          (quota) => quota.label === active.target.value
        ),
        activePage: 0,
      },
      () => {
        this.props.getQuotas({
          page: this.state.activePage,
          status: this.state.active[0].value,
        });
      }
    );
  };
  public handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, () => {
      this.props.getQuotas({
        page: this.state.activePage - 1,
        status: this.state.active[0].value,
      });
    });
  };

  public componentDidMount() {
    this.props.getQuotas({ page: this.state.activePage - 1 });
  }

  public render() {
    const { loaded, failed, quotas, totalQuotas } = this.props;
    let render = (
      <Aux>
        {new Array(10).fill(1).map((a, index) => {
          return (
            <div key={index} className={quotaStyles.wrapper}>
              <Skeleton widthRandomness={0} width={"8rem"} height={"2rem"} />
              <div className={quotaStyles.user}>
                <Skeleton
                  widthRandomness={0}
                  width={"7rem"}
                  height={"7rem"}
                  borderRadius={"50%"}
                />
                <Skeleton widthRandomness={0} width={"8rem"} height={"2rem"} />
              </div>
              <div className={quotaStyles.phone}>
                <img
                  className={quotaStyles.logo}
                  src={require("../../assets/phone.svg")}
                />
                <Skeleton widthRandomness={0} width={"8rem"} height={"2rem"} />
              </div>
              <Skeleton widthRandomness={0} width={"8rem"} height={"2rem"} />
              <Skeleton widthRandomness={0} width={"8rem"} height={"2rem"} />
            </div>
          );
        })}
      </Aux>
    );
    if (loaded) {
      if (quotas.length) {
        render = (
          <Aux>
            <div className={styles.usersWrapper}>
              {this.props.quotas.map((quota) => (
                <QuoteRequest
                  onAccept={this.acceptQuota}
                  onReject={this.rejectQuota}
                  key={quota.created_at}
                  quota={quota}
                />
              ))}
            </div>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={8}
              totalItemsCount={totalQuotas}
              pageRangeDisplayed={8}
              onChange={this.handlePageChange}
              prevPageText="<"
              nextPageText=">"
              innerClass={styles.innerClass}
              activeClass={styles.activeClass}
              activeLinkClass={styles.activeLinkClass}
              itemClass={styles.itemClass}
              itemClassFirst={styles.itemClassFirst}
              itemClassLast={styles.itemClassLast}
              itemClassPrev={styles.itemClassPrev}
              itemClassNext={styles.itemClassNext}
            />
          </Aux>
        );
      } else {
        render = (
          <div className={styles.wrapper}>
            <div className={styles.rejectedText}>
              <h1>There are no requests!</h1>
            </div>
          </div>
        );
      }
    }
    if (failed) {
      render = (
        <div className={styles.wrapper}>
          <div className={styles.rejectedText}>
            <h1>Something went wrong!</h1>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.filterWrapper}>
          {loaded ? (
            <h1 className={styles.textWrapper}>
              {this.state.active[0].label} Requests ({totalQuotas})
            </h1>
          ) : (
            <div />
          )}
          <div className={styles.btnWrapper}>
            <div className={styles.filter}>
              <img
                src={require("../../assets/icon-filter.svg")}
                className={styles.img}
              />
              <span className={styles.text}>Filters</span>
            </div>
            <button
              onClick={this.onBtnActive}
              className={
                this.state.active[0].label === "All"
                  ? styles.btnActive
                  : styles.btn
              }
              value="All"
            >
              All
            </button>
            <button
              onClick={this.onBtnActive}
              className={
                this.state.active[0].label === "Holding"
                  ? styles.btnActive
                  : styles.btn
              }
              value="Holding"
            >
              Holding
            </button>
            <button
              onClick={this.onBtnActive}
              className={
                this.state.active[0].label === "Accepted"
                  ? styles.btnActive
                  : styles.btn
              }
              value="Accepted"
            >
              Accepted
            </button>
            <button
              onClick={this.onBtnActive}
              className={
                this.state.active[0].label === "Rejected"
                  ? styles.btnActive
                  : styles.btn
              }
              value="Rejected"
            >
              Rejected
            </button>
          </div>
        </div>
        {render}
      </div>
    );
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  token: state.authReducer.token,
  quotas: state.reachQuotasReducer.QUOTAS,
  totalQuotas: state.reachQuotasReducer.totalQuotas,
  loaded: state.reachQuotasReducer.completed,
  failed: state.reachQuotasReducer.failed,
});
const mapDispatchToProps = (dispatch) => ({
  getQuotas: (quotaFilters: IGetQuotasFilters) =>
    dispatch(getReachQuotas(quotaFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteRequests);
