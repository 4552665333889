import React from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Select } from "../../Components/FormElements/Select";
import Skeleton from "react-skeleton-loader";
const paths = [
  require("../../assets/bar-chart.svg"),
  require("../../assets/conversion-rate.svg"),
  require("../../assets/num-customers.svg"),
  require("../../assets/num-redemptions.svg"),
  require("../../assets/redemptions-rate.svg"),
  require("../../assets/warning.svg"),
];
interface IDropDown {
  text: string;
  value: string;
}
interface IProps {
  title: string;
  number: number;
  currentFilter: { text: string; value: string };
  pathIndex: number;
  error?: boolean;
  onChange?: (input: IDropDown) => void;
  noFilter?: boolean;
  percent?: boolean;
  margin?: string;
  loading: boolean;
}

export const PCard: React.FC<IProps> = ({
  number,
  onChange,
  pathIndex,
  margin,
  title,
  noFilter,
  percent,
  loading,
}) => {
  return (
    <div style={{ margin }} className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        {!noFilter ? (
          <Select
            showLabelWithValue={true}
            label={"Time:"}
            className={styles.select}
            onChange={(input) => {
              if (onChange) {
                onChange(input);
              }
            }}
            options={[
              { text: "All time", value: "all" },
              { text: "Today", value: "today" },
              { text: "Last 3 days", value: "last3days" },
              { text: "This week", value: "week" },
              { text: "this month", value: "month" },
            ]}
          />
        ) : (
          <div
            style={{
              height: "40px",
              marginBottom: "2.4rem",
              margin: "1rem 0",
              padding: "24px",
            }}
          />
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.stats}>
          {loading ? (
            <Skeleton widthRandomness={0} width={"6rem"} height={"2rem"} />
          ) : (
            <h1>
              {number < 0 ? "--" : number}
              {number >= 0 && percent && <span>%</span>}
            </h1>
          )}
        </div>
        <img src={paths[pathIndex]} />
      </div>
    </div>
  );
};

export default hot(module)(PCard);
