import React, { useRef } from "react";
import styles from "./styles.module.sass";
import { UseFormWatch } from "react-hook-form";
import { useUploadBrands } from "../../hooks/useUploadBrands";
export const defaultCSVExample = require("../../../../../../public/exmples/brand-example.csv")
  .default;
const DownloadExample: React.FC = () => {
  return (
    <a className={styles.downloadTemplate} href={defaultCSVExample}>
      <img
        className={styles.downloadIcon}
        src={require("../../../../../assets/download.svg")}
      />
      Download CSV template
    </a>
  );
};

interface IProps {
  setCheckBoxValue?: React.Dispatch<React.SetStateAction<boolean>> | any;
  isReactivating?: boolean;
  setValue?: (
    name: string,
    value: any,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
  watch?: UseFormWatch<any>;
  isEditing?: boolean;
  setApplyCapping?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setApplyMinEligibleInvoice?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | any;
}

export const UploadBrands: React.FC<IProps> = ({
  isReactivating,
  setCheckBoxValue,
  setValue,
  watch,
  isEditing,
  setApplyCapping,
  setApplyMinEligibleInvoice,
}) => {
  const { setFile, name, csvJson } = useUploadBrands({
    watch,
    setValue,
    setSplit: setCheckBoxValue,
    isEditing: Boolean(isEditing),
    setApplyCapping,
    setApplyMinEligibleInvoice,
  });
  const ref = useRef<HTMLInputElement>();
  const handleUploadBrandsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setFile({
        file: URL.createObjectURL((e.target as any).files[0]),
        name: (e.target as any).files[0].name,
      });
      e.target.value = "";
    }
  };

  const uploadBrandsCSV = () =>
    isReactivating ? null : (ref.current as HTMLInputElement)?.click();

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <div className={styles.inputWrapper}>
          <label
            className={isReactivating ? styles.disabled : styles.input}
            onClick={() => {
              uploadBrandsCSV();
              if (setValue) {
                setValue("csv_values", []);
              }
            }}
          >
            Upload Brands Csv
            <input
              type={"file"}
              ref={ref.current as HTMLInputElement | any}
              hidden={true}
              onChange={handleUploadBrandsChange}
            />
          </label>
          {name && <span className={styles.fileName}>{name}</span>}
        </div>
        <DownloadExample />
      </div>
      {name && !csvJson.length && (
        <span className={styles.error}>Something went wrong</span>
      )}
    </React.Fragment>
  );
};

export default UploadBrands;
