import { genericAction } from "../../../../constants";
export const ACTION_EXPIRE_PROMO_CODE = "ACTION_EXPIRE_PROMO_CODE";
export const expirePromoCodeAction = genericAction(ACTION_EXPIRE_PROMO_CODE);
export const getBannerCardsAction = genericAction("ACTION_GET_BANNER_CARDS");
export const createBannerCardAction = genericAction(
  "ACTION_CREATE_BANNER_CARDS"
);
export const deleteBannerCardAction = genericAction(
  "ACTION_DELETE_BANNER_CARDS"
);
export const reOrderBannerCardAction = genericAction(
  "ACTION_REORDER_BANNER_CARDS"
);

export const getAnnouncementsAction = genericAction("ACTION_GET_ANNOUNCEMENTS");
export const createAnnouncementAction = genericAction(
  "ACTION_CREATE_ANNOUNCEMENTS"
);
export const deleteAnnouncementAction = genericAction(
  "ACTION_DELETE_ANNOUNCEMENTS"
);
const ACTION_GET_PUSH_NOTIFICATIONS = "ACTION_GET_PUSH_NOTIFICATIONS";
export const getPushNotificationsAction = genericAction(
  ACTION_GET_PUSH_NOTIFICATIONS
);

const ACTION_CREATE_PUSH_NOTIFICATIONS = "ACTION_CREATE_PUSH_NOTIFICATIONS";
export const createPushNotificationAction = genericAction(
  ACTION_CREATE_PUSH_NOTIFICATIONS
);

export const getStoreTagsAction = genericAction("ACTION_GET_STORE_TAGS");
export const createStoreTagsAction = genericAction("ACTION_CREATE_STORE_TAGS");
export const deleteStoreTagAction = genericAction("ACTION_DELETE_STORE_TAG");
export const editStoreTagAction = genericAction("ACTION_EDIT_STORE_TAG");

const ACTION_GET_CATEGORIES = "ACTION_GET_CATEGORIES";
export const getCategoriesAction = genericAction(ACTION_GET_CATEGORIES);

const ACTION_CREATE_CATEGORY = "ACTION_CREATE_CATEGORY";
export const createCategoryAction = genericAction(ACTION_CREATE_CATEGORY);

const ACTION_GET_COMMISSIONS = "ACTION_GET_COMMISSIONS";
export const getCommissionsAction = genericAction(ACTION_GET_COMMISSIONS);
const ACTION_EDIT_COMMISSIONS = "ACTION_EDIT_COMMISSIONS";
export const editCommissionsAction = genericAction(ACTION_EDIT_COMMISSIONS);
const ACTION_EDIT_CATEGORY = "ACTION_EDIT_CATEGORY";
export const editCategoryAction = genericAction(ACTION_EDIT_CATEGORY);

const ACTION_DELETE_CATEGORY = "ACTION_DELETE_CATEGORY";
export const deleteCategoryAction = genericAction(ACTION_DELETE_CATEGORY);

const ACTION_GET_SMS_CAMPAIGNS_ACTION = "ACTION_GET_SMS_CAMPAIGNS_ACTION";
export const getSMSCampaignsAction = genericAction(
  ACTION_GET_SMS_CAMPAIGNS_ACTION
);

const ACTION_UNIVERSAL_PROMO_CODE = "ACTION_UNIVERSAL_PROMO_CODE";
export const createUniversalPromoCodeAction = genericAction(
  ACTION_UNIVERSAL_PROMO_CODE
);

const ACTION_GET_PROMOTION_STORES = "ACTION_GET_PROMOTION_STORES";
export const getPromotionStoresAction = genericAction(
  ACTION_GET_PROMOTION_STORES
);

const ACTION_EDIT_PROMO_CODE = "ACTION_EDIT_PROMO_CODE";
export const editPromoCodeAction = genericAction(ACTION_EDIT_PROMO_CODE);

const ACTION_CREATE_PROMO_CODE = "ACTION_CREATE_PROMO_CODE";
export const createPromoCodeAction = genericAction(ACTION_CREATE_PROMO_CODE);

const ACTION_CLEAR_CUTTENT_PROMOTION_STORE =
  "ACTION_CLEAR_CUTTENT_PROMOTION_STORE";
export const clearCurrentPromotionStoreAction = genericAction(
  ACTION_CLEAR_CUTTENT_PROMOTION_STORE
);
export const clearPrevStateAction = "ACTION_CLEAR_PREV_STATUS";

// account managers

const ACTION__GET_ADMINS_BY_ROLE = "ACTION__GET_ADMINS_BY_ROLE";
export const getAdminsByRoleAction = genericAction(ACTION__GET_ADMINS_BY_ROLE);

const ACTION_ASSIGIN_ACCOUNT_MANAGER = "ACTION_ASSIGIN_ACCOUNT_MANAGER";
export const assignAccountManagerAction = genericAction(
  ACTION_ASSIGIN_ACCOUNT_MANAGER
);
export const ACTION_SET_ACCOUNT_MANAGER = "ACTION_SET_ACCOUNT_MANAGER";

const ACTION_GET_ACCOUNT_MANAGER = "ACTION_GET_ACCOUNT_MANAGER";
export const getAccountManagerAction = genericAction(
  ACTION_GET_ACCOUNT_MANAGER
);
