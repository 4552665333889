import {
  editPromotedBrandActionType,
  getPromotedBrandsAction,
  IEditPromotedBrandSuccessAction,
  IGetPromotedBrandsSuccessAction,
} from "../../types";
import * as constants from "../../constants";
import { IStore } from "../../axios/getBrands";

export interface IPromotedBrandsReducerState {
  brandsById: { [key: string]: IStore };
  brandsIds: string[];
  isLoading: "suceess" | "loading" | "error";
}

const initialState: IPromotedBrandsReducerState = {
  isLoading: "loading",
  brandsById: {},
  brandsIds: [],
};

export const promotedBrandsReducer = (
  state = initialState,
  action: getPromotedBrandsAction | editPromotedBrandActionType
): IPromotedBrandsReducerState => {
  switch (action.type) {
    case constants.getPromotedBrandsAction.requested:
    case constants.editPromotedBrandAction.requested: {
      return {
        ...state,
        isLoading: "loading",
      };
    }
    case constants.getPromotedBrandsAction.fulfilled: {
      return {
        ...state,
        isLoading: "suceess",
        brandsById: (action as IGetPromotedBrandsSuccessAction).payload.reduce(
          (acc, next) => ({
            ...acc,
            [next.id]: next,
          }),
          {}
        ),
        brandsIds: (action as IGetPromotedBrandsSuccessAction).payload.map(
          (item) => item.id
        ),
      };
    }
    case constants.getPromotedBrandsAction.rejected: {
      return { ...state, isLoading: "error" };
    }

    case constants.editPromotedBrandAction.fulfilled: {
      const {
        promoted_status,
        store,
      } = (action as IEditPromotedBrandSuccessAction).payload;
      const newBrandsList = { ...state.brandsById };
      delete newBrandsList[`${store.id}`];

      return {
        ...state,
        isLoading: "error",
        brandsById:
          promoted_status === "add"
            ? {
                ...state.brandsById,
                [`${store.id}`]: { ...store, promoted_at: Date.now() },
              }
            : { ...newBrandsList },
        brandsIds:
          promoted_status === "add"
            ? [...state.brandsIds, store.id]
            : state.brandsIds.filter((id) => id !== store.id),
      };
    }

    default:
      return state;
  }
};
