import React, { useEffect, useMemo, useState } from "react";
import * as styles from "./styles.module.sass";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { change } from "redux-form";
import { useDispatch } from "react-redux";

interface IProps {
  label: string;
  className: string;
  disabled?: boolean;
  options: any[];
  input: {
    [key: string]: any;
  };
  meta: {
    [key: string]: any;
  };
  alreadySetValue: string;
}

const RenderDropDownSelectWithSearch: React.FC<IProps> = (props) => {
  const {
    input,
    label,
    className,
    meta: { touched, error, warning },
    options,
    alreadySetValue,
    disabled,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (alreadySetValue && input && input.onChange) {
      const selectedValue = options.filter(
        (option) => option.value === alreadySetValue
      )[0] || { label: "", value: "" };
      setSelectInputState(selectedValue);
      input.onChange(alreadySetValue);
    }
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [selectInputState, setSelectInputState] = useState({
    value: "",
    label: "",
  });
  const optionsLabels = useMemo(() => {
    return [...options.map((option) => option.label)].filter(Boolean);
  }, [options]);

  const handleSelectChange = (event: any, newValue: any) => {
    const selectedValue = options.filter(
      (option) => option.label === newValue
    )[0] || { label: "", value: "" };
    setSelectInputState(selectedValue);
    input.onChange(selectedValue.value);
    // clearing stores_ids prop on different country select
    dispatch(change("AddPromoCodeForm", "stores_ids", []));
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const renderInput = (params) => (
    <TextField
      disabled={disabled}
      {...params}
      placeholder="Select Country"
      variant="outlined"
      fullWidth={true}
      style={{ width: "100%" }}
    />
  );

  const renderOptionNodes = (option, state) => (
    <div className={styles.item}>{option}</div>
  );

  // on input blur update input value with selection label text
  const onAutoCompleteBlur = () => {
    setInputValue(selectInputState.label);
  };

  // temporarly clear input on click
  const onAutoCompleteClick = () => {
    setInputValue("");
  };

  return (
    <div className={className ? styles.fullWidthDrop : styles.dropDownPart}>
      <label htmlFor={input.name} className={styles.label}>
        <span>{label}</span>
        {touched &&
          ((error && <span className={styles.formError}>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </label>
      <br />
      <Autocomplete
        disabled={disabled}
        value={selectInputState?.label}
        onChange={handleSelectChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        id={input.name}
        options={optionsLabels}
        renderInput={renderInput}
        className={className}
        style={{ margin: 0, padding: 0 }}
        renderOption={renderOptionNodes}
        blurOnSelect={true}
        autoHighlight={true}
        openOnFocus={true}
        onBlur={onAutoCompleteBlur}
        onBlurCapture={onAutoCompleteBlur}
        onClick={onAutoCompleteClick}
        onClickCapture={onAutoCompleteClick}
      />
    </div>
  );
};

export default RenderDropDownSelectWithSearch;
