export const bannerCardsURL = `/api/mobile-carousel`;
export const announcementsURL = `/api/announcement`;
export const getStoreTags = "/api/store-tag";
export const deleteStoreTag = (id: string) => `/api/store-tag/${id}`;
export const getCategories = "/api/store-category";
export const categoryURL = "/api/store-category";
export const deleteCategoryURL = (id: string) => `/api/store-category/${id}`;
export const editCategoryURL = (id: string) => `/api/store-category/${id}`;
export const getPushNotification = "/api/sms/camapigns";
export const expirePromoCodeUrl = (promoId: string) =>
  `/api/admin/promo-code/${promoId}/expire`;
export const CreateUniversalPromoCodeURL = (type) =>
  `/api/admin/promo-code/${type}/universal`;
export const PromoCodesURL = (type) => `/api/admin/promo-code/${type}/csv`;
export const getPromotionStores = (id: string) =>
  `/api/admin/promo-code/${id}/stores`;
export const getPushNotificationURL = "/api/push/campaigns";
export const getSMSCampaignsURL = "/api/sms/campaigns";

export const editPromoCodeURL = (id: string) =>
  `/api/admin/promo-code/${id}/update`;

export const getPromocodeByRankURl = "/api/admin/promo-code/get-promo-by-rank";
// highest rank
export const getHighestRankURL = "/api/admin/promo-code/highest-rank";
