import React from "react";
import { SystemAnnouncementTargeting, IAnnouncementBase } from "../../types";
import * as styles from "./styles.module.sass";
import { useDispatch } from "react-redux";
import { deleteAnnouncement } from "../../redux-store/actions/announcementsActions";
import { confirmDanger } from "../../BannerCards/lib/confirmDanger";
import { useProcessImageResource } from "../../BannerCards/lib/useProcessImageResource";

interface IProps {
  announcementData: IAnnouncementBase;
}

export const AnnouncementItem: React.FC<IProps> = ({ announcementData }) => {
  const dispatch = useDispatch();
  const handleDeleteItem = React.useCallback(async () => {
    const result = await confirmDanger(
      "Are you sure you want to delete this announcement?"
    );
    if (result.value) {
      dispatch(deleteAnnouncement(announcementData));
    }
  }, [announcementData.id]);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const announcementImage = useProcessImageResource(announcementData.image);
  const targetUsersCount = React.useMemo(() => {
    if (announcementData.visibility === SystemAnnouncementTargeting.private) {
      return announcementData.target_users || "-";
    }
    return "Universal";
  }, [announcementData.target_users]);
  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <img src={announcementImage} className={styles.bannerImage} />
      <div className={styles.targetedUsers}>
        <div className={styles.titleNumbers}>
          <div className={styles.title}>Targeted users</div>
          <div className={styles.numbers}>{targetUsersCount}</div>
        </div>
        <span onClick={handleDeleteItem} className="icon-trash">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </span>
      </div>
    </div>
  );
};
