import { useQuery } from "react-query";
import { Activity, Country, MerchantStatus, PaymentStatus } from "../../types";
import { getMerchantsApi } from "../../axios/merchants";
import { store } from "../../redux-store/configureStore";

export const getMerchants = ({
  params,
  queryEnabled,
}: {
  params: {
    country?: Country;
    search?: string;
    payment_status?: PaymentStatus;
    merchant_status?: MerchantStatus;
    activity?: Activity;
    page: number;
  };
  queryEnabled: boolean;
}) => {
  const token = store.getState().authReducer.token;
  const { data, status } = useQuery(
    ["merchants", { ...params }],
    async () => await getMerchantsApi(token, { ...params }),
    {
      enabled: queryEnabled,
      retry: false,
      cacheTime: 0,
    }
  );

  return {
    data,
    status,
  };
};
