import axios from "./axios";
import * as Requests from "./requests";
import { IPaymentRequest } from "./verifyPayment";

export const confirmPayment = (token, data: IPaymentRequest): any => {
  return axios.post(Requests.confirmPayment(data.store_id), data, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
