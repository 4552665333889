import { Modal } from "antd";
import React, { useEffect } from "react";
import styles from "./styles.module.sass";
import CrossIcon from "../../../../assets/ic_cross.svg";
import { useForm } from "react-hook-form";
import StartEndDate from "../../../FormElements/StartEndDate";
import GenericInputField from "../../../FormElements/GenericInputField";
import "./styles.less";
import { useDispatch } from "react-redux";
import {
  createCashbackDetails,
  editCashbackDetails,
} from "../../../../redux-store/actions";
import { useSelect } from "../../../../hooks/useSelect";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
import { ClipLoader } from "react-spinners";
import { yupResolver } from "@hookform/resolvers/yup";
import { cashbackDetailsSchema } from "./validations";
interface ICashbackDetailsPopupProps {
  open: boolean;
  onClose: () => void;
  store_id: string;
}
interface ICashbackDetailsFormValues {
  cashback_koinz_share: number;
  cashback_merchant_share: number;
  promo_share_max_cpo: number;
  start_date: number;
  end_date: number;
}
export const CashbackDetailsPopup: React.FC<ICashbackDetailsPopupProps> = ({
  onClose,
  open,
  store_id,
}) => {
  const {
    cashbackDetailsReducer: { status, cashback_details },
  } = useSelect((state) => state);
  const {
    control,
    setValue,
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<ICashbackDetailsFormValues>({
    defaultValues: {
      start_date: undefined,
      end_date: undefined,
      cashback_koinz_share: 0,
      cashback_merchant_share: 100,
    },
    reValidateMode: "onChange",
    resolver: yupResolver(cashbackDetailsSchema)
  });
  const watchers = watch();

  const dispatch = useDispatch();
  useEffect(() => {
    if (cashback_details) {
      setValue("cashback_koinz_share", cashback_details.cashback_koinz_share);
      setValue(
        "cashback_merchant_share",
        cashback_details.cashback_merchant_share
      );
      setValue("end_date", cashback_details.cashback_merchant_share_end_date ?? undefined);
      setValue(
        "start_date",
        cashback_details.cashback_merchant_share_start_date ?? undefined
      );
      setValue("promo_share_max_cpo", cashback_details.promo_share_max_cpo);
    }
  }, [cashback_details]);
  const onSubmit = (values: ICashbackDetailsFormValues) => {
    if (cashback_details) {
      dispatch(
        editCashbackDetails({
          store_id,
          cashback_merchant_share_end_date: values.end_date,
          cashback_merchant_share: values.cashback_merchant_share,
          promo_share_max_cpo: values.promo_share_max_cpo,
          cashback_merchant_share_start_date: values.start_date,
          cashback_koinz_share: values.cashback_koinz_share,
        })
      );
    } else {
      dispatch(
        createCashbackDetails({
          store_id,
          cashback_merchant_share_end_date: values.end_date,
          cashback_merchant_share: values.cashback_merchant_share,
          promo_share_max_cpo: values.promo_share_max_cpo,
          cashback_merchant_share_start_date: values.start_date,
        })
      );
    }
  };
  useEffect(() => {
    if (status === LoadingStatus.success) {
      onClose();
    }
  }, [status]);
  return (
    <Modal
      destroyOnClose={true}
      closable={false}
      centered={true}
      title={""}
      footer={""}
      open={open}
      width={561}
      className={"cashback-detail-modal"}
    >
      <div className={styles.main}>
        <div className={styles.header}>
          <span className={styles.title}>Add cashback details</span>
          <div onClick={onClose} className={styles.iconWrapper}>
            <img className={styles.icon} src={CrossIcon} />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formWrapper}>
            <div className={styles.shareInputsWrapper}>
              <GenericInputField
                badge={"%"}
                onChange={(e) => {
                  const currentValue =
                    Number(e.target.value ?? 0) >= 0 &&
                    Number(e.target.value ?? 0) <= 100
                      ? Number(e.target.value ?? 0)
                      : Number(e.target.value ?? 0) < 0
                      ? 0
                      : 100;
                  const cashback_merchant_share = 100 - currentValue;
                  setValue("cashback_merchant_share", cashback_merchant_share);
                  setValue("cashback_koinz_share", currentValue);
                }}
                placeholder={"e.g.  10%"}
                label={"Koinz percentage"}
                error={errors?.cashback_koinz_share}
                register={register("cashback_koinz_share")}
                type={"number"}
              />
              <div className={styles.separator} />
              <GenericInputField
                badge={"%"}
                onChange={(e) => {
                  const actualValue =
                    Number(e.target.value ?? 0) >= 0 &&
                    Number(e.target.value ?? 0) <= 100
                      ? Number(e.target.value ?? 0)
                      : Number(e.target.value ?? 0) < 0
                      ? 0
                      : 100;
                  const cashback_koinz_share = 100 - actualValue;
                  setValue("cashback_koinz_share", cashback_koinz_share);
                  setValue("cashback_merchant_share", actualValue);
                }}
                placeholder={"e.g.  90%"}
                label={"Brand percentage"}
                error={errors?.cashback_merchant_share}
                register={register("cashback_merchant_share")}
                type={"number"}
              />
            </div>
            <StartEndDate
              errors={errors as any}
              control={control}
              setValue={setValue}
              end_date={watchers.end_date}
              start_date={watchers.start_date}
            />
            <div style={{ marginBottom: "24px" }} />
            <GenericInputField
              onChange={(e) => {
                setValue("promo_share_max_cpo", Number(e.target.value));
              }}
              placeholder={"Enter max CPO amount..."}
              label={
                <span className={styles.cpoTitle}>
                  Maximum CPO
                  <span className={styles.optional}>(Optional)</span>
                </span>
              }
              error={errors?.promo_share_max_cpo}
              register={register("promo_share_max_cpo")}
              type={"number"}
            />
          </div>

          <div className={styles.footer}>
            <button
              type={"submit"}
              disabled={status === LoadingStatus.loading}
              className={styles.btnSave}
            >
              {status === LoadingStatus.loading ? (
                <ClipLoader size={20} color={"#fff"} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CashbackDetailsPopup;