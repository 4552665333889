import { Modal, ModalBody, ModalHeader } from "reactstrap";
import * as React from "react";
import * as styles from "./styles.module.sass";
import "react-datetime/css/react-datetime.css";
import {
  InjectedFormProps,
  reduxForm,
  Field,
  formValueSelector,
} from "redux-form";
import { RenderGenericField } from "../../Components/FormElements/RenderGenericField";
import { email, required } from "../../redux-store/validators";
import { RenderPasswordField } from "../../Components/FormElements/RenderPasswordField";
import {
  ISelectItemData,
  RenderDropDownSelect,
} from "../../Components/FormElements/RenderDropDownSelect";
import { RenderDatePicker } from "../../Components/FormElements/RenderDatePicker";
import { connect } from "react-redux";
import {
  editBrand,
  fillBrandData,
  resetEditBrand,
} from "../../redux-store/actions/editBrandActions";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { IStore } from "../../axios/getBrands";
import { Dispatch } from "redux";
import {
  ICountry,
  IEditBrandAction,
  IFillEditBrandAction,
  IResetEditBrandAction,
} from "../../types";
import { FormEvent } from "react";
import { brandStatuses } from "../../constants";
import normalizePhone, {
  normalizeDate,
  transformDate,
} from "../../redux-store/transformers";
import { Status } from "../../Components/Card/Status";
import { hot } from "react-hot-loader";
import countriesAndTimezones from "countries-and-timezones";
// import { useProgressState } from "../../Components/ShopXSettings/useProgressState";
// import { getPOSProviders } from "../../axios/getPOSProviders";
import { useRecoilState } from "recoil";
import { filtersAtom } from "../../atoms/merchants";
import { useDeepCompareEffect } from "use-deep-compare";
import { getAdminsByRole } from "../../Components/TargetedTools/redux-store/actions/admins-by-role-actions";
import { notify } from "react-notify-toast";

interface IProps {
  modal: boolean;
  filled: boolean;
  toggle: () => void;
  onSubmit: () => Promise<void>;
  title: string;
  brand: any;
  fillFormData: (data: IStore) => void;
  initialValues: IStore;
  countries: ICountry[];
  resetEdit: () => void;
  formValues: {
    country_iso_code: ICountry;
    timezone: any;
  };
}

const renderStatusHeader = (item: ISelectItemData) => (
  <Status status={item.value} />
);
// const usePOSProviders = () => {
//   const { setLoading, setFailure, setSuccess, ...progress } =
//     useProgressState();

//   const [posProviders, setPosProviders] = React.useState<ISelectItemData[]>([]);

//   React.useEffect(() => {
//     getPOSProviders()
//       .then(({ data: { pos_providers } }) => {
//         setSuccess();
//         setPosProviders([
//           ...pos_providers.map((item) => ({
//             label: item.name,
//             value: item.value,
//           })),
//           ...posSelectItems,
//         ]);
//       })
//       .catch(() => {
//         setSuccess();
//         setPosProviders([
//           {
//             label: "Pos Rocket",
//             value: "rocket",
//           },
//           {
//             label: "foodics",
//             value: "foodics",
//           },
//           ...posSelectItems,
//         ]);
//       });
//   }, []);

//   return {
//     posProviders,
//     ...progress,
//   };
// };
const EditModal: React.FC<IProps & InjectedFormProps> = (props) => {
  const fillProps = () => {
    props.fillFormData({ ...props.brand });
  };
  const [, setFilters] = useRecoilState(filtersAtom);
  React.useEffect(() => {
    fillProps();
    return () => {
      props.resetEdit();
    };
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    props.touch(
      "password",
      "name",
      "email",
      "phone_number",
      "country_iso_code",
      "activation_date",
      "status",
      // "pos",
      "timezone"
    );
    if (props.valid) {
      try {
        await props.onSubmit();
      } catch (error) {
        notify.show("An error occurred while saving edits.", "error");
      }
    }
  };
  const prevent = (e) => {
    e.stopPropagation();
  };

  const toggleModal = () => {
    props.toggle();
    props.reset();
  };
  const {
    change,
    formValues: { timezone, country_iso_code },
    submitSucceeded,
  } = props;

  useDeepCompareEffect(() => {
    if (submitSucceeded) {
      props.reset();
      props.toggle();
      setFilters((prev) => ({
        params: {
          ...prev.params,
        },
        queryEnabled: true,
      }));
    }
  }, [submitSucceeded, setFilters, props]);

  React.useEffect(() => {
    if (timezone) {
      change("timezone", timezone);
    }
  }, [timezone]);

  const countrySelectItems = props.countries.map(
    (country: ICountry): ISelectItemData => ({
      value: country,
      label: country.EnName,
    })
  );

  // const { success, posProviders } = usePOSProviders();
  const timeZoneSelectItems: ISelectItemData[] = React.useMemo(() => {
    const countryISOCode = country_iso_code?.ISO_code?.toUpperCase();
    const timezones = countryISOCode
      ? countriesAndTimezones.getTimezonesForCountry(countryISOCode)
      : [];
    change("timezone", timezones[0]?.name);
    return timezones?.length
      ? timezones.map((t) => ({
          value: t.name,
          label: `(GMT${t.offsetStr}) ${t.name}`,
        }))
      : [];
  }, [country_iso_code]);

  return props.countries.length > 0 && props.filled ? (
    <Modal
      onClick={prevent}
      isOpen={props.modal}
      toggle={toggleModal}
      size="lg"
    >
      <ModalHeader toggle={props.toggle}>
        <h1>{props.title}</h1>
      </ModalHeader>
      <ModalBody className={styles.wrapper}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <Field
            name="name"
            type="text"
            component={RenderGenericField as any}
            label="Brand name"
            validate={[required]}
          />
          <Field
            name="email"
            type="email"
            component={RenderGenericField as any}
            label="Email"
            validate={[email, required]}
          />
          <Field
            name="password"
            type="password"
            component={RenderPasswordField as any}
            label="Password"
          />
          <Field
            name="phone_number"
            type="text"
            component={RenderGenericField as any}
            validate={[required]}
            normalize={normalizePhone}
            label="Phone number"
          />
          <Field
            type="select"
            name="country_iso_code"
            component={RenderDropDownSelect as any}
            validate={[required]}
            options={countrySelectItems}
            label="Country"
          />
          <Field
            type="select"
            renderSelectToggle={renderStatusHeader}
            name="status"
            component={RenderDropDownSelect as any}
            validate={[required]}
            options={brandStatuses}
            label="Status"
          />
          <Field
            name="activation_date"
            component={RenderDatePicker as any}
            label="Activation date"
            normalize={normalizeDate}
            format={transformDate}
            validate={[required]}
          />
          {/* <Field
            name="pos"
            component={RenderDropDownSelect as any}
            options={posProviders}
            label="Pos integration"
            validate={[required]}
          /> */}
          <Field
            name="timezone"
            component={RenderDropDownSelect as any}
            label="Time Zone"
            options={timeZoneSelectItems}
            disabled={!timeZoneSelectItems.length}
            validate={[required]}
          />
          {/* {props?.initialValues?.api_key && (
            <Field
              name="api_key"
              type="text"
              readOnly={true}
              fullWidth={true}
              component={RenderGenericField as any}
              label="Api key"
            />
          )} */}
        </form>
        <button onClick={handleSubmit} type="submit" className={styles.btn}>
          Save edits
        </button>
      </ModalBody>
    </Modal>
  ) : null;
};

let EditBrandForm = reduxForm({
  form: "editBrand",
})(EditModal as any);
const selector = formValueSelector("editBrand");
EditBrandForm = connect((state) => {
  // can select values individually
  const country_iso_code = selector(state, "country_iso_code");
  const timezone = selector(state, "timezone");
  return {
    formValues: {
      country_iso_code,
      timezone,
    },
  };
})(EditBrandForm) as any;
const mapStateToProps = (state: IRootReducerState) => ({
  filled: state.editBrandReducer.filled,
  countries: state.countriesReducer.countries,
  account_managers: state.getAdminsByRoleReducer?.account_manager,
});

const mapDispatchToProps = (
  dispatch: Dispatch<
    IFillEditBrandAction | IEditBrandAction | IResetEditBrandAction
  >
) => ({
  fillFormData: (store: IStore) => dispatch(fillBrandData(store)),
  resetEdit: () => dispatch(resetEditBrand()),
  onSubmit: () => dispatch(editBrand()),
  getAdminsByRole: (role: string) => dispatch(getAdminsByRole({ role })),
});

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(EditBrandForm) as any
);
