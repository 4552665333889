import React, { useMemo } from "react";
import styles from "./styles.module.sass";
import ic_brand_message from "../../../assets/ic_brand_message.svg";
import ic_store from "../../../assets/ic_store.svg";
import BrandPoc from "../BrandPoc";
import ContactMethod from "../ContactMethod";
import { useSelect } from "../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { resolveTasks as resolveTasksAction } from "../../../redux-store/actions";
import { useRecoilValue } from "recoil";
import { validateTakeActions } from "../../../validations";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import { ClipLoader } from "react-spinners";
export const BrandMessage: React.FC = () => {
  const dispatch = useDispatch();
  const { valid } = useRecoilValue(validateTakeActions);
  const {
    selected_store_id,
    selectedBrandsById,
    resolve_all,
    selectedBrandsTasksIds,
    resolve_tasks_loading_status,
  } = useSelect((state) => ({
    ...state.actionCenterBrandsReducer,
    resolve_tasks_loading_status: state.resolveTasksReducer.loadingStatus,
  }));

  const storeName = useMemo(() => {
    if (selected_store_id) {
      return selectedBrandsById[selected_store_id].store_name;
    }
    return "";
  }, [selected_store_id, selectedBrandsById]);

  const counts = useMemo(() => {
    let count: number = 0;
    const brandCounts = selectedBrandsTasksIds.filter((brandId) =>
      selectedBrandsById[brandId].tasks.some((task) => task.selected)
    ).length;
    for (const brandId of selectedBrandsTasksIds) {
      for (const task of selectedBrandsById[brandId].tasks) {
        if (task.selected) {
          count += 1;
        }
      }
    }
    return {
      branches: count,
      brands: brandCounts,
    };
  }, [selectedBrandsTasksIds, selectedBrandsById]);
  const resolveTasksPayload = useMemo(() => {
    return Object.values(selectedBrandsById)
      .filter((brand) => brand.tasks.some((task) => task.selected))
      .map((element) => ({
        id: element.store_id,
        store_name: element.store_name,
        tasks: element.tasks.filter((task) => task.selected),
        contact_templates: element.contact_templates
          .filter((contact_template) => contact_template.selected)
          .map((contact_templates) => ({
            id: contact_templates.id,
            updated_content: contact_templates.updated_content,
          })),
      }));
  }, [selectedBrandsById]);
  const handleResolveTasks = () => {
    dispatch(resolveTasksAction({ stores: resolveTasksPayload }));
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <img className={styles.brandMessageIcon} src={ic_brand_message} />
        <span className={styles.headerTitle}>
          {storeName.trim() ? storeName : "Resolve All"}
        </span>
      </div>
      {resolve_all && (
        <div className={styles.sendToWrapper}>
          <span className={styles.sendToTitle}>Send to:</span>
          <img
            className={styles.icStore}
            style={{ marginRight: "8px" }}
            src={ic_store}
          />
          <span className={styles.brandCounts}>{counts.brands} Brands</span>
          <span
            className={styles.brandCounts}
            style={{ color: "#65656C", marginLeft: "8px" }}
          >
            ({counts.branches} branches)
          </span>
        </div>
      )}
      <ContactMethod />
      {selected_store_id.trim() &&
        !!(selectedBrandsById[selected_store_id].points_of_contacts || [])
          .length && <BrandPoc />}

      {(resolve_all || selectedBrandsTasksIds.length === 1) && (
        <div className={styles.sendBulkMessageWrapper}>
          <button
            disabled={!valid || resolve_tasks_loading_status === LoadingStatus.loading}
            onClick={handleResolveTasks}
            className={
              valid || resolve_tasks_loading_status !== LoadingStatus.loading
                ? styles.sendBulkMessageBtn
                : styles.disabledSendBulkMessageBtn
            }
          >
            {resolve_tasks_loading_status === LoadingStatus.loading ? (
              <ClipLoader color=" #fff" size={25} />
            ) : (
              <React.Fragment>
                {selectedBrandsTasksIds.length === 1
                  ? "Send Message"
                  : "Send Bulk Messages"}
              </React.Fragment>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default BrandMessage;
