import { useQRPortalDeal } from "./useQRPortalDeal";
import { Queriers } from "../../queries";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeMerchantDealsAtom } from "../../atoms";
import { usePortalDeal } from "./usePortalDeal";
import { useDeliveryDeal } from "./useDeliveryDeal";
import { useLoyaltyDeal } from "./useLoyaltyDeal";
import { usePickupDeal } from "./usePickupDeal";
import { useDriveThruDeal } from "./useDriveThruDeal";

export const useActiveMerchantDeals = (store_id) => {
  const [activeMerchantDeals, setActiveMerchantDeals] = useRecoilState(
    activeMerchantDealsAtom
  );

  const { data, status } = Queriers.getActiveMerchantDeals(store_id);
  const { portalToggle, mutatePortalDeal } = usePortalDeal(store_id, data);
  const { deliveryToggle } = useDeliveryDeal(store_id, data);
  const { loyaltyToggle } = useLoyaltyDeal(store_id, data);
  const { pickupToggle } = usePickupDeal(store_id, data);
  const { driveThruToggle } = useDriveThruDeal(store_id, data);
  const { qrPortalToggle, mutateQRPortalDeal } = useQRPortalDeal(
    store_id,
    data
  );
  useEffect(() => {
    if (data && status === "success") {
      setActiveMerchantDeals({
        delivery_status: {
          loading: "success",
          status: data.delivery_status,
        },
        loyalty_status: {
          loading: "success",
          status: data.loyalty_status,
        },
        pickup_status: {
          loading: "success",
          status: data.pickup_status,
        },
        drive_thru_status: {
          loading: "success",
          status: data.drive_thru_status,
        },
        web_portal_status: {
          loading: "success",
          status:
            data.web_portal_status === "service_enabled_unpublished" ||
            data.web_portal_status === "service_enabled_published"
              ? "service_enabled"
              : "service_disabled",
        },
        qr_portal_status: {
          loading: "success",
          status:
            data.qr_portal_status === "service_enabled_unpublished" ||
            data.qr_portal_status === "service_enabled_published"
              ? "service_enabled"
              : "service_disabled",
        },
      });
    }
  }, [data, status]);

  return {
    activeMerchantDeals,
    portalToggle,
    deliveryToggle,
    loyaltyToggle,
    pickupToggle,
    mutatePortalDeal,
    setActiveMerchantDeals,
    qrPortalToggle,
    mutateQRPortalDeal,
    driveThruToggle,
  };
};
