import axios from "axios";
import { PaymentStatus, MerchantStatus, Country, Activity } from "../../types";
import * as Requests from "../requests";

export const getMerchantsApi = async (
  token: string,
  params: {
    page: number;
    search?: string;
    payment_status?: PaymentStatus;
    merchant_status?: MerchantStatus;
    country?: Country;
    activity?: Activity;
  }
) => {
  const { data } = await axios.get(Requests.getMerchantsURL, {
    headers: { token },
    params: {
      ...params,
    },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
  return data;
};
