import { call, put, select, takeLatest } from "redux-saga/effects";
import { editCashbackDetailsApi } from "../../axios";
import { union } from "ts-action";
import { editCashbackDetailsAction } from "../../constants";
import {
  editCashbackDetails,
  editCashbackDetailsFailure,
  editCashbackDetailsSuccess,
} from "../actions";
import { selectToken } from "../selectors";
const actionTypes = union({ editCashbackDetails });
function* editCashbackDetailsSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    yield call(editCashbackDetailsApi, token, payload);
    yield put(
      editCashbackDetailsSuccess({
        cashback_merchant_share_end_date: payload.cashback_merchant_share_end_date,
        cashback_merchant_share: payload.cashback_merchant_share,
        cashback_merchant_share_start_date: payload.cashback_merchant_share_start_date,
        id: payload.store_id,
        promo_share_max_cpo: payload.promo_share_max_cpo,
        cashback_koinz_share: Math.abs(100 - Number(payload.cashback_merchant_share))
      })
    );
  } catch (error) {
    yield put(editCashbackDetailsFailure(error));
  }
}

export function* watchEditCashbackDetailsSaga() {
  yield takeLatest(
    editCashbackDetailsAction.requested,
    editCashbackDetailsSaga
  );
}
