import * as React from "react";
import * as styles from "../../../FormElements/styles.module.sass";
import { WrappedFieldProps } from "redux-form";

interface IProps {
  readOnly: boolean;
  label: string;
  type: string;
  className?: string;
  optional?: true;
  fullWidth: boolean;
  placeholder?: string
}
export class RenderGenericField extends React.Component<
  WrappedFieldProps & IProps
> {
  public render() {
    const { input, label, type, readOnly, fullWidth, optional, placeholder } = this.props;
    return (
      <div
        className={`${fullWidth ? styles.fullWidthInput : styles.promoIdInput}`}
      >
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
          {optional && <span className={styles.optional}>(Optional)</span>}
        </label>
        <input
          autoComplete="off"
          className={styles.input}
          readOnly={readOnly}
          placeholder={placeholder}
          id={input.name}
          {...input}
          type={type}
        />
      </div>
    );
  }
}
