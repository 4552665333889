import axios from "./axios";
import * as Requests from "./requests";

export enum DeliveryManagementStatus {
  enabled = "enabled",
  disabled = "disabled",
}
export interface IDeliveryManagementStatus {
  delivery_method: DeliveryManagementStatus;
}

export const editFleetStatus = (
  token: string,
  status: DeliveryManagementStatus,
  store_id
) => {
  return axios.put<IDeliveryManagementStatus>(
    Requests.getFleetStatus(store_id),

    { status },

    {
      headers: {
        token,
      },
      baseURL: process.env.PICKUP_ENDPOINT,
    }
  );
};
