import * as Requests from "../requests";
import axios from "axios";
import { OrderingGMVTypes } from "../../atoms/merchantProfile";

export const editOrderingGMVTypeApi = async (
  token,
  store_id,
  gmv_type: OrderingGMVTypes
) => {
  return await axios.put(
    Requests.orderingGMVTypeURL(store_id),
    { gmv_type },
    {
      headers: { token },
      baseURL: process.env.LUMEN_ENDPOINT,
    }
  );
};
