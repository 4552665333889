import axios from "./axios";

export interface IPosProvider {
  name: string;
  value: string;
  is_full_integration: 0 | 1;
}

export const getPOSProviders = () =>
  axios.get<{ pos_providers: IPosProvider[] }>("/api/get-pos-providers", {
    baseURL: process.env.POS_ENDPOINT,
  });
