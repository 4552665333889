import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IAddStoreInfo, IStore } from "./getBrands";

export const addBrand = (token, data): AxiosReturn<IStore> => {
  return axios.post(Requests.addBrand, data, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};

export const addBrandMock = (
  token,
  data: IAddStoreInfo
): AxiosReturn<{ id: string }> => {
  return axios.post(
    "https://private-57c776-shopxadmin.apiary-mock.com/add-store",
    data,
    {
      baseURL: "",
    }
  );
};
