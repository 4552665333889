import { call, put, select, debounce } from "redux-saga/effects";
import {
  getPromoCodeStoresAction,
  IGetStorePromoCodesAction,
} from "../../constants";
import { selectToken } from "../selectors";
import { getPromoCodesAPI } from "../../axios/getPromoCodes";
import {
  getPromoCodeSuccess,
  getPromoCodeFailed,
} from "../actions/getPromoCodesActions";

function* getPromoCodesSaga(action: IGetStorePromoCodesAction) {
  try {
    const token: string = yield select(selectToken);
    yield call(getPromoCodesAPI, token, {
      promo_code: action.payload.promo_code,
      stores_ids: action.payload.stores_id,
    });
    yield put(getPromoCodeSuccess(true));
  } catch (e) {
    yield put(getPromoCodeFailed(e));
  }
}

export function* watchGetPromoCodesSaga() {
  yield debounce(600, getPromoCodeStoresAction.requested, getPromoCodesSaga);
}
