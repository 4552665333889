import React, { useState } from "react";
import styles from "./styles.module.sass";
import chevronDown from "../../../assets/chevaron_up.svg";
import alert from "../../../assets/ic_alert.svg";
import { Collapse } from "antd";
import BranchesNeedAction from "../BranchesNeedAction";
import "../styles.less";
import { CheckIcon } from "../../../assets/components";
import { useDispatch } from "react-redux";
import {
  getPointsOfContacts,
  setSelectedTasksAction,
} from "../../../redux-store/actions";
import { useSelect } from "../../../hooks/useSelect";
import "../styles.less";

const BrandActionsItem: React.FC = () => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const { shownBrandsTasksById, shownBrandsTasksIds } = useSelect(
    (state) => state.actionCenterBrandsReducer
  );

  const handleExpand = (index: number) => {
    const expandedKeys = [...activeKey];
    if (expandedKeys.includes(index.toString())) {
      setActiveKey(expandedKeys.filter((key) => key !== index.toString()));
    } else {
      setActiveKey([...expandedKeys, index.toString()]);
    }
  };

  return (
    <Collapse
      activeKey={activeKey}
      bordered={false}
      className={`${styles.collapseWrapper} ant-brand-actions-item-collapse`}
    >
      {shownBrandsTasksIds.map((id, index) => {
        const storeImage =
          process.env.IMAGE_BASEURL + shownBrandsTasksById[id].store_image;
        const allTasksSelected = shownBrandsTasksById[id].tasks.every(
          (task) => task.selected
        );
        const item = shownBrandsTasksById[id];
        const selectAllBrandTasks = () => {
          dispatch(
            setSelectedTasksAction({
              selected: allTasksSelected ? false : true,
              store_id: shownBrandsTasksById[id].store_id,
              page: "action-center",
            })
          );
          if (!allTasksSelected) {
            dispatch(
              getPointsOfContacts({
                store_id: shownBrandsTasksById[id].store_id,
              })
            );
          }
        };

        return (
          <Collapse.Panel
            showArrow={false}
            header={
              <div className={styles.headerWrapper}>
                <div className={styles.leftSideWrapper}>
                  <div
                    onClick={() => {
                      selectAllBrandTasks();
                    }}
                    className={styles.checkbox}
                    style={{
                      backgroundColor: allTasksSelected ? "#1C1C1E" : "#F2F2F7",
                    }}
                  >
                    {allTasksSelected && (
                      <CheckIcon
                        style={{ width: "12px", height: "12px" }}
                        stroke="#fff"
                      />
                    )}
                  </div>
                  <div className={styles.brandInfoWrapper}>
                    <div className={styles.brandImageWrapper}>
                      <img src={storeImage} className={styles.brandImage} />
                    </div>
                    <div
                      onClick={() => {
                        if (
                          activeKey.includes(index.toString()) &&
                          activeKey.length === 1
                        ) {
                          setActiveKey([]);
                        } else {
                          setActiveKey([index.toString()]);
                        }
                      }}
                      className={styles.brandDetailsWrapper}
                    >
                      <span className={styles.brandName}>
                        {item.store_name}
                      </span>
                      <div className={styles.branchesNeedActionWrapper}>
                        <img className={styles.alertIcon} src={alert} />
                        <span className={styles.branchesNeedAction}>
                          {item.tasks.length} branches need action
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => handleExpand(index)}
                  className={styles.rightSideWrapper}
                >
                  <span className={styles.collapseIcon}>
                    {activeKey.includes(`${index}`) ? "Collapse" : "Expand"}
                  </span>
                  <img
                    className={styles.chevronIcon}
                    style={{
                      transform: activeKey.includes(`${index}`)
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                    }}
                    src={chevronDown}
                  />
                </div>
              </div>
            }
            className={`${styles.collapsePanel} brand-actions-item-header`}
            key={`${index}`}
          >
            <div className={styles.branchesNeedActionContent}>
              <BranchesNeedAction
                store_image={item.store_image}
                store_id={item.store_id}
                store_name={item.store_name}
                tasks={item.tasks}
                points_of_contacts={item.points_of_contacts}
              />
            </div>
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export default BrandActionsItem;