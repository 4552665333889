import { getPointsOfContactsAction } from "../../constants";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getPointsOfContactsSuccess, resetAppState } from "../actions";
import { IPointOfContact } from "../../types";

interface IPointsOfContactsState {
  points_of_contacts: IPointOfContact[];
}
export const getPointsOfContactsReducer = withLoadingReducer<
  IPointsOfContactsState
>(
  reducer<IPointsOfContactsState>(
    [
      on(resetAppState, () => ({ points_of_contacts: [] })),
      on(getPointsOfContactsSuccess, (state, { payload }) => ({
        ...state,
        points_of_contacts: payload.points_of_contacts,
      })),
    ],
    {
      points_of_contacts: [],
    }
  ),
  getPointsOfContactsAction
);
