import {
  getBrandDetailsAction,
  IEditBrandMutate,
  IEditBrandSuccessAction,
  IGetBrandDetailsSuccessAction,
} from "../../types";
import * as constants from "../../constants";
import { mutateState } from "../../helpers/mutate-state";
import { IStore } from "../../axios/getBrands";

const BRAND_DETAILS = "BRAND_DETAILS";
const LOADING = "loading";
const LOADED = "loaded";
const FAILED = "failed";
const BRAND_STATS = "brandStats";

export interface IBrandDetailsReducerState {
  [BRAND_DETAILS]: IStore | undefined;
  [LOADED]: boolean;
  [LOADING]: boolean;
  [FAILED]: boolean;
  [BRAND_STATS]: {
    failedOperationsNumber: number;
    registeredCustomersNumber: number;
    reviewsNumber: number;
    conversionRate: number;
    redemptionRate: number;
    redemptionNumber: number;
    tempCustomersNumber: number;
    visitsNumber: number;
  };
}

const initialState: IBrandDetailsReducerState = {
  [BRAND_DETAILS]: undefined,
  [LOADED]: false,
  [LOADING]: false,
  [FAILED]: false,
  [BRAND_STATS]: {
    failedOperationsNumber: 0,
    registeredCustomersNumber: 0,
    reviewsNumber: 0,
    conversionRate: 0,
    redemptionRate: 0,
    redemptionNumber: 0,
    tempCustomersNumber: 0,
    visitsNumber: 0,
  },
};

export const brandDetailsReducer = (
  state = initialState,
  action:
    | getBrandDetailsAction
    | IGetBrandDetailsSuccessAction
    | IEditBrandSuccessAction
): IBrandDetailsReducerState => {
  switch (action.type) {
    case constants.getBrandDetailsAction.requested: {
      return mutateState(state, (map) => {
        map.set(LOADED, false);
        map.set(LOADING, true);
        map.set(FAILED, false);
      });
    }
    case constants.getBrandDetailsAction.rejected: {
      return mutateState(state, (map) => {
        map.set(LOADED, false);
        map.set(LOADING, false);
        map.set(FAILED, true);
      });
    }
    case constants.getBrandDetailsAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(
          BRAND_DETAILS,
          (action as IGetBrandDetailsSuccessAction).payload
        );
        map.set(LOADED, true);
        map.set(LOADING, false);
        map.set(FAILED, false);
      });
    }
    case constants.ACTION_EDIT_BRAND_MUTATION_SUCCESS: {
      return mutateState(state, (map) => {
        const oldBrandDetails = map.get(BRAND_DETAILS) as IStore;
        map.set(BRAND_DETAILS, {
          ...oldBrandDetails,
          ...(action as IEditBrandMutate).payload,
        });
      });
    }
    case constants.editBrandAction.fulfilled: {
      return mutateState(state, (map) => {
        const oldBrandDetails = map.get(BRAND_DETAILS) as IStore;
        map.set(BRAND_DETAILS, {
          ...oldBrandDetails,
          ...(action as IEditBrandSuccessAction).payload,
        });
      });
    }
    default:
      return state;
  }
};
