import React from "react";
import styles from "./styles.module.sass";
import { IStore } from "../../../../axios/getBrands";
import { getStatus } from "../../../../../Utils/status";
import { LazyImage } from "../../../../UIComponents/LazyImage/index";
import { prefixImage } from "../../../Profile/index";
import { useDispatch } from "react-redux";
import { logDashboardPreviewRequest } from "../../../../redux-store/actions/dashboardPreviewLoggerAction";
import { notify } from "react-notify-toast";
import { useSelect } from "../../../../hooks/useSelect";
import history from "../../../../helpers/history";
import { paymentStatuses } from "../../../../helpers/options";
import { useDeepCompareMemo } from "use-deep-compare";
import ReactTooltip from "react-tooltip";
import { useToggleEditModal } from "../../../../hooks/merchants/useToggleEditModal";
import EditModal from "../../../..//UIComponents/EditModal";
import { clearPrevCashbackDetails } from "../../../../redux-store/actions";
interface IProps {
  merchant: IStore;
}

const countries = {
  ae: "🇦🇪 United Arab Emirates",
  eg: "🇪🇬 Egypt",
  sa: "🇸🇦 Saudi Arabia",
  om: "🇴🇲 Oman",
};

const paymentStatusClass: Record<number, string> = {
  0: styles.setup,
  1: styles.trial,
  2: styles.paid,
  3: styles.waitingForPayment,
};
const activeDealsTexts = {
  pickup: "Pickup",
  delivery: "Delivery",
  web_portal: "Web portal",
  loyalty: "Loyalty system",
};
const statusesClass = [
  styles.inactiveStatusWrapper,
  styles.activeStatusWrapper,
  styles.inProgressStatusWrapper,
  styles.closedStatusWrapper,
  styles.churnedStatusWrapper
];
const activeDealsTypesById = {
  delivery: require("../../../../assets/delivery.svg"),
  pickup: require("../../../../assets/pickup.svg"),
  loyalty: require("../../../../assets/loyalty.svg"),
  web_portal: require("../../../../assets/web-portal.svg"),
};
export const MerchantsTableBody: React.FC<IProps> = ({ merchant }) => {
  const dispatch = useDispatch();
  const { token } = useSelect((state) => ({
    token: state.authReducer.token,
  }));
  const { toggleEditBrandModal, fullData, editModalOpen } = useToggleEditModal(
    merchant.id
  );

  const recordDashboardPreviewRequest = () => {
    dispatch(logDashboardPreviewRequest({ store_id: merchant.id }));
  };
  const openDashboardPreview = () => {
    try {
      const url = new URL(`${process.env.DASHBOARD_FRONTEND_APP_URL}`);
      url.searchParams.append("token", token);
      url.searchParams.append("store_id", merchant.id);
      url.searchParams.append("is_remote_authentication", "true");
      url.searchParams.append("email", "Admin");
      return `${url.origin}/#/preview/auth${url.search}`;
    } catch (e) {
      // throw error
      notify.show("Invalid dashboard URL", "error");
      return "";
    }
  };
  const previewBrand = () => {
    if (merchant?.id) {
      dispatch(clearPrevCashbackDetails())
      history.push(`/profile/${merchant?.id}/statistics`);
    }
  };
  const paymentStatus = useDeepCompareMemo(
    () => paymentStatuses.filter((ps) => ps.value === merchant.payment_status),
    [paymentStatuses, merchant.payment_status]
  );
  return (
    <div key={merchant.id} className={styles.main}>
      <div onClick={previewBrand} className={styles.brandNameWrapper}>
        <div className={styles.brandIconWrapper}>
          <LazyImage
            src={
              merchant?.store_image
                ? prefixImage(merchant?.store_image)
                : require("../../../../assets/admin-placeholder-profile.svg")
            }
            className={styles.brandIcon}
            placeholder={require("../../../../assets/admin-placeholder-profile.svg")}
          />
        </div>
        <div className={styles.brandWrapper}>
          <span className={styles.brandName}>{merchant.name}</span>
          <span className={styles.country}>
            {countries[merchant?.country_iso_code?.toLowerCase()]}
          </span>
        </div>
      </div>
      <div onClick={previewBrand} className={statusesClass[merchant?.status]}>
        <div className={styles.dot} />
        <span className={styles.running}>{getStatus(merchant.status)}</span>
      </div>
      <div onClick={previewBrand} className={styles.activeDealsType}>
        {(merchant.active_deals || [])?.map((active_deal) => (
          <React.Fragment key={active_deal}>
            <ReactTooltip
              id={`##${active_deal}`}
              place={"top"}
              className={styles.tooltipWrapper}
            >
              {activeDealsTexts[active_deal]}
            </ReactTooltip>
            <img
              data-tip={true}
              data-for={`##${active_deal}`}
              className={styles.activeDealsIco}
              src={activeDealsTypesById[active_deal]}
            />
          </React.Fragment>
        ))}
      </div>

      <span
        onClick={previewBrand}
        className={paymentStatusClass[paymentStatus[0]?.value]}
      >
        {paymentStatus[0]?.text.split("\n").map((s) => (
          <span key={s}>{s}</span>
        ))}
      </span>
      {editModalOpen && (
        <EditModal
          brand={fullData}
          initialValues={{ ...fullData.store, ...fullData.manager }}
          modal={editModalOpen}
          toggle={toggleEditBrandModal}
          title="Edit Brand"
        />
      )}

      <div className={styles.actionsWrapper}>
        <a
          target="_blank"
          onClick={recordDashboardPreviewRequest}
          href={openDashboardPreview()}
          className={styles.buttonWrapper}
        >
          <img
            className={styles.buttonIcon}
            src={require("../../../../assets/feather _ external-link.svg")}
          />
          <span className={styles.buttonText}> Dashboard</span>
        </a>
        <button onClick={toggleEditBrandModal} className={styles.editButton}>
          <img
            className={styles.editIcon}
            src={require("../../../../assets/feather _ edit.svg")}
          />
        </button>
      </div>
    </div>
  );
};

export default MerchantsTableBody;
