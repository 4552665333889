import { useState, useCallback, useEffect } from "react";
import {
  editFleetStatus,
  DeliveryManagementStatus,
} from "../../../../../axios/editFleetStatus";
import { useProgressState } from "../../../../ShopXSettings/useProgressState";
import { getStoreInfo } from "../../../../../axios/getStoreInfo";

export const useDeliveryMethod = (token: string, store_id: string) => {
  const [deliveryManagementStatus, setDeliveryManagementStatus] = useState(
    DeliveryManagementStatus.disabled
  );
  const {
    setFailure,
    setLoading,
    setSuccess,
    ...progress
  } = useProgressState();

  const deliveryManagementStatusSwitch = useCallback(
    (newStatus: DeliveryManagementStatus) => {
      setLoading();
      setDeliveryManagementStatus(newStatus);
      editFleetStatus(token, newStatus, store_id)
        .then(setSuccess)
        .catch(() => {
          setDeliveryManagementStatus(deliveryManagementStatus);
          setFailure();
        });
    },
    [setLoading, setSuccess, setFailure]
  );
  useEffect(() => {
    setLoading();
    getStoreInfo(token, store_id)
      .then((body) => {
        setSuccess();
        setDeliveryManagementStatus(body.data.delivery_management_status);
      })
      .catch(setFailure);
  }, [store_id, token]);
  return {
    deliveryManagementStatusSwitch,
    deliveryManagementStatus,
    ...progress,
  };
};
