import axios from "axios";
import * as Requests from "./requests";

export const changeStorePromotedStatus = async (
  store_id: string,
  token: string,
  body: { promoted_status: "add" | "remove" }
) => {
  const { data } = await axios.put(
    Requests.AddOrDeleteToPromotedBrandsList(store_id),
    body,
    {
      headers: { token },
      baseURL: process.env.LUMEN_ENDPOINT,
    }
  );

  return data;
};
