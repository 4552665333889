import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  sendSmsMessageAction,
  sendSmsMessageActionType,
} from "../../constants";
import { selectToken } from "../selectors";
import { sendSmsMessageAPI } from "../../axios/sendSms";
import {
  sendSmsMessageSuccess,
  sendSmsMessageFailed,
} from "../actions/sendSmsMessageAction";
import { notify } from "react-notify-toast";
import { getSMSCampaigns } from "../../Components/TargetedTools/redux-store/actions/sms-campaigns-actions";

function* sendSmsMessageSaga(action: sendSmsMessageActionType) {
  try {
    const token: string = yield select(selectToken);
    const res = yield call(
      sendSmsMessageAPI,
      token,
      action.payload as FormData
    );

    if (res.status >= 200 && res.status < 300) {
      yield put(
        sendSmsMessageSuccess({ success: res.data.total_accepted, failed: 0 })
      );
      yield put(getSMSCampaigns({ page: 0, per_page: 25 }));
      notify.show("SMS Sent successfully", "success");
    } else {
      throw res;
    }
  } catch (error) {
    yield put(sendSmsMessageFailed(error));
    notify.show("There is an error", "error");
    // console.log("Error Message : ", error);
  }
}

export function* watchSendSmsMessageSaga() {
  yield takeLatest(sendSmsMessageAction.requested, sendSmsMessageSaga);
}
