import * as constants from "../../constants";
import { IAuthAction, ILoginSuccessAction } from "../../types";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";

export interface IAuthReducerState {
  token: string;
  isLoggedIn: boolean;
}

const TOKEN = "token";
const IS_LOGGED_IN = "isLoggedIn";

const initialState: IAuthReducerState = Map({
  [TOKEN]: "",
  [IS_LOGGED_IN]: false,
}).toJS();

const authReducer = (
  state: IAuthReducerState = initialState,
  action: IAuthAction
): IAuthReducerState => {
  switch (action.type) {
    case constants.loginAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(TOKEN, (action as ILoginSuccessAction).payload.token);
        map.set(IS_LOGGED_IN, true);
      });
    }
    case constants.logoutAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(TOKEN, "");
        map.set(IS_LOGGED_IN, false);
      });
    }
    default:
      return state;
  }
};

export default authReducer;
