import React, { useCallback, useEffect, useMemo } from "react";
import styles from "./styles.module.sass";
import ic_copy from "../../../assets/ic_copy.svg";
import { CheckIcon } from "../../../assets/components";
import { useBrandContactMethods } from "../../../hooks";
import { ContactMethodType } from "../../../types";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentContactMethodAtom,
  selectedContactMethodsState,
} from "../../../atoms";
import { contactMethods } from "../../../constants";
import copy from "copy-to-clipboard";
import { validateTakeActions } from "../../../validations";

export const ContactMethod: React.FC = () => {
  const {
    handleSelectedContactMethods,
    handleTemplateMessageChange,
    handleContactMethodTabView,
    selected_store_id,
    checkedContactMethod,
    templateMessageValue,
    setTemplateMessageValue,
  } = useBrandContactMethods();

  const [currentContactMethod, setCurrentContactMethod] = useRecoilState<
    ContactMethodType
  >(currentContactMethodAtom);
  const {
    getCurrentMethodData,
    contact_methods_view,
  } = useRecoilValue(selectedContactMethodsState);
  const { valid, messages } = useRecoilValue(validateTakeActions);
  useEffect(() => {
    if (selected_store_id && contact_methods_view) {
      Object.entries(contact_methods_view?.[selected_store_id] ?? {}).forEach(
        ([contact_method, element]) => {
          if (element.selected) {
            setCurrentContactMethod(Number(contact_method));
          }
        }
      );
    }
  }, [selected_store_id, contact_methods_view]);
  const templateMessage = useMemo(
    () =>
      getCurrentMethodData(currentContactMethod, selected_store_id)?.message,
    [getCurrentMethodData, currentContactMethod, selected_store_id]
  );
  const inputLength = useMemo(() => {
    return templateMessage?.length ?? 0;
  }, [templateMessage]);

  const copyTemplateMessage = useCallback(() => {
    if (templateMessage) {
      copy(templateMessage);
    }
  }, [templateMessage]);

  useEffect(() => {
    setTemplateMessageValue(templateMessage ?? "");
  }, [templateMessage]);

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Contact Method</span>
      {/* Render the contact method tabs */}
      <div className={styles.contactTabsWrapper}>
        <div className={styles.contactMethodsTabs}>
          {contactMethods.map(({ icon, label, value }) => {
            const elementChecked = checkedContactMethod(value);

            return (
              <div
                onClick={() => handleSelectedContactMethods(value)}
                className={
                  elementChecked
                    ? styles.activeContactWrapper
                    : styles.contactTabWrapper
                }
                key={value}
              >
                <div className={styles.titleWrapper}>
                  <img className={styles.methodIcon} src={icon} />
                  <span className={styles.methodTitle}>{label}</span>
                </div>
                <div
                  className={
                    elementChecked
                      ? styles.checkedBoxWrapper
                      : styles.checkboxWrapper
                  }
                >
                  {elementChecked && (
                    <CheckIcon
                      stroke="#fff"
                      style={{ width: "12px", height: "12px" }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {!valid && messages?.[currentContactMethod]?.channel && (
          <span
            style={{
              fontSize: "14px",
              color: "#EE5253",
              fontWeight: 500,
              paddingTop: "4px",
            }}
          >
            {messages[currentContactMethod]?.channel}
          </span>
        )}
      </div>

      {/* Render the template message input field */}
      <div className={styles.templateMessageTitleWrapper}>
        <span className={styles.templateMessageTitle}>Template Message</span>
        <div className={styles.copyTemplateMessageWrapper}>
          <img
            onClick={copyTemplateMessage}
            src={ic_copy}
            className={styles.copyTemplateMessageIcon}
          />{" "}
          <span className={styles.copyText}>Copy</span>
        </div>
      </div>
      <div className={styles.templateMessageInputWrapper}>
        <textarea
          value={templateMessageValue}
          onChange={(event) => {
            if (
              inputLength < 300 &&
              currentContactMethod !== ContactMethodType.Whatsapp
            ) {
              handleTemplateMessageChange(event, currentContactMethod);
            }
          }}
          className={styles.input}
        />
        <span className={styles.textCount}>{inputLength}/300</span>
      </div>
      {messages?.[currentContactMethod]?.template && (
        <span
          style={{
            fontSize: "14px",
            color: "#EE5253",
            fontWeight: 500,
            paddingTop: "4px",
          }}
        >
          {messages[currentContactMethod]?.template}
        </span>
      )}
      {/* Render the contact method view tabs */}
      <div className={styles.contactTabsViews}>
        {contactMethods.map(({ label, value }) => (
          <span
            key={value}
            onClick={() => {
              handleContactMethodTabView(value);
              setCurrentContactMethod(value);
            }}
            className={
              getCurrentMethodData(value, selected_store_id)?.selected
                ? styles.selectedContactTabView
                : styles.contactTabView
            }
          >
            {label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ContactMethod;
