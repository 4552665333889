import React from "react";
import { FieldUIWrapper } from "../../../TargetedTools/BannerCards/AddBannerCard/RenderMultipleRadioButtonsField";
import * as styles from "./styles.module.sass";

export const defaultCSVExample = require("../../../../../public/exmples/text.csv")
  .default;
const DownloadExample: React.FC = () => {
  return (
    <a className={styles.downloadTemplate} href={defaultCSVExample}>
      <img
        className={styles.downloadIcon}
        src={require("../../../../assets/download.svg")}
      />
      Download CSV template
    </a>
  );
};

interface IProps {
  label?: string;
  buttonText: string;
  disabled?: boolean;
  value: File;
  touched?: boolean;
  error?: any;
  name?: string;
  onChange: (e) => void;
}

export const GenericFileUploadField: React.FC<IProps> = ({
  label,
  buttonText,
  disabled,
  onChange,
  error,
  name,
  touched,
  value,
}) => {
  const isInvalid = touched && error;
  const handleChangeFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length) {
        onChange(e.target.files[0]);
      }
    },
    []
  );

  return (
    <FieldUIWrapper label={label ?? ""} isInvalid={isInvalid} error={error}>
      <div className={styles.wrapper}>
        <label
          htmlFor={name}
          className={disabled ? styles.dimmedButton : styles.uploadButton}
        >
          {buttonText}
          <input
            onChange={handleChangeFile}
            type={"file"}
            disabled={disabled}
            id={name}
            hidden={true}
            accept={".csv"}
          />
        </label>
        <DownloadExample />
      </div>
      <span className={styles.fileName}>
        {value instanceof File && value.name}
      </span>
    </FieldUIWrapper>
  );
};
