import { on, reducer } from "ts-action";
import { IAnnouncementBase } from "../../types";
import {
  createAnnouncementSuccess,
  deleteAnnouncement,
  getAnnouncementsSuccess,
  createAnnouncementFailure,
  createAnnouncementReset,
  createAnnouncement,
} from "../actions/announcementsActions";
import { mapById, convertToIds } from "./lib";
import {
  ILoadingState,
  LoadingStatus,
  withLoadingReducer,
} from "../../../../redux-store/reducers/withLoadingState";
import { getAnnouncementsAction } from "../actions/constants";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";

interface IState {
  announcements: string[];
  announcementsById: { [x: string]: IAnnouncementBase };
  mutationStatus: LoadingStatus;
}

export const announcementsReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(getAnnouncementsSuccess, (state, { payload }) => ({
          ...state,
          announcements: convertToIds(payload),
          announcementsById: mapById(payload),
        })),
        on(deleteAnnouncement, (state, action) => {
          return {
            ...state,
            announcements: state.announcements.filter(
              (item) => item !== action.payload.id
            ),
          };
        }),
        on(createAnnouncementSuccess, (state, { payload }) => {
          return {
            ...state,
            announcementsById: {
              ...state.announcementsById,
              [payload.id]: payload,
            },
            announcements: [payload.id, ...state.announcements],
            mutationStatus: LoadingStatus.success,
          };
        }),
        on(createAnnouncementFailure, (state) => {
          return {
            ...state,
            mutationStatus: LoadingStatus.failed,
          };
        }),
        on(createAnnouncementReset, (state) => {
          return {
            ...state,
            mutationStatus: LoadingStatus.idle,
          };
        }),
        on(createAnnouncement, (state) => {
          return {
            ...state,
            mutationStatus: LoadingStatus.loading,
          };
        }),
      ],
      {
        announcements: [],
        announcementsById: {},
        mutationStatus: LoadingStatus.idle,
      }
    ),
    getAnnouncementsAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
