import {
  Field,
  reduxForm,
  InjectedFormProps,
  formValueSelector,
} from "redux-form";
import { Drawer } from "@material-ui/core";
import React, { Fragment } from "react";
import * as styles from "./styles.module.sass";
import { CustomDropZoneField } from "./CustomDropZoneField";
import { maxLength, required } from "../../../../redux-store/validators";
import { RenderMultipleRadioButtonsField } from "./RenderMultipleRadioButtonsField";
import { ISelectItemData } from "../../../FormElements/RenderDropDownSelect";
import { SystemAnnouncementTargeting } from "../../types";
import { useDispatch } from "react-redux";
import { createBannerCard } from "../../redux-store/actions/bannerCardsActions";
import { RenderGenericFileUploadField } from "./RenderGenericFileUploadField";
import { useSelect } from "../../../../hooks/useSelect";
import { SelectStoresByCountry } from "../../AddPromoCode/PromoCodeForm/SelectStoresByCountry";
import {
  isScreenRequiresStoreId,
  StoreScreens,
  TARGET_SCREENS,
} from "../lib/mobile-app-screens";
import { RenderDropDownSelect } from "../../../SharedComponent/RenderDropDownSelect";
import { RenderGenericField } from "./RenderGenericField";

interface IExtraProps {
  open: boolean;
  closeForm: () => void;
}

const VisibilityOptions: ISelectItemData<SystemAnnouncementTargeting>[] = [
  {
    value: SystemAnnouncementTargeting.public,
    label: "All users",
  },
  {
    value: SystemAnnouncementTargeting.private,
    label: "Selected users",
  },
];

const addBannerCardStateSelector = formValueSelector("addBannerCard");

type IProps = IExtraProps & InjectedFormProps;

export const maxLength1 = maxLength(1);

export const getStoreMetaData = (values) => {
  let meta_data;
  const selectedStoreId = values.stores_ids?.[0];
  if (selectedStoreId) {
    meta_data = {
      ...meta_data,
      store_id: selectedStoreId,
    };
  }
  if (
    values.store_filters &&
    values.header_icon &&
    values.en_header_title &&
    values.ar_header_title
  ) {
    meta_data = {
      ...meta_data,
      store_filters: values.store_filters,
      header_icon: values.header_icon,
      en_header_title: values.en_header_title,
      ar_header_title: values.ar_header_title,
    };
  }
  if (values.reel_id) {
    meta_data = {
      reel_id: values.reel_id,
    };
  }
  if (values.influencer_profile_id) {
    meta_data = {
      influencer_profile_id: values.influencer_profile_id,
    };
  }
  return meta_data;
};

const AddBannerCard: React.FC<IProps> = ({
  open,
  closeForm,
  handleSubmit,
  invalid,
  destroy,
  form,
}) => {
  const dispatch = useDispatch();
  const submitCard = React.useCallback((values) => {
    const meta_data = getStoreMetaData(values);
    dispatch(
      createBannerCard({
        ...values,
        meta_data,
      })
    );
    handleClose();
  }, []);
  const handleClose = React.useCallback(() => {
    destroy();
    closeForm();
  }, [closeForm, destroy]);

  const { visibility, value } = useSelect((state) =>
    addBannerCardStateSelector(state, "visibility", "value")
  );
  return (
    <Drawer anchor={"right"} open={open}>
      <div className={styles.form}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Add banner card
            <span className={"icon-close"} onClick={handleClose} />
          </div>
          <Field
            name="image"
            component={CustomDropZoneField}
            validate={[required]}
          />

          <Field
            validate={[required]}
            name={"value"}
            label={"Target screen"}
            options={TARGET_SCREENS}
            className={true}
            component={RenderDropDownSelect as any}
          />

          {value && value !== StoreScreens.screen_dynamic_stores && (
            <Fragment>
              {isScreenRequiresStoreId(value) && (
                <SelectStoresByCountry
                  validateCountry={[required]}
                  validateStores={[required, maxLength1]}
                  onlyOne={true}
                  formName={form}
                />
              )}
              <Field
                component={RenderGenericField}
                optional={true}
                label={"Target promo code ID"}
                name={"ordering_promotion_id"}
              />
            </Fragment>
          )}
          {value === StoreScreens.screen_dynamic_stores && (
            <Fragment>
              <Field
                component={RenderGenericField}
                label={"Filter name"}
                placeholder="Enter filter name"
                name={"store_filters"}
                validate={[required]}
              />
              <Field
                component={RenderGenericField}
                label={"Header title (English)"}
                placeholder="Enter header title"
                name={"en_header_title"}
                validate={[required]}
              />
              <Field
                component={RenderGenericField}
                label={"Header title (بالعربية)"}
                placeholder="Enter header title"
                name={"ar_header_title"}
                validate={[required]}
              />
              <span className={styles.headerIconText}>Header Icon</span>
              <Field
                name="header_icon"
                component={CustomDropZoneField}
                validate={[required]}
                imageSize={"120px * 88px"}
                wrapperClassName={styles.headerIconWrapper}
                dropZoneClassName={styles.dropzone}
                imagePreviewClassName={styles.headerIconPreview}
                recommendedSizeUI={
                  <div className={styles.recommendedSizeWrapper}>
                    <span className={styles.recommendedSizeText}>
                      Drag and drop icon or browse
                    </span>
                    <span className={styles.recommendedSizeSubText}>
                      Recommended size: 120px * 88px
                    </span>
                  </div>
                }
              />
            </Fragment>
          )}
          <Field
            validate={[required]}
            label={"Target users"}
            component={RenderMultipleRadioButtonsField}
            options={VisibilityOptions}
            name="visibility"
          />
          {visibility === SystemAnnouncementTargeting.private && (
            <Field
              validate={[required]}
              buttonText={"Upload CSV file"}
              label={"CSV upload"}
              component={RenderGenericFileUploadField}
              name={"customers_file"}
            />
          )}
        </div>
        <button
          disabled={invalid}
          onClick={handleSubmit(submitCard)}
          className={styles.submit}
        >
          Add banner card
        </button>
      </div>
    </Drawer>
  );
};

export default reduxForm<{}, IExtraProps>({
  form: "addBannerCard",
})(AddBannerCard as React.FC<IExtraProps>);
