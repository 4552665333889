import React, { useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { GenericButton } from "../../../SharedComponent/GenericButtonWithIcon";
import SendSMS from "../SendSMS";

interface IProps {
  length: number;
}
const SMSHeader: React.FC<IProps> = ({ length }) => {
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.headWrapper}>
          <span className={styles.pushNotificationCount}>
            Total sms ({length})
          </span>
          <GenericButton
            iconPath={require("../../../../assets/add.svg")}
            className={styles.createBtn}
            IconClass={styles.icon}
            label={"Add SMS"}
            onClick={handleOpenModal}
          />
          {open && <SendSMS open={open} handleClose={handleCloseModal} />}
        </div>
        <div className={styles.header}>
          <span className={styles.date}>Date</span>

          <span className={styles.body}>Body</span>
          <span className={styles.success}>Success rate</span>
          <span className={styles.status}>Status</span>
        </div>
      </div>
    </div>
  );
};

export default hot(module)(SMSHeader);
