import { IGetPaymentLogsAction } from "../../types";
import { selectToken } from "../selectors";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { getPaymentLogsFailure, getPaymentLogsSuccess } from "../actions";
import { getPaymentLogsAction } from "../../constants";
import { getPaymentLogs } from "../../axios/getPaymentLogs";

function* paymentLogsSaga(action: IGetPaymentLogsAction) {
  try {
    const token = yield select(selectToken);
    const res: AxiosResponse<any> = yield call(
      getPaymentLogs,
      token,
      action.payload
    );
    yield put(getPaymentLogsSuccess(res.data));
  } catch (e) {
    yield put(getPaymentLogsFailure(e));
  }
}

export function* watchPaymentLogsSaga() {
  yield takeLatest(getPaymentLogsAction.requested, paymentLogsSaga);
}
