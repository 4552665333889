import { useMutation } from "react-query";
import { ServiceType } from "../../axios/merchantProfile/editActiveMerchantDealsApi";
import { ServiceStatus } from "../../atoms/merchantProfile";
import { editActiveMerchantDealsApi } from "../../axios/merchantProfile";
import { useSelect } from "../../hooks/useSelect";

export const editActiveMerchantDeals = (
  store_id,
  service_type: ServiceType
) => {
  const token = useSelect((state) => state.authReducer.token);
  return useMutation(
    (status: ServiceStatus) =>
      editActiveMerchantDealsApi(token, store_id, service_type, status),
    { retry: 0 }
  );
};
