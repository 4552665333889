import React from "react";
import * as styles from "./styles.module.sass";
import { uDIC } from "../useDefaultAndInsertedClassName";
import { ClipLoader } from "react-spinners";

interface IProps {
  label: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  spinnerColor?: string;
  loading?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
}
const GenericButtonWithoutIcon: React.FC<IProps> &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > = (props) => {
  return (
    <button
      type="button"
      {...props}
      className={uDIC(
        `${props.disabled ? styles.dis : styles.genericButtonDefaults}`,
        props.className
      )}
      disabled={props.disabled}
    >
      {props.loading ? (
        <ClipLoader size={30} color={props.spinnerColor} />
      ) : (
        props.label
      )}
    </button>
  );
};

export { GenericButtonWithoutIcon };
