import { call, select, takeLatest, put } from "redux-saga/effects";
import { notify } from "react-notify-toast";
import {
  createPromoCode,
  createPromoCodeFailure,
  createPromoCodeSuccess,
} from "../actions/promoCodeActions";
import { selectToken } from "../../../../redux-store/selectors";
import { PromoCodesAPI } from "../../axios/createPromoCode";
import { createPromoCodeAction } from "../actions/constants";
import { union } from "ts-action";
import { PromoScreens } from "../../AddPromoCode/PromoCodeForm/lib";

const actionType = union({ createPromoCode });

function* createPromoCodeSaga({ payload }: typeof actionType) {
  try {
    const token: string = yield select(selectToken);
    const isMultiplier = payload.get("type") === PromoScreens.multiplier;
    const isCashbackPromo =
      payload.get("type") === PromoScreens.cashback_percentage;
    const type = isMultiplier
      ? "points-multiplier"
      : isCashbackPromo
      ? "cashback"
      : "discount";

    if (isMultiplier) {
      payload.delete("type");
    }

    const res = yield call(PromoCodesAPI, token, payload, type);
    yield put(createPromoCodeSuccess(res.data));
    notify.show("promo code created successfully", "success");
  } catch (error) {
    if (
      error.response.data.validation_errors.promo_code[0].includes(
        "the promo_code value can't equal to an active promo code in any of the specified stores"
      )
    ) {
      notify.show(
        "The promo code value can't equal to an active promo code in any of the specified stores",
        "error"
      );
    } else if(error.message.includes("Network Error")) {
      notify.show("Please check your internet", "error");
    }  else {
      notify.show("promo code creation failed", "error");
    }
    yield put(createPromoCodeFailure(error));
  }
}

export function* watchCreatePromoCodeSaga() {
  yield takeLatest(createPromoCodeAction.requested, createPromoCodeSaga);
}
