import axios from "./axios";
import { bannerCardsURL } from "./requests";
import { IGetBannerCardItem } from "../types";

export const createBannerCardAPI = (token: string, cardData: FormData) => {
  return axios.post<IGetBannerCardItem>(`${bannerCardsURL}`, cardData, {
    headers: { token },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
