import * as React from "react";
import * as styles from "./style.module.sass";
import CreateCategory from "../../CreateCategory";
import { GenericButton } from "../../../../SharedComponent/GenericButtonWithIcon";
// import AddPromoCode from "../../AddPromoCode/PromoCodeForm";
// import { GenericButton } from "../../../SharedComponent/GenericButtonWithIcon";

interface IProps {
  totalCategories: number;
}
const ViewCategoriesHeader: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const openModalHandler = () => setOpen(true);
  const closeModalHandler = () => setOpen(false);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headWrapper}>
          <span className={styles.totalPromoCodesNum}>
            Total Categories ({props.totalCategories})
          </span>
          <GenericButton
            label={"Add new category"}
            className={styles.createBtn}
            IconClass={styles.icon}
            onClick={openModalHandler}
            iconPath={require("../../../../../assets/add.svg")}
          />
        </div>
      </div>
      {open && (
        <CreateCategory
          is_create={true}
          open={open}
          onClose={closeModalHandler}
        />
      )}
    </>
  );
};
export default ViewCategoriesHeader;
