import { action, payload } from "ts-action";
import {
  getCategoriesAction,
  createCategoryAction,
  deleteCategoryAction,
  editCategoryAction,
} from "./constants";
import { ICategory, ICreateCategory } from "../../types";
import { optAction, optReaction } from "../../../../redux-store/actions/lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const getCategories = action(getCategoriesAction.requested);
export const getCategoriesSuccess = action(
  getCategoriesAction.fulfilled,
  payload<ICategory[]>()
);
export const getCategoriesFailure = action(getCategoriesAction.rejected);

export const createCategory = optAction(
  createCategoryAction.requested,
  payload<ICreateCategory>()
);
export const createCategorySuccess = optReaction(
  createCategoryAction.fulfilled,
  payload<ICreateCategory>(),
  COMMIT
);
export const createCategoryFailure = optReaction(
  createCategoryAction.rejected,
  payload<ICreateCategory>(),
  REVERT
);

export const deleteCategory = optAction(
  deleteCategoryAction.requested,
  payload<ICreateCategory>()
);
export const deleteCategorySuccess = optReaction(
  deleteCategoryAction.fulfilled,
  payload<ICreateCategory>(),
  COMMIT
);
export const deleteCategoryFailure = optReaction(
  deleteCategoryAction.rejected,
  payload<ICreateCategory>(),
  REVERT
);

export const editCategory = optAction(
  editCategoryAction.requested,
  payload<ICreateCategory>()
);
export const editCategorySuccess = optReaction(
  editCategoryAction.fulfilled,
  payload<ICreateCategory>(),
  COMMIT
);
export const editCategoryFailure = optReaction(
  editCategoryAction.rejected,
  payload<ICreateCategory>(),
  REVERT
);
