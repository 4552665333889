import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import { getWatchers } from "../../axios/getWatchers";
import { selectToken } from "../../redux-store/selectors";
import { getWatchersAction } from "../../constants";

function* getWatchersSaga(action) {
  try {
    const token = yield select(selectToken);
    const watcher = yield call(getWatchers, token);

    yield put(actions.getWatchersSuccess(watcher.data));
  } catch (e) {
    yield put(actions.getWatchersFailure(e));
  }
}

export function* watchGetWatchersSaga() {
  yield takeLatest(getWatchersAction.requested, getWatchersSaga);
}
