import axios from "./axios";
import { bannerCardsURL } from "./requests";

export const reorderBannerCardAPI = (
  token: string,
  cardId: string,
  order: number
) => {
  return axios.put(
    `${bannerCardsURL}/${cardId}/reorder`,
    {
      order,
    },
    { headers: { token }, baseURL: process.env.LUMEN_ENDPOINT }
  );
};
