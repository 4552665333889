import { call, put, takeLatest } from "redux-saga/effects";
import { getPosProvidersAction } from "../../constants";
import {
  getPosProvidersFailure,
  getPosProvidersSuccess,
} from "../actions/pos-actions";
import { getPOSProviders as getPOSProvidersApi } from "../../axios/getPOSProviders";

function* getPosProvidersSaga() {
  try {
    const res = yield call(getPOSProvidersApi);
    yield put(getPosProvidersSuccess(res.data.pos_providers));
  } catch (error) {
    yield put(getPosProvidersFailure(error));
  }
}

export function* watchGetPosProvidersSaga() {
  yield takeLatest(getPosProvidersAction.requested, getPosProvidersSaga);
}
