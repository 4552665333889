import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../selectors";
import { IGetBrandWizardInfoAction, IStep } from "../../types";
import {
  getBrandWizardInfoFailure,
  getBrandWizardInfoSuccess,
} from "../actions/wizardInfoActions";
import { getBrandWizardInfoAction } from "../../constants";
import { getBrandWizardToken } from "../../axios/getBrandWizardInfo";
import { getCurrentStep } from "../../axios/getCurrentStep";

const initialStep: IStep = {
  created_at: 0,
  end_time: 0,
  id: "de1as31",
  key: 0,
  start_time: 122112,
  updated_at: 12213123,
};

function* getBrandWizardInfoSaga(action: IGetBrandWizardInfoAction) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getBrandWizardToken, token, action.payload);
    const wizardToken = res.data.wizard_token;
    const currentStepRes = yield call(getCurrentStep, wizardToken);

    if (currentStepRes.status === 200) {
      const currentStep = currentStepRes.data.wizardStep[0] as IStep;
      yield put(getBrandWizardInfoSuccess(currentStep, wizardToken));
    } else {
      yield put(getBrandWizardInfoSuccess(initialStep, wizardToken));
    }
  } catch (e) {
    yield put(getBrandWizardInfoFailure(e));
  }
}

export function* watchGetBrandWizardInfoSaga() {
  yield takeLatest(getBrandWizardInfoAction.requested, getBrandWizardInfoSaga);
}
