import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { reducer, on, Reducer } from "ts-action";
import {
  getSystemPromoCode,
  getSystemPromoCodeFailed,
  getSystemPromoCodeSuccess,
} from "../../../../redux-store/actions/getSystemPromoCodesActions";
import { mapById } from "./lib";
import { ISinglePromoCode, ISinglePromotionStore } from "../../../../constants";
import { expirePromoCode } from "../actions/expire-actions";
import {
  getPromotionStoresSuccess,
  editPromoCode,
  editPromoCodeSuccess,
  clearCurrentPromotionStore,
  createUniversalPromoCodeSuccess,
  createUniversalPromoCode,
  createUniversalPromoCodeFailure,
  clearPrevState,
  createPromoCode,
  createPromoCodeSuccess,
  createPromoCodeFailure,
  editPromoCodeFailure,
  // createPromoCodeSuccess
} from "../actions/promoCodeActions";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
import { PromocodeInterfaces } from "../../types";

interface ISystemPromoCodesReducer {
  promos: string[];
  promosIsLoading: LoadingStatus | undefined;
  promoById: { [id: string]: ISinglePromoCode };
  currentPromotionStore: ISinglePromotionStore[];
  page: number;
  filters: PromocodeInterfaces.IPromocodeFilter;
  loading: LoadingStatus | undefined;
  promosHasMore: boolean;
  total: number;
}

const initialState = {
  promos: [],
  promoById: {},
  promosHasMore: true,
  promosIsLoading: undefined,
  currentPromotionStore: [],
  page: 0,
  loading: undefined,
  filters: {
    status: undefined,
    country_codes: undefined,
    order_by: undefined,
  },
  total: 0,
};

const SystemPromoCodesReducer = optimistic(
  reducer<ISystemPromoCodesReducer>(
    [
      on(createPromoCode, (state, {}) => ({
        ...state,
        loading: LoadingStatus.loading,
      })),
      on(createPromoCodeSuccess, (state, {}) => ({
        ...state,
        loading: LoadingStatus.success,
      })),
      on(createPromoCodeFailure, (state, {}) => ({
        ...state,
        loading: LoadingStatus.failed,
      })),
      on(createUniversalPromoCodeSuccess, (state, {}) => ({
        ...state,
        loading: LoadingStatus.success,
      })),
      on(createUniversalPromoCode, (state, {}) => ({
        ...state,
        loading: LoadingStatus.loading,
      })),
      on(createUniversalPromoCodeFailure, (state, {}) => ({
        ...state,
        loading: LoadingStatus.failed,
      })),
      on(getSystemPromoCodeFailed, (state, {}) => ({
        ...state,
        promosHasMore: false,
        promosIsLoading: LoadingStatus.failed,
        loading: LoadingStatus.failed,
      })),
      on(getSystemPromoCode, (state, { payload }) => {
        if (payload.page === 0) {
          return {
            ...state,
            total: 0,
            promos: [],
            page: 0,
            promosIsLoading: LoadingStatus.loading,
            promosHasMore: true,
            filters: {
              ...payload.filters,
            },
          };
        }
        return {
          ...state,
          page: payload.page,
          promosHasMore: true,
          filters: {
            ...payload.filters,
          },
          promosIsLoading: LoadingStatus.loading,
        };
      }),
      on(getSystemPromoCodeSuccess, (state, { payload }) => ({
        ...state,
        promos: [
          ...state.promos,
          ...Array.from(new Set(payload.data.map((data) => data.id))),
        ],
        promoById: { ...state.promoById, ...mapById(payload.data) },
        promosHasMore: payload.promosHasMore,
        promosIsLoading: LoadingStatus.success,
        total: payload.total,
      })),

      on(expirePromoCode, (state, action) => {
        const id = action.payload.promoId;
        return {
          ...state,
          promoById: {
            ...state.promoById,
            [id]: {
              ...state.promoById[id],
              expired_by_admin_at: new Date().getTime(),
            },
          },
        };
      }),
      on(getPromotionStoresSuccess, (state, action) => ({
        ...state,
        currentPromotionStore: action.payload,
      })),
      on(clearPrevState, (state, {}) => ({
        ...state,
        loading: undefined,
        promosIsLoading: undefined,
      })),
      on(clearCurrentPromotionStore, (state, action) => ({
        ...state,
        currentPromotionStore: [],
      })),
      on(editPromoCodeFailure, (state, action) => ({
        ...state,
        loading: LoadingStatus.failed,
      })),
      on(editPromoCodeSuccess, (state, action) => {
        const id = action.meta.optimistic.id.toString();
        return {
          ...state,
          promos: state.promos.map((catId) =>
            catId === id ? action.payload.id : catId
          ),
          loading: LoadingStatus.success,
          promoById: {
            ...state.promoById,
            [action.payload.id]: {
              ...state.promoById[action.payload.id],
              ...action.payload.data,
              expired_by_admin_at: action.payload.reactivate
                ? undefined
                : state.promoById[action.payload.id].expired_by_admin_at,
              end_time: action.payload.reactivate
                ? (action.payload.end_time as number)
                : state.promoById[action.payload.id].end_time,
            },
          },
        };
      }),
      on(editPromoCode, (state, action) => {
        const oldPromo = state.promoById[action.payload.id];
        return {
          ...state,
          loading: LoadingStatus.loading,
          promos: state.promos,
          promoById: {
            ...state.promoById,
            [action.payload.id]: {
              ...oldPromo,
              ...action.payload.data,
            },
          },
        };
      }),
    ],
    initialState
  )
) as Reducer<OptimisticState<ISystemPromoCodesReducer>>;

export default SystemPromoCodesReducer;
