import * as Requests from "./requests";

import axios from "./axios";
import { PromocodeInterfaces } from "../Components/TargetedTools/types";
import qs from "qs";
export const getSystemPromoCodesAPI = (
  token: string,
  page: number,
  per_page: number,
  filters?: PromocodeInterfaces.IPromocodeFilter
) => {
  return axios.get(Requests.getSystemPromoCodesURL, {
    params: {
      page,
      per_page,
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {
        arrayFormat: "indices",
        encode: false,
      });
    },
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
