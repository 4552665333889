import React, { useCallback, useMemo } from "react";
import * as styles from "./style.module.sass";
import { hot } from "react-hot-loader";
import { useDeliveryMethod } from "./hooks";
import * as switchStyles from "../../styles.module.sass";
import { useSelect } from "../../../../../hooks/useSelect";
import { DeliveryManagementStatus } from "../../../../../axios/editFleetStatus";
import SettingsSwitch from "../../../../ShopXSettings/SettingsSwitch";
interface IProps {
  token: string;
  delivery_status?: string;
  store_id: string;
}

enum IsoCountryCode {
  uae = "ae",
  sa = "sa",
}

const FleetStatusToggle: React.FC<IProps> = ({ store_id, token, delivery_status }) => {
  const { BRAND_DETAILS } = useSelect((state) => state.brandDetailsReducer);
  const {
    deliveryManagementStatus,
    deliveryManagementStatusSwitch,
    loading,
  } = useDeliveryMethod(token, store_id);
  const handleDeliveryManagementStatusChange = useCallback(
    (checked: boolean) => {
      deliveryManagementStatusSwitch(
        checked
          ? DeliveryManagementStatus.enabled
          : DeliveryManagementStatus.disabled
      );
    },
    []
  );

  const isDeliveryManagementEnabled = useMemo(
    () =>
      deliveryManagementStatus === DeliveryManagementStatus.enabled
        ? true
        : false,
    [deliveryManagementStatus]
  );
  const isoCountryCode = BRAND_DETAILS?.country_iso_code;
  return (
    <>
      {(isoCountryCode === IsoCountryCode.uae ||
        isoCountryCode === IsoCountryCode.sa) && (
        <div className={delivery_status === "enabled" ? styles.fleetAnimated : styles.fleetWrapper}>
          <div className={styles.fleetInfo}>
            <span className={styles.fleetTitle}>Deliver with Koinz</span>
          </div>
          <SettingsSwitch
            data-test-id="deliveryManagementControl"
            id={
              isDeliveryManagementEnabled ? "fleet_enabled" : "fleet_disabled"
            }
            className={switchStyles.sw}
            checked={isDeliveryManagementEnabled}
            onChange={handleDeliveryManagementStatusChange}
            onColor={"#f09440"}
            disabled={loading}
            handleDiameter={28}
            offColor={"#e5e5ea"}
            onHandleColor="#ffffff"
            offHandleColor="#8e8e93"
            height={32}
            width={56}
            />
          </div>
        )}
    </>
  );
};

export default hot(module)(FleetStatusToggle);
