import moment from "moment-timezone";

import {
  PromoMerchantVisibility,
  PromoTypes,
  ToggleStatus,
} from "../../../TargetedTools/types";
import { ISelectItemData } from "../../components/CustomDropDownSelect/index";

const isTrustyValue = (condition: boolean, value) => {
  if (condition) {
    return value;
  } else {
    return undefined;
  }
};

const formatDate = (dateInput) => {
  if (!dateInput) {
    return null;
  }
  return moment(dateInput).format("ddd MMM DD YYYY HH:mm:ss");
};
export const processEditingPromoCodesValues = (
  values,
  country_iso_code: string,
  reactive?: number
) => {
  const formData = new FormData();

  formData.append("type", values.type);
  if (reactive) {
    formData.append("reactivate", reactive as any);
  }
  values.stores_ids?.forEach((store) => {
    formData.append("stores_ids[]", store);
  });
  values.stores_ids.forEach((store) => {
    if (typeof values.stores_fund_percentage[store]?.brand !== "undefined") {
      formData.append(
        "stores_fund_percentage[]",
        values.stores_fund_percentage[store].brand
      );
    }
    if (
      values.type === PromoTypes.cashback_percentage &&
      values.promo_merchant_visibility !== PromoMerchantVisibility.masked
    ) {
      if (
        values?.max_cashback_values &&
        typeof values?.max_cashback_values[store] !== "undefined"
      ) {
        formData.append(
          "max_cashback_values[]",
          values.max_cashback_values[store]
        );
      }
      if (
        values?.expires_after_values &&
        typeof values.expires_after_values[store] !== "undefined"
      ) {
        formData.append(
          "expires_after_values[]",
          values.expires_after_values[store]
        );
      }
    }
    if (values.promo_merchant_visibility !== PromoMerchantVisibility.masked) {
      if (
        values?.max_discount_values &&
        values.type === PromoTypes.percentage &&
        typeof values?.max_discount_values[store] !== "undefined"
      ) {
        formData.append(
          "max_discount_values[]",
          values.max_discount_values[store]
        );
      }
      if (
        values?.min_threshold_values &&
        (values.type === PromoTypes.percentage ||
          values.type === PromoTypes.cashback_percentage) &&
        typeof values?.min_threshold_values[store] !== "undefined"
      ) {
        formData.append(
          "min_threshold_values[]",
          values.min_threshold_values[store]
        );
      }
    }
  });
  if (values.promo_merchant_visibility === PromoMerchantVisibility.masked) {
    values.stores_ids.forEach((id) => {
      formData.append(
        "masked_values[]",
        (values?.masked_values[id] / 100) as any
      );
    });
    values.stores_ids.forEach((id) => {
      if (values?.masked_values && values?.masked_promo_codes[id]) {
        formData.append("masked_promo_codes[]", values?.masked_promo_codes[id]);
      }
    });
  }
  if (values.rank) {
    formData.append("rank", values.rank);
  }
  const isMultiplier = Number(
    values.type === PromoTypes.multiplier ||
      (values.type === PromoTypes.percentage &&
        values.promo_merchant_visibility !== PromoMerchantVisibility.masked)
  );
  formData.append("title", values.title);
  formData.append("desc", values.desc);
  formData.append("ar_desc", values.ar_desc);
  formData.append("ar_title", values.ar_title);
  if (values.type !== PromoTypes.cashback_percentage) {
    formData.append("min_threshold", !isMultiplier ? values.min_threshold : 0);
  }
  if (
    typeof values.cashback_value === "undefined" &&
    typeof values.cashback_value !== "undefined"
  ) {
    formData.append("max_cashback_value", values.max_cashback_value);
  }

  formData.append(
    "promo_merchant_visibility",
    values.promo_merchant_visibility
  );
  formData.append("allowed_payment_method", values.allowed_payment_method);

  formData.append("end_time", formatDate(values.end_time) || "");
  formData.append("max_usage_times", values.max_usage_times);
  if (!values?.target_customers) {
    formData.delete("target_customers");
  } else {
    formData.append("target_customers", values?.target_customers);
  }
  formData.append("country_iso_code", country_iso_code);
  return formData;
};

export const processPromoCodesValues = (
  values,
  country_iso_code: string,
  is_compensational: boolean
) => {
  const formData = new FormData();
  formData.append("type", values.type);
  values.stores_ids?.forEach((store) => {
    formData.append("stores_ids[]", store);
  });
  const isMultiplier = Number(
    values.type === PromoTypes.multiplier ||
      (values.type === PromoTypes.percentage &&
        values.promo_merchant_visibility !== PromoMerchantVisibility.masked)
  );
  if (is_compensational) {
    formData.append("is_compensational", `${is_compensational}`);
    formData.append("expiry_days", values.expiry_days);
  }

  formData.append("title", values.title);
  formData.append("ordering_type", values.ordering_type);
  formData.append("is_exclusive", values.is_exclusive);
  values.stores_ids.forEach((store) => {
    if (typeof values.stores_fund_percentage[store]?.brand !== "undefined") {
      formData.append(
        "stores_fund_percentage[]",
        values.stores_fund_percentage[store].brand
      );
    }
    if (
      values.type === PromoTypes.cashback_percentage &&
      values.promo_merchant_visibility !== PromoMerchantVisibility.masked
    ) {
      if (
        values?.max_cashback_values &&
        typeof values?.max_cashback_values[store] !== "undefined"
      ) {
        formData.append(
          "max_cashback_values[]",
          values.max_cashback_values[store]
        );
      }
      if (
        values?.expires_after_values &&
        typeof values.expires_after_values[store] !== "undefined"
      ) {
        formData.append(
          "expires_after_values[]",
          values.expires_after_values[store]
        );
      }
    }
    if (values.promo_merchant_visibility !== PromoMerchantVisibility.masked) {
      if (
        values?.max_discount_values &&
        values.type === PromoTypes.percentage &&
        typeof values?.max_discount_values[store] !== "undefined"
      ) {
        formData.append(
          "max_discount_values[]",
          values?.max_discount_values[store]
        );
      }
      if (
        values?.min_threshold_values &&
        (values.type === PromoTypes.percentage ||
          values.type === PromoTypes.cashback_percentage) &&
        typeof values?.min_threshold_values[store] !== "undefined"
      ) {
        formData.append(
          "min_threshold_values[]",
          values?.min_threshold_values[store]
        );
      }
    }
  });
  if (values.promo_merchant_visibility === PromoMerchantVisibility.masked) {
    values.stores_ids.forEach((id) => {
      formData.append(
        "masked_values[]",
        (values?.masked_values[id] / 100) as any
      );
    });
    values.stores_ids.forEach((id) => {
      if (values?.masked_promo_codes && values?.masked_promo_codes[id]) {
        formData.append("masked_promo_codes[]", values?.masked_promo_codes[id]);
      }
    });
  }
  if (values.cashback_value && values.type === PromoTypes.cashback_percentage) {
    formData.append("cashback_value", (values.cashback_value / 100) as any);
    formData.append("max_cashback_value", values.max_cashback_value);
  }
  formData.append("desc", values.desc);
  formData.append("ar_desc", values.ar_desc);
  formData.append("ar_title", values.ar_title);
  formData.append("min_threshold", !isMultiplier ? values.min_threshold : 0);
  if (values.multiplier_value && values.type === PromoTypes.multiplier) {
    formData.append("multiplier_value", values.multiplier_value);
  }
  if (
    typeof values.discount_value !== "undefined" &&
    (values.type === PromoTypes.percentage ||
      values.type === PromoTypes.discount)
  ) {
    formData.append(
      "discount_value",
      (values.type === PromoTypes.percentage
        ? values.discount_value / 100
        : values.discount_value) as any
    );
    if (typeof values.max_discount_value !== "undefined") {
      formData.append(
        "max_discount_value",
        values.type === PromoTypes.discount
          ? values.discount_value
          : values.max_discount_value
      );
    }
  }
  formData.append("start_time", formatDate(values.start_time) || "");
  formData.append("end_time", formatDate(values.end_time) || "");
  formData.append("rank", values.rank);
  if (values.max_usage_times) {
    formData.append("max_usage_times", values.max_usage_times);
  }
  formData.append("target_customers", values.target_customers);
  formData.append(
    "suggested",
    `${Number(values.suggested === ToggleStatus.enable)}`
  );
  formData.append(
    "promo_merchant_visibility",
    values.promo_merchant_visibility
  );
  formData.append("allowed_payment_method", values.allowed_payment_method);
  formData.append("promo_code", values.promo_code);
  formData.append("country_iso_code", country_iso_code);
  return formData;
};

export const processUniversalPromoCodesValues = (values, country_iso_code) => {
  const isMultiplier = Number(
    values.type === PromoTypes.multiplier ||
      (values.type === PromoTypes.percentage &&
        values.promo_merchant_visibility !== PromoMerchantVisibility.masked)
  );
  return {
    country_iso_code,
    is_exclusive: values.is_exclusive,
    max_discount_values:
      values?.max_discount_values &&
      values.type === PromoTypes.percentage &&
      values.promo_merchant_visibility !== PromoMerchantVisibility.masked
        ? values?.stores_ids?.map((store_id) =>
            Number(values?.max_discount_values[store_id])
          )
        : undefined,
    expires_after_values:
      values?.expires_after_values &&
      values.type === PromoTypes.cashback_percentage &&
      values.promo_merchant_visibility !== PromoMerchantVisibility.masked
        ? values?.stores_ids?.map((store_id) =>
            Number(values?.expires_after_values[store_id])
          )
        : undefined,
    max_cashback_values:
      values?.max_cashback_values &&
      values.type === PromoTypes.cashback_percentage &&
      values.promo_merchant_visibility !== PromoMerchantVisibility.masked
        ? values?.stores_ids?.map((store_id) =>
            Number(values?.max_cashback_values[store_id])
          )
        : undefined,
    min_threshold_values:
      values?.min_threshold_values &&
      (values.type === PromoTypes.percentage ||
        values.type === PromoTypes.cashback_percentage) &&
      values.promo_merchant_visibility !== PromoMerchantVisibility.masked
        ? values?.stores_ids?.map((store_id) =>
            Number(values?.min_threshold_values[store_id])
          )
        : undefined,
    max_cashback_value: isTrustyValue(
      values.type === PromoTypes.cashback_percentage,
      values.max_cashback_value
    ),
    cashback_value: isTrustyValue(
      values.type === PromoTypes.cashback_percentage,
      values.cashback_value / 100
    ),
    stores_ids: [...values.stores_ids],
    title: values.title,
    desc: values.desc,
    ar_desc: values.ar_desc,
    ar_title: values.ar_title,
    type: values.type,
    min_threshold: !isMultiplier ? values.min_threshold : 0,
    discount_value: isTrustyValue(
      values.type === PromoTypes.percentage ||
        values.type === PromoTypes.discount,
      values.type === PromoTypes.percentage
        ? values.discount_value / 100
        : values.discount_value
    ),
    max_discount_value: isTrustyValue(
      values.type === PromoTypes.percentage ||
        values.type === PromoTypes.discount,
      values.type === PromoTypes.discount
        ? values.discount_value
        : values.max_discount_value && values.max_discount_value
    ),
    ordering_type: values.ordering_type,
    start_time: formatDate(values.start_time),
    end_time: formatDate(values.end_time),
    max_usage_times: values.max_usage_times,
    promo_code: values?.promo_code,
    targeting_method: values.targeting_method,
    rank: Number(values.rank),
    suggested: Number(values.suggested === ToggleStatus.enable),
    promo_merchant_visibility: values.promo_merchant_visibility,
    allowed_payment_method: values.allowed_payment_method,
    // "stores_ids[]": values.storeId,
    masked_promo_codes:
      values.promo_merchant_visibility === PromoMerchantVisibility.masked &&
      values?.masked_promo_codes
        ? values?.stores_ids?.map(
            (store_id) => values?.masked_promo_codes[store_id]
          )
        : undefined,
    masked_values:
      values.promo_merchant_visibility === PromoMerchantVisibility.masked &&
      values?.masked_values
        ? values?.stores_ids?.map((store) => values?.masked_values[store] / 100)
        : undefined,
    stores_fund_percentage: values?.stores_fund_percentage
      ? values?.stores_ids?.map(
          (store) => values?.stores_fund_percentage[store]?.brand
        )
      : undefined,
    multiplier_value: values.multiplier_value,
  };
};

export const pointsSelectItems: ISelectItemData[] = [
  { label: "2X", value: 2 },
  { label: "3X", value: 3 },
  { label: "4X", value: 4 },
  { label: "5X", value: 5 },
];

export const ordering_type: ISelectItemData[] = [
  { label: "Ordering", value: "ordering" },
  { label: "Pickup", value: "pickup" },
  { label: "Delivery", value: "delivery" },
];

export const CATEGORIES = {
  cashback_percentage: "Cashback",
  discount_percentage: "Percentage",
};

export const PROMO_TYPE_LIST = [
  {
    value: PromoTypes.percentage,
    label: "Percentage",
  },
  {
    value: PromoTypes.discount,
    label: "Discount value",
  },
  {
    value: PromoTypes.multiplier,
    label: "Points multiplier",
  },
  {
    value: PromoTypes.cashback_percentage,
    label: "Cashback",
  },
];

export const setValuesById = (ids: string[], values: any) => {
  return ids.reduce((acc, next) => {
    const map = { ...acc };
    map[next] = values;
    return map;
  }, {});
};

export const allStoresPercentageEqWithMasked = (stores) => {
  return (stores || []).length > 1
    ? stores?.every(
        (value) =>
          typeof value.store_fund_percentage !== "undefined" &&
          typeof value.masked_value !== "undefined" &&
          typeof value.masked_promo_code !== "undefined" &&
          value.store_fund_percentage === stores[0]?.store_fund_percentage &&
          value.masked_value === stores[0]?.masked_value &&
          value.masked_promo_code === stores[0].masked_promo_code
      )
    : false;
};

export const allStoresCappingEg = (stores) => {
  return (stores || []).length > 1
    ? stores?.every(
        (store) =>
          typeof store?.max_discount_value !== "undefined" &&
          store?.max_discount_value === stores[0].max_discount_value
      )
    : false;
};

export const allEqStoresMinEligibleInvoice = (stores) => {
  return (stores || []).length > 1
    ? stores?.every(
        (store) =>
          typeof store?.min_threshold !== "undefined" &&
          Number(store?.min_threshold) === Number(stores[0].min_threshold)
      )
    : false;
};
export const allValuesEq = (elements) => {
  return typeof elements === "object"
    ? Object.values(elements).every((element) => element === elements[0])
    : elements.every((element) => element === elements[0]);
};
export const allEqStoresExpiredAfter = (stores) => {
  return (stores || []).length > 1
    ? stores?.every(
        (store) =>
          typeof store?.expires_after !== "undefined" &&
          store?.expires_after === stores[0].expires_after
      )
    : false;
};

export const allStoresPercentageEqWithWithoutMasked = (stores) => {
  return (stores || [])?.length > 1
    ? stores?.every(
        (value) =>
          typeof value.store_fund_percentage !== "undefined" &&
          value?.store_fund_percentage === stores[0]?.store_fund_percentage
      )
    : false;
};
export const allEqStoresMaxCashbackValues = (stores) => {
  return (stores || []).length > 1
    ? stores?.every(
        (store) =>
          typeof store?.max_cashback_value !== "undefined" &&
          store?.max_cashback_value === stores[0].max_cashback_value
      )
    : false;
};
export const currentStoresFromCsv = () => {
  return;
};
