export const useHasTruthyValue = <T>(object: T): boolean => {
  let hasTruthyValue = false;
  if (typeof object === "number") {
    hasTruthyValue = true;
  }
  if (object) {
    Object.values(object).forEach((element) => {
      if (
        typeof element === "boolean" ||
        typeof element === "number" ||
        (Array.isArray(element) && element.length > 0) ||
        (typeof element !== "object" && element)
      ) {
        hasTruthyValue = true;
      } else if (
        typeof element === "object" &&
        element !== null &&
        useHasTruthyValue(element)
      ) {
        hasTruthyValue = true;
      }
    });
  }
  return hasTruthyValue;
};
