import axios, { AxiosResponse } from "axios";
import * as Requests from "./requests";
// import {logout} from "../redux-store/actions/logout-actions";
// import {store} from "../redux-store/configureStore";
// import {switchCashier} from "./requests";
const handleSuccess = (
  res: AxiosResponse
): AxiosResponse | Promise<AxiosResponse> => res;
const handleError = (error: any) => {
  const isLogin = error?.response?.config?.url?.includes(Requests.login);
  if (error?.response?.status === 401 && !isLogin) {
    localStorage.clear();
    location.reload();
  }
  throw error;
};

const instance = axios.create({
  baseURL: process.env.ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(handleSuccess, handleError);

export default instance;
