import axios from "axios";

export const getActiveLocationsStatsApi = async (token) => {
  const { data } = await axios.get<
    Array<{ country: string; active_branches: number }>
  >(
    "/api/branches-statistics/active-branches?countries[0]=eg&countries[1]=sa&countries[2]=ae&countries[3]=om",
    {
      headers: {
        token,
      },
      baseURL: process.env.LUMEN_ENDPOINT,
    }
  );
  return data;
};
