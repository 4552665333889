import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import React, { useState, useEffect } from "react";
import { GenericButton } from "../../SharedComponent/GenericButtonWithIcon";
import AddNewTags from "./AddNewTags";
import BrandTagsWrapper from "./BrandTagsWrapper";
import { useDispatch } from "react-redux";
import { getStoreTag } from "../redux-store/actions/tags-actions";
import { useSelect } from "../../../hooks/useSelect";

const Tags: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStoreTag());
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = () => setOpenModal(false);
  const { tags } = useSelect((state) => state.tagsReducer);
  return (
    <div className={styles.wrapper}>
      <div className={styles.totalTagsWrapper}>
        <span className={styles.totalTags}>Total Tags ({tags.length})</span>
        <GenericButton
          className={styles.btn}
          label={"Add new tag"}
          onClick={openModalHandler}
          iconPath={require("../../../assets/add.svg")}
          IconClass={styles.icon}
        />
        {openModal && (
          <AddNewTags open={openModal} onClose={closeModalHandler} />
        )}
      </div>
      <div style={{ marginTop: "2.7rem" }} />
      <BrandTagsWrapper />
    </div>
  );
};

export default hot(module)(Tags);
