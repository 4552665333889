import * as React from "react";
import * as styles from "./style.module.sass";
// import emojiUnicode from "emoji-unicode";
// import punycode from 'punycode';

import {
  Field,
  reduxForm,
  InjectedFormProps,
  formValueSelector,
} from "redux-form";
import {
  fullTimeValidator,
  required,
  maxLength,
} from "../../../../redux-store/validators";
import { RenderTextAreaGenericField } from "../../../FormElements/RenderTextAreaGenericField";

import { connect, useDispatch } from "react-redux";
import { RenderGenericField } from "../../../SharedComponent/RenderGenericField/RenderGenericField";
import RenderFileField from "../../../SharedComponent/RenderFileField/RenderFileField";
import { hot } from "react-hot-loader";
import { RenderFullTimePicker } from "../../../FormElements/RenderFullTimePicker";
import { Drawer } from "@material-ui/core";
import RenderCoverPhotoPickerClass from "../../../FormElements/RenderPhotoPicker/index";
import { RenderDropDownSelect } from "../../../FormElements/RenderDropDownSelect";
import { getStoreMetaData } from "../../BannerCards/AddBannerCard";
import { SelectStoresByCountry } from "../../AddPromoCode/PromoCodeForm/SelectStoresByCountry";
import {
  TARGET_SCREENS,
  isScreenRequiresStoreId,
  StoreScreens,
} from "../../BannerCards/lib/mobile-app-screens";

export const defaultCSVExample = require("../../../../../public/exmples/text.csv")
  .default;

import imageCompression from "browser-image-compression";
import { sendPushNotification } from "../../../../redux-store/actions/sendPushNotificationAction";

// export const getStoreMetaData = values => {
//   let meta_data;
//   const selectedStoreId = values.stores_ids?.[0];
//   if (selectedStoreId) {
//     meta_data = {
//       store_id: selectedStoreId
//     };
//   }
//   return meta_data;
// };

export const compressImages = (imageFile: Blob | File): Promise<any> => {
  return new Promise((resolve) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1220,
      useWebWorker: true,
    };
    imageCompression(imageFile, options).then((cp) => {
      resolve(cp);
    });
  });
};
interface IPushNotificationProps {
  file_data: FileList;
  screen_id: StoreScreens;
  open: boolean;
  handleClose: () => void;
}

export const maxLength1 = maxLength(1);
// const addPushNotificationStateSelector = formValueSelector("PushNotificationForm");

const AddPushNotification: React.FC<
  IPushNotificationProps & InjectedFormProps
> = (props) => {
  const {
    handleSubmit,
    screen_id,
    file_data,
    reset,
    open,
    handleClose,
    asyncValidate,
    form,
  } = props;
  const dispatch = useDispatch();

  const sendPushNotificationFormData = async (values) => {
    const meta_data = getStoreMetaData(values);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("message", values.message);
    formData.append("data", values.data[0]);

    formData.append("scheduled_at", values.scheduling_datetime.timestamp);

    if (meta_data !== undefined) {
      formData.append("store_id", meta_data.store_id);
    }
    if (values.image !== undefined) {
      const img = (v) => compressImages(v[0]);
      const image_file = await img(values.image);
      // tslint:disable-next-line: no-console
      console.log("Image ===========>", image_file);
      formData.append("image", image_file);
    }
    if (values.screen_id !== undefined) {
      formData.append("screen_id", values.screen_id);
    }
    // tslint:disable-next-line: no-console
    console.log(
      "Push notification data , ======>",
      values,
      JSON.stringify(values)
    );
    dispatch(sendPushNotification(formData));
    reset();

    handleClose();
  };
  const startAsyncValidation = () => {
    setTimeout(asyncValidate);
  };

  // tslint:disable-next-line: no-console
  // const { screen_id } = useSelect(state =>
  //   addPushNotificationStateSelector(state, "screen_id")
  // );

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <span className={styles.header}>Send Push notification</span>
          <img
            onClick={handleClose}
            className={styles.icon}
            src={require("../../../../assets/icon-close.svg")}
          />
        </div>
        <div className={styles.formWrapper}>
          <form onSubmit={handleSubmit(sendPushNotificationFormData)}>
            <Field
              name="image"
              type="file"
              label="Notification image"
              customLabel={1}
              focusBtn={true}
              buttonText={"Upload Image"}
              isCroppable={true}
              component={RenderCoverPhotoPickerClass as any}
              className={styles.uploadBtn}
              onPick={startAsyncValidation}
            />
            <Field
              name="title"
              component={RenderGenericField as any}
              label="Notification title"
              placeholder={"Write your notification title here..."}
              type="text"
              className={styles.select}
              validate={[required]}
            />
            <Field
              name="message"
              component={RenderTextAreaGenericField as any}
              label="Notification text (max. 178 char)"
              placeholder={"Write your SMS message here..."}
              className={styles.select}
              validate={[required]}
            />
            <Field
              name={"screen_id"}
              label={"Target screen"}
              options={TARGET_SCREENS}
              className={true}
              component={RenderDropDownSelect as any}
            />

            {isScreenRequiresStoreId(screen_id) && (
              <SelectStoresByCountry
                validateCountry={[required]}
                validateStores={[required, maxLength1]}
                onlyOne={true}
                formName={form}
              />
            )}
            <div className={styles.fileWrapper}>
              <Field
                name="data"
                type="file"
                component={RenderFileField as any}
                label="Upload CSV file"
                validate={[required]}
                className="form-control"
              />
              <a
                className={styles.fileLab}
                href={defaultCSVExample}
                download={true}
              >
                Download CSV template
              </a>
            </div>
            {Array.from(file_data || []).map((file) => (
              <span className={styles.fileName} key={file.name}>
                {file.name}
              </span>
            ))}
            <div style={{ margin: "2.4rem 0" }} />

            <Field
              className={styles.datePicker}
              name="scheduling_datetime"
              validate={[fullTimeValidator]}
              component={RenderFullTimePicker}
              label={"Schedule at"}
            />

            <button type="submit" className={styles.submitBtn}>
              Send Push Notification
            </button>
          </form>
        </div>
      </div>
    </Drawer>
  );
};

let PushNotificationForm = reduxForm<{}, IPushNotificationProps>({
  form: "PushNotificationForm",
})(AddPushNotification as any) as any;

const selector = formValueSelector("PushNotificationForm");
PushNotificationForm = connect((state) => ({
  file_data: selector(state, "data"),
  screen_id: selector(state, "screen_id")
}))(PushNotificationForm) as any;

export default hot(module)(PushNotificationForm as any);
