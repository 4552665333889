import React from "react";
import { prefixImage } from "../../../Profile";

export const useProcessImageResource = (
  imageResource: File | string,
  baseUrl = ""
) => {
  // I could've used a useMemo like such
  // return React.useMemo(() => {
  //   if (imageResource instanceof File) {
  //     return URL.createObjectURL(imageResource);
  //   } else {
  //     return imageResource;
  //   }
  // }, [imageResource]);
  // but this would leave room for memory leaks as the memo wouldn't track the change
  // from file to string therefore this won't work
  // there for a ref is more appropriate in this context
  // as a ref would have a single reference accross the application
  // and would be tracked if the `imageResource` changed to a string
  // and the `URL.createObjectURL` memory leak can be plugged through the same effect

  const [imageSource, setImageSource] = React.useState<string>("");

  React.useEffect(() => {
    if (imageResource instanceof File) {
      setImageSource(URL.createObjectURL(imageResource));
    } else {
      setImageSource(imageResource && prefixImage(baseUrl + imageResource));
    }

    return () => {
      if (imageResource instanceof File) {
        URL.revokeObjectURL(imageSource);
      }
    };
  }, [imageResource]);
  return imageSource;
};
