import { on, reducer } from "ts-action";
import { LoadingStatus, withLoadingReducer } from "./withLoadingState";
import { getBrandPosEntitiesAction } from "../../constants";
import { IPosEntity } from "../../pages/PosIntegration/types";
import * as actions from "../actions";
import {
  updateBrandPosIntegrationStatus,
  updateExportBrandPosMenu,
} from "../../helpers/pos";

export interface IBrandPosEntity extends IPosEntity {
  loading_status?: LoadingStatus;
  JSONFileContent?: any;
  JSONFileLoadingStatus?: LoadingStatus;
}
export interface IBrandPosEntitiesState {
  brandPosEntitiesIds: string[];
  brandPosEntitiesById: {
    [key: string]: IBrandPosEntity;
  };
  status: LoadingStatus;
}

export const getBrandPosEntitiesReducer = withLoadingReducer<
  IBrandPosEntitiesState
>(
  reducer<IBrandPosEntitiesState>(
    [
      on(actions.getBrandPosEntitiesSuccess, (state, { payload }) => ({
        ...state,
        brandPosEntitiesIds: payload
          .sort((a, b) => a.created_at - b.created_at)
          .map((entity) => entity.id),
        brandPosEntitiesById: payload.reduce((acc, entity) => {
          return {
            ...acc,
            [entity.id]: entity,
          };
        }, {}),
      })),
      on(actions.getBrandPosEntitiesFailure, (state, _) => ({
        ...state,
        brandPosEntitiesId: [],
        brandPosEntitiesById: {},
      })),
      on(actions.getBrandPosEntities, (state, _) => ({
        ...state,
        brandPosEntitiesIds: [],
        brandPosEntitiesById: {},
      })),
      on(actions.createBrandPosEntitySuccess, (state, { payload }) => ({
        ...state,
        brandPosEntitiesIds: [
          ...(state.brandPosEntitiesIds || []),
          payload.id,
        ].filter((entityId) => entityId),
        brandPosEntitiesById: {
          ...state.brandPosEntitiesById,
          ...(payload.id !== "" ? { [payload.id]: payload } : {}),
        },
        status: LoadingStatus.success,
      })),
      on(actions.createBrandPosEntity, (state, _) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(actions.createBrandPosEntityFailure, (state, _) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
      on(actions.updateBrandPosEntity, (state, _) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(actions.updateBrandPosEntityFailure, (state, _) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
      on(actions.updateBrandPosEntitySuccess, (state, { payload }) => ({
        ...state,
        status: LoadingStatus.success,
        brandPosEntitiesById: {
          ...state.brandPosEntitiesById,
          [payload.id]: payload,
        },
      })),
      on(actions.deleteBrandPosEntity, (state, _) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(actions.deleteBrandPosEntityFailure, (state, _) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
      on(actions.deleteBrandPosEntitySuccess, (state, { payload }) => ({
        ...state,
        brandPosEntitiesIds: state.brandPosEntitiesIds.filter(
          (element) => element !== payload.id
        ),
        brandPosEntitiesById: Object.keys(state.brandPosEntitiesById).reduce(
          (acc, key) => {
            if (key !== payload.id) {
              acc[key] = state.brandPosEntitiesById[key];
            }
            return acc;
          },
          {}
        ),
        status: LoadingStatus.success,
      })),
      on(actions.updatePosEntitiesLoadingStatus, (state, { payload }) => ({
        ...state,
        status: payload ? payload : LoadingStatus.idle,
      })),
      on(actions.setBrandPosEntity, (state, { payload }) => {
        switch (payload?.mode) {
          case "create":
            return {
              ...state,
              brandPosEntitiesIds: [
                ...(state.brandPosEntitiesIds || []),
                payload.id,
              ],
              brandPosEntitiesById: {
                ...state.brandPosEntitiesById,
                [payload.id]: payload,
              },
            };
          case "edit":
            return {
              ...state,
              brandPosEntitiesById: {
                ...state.brandPosEntitiesById,
                [payload.id]: payload,
              },
            };
          case "delete":
            return {
              ...state,
              brandPosEntitiesById: {
                ...state.brandPosEntitiesById,
                [payload.id]: {
                  ...state.brandPosEntitiesById[payload.id],
                  mode: "delete",
                },
              },
            };
          default:
            return {
              ...state,
              brandPosEntitiesById: state.brandPosEntitiesIds.reduce(
                (acc, id) => {
                  if (state.brandPosEntitiesById[id]) {
                    acc[id] = state.brandPosEntitiesById[id];
                  }
                  return acc;
                },
                {}
              ),
              brandPosEntitiesIds: state.brandPosEntitiesIds.filter(
                (id) => id !== ""
              ),
            };
        }
      }),
      on(
        actions.getBrandPosLoyaltyIntegrationStatusSuccess,
        updateBrandPosIntegrationStatus
      ),
      on(
        actions.getBrandPosOrderingIntegrationStatusSuccess,
        updateBrandPosIntegrationStatus
      ),
      on(actions.exportBrandPosMenu, (state, { payload }) => ({
        ...state,
        brandPosEntitiesById: updateExportBrandPosMenu(
          state,
          payload.pos_entity_id,
          {},
          LoadingStatus.loading
        ),
      })),
      on(actions.exportBrandPosMenuSuccess, (state, { payload }) => ({
        ...state,
        brandPosEntitiesById: updateExportBrandPosMenu(
          state,
          payload.pos_entity_id,
          payload.JSONFileContent,
          LoadingStatus.success
        ),
      })),
      on(actions.exportBrandPosMenuFailure, (state, { payload }) => ({
        ...state,
        brandPosEntitiesById: updateExportBrandPosMenu(
          state,
          payload.pos_entity_id,
          {},
          LoadingStatus.failed
        ),
      })),
      on(actions.exportBrandPosMenuReset, (state, { payload }) => ({
        ...state,
        brandPosEntitiesById: updateExportBrandPosMenu(
          state,
          payload,
          {},
          LoadingStatus.idle
        ),
      })),
      on(actions.genBrandPosPaymentMethod, (state, { payload }) => ({
        ...state,
        brandPosEntitiesById: {
          ...state.brandPosEntitiesById,
          [payload.pos_entity_id]: {
            ...state.brandPosEntitiesById[payload.pos_entity_id],
            loading_status: LoadingStatus.loading,
          },
        },
      })),
      on(actions.genBrandPosPaymentMethodSuccess, (state, { payload }) => ({
        ...state,
        brandPosEntitiesById: {
          ...state.brandPosEntitiesById,
          [payload.pos_entity_id]: {
            ...state.brandPosEntitiesById[payload.pos_entity_id],
            loading_status: LoadingStatus.success,
          },
        },
      })),
      on(actions.genBrandPosPaymentMethodFailure, (state, { payload }) => ({
        ...state,
        brandPosEntitiesById: {
          ...state.brandPosEntitiesById,
          [payload.pos_entity_id]: {
            ...state.brandPosEntitiesById[payload.pos_entity_id],
            loading_status: LoadingStatus.failed,
          },
        },
      })),
    ],
    {
      status: LoadingStatus.idle,
      brandPosEntitiesById: {},
      brandPosEntitiesIds: [],
    }
  ),
  getBrandPosEntitiesAction
);
export default getBrandPosEntitiesReducer;
