import { isEqual } from "lodash";
import { atom, selector } from "recoil";
import { ContactMethodType, ISelectedContactMethodsState } from "../types";

export const selectedContactMethodsAtom = atom<ISelectedContactMethodsState>({
  key: "selectedContactMethodsAtom",
  default: {
    selected_contact_methods: undefined,
    contact_methods_view: undefined,
    resolve_all: {
      contact_methods_view: {
        [ContactMethodType.Whatsapp]: {
          message: "",
          selected: true,
        },
        [ContactMethodType.SMS]: {
          message: "",
          selected: false,
        },
        [ContactMethodType.Email]: {
          message: "",
          selected: false,
        },
      },
      selected_contact_methods: {
        [ContactMethodType.Whatsapp]: true,
        [ContactMethodType.SMS]: false,
        [ContactMethodType.Email]: false,
      },
    },
  },
});

export const currentContactMethodAtom = atom<ContactMethodType>({
  key: "currentContactMethodAtom",
  default: ContactMethodType.Whatsapp,
});

export const selectedContactMethodsState = selector({
  key: "selectedContactMethodsState",
  get({ get }) {
    const state = get(selectedContactMethodsAtom);
    const ids = Object.keys(state.contact_methods_view ?? {});
    const areContactMethodsEqual = (brandId: string) => {
      const brandContactMethods = state.contact_methods_view?.[brandId] ?? {};
      return Object.keys(brandContactMethods).every((element) =>
        isEqual(
          brandContactMethods[Number(element)].message,
          state.resolve_all.contact_methods_view[element].message
        )
      );
    };
    const areAllSelectedContactMethodsEqual = ids.every(
      (brandId) =>
        isEqual(
          state.selected_contact_methods?.[brandId],
          state.resolve_all.selected_contact_methods
        ) && areContactMethodsEqual(brandId)
    );
    const getCurrentMethodData = (
      contact_method: ContactMethodType,
      selected_store_id: string
    ) => {
      if (state.contact_methods_view && !selected_store_id) {
        return state.resolve_all?.contact_methods_view[contact_method];
      } else if (state.contact_methods_view && selected_store_id) {
        return state.contact_methods_view?.[selected_store_id][contact_method];
      }
      return null;
    };
    return {
      ...state,
      areAllSelectedContactMethodsEqual,
      getCurrentMethodData,
    };
  },
});
