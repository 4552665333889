import React from "react";
import * as styles from "./styles.module.sass";
interface IProps {
  label: string;
}
export const TableBodyPlaceholder: React.FC<IProps> = ({ label }) => {
  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src={require("../../../../assets/group-2.svg")}
      />
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default TableBodyPlaceholder;
