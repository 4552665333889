import * as React from "react";
import * as styles from "../../FormElements/styles.module.sass";
import PasswordMask from "react-password-mask";
import { WrappedFieldProps } from "redux-form";

export class RenderPasswordField extends React.Component<
  WrappedFieldProps & {
    maxLength: number;
    className: string;
    label: string;
    type: string;
  }
> {
  public render() {
    const {
      input,
      label,
      meta: { touched, error, warning },
      className,
      maxLength,
    } = this.props;
    const mask = (
      <img
        style={{ width: "3rem" }}
        src={require("../../../assets/mask.svg")}
      />
    );
    return (
      <div className={className ? styles.fullWidthInput : styles.inputWrapper}>
        <label htmlFor={input.name} className={styles.passwrodLabel}>
          <span>{label}</span>
          {touched &&
            ((error && <span className={styles.formError}>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </label>
        <PasswordMask
          maxLength={maxLength}
          inputClassName={styles.passInputWrapperForUser}
          showButtonContent={mask}
          hideButtonContent={mask}
          placeholder="Password"
          {...input}
          buttonStyles={{ background: "none" }}
          id={input.name}
        />
      </div>
    );
  }
}
