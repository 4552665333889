import React from "react";
import styles from "./styles.module.sass";
import ic_check from "../../../assets/ic_check.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelect } from "../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { getResolvedActions } from "../../../redux-store/actions";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import info from "../../../assets/info.svg";

export const ResolvedActionsCard: React.FC = () => {
  const {
    has_more,
    loadingStatus,
    page,
    resolved_actions,
    account_manager_id,
  } = useSelect((state) => ({ ...state.getResolvedActionsReducer }));
  const dispatch = useDispatch();
  const handleNext = () => {
    dispatch(getResolvedActions({ page: page + 1, account_manager_id }));
  };
  return (
    <div className={styles.main}>
      <div className={styles.headerWrapper}>
        <div className={styles.resolvedIcon}>
          <img className={styles.ic_check} src={ic_check} />
        </div>
        <span className={styles.resolvedTitle}>
          Resolved actions <span>({resolved_actions.length})</span>
        </span>
      </div>
      <InfiniteScroll
        height={360}
        className={styles.resolvedIssuesWrapper}
        hasMore={has_more}
        loader={
          loadingStatus === LoadingStatus.loading && (
            <div className={styles.loaderWrapper}>
              <ClipLoader color=" #f09440" size={50} />
            </div>
          )
        }
        next={handleNext}
        dataLength={resolved_actions.length}
      >
        {!resolved_actions.length && !has_more ? (
          <div className={styles.emptyWrapper}>
            <img className={styles.infoIcon} src={info}/>
            <span className={styles.thereAreNoTasksResolved}>There are no tasks resolved</span>
          </div>
        ) : (
          (resolved_actions || []).map((resolved_action) => (
            <div
              className={styles.resolvedIssueWrapper}
              key={resolved_action.id}
            >
              <span className={styles.resolvedIssueTitle}>
                {moment(resolved_action.created_at).calendar()}
              </span>
              <span className={styles.resolvedIssueMessage}>
                {resolved_action.resolve_action_summary}
              </span>
            </div>
          ))
        )}
      </InfiniteScroll>
    </div>
  );
};

export default ResolvedActionsCard;
