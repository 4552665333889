import React, { useState } from "react";
import { IOptionItem, ISelectProps } from "../types";
import styles from "./styles.module.sass";
import "../styles.less";
import { Select as AntdSelect } from "antd";
import chevron_down from "../../../assets/chevron-down.svg";
import { CheckIcon } from "../../../assets/components";
export function Select<T>({
  options,
  className,
  popupClassName,
  defaultValue,
  onChange,
  label,
}: ISelectProps<T>) {
  const [selectedItem, setSelectedItem] = useState<IOptionItem<T> | undefined>(
    defaultValue
  );
  return (
    <AntdSelect
      bordered={false}
      suffixIcon={
        <img src={chevron_down} style={{ width: "16px", height: "16px" }} />
      }
      className={className ?? "generic-select"}
      popupClassName={popupClassName ?? "generic-dropdown"}
      value={`${label}: ${selectedItem?.label}`}
    >
      <div className={styles.dropdownWrapper}>
        {options.map((item) => (
          <div
            key={String(item?.value)}
            onClick={() => {
              setSelectedItem(item);
              if (onChange) {
                onChange(item);
              }
            }}
            style={{
              backgroundColor:
                item.value === selectedItem?.value ? "#FFF4EA" : "transparent",
            }}
            className={styles.dropdownElementWrapper}
          >
            {item.value === selectedItem?.value && (
              <CheckIcon stroke="#000" style={{ marginRight: "4px" }} />
            )}
            <span className={styles.dropdownElementLabel}>{item.label}</span>
          </div>
        ))}
      </div>
    </AntdSelect>
  );
}

export default Select;
