import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getContactTemplateApi } from "../../axios";
import { IContactTemplate } from "../../types";
import { getContactTemplatesAction } from "../../constants";
import {
  getContactTemplatesFailure,
  getContactTemplatesSuccess,
  getContactTemplates,
} from "../actions";
import { selectToken } from "../selectors";
import { union } from "ts-action";

const actionType = union({ getContactTemplates });
function* getContactTemplatesSaga({
  payload: { template_code },
}: typeof actionType) {
  try {
    const token: string = yield select(selectToken);
    const res: AxiosResponse<IContactTemplate[]> = yield call(
      getContactTemplateApi,
      token,
      template_code
    );
    yield put(getContactTemplatesSuccess(res.data));
  } catch (error) {
    yield put(getContactTemplatesFailure(error));
  }
}

export function* watchGetContactTemplatesSaga() {
  yield takeLatest(getContactTemplatesAction.requested, getContactTemplatesSaga);
}
