import React from "react";
import * as styles from "./styles.module.sass";
import { Modal } from "@material-ui/core";

interface IProps {
  open: boolean;
  onClose: () => void;
  rank: number;
  promo_code?: string;
  handleOverwrite: (values) => void;
  setInvalidRank: (values) => void;
  promoCodeValues: any;
}
export const OverwriteRankingPopup: React.FC<IProps> = ({
  onClose,
  open,
  rank,
  promo_code,
  setInvalidRank,
  handleOverwrite: handleOverwriteFn,
  promoCodeValues,
}) => {
  const handleOverwrite = () => {
    setInvalidRank(false);
    handleOverwriteFn({ ...promoCodeValues });
  };
  return (
    <Modal open={open} onClose={onClose} className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <span className={styles.title}>Overwrite ranking</span>
          <div className={styles.contentWrapper}>
            <img
              className={styles.errorImage}
              src={require("../../../../assets/icon-error.svg")}
            />
            <span className={styles.rank}>
              Rank <strong>“{rank}”</strong>{" "}
              {promo_code && (
                <React.Fragment>
                  in <strong>“{promo_code}”</strong>
                </React.Fragment>
              )}{" "}
              is already selected
            </span>
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </button>
          <button onClick={handleOverwrite} className={styles.overwriteButton}>
            Overwrite
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OverwriteRankingPopup;
