import * as React from "react";
import * as styles from "./App.module.sass";
import Notification from "react-notify-toast";
import "./App.less";
import { HashRouter, Switch, Redirect } from "react-router-dom";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import { IRootReducerState } from "./redux-store/rootReducer";
import LoadingBar from "react-redux-loading-bar";
import NavBar from "./Components/NavBar";
import Header from "./Components/Header";
import Login from "./Components/login";
import { logout } from "./redux-store/actions";
import { Route } from "react-router";
import ScrollToTop from "./UIComponents/Scroller";
import Profile from "./Components/Profile";
import QuoteRequests from "./Components/QuoteRequests";
import CampaignRequests from "./Components/CampaignRequests";
import ShopXSettings from "./Components/ShopXSettings";
import WatchTower from "./Components/WatchTower";
import SMS from "./Components/TargetedTools/SMS/index";
import PromoCode from "./Components/TargetedTools/AddPromoCode";
import PushNotification from "./Components/TargetedTools/PushNotification/index";
import { BannerCards } from "./Components/TargetedTools/BannerCards";
import { Announcements } from "./Components/TargetedTools/Announcements";
import Tags from "./Components/TargetedTools/Tags";
import Categories from "./Components/TargetedTools/Categories";
import MerchantsStats from "./Components/MerchantsStats";
import { getCountries } from "./redux-store/actions";
import PromotedSection from "./Components/TargetedTools/promotedSection";
import "./styles.less";
import { getAdminsByRole } from "./Components/TargetedTools/redux-store/actions/admins-by-role-actions";
import {
  AmsActivityLog,
  AmsActionCenter,
  AmsTakeAction,
  PosIntegration,
} from "./pages";

interface IProps {
  syncState: number;
  lastUpTime: number;
  isLoggedIn: boolean;
  is_account_manager_logged_in: boolean;
  logout;
  getCountries;
  getAdminsByRole: (payload: { role: string }) => void;
}

export const Aux = (props) => props.children;

const NotFoundContent = () => <h1 style={{ margin: "auto" }}>Not Found</h1>;

class App extends React.Component<IProps> {
  public componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.getCountries();
      this.props.getAdminsByRole({ role: "account_manager" });
    }
  }
  public render() {
    const authenticatedStuff = (
      <div className={styles.mainSection}>
        <HashRouter>
          <Aux>
            <NavBar logout={this.props.logout} />
            <div className={styles.navBar} />
            <div className={styles.secondarySection}>
              <Header {...{}} />
              <Aux>
                <ScrollToTop {...{}}>
                  <Switch>
                    <Route
                      exact={true}
                      path="/watch-tower"
                      component={WatchTower}
                    />
                    <Route exact={true} path="/" component={MerchantsStats} />
                    <Route
                      exact={true}
                      path="/profile/:store_id/statistics"
                      component={Profile}
                    />
                    <Route
                      exact={true}
                      path="/profile/:store_id/statistics"
                      component={Profile}
                    />
                    <Route
                      exact={true}
                      path="/profile/:store_id/pos-integration"
                      render={() => (
                        <div className={styles.posIntegration}>
                          <PosIntegration />
                        </div>
                      )}
                    />
                    <Route
                      exact={true}
                      path={"/account-managers/activity-log"}
                      render={() => {
                        return this.props.is_account_manager_logged_in ? (
                          <AmsActivityLog />
                        ) : (
                          <Redirect to={"/"} />
                        );
                      }}
                    />
                    <Route
                      exact={true}
                      path={"/account-managers/action-center"}
                      render={() => {
                        return this.props.is_account_manager_logged_in ? (
                          <AmsActionCenter />
                        ) : (
                          <Redirect to={"/"} />
                        );
                      }}
                    />
                    <Route
                      exact={true}
                      path={"/account-managers/action-center/take-action"}
                      render={() => {
                        return this.props.is_account_manager_logged_in ? (
                          <AmsTakeAction />
                        ) : (
                          <Redirect to={"/"} />
                        );
                      }}
                    />
                    <Route
                      exact={true}
                      path="/profile/:store_id/payment"
                      component={Profile}
                    />
                    <Route
                      exact={true}
                      path="/profile/:store_id/logs"
                      component={Profile}
                    />
                    <Route
                      exact={true}
                      path="/profile/:store_id/wizard"
                      component={Profile}
                    />
                    <Route
                      exact={true}
                      path="/reach/payment"
                      component={QuoteRequests}
                    />
                    <Route
                      exact={true}
                      path="/reach/campaigns"
                      component={CampaignRequests}
                    />
                    <Route
                      exact={true}
                      path="/shopx-settings"
                      component={ShopXSettings}
                    />

                    <Route
                      exact={true}
                      path="/targeting/promo-codes"
                      component={PromoCode}
                    />
                    <Route
                      exact={true}
                      path="/targeting/promoted-brands"
                      component={PromotedSection}
                    />
                    <Route
                      exact={true}
                      path="/targeting/banner-cards"
                      component={BannerCards}
                    />
                    <Route
                      exact={true}
                      path="/targeting/announcements"
                      component={Announcements}
                    />
                    <Route
                      exact={true}
                      path={"/targeting/tags"}
                      component={Tags}
                    />

                    <Route
                      exact={true}
                      path={"/targeting/categories"}
                      component={Categories}
                    />

                    <Route exact={true} path="/reach/sms" component={SMS} />
                    <Route
                      exact={true}
                      path="/reach/push-notifications"
                      component={PushNotification}
                    />

                    <Route render={NotFoundContent} />
                  </Switch>
                </ScrollToTop>
              </Aux>
            </div>
          </Aux>
        </HashRouter>
      </div>
    );
    return (
      <div className={styles.App}>
        <LoadingBar style={{ backgroundColor: "yellow" }} />
        <div style={{ fontSize: "2rem" }}>
          <Notification options={{ zIndex: 2000 }} />
        </div>
        {this.props.isLoggedIn ? authenticatedStuff : <Login {...{}} />}
      </div>
    );
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  isLoggedIn: state.authReducer.isLoggedIn,
  is_account_manager_logged_in:
    (state.getAdminsByRoleReducer.account_manager || []).find(
      (am) => am.logged_in
    )?.logged_in || false,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getCountries: () => dispatch(getCountries()),
  getAdminsByRole: (payload: { role: string }) =>
    dispatch(getAdminsByRole({ role: payload.role })),
});

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(App as any) as any
);
