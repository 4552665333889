import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  sendPushNotificationAction,
  sendPushNotificationActionType,
} from "../../constants";
import { selectToken } from "../selectors";
import {
  sendPushNotificationAPI,
  ISendPushNotificationType,
} from "../../axios/sendPushNotification";
import {
  sendPushNotificationSuccess,
  sendPushNotificationFailed,
} from "../actions/sendPushNotificationAction";
import { notify } from "react-notify-toast";
import { getPushNotifications } from "../../Components/TargetedTools/redux-store/actions/pushNotification";
// import { useDispatch } from "react-redux";

function* sendPushNotificationSaga(action: sendPushNotificationActionType) {
  try {
    // const dispatch = useDispatch();
    const token = yield select(selectToken);
    const res = yield call(
      sendPushNotificationAPI,
      token,
      action.payload as ISendPushNotificationType
    );
    if (res.status >= 200 && res.status < 300) {
      yield put(sendPushNotificationSuccess(res));

      notify.show("Push Notification sent successfully", "success");
      yield put(getPushNotifications({ page: 0, per_page: 25 }));
    } else {
      throw res;
    }
  } catch (error) {
    yield put(sendPushNotificationFailed(error));
    notify.show("There is an error", "error");
    // console.log("Error Message : ", error);
  }
  // yield put(sendPushNotificationSuccess(res));
  // notify.show(`Push notification is successfully sent`, 'success')

  // }
  // catch (e) {
  //   yield put(sendPushNotificationFailed(e));
  //   notify.show('There is an error here', 'error')
  // }
}

export function* watchSendPushNotificationSaga() {
  yield takeLatest(
    sendPushNotificationAction.requested,
    sendPushNotificationSaga
  );
}
