import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  createStoreTag,
  createStoreTagFailure,
  createStoreTagSuccess,
} from "../actions/tags-actions";
import { createStoreTagsAction } from "../actions/constants";
import { createStoreTags } from "../../axios/createStoreTags";
import { appendFormData } from "./createBannerCardSaga";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { customNotify } from "../../../../UIComponents/customNotification/customNotification";

const actionType = union({ createStoreTag });

function* createStoreTagsSaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  const formData = new FormData();
  const append = appendFormData.bind(null, formData);

  append("name_en", payload.name_en);
  append("name_ar", payload.name_ar);
  const res = yield call(createStoreTags, token, formData);
  const resStatus =
    res.status === 200
      ? customNotify.success(
          `[${payload.name_en}-${payload.name_ar}] Tag addedd successfully`
        )
      : customNotify.error("There is something wrong");
  return {
    ...payload,
    ...res.data,
    resStatus,
  };
}
export function* watchCreateStoreTagsSaga() {
  yield takeLatest(
    createStoreTagsAction.requested,
    handleOptimism(createStoreTagsSaga, {
      success: createStoreTagSuccess,
      failure: createStoreTagFailure,
    })
  );
}
