import axios from "./axios";
import { announcementsURL } from "./requests";
import { IAnnouncementBase } from "../types";

export const createAnnouncementAPI = (token: string, cardData: FormData) => {
  return axios.post<IAnnouncementBase>(`${announcementsURL}`, cardData, {
    headers: { token },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
