import axios from "./axios";
import * as Requests from "./requests";

export const rejectCampaign = (token: string, id: string) => {
  return axios.put(
    Requests.rejectCampaign(id),
    {
      accept: 0,
    },
    {
      baseURL: process.env.LUMEN_ENDPOINT,
      headers: {
        token,
      },
    }
  );
};
