import isEqual from "lodash/isEqual";
import transform from "lodash/transform";
import isObject from "lodash/isObject";
/**
 * Deep diff between two object, using lodash
 * @param  {object} object Object compared
 * @param  {object} base   Object to compare with
 * @return {object}        Return a new object who represent the diff
 */

interface IObj {
  [x: string]: any;
}
export function difference(object: IObj, base: IObj) {
  function changes(object1: IObj, base1: IObj) {
    return transform(object1, (result: IObj, value, key) => {
      if (!isEqual(value, base1[key])) {
        result[key] =
          isObject(value) && isObject(base1[key])
            ? changes(value, base1[key])
            : value;
      }
    });
  }
  return changes(object, base);
}
