import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IBrandTasks } from "../../types";
import { getBrandsWithTasksApi } from "../../axios";
import { getBrandsWithTasksAction } from "../../constants";
import {
  getBrandsWithTasks,
  getBrandsWithTasksFailure,
  getBrandsWithTasksSuccess,
} from "../actions";
import { selectToken } from "../selectors";
import { union } from "ts-action";

const actionType = union({ getBrandsWithTasks });
function* getBrandsWithTasksSaga({ payload }: typeof actionType) {
  try {
    const token: string = yield select(selectToken);
    const res: AxiosResponse<IBrandTasks[]> = yield call(
      getBrandsWithTasksApi,
      token,
      payload.account_manager_id
    );
    yield put(
      getBrandsWithTasksSuccess({
        data: res.data ?? [],
        account_manager_id: payload.account_manager_id,
      })
    );
  } catch (error) {
    yield put(
      getBrandsWithTasksFailure({
        account_manager_id: payload.account_manager_id,
        data: [],
      })
    );
  }
}

export function* watchGetBrandsWithTasksSage() {
  yield takeLatest(getBrandsWithTasksAction.requested, getBrandsWithTasksSaga);
}
