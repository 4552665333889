import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getBrandPosEntities,
  getBrandPosEntitiesFailure,
  getBrandPosEntitiesSuccess,
} from "../actions";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import { getBrandPosEntitiesAction } from "../../constants";
import { getBrandPosEntitiesApi } from "../../axios/pos";

const actionTypes = union({ getBrandPosEntities });
function* getBrandPosEntitiesSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getBrandPosEntitiesApi, token, payload);
    yield put(getBrandPosEntitiesSuccess(res.data.pos));
  } catch (error) {
    yield put(getBrandPosEntitiesFailure(error));
  }
}

export function* watchGetBrandPosEntitiesSaga() {
  yield takeLatest(
    getBrandPosEntitiesAction.requested,
    getBrandPosEntitiesSaga
  );
}
