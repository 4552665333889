import { Modal, ModalBody } from "reactstrap";
import * as React from "react";
import * as styles from "./styles.module.sass";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { RenderPasswordField } from "../../Components/FormElements/RenderPasswordField";
import { required } from "../../redux-store/validators";
import { hot } from "react-hot-loader";
import { FormEvent } from "react";

interface IProps {
  modal: boolean;
  toggle: () => void;
  title: string;
  verifyPayment: () => void;
  brandName: string;
}
class Confirmation extends React.Component<IProps & InjectedFormProps> {
  public handleSubmit = (e: FormEvent) => {
    if (!this.props.submitting) {
      e.preventDefault();
      this.props.touch("password");
      if (this.props.valid) {
        this.props.verifyPayment();
      }
    }
  };
  public componentWillUnmount() {
    this.props.reset();
  }

  public render() {
    if (this.props.submitSucceeded && this.props.modal) {
      this.props.toggle();
      this.props.reset();
    }
    return (
      <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
        <ModalBody>
          <form
            autoComplete="off"
            onSubmit={this.handleSubmit}
            className={styles.wrapper}
          >
            <h1>{this.props.title} Payment</h1>
            <p>
              Enter your password to {this.props.title}
              <span style={{ color: "red", margin: 0 }}>
                {" "}
                {this.props.brandName} payment
              </span>
            </p>
            <Field
              name="password"
              type="password"
              className="fullWidth"
              component={RenderPasswordField as any}
              label="Password"
              validate={[required]}
            />
            <button
              onClick={this.handleSubmit}
              type="submit"
              className={styles.btn}
            >
              {this.props.title} Payment
            </button>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

const ConfirmationModal = reduxForm({
  form: "confirmationForm",
})(Confirmation);
export default hot(module)(ConfirmationModal) as any;
