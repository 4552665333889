import axios from "../axios";
import { posEntitiesURL } from "../requests";
export const deleteBrandPosEntityApi = (token: string, id: string, store_id: string) => {
  return axios.delete(posEntitiesURL, {
    baseURL: process.env.POS_ENDPOINT,
    headers: {
      token,
    },  
    data: {
      id,
      store_id
    }
  });
};
