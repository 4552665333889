import React, { useEffect } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { Drawer } from "@material-ui/core";
import { Form, Field, reduxForm, InjectedFormProps } from "redux-form";
import { RenderGenericField } from "../../../SharedComponent/RenderGenericField/RenderGenericField";
import { RenderPasswordField } from "../../../SharedComponent/RenderPasswordField/index";
import { RenderDropDownSelect } from "../../../SharedComponent/RenderDropDownSelect/index";
import { GenericButtonWithoutIcon } from "../../../SharedComponent/GenericButtonWithoutIcon";
import { ISelectItemData } from "../../../FormElements/RenderDropDownSelect";
import RadioButtonField from "../../../SharedComponent/RadioButton";
import { useDispatch } from "react-redux";
import { useSelect } from "../../../../hooks/useSelect";
import { IAddWatchers } from "../../../../types";
import { getCountries } from "../../../../redux-store/actions";
import { addWatchers } from "../../../../redux-store/actions";
import { required, isEmpty } from "../../../../redux-store/validators";
interface IProps {
  open: boolean;
  onClose: () => void;
}

const AddUserForm: React.FC<IProps & InjectedFormProps<IAddWatchers>> = (
  props
) => {
  const { onClose, open } = props;
  const countries = useSelect((state) => state.countriesReducer.countries);
  const countrySelectItems = countries.map(
    (country): ISelectItemData => ({
      value: country.ISO_code,
      label: country.EnName,
    })
  );
  const [op, setOp] = React.useState(open);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCountries());
  }, []);
  const add = (values) => {
    dispatch(addWatchers({ ...values }));
    setOp(!op);
  };

  return (
    <Drawer anchor="right" open={op} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.addUserWrapper}>
          <span className={styles.addUserTitle}>Add new user</span>
          <img
            src={require("../../../../assets/icon-close.svg")}
            className={styles.close}
            onClick={onClose}
          />
        </div>
        <Form onSubmit={props.handleSubmit(add)}>
          <div className={styles.form}>
            <Field
              name="name"
              type="text"
              component={RenderGenericField as any}
              label={"Full Name"}
              className={styles.input}
              placeholder={"John doe"}
              validate={[required, isEmpty]}
            />
            <div className={styles.separator} />
            <Field
              name="email"
              type="email"
              component={RenderGenericField as any}
              label={"Email"}
              className={styles.input}
              placeholder={"name@example.com"}
              validate={[required, isEmpty]}
            />
            <div className={styles.separator} />

            <Field
              component={RenderPasswordField as any}
              name="password"
              type="password"
              className={styles.input}
              label="Password"
              validate={[required, isEmpty]}
            />
            <Field
              name={"country_iso_code"}
              className={styles.input}
              component={RenderDropDownSelect as any}
              label={"Country"}
              options={countrySelectItems}
              validate={[required, isEmpty]}
            />
            <div className={styles.separator} />
            <span className={styles.userRole}>User role</span>
            <div className={styles.userRoleWrapper}>
              <Field
                component={RadioButtonField}
                label="Admin"
                name="role"
                radioValue="supervisor_watcher"
                validate={[required, isEmpty]}
              />
              <div style={{ marginLeft: "8.1rem" }} />
              <Field
                component={RadioButtonField}
                label="Customer support"
                radioValue="representative_watcher"
                name="role"
                validate={[required, isEmpty]}
              />
            </div>
            <div className={styles.separator} />

            <GenericButtonWithoutIcon
              label={"Add User"}
              type={"submit"}
              className={styles.btn}
            />
            <div className={styles.separator} />
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default hot(module)(
  reduxForm({
    form: "addUserForm",
  })(AddUserForm as any) as any
);
