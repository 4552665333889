import { getCashbackDetailsAction } from "../../constants";
import { on, reducer } from "ts-action";
import { LoadingStatus, withLoadingReducer } from "./withLoadingState";
import {
  clearPrevCashbackDetails,
  createCashbackDetails,
  createCashbackDetailsFailure,
  createCashbackDetailsSuccess,
  editCashbackDetails,
  editCashbackDetailsFailure,
  editCashbackDetailsSuccess,
  getCashbackDetailsSuccess,
  ICashbackDetails,
} from "../actions";
interface IState {
  cashback_details?: ICashbackDetails;
  status?: LoadingStatus;
}

export const cashbackDetailsReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(clearPrevCashbackDetails, (state, {}) => ({
        ...state,
        cashback_details: undefined,
        status: undefined,
      })),
      on(getCashbackDetailsSuccess, (state, { payload }) => ({
        ...state,
        cashback_details: payload.cashback_details,
      })),
      on(createCashbackDetailsSuccess, (state, { payload }) => ({
        ...state,
        cashback_details: payload,
        status: LoadingStatus.success,
      })),
      on(createCashbackDetails, (state, {}) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(createCashbackDetailsFailure, (state, {}) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
      on(editCashbackDetailsSuccess, (state, { payload }) => ({
        ...state,
        cashback_details: payload,
        status: LoadingStatus.success,
      })),
      on(editCashbackDetails, (state, {}) => ({
        ...state,
        status: LoadingStatus.loading,
      })),
      on(editCashbackDetailsFailure, (state, {}) => ({
        ...state,
        status: LoadingStatus.failed,
      })),
    ],
    {
      cashback_details: undefined,
      status: undefined,
    }
  ),
  getCashbackDetailsAction
);