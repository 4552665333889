import React from "react";
import { useState } from "react";
import { ConnectedActiveLocationsStats } from "./components";
import styles from "./styles.module.sass";
import AddModal from "../../UIComponents/AddModal";
import { Merchants } from "./components";
export const MerchantsStats: React.FC = () => {
  const [modal, setModal] = useState(false);
  const openAddBrandModal = () => setModal(!modal);

  return (
    <div className={styles.wrapper}>
      <ConnectedActiveLocationsStats />
      <div
        style={{
          margin: "0 24px",
          marginBottom: "56px ",
        }}
      >
        <div className={styles.merchantHeaderWrapper}>
          <span className={styles.title}>Brands</span>
          <button onClick={openAddBrandModal} className={styles.buttonWrapper}>
            <img
              src={require("../../assets/feather _ plus.svg")}
              className={styles.plus}
            />
            Add new brand
          </button>
        </div>
        <Merchants />
      </div>
      {modal && (
        <AddModal
          modal={modal}
          toggle={openAddBrandModal}
          title="Add a new brand"
          {...{}}
        />
      )}
    </div>
  );
};

export default MerchantsStats;
