import { Mutations } from "../../queries";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeMerchantDealsAtom } from "../../atoms";
import { customNotify } from "../../UIComponents/customNotification/customNotification";

export const useLoyaltyDeal = (store_id, data) => {
  const [activeMerchantDeals, setActiveMerchantDeals] = useRecoilState(
    activeMerchantDealsAtom
  );

  const {
    mutate: mutateLoyaltyDeal,
    variables: mutateLoyaltyDealVariables,
    reset: mutationLoyaltyDealReset,
    status: mutateLoyaltyDealStatus,
  } = Mutations.editActiveMerchantDeals(store_id, "loyalty-status");
  const loyaltyToggle = (checked: boolean) => {
    setActiveMerchantDeals((prevState) => ({
      ...prevState,
      loyalty_status: {
        status: checked ? "service_enabled" : "service_disabled",
        loading: "loading",
      },
    }));
    mutateLoyaltyDeal(checked ? "service_enabled" : "service_disabled");
  };
  useEffect(() => {
    if (mutateLoyaltyDealStatus === "success") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        loyalty_status: {
          status: prevState.loyalty_status.status,
          loading: "error",
        },
      }));
      mutationLoyaltyDealReset();
    } else if (mutateLoyaltyDealStatus === "error") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        loyalty_status: {
          status: data?.loyalty_status,
          loading: "error",
        },
      }));
      customNotify.error("Something went wrong!!");
      mutationLoyaltyDealReset();
    }
  }, [mutateLoyaltyDealVariables, mutateLoyaltyDealStatus, data]);

  return {
    loyaltyToggle,
    activeMerchantDeals,
  };
};
