import { IStoreTasks } from "src/types";
import axios from "../axios";
import { resolveTasksURL } from "../requests";

export const resolveTasksApi = (
  token: string,
  stores: Omit<IStoreTasks<number | string>, "store_name">[]
) => {
  return axios.post(
    resolveTasksURL,
    { stores },
    {
      headers: { token },
      baseURL: process.env.LUMEN_ENDPOINT,
    }
  );
};
