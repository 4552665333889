import { call, put, select, takeLatest } from "redux-saga/effects";
import { getResolvedActionsAction } from "../../constants";
import { union } from "ts-action";
import { getResolvedActionsApi } from "../../axios";
import {
  getResolvedActions,
  getResolvedActionsFailure,
  getResolvedActionsSuccess,
} from "../actions";
import { selectToken } from "../selectors";
import { AxiosResponse } from "axios";
import { IActionSummary } from "../../types";

const actionType = union({ getResolvedActions });
function* getResolvedActionsSaga({ payload }: typeof actionType) {
  try {
    const token: string = yield select(selectToken);
    const res: AxiosResponse<IActionSummary[]> = yield call(
      getResolvedActionsApi,
      token,
      payload.page,
      payload.account_manager_id
    );
    yield put(
      getResolvedActionsSuccess({
        page: payload.page,
        account_manager_id: payload.account_manager_id,
        resolved_actions: res.data,
        has_more: !!res.data.length,
      })
    );
  } catch (error) {
    yield put(getResolvedActionsFailure(error));
  }
}

export function* watchGetResolvedActionsSaga() {
  yield takeLatest(getResolvedActionsAction.requested, getResolvedActionsSaga);
}
