import React, { useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { GenericButton } from "../../../SharedComponent/GenericButtonWithIcon";
import { IAddWatchers } from "../../../../types";
import { useSelect } from "../../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { deleteWatcher } from "../../../../redux-store/actions";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
// import { useDispatch } from "react-redux";
// import { useSelect } from '../../../../hooks/useSelect';

interface IProps {
  data: IAddWatchers;
}

const Watcher: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { country_iso_code, email, name, role } = props.data;
  const [show, setShow] = useState(false);
  const showButtons = () => setShow(true);
  const hideButtons = () => setShow(false);

  const { countries, deleteWatcherStatus } = useSelect((state) => ({
    countries: state.countriesReducer.countries,
    deleteWatcherStatus: state.deleteWatcherReducer.loadingStatus,
  }));

  // const [country, setCountry] = React.useState();

  const country = React.useMemo(
    () => countries.filter((it) => it.ISO_code === country_iso_code),
    [countries]
  );

  const avatarString = React.useCallback(
    (str) => {
      return str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    },
    [name]
  );

  const handleDeleteWatcher = () => {
    dispatch(deleteWatcher({ id: props.data.id }));
  };
  return (
    <div
      className={styles.info}
      onMouseEnter={showButtons}
      onMouseLeave={hideButtons}
    >
      <div className={`${show ? styles.userInfoActive : styles.userInfo}`}>
        <div className={styles.avatar}>
          <span className={styles.words}>{avatarString(name)}</span>
        </div>
        <span className={styles.user}>{name}</span>
      </div>
      <span className={`${show ? styles.emailActive : styles.email}`}>
        {email}
      </span>
      <span className={`${show ? styles.countryActive : styles.country}`}>
        {country[0] ? country[0].EnName : " "}
      </span>

      <div className={`${show ? styles.roleActive : styles.role}`}>
        <span className={styles.roleWrapper}>
          <span className={styles.roleTitle}>
            {role === "supervisor_watcher" ? "Admin" : "Agent"}
          </span>
        </span>
      </div>
      <div className={`${show ? styles.btnWrapper : styles.btnWrapperHide}`}>
        {/* <GenericButton
          iconPath={require("../../../../assets/edit.svg")}
          className={styles.edit}
        /> */}
        <div style={{ marginLeft: "1rem" }} />
        <GenericButton
          iconPath={require("../../../../assets/icon-delete.svg")}
          className={
            deleteWatcherStatus === LoadingStatus.loading
              ? styles.disabledDelete
              : styles.delete
          }
          onClick={handleDeleteWatcher}
          disable={
            !props.data.id || deleteWatcherStatus === LoadingStatus.loading
          }
        />
      </div>
    </div>
  );
};

export default hot(module)(Watcher);
