import { payload } from "ts-action";
import { COMMIT, REVERT } from "redux-optimistic-ui";
import { expirePromoCodeAction } from "./constants";
import { optAction, optReaction } from "../../../../redux-store/actions/lib";

export const expirePromoCode = optAction(
  expirePromoCodeAction.requested,
  payload<{ promoId: string }>()
);
export const expirePromoCodeSuccess = optReaction(
  expirePromoCodeAction.fulfilled,
  payload<any>(),
  COMMIT
);

export const expirePromoCodeFailure = optReaction(
  expirePromoCodeAction.rejected,
  payload<any>(),
  REVERT
);
