import { MerchantStatus, PaymentStatus } from "../types";
import { ISelectItemData } from "../Components/FormElements/Select/index";
export const paymentStatuses: ISelectItemData[] = [
  {
    value: 0,
    text: "On\nSetup",
  },
  {
    value: 1,
    text: "On\nTrial",
  },
  {
    value: 2,
    text: "Paid",
  },
  {
    value: 3,
    text: `Waiting \n payment`,
  },
];

export const countriesOptions: ISelectItemData<
  "eg" | "sa" | "in" | "om" | "ae" | undefined
>[] = [
  {
    value: undefined,
    text: "All",
  },
  {
    value: "eg",
    text: "Egypt",
  },
  {
    value: "sa",
    text: `Saudi arabia`,
  },
  {
    value: "in",
    text: "India",
  },
  {
    value: "om",
    text: "Oman",
  },
  {
    value: "ae",
    text: "UAE",
  },
];

export const paymentStatusesOptions: ISelectItemData<
  PaymentStatus | undefined
>[] = [
  {
    value: undefined,
    text: "All",
  },
  {
    value: "paid",
    text: "Paid",
  },
  {
    value: "waiting_for_payment",
    text: `Waiting for payment`,
  },
  {
    value: "on_trial",
    text: "On Trial",
  },
  {
    value: "on_setup",
    text: "On Setup",
  },
];

export const merchantStatusOptions: ISelectItemData<
  MerchantStatus | undefined
>[] = [
  {
    value: undefined,
    text: "All",
  },
  {
    value: "running",
    text: "Running",
  },
  {
    value: "in_progress",
    text: "In progress",
  },
  {
    value: "inactive",
    text: "Inactive",
  },
  {
    value: "closed",
    text: `Closed`,
  },
];
