import * as React from "react";
import { connect } from "react-redux";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { hot } from "react-hot-loader";
import PaymentForm from "../../UIComponents/PaymentForm";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import {
  IConfirmPaymentAction,
  IResetPaymentAction,
  IVerifyPaymentAction,
} from "../../types";
import {
  confirmPayment,
  resetPayment,
  verifyPayment,
} from "../../redux-store/actions";
import { IStore } from "../../axios/getBrands";
import { LoadingPageInfo } from "../LoadingPageInfo";
import * as moment from "moment";

interface IProps {
  brand: IStore;
  paid: number;
  verified: boolean;
  verifying: boolean;
  failed: boolean;
  verifyPayment: (brandId: string) => void;
  confirmPayment: (brandId: string) => void;
  resetPayment: () => void;
}

class Payment extends React.Component<
  IProps &
    RouteComponentProps<{
      store_id: string;
    }>
> {
  public verifyPayment = () => {
    const { store_id } = this.props.match.params;
    this.props.verifyPayment(store_id);
  };
  public confirmPayment = () => {
    const { store_id } = this.props.match.params;
    this.props.confirmPayment(store_id);
  };
  public componentWillUnmount() {
    this.props.resetPayment();
  }

  public render() {
    if (this.props.brand) {
      if (!this.props.verified) {
        this.props.brand.payment_date = moment(
          this.props.brand.payment_date,
          "x"
        );
        return (
          <PaymentForm
            verifyPayment={this.verifyPayment}
            title="Verify brand"
            initialValues={this.props.brand}
            brand={this.props.brand}
          />
        );
      }
      if (this.props.verified) {
        return (
          <PaymentForm
            verifyPayment={this.confirmPayment}
            title="Confirm next"
            brand={this.props.brand}
          />
        );
      }
      return <LoadingPageInfo />;
    }
    return <LoadingPageInfo />;
  }
}
const mapStateToProps = (state: IRootReducerState) => ({
  paid: state.paymentReducer.paid,
  verified: state.paymentReducer.verified,
  verifying: state.paymentReducer.verifying,
  failed: state.paymentReducer.failed,
});
const mapDispatchToProps = (
  dispatch: Dispatch<
    IVerifyPaymentAction | IConfirmPaymentAction | IResetPaymentAction
  >
) => ({
  verifyPayment: (brandId: string) => dispatch(verifyPayment(brandId)),
  confirmPayment: (brandId: string) => dispatch(confirmPayment(brandId)),
  resetPayment: () => dispatch(resetPayment()),
});

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(Payment)
);
