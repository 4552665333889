import { action, payload } from "ts-action";
import { getReachQuotasAction } from "../../constants";
import { IGetQuotasFilters } from "../../axios/get-quotas";
import { IStore } from "../../axios/getBrands";
import { IManager } from "./getReachCampaignsActions";

export interface IReachQuotasResponse {
  id: string;
  name: string;
  points: number;
  sms_price: number;
  reach_quota_eloquent_id: number;
  status: number;
  store_id: string;
  store_name: string;
  manager_phone_number: string;
  store: IStore;
  manager: IManager;
  created_at: number;
}

export const getReachQuotas = action(
  getReachQuotasAction.requested,
  payload<IGetQuotasFilters>()
);
export const getReachQuotasSuccess = action(
  getReachQuotasAction.fulfilled,
  payload<{ quotas: IReachQuotasResponse[]; total_quotas: number }>()
);
export const getReachQuotasFailure = action(
  getReachQuotasAction.rejected,
  payload<Error>()
);
