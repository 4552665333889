import { call, put, select, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { expirePromoCodeAction } from "../actions/constants";
import {
  expirePromoCodeFailure,
  expirePromoCode,
  expirePromoCodeSuccess,
} from "../actions/expire-actions";
import { expirePromoCodeAPI } from "../../axios/expirePromoCode";
import { selectToken } from "../../../../redux-store/selectors";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { getSystemPromoCode } from "../../../../redux-store/actions/getSystemPromoCodesActions";
const actionType = union({ expirePromoCode });
function* expirePromoCodeSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  const { promoId } = action.payload;
  const res = yield call(expirePromoCodeAPI, token, promoId);
  if (res.status === 200) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    yield put(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: undefined,
      })
    );
  }
}

export function* watchExpirePromoCodeSaga() {
  yield takeLatest(
    expirePromoCodeAction.requested,
    handleOptimism(expirePromoCodeSaga, {
      failure: expirePromoCodeFailure,
      success: expirePromoCodeSuccess,
    })
  );
}
