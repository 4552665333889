import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  deleteCategory,
  editCategorySuccess,
  editCategoryFailure,
} from "../actions/categoriesAction";
import { editCategoryAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { editCategoryAPI } from "../../axios/editCategories";
import { customNotify } from "../../../../UIComponents/customNotification/customNotification";

const actionType = union({ deleteCategory });

function* editCategorySaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  const active_image =
    payload.active_image instanceof File ? payload.active_image : undefined;
  const inactive_image =
    payload.inactive_image instanceof File ? payload.inactive_image : undefined;

  const formData = new FormData();
  if (active_image) {
    formData.append("active_image", active_image);
  }
  if (inactive_image) {
    formData.append("inactive_image", inactive_image);
  }
  formData.append("name_ar", payload.name_ar);
  formData.append("name_en", payload.name_en);
  formData.append("_method", "put");
  const res = yield call(editCategoryAPI, token, payload.id, formData);

  customNotify.success(
    `[${payload.name_en}-${payload.name_ar}] Category edited successfully`
  );
  return res.data;
}

export function* watchEditCategorySaga() {
  yield takeLatest(
    editCategoryAction.requested,
    handleOptimism(editCategorySaga, {
      success: editCategorySuccess,
      failure: editCategoryFailure,
    })
  );
}
