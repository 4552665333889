import * as constants from "../../constants";
import { IGetPaymentLogsSuccessAction, IPaymentLogs } from "../../types";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";

export interface IPaymentLogsReducerState {
  logs: IPaymentLogs[];
  completed: boolean;
  failed: boolean;
  inProgress: boolean;
}

const COMPLETED = "completed";
const IN_PROGRESS = "inProgress";
const FAILED = "failed";
const LOGS = "logs";

const initialState: IPaymentLogsReducerState = Map({
  [COMPLETED]: false,
  [IN_PROGRESS]: false,
  [FAILED]: false,
  [LOGS]: [],
}).toJS();

const paymentLogsReducer = (
  state: IPaymentLogsReducerState = initialState,
  action: IGetPaymentLogsSuccessAction
): IPaymentLogsReducerState => {
  switch (action.type) {
    case constants.getPaymentLogsAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(LOGS, (action as IGetPaymentLogsSuccessAction).payload);
        map.set(COMPLETED, true);
        map.set(FAILED, false);
        map.set(IN_PROGRESS, false);
      });
    }
    case constants.getPaymentLogsAction.requested: {
      return mutateState(state, (map) => {
        map.set(COMPLETED, false);
        map.set(FAILED, false);
        map.set(IN_PROGRESS, true);
      });
    }
    case constants.getPaymentLogsAction.rejected: {
      return mutateState(state, (map) => {
        map.set(COMPLETED, false);
        map.set(FAILED, true);
        map.set(IN_PROGRESS, false);
      });
    }
    case constants.ACTION_RESET_PAYMENT_LOGS: {
      return mutateState(state, (map) => {
        map.set(LOGS, []);
        map.set(COMPLETED, false);
        map.set(FAILED, false);
        map.set(IN_PROGRESS, false);
      });
    }
    default:
      return state;
  }
};

export default paymentLogsReducer;
