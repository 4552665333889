import React from "react";
import * as styles from "./styles.module.sass";
import { uDIC } from "../useDefaultAndInsertedClassName";

interface IProps {
  label?: string;
  iconPath: string;
  className?: string;
  IconClass?: string;
  onClick?: () => void;
  disable?: boolean;
  ref?: any;
}
const GenericButton: React.FC<IProps> &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > = (props) => {
  const { iconPath, IconClass, ...rest } = props;
  const icon = <img className={uDIC(styles.icon, IconClass)} src={iconPath} />;
  return (
    <button
      type="button"
      ref={rest.ref}
      {...rest}
      className={uDIC(styles.genericButtonDefaults, props.className)}
      disabled={props.disable}
    >
      {icon}
      {props.label}
    </button>
  );
};

export { GenericButton };
