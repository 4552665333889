import {
  ACTION_PROMOED_ADD_PAGE,
  ACTION_PROMOTED_DISABLE_LOAD_MORE,
  ACTION_PROMOTED_ENABLE_LOAD_MORE,
  ACTION_FILTER_PROMOTED_BRANDS,
  ACTION_GET_PROMOTED_BRANDS_STATISTICS_SUCCESS,
  ACTION_RESET_FILTER_PROMOTED_BRANDS,
  ACTION_PROMOTED_RESET_PAGE,
  ACTION_PROMOTRED_SUB_PAGE,
  getPromotedBrandsAction,
} from "../../constants";
import { IStore } from "../../axios/getBrands";
import { action, payload } from "ts-action";

export const getPromotedBrands = action(getPromotedBrandsAction.requested);
export const getPromotedBrandsSuccess = action(
  getPromotedBrandsAction.fulfilled,
  payload<IStore[]>()
);
export const getPromotedBrandsStatisticsSuccess = action(
  ACTION_GET_PROMOTED_BRANDS_STATISTICS_SUCCESS,
  payload<IStore[]>()
);
export const getPromotedBrandsFailure = action(
  getPromotedBrandsAction.rejected,
  payload<Error>()
);
export const filterBrands = action(
  ACTION_FILTER_PROMOTED_BRANDS,
  payload<any>()
);
export const resetFilterBrands = action(ACTION_RESET_FILTER_PROMOTED_BRANDS);
export const addPage = action(ACTION_PROMOED_ADD_PAGE);
export const subPage = action(ACTION_PROMOTRED_SUB_PAGE);
export const resetPage = action(ACTION_PROMOTED_RESET_PAGE);
export const enableLoadMore = action(ACTION_PROMOTED_ENABLE_LOAD_MORE);
export const disableLoadMore = action(ACTION_PROMOTED_DISABLE_LOAD_MORE);
