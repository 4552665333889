import Axios from "../axios";
import { getContactTemplateURL } from "../requests";

export const getContactTemplateApi = (token: string, template_code: number) => {
  return Axios.get(getContactTemplateURL, {
    headers: { token },
    params: { template_code },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
