import { getBrandPosLoyaltyIntegrationStatusSuccess } from "../../redux-store/actions";
import {
  LoyaltyPosIntegrationStatus,
  OrderingPosIntegrationStatus,
} from "../../pages/PosIntegration/types";
import { IBrandPosEntitiesState } from "../../redux-store/reducers/getBrandPosEntitiesReducer";

interface IAction<P> {
  type: string;
  payload: P;
}
export const updateBrandPosIntegrationStatus = (
  state: IBrandPosEntitiesState,
  action: IAction<{
    pos_entity_id: string;
    status: OrderingPosIntegrationStatus | LoyaltyPosIntegrationStatus;
  }>
): IBrandPosEntitiesState => {
  const { payload, type } = action;
  const integrationStatusField =
    type === getBrandPosLoyaltyIntegrationStatusSuccess.type
      ? "loyalty_integration_status"
      : "ordering_integration_status";
  return {
    ...state,
    brandPosEntitiesById: {
      ...state.brandPosEntitiesById,
      [payload.pos_entity_id]: {
        ...state.brandPosEntitiesById[payload.pos_entity_id],
        [integrationStatusField]: payload.status,
      },
    },
  };
};
