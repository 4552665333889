import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getPromotedBrandsFailure,
  getPromotedBrandsSuccess,
} from "../actions/promotedBrandsAction";
import { IStore } from "../../axios/getBrands";
import { selectToken } from "../selectors";
import { getPromotedBrandsAction } from "../../constants";
import { IGetBrandsAction } from "../../types";
import { getPromotedBrands } from "../../axios/getPromotedBrands";

export interface IGroupedData {
  grouping_criteria: string;
  stores: IStore[];
}

function* promotedBrandsSaga(action: IGetBrandsAction) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getPromotedBrands, token, { page: 0 });
    yield put(getPromotedBrandsSuccess(res));
  } catch (e) {
    yield put(getPromotedBrandsFailure(e));
  }
}

export function* watchPromotedBrandsSaga() {
  yield takeLatest(getPromotedBrandsAction.requested, promotedBrandsSaga);
}
