import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  createBannerCard,
  createBannerCardFailure,
  createBannerCardSuccess,
} from "../actions/bannerCardsActions";
import { createBannerCardAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { createBannerCardAPI } from "../../axios/createBannerCard";
import {
  SystemAnnouncementTargeting,
  SystemAnnouncementType,
} from "../../types";
// react-notify-toast
import { notify } from "react-notify-toast";

const actionType = union({ createBannerCard });

export const appendFormData = (
  formData: FormData,
  name: string,
  value?: string | Blob,
  fileName?: string
) => {
  if (value instanceof File) {
    return formData.append(name, value, fileName || value.name);
  }
  return value && formData.append(name, value);
};

export const convertFileToString = (file: File) => {
  return new Promise<string>((res) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      res(fileReader.result as string);
    };
    fileReader.readAsText(file);
  });
};

export const countCSVRows = async (csvFile: File) => {
  const csvText = await convertFileToString(csvFile);
  const linesCount = csvText.split("\n").length;
  return linesCount - 1;
};

function* createBannerCardSaga({ payload }: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const formData = new FormData();
    const append = appendFormData.bind(null, formData);

    append("image", payload.image);
    append("type", SystemAnnouncementType.screen);
    append("visibility", payload.visibility);
    append("value", payload.value);
    append("ordering_promotion_id", payload.ordering_promotion_id);
    if (typeof payload.meta_data === "object" && payload.meta_data !== null) {
      for (const key in payload.meta_data) {
        if (payload.meta_data.hasOwnProperty(key)) {
          const value = payload.meta_data[key];
          formData.append(`meta_data[${key}]`, value);
        }
      }
    }
    let target_users;
    if (payload.visibility === SystemAnnouncementTargeting.private) {
      append("customers_file", payload.customers_file, "customers.csv");
      target_users = yield call(countCSVRows, payload.customers_file as File);
    }
    const res = yield call(createBannerCardAPI, token, formData);
    notify.show("Banner created successfully", "success");

    return { ...payload, ...res.data, customers_file: undefined, target_users };
  } catch (error) {
    notify.show("Banner creation failed", "error");
  }
}

export function* watchCreateBannerCardSaga() {
  yield takeLatest(
    createBannerCardAction.requested,
    handleOptimism(createBannerCardSaga, {
      success: createBannerCardSuccess,
      failure: createBannerCardFailure,
    })
  );
}
