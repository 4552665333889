import {
  ACTION_CLEAR_PREV_CASHBACK_DETAILS,
  createCashbackDetailsAction,
  editCashbackDetailsAction,
  getCashbackDetailsAction,
} from "../../constants";
import { action, payload } from "ts-action";

export interface ICashbackDetails {
  id: string;
  cashback_merchant_share: any;
  cashback_koinz_share: any;
  promo_share_max_cpo: any;
  cashback_merchant_share_start_date: number;
  cashback_merchant_share_end_date: number;
}

// get
export const getCashbackDetails = action(
  getCashbackDetailsAction.requested,
  payload<{ store_id: string }>()
);
export const getCashbackDetailsSuccess = action(
  getCashbackDetailsAction.fulfilled,
  payload<{cashback_details?: ICashbackDetails}>()
);
export const getCashbackDetailsFailure = action(
  getCashbackDetailsAction.rejected,
  payload<Error>()
);

// create

export const createCashbackDetails = action(
  createCashbackDetailsAction.requested,
  payload<
    Omit<ICashbackDetails, "id" | "cashback_koinz_share"> & { store_id: string }
  >()
);
export const createCashbackDetailsSuccess = action(
  createCashbackDetailsAction.fulfilled,
  payload<ICashbackDetails>()
);
export const createCashbackDetailsFailure = action(
  createCashbackDetailsAction.rejected,
  payload<Error>()
);

// edit
export const editCashbackDetails = action(
  editCashbackDetailsAction.requested,
  payload<Omit<ICashbackDetails, "id"> & { store_id: string }>()
);
export const editCashbackDetailsSuccess = action(
  editCashbackDetailsAction.fulfilled,
  payload<ICashbackDetails>()
);
export const editCashbackDetailsFailure = action(
  editCashbackDetailsAction.rejected,
  payload<Error>()
);

export const clearPrevCashbackDetails = action(ACTION_CLEAR_PREV_CASHBACK_DETAILS)
