import React, { useEffect, useMemo } from "react";
import * as styles from "./style.module.sass";
import { Drawer } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { reduxForm, formValueSelector, InjectedFormProps } from "redux-form";
import Loader from "react-loader-spinner";
import { required, code_split } from "../../../../redux-store/validators";
import { connect } from "react-redux";
import { IRootReducerState } from "../../../../redux-store/rootReducer";
import { GenericButtonWithoutIcon } from "../../../SharedComponent/GenericButtonWithoutIcon";
import { pointsSelectItems } from "./lib";
import { SelectStoresByCountry } from "./SelectStoresByCountry";
import { SettingsSwitch } from "../../../SharedComponent/settingsSwitch";
import {
  IPromocodeForm,
  OrderingTypes,
  PromocodeTarget,
  PromoMerchantVisibility,
  PromoTypes,
  ToggleStatus,
} from "../../../TargetedTools/types";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
import { useSelect } from "../../../../hooks/useSelect";
import { useGetHighestRank, useGetPromocodeByRank } from "./promocode.hooks";
import { OverwriteRankingPopup } from "../../components";
import { useForm, Controller } from "react-hook-form";
import { DropDownSelect } from "../../../../Components/FormElements/NoRedux/DropDownSelect";
import { SpecialDatePicker } from "../../../../Components/FormElements/NoRedux/SpecialDatePicker";
import { CustomPostfixField } from "../../../../Components/FormElements/NoRedux/CustomPostfixField";
import CustomSelect from "../../../FormElements/NoRedux/CustomSelect";
import { GenericFileUploadField } from "../../../../Components/FormElements/NoRedux/GenericFileUploadField";
import { selectPromocodeWatchers } from "../helpers/selectPromocodeWatchers";
import { usePromocodeForm } from "../hooks/usePromocodeForm";
import {
  Tab,
  Tabs,
  PromoTypesInputs,
  PromocodeVisibility,
  DefaultPromocodeInputs,
  Input,
  MaskedPromocodeToggle,
  MaskedPromoShares,
  MissingBrandCsvValues,
  SplitAll,
} from "../components";
import { selectCountryISOCode } from "../helpers/selectCountryISOCode";
import { PROMO_TYPE_LIST } from "../helpers/lib";
import { usePromocodeFromSubmit } from "../hooks/usePromocodeFromSubmit";
import { DatePicker } from "../../../FormElements/NoRedux/DatePicker";
import { useDeepCompareMemo } from "use-deep-compare";
import { setPromocodeInitialValues } from "../helpers/setPromocodeInitialValues";
import { useMissingBrandCsvValues } from "../hooks/useMissingBrandCsvValues";
import { useCurrency } from "../hooks/useCurrency";

const AddPromoCode: React.FC<IPromocodeForm & InjectedFormProps<any>> = (
  props
) => {
  const {
    open,
    close,
    valid_promo_code,
    isEditing,
    isDuplicating,
    stores_ids,
    viewOnly,
    ordering_promotion_id,
    initialValues,
    countryStores,
    promotionStoresIsLoading,
    isReactivating,
    onSubmitPromoTypes,
  } = props;
  const { token, promoCodeValues } = useSelect((state) => ({
    ...state.authReducer,
    promoCodeValues: state.form?.AddPromoCodeForm?.values,
    countries: state.countriesReducer.countries,
  }));
  const { loading } = useSelect((state) => state.SystemPromoCodesReducer);
  const country_iso_code: any = selectCountryISOCode();
  const {
    setValue,
    control,
    watch,
    formState,
    handleSubmit,
    reset,
    setError,
    clearErrors,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: useDeepCompareMemo(() => {
      return {
        ...setPromocodeInitialValues({
          ...initialValues,
          stores_ids: promoCodeValues?.stores,
        }),
      };
    }, [initialValues, promoCodeValues?.stores]),
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    delayError: undefined,
  });

  // watchers
  const watchers = selectPromocodeWatchers(watch);
  const allReactHookFormFields = watch();

  // promo code custom hook
  const {
    handleDrawerClose,
    handleOrderingTypeChange,
    handleChangePromoType,
    handlePromocodeTargetChange,
    promocodeTarget,
    promocodeSplit,
    setPromocodeSplit,
    submitButtonDisabled,
    handleMaskedPromocode,
    handleExclusivePromo,
    selectedStoresMadeForSplit,
    setApplyCapping,
    applyCapping,
    setApplyMinEligibleInvoice,
    applyMinEligibleInvoice,
    applyMaxCashbackValue,
    setApplyMaxCashbackValue,
    setApplyExpiresAfter,
    applyExipresAfter,
  } = usePromocodeForm({
    reset,
    close,
    setValue,
    watchers,
    clearErrors,
    setError,
    stores_ids,
    isDuplicating: Boolean(isDuplicating),
    initialValues,
    isReactivating: Boolean(isReactivating),
    isEditing: Boolean(isEditing),
    masked:
      watchers.promo_merchant_visibility === PromoMerchantVisibility.masked,
    countryStores,
    country_iso_code,
  });
  useEffect(() => {
    setValue("country_iso_code", country_iso_code);
  }, [country_iso_code]);
  const checkPromocodeRank = useGetPromocodeByRank(token, {
    rank: watchers.rank,
    country_iso_code,
  });
  // Highest rank to validate using it ( for ex: max number in rank field <= highestRank)
  const { data: highestRank } = useGetHighestRank(token);
  const {
    handleCloseOverwriteRankingPopup,
    handleFormSubmit,
    handleOverwriteRankSubmit,
    invalidRank,
    setInvalidRank,
  } = usePromocodeFromSubmit({
    checkPromocodeRankData: {
      promo_code: checkPromocodeRank?.data?.promo_code,
      rank: checkPromocodeRank?.data?.rank,
    },
    country_iso_code,
    highestRank,
    initialValues,
    onSubmitPromoTypes,
    promo_id: ordering_promotion_id,
    promocodeTarget,
    watchers,
    handleDrawerClose,
    submittingStatus: loading,
  });
  const {
    missingBrandCsvValues,
    setShowMissingCsvValuesCard,
    showMissingCsvValuesCard,
  } = useMissingBrandCsvValues({ watchers });
  const promocodeCondition = {
    masked: PromoTypes.multiplier,
    visible: PromoTypes.cashback_percentage,
  };
  const promocodeOptions = useDeepCompareMemo(() => {
    return PROMO_TYPE_LIST.filter(
      (promo_type) =>
        promo_type.value !==
        promocodeCondition[watchers.promo_merchant_visibility]
    );
  }, [watchers.promo_merchant_visibility]);

  const setMaxDiscountValuesInitialValues = (store_id) => {
    return watchers.max_discount_values &&
      watchers.max_discount_values[store_id]
      ? watchers.max_discount_values[store_id]
      : 0;
  };
  const setApplyMinEligibleInvoiceInitialValues = (store_id) => {
    return watchers.min_threshold_values &&
      watchers.min_threshold_values[store_id]
      ? watchers.min_threshold_values[store_id]
      : 0;
  };
  const setApplyMaxCashbackInitialValues = (store_id) => {
    return watchers.max_cashback_values &&
      watchers.max_cashback_values[store_id]
      ? watchers.max_cashback_values[store_id]
      : 0;
  };

  const setApplyExpiresAfterInitialValues = (store_id) => {
    return watchers.expires_after_values &&
      watchers.expires_after_values[store_id]
      ? watchers.expires_after_values[store_id]
      : 72;
  };
  const currency = useCurrency(watchers.country_iso_code);
  const promo_id = useMemo(() => {
    if ((isEditing || isReactivating) && initialValues.id) {
      return initialValues.id;
    }
    return undefined;
  }, [isEditing, isReactivating, initialValues?.id]);

  const countryCodeInitialValue = useMemo(() => {
    if (initialValues?.country_iso_code) {
      return initialValues.country_iso_code.toLowerCase().includes("eg")
        ? "+2"
        : "+966";
    }
    return undefined;
  }, [initialValues?.country_iso_code]);

  return (
    <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
      <div className={styles.wrapper}>
        {promotionStoresIsLoading ? (
          <div className={styles.loader}>
            <Loader type="Puff" color="#f09440" height={100} width={100} />
          </div>
        ) : (
          <>
            <div className={styles.header}>
              <span className={styles.headerText}>
                {props.formLabel ?? "Add promo code"}{" "}
              </span>
              <CloseIcon className={styles.headerIcon} onClick={close} />
            </div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div
                style={{
                  padding: "0 3.2rem",
                }}
              >
                <SelectStoresByCountry
                  country_iso_code={countryCodeInitialValue}
                  promo_id={promo_id}
                  isDuplicating={isDuplicating}
                  isReactivating={isReactivating}
                  watch={watch}
                  isEditing={isEditing}
                  setValue={setValue}
                  disabled={isReactivating}
                  validateCountry={[required]}
                  validateStores={[required]}
                  formName={props.form}
                  value={watchers.stores_ids}
                  setCheckBoxValue={setPromocodeSplit}
                  setApplyCapping={setApplyCapping}
                  setApplyMinEligibleInvoice={setApplyMinEligibleInvoice}
                />
              </div>
              {!!watchers.stores_ids?.length && (
                <>
                  <DefaultPromocodeInputs
                    countryISOCode={country_iso_code}
                    highestRank={highestRank}
                    setError={setError}
                    isReactivating={Boolean(isReactivating)}
                    control={control}
                    isEditing={Boolean(isEditing)}
                    watchers={watchers}
                    valid_promo_code={valid_promo_code}
                  />
                  {!isReactivating && (
                    <PromocodeVisibility
                      promoType={watchers.type}
                      isEditing={Boolean(isEditing)}
                      control={control}
                      watchers={watchers}
                      isReactivating={Boolean(isReactivating)}
                      isDuplicating={Boolean(isDuplicating)}
                      setValue={setValue}
                    />
                  )}
                  <MaskedPromocodeToggle
                    checked={Boolean(watchers.isExclusivePromocode)}
                    label="This is an exclusive promo"
                    onChange={handleExclusivePromo}
                    showInfoIcon={true}
                    disabled={isReactivating || isEditing}
                    tooltipText="Hide the promo code in the app, granting exclusive usage to those who know it"
                  />
                  {invalidRank && (
                    <OverwriteRankingPopup
                      setInvalidRank={setInvalidRank}
                      promoCodeValues={allReactHookFormFields}
                      handleOverwrite={handleOverwriteRankSubmit}
                      promo_code={checkPromocodeRank?.data?.promo_code}
                      rank={watchers.rank}
                      open={invalidRank}
                      onClose={handleCloseOverwriteRankingPopup}
                    />
                  )}
                  <div className={styles.promoType}>
                    {!isEditing && (
                      <div className={styles.promoTypeWrapper}>
                        <span className={styles.promoTypeDropdownTitle}>
                          Promo code type
                        </span>
                        <div style={{ marginBottom: "24px" }} />
                        <DropDownSelect
                          label={"Promo code type"}
                          options={promocodeOptions}
                          value={watchers.type}
                          onBlur={() => {}}
                          className={styles.promoTypeDropdown}
                          name={"type"}
                          onChange={handleChangePromoType}
                        />
                      </div>
                    )}

                    {["invisible", "masked"].includes(
                      watchers.promo_merchant_visibility
                    ) &&
                      watchers.type !== PromoTypes.multiplier &&
                      !isEditing &&
                      !isReactivating && (
                        <MaskedPromocodeToggle
                          label="This is a masked promo"
                          checked={
                            watchers.promo_merchant_visibility ===
                            PromoMerchantVisibility.masked
                              ? true
                              : false
                          }
                          onChange={handleMaskedPromocode}
                        />
                      )}
                    {watchers.type && (
                      <>
                        {watchers.type === PromoTypes.multiplier && (
                          <div className={styles.pointsMultiplierWrapper}>
                            <Controller
                              name={"multiplier_value"}
                              control={control}
                              rules={{
                                required: {
                                  value:
                                    watchers.type === PromoTypes.multiplier,
                                  message:
                                    "Points multiplier value is required",
                                },
                              }}
                              render={({
                                field: { onChange, value, ref, onBlur },
                                fieldState: { invalid, error, isTouched },
                              }) => (
                                <>
                                  <DropDownSelect
                                    onBlur={onBlur}
                                    name="multiplier_value"
                                    options={pointsSelectItems}
                                    label="Points multiplier value"
                                    className={styles.select}
                                    disabled={isReactivating || isEditing}
                                    value={value}
                                    onChange={(v) => (v ? onChange(v) : {})}
                                    error={error || invalid}
                                    touched={isTouched}
                                  />
                                </>
                              )}
                            />
                          </div>
                        )}
                        <PromoTypesInputs
                          watchers={watchers}
                          setError={setError}
                          isEditing={Boolean(isEditing)}
                          isReactivating={Boolean(isReactivating)}
                          initialValues={initialValues}
                          control={control}
                        />

                        {(isEditing || isReactivating) && (
                          <div style={{ marginTop: "24px" }}>
                            <Controller
                              name={"end_time"}
                              control={control}
                              render={({
                                field: { onChange, value, ref, onBlur },
                                fieldState: { invalid, error, isTouched },
                              }) => (
                                <>
                                  <DatePicker
                                    timeFormat={true}
                                    onlyTodayAndAfter={isReactivating}
                                    onBlur={onBlur}
                                    name="end_time"
                                    label={"End date"}
                                    className={styles.select}
                                    value={value || undefined}
                                    onChange={(v) => onChange(v)}
                                    error={error || invalid}
                                    touched={isTouched}
                                  />
                                </>
                              )}
                            />
                          </div>
                        )}
                        {!isEditing && !isReactivating && (
                          <div className={styles.priceWrapper}>
                            <Controller
                              name={"start_time"}
                              control={control}
                              render={({
                                field: { onChange, value, ref, onBlur },
                                fieldState: { invalid, error, isTouched },
                              }) => (
                                <div
                                  style={{ width: "100%", marginRight: "20px" }}
                                >
                                  <SpecialDatePicker
                                    timeFormat={true}
                                    is_start={true}
                                    onBlur={onBlur}
                                    name="start_time"
                                    label="Start date"
                                    className={styles.select}
                                    noValidation={true}
                                    value={value || undefined}
                                    onChange={(e) => onChange(e)}
                                    touched={isTouched}
                                    validDate={error || invalid ? false : true}
                                  />
                                </div>
                              )}
                            />

                            <Controller
                              name={"end_time"}
                              control={control}
                              render={({
                                field: { onChange, value, ref, onBlur },
                                fieldState: { invalid, error, isTouched },
                              }) => (
                                <div style={{ width: "100%" }}>
                                  <SpecialDatePicker
                                    timeFormat={true}
                                    is_start={false}
                                    noValidation={true}
                                    onBlur={onBlur}
                                    name="end_time"
                                    label="End date"
                                    className={styles.select}
                                    value={value || undefined}
                                    onChange={(e) => onChange(e)}
                                    touched={isTouched}
                                    validDate={error || invalid ? false : true}
                                  />
                                </div>
                              )}
                            />
                            <br />
                            {formState?.errors &&
                              formState?.errors?.start_time?.message && (
                                <span className={styles.dateError}>
                                  {formState?.errors?.start_time?.message}
                                </span>
                              )}
                          </div>
                        )}

                        {watchers.type === PromoTypes.multiplier ? (
                          <>
                            <div
                              style={{ borderBottom: "1px solid #e6e6e6" }}
                              className={`${styles.SwitchWrapper} ${
                                watchers.suggested === ToggleStatus.enable
                                  ? styles.activePreselected
                                  : styles.inactivePreselected
                              }`}
                            >
                              <div className={styles.SwitchContent}>
                                <span className={styles.SwitchSpan}>
                                  This is a Preselected promocode
                                </span>
                                <p className={styles.SwitchP}>
                                  This will appears to Koinz app users as a
                                  suggested promocodes before checkout
                                </p>
                              </div>

                              <Controller
                                name={"suggested"}
                                control={control}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <SettingsSwitch
                                    disabled={
                                      viewOnly || isEditing || isReactivating
                                    }
                                    onColor={"#f09440"}
                                    offColor={"#fff"}
                                    className={styles.switch}
                                    checked={value === ToggleStatus.enable}
                                    onChange={(v) =>
                                      onChange(
                                        v
                                          ? ToggleStatus.enable
                                          : ToggleStatus.disable
                                      )
                                    }
                                  />
                                )}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <br />
                        {watchers.stores_ids.length > 1 && (
                          <SplitAll
                            applyExpiresAfter={applyExipresAfter}
                            setApplyExpiresAfter={setApplyExpiresAfter}
                            applyMaxCashbackValue={applyMaxCashbackValue}
                            setApplyMaxCashbackValue={setApplyMaxCashbackValue}
                            setValue={setValue}
                            applyMinEligibleInvoice={applyMinEligibleInvoice}
                            setApplyMinEligibleInvoice={
                              setApplyMinEligibleInvoice
                            }
                            setApplyCapping={setApplyCapping}
                            applyCapping={applyCapping}
                            setPromocodeSplit={setPromocodeSplit}
                            promocodeSplit={promocodeSplit}
                            watchers={watchers}
                            control={control}
                          />
                        )}
                        <>
                          {watchers.stores_ids?.length &&
                            (((!applyCapping || !applyMinEligibleInvoice) &&
                              watchers.promo_merchant_visibility !==
                                PromoMerchantVisibility.masked &&
                              watchers.type === PromoTypes.percentage) ||
                              !promocodeSplit ||
                              (watchers.promo_merchant_visibility !==
                                PromoMerchantVisibility.masked &&
                                watchers.type ===
                                  PromoTypes.cashback_percentage &&
                                (!applyExipresAfter ||
                                  !applyMaxCashbackValue ||
                                  !applyMinEligibleInvoice ||
                                  !promocodeSplit))) &&
                            selectedStoresMadeForSplit.map((store, index) => {
                              return (
                                <div
                                  className={styles.promoCodeSplitWrapper}
                                  key={store.value}
                                >
                                  {(!promocodeSplit ||
                                    !applyCapping ||
                                    !applyMinEligibleInvoice ||
                                    !applyExipresAfter ||
                                    !applyMaxCashbackValue) && (
                                    <div className={styles.brandInfoWrapper}>
                                      {store.img && (
                                        <img
                                          className={styles.brandImage}
                                          src={
                                            process.env.WIZARD_LUMEN_ENDPOINT +
                                            "/" +
                                            store.img
                                          }
                                        />
                                      )}
                                      <span className={styles.storeLabel}>
                                        {store.label}
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    className={styles.Wrapper}
                                    key={store.value}
                                  >
                                    {!promocodeSplit && (
                                      <Controller
                                        name={`stores_fund_percentage.${store.value}`}
                                        control={control}
                                        rules={{
                                          validate: (v) => code_split(v),
                                        }}
                                        render={({
                                          field: {
                                            onChange,
                                            value,
                                            ref,
                                            onBlur,
                                          },
                                          fieldState: {
                                            invalid,
                                            error,
                                            isTouched,
                                          },
                                        }) => (
                                          <>
                                            <CustomPostfixField
                                              label="$0.00"
                                              onBlur={onBlur}
                                              value={
                                                watchers.stores_fund_percentage &&
                                                watchers.stores_fund_percentage[
                                                  store.value
                                                ]?.brand
                                                  ? watchers
                                                      .stores_fund_percentage[
                                                      store.value
                                                    ]
                                                  : {
                                                      brand: 0,
                                                      koinz: 100,
                                                    }
                                              }
                                              onChange={onChange}
                                              error={error || invalid}
                                              touched={isTouched}
                                              type="number"
                                              name={`stores_fund_percentage.${store.value}`}
                                              placeholder={"$0.00"}
                                              postFix={"%"}
                                              className={`${styles.additionalPice}`}
                                            />
                                          </>
                                        )}
                                      />
                                    )}
                                    {watchers.promo_merchant_visibility ===
                                      PromoMerchantVisibility.masked &&
                                      !promocodeSplit && (
                                        <MaskedPromoShares
                                          isEditing={isEditing}
                                          initialValue={{
                                            masked_values:
                                              watchers.masked_values &&
                                              watchers.masked_values[
                                                store.value
                                              ]
                                                ? watchers.masked_values[
                                                    store.value
                                                  ]
                                                : 0,
                                            masked_promo_codes:
                                              watchers.masked_promo_codes &&
                                              watchers.masked_promo_codes[
                                                store.value
                                              ]
                                                ? watchers.masked_promo_codes[
                                                    store.value
                                                  ]
                                                : "",
                                          }}
                                          control={control}
                                          setError={setError}
                                          masked_promo_codes_name={
                                            `masked_promo_codes.${store.value}` as string
                                          }
                                          masked_values_name={
                                            `masked_values.${store.value}` as string
                                          }
                                        />
                                      )}
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <React.Fragment>
                                        {!applyCapping &&
                                          watchers.promo_merchant_visibility !==
                                            PromoMerchantVisibility.masked &&
                                          watchers.type ===
                                            PromoTypes.percentage && (
                                            <Input
                                              initialValue={setMaxDiscountValuesInitialValues(
                                                store.value
                                              )}
                                              type={"number"}
                                              currency={currency}
                                              setError={setError}
                                              control={control}
                                              name={
                                                `max_discount_values.${store.value}` as string
                                              }
                                              label={"Capping"}
                                              isEditing={isEditing}
                                              rules={{
                                                required: {
                                                  value: true,
                                                  message:
                                                    "Capping value is required",
                                                },
                                              }}
                                              placeholder="30 L.E"
                                              mainClassName={
                                                styles.cappingInput
                                              }
                                            />
                                          )}
                                      </React.Fragment>
                                      {!applyCapping &&
                                        !applyMinEligibleInvoice &&
                                        watchers.type ===
                                          PromoTypes.percentage &&
                                        watchers.promo_merchant_visibility !==
                                          PromoMerchantVisibility.masked && (
                                          <div
                                            style={{ marginRight: "20px" }}
                                          />
                                        )}
                                      <React.Fragment>
                                        {!applyMinEligibleInvoice &&
                                          watchers.promo_merchant_visibility !==
                                            PromoMerchantVisibility.masked &&
                                          (watchers.type ===
                                            PromoTypes.percentage ||
                                            watchers.type ===
                                              PromoTypes.cashback_percentage) && (
                                            <Input
                                              initialValue={setApplyMinEligibleInvoiceInitialValues(
                                                store.value
                                              )}
                                              type={"number"}
                                              currency={currency}
                                              setError={setError}
                                              control={control}
                                              name={
                                                `min_threshold_values.${store.value}` as string
                                              }
                                              label={"Min eligible invoice"}
                                              isEditing={isEditing}
                                              rules={{
                                                required: {
                                                  value: true,
                                                  message:
                                                    "Min eligible invoice is required",
                                                },
                                              }}
                                              placeholder="30 L.E"
                                              mainClassName={
                                                styles.cappingInput
                                              }
                                            />
                                          )}
                                      </React.Fragment>
                                      <React.Fragment>
                                        {!applyMaxCashbackValue &&
                                          watchers.promo_merchant_visibility !==
                                            PromoMerchantVisibility.masked &&
                                          watchers.type ===
                                            PromoTypes.cashback_percentage && (
                                            <React.Fragment>
                                              {!applyMinEligibleInvoice && (
                                                <div
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                              )}
                                              <Input
                                                initialValue={setApplyMaxCashbackInitialValues(
                                                  store.value
                                                )}
                                                type={"number"}
                                                currency={currency}
                                                setError={setError}
                                                control={control}
                                                name={
                                                  `max_cashback_values.${store.value}` as string
                                                }
                                                label={"Max cashback value"}
                                                isEditing={isEditing}
                                                rules={{
                                                  required: {
                                                    value: true,
                                                    message:
                                                      "Max cashback value is required",
                                                  },
                                                }}
                                                placeholder="30 L.E"
                                                mainClassName={
                                                  styles.cappingInput
                                                }
                                              />
                                            </React.Fragment>
                                          )}
                                      </React.Fragment>
                                    </div>
                                    <React.Fragment>
                                      {!applyExipresAfter &&
                                        watchers.promo_merchant_visibility !==
                                          PromoMerchantVisibility.masked &&
                                        watchers.type ===
                                          PromoTypes.cashback_percentage && (
                                          <React.Fragment>
                                            <div
                                              style={{ marginRight: "10px" }}
                                            />
                                            <Input
                                              initialValue={setApplyExpiresAfterInitialValues(
                                                store.value
                                              )}
                                              type={"number"}
                                              currency={"Hours"}
                                              setError={setError}
                                              control={control}
                                              name={
                                                `expires_after_values.${store.value}` as string
                                              }
                                              label={"Expired after"}
                                              isEditing={isEditing}
                                              rules={{
                                                required: {
                                                  value: true,
                                                  message:
                                                    "Expired after is required",
                                                },
                                                min: {
                                                  value: 1,
                                                  message:
                                                    "Expired after should be greater than or equal 1",
                                                },
                                              }}
                                              placeholder="30 L.E"
                                              mainClassName={
                                                styles.cappingInput
                                              }
                                            />
                                          </React.Fragment>
                                        )}
                                    </React.Fragment>
                                  </div>
                                </div>
                              );
                            })}
                        </>

                        {!isEditing && (
                          <>
                            <p className={styles.promocodeSettingsTitle}>
                              Promocode settings
                            </p>
                            <div className={styles.promoCodeType}>
                              <span className={styles.span}>
                                Promocode ordering type
                              </span>
                              <Tabs>
                                <Tab
                                  style={{ borderRadius: "4px 0 0 4px" }}
                                  state={
                                    watchers.ordering_type ===
                                    OrderingTypes.ordering
                                  }
                                  onClick={() => {
                                    handleOrderingTypeChange(
                                      OrderingTypes.ordering
                                    );
                                  }}
                                  title={"All"}
                                />
                                <Tab
                                  style={{
                                    borderRadius: 0,
                                    borderRight: 0,
                                    borderLeft: 0,
                                  }}
                                  state={
                                    watchers.ordering_type ===
                                    OrderingTypes.delivery
                                  }
                                  onClick={() => {
                                    handleOrderingTypeChange(
                                      OrderingTypes.delivery
                                    );
                                  }}
                                  title={"Delivery Only"}
                                />
                                <Tab
                                  style={{ borderRadius: " 0 4px 4px 0 " }}
                                  state={
                                    watchers.ordering_type ===
                                    OrderingTypes.pickup
                                  }
                                  onClick={() => {
                                    handleOrderingTypeChange(
                                      OrderingTypes.pickup
                                    );
                                  }}
                                  title={"Pickup Only"}
                                />
                              </Tabs>
                            </div>
                            {/* promocode appearance */}
                            <div className={styles.promoCodeType}>
                              <span className={styles.span}>
                                Promocode target
                              </span>
                              <Tabs>
                                <Tab
                                  style={{ borderRadius: "4px 0 0 4px" }}
                                  state={
                                    promocodeTarget === PromocodeTarget.normal
                                  }
                                  onClick={() =>
                                    handlePromocodeTargetChange(
                                      PromocodeTarget.normal
                                    )
                                  }
                                  title={"Targeted"}
                                />
                                <Tab
                                  style={{
                                    borderRadius: 0,
                                    borderRight: 0,
                                    borderLeft: 0,
                                  }}
                                  state={
                                    promocodeTarget ===
                                    PromocodeTarget.universal
                                  }
                                  onClick={() =>
                                    handlePromocodeTargetChange(
                                      PromocodeTarget.universal
                                    )
                                  }
                                  title={"Universal"}
                                />
                                <Tab
                                  style={{ borderRadius: " 0 4px 4px 0 " }}
                                  state={
                                    promocodeTarget ===
                                    PromocodeTarget.compensational
                                  }
                                  onClick={() =>
                                    handlePromocodeTargetChange(
                                      PromocodeTarget.compensational
                                    )
                                  }
                                  title={"Compensational"}
                                />
                              </Tabs>
                            </div>

                            {/* case of compensational promo */}
                            {promocodeTarget ===
                              PromocodeTarget.compensational && (
                              <div
                                className={styles.promocodeDurationInputWrapper}
                              >
                                <Input
                                  setError={setError}
                                  name={"expiry_days"}
                                  control={control}
                                  rules={{
                                    min: {
                                      value: 1,
                                      message: "Should be more than 0",
                                    },
                                  }}
                                  type="number"
                                  days="days"
                                  label="Promocode duration"
                                  placeholder={"0"}
                                />
                              </div>
                            )}
                          </>
                        )}
                        <React.Fragment>
                          {!isReactivating && (
                            <React.Fragment>
                              {promocodeTarget === PromocodeTarget.universal ? (
                                <React.Fragment>
                                  {!isEditing && (
                                    <div className={styles.SelectUsersWrapper}>
                                      <p className={styles.text}>
                                        Select users type
                                      </p>

                                      <Controller
                                        name={"targeting_method"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({
                                          field: { onChange, value },
                                          fieldState: { invalid, error },
                                        }) => (
                                          <CustomSelect
                                            value={
                                              value ?? watchers.targeting_method
                                            }
                                            onChange={onChange}
                                          />
                                        )}
                                      />
                                    </div>
                                  )}
                                </React.Fragment>
                              ) : (
                                <div className={styles.fileWrapper}>
                                  {!isReactivating && (
                                    <Controller
                                      name={"target_customers"}
                                      control={control}
                                      rules={{
                                        required: isEditing ? false : true,
                                      }}
                                      render={({
                                        field: { onChange, value },
                                        fieldState: { invalid, error },
                                      }) => (
                                        <GenericFileUploadField
                                          name="target_customers"
                                          buttonText={"Upload CSV file"}
                                          disabled={isReactivating}
                                          value={value}
                                          onChange={onChange}
                                          error={error}
                                        />
                                      )}
                                    />
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      </>
                    )}
                  </div>
                  {showMissingCsvValuesCard && (
                    <MissingBrandCsvValues
                      showError={showMissingCsvValuesCard}
                      closeError={() => setShowMissingCsvValuesCard(false)}
                      errors={missingBrandCsvValues}
                    />
                  )}
                </>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "24px",
                }}
              >
                <GenericButtonWithoutIcon
                  loading={loading === LoadingStatus.loading}
                  spinnerColor={"white"}
                  className={styles.submitBtn}
                  disabled={submitButtonDisabled({ formState, loading })}
                  label={(props.formLabel as string) ?? "Add promo code"}
                  type="submit"
                />
              </div>
            </form>
          </>
        )}
      </div>
    </Drawer>
  );
};

const AddPromoCodeForm = reduxForm<{}, IPromocodeForm>({
  form: "AddPromoCodeForm",
})(AddPromoCode as any);

const selector = formValueSelector("AddPromoCodeForm");
const mapStateToProps = (state: IRootReducerState) => ({
  countries: state.countriesReducer.countries,
  countryStores: state.CountryStoresReducer.stores,
  valid_promo_code: state.StorePromoCodesReducerAvailability.valid_promo_code,
  ...selector(state, "country_iso_code", "store", "stores_ids", "stores"),
});
export default connect(mapStateToProps)(AddPromoCodeForm) as any;