import * as React from "react";
import * as styles from "../styles.module.sass";
import { DirectedText } from "../../DirectedText";

interface IProps {
  placeholder: string;
  disabled?: boolean;
  className: string;
  value?: string;
  touched?: boolean;
  error?: any;
  name?: string;
  onChange?: (e) => void;
}
export class TextAreaGenericField extends React.Component<
  IProps & { label: string }
> {
  public render() {
    const {
      disabled,
      label,
      className,
      placeholder,
      value,
      error,
      name,
      touched,
      onChange,
    } = this.props;

    const isInvalid = touched && error;
    const isValid = touched && !error;
    return (
      <div className={`${styles.textAreaWrapper} ${className}`}>
        <label htmlFor={name} className={styles.textAreaLabel}>
          <span>{label}</span>
        </label>
        <DirectedText
          // style={{ width: "100%" }}
          text={value}
          aria-disabled={disabled}
        >
          <textarea
            disabled={disabled}
            className={styles.textArea}
            onChange={onChange}
            value={value}
            autoComplete="off"
            style={{
              // ...(active ? { borderColor: "#fdbb11" } : {}),
              ...(isValid ? { borderColor: "#38c172" } : {}),
              ...(isInvalid ? { borderColor: "#db3030" } : {}),
              ...(disabled ? { cursor: "not-allowed" } : {}),
            }}
            id={name}
            placeholder={placeholder}
          />
        </DirectedText>
        {error && <span className={styles.formError}>{error.message}</span>}
      </div>
    );
  }
}
