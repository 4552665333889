import axios from "./axios";
import * as Requests from "./requests";

interface IApiKey {
  api_key: string;
}

export const exportFoodicsMenu = (api_key: IApiKey) => {
  return axios.get(Requests.exportFoodicsMenuURL, {
    baseURL: process.env.POS_ENDPOINT,
    headers: {
      "api-key": api_key.api_key,
    },
  });
};
