import { getContactTemplatesAction } from "../../constants";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { IContactTemplate } from "../../types";
import { getContactTemplatesSuccess, resetAppState } from "../actions";

interface IContactTemplatesState {
  contact_templates: IContactTemplate[];
  contactTemplatesById: { [x: number]: IContactTemplate };
}
export const getContactTemplatesReducer = withLoadingReducer<
  IContactTemplatesState
>(
  reducer<IContactTemplatesState>(
    [
      on(resetAppState, () => ({
        contact_templates: [],
        contactTemplatesById: {},
      })),
      on(getContactTemplatesSuccess, (state, { payload }) => ({
        ...state,
        contact_templates: payload,
        contactTemplatesById: payload.reduce(
          (acc, next) => ({ ...acc, [next.id]: next }),
          {}
        ),
      })),
    ],
    {
      contact_templates: [],
      contactTemplatesById: {},
    }
  ),
  getContactTemplatesAction
);
