import { on, reducer } from "ts-action";
import { mapById, convertToIds } from "./lib";
import {
  ILoadingState,
  withLoadingReducer,
} from "../../../../redux-store/reducers/withLoadingState";
import { getStoreTagsAction } from "../actions/constants";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
import {
  IStoreTag,
  getStoreTagSuccess,
  createStoreTagSuccess,
  createStoreTag,
  deleteStoreTag,
  editStoreTag,
} from "../actions/tags-actions";

interface IState {
  tags: string[];
  tagsById: { [x: string]: IStoreTag };
}

export const tagsReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(getStoreTagSuccess, (state, { payload }) => ({
          ...state,
          tags: convertToIds(payload),
          tagsById: mapById(payload),
        })),
        on(createStoreTagSuccess, (state, { payload, meta }) => {
          const optId = meta.optimistic.id.toString();
          return {
            ...state,
            tagsById: {
              ...state.tagsById,
              [payload.id]: payload,
            },
            tags: state.tags.map((id) => (optId === id ? payload.id : id)),
          };
        }),
        on(createStoreTag, (state, action) => {
          const id = action.meta.optimistic.id.toString();
          return {
            ...state,
            tags: [id, ...state.tags],
            tagsById: {
              ...state.tagsById,
              [id]: action.payload,
            },
          };
        }),
        on(editStoreTag, (state, action) => {
          const oldTagData = state.tagsById[action.payload.id];

          return {
            ...state,
            tags: state.tags,
            tagsById: {
              ...state.tagsById,
              [action.payload.id]: {
                ...oldTagData,
                ...action.payload,
              },
            },
          };
        }),
        on(deleteStoreTag, (state, action) => {
          return {
            ...state,
            tags: state.tags.filter((i) => i !== action.payload.id),
          };
        }),
      ],
      { tags: [], tagsById: {} }
    ),
    getStoreTagsAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
