import { loadingReducerGenerator } from "./loadingReducerGenerator";
import { mutateState } from "../../helpers/mutate-state";

export function generateStatsReducer<A>(genericAction) {
  interface IBrandStatsItem {
    number: number;
  }

  const initialState: IBrandStatsItem = {
    number: 0,
  };
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case genericAction.fulfilled: {
        return mutateState(state, (map) => {
          map.set("number", action.payload);
        });
      }
      default:
        return state;
    }
  };
  return loadingReducerGenerator<IBrandStatsItem, A>(genericAction, reducer);
}
