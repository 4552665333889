import React from "react";
import { WrappedFieldProps } from "redux-form";
import { ISelectItemData } from "../../../../FormElements/RenderDropDownSelect";
import * as styles from "./styles.module.sass";

interface IProps extends WrappedFieldProps {
  label: string;
  options: ISelectItemData<number | string>[];
}
interface IRadioButtonItemProps {
  option: ISelectItemData;
  onClick: (value: any) => void;
  isActive: boolean;
  invalid: boolean;
}
const RadioButtonItem: React.FC<IRadioButtonItemProps> = ({
  option,
  onClick,
  isActive,
  invalid,
}) => {
  const handleSelect = React.useCallback(() => onClick(option.value), [
    option.value,
    onClick,
  ]);
  return (
    <button
      onClick={handleSelect}
      className={`${styles.radioButton} ${isActive ? styles.active : ""} ${
        invalid ? styles.invalid : ""
      }`}
    >
      {option.label}
    </button>
  );
};

interface IFieldUIWrapperProps {
  label: string;
  isInvalid: boolean;
  error?: string;
}

export const FieldUIWrapper: React.FC<IFieldUIWrapperProps> = ({
  children,
  isInvalid,
  error,
  label,
}) => {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      {children}
      {isInvalid && <p className={styles.errorMsg}>{error}</p>}
    </div>
  );
};

export const RenderMultipleRadioButtonsField: React.FC<IProps> = ({
  label,
  options,
  input,
  meta,
}) => {
  const handleSelectItem = React.useCallback(
    (value: any) => {
      input.onChange(value);
    },
    [input.onChange]
  );

  // const optionsMap = React.useMemo(() => options.reduce((acc, next) => {
  //   return {
  //     ...acc,
  //     [next.value]: next
  //   }
  // }, {}),[options]);
  const isInvalid = meta.touched && meta.invalid;

  return (
    <FieldUIWrapper label={label} isInvalid={isInvalid} error={meta.error}>
      <div className={styles.radioButtonsWrapper}>
        {options.map((option) => {
          return (
            <RadioButtonItem
              invalid={isInvalid}
              isActive={input.value === option.value}
              onClick={handleSelectItem}
              option={option}
              key={option.value}
            />
          );
        })}
      </div>
    </FieldUIWrapper>
  );
};
