import { useDispatch } from "react-redux";
import { useSelect } from "../../../hooks/useSelect";
import { useEffect, useMemo, useState } from "react";
import {
  createBrandPosEntity,
  updatePosEntitiesLoadingStatus,
  updateBrandPosEntity,
  exportBrandPosMenu,
  setBrandPosEntity,
} from "../../../redux-store/actions";
import { LoadingStatus } from "../../../redux-store/reducers/withLoadingState";
import { ISelectItemData } from "../../../Components/FormElements/Select";
import { IBrandPosEntity } from "../../../redux-store/reducers/getBrandPosEntitiesReducer";

export const useBrandPosEntityHandlers = ({
  brandPosEntity,
  store_id,
}: {
  brandPosEntity: IBrandPosEntity;
  store_id: string;
}) => {
  const dispatch = useDispatch();
  const {
    posProviders,
    status,
    brand,
  } = useSelect((state) => ({
    posProviders: state.posProvidersReducer.posProviders,
    ...state.getBrandPosEntitiesReducer,
    brand: state.brandDetailsReducer.BRAND_DETAILS,
  }));
  const [brandPosEntityValues, setBrandPosEntityValues] = useState<{
    name: string;
    pos_vendor: string;
  }>({
    name: brandPosEntity.name,
    pos_vendor: brandPosEntity.pos_vendor,
  });

  useEffect(() => {
    setBrandPosEntityValues({
      name: brandPosEntity.name,
      pos_vendor: brandPosEntity.pos_vendor,
    });
  }, [brandPosEntity]);
  const handleSaveChanges = () => {
    switch (brandPosEntity.mode) {
      case "create":
        dispatch(
          createBrandPosEntity({
            store_id,
            name: brandPosEntityValues.name,
            pos_vendor: brandPosEntityValues.pos_vendor,
          })
        );
        break;
      case "edit":
        dispatch(
          updateBrandPosEntity({
            store_id,
            id: brandPosEntity.id,
            ...brandPosEntityValues,
          })
        );
        break;
      default:
        break;
    }
  };

  const handlePosNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrandPosEntityValues({ ...brandPosEntityValues, name: e.target.value });
  };
  const handlePosEntityChange = ({ value }: ISelectItemData) => {
    setBrandPosEntityValues({ ...brandPosEntityValues, pos_vendor: value });
  };
  const handleBrandPosMenuExport = () => {
    dispatch(
      exportBrandPosMenu({
        api_key: brandPosEntity.api_key,
        pos_entity_id: brandPosEntity.id,
      })
    );
  };
  const posEntityInitialValue = useMemo(
    () =>
      posProviders.find(
        (posProvider) => posProvider.value === brandPosEntityValues.pos_vendor
      ),
    [brandPosEntityValues.pos_vendor, posProviders]
  );
  const posEntitiesOptions = useMemo(
    () =>
      posProviders.map((element) => ({
        value: element.value,
        text: element.label,
      })),
    [posProviders]
  );

  useEffect(() => {
    if (status === LoadingStatus.success) {
      dispatch(setBrandPosEntity({id: brandPosEntity.id, mode: undefined}));
      dispatch(updatePosEntitiesLoadingStatus(undefined));
    }
  }, [status]);

  useEffect(() => {
    if (brandPosEntity.JSONFileLoadingStatus === LoadingStatus.success) {
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(brandPosEntity.JSONFileContent));
      const downloadAnchorElem = document.getElementById("downloadAnchorElem");
      if (downloadAnchorElem) {
        downloadAnchorElem.setAttribute("href", dataStr);
        downloadAnchorElem.setAttribute(
          "download",
          `${brand?.name} - ${brandPosEntity.name} menu.json`
        );
        downloadAnchorElem.click();
      }
    }
  }, [brandPosEntity.JSONFileContent, brandPosEntity.JSONFileLoadingStatus]);
  return {
    handleSaveChanges,
    handlePosNameChange,
    handlePosEntityChange,
    handleBrandPosMenuExport,
    brandPosEntityValues,
    posEntityInitialValue,
    posEntitiesOptions,
    status,
    setBrandPosEntityValues,
  };
};
