import * as constants from "../../constants";
import { Map } from "immutable";
import { mutateState } from "../../helpers/mutate-state";
import { union } from "ts-action";
import {
  getReachQuotas,
  getReachQuotasFailure,
  getReachQuotasSuccess,
} from "../actions/getReachQuotasActions";
import { IReachQuotasResponse } from "../actions/getReachQuotasActions";

const COMPLETED = "completed";
const IN_PROGRESS = "inProgress";
const FAILED = "failed";
const QUOTAS = "QUOTAS";
const TOTAL_QUOTAS = "totalQuotas";

export interface IReachQuotasReducerState {
  [COMPLETED]: boolean;
  [IN_PROGRESS]: boolean;
  [FAILED]: boolean;
  [QUOTAS]: IReachQuotasResponse[];
  [TOTAL_QUOTAS]: number;
}

const initialState: IReachQuotasReducerState = Map({
  [COMPLETED]: false,
  [IN_PROGRESS]: false,
  [FAILED]: false,
  [QUOTAS]: [],
  [TOTAL_QUOTAS]: 0,
}).toJS();

const allActions = union({
  getReachQuotas,
  getReachQuotasSuccess,
  getReachQuotasFailure,
});
type actions = typeof allActions;
const reachQuotasReducer = (
  state: IReachQuotasReducerState = initialState,
  action: actions
): IReachQuotasReducerState => {
  switch (action.type) {
    case constants.getReachQuotasAction.fulfilled: {
      return mutateState(state, (map) => {
        map.set(COMPLETED, true);
        map.set(FAILED, false);
        map.set(IN_PROGRESS, false);
        map.set(TOTAL_QUOTAS, (action.payload as any).total_quotas);
        const data = (action.payload as any).quotas;
        map.set(QUOTAS, data);
      });
    }
    case constants.getReachQuotasAction.requested: {
      return mutateState(state, (map) => {
        map.set(COMPLETED, false);
        map.set(FAILED, false);
        map.set(IN_PROGRESS, true);
      });
    }
    case constants.getReachQuotasAction.rejected: {
      return mutateState(state, (map) => {
        map.set(COMPLETED, false);
        map.set(FAILED, true);
        map.set(IN_PROGRESS, false);
      });
    }
    default:
      return state;
  }
};

export default reachQuotasReducer;
