import {
  ContactMethodType,
  IContactMethod,
  IActionCenterBrandsState,
} from "../types";
import ic_message from "../assets/ic_message_circle.svg";
import ic_whatsapp from "../assets/ic_whatsapp.svg";
// import ic_email from "../assets/ic_mail.svg";

export const actionCenterAlerts: { [x: number]: string } = {
  0: "Transaction drop",
  1: "Orders drop",
  2: "Fulfilment rate",
};
export const contactMethodLabels: { [x: number]: string } = {
  1: "whatsapp",
  2: "SMS",
  // 3: "email",
};
export const contactMethods: IContactMethod[] = [
  {
    value: ContactMethodType.Whatsapp,
    label: "WhatsApp",
    icon: ic_whatsapp,
  },
  {
    value: ContactMethodType.SMS,
    label: "SMS",
    icon: ic_message,
  },
  // {
  //   value: ContactMethodType.Email,
  //   label: "Email",
  //   icon: ic_email,
  // },
];

export const actionCenterBrandsInitialState: IActionCenterBrandsState = {
  brandsById: {},
  brandsIds: [],
  shownBrandsTasksIds: [],
  shownBrandsTasksById: {},
  selectedBrandsTasksIds: [],
  selectedBrandsById: {},
  alert_types: [],
  account_manager_id: "",
  selected_store_id: "",
  resolve_all: true,
  contactTemplatesById: {},
};

export const PocRoles: Record<string, string> = {
  it_manager: "IT manager",
  operations_manager: "Operations manager",
  marketing_manager: "Marketing manager",
};