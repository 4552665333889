import React, { useState, useEffect } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { GenericButton } from "../../../SharedComponent/GenericButtonWithIcon";
import EditCommission from "./EditCommission";
import { useSelect } from "../../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { getCommissions } from "../../../TargetedTools/redux-store/actions/commissions";
interface IProps {
  store_id: string;
}
const Commission: React.FC<IProps> = ({ store_id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCommissions(store_id));
  }, [store_id]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { commissions } = useSelect((state) => state.commissionsReducer);
  return (
    <div className={styles.wrapper}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={styles.labelWrapper}>
          <span className={styles.label}>Ordering commission</span>
          <div className={styles.iconWrapper}>
            <GenericButton
              iconPath={require("../../../../assets/edit.svg")}
              className={styles.btn}
              IconClass={styles.icon}
              onClick={handleOpen}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={styles.pickupWrapper}>
            <span className={styles.pickupTitle}>Pickup</span>
            <span className={styles.pickupPercentage}>
              {commissions.pickup_commission || 0}%
            </span>
          </div>
          <div className={styles.pickupWrapper}>
            <span className={styles.pickupTitle}>Delivery</span>
            <span className={styles.pickupPercentage}>
              {commissions.delivery_commission || 0}%
            </span>
          </div>
          <div className={styles.pickupWrapper}>
            <span className={styles.pickupTitle}>Delivery Share</span>
            <span className={styles.pickupPercentage}>
              {commissions.delivery_share || 0}
              {commissions.delivery_share_type === "percentage" ? "%" : " EGP"}
            </span>
          </div>
        </div>
      </div>

      {open && (
        <EditCommission
          initialValues={{ ...commissions }}
          store_id={store_id}
          onClose={handleClose}
          open={open}
        />
      )}
    </div>
  );
};

export default hot(module)(Commission);
