import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./index.sass";
import persistedStore from "./redux-store/configureStore";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/fonts/stylesheet.css";
import "./assets/icon-fonts";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { QueryClientProvider, QueryClient } from "react-query";
import { RecoilRoot } from "recoil";
async function init() {
  const { store } = await persistedStore();
  const queryClient = new QueryClient();

  ReactDOM.render(
    <DragDropContextProvider backend={HTML5Backend}>
      <RecoilRoot>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Provider>
      </RecoilRoot>
    </DragDropContextProvider>,
    document.getElementById("root") as HTMLDivElement
  );
}

init();
