import * as React from "react";
import { Aux } from "../../../App";
import * as styles from "./styles.module.sass";

interface ISeeMoreProps {
  text: string;
  limit: number;
}

interface ISeeMoreState {
  isShowingMore: boolean;
}

export class SeeMore extends React.Component<ISeeMoreProps, ISeeMoreState> {
  public state = {
    isShowingMore: false,
  };

  public readMore = () => this.setState({ isShowingMore: true });
  public readLess = () => this.setState({ isShowingMore: false });

  public render() {
    const { isShowingMore } = this.state;
    const { limit, text } = this.props;

    return (
      <Aux>
        {isShowingMore || text.length < limit ? text : text.slice(limit)}
        {text.length > limit ? (
          isShowingMore ? (
            <span className={styles.readMore} onClick={this.readLess}>
              read less
            </span>
          ) : (
            <span className={styles.readMore} onClick={this.readMore}>
              ...read more
            </span>
          )
        ) : (
          ""
        )}
      </Aux>
    );
  }
}
