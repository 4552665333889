import React from "react";
import * as styles from "./styles.module.sass";

interface IProps {
  onChoosePlan: (isTrial: boolean) => void;
}
interface IState {
  isTrial: boolean;
}

export default class ChoosePlan extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isTrial: false,
    };
  }

  public setTrialPlan = (e: any) => {
    e.stopPropagation();
    this.setState({ isTrial: true }, () => {
      this.props.onChoosePlan(this.state.isTrial);
    });
  };
  public setPaidPlan = (e: any) => {
    e.stopPropagation();
    this.setState({ isTrial: false }, () => {
      this.props.onChoosePlan(this.state.isTrial);
    });
  };

  public render() {
    return (
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Choose activation plan</h1>
        <div className={styles.cardsWrapper}>
          <div onClick={this.setTrialPlan} className={styles.card}>
            <img
              className={styles.img}
              src={require("../../assets/trial.svg")}
            />
            <p className={styles.plan}>Trial plan</p>
          </div>
          <div onClick={this.setPaidPlan} className={styles.card}>
            <img
              className={styles.img}
              src={require("../../assets/paid.svg")}
            />
            <p className={styles.plan}>Paid plan</p>
          </div>
        </div>
      </div>
    );
  }
}
