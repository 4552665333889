import React, { useEffect, useMemo, useState } from "react";
import { IStore } from "src/axios/getBrands";
import styles from "../../styles.module.sass";
import { ISelectionList } from "../../types";
import { useDispatch } from "react-redux";
import { editPromotedBrand } from "../../../../../redux-store/actions/edtitPromotedBrandActions";
import { useSelect } from "../../../../../hooks/useSelect";
import { IRootReducerState } from "src/redux-store/rootReducer";
import { useDeepCompareEffect } from "use-deep-compare";
import { ClipLoader } from "react-spinners";
const SelectionList: React.FC<ISelectionList> = ({
  list,
  open,
}: ISelectionList) => {
  const dispatch = useDispatch();
  const [promoted_brands, set_promoted_brands] = useState<IStore[]>([]);
  const [non_promoted_brands, set_non_promoted_brands] = useState<IStore[]>([]);
  const [brandToBePromoted, setBrandToBePromoted] = useState<IStore | null>(
    null
  );
  const makeBrandPromoted = ({
    store,
    body,
  }: {
    store: IStore;
    body: {
      promoted_status: "add" | "remove";
    };
  }) => {
    dispatch(
      editPromotedBrand({
        store,
        body,
      })
    );
  };
  const { promotedBrands, isLoading } = useSelect(
    (state: IRootReducerState) => ({
      promotedBrands: state.promotedBrandsReducer.brandsIds,
      isLoading: state.promotedBrandsReducer.isLoading,
    })
  );
  const [isSelected, setIsSelected] = useState<string>("");

  const isActionLoading = useMemo(() => isLoading === "loading" && isSelected, [
    isLoading,
    isSelected,
  ]);
  useDeepCompareEffect(() => {
    set_promoted_brands(
      list.filter(
        (item) => item.hasOwnProperty("promoted_at") && item.promoted_at
      )
    );
    set_non_promoted_brands(
      list.filter(
        (item) => item.hasOwnProperty("promoted_at") && !item.promoted_at
      )
    );
  }, [list]);

  useEffect(() => {
    set_promoted_brands([...promoted_brands, brandToBePromoted as IStore]);
    set_non_promoted_brands([
      ...non_promoted_brands.filter(
        (item) => item.id !== brandToBePromoted?.id
      ),
    ]);
  }, [promotedBrands]);

  useEffect(() => {
    if (isLoading !== "loading") {
      setIsSelected("");
    }
  }, [isLoading]);
  return open ? (
    <div className={styles.selectionList}>
      {non_promoted_brands.length > 0 && (
        <div className={styles.sectionWrapper}>
          <div className={styles.title}> Brands </div>
          <div className={styles.itemWrapper}>
            {non_promoted_brands.map((item: IStore) => (
              <div key={item?.id} className={styles.item}>
                <div className={styles.itemTitle}>
                  {item && item?.en_name ? item?.en_name : item?.name}
                </div>
                <div className={styles.itemAction}>
                  {isActionLoading && isSelected === item.id ? (
                    <div className={styles.loaderWrapper}>
                      <ClipLoader color=" #f09440" size={45} />
                    </div>
                  ) : (
                    <button
                      className={`${styles.btn} ${styles.active}`}
                      type={"button"}
                      onClick={() => {
                        setIsSelected(item.id);
                        setBrandToBePromoted(item);
                        makeBrandPromoted({
                          store: item,
                          body: {
                            promoted_status: "add",
                          },
                        });
                      }}
                    >
                      <span className={styles.icon}>
                        <img
                          className={styles.imageIcon}
                          src={require("../../../../../assets/add.svg")}
                        />{" "}
                      </span>
                      Add
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {promoted_brands.length > 0 && (
        <div className={styles.sectionWrapper}>
          <div className={styles.title}> Added </div>
          <div className={styles.itemWrapper}>
            {promoted_brands.map((item: IStore) => (
              <div key={item?.id} className={styles.item}>
                <div className={styles.itemTitle}>
                  {" "}
                  {item && item?.en_name ? item?.en_name : item?.name}
                </div>
                <div className={styles.itemAction}>
                  <button
                    className={`${styles.btn} ${styles.inactive}`}
                    type={"button"}
                    disabled={!!item?.promoted_at}
                  >
                    <span className={styles.icon}>
                      {" "}
                      <img
                        src={require("../../../../../assets/icon-yes.svg")}
                      />{" "}
                    </span>
                    Added
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!promoted_brands.length && !non_promoted_brands.length && (
        <div className={styles.notFound}>
          <span>No brands to be shown ....</span>
        </div>
      )}
    </div>
  ) : null;
};

export default SelectionList;
