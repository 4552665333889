import React, { useCallback, useEffect, useRef } from "react";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";

const RenderFileField: React.FC<WrappedFieldProps & { label: string }> = ({
  input,
  label,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      return input.onChange(e.target.files);
    },
    [input]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && !input.value) {
      inputRef.current.value = "";
    }
  }, [input.value]);

  return (
    <div className={styles.fileWrapper}>
      <span>{label}</span>
      <label htmlFor={input.name} className={styles.labelFile}>
        <span>{label}</span>
        <input
          ref={inputRef}
          onChange={handleChange}
          className={styles.file}
          id={input.name}
          accept={".csv"}
          type={"file"}
          hidden={true}
        />
      </label>
    </div>
  );
};
export default RenderFileField;
