import React from "react";
import styles from "./styles.module.sass";

interface IProps {
  className?: string;
  label: string;
  editable?: boolean;
  editOnClick?: () => void;
}
export const GenericCard: React.FC<IProps> = ({
  editable,
  label,
  children,
  className,
  editOnClick,
}) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{label}</span>
        {editable && (
          <button onClick={editOnClick} className={styles.iconWrapper}>
            <img
              className={styles.icon}
              src={require("../../../../assets/feather _ edit.svg")}
            />
          </button>
        )}
      </div>
      {children}
    </div>
  );
};

export default GenericCard;
