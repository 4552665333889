import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export interface ILoginRequestData {
  email: string;
  password: string;
}

export const login = (
  data: ILoginRequestData
): AxiosReturn<{ token: string }> => axios.post(Requests.login, data);
