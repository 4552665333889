import axios from "./axios";
import * as Requests from "./requests";

export const editCommissionsApi = (
  token: string,
  store_id: string,
  pickup_commission: number,
  delivery_commission: number,
  delivery_share: number,
  delivery_share_type: string
) => {
  return axios.put(
    Requests.commissionUrl(store_id),
    {
      pickup_commission,
      delivery_commission,
      delivery_share,
      delivery_share_type,
    },
    {
      headers: {
        token,
      },
      baseURL: process.env.PICKUP_ENDPOINT,
    }
  );
};
