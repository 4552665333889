import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  getStoreTagFailure,
  getStoreTagSuccess,
} from "../actions/tags-actions";
import { selectToken } from "../../../../redux-store/selectors";
import { getStoreTags } from "../../axios/getStoreTags";
import { getStoreTagsAction } from "../actions/constants";

function* getStoreTagsSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getStoreTags, token);
    yield put(getStoreTagSuccess(res.data));
  } catch (e) {
    yield put(getStoreTagFailure(e));
  }
}
export function* watchGetStoreTagsSaga() {
  yield takeLatest(getStoreTagsAction.requested, getStoreTagsSaga);
}
