import axios from "./axios";
import * as Requests from "./requests";

export const getCampaignBranches = (token: string, campaign_id: string) => {
  return axios.get(Requests.campaignBranches, {
    baseURL: process.env.LUMEN_ENDPOINT,
    params: {
      campaign_id,
    },
    headers: {
      token,
    },
  });
};
