// import axios from "axios";
// import * as Requests from "./requests";

// export const getPromotedSectionTitle = async (token: string) => {
//   const { data } = await axios.get(Requests.getPromotedSectionTitle, {
//     headers: { token },
//     baseURL: process.env.LUMEN_ENDPOINT,
//   });
//   return data;
// };

import { IPromotedBrandsSectionTitle } from "..//redux-store/actions/promotedSectionTitleAction";

export const getPromotedSectionTitle: (
  token: string
) => Promise<IPromotedBrandsSectionTitle> = (token: string) =>
  new Promise((resolve, reject) => {
    resolve({
      en_title: "Newly Joined",
      ar_title: "انضم حديثاً",
    });
  });
