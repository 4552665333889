import { DotLoader } from "react-spinners";
import React from "react";
import * as styles from "./styles.module.sass";

export const LoadingPageInfo = () => {
  return (
    <div className={styles.loadingWrapper}>
      <DotLoader
        className={styles.override}
        sizeUnit={"px"}
        size={150}
        color={"#FEBE19"}
      />
    </div>
  );
};
