import * as styles from "./styles.module.sass";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

export interface IOption<T extends unknown> {
  label: T;
  value: T;
}
interface IProps<T> {
  label?: string;
  dropdownLabel?: string;
  placeholder?: string;
  searchLabel?: string;
  selectAll?: boolean;
  selectedLabel?: string;
  dispatchingFunctions: (value?: T) => T;
  justify?: "center" | "flex-end" | "flex-start";
  options: IOption<T>[];
  onChange: (
    values: T[],
    setValues: React.Dispatch<React.SetStateAction<T[]>>,
    id: T
  ) => void;
}

export const MultipleSelect = <T,>({
  label,
  options,
  onChange,
  dispatchingFunctions,
  dropdownLabel,
  placeholder,
  selectedLabel,
  searchLabel,
  justify,
  selectAll,
}: PropsWithChildren<IProps<T>>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [values, setValues] = useState<T[]>([] as T[]);

  const handleClick = (id: T) => {
    onChange(values, setValues, id);
  };
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    if (options.length > 0) {
      const newValues = values.filter(
        (v) => options.findIndex((option) => option.value === v) > -1
      );

      setValues(newValues);
    }
  }, [options]);

  useEffect(() => {
    if (options.length > 0) {
      const newValues = values.filter(
        (v) => options.findIndex((option) => option.value === v) > -1
      );
      setValues(newValues);
    }
  }, [options]);

  useEffect(() => {
    setSearchValue("");
  }, [options]);

  const getSelectedOneName = useMemo(() => {
    return ((options ?? []) as IOption<T>[]).find((o) => o.value === values[0])
      ?.label;
  }, [options, values]);
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const selectOrUnSelectAll = () => {
    if (values.length === options.length) {
      setValues([]);
    } else {
      setValues(options.map((option) => option.value));
    }
    dispatchingFunctions();
  };

  useEffect(() => {
    if (selectAll) {
      setValues(options.map((option) => option.value));
    }
  }, [selectAll, options]);

  return (
    <div className={styles.wrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.dropdown}>
        <div style={{ width: "100%" }}>
          <Dropdown
            style={{ display: "flex", justifyContent: justify }}
            direction="up"
            isOpen={isOpen}
            toggle={toggle}
          >
            <DropdownToggle className={styles.dropdownToggle}>
              <span className={styles.dropdownLabel}>{dropdownLabel}</span>{" "}
              <span className={styles.selectedValue}>
                {values.length === 0
                  ? "All"
                  : values.length === options.length
                  ? "All"
                  : values.length === 1
                  ? getSelectedOneName
                  : `${values.length} ${selectedLabel}`}
              </span>
              <img
                className={styles.dropdownIcon}
                style={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
                src={require("../../../../assets/feather _ chevron-down.svg")}
              />
            </DropdownToggle>

            <DropdownMenu className={styles.menu}>
              <div className={styles.searchBox}>
                <div className={styles.search}>
                  <input
                    className={styles.input}
                    value={searchValue}
                    placeholder={placeholder}
                    type="text"
                    onChange={handleSearchChange}
                  />
                  <img
                    src={require("../../../../assets/search.svg")}
                    className={styles.searchIcon}
                  />
                </div>
              </div>
              <div className={styles.menuItems}>
                {!searchValue && (
                  <span
                    className={styles.dropdownItem}
                    onClick={selectOrUnSelectAll}
                  >
                    <div
                      className={
                        values.length || values.length === options.length
                          ? styles.checkboxSelected
                          : styles.checkedBox
                      }
                    >
                      {/* icon-dash.svg */}
                      {(values.length || values.length === options.length) && (
                        <img
                          className={styles.checkboxIcon}
                          src={
                            values.length === options.length
                              ? require("../../../../assets/icon-yes.svg")
                              : require("../../../../assets/icon-dash.svg")
                          }
                        />
                      )}
                    </div>
                    Select All
                  </span>
                )}

                {options
                  .filter((option: any) =>
                    option.label
                      ?.toLowerCase()
                      .includes(searchValue.toLowerCase())
                  )
                  .map((otp: any) => (
                    <span
                      key={otp.value}
                      className={styles.dropdownItem}
                      onClick={() => handleClick(otp.value)}
                    >
                      <div
                        className={
                          values.includes(otp.value)
                            ? styles.checkboxSelected
                            : styles.checkedBox
                        }
                      >
                        {values.includes(otp.value) && (
                          <img
                            className={styles.checkboxIcon}
                            src={require("../../../../assets/icon-yes.svg")}
                          />
                        )}
                      </div>

                      {otp.label}
                    </span>
                  ))}
                {options.filter((option: any) =>
                  option.label.toLowerCase().includes(searchValue.toLowerCase())
                ).length === 0 && (
                  <span className={styles.notFound}>
                    No {searchLabel} Found
                  </span>
                )}
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default MultipleSelect;
