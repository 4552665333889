import * as React from "react";
import * as styles from "./styles.module.sass";
import { connect } from "react-redux";
import * as actions from "../../redux-store/actions";
import { hot } from "react-hot-loader";

interface IProps {
  login: (data) => void;
}

interface IState {
  email: string;
  password: string;
  emailShow: boolean;
  passwordShow: boolean;
}

class Login extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailShow: false,
      passwordShow: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleEmailChange(event) {
    this.setState({ email: event.target.value, emailShow: true });
  }

  public handlePasswordChange(event) {
    this.setState({ password: event.target.value, passwordShow: true });
  }

  public handleSubmit(event) {
    this.props.login({
      email: this.state.email.toLowerCase(),
      password: this.state.password,
    });
    event.preventDefault();
  }

  public render() {
    let formatError = "";

    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.state.email)) {
      if (this.state.passwordShow) {
        if (!this.state.password.length) {
          formatError = "password can't be empty";
        } else {
          formatError = "";
        }
      }
    } else {
      if (this.state.emailShow) {
        formatError = "Please enter valid email";
      }
      if (this.state.passwordShow && this.state.emailShow) {
        formatError = "please enter valid email and password";
      }
    }

    const formError =
      !formatError.length && this.state.passwordShow && this.state.emailShow;

    return (
      <div className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <img src={require("../../assets/logo.svg")} />
        </div>
        <form onSubmit={this.handleSubmit} className={styles.formWrapper}>
          <label className={styles.label} htmlFor={"email"}>
            Email
          </label>
          <input
            data-test-id="loginEmail"
            className={styles.input}
            maxLength={70}
            placeholder=" "
            name="email"
            id="email"
            type="text"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />

          <label className={styles.label}>Password</label>
          <input
            data-test-id="loginPassword"
            className={styles.input}
            maxLength={70}
            placeholder=" "
            name="password"
            type="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
          <button
            data-test-id="loginSubmit"
            disabled={!formError}
            className={formError ? styles.btn : styles.btnDisabled}
            type="submit"
          >
            Login &rarr;
          </button>
          <span
            style={{ fontSize: "1.6rem", color: "red", position: "relative" }}
          >
            {formatError}
          </span>
        </form>
        <div className={styles.lineWrapper}>
          Made with <img src={require("../../assets/no-love.svg")} />{" "}
          <img src={require("../../assets/keyboard.svg")} /> by ShopX team
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ state });

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(actions.login(data)),
});

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(Login));
