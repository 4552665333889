import { call, put, select, takeLatest } from "redux-saga/effects";
import { createCashbackDetailsApi } from "../../axios";
import { union } from "ts-action";
import { createCashbackDetailsAction } from "../../constants";
import {
  createCashbackDetails,
  createCashbackDetailsFailure,
  createCashbackDetailsSuccess,
} from "../actions";
import { selectToken } from "../selectors";
const actionTypes = union({ createCashbackDetails });
function* createCashbackDetailsSaga({
  payload,
}: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    yield call(createCashbackDetailsApi, token, payload);
    yield put(createCashbackDetailsSuccess({
        cashback_merchant_share_end_date: payload.cashback_merchant_share_end_date,
        cashback_merchant_share: payload.cashback_merchant_share,
        cashback_merchant_share_start_date: payload.cashback_merchant_share_start_date,
        id: payload.store_id,
        promo_share_max_cpo: payload.promo_share_max_cpo,
        cashback_koinz_share: Math.abs(100 - Number(payload.cashback_merchant_share))
    }));
  } catch (error) {
    yield put(createCashbackDetailsFailure(error));
  }
}

export function* watchCreateCashbackDetailsSaga() {
  yield takeLatest(createCashbackDetailsAction.requested, createCashbackDetailsSaga);
}
