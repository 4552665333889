import { on, reducer } from "ts-action";
import {
  getSMSCampaignsSuccess,
  ISMSCampaign,
} from "../actions/sms-campaigns-actions";
import { mapById } from "./lib";
import {
  ILoadingState,
  withLoadingReducer,
} from "../../../../redux-store/reducers/withLoadingState";
import { getSMSCampaignsAction } from "../actions/constants";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";

interface IState {
  smsCampaigns: string[];
  smsCampaignsById: { [x: string]: ISMSCampaign };
  total_sms_campaigns: number;
}

export const smsCampaignsReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(getSMSCampaignsSuccess, (state, { payload }) => ({
          total_sms_campaigns: payload.total_sms_campaigns,
          smsCampaigns: [...payload.sms_campaigns.map((i) => i.id)],
          smsCampaignsById: mapById(payload.sms_campaigns),
        })),
      ],
      { smsCampaigns: [], smsCampaignsById: {}, total_sms_campaigns: 0 }
    ),
    getSMSCampaignsAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
