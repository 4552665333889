import {
  IAddBrandAction,
  IAddBrandFailureAction,
  IAddBrandSuccessAction,
} from "../../types";
import { addBrandAction } from "../../constants";

export const addBrand = (isTrial: boolean): IAddBrandAction => ({
  type: addBrandAction.requested,
  payload: isTrial,
});

export const addBrandSuccess = (data: any): IAddBrandSuccessAction => ({
  type: addBrandAction.fulfilled,
  payload: data,
});

export const addBrandFailure = (error): IAddBrandFailureAction => ({
  type: addBrandAction.rejected,
  payload: error,
});
