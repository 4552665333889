import { IRootReducerState } from "./rootReducer";
import { ISelectItemData } from "../Components/FormElements/RenderDropDownSelect";

export const selectForm = (state: IRootReducerState) => state.form as any;
export const selectToken = (state: IRootReducerState): string =>
  state.authReducer.token;
export const selectLastPage = (state: IRootReducerState): number =>
  state.brandsReducer.page;
export const selectLastGroup = (state: IRootReducerState): string =>
  state.brandsReducer.group;
export const selectNextGroupCriteria = (
  state: IRootReducerState
): ISelectItemData => state.brandsReducer.nextGroupingCriteria;
