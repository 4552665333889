import React, { useEffect } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import * as moment from "moment";
import { RouteComponentProps } from "react-router";
import { useSelect } from "../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import {
  getBrandConversionRate,
  getBrandRegisteredCustomerNumber,
  getBrandTempCustomersNumber,
  getBrandVisitsNumber,
  getBrandRedemptionNumber,
  getBrandFailedOperationsNumber,
  getBrandRedemptionRate,
  getBrandReviewsNumber,
  getUnresolvedIssuesNumber,
  getCashbackDetails,
} from "../../../redux-store/actions";
import PCard from "../../../UIComponents/Card";
import ActiveMerchantDeals from "./ActiveMerchantDeals";
import ProfileSettingCards from "./ProfileSettingCards";
import CashbackDetails from "./CashbackDetails";
// import { ActivityLog } from "..";

const Card: React.FC<RouteComponentProps<{
  store_id: string;
  token: string;
}>> = (props) => {
  const dispatch = useDispatch();
  const { BRAND_DETAILS } = useSelect((state) => state.brandDetailsReducer);
  const {
    match: {
      params: { store_id },
    },
  } = props;
  const brandConversionRate = useSelect(
    (state) => state.brandConversionRateReducer
  );
  const numberOfRegisteredCustomers = useSelect(
    (state) => state.brandRegisteredCustomerNumberReducer
  );
  const numberOfTempCustomers = useSelect(
    (state) => state.brandTempCustomersNumberReducer
  );
  const visitsNumber = useSelect((state) => state.brandVisitsNumberReducer);
  const redemptionsNumber = useSelect(
    (state) => state.brandRedemptionNumberReducer
  );
  const failedOperationsNumber = useSelect(
    (state) => state.brandFailedOperationsNumberReducer
  );
  const redemptionRate = useSelect((state) => state.brandRedemptionRateReducer);
  const reviewsNumber = useSelect((state) => state.brandReviewsNumberReducer);
  const unresolvedIssuesNumber = useSelect(
    (state) => state.brandUnresolvedIssuesNumberReducer
  );

  const constructFilteringStat = (filter) => {
    if (filter.value === "last3days") {
      return {
        start_time: +moment().subtract(3, "day").startOf("day").format("x"),
        end_time: +moment().format("x"),
      };
    } else if (filter.value === "today") {
      return {
        start_time: +moment().startOf("day").format("x"),
        end_time: +moment().endOf("day").format("x"),
      };
    } else if (filter.value === "month") {
      return {
        start_time: +moment().startOf("month").format("x"),
        end_time: +moment().endOf("month").format("x"),
      };
    } else if (filter.value === "week") {
      return {
        start_time: +moment().startOf("week").format("x"),
        end_time: +moment().endOf("week").format("x"),
      };
    } else {
      return {
        start_time: undefined,
        end_time: undefined,
      };
    }
  };
  useEffect(() => {
    dispatch(
      getCashbackDetails({
        store_id,
      })
    );
    dispatch(
      getBrandConversionRate(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
    dispatch(
      getBrandRegisteredCustomerNumber(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
    dispatch(
      getBrandTempCustomersNumber(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
    dispatch(
      getBrandVisitsNumber(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
    dispatch(
      getBrandRedemptionNumber(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
    dispatch(
      getBrandFailedOperationsNumber(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
    dispatch(
      getBrandRedemptionRate(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
    dispatch(
      getBrandReviewsNumber(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
    dispatch(
      getUnresolvedIssuesNumber(store_id, {
        end_time: undefined,
        start_time: undefined,
      })
    );
  }, [store_id]);

  const onConversionRateFilterChange = (input) => {
    dispatch(getBrandConversionRate(store_id, constructFilteringStat(input)));
  };
  const onNumberOfRegisteredCustomersChange = (input) => {
    dispatch(
      getBrandRegisteredCustomerNumber(store_id, constructFilteringStat(input))
    );
  };
  const onNumberOfTempCustomersChange = (input) => {
    dispatch(
      getBrandTempCustomersNumber(store_id, constructFilteringStat(input))
    );
  };
  const onNumberOfTotalVisitsChange = (input) => {
    dispatch(getBrandVisitsNumber(store_id, constructFilteringStat(input)));
  };
  const onNumberOfRedemptionsChange = (input) => {
    dispatch(getBrandRedemptionNumber(store_id, constructFilteringStat(input)));
  };
  const onNumberOfFailedOperationsChange = (input) => {
    dispatch(
      getBrandFailedOperationsNumber(store_id, constructFilteringStat(input))
    );
  };
  const onRedemptionRateChange = (input) => {
    dispatch(getBrandRedemptionRate(store_id, constructFilteringStat(input)));
  };
  const onNumberOfReviewsChange = (input) => {
    dispatch(getBrandReviewsNumber(store_id, constructFilteringStat(input)));
  };

  return (
    <>
      <ProfileSettingCards store_id={store_id} BRAND_DETAILS={BRAND_DETAILS} />
      <div className={styles.activeMerchantDealsWrapper}>
        {/* <ActivityLog
          logs={[
            {
              id: "edfghjhdmfdkf",
              brand: "Ennabi",
              issued_at: "23/03/2023",
              issued_from: "Hazem",
              issued_to: "Hellawy",
            },
            {
              id: "34567898eihjsdy8we8e",
              brand: "Ennabi",
              issued_at: "23/03/2023",
              issued_from: "Hazem Hemaily",
              issued_to: "Abdelrahman Hellawy",
            },
          ]}
        /> */}
      </div>
      <div className={styles.activeMerchantDealsWrapper}>
        <ActiveMerchantDeals store_id={store_id} />
      </div>
      <div className={styles.activeMerchantDealsWrapper}>
        <CashbackDetails store_id={store_id} />
      </div>
      <div className={styles.brandProgressWrapper}>
        <div className={styles.rocketWrapper}>
          <img
            className={styles.rocket}
            src={require("../../../assets/icon_solid_rocket.svg")}
          />
        </div>{" "}
        <span className={styles.brandProgress}>
          {BRAND_DETAILS?.en_name
            ? BRAND_DETAILS?.en_name
            : BRAND_DETAILS?.ar_name || BRAND_DETAILS?.name}{" "}
          progress
        </span>
      </div>
      <div className={styles.cards}>
        {brandConversionRate.failed ? (
          <div
            style={{
              margin: "0 24px 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={brandConversionRate.loading}
            percent={true}
            key={1}
            number={+(brandConversionRate.number * 100).toFixed(4)}
            currentFilter={brandConversionRate}
            onChange={onConversionRateFilterChange}
            pathIndex={1}
            margin={"0 24px 24px 0"}
            title="Conversion rate"
          />
        )}
        {numberOfRegisteredCustomers.failed ? (
          <div
            style={{
              margin: "0 24px 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={numberOfRegisteredCustomers.loading}
            key={2}
            number={numberOfRegisteredCustomers.number}
            currentFilter={numberOfRegisteredCustomers}
            onChange={onNumberOfRegisteredCustomersChange}
            pathIndex={2}
            margin={"0 24px 24px 0"}
            title="Total customers"
          />
        )}
        {numberOfTempCustomers.failed ? (
          <div
            style={{
              margin: "0 0 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={numberOfTempCustomers.loading}
            key={3}
            number={numberOfTempCustomers.number}
            onChange={onNumberOfTempCustomersChange}
            currentFilter={numberOfTempCustomers}
            pathIndex={0}
            margin={"0 0 24px 0"}
            title="Temp. customers"
          />
        )}
        {visitsNumber.failed ? (
          <div
            style={{
              margin: "0 24px 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={visitsNumber.loading}
            key={4}
            number={visitsNumber.number}
            onChange={onNumberOfTotalVisitsChange}
            pathIndex={0}
            currentFilter={visitsNumber}
            margin={"0 24px 24px 0"}
            title="Total visits"
          />
        )}
        {redemptionsNumber.failed ? (
          <div
            style={{
              margin: "0 24px 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={redemptionsNumber.loading}
            key={5}
            number={redemptionsNumber.number}
            onChange={onNumberOfRedemptionsChange}
            pathIndex={3}
            currentFilter={redemptionsNumber}
            title="Total redemptions"
            margin={"0 24px 24px 0"}
          />
        )}
        {failedOperationsNumber.failed ? (
          <div
            style={{
              margin: "0 0 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={failedOperationsNumber.loading}
            key={6}
            number={failedOperationsNumber.number}
            currentFilter={failedOperationsNumber}
            onChange={onNumberOfFailedOperationsChange}
            error={true}
            pathIndex={5}
            title="Failed operations"
            margin={"0 0 24px 0"}
          />
        )}
        {redemptionRate.failed ? (
          <div
            style={{
              margin: "0 24px 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={redemptionRate.loading}
            percent={true}
            key={7}
            number={+(redemptionRate.number * 100).toFixed(4)}
            currentFilter={redemptionRate}
            onChange={onRedemptionRateChange}
            pathIndex={4}
            title="Redemption rate"
            margin={"0 24px 24px 0"}
          />
        )}
        {reviewsNumber.failed ? (
          <div
            style={{
              margin: "0 24px 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={reviewsNumber.loading}
            key={8}
            number={reviewsNumber.number}
            onChange={onNumberOfReviewsChange}
            pathIndex={0}
            currentFilter={reviewsNumber}
            title="Reviews number"
            margin={"0 24px 24px 0"}
          />
        )}

        {unresolvedIssuesNumber.failed ? (
          <div
            style={{
              margin: "0 0 24px 0",
            }}
            className={styles.wrapper}
          >
            <span>Failed to load</span>
          </div>
        ) : (
          <PCard
            loading={unresolvedIssuesNumber.loading}
            error={true}
            noFilter={true}
            key={9}
            number={unresolvedIssuesNumber.number}
            pathIndex={5}
            currentFilter={{ text: "All time", value: "all" }}
            title="Total unresolved issues number"
            margin={"0 0 24px 0"}
          />
        )}
      </div>
    </>
  );
};

export default hot(module)(Card);
