import Datetime from "react-datetime";
import * as React from "react";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import IcoMoon from "react-icomoon";
import "react-datetime/css/react-datetime.css";
import moment, { Moment } from "moment";

interface IProps {
  label: string;
  className: any;
  disabled: boolean;
  onlyTodayAndAfter: boolean;
}

export class RenderDatePicker extends React.Component<
  WrappedFieldProps & IProps
> {
  public render() {
    const onlyTodayAndAfter = (current: Moment) => {
      return current.isAfter(moment().startOf("day").subtract(1, "day"));
    };
    const {
      input,
      label,
      meta: { touched, error, valid, invalid, active },
      className,
    } = this.props;
    const isInvalid = touched && invalid;
    const isValid = touched && valid;

    return (
      <div
        className={
          className
            ? `${styles.fullWidthDate} ${
                typeof className === "string" ? className : ""
              }`
            : styles.datePickerWrapper
        }
      >
        <label htmlFor={input.name} className={styles.label}>
          <span>{label}</span>
          {touched && error && (
            <span className={styles.formError}>{error}</span>
          )}
        </label>
        <div
          className={`${styles.datePicker} ${active ? styles.active : ""}${
            isValid ? styles.valid : ""
          }${isInvalid ? styles.invalid : ""}`}
        >
          <Datetime
            className={`${active ? styles.active : ""} ${
              isValid ? styles.valid : ""
            } ${isInvalid ? styles.invalid : ""}`}
            disableOnClickOutside={true}
            inputProps={{
              readOnly: true,
              className: styles.dateInput,
              disabled: this.props.disabled,
            }}
            timeFormat={false}
            isValidDate={
              this.props.onlyTodayAndAfter ? onlyTodayAndAfter : undefined
            }
            value={input.value}
            defaultValue="MM/DD/YYYY"
            onChange={input.onChange}
          />
          <div className={styles.icon}>
            <IcoMoon icon="calendar" />
          </div>
        </div>
      </div>
    );
  }
}
