export interface IContactTemplate {
  id: ContactMethodType;
  content: string;
  parameters_keys: string[];
  code: number;
  channel: string;
  created_at: string;
  updated_at: string;
}

export interface IPointOfContact {
  contacted_before: number;
  country_code: string;
  updated_at: number;
  created_at: number;
  name: string;
  phone_number: string;
  id: string;
  is_out_dated: 1 | 0;
  email: string;
  role: string;
}

export interface IAccountManagerInfo {
  email: string;
  name: string;
  roles: string[];
}
export enum ContactMethodType {
  Whatsapp = 1,
  SMS = 2,
  Email = 3,
}
export interface IContactTemplatePayload {
  id: string | number;
  updated_content: string;
  selected: boolean;
}
export interface IBrandTasks {
  store_id: string;
  store_name: string;
  store_image: string;
  points_of_contacts: IPointOfContact[];
  contact_templates: IContactTemplatePayload[];
  tasks: ITask[];
}

export enum ALERTS {
  transaction_drop,
  orders_drop,
  fulfilment,
  cpo,
}
export interface ITask {
  account_manager_id: string;
  id: string;
  branch_id: string;
  branch_name: string;
  total_orders: number;
  fulfilment: number;
  cpo: number;
  alert_type: ALERTS;
  alert_percentage: number;
  cycle: "day" | "week" | "month" | "year";
  drop_from: number;
  drop_to: number;
  alerts: IAlert[];
  resolved: boolean;
  details: any;
  created_at: string;
  updated_at: string;
  selected?: boolean;
  page: "take-action" | "action-center";
}

export interface IAlert {
  type: number;
  percentage: number;
}

export interface IContactMethodView {
  selected: boolean;
  message: string;
}

export interface IContactMethodsState {
  [storeId: string]: IContactMethodValue<boolean>;
}

export type IContactMethodValue<T> = {
  [key in ContactMethodType]: T;
}
export interface ISelectedContactMethodsState {
  resolve_all: {
    contact_methods_view: IContactMethodValue<IContactMethodView>;
    selected_contact_methods: IContactMethodValue<boolean>;
  };
  contact_methods_view?: {
    [storeId: string]: IContactMethodValue<IContactMethodView>;
  };
  selected_contact_methods?: {
    [storeId: string]: IContactMethodValue<boolean>;
  };
}
export interface IContactMethod {
  icon: string;
  label: string;
  value: ContactMethodType;
}

export interface IUseBrandContactMethodsReturnType {
  selected_store_id: string;
  handleSelectedContactMethods: (contactMethod: ContactMethodType) => void;
  activeContactMethod: ContactMethodType;
  setActiveContactMethod: React.Dispatch<
    React.SetStateAction<ContactMethodType>
  >;
  handleTemplateMessageChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    contact_method: ContactMethodType
  ) => void;
  templateMessageValue: string;
  setTemplateMessageValue: (value: string) => void;
  handleContactMethodTabView: (value: ContactMethodType) => void;
  checkedContactMethod: (value: ContactMethodType) => boolean;
}

export interface IActionCenterBrandsState {
  brandsById: Record<string, IBrandTasks>;
  brandsIds: string[];
  shownBrandsTasksIds: string[];
  shownBrandsTasksById: Record<string, IBrandTasks>;
  selectedBrandsTasksIds: string[];
  selectedBrandsById: Record<string, IBrandTasks>;
  alert_types: ALERTS[];
  account_manager_id: string;
  selected_store_id: string;
  resolve_all: boolean;
  contactTemplatesById: {
    [x: string]: { id: number; updated_content: string };
  };
}

export interface IResolveTasksPayload<T> {
  stores: IStoreTasks<T>[];
}

export interface IStoreTasks<T> {
  id: string;
  store_name: string;
  tasks: T[];
  contact_templates: Array<Omit<IContactTemplatePayload, "selected">>;
}

export interface IActionSummary {
  id: number;
  store_id: string;
  resolve_action_summary: string;
  created_at: string;
  updated_at: string;
}
