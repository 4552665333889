import { Modal, ModalBody, ModalHeader } from "reactstrap";
import * as React from "react";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import * as styles from "./styles.module.sass";
import { email, isEmpty, required } from "../../redux-store/validators";
import { RenderGenericField } from "../../Components/FormElements/RenderGenericField";
import {
  ISelectItemData,
  RenderDropDownSelect,
} from "../../Components/FormElements/RenderDropDownSelect";
import { posSelectItems } from "../../constants";
// import {FormEvent} from "react";
import { IAddBrandAction, ICountry, IGetCountriesAction } from "../../types";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { Dispatch } from "redux";
import { getCountries } from "../../redux-store/actions";
import { connect } from "react-redux";
import { addBrand } from "../../redux-store/actions/addBrandActions";
import normalizePhone from "../../redux-store/transformers";
import { hot } from "react-hot-loader";
import { FormEvent } from "react";
import StepsCmp, { Step as StepCmp } from "../rc-steps/es/index";
import "../rc-steps/assets/index.css";
import "../rc-steps/assets/iconfont.css";
import ChoosePlan from "../ChoosePlan";
import PaidFormComponent from "../PaidForm";
import TrialFormComponent from "../TrialForm";
import countriesAndTimezones from "countries-and-timezones";
// import { getPOSProviders } from "../../axios/getPOSProviders";
import {
  getAdminsByRole,
  IAccountManager,
} from "../../Components/TargetedTools/redux-store/actions/admins-by-role-actions";
import { getAdminsByRoleAction } from "src/Components/TargetedTools/redux-store/actions/constants";

const Steps = StepsCmp as any;
const Step = StepCmp as any;

interface IProps {
  modal: boolean;
  toggle: () => void;
  title: string;
  countries: ICountry[];
  account_managers: IAccountManager[];
  getCountries: () => void;
  addBrand: (isTrial: boolean) => void;
  getAdminsByRole: (role: string) => void;
  formValues: {
    country_iso_code: any;
  };
}

interface IState {
  currentStep: number;
  isTrial: boolean;
  // loadedPOSIntegrations: boolean;
  // posIntegrations: ISelectItemData[];
}

class AddModal extends React.Component<IProps & InjectedFormProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      isTrial: false,
      // loadedPOSIntegrations: false,
      // posIntegrations: [],
    };
  }

  public handleNextStep = () => {
    this.setState((prevState) => ({
      currentStep:
        prevState.currentStep < 3
          ? prevState.currentStep + 1
          : prevState.currentStep,
    }));
  };

  public handlePrevStep = () => {
    this.setState((prevState) => ({
      currentStep:
        prevState.currentStep > 0
          ? prevState.currentStep - 1
          : prevState.currentStep,
    }));
  };

  public componentDidMount() {
    this.props.getCountries();
    this.props.getAdminsByRole("account_manager");
    // getPOSProviders()
    //   .then(({ data: { pos_providers } }) => {
    //     this.setState({
    //       // loadedPOSIntegrations: true,
    //       posIntegrations: [
    //         ...pos_providers.map((item) => ({
    //           label: item.name,
    //           value: item.value,
    //         })),
    //         ...posSelectItems,
    //       ],
    //     });
    //   })
    //   .catch(() => {
    //     this.setState({
    //       loadedPOSIntegrations: true,
    //       posIntegrations: [
    //         {
    //           label: "Pos Rocket",
    //           value: "rocket",
    //         },
    //         {
    //           value: "foodics",
    //           label: "Foodics",
    //         },
    //         ...posSelectItems,
    //       ],
    //     });
    //   });
  }

  public componentWillUpdate() {
    if (!this.props.modal) {
      this.props.reset();
    }
  }

  public handleIsTrialSet = (isTrial: boolean) => {
    this.setState({ isTrial });
    this.handleNextStep();
  };

  public addBrand = (e: FormEvent) => {
    if (!this.props.submitting) {
      e.preventDefault();
      this.props.addBrand(this.state.isTrial);
    }
  };
  public handleBrandInfoSubmit = (e: FormEvent) => {
    if (this.state.currentStep === 0) {
      e.preventDefault();
      this.props.touch(
        "name",
        "email",
        "phone_number",
        "country_iso_code",
        "activation_date",
        // "pos",
        "payment_term",
        "payment_date",
        "brand_name",
        "starting_branches_count",
        "timezone",
        "account_manager_id"
      );
      if (this.props.valid) {
        this.handleNextStep();
        // this.props.addBrand();
      }
    } else {
      this.handleNextStep();
    }
  };

  public componentWillReceiveProps(nextProps: IProps) {
    const {
      change,
      formValues: { country_iso_code },
    } = this.props;
    if (
      (country_iso_code ? country_iso_code.ISO_code : undefined) !==
      nextProps.formValues.country_iso_code
    ) {
      change(
        "timezone",
        countriesAndTimezones.getTimezonesForCountry(
          nextProps.formValues.country_iso_code.ISO_code.toUpperCase()
        )[0].name
      );
    }
  }

  public render() {
    if (this.props.submitSucceeded && this.props.modal) {
      this.props.toggle();
    }
    const countrySelectItems = this.props.countries.map(
      (country: ICountry): ISelectItemData => ({
        value: country,
        label: country.EnName,
      })
    );
    const {
      formValues: { country_iso_code },
    } = this.props;
    const { currentStep, isTrial } = this.state;
    const timeZoneSelectItems: ISelectItemData[] = country_iso_code
      ? countriesAndTimezones
          .getTimezonesForCountry(country_iso_code.ISO_code.toUpperCase())
          .map((t) => ({
            value: t.name,
            label: `(GMT${t.offsetStr}) ${t.name}`,
          }))
      : [];
    const accountManagersOptions: ISelectItemData[] = Array.isArray(
      this.props.account_managers
    )
      ? this.props.account_managers.map((element) => ({
          label: element.name,
          value: element.id,
        }))
      : [];

    return (
      <Modal isOpen={this.props.modal} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          <h1 style={{ margin: "0 1rem" }}>{this.props.title}</h1>
        </ModalHeader>
        <ModalBody className={styles.wrapper}>
          {/* {!this.state.loadedPOSIntegrations ? (
            <BounceLoader size={30} color="#FEAD12" />
          ) : ( */}
          <>
            <Steps current={this.state.currentStep}>
              <Step title="Add brand information" />
              <Step title="Payment plan" />
              <Step title="Payment setup" />
            </Steps>

            {this.state.currentStep === 0 && (
              <form
                autoComplete="off"
                onSubmit={this.handleBrandInfoSubmit}
                className={styles.form}
              >
                <Field
                  name="brand_name"
                  type="text"
                  component={RenderGenericField as any}
                  label="Brand name"
                  validate={[required, isEmpty]}
                />
                <Field
                  name="email"
                  type="email"
                  component={RenderGenericField as any}
                  label="Email"
                  validate={[required, email]}
                />
                <Field
                  name="phone_number"
                  type="text"
                  component={RenderGenericField as any}
                  label="Phone number"
                  validate={[required]}
                  normalize={normalizePhone}
                />
                <Field
                  name="country_iso_code"
                  component={RenderDropDownSelect as any}
                  options={countrySelectItems}
                  label="Country"
                  validate={[required]}
                />
                {/* <Field
                    name="pos"
                    component={RenderDropDownSelect as any}
                    options={this.state.posIntegrations}
                    label="Pos integration"
                    validate={[required]}
                  /> */}
                <Field
                  name="timezone"
                  component={RenderDropDownSelect as any}
                  label="Time Zone"
                  options={timeZoneSelectItems}
                  disabled={!timeZoneSelectItems.length}
                  validate={[required]}
                />
                <Field
                  name="account_manager_id"
                  component={RenderDropDownSelect as any}
                  label="AM assignation"
                  options={accountManagersOptions}
                  disabled={!accountManagersOptions.length}
                  validate={[required]}
                  showArrow={true}
                />
              </form>
            )}
            {currentStep === 1 && (
              <ChoosePlan onChoosePlan={this.handleIsTrialSet} />
            )}
            {currentStep === 2 && isTrial && <TrialFormComponent />}
            {currentStep === 2 && !isTrial && <PaidFormComponent />}
            <div className={styles.btnWrapper}>
              {currentStep > 0 ? (
                <button
                  onClick={this.handlePrevStep}
                  className={styles.backBtn}
                >
                  Back
                </button>
              ) : (
                <div />
              )}
              {currentStep === 2 ? (
                <button
                  type="submit"
                  onClick={this.addBrand}
                  className={styles.btn}
                >
                  Add brand
                </button>
              ) : (
                currentStep !== 1 && (
                  <button
                    onClick={this.handleBrandInfoSubmit}
                    type="submit"
                    className={styles.nextBtn}
                  >
                    Next
                  </button>
                )
              )}
            </div>
          </>
          {/* )} */}
        </ModalBody>
      </Modal>
    );
  }
}

let AddBrandForm = reduxForm({
  form: "addBrand",
})(AddModal as any);
const selector = formValueSelector("addBrand");
AddBrandForm = connect((state) => {
  // can select values individually
  const country_iso_code = selector(state, "country_iso_code");
  return {
    formValues: {
      country_iso_code,
    },
  };
})(AddBrandForm) as any;
const mapStateToProps = (state: IRootReducerState) => ({
  countries: state.countriesReducer.countries,
  initialValues: { pos: posSelectItems[posSelectItems.length - 1].value },
  account_managers: state.getAdminsByRoleReducer?.account_manager,
});

interface IGetAdminByRoleAction {
  type: typeof getAdminsByRoleAction.requested;
  payload: { role: string };
}
const mapDispatchToProps = (
  dispatch: Dispatch<
    IGetCountriesAction | IAddBrandAction | IGetAdminByRoleAction
  >
) => ({
  getCountries: () => dispatch(getCountries()),
  addBrand: (isTrial: boolean) => dispatch(addBrand(isTrial)),
  getAdminsByRole: (role: string) => dispatch(getAdminsByRole({ role })),
});

export default hot(module)(
  connect(mapStateToProps, mapDispatchToProps)(AddBrandForm) as any
);
