import * as React from "react";
import * as styles from "./style.module.sass";
// import { ISingleCountryStore } from "../../../../axios/getBrands";
interface IProps {
  disabled?: boolean;
  chip: {
    label: string;
    value: string;
  };
  clicked: (label: string, value: string) => void;
}
const SingleChip: React.FC<IProps> = (props) => {
  const { chip, clicked, disabled } = props;
  const handleClick = () => {
    if (!disabled) {
      clicked(chip.label, chip.value);
    }
  };
  return (
    <div
      className={disabled ? styles.dimmedWrapper : styles.wrapper}
      key={chip.value}
    >
      <span className={styles.sp}>{chip.label}</span>
      <img
        className={styles.im}
        src={require("../../../../assets/close.svg")}
        onClick={handleClick}
      />
    </div>
  );
};

export default SingleChip;
