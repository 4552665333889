import { IVerifyPaymentAction } from "../../types";
import { selectForm, selectToken } from "../selectors";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { verifyPaymentFailure, verifyPaymentSuccess } from "../actions";
import { verifyPaymentAction } from "../../constants";
import { IPaymentRequest, verifyPayment } from "../../axios/verifyPayment";
import {
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  initialize,
} from "redux-form";
import { IFormState } from "../rootReducer";
import { notify } from "react-notify-toast";
import * as moment from "moment";

function* verifyPaymentSaga(action: IVerifyPaymentAction) {
  try {
    yield put(startSubmit("confirmationForm"));
    const form: IFormState = yield select(selectForm);
    const { password } = form.confirmationForm.values as any;
    const { payment_term, payment_date, comment } = form.paymentForm
      .values as any;
    const token = yield select(selectToken);
    const data: IPaymentRequest = {
      password,
      payment_date: payment_date.format("x"),
      payment_term,
      comment,
      store_id: action.payload,
    };
    const res: AxiosResponse<any> = yield call(verifyPayment, token, data);
    yield put(verifyPaymentSuccess(res.data.paid));
    notify.show("Payment verified successfully.", "success");
    yield put(setSubmitSucceeded("confirmationForm"));
    const confirmationInitialValues = {
      payment_term: data.payment_term,
      payment_date:
        data.payment_term === 0
          ? moment(data.payment_date, "x").add(1, "month")
          : data.payment_term === 1
          ? moment(data.payment_date, "x").add(3, "month")
          : moment(data.payment_date, "x").add(1, "year"),
    };
    yield put(initialize("paymentForm", confirmationInitialValues));
  } catch (e) {
    yield put(setSubmitFailed("confirmationForm"));
    yield put(verifyPaymentFailure(e));
  }
}

export function* watchVerifyPaymentSaga() {
  yield takeLatest(verifyPaymentAction.requested, verifyPaymentSaga);
}
