import * as React from "react";
import * as styles from "./styles.module.sass";
import * as requestStyles from "./CampaignRequest/styles.module.sass";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { connect } from "react-redux";
import { CampaignRequest } from "./CampaignRequest";
import {
  getReachCampaigns,
  IReachCampaignsResponse,
} from "../../redux-store/actions/getReachCampaignsActions";
import { IGetCampaignsFilters } from "../../axios/get-campaigns";
import { ISelectItemData } from "../FormElements/RenderDropDownSelect";
import { Aux } from "../../App";
import Pagination from "react-js-pagination";
import * as WS from "../../axios";
import { notify } from "react-notify-toast";
import Skeleton from "react-skeleton-loader";

interface IState {
  active: ISelectItemData[];
  activePage: number;
}

interface IProps {
  token: string;
  getCampaigns: (filters: IGetCampaignsFilters) => void;
  campaigns: IReachCampaignsResponse[];
  CAMPAIGNS_COUNT: number;
  loaded: boolean;
  failed: boolean;
}

export const campaignRequestStatuses: ISelectItemData[] = [
  {
    value: undefined,
    label: "All",
  },
  {
    value: [0, 1],
    label: "Waiting for review",
  },
  {
    value: [2],
    label: "Rejected",
  },
  {
    value: [3],
    label: "Scheduled",
  },
  {
    value: [4],
    label: "Running",
  },
  {
    value: [5],
    label: "Completed",
  },
];

class CampaignRequests extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      active: [campaignRequestStatuses[0]],
      activePage: 1,
    };
  }

  public componentDidMount() {
    this.props.getCampaigns({ page: this.state.activePage - 1 });
  }

  public onBtnActive = (active) => {
    this.setState(
      {
        active: campaignRequestStatuses.filter(
          (campaign) => campaign.label === active.target.value
        ),
        activePage: 0,
      },
      () => {
        this.props.getCampaigns({
          page: this.state.activePage,
          status: this.state.active[0].value,
        });
      }
    );
  };
  public handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, () => {
      this.props.getCampaigns({
        page: this.state.activePage - 1,
        status: this.state.active[0].value,
      });
    });
  };

  public rejectCampaign = (campaignId: string) => {
    return WS.rejectCampaign(this.props.token, campaignId)
      .then(() => {
        this.props.getCampaigns({
          page: this.state.activePage - 1,
          status: this.state.active[0].value,
        });
        notify.show("Campaign was rejected", "success");
      })
      .catch(() =>
        notify.show("Something went wrong, please try again", "error")
      );
  };

  public acceptCampaign = (campaignId: string) => {
    return WS.acceptCampaign(this.props.token, campaignId)
      .then(() => {
        this.props.getCampaigns({
          page: this.state.activePage - 1,
          status: this.state.active[0].value,
        });
        notify.show("Campaign queued successfully", "success");
      })
      .catch(() =>
        notify.show("Something went wrong, please try again", "error")
      );
  };

  public render() {
    const { loaded, failed, campaigns, CAMPAIGNS_COUNT } = this.props;
    let render = (
      <Aux>
        {new Array(10).fill(1).map((a, index) => {
          return (
            <div key={index} className={requestStyles.wrapper}>
              <div className={requestStyles.wrapper2}>
                <Skeleton widthRandomness={0} width={"8rem"} height={"2rem"} />
                <div className={requestStyles.user}>
                  <Skeleton
                    widthRandomness={0}
                    width={"7rem"}
                    height={"7rem"}
                    borderRadius={"50%"}
                  />
                  <Skeleton
                    widthRandomness={0}
                    width={"8rem"}
                    height={"2rem"}
                  />
                </div>
                <div className={requestStyles.phone}>
                  <img
                    className={requestStyles.logo}
                    src={require("../../assets/phone.svg")}
                  />
                  <Skeleton
                    widthRandomness={0}
                    width={"8rem"}
                    height={"2rem"}
                  />
                </div>
                <div className={requestStyles.date}>
                  <img
                    className={requestStyles.logo}
                    src={require("../../assets/icon-time.svg")}
                  />
                  <Skeleton
                    widthRandomness={0}
                    width={"8rem"}
                    height={"2rem"}
                  />
                </div>
                <Skeleton widthRandomness={0} width={"8rem"} height={"2rem"} />
                <Skeleton widthRandomness={0} width={"8rem"} height={"2rem"} />
              </div>
            </div>
          );
        })}
      </Aux>
    );
    if (loaded) {
      if (campaigns.length) {
        render = (
          <Aux>
            <div className={styles.usersWrapper}>
              {this.props.campaigns.map((campaign) => (
                <CampaignRequest
                  campaign={campaign}
                  key={campaign.id}
                  onAccept={this.acceptCampaign}
                  onReject={this.rejectCampaign}
                />
              ))}
            </div>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={8}
              totalItemsCount={CAMPAIGNS_COUNT}
              pageRangeDisplayed={8}
              onChange={this.handlePageChange}
              prevPageText="<"
              nextPageText=">"
              innerClass={styles.innerClass}
              activeClass={styles.activeClass}
              activeLinkClass={styles.activeLinkClass}
              itemClass={styles.itemClass}
              itemClassFirst={styles.itemClassFirst}
              itemClassLast={styles.itemClassLast}
              itemClassPrev={styles.itemClassPrev}
              itemClassNext={styles.itemClassNext}
            />
          </Aux>
        );
      } else {
        render = (
          <div className={styles.wrapper}>
            <div className={styles.rejectedText}>
              <h1>There are no requests!</h1>
            </div>
          </div>
        );
      }
    }
    if (failed) {
      render = (
        <div className={styles.wrapper}>
          <div className={styles.rejectedText}>
            <h1>Something went wrong!</h1>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.filterWrapper}>
          {loaded ? (
            <h1 className={styles.textWrapper}>
              {this.state.active[0].label} Requests ({CAMPAIGNS_COUNT})
            </h1>
          ) : (
            <div />
          )}
          <div className={styles.btnWrapper}>
            <div className={styles.filter}>
              <img
                src={require("../../assets/icon-filter.svg")}
                className={styles.img}
              />
              <span className={styles.text}>Filters</span>
            </div>
            {campaignRequestStatuses.map((status) => (
              <button
                key={status.value}
                onClick={this.onBtnActive}
                className={
                  this.state.active[0].label === status.label
                    ? styles.btnActive
                    : styles.btn
                }
                value={status.label}
              >
                {status.label}
              </button>
            ))}
          </div>
        </div>
        {render}
      </div>
    );
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  token: state.authReducer.token,
  campaigns: state.reachCampaignsReducer.CAMPAIGNS,
  loaded: state.reachCampaignsReducer.completed,
  failed: state.reachCampaignsReducer.failed,
  CAMPAIGNS_COUNT: state.reachCampaignsReducer.CAMPAIGNS_COUNT,
});
const mapDispatchToProps = (dispatch) => ({
  getCampaigns: (filters) => dispatch(getReachCampaigns(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignRequests);
