import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  deleteBrandPosEntity,
  deleteBrandPosEntityFailure,
  deleteBrandPosEntitySuccess,
  getBrandPosEntitiesReset,
} from "../actions";
import { deleteBrandPosEntityAction } from "../../constants";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import { deleteBrandPosEntityApi } from "../../axios/pos";
import { customNotify } from "../../UIComponents/customNotification/customNotification";

const actionTypes = union({ deleteBrandPosEntity });
function* deleteBrandPosEntitySaga({ payload }: typeof actionTypes) {
  try {
    yield put(getBrandPosEntitiesReset())
    const token = yield select(selectToken);
    const res = yield call(
      deleteBrandPosEntityApi,
      token,
      payload.id,
      payload.store_id
    );
    yield put(deleteBrandPosEntitySuccess(res.data.pos));
    customNotify.success("POS deleted successfully!", 2000);
  } catch (error) {
    customNotify.error("Something went wrong, please try again", 2000);
    yield put(deleteBrandPosEntityFailure(error));
  }
}

export function* watchDeleteBrandPosEntitySaga() {
  yield takeLatest(
    deleteBrandPosEntityAction.requested,
    deleteBrandPosEntitySaga
  );
}
