import React, { useState } from "react";
import CsvUploadErrorsPopup from "../CsvUploadErrorsPopup";
import styles from "./styles.module.sass";

interface IProps {
  errors: any[];
  showError: boolean;
  closeError: () => void;
}
export const MissingBrandCsvValues: React.FC<IProps> = ({
  errors,
  showError,
  closeError,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return showError ? (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.missingMain}>
          <img
            className={styles.errorIcon}
            src={require("../../../../../assets/icon-error.svg")}
          />
          <div className={styles.missingValuesWrapper}>
            <span className={styles.missingValuesTitle}>
              {errors.length} Missing values found
            </span>
            {errors && (
              <span className={styles.missingValues}>
                {errors[0]?.substring(0, 50).concat("...")}
              </span>
            )}
          </div>
        </div>
        <div className={styles.closingWrapper}>
          {open && (
            <CsvUploadErrorsPopup
              open={open}
              onClose={handleClose}
              errors={errors}
            />
          )}
          {/* {errors.length > 1 && ( */}
          <button
            type="button"
            onClick={handleOpen}
            className={styles.showAllErrors}
          >
            Show all errors
          </button>
          {/* )} */}
          <img
            onClick={closeError}
            className={styles.closeIcon}
            src={require("../../../../../assets/cross.svg")}
          />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default MissingBrandCsvValues;
