import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  exportBrandPosMenu,
  exportBrandPosMenuSuccess,
  exportBrandPosMenuFailure,
} from "../actions";
import { exportBrandPosMenuAction } from "../../constants";
import { exportFoodicsMenu as exportFoodicsMenuRequest } from "../../axios/exportFoodicsMenu";
import { union } from "ts-action";

const actionTypes = union({ exportBrandPosMenu });
function* exportBrandPosMenuSaga({ payload }: typeof actionTypes) {
  try {
    const res: AxiosResponse<any> = yield call(exportFoodicsMenuRequest, {
      api_key: payload.api_key,
    });

    yield put(
      exportBrandPosMenuSuccess({
        JSONFileContent: res.data,
        pos_entity_id: payload.pos_entity_id,
      })
    );
  } catch (e) {
    yield put(exportBrandPosMenuFailure(e));
  }
}

export function* watchExportBrandPosMenuSaga() {
  yield takeLatest(exportBrandPosMenuAction.requested, exportBrandPosMenuSaga);
}
