import * as types from "../../types";

import * as constants from "../../constants";
import { generateStatsReducer } from "./generateStatsReducer";

export const brandFailedOperationsNumberReducer = generateStatsReducer<
  types.getFailedOperationsNumberActionType
>(constants.getBrandFailedOperationsNumberAction);
export const brandRegisteredCustomerNumberReducer = generateStatsReducer<
  types.getBrandRegisteredCustomerNumberActionType
>(constants.getBrandRegisteredCustomerNumberAction);
export const brandReviewsNumberReducer = generateStatsReducer<
  types.getBrandReviewsNumberActionType
>(constants.getBrandReviewsNumberAction);
export const brandConversionRateReducer = generateStatsReducer<
  types.getBrandConversionRateActionType
>(constants.getBrandConversionRateAction);
export const brandRedemptionRateReducer = generateStatsReducer<
  types.getBrandRedemptionRateActionType
>(constants.getBrandRedemptionRateAction);
export const brandRedemptionNumberReducer = generateStatsReducer<
  types.getBrandRedemptionNumberActionType
>(constants.getBrandRedemptionNumberAction);
export const brandTempCustomersNumberReducer = generateStatsReducer<
  types.getBrandTempCustomersNumberActionType
>(constants.getBrandTempCustomersNumberAction);
export const brandVisitsNumberReducer = generateStatsReducer<
  types.getBrandVisitsNumberActionType
>(constants.getBrandVisitsNumberAction);
export const brandUnresolvedIssuesNumberReducer = generateStatsReducer<
  types.getBrandUnresolvedIssuesActionType
>(constants.getBrandUnresolvedIssuesNumberAction);
