import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.sass";
import "../styles.less";
import { IMultiSelectProps } from "../types";
import { Select as AntdSelect } from "antd";
import chevron_down from "../../../assets/chevron-down.svg";
import { CheckIcon } from "../../../assets/components";

export function MultiSelect<T>({
  options,
  className,
  defaultValues,
  label,
  onChange,
  popupClassName,
}: IMultiSelectProps<T>) {
  const [open, setOpen] = useState(false);
  const [selectedAll, setSelectedAll] = useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState<T[]>(defaultValues ?? []);

  const value = useMemo(() => {
    if (selectedAll || !selectedItems.length) {
      return "All";
    } else if (selectedItems.length > 1 && !selectedAll) {
      return `${selectedItems.length} selected`;
    } else {
      const selected = options.find((option) =>
        selectedItems.includes(option.value)
      )?.label;
      return selected;
    }
  }, [selectedItems, selectedAll]);
  useEffect(() => {
    if (selectedAll) {
      setSelectedItems(options.map((option) => option.value));
    }
  }, [selectedAll]);
  useEffect(() => {
    if (options.length === defaultValues?.length) {
      setSelectedAll(true);
    }
    setSelectedItems(defaultValues ?? []);
  }, [defaultValues]);
  return (
    <AntdSelect
      onSelect={() => {
        setOpen(true);
      }}
      onClick={(event) => {
        if (
          open &&
          event.currentTarget.querySelector(".ant-select-selection-item") ===
            event.target
        ) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }}
      bordered={false}
      open={open}
      onBlur={() => {
        setOpen(false);
      }}
      suffixIcon={
        <img src={chevron_down} style={{ width: "16px", height: "16px" }} />
      }
      className={className ?? "generic-select"}
      popupClassName={popupClassName ?? "generic-dropdown"}
      value={`${label}: ${value}`}
    >
      <div className={styles.dropdownWrapper}>
        <div
          onClick={() => {
            if (selectedItems.length === options.length) {
              onChange?.([]);
              setSelectedItems([]);
              setSelectedAll(false);
            } else {
              setSelectedAll(true);
              onChange?.(options.map((option) => option.value));
              setSelectedItems(options.map((option) => option.value));
            }
          }}
          className={styles.dropdownElementWrapper}
        >
          <div
            className={styles.checkboxWrapper}
            style={{
              backgroundColor: selectedAll ? "#000" : "#F2F2F7",
            }}
          >
            {selectedAll && <CheckIcon stroke="#fff" />}
          </div>
          <span className={styles.dropdownElementLabel}>All</span>
        </div>
        {options.map((item) => (
          <div
            key={String(item?.value)}
            onClick={() => {
              if (selectedItems.includes(item.value)) {
                onChange?.(
                  selectedItems.filter((element) => element !== item.value)
                );
                setSelectedItems(
                  selectedItems.filter((element) => element !== item.value)
                );

                setSelectedAll(false);
              } else {
                if (selectedItems.length === options.length - 1) {
                  setSelectedAll(true);
                }
                onChange?.([...selectedItems, item.value]);

                setSelectedItems([...selectedItems, item.value]);
              }
            }}
            className={styles.dropdownElementWrapper}
          >
            <div
              className={styles.checkboxWrapper}
              style={{
                backgroundColor: selectedItems.includes(item.value)
                  ? "#000"
                  : "#F2F2F7",
              }}
            >
              {selectedItems.includes(item.value) && (
                <CheckIcon stroke="#fff" />
              )}
            </div>
            <span className={styles.dropdownElementLabel}>{item.label}</span>
          </div>
        ))}
      </div>
    </AntdSelect>
  );
}

export default MultiSelect;
