import { reducer, on } from "ts-action";
import { ICommissions } from "./lib";
import { withLoadingReducer } from "../../../../redux-store/reducers/withLoadingState";
import { getCommissionsAction } from "../actions/constants";
import { getCommissionsSuccess, editCommissions } from "../actions/commissions";

interface IState {
  commissions: ICommissions;
}

export const commissionsReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(getCommissionsSuccess, (state, { payload }) => {
        return {
          ...state,
          commissions: payload,
        };
      }),
      on(editCommissions, (state, { payload }) => {
        return {
          ...state,
          commissions: payload.commissions,
        };
      }),
    ],
    {
      commissions: {
        delivery_commission: 0,
        pickup_commission: 0,
        delivery_share: 0,
        delivery_share_type: "percentage",
      },
    }
  ),
  getCommissionsAction
);
