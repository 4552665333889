import React from "react";
import styles from "./styles.module.sass";
import chevron from "../../../assets/chevaron_up.svg";
import { CheckIcon } from "../../../assets/components";

interface IBranchCollapseHeaderProps {
  expanded: boolean;
  onExpand: () => void;
  title: string;
  selector?: boolean;
  resolveAll?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onSelect?: () => void; 
  onForceExpand?: () => void;
}
export const BranchCollapseHeader: React.FC<IBranchCollapseHeaderProps> = ({
  expanded,
  onExpand,
  title,
  selector,
  resolveAll,
  className,
  style,
  onSelect,
  onForceExpand
}) => {
  return (
    <div
      style={{ ...style, cursor: "pointer" }}
      onClick={() => {
        if (resolveAll) {
          onExpand();
        }
      }}
      className={`${styles.brandHeaderWrapper} ${className}`}
    >
      <div className={styles.brandNameWrapper}>
        {typeof selector === "boolean" && (
          <div
            style={{
              backgroundColor: selector ? "#1C1C1E" : "#F2F2F7",
            }}
            className={styles.checkboxWrapper}
            onClick={onSelect}
          >
            {selector && (
              <CheckIcon
                style={{ width: "12px", height: "12px" }}
                stroke="#fff"
              />
            )}
          </div>
        )}
        <span onClick={() => {
          if(typeof expanded === "boolean" && onForceExpand) {
            onForceExpand();
          }
        }} className={styles.brandName}>{title}</span>
      </div>
      <img
        className={styles.chevron}
        style={{
          transform: expanded
            ? ""
            : resolveAll
            ? "rotate(90deg)"
            : "rotate(180deg)",
        }}
        onClick={onExpand}
        src={chevron}
      />
    </div>
  );
};

export default BranchCollapseHeader;
