import { on, reducer } from "ts-action";
import {
  getPushNotificationsSuccess,
  createPushNotificationSuccess,
} from "../actions/pushNotification";
import { mapById } from "./lib";
import {
  ILoadingState,
  withLoadingReducer,
} from "../../../../redux-store/reducers/withLoadingState";
import { getPushNotificationsAction } from "../actions/constants";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
import { IPushCampaign } from "../../axios/get-push-notification";

interface IState {
  notifications: string[];
  notificationsById: { [x: string]: IPushCampaign };
  campaigns_count: number;
}

export const pushNotificationsReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(getPushNotificationsSuccess, (state, { payload }) => ({
          ...state,
          campaigns_count: payload.total_campaigns,
          notifications: [
            ...payload.campaigns.map((i) => i.id),
            ...state.notifications,
          ],
          notificationsById: {
            ...mapById(payload.campaigns),
            ...state.notificationsById,
          },
        })),
        on(createPushNotificationSuccess, (state, action) => {
          const id = action.meta.optimistic.id.toString();
          return {
            ...state,
            notifications: [...state.notifications, id],
            notificationsById: {
              ...state.notificationsById,
              [id]: action.payload,
            },
          };
        }),
        // on(createPushNotificationSuccess, (state, { payload , meta }) => {
        //   const optId = meta.optimistic.id.toString();
        //   return {
        //     ...state,
        //     campaigns_count: state.campaigns_count + 1,
        //     notificationsById: {
        //       ...state.notificationsById,
        //       [payload.id]: payload
        //     },
        //     notifications: state.notifications.map(id => (optId === id ? payload.id : id))
        //   };
        // }),
      ],
      { notifications: [], notificationsById: {}, campaigns_count: 0 }
    ),
    getPushNotificationsAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
