import React from "react";
import {
  Control,
  FieldName,
  FieldErrors,
  Controller,
  FieldValues,
  FieldPath,
  RegisterOptions,
  UseFormSetError,
} from "react-hook-form";
import { useDeepCompareEffect } from "use-deep-compare";
import styles from "./styles.module.sass";
interface IProps<T> {
  currency?: string;
  label?: string;
  labelClassName?: string;
  name: FieldName<T>;
  control: Control;
  errors?: FieldErrors;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  className?: string;
  mainClassName?: string;
  percentage?: boolean;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  isEditing?: boolean;
  days?: string;
  setError: UseFormSetError<FieldValues>;
  initialValue?: any;
  isMasked?: boolean;
  pattern?: any;
  max?: string | number;
  min?: string | number;
  countryISOCode?: string;
  highestRank?: number;
  isReactivating?: boolean;
}
export const Input = <T extends {}>({
  label,
  labelClassName,
  control,
  name,
  rules,
  className,
  placeholder,
  type,
  percentage,
  disabled,
  currency,
  days,
  setError,
  initialValue,
  pattern,
  max,
  min,
  isEditing,
  countryISOCode,
  highestRank,
  isReactivating,
  mainClassName,
  
}: IProps<T>) => {
  const promoRankingValidation = (value) => {
    if (
      highestRank &&
      Number(value) > highestRank[countryISOCode as string] + 1
    ) {
      setError("rank", {
        message: `The highest rank is ${highestRank[countryISOCode as string]}`,
      });
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error, isTouched, invalid, isDirty },
      }) => {
        useDeepCompareEffect(() => {
          if (value === "" && isEditing) {
            setError(name, {
              message: "*Required",
            });
          }
        }, [value, isEditing]);
        return (
          <div className={`${styles.inputMain} ${mainClassName}`}>
            <span className={`${labelClassName} ${styles.label}`}>{label}</span>
            <div
              style={{
                ...(error ? { borderColor: "#db3030" } : {}),
                ...(disabled ? { cursor: "not-allowed" } : {}),
              }}
              className={`${styles.inputWrapper} ${className}`}
            >
              <input
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  percentage
                    ? onChange(
                        Number(event.target.value) <= 100
                          ? Number(event.target.value)
                          : 0
                      )
                    : currency
                    ? onChange(event.target.value)
                    : onChange(event.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setError(name, {
                      message: "*Required",
                    });
                  }
                  if (name.includes("rank")) {
                    promoRankingValidation(value);
                  }
                }}
                disabled={disabled}
                value={value || initialValue}
                type={type}
                placeholder={placeholder}
              />
              {percentage && <div className={styles.postFix}>%</div>}
              {currency && <div className={styles.postFix}>{currency}</div>}
              {days && <div className={styles.postFix}>{days}</div>}
            </div>
            {error && <span className={styles.formError}>{error.message}</span>}
          </div>
        );
      }}
    />
  );
};

export default Input;
