import axios from "./axios";
import * as Requests from "./requests";

export const createUniversalPromoCodeAPI = (
  token,
  data,
  type: "discount" | "points-multiplier" | "cashback"
) => {
  return axios.post(Requests.CreateUniversalPromoCodeURL(type), data, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
