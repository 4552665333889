import {
  IFilterBrandsAction,
  IGetBrandsAction,
  IGetBrandsFailureAction,
  IGetBrandsStatisticsSuccessAction,
  IGetBrandsSuccessAction,
  IResetFilterBrandsAction,
} from "../../types";
import {
  ACTION_ADD_PAGE,
  ACTION_DISABLE_LOAD_MORE,
  ACTION_ENABLE_LOAD_MORE,
  ACTION_FILTER_BRANDS,
  ACTION_GET_BRANDS_STATISTICS_SUCCESS,
  ACTION_RESET_FILTER_BRANDS,
  ACTION_RESET_PAGE,
  ACTION_SUB_PAGE,
  getBrandsAction,
} from "../../constants";
import { IStore, IStoresFilters } from "../../axios/getBrands";

export const getBrands = (filters: IStoresFilters): IGetBrandsAction => ({
  type: getBrandsAction.requested,
  payload: filters,
});

export const getBrandsSuccess = (data: IStore[]): IGetBrandsSuccessAction => ({
  type: getBrandsAction.fulfilled,
  payload: data,
});

export const getBrandsStatisticsSuccess = (
  data: IStore[]
): IGetBrandsStatisticsSuccessAction => ({
  type: ACTION_GET_BRANDS_STATISTICS_SUCCESS,
  payload: data,
});
export const getBrandsFailure = (error): IGetBrandsFailureAction => ({
  type: getBrandsAction.rejected,
  payload: error,
});

export const filterBrands = (filters): IFilterBrandsAction => ({
  type: ACTION_FILTER_BRANDS,
  payload: filters,
});

export const resetFilterBrands = (): IResetFilterBrandsAction => ({
  type: ACTION_RESET_FILTER_BRANDS,
});

export const addPage = () => ({
  type: ACTION_ADD_PAGE,
});

export const subPage = () => ({
  type: ACTION_SUB_PAGE,
});

export const resetPage = () => ({
  type: ACTION_RESET_PAGE,
});

export const enableLoadMore = () => ({
  type: ACTION_ENABLE_LOAD_MORE,
});

export const disableLoadMore = () => ({
  type: ACTION_DISABLE_LOAD_MORE,
});
