import * as React from "react";
interface IProps {
  is_active: boolean;
}
const Icon: React.FC<IProps> = ({ is_active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={is_active ? "#F09440" : "#000"}
          fillRule="nonzero"
          d="M12.636 8.695h-1.304V6.087h1.304c.182 0 .345-.114.408-.284.063-.171.013-.363-.125-.48L6.832.104c-.162-.14-.403-.14-.566 0L.18 5.322c-.138.118-.188.31-.124.48.062.171.225.285.407.285h1.304v2.608H.462c-.24 0-.435.195-.435.435v3.478c0 .241.195.435.435.435h1.304v6.087H.462c-.24 0-.435.194-.435.435 0 .24.195.435.435.435h12.174c.24 0 .435-.194.435-.435 0-.24-.195-.435-.435-.435h-1.304v-6.087h1.304c.24 0 .435-.194.435-.435V9.13c0-.24-.195-.435-.435-.435zM6.549 1.007l4.912 4.21H1.637l4.912-4.21zm-3.913 5.08h7.826v2.608H2.636V6.087zm3.913 10.557l3.108 2.486H3.44l3.108-2.486zM2.636 18.66v-5.147l3.217 2.574-3.217 2.573zm4.61-2.573l3.216-2.574v5.147l-3.217-2.573zm-.697-.557L3.44 13.043h6.216L6.55 15.53zm5.652-3.356H.897V9.565H12.2v2.609z"
          transform="translate(3.333)"
        />
        <path
          fill=""
          d="M-3.333 0H16.667V20H-3.333z"
          transform="translate(3.333)"
        />
      </g>
    </svg>
  );
};
export default Icon;
