import axios from "../axios";
import { brandPosIntegrationStatusURL } from "../requests";
export const getBrandPosIntegrationStatusApi = (
  status: "loyalty-integration-status" | "ordering-integration-status",
  token: string,
  pos_entity_id: string,
  store_id: string
) => {
  return axios.get(brandPosIntegrationStatusURL(status), {
    baseURL: process.env.POS_ENDPOINT,
    headers: {
      token,
    },
    params: {
      store_id,
      pos_entity_id,
    },
  });
};
