import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import moment from "moment";
import {
  createAnnouncement,
  createAnnouncementFailure,
  createAnnouncementSuccess,
} from "../actions/announcementsActions";
import { createAnnouncementAction } from "../actions/constants";
import { createAnnouncementAPI } from "../../axios/createAnnouncement";
import {
  SystemAnnouncementTargeting,
  SystemAnnouncementType,
} from "../../types";
import { appendFormData, countCSVRows } from "./createBannerCardSaga";

const actionType = union({ createAnnouncement });

function* createAnnouncementSaga({ payload }: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const formData = new FormData();
    const append = appendFormData.bind(null, formData);

    append("image", payload.image);
    append("routing_type", SystemAnnouncementType.screen);
    append("visibility", payload.visibility);
    append("routing_value", payload.routing_value);
    append("ordering_promotion_id", payload.ordering_promotion_id);
    append("routing_meta_data", JSON.stringify(payload.routing_meta_data));
    append("device_type", payload.device_type);
    append("start_time", moment().format("x"));
    append("end_time", moment().add(100, "year").format("x"));
    let target_users;
    if (
      payload.visibility === SystemAnnouncementTargeting.private &&
      payload.customers_file
    ) {
      append("customers_file", payload.customers_file, "customers.csv");

      target_users = yield call(countCSVRows, payload.customers_file as File);
    }
    const res = yield call(createAnnouncementAPI, token, formData);
    yield put(
      createAnnouncementSuccess({
        ...payload,
        ...res.data.announcement,
        customers_file: undefined,
        target_users,
      })
    );
  } catch (e) {
    yield put(createAnnouncementFailure(e));
  }
}

export function* watchCreateAnnouncementSaga() {
  yield takeLatest(createAnnouncementAction.requested, createAnnouncementSaga);
}
