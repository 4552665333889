import { action, payload } from "ts-action";
import {
  getStoreTagsAction,
  createStoreTagsAction,
  deleteStoreTagAction,
  editStoreTagAction,
} from "./constants";
import { COMMIT, REVERT } from "redux-optimistic-ui";
import { optReaction, optAction } from "../../../../redux-store/actions/lib";

export interface IStoreTag {
  created_at: number;
  id: string;
  updated_at: number;
  name_ar: string;
  name_en: string;
}
export const getStoreTag = action(getStoreTagsAction.requested);
export const getStoreTagSuccess = action(
  getStoreTagsAction.fulfilled,
  payload<IStoreTag[]>()
);
export const getStoreTagFailure = action(
  getStoreTagsAction.rejected,
  payload<Error>()
);

export const createStoreTag = optAction(
  createStoreTagsAction.requested,
  payload<IStoreTag>()
);

export const createStoreTagSuccess = optReaction(
  createStoreTagsAction.fulfilled,
  payload<IStoreTag>(),
  COMMIT
);
export const createStoreTagFailure = optReaction(
  createStoreTagsAction.rejected,
  payload<Error>(),
  REVERT
);

export const editStoreTag = optAction(
  editStoreTagAction.requested,
  payload<IStoreTag>()
);

export const editStoreTagSuccess = optReaction(
  editStoreTagAction.fulfilled,
  payload<IStoreTag>(),
  COMMIT
);
export const editStoreTagFailure = optReaction(
  editStoreTagAction.rejected,
  payload<Error>(),
  REVERT
);

export const deleteStoreTag = optAction(
  deleteStoreTagAction.requested,
  payload<IStoreTag>()
);
export const deleteStoreTagSuccess = optReaction(
  deleteStoreTagAction.fulfilled,
  payload<IStoreTag>(),
  COMMIT
);
export const deleteStoreTagFailure = optReaction(
  deleteStoreTagAction.rejected,
  payload<IStoreTag>(),
  REVERT
);
