import { ICreateCategory } from "../../types";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import {
  withLoadingReducer,
  ILoadingState,
} from "../../../../redux-store/reducers/withLoadingState";
import { Reducer } from "redux";
import { getCategoriesAction } from "../actions/constants";
import { on, reducer, union } from "ts-action";
import {
  getCategoriesSuccess,
  createCategory,
  deleteCategory,
  editCategory,
  createCategorySuccess,
  editCategorySuccess,
} from "../actions/categoriesAction";
import { convertToIds, mapById } from "./lib";

interface IState {
  categories: string[];
  categoriesById: { [x: string]: ICreateCategory };
}

const actionType = union({ createCategorySuccess, editCategorySuccess });

const handleServerPersist = (
  state: IState,
  { payload, meta }: typeof actionType
) => {
  const id = meta.optimistic.id.toString();
  return {
    ...state,
    categories: state.categories.map((catId) =>
      catId === id ? payload.id : catId
    ),
    categoriesById: {
      ...state.categoriesById,
      [payload.id]: payload,
    },
  };
};

export const categoriesReducer = optimistic(
  withLoadingReducer(
    reducer<IState>(
      [
        on(getCategoriesSuccess, (state, { payload }) => ({
          ...state,
          categories: convertToIds(payload),
          categoriesById: mapById(payload),
        })),
        on(createCategory, (state, action) => {
          const id = action.meta.optimistic.id.toString();
          return {
            ...state,
            categories: [...state.categories, id],
            categoriesById: {
              ...state.categoriesById,
              [id]: action.payload,
            },
          };
        }),
        on(createCategorySuccess, handleServerPersist),
        on(editCategorySuccess, handleServerPersist),
        on(editCategory, (state, action) => {
          const oldCat = state.categoriesById[action.payload.id];
          return {
            ...state,
            categories: state.categories,
            categoriesById: {
              ...state.categoriesById,
              [action.payload.id]: {
                ...oldCat,
                ...action.payload,
              },
            },
          };
        }),
        on(deleteCategory, (state, action) => {
          return {
            ...state,
            categories: state.categories.filter(
              (item) => item !== action.payload.id
            ),
          };
        }),
      ],
      { categories: [], categoriesById: {} }
    ),
    getCategoriesAction
  )
) as Reducer<OptimisticState<IState & ILoadingState>>;
