import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  updateBrandsWithTasksFilter,
  getBrandsWithTasks,
  getContactTemplates,
  getResolvedActions,
  getBrandsWithTasksReset,
  selectStoreIdAction,
  resolveTasksReset,
} from "../../redux-store/actions";
import BrandActionsCard from "./BrandActionsCard";
import ResolvedActionsCard from "./ResolvedActionsCard";
import styles from "./styles.module.sass";
import { useSelect } from "../../hooks/useSelect";
import { MultiSelect, Select } from "../../UIComponents/antd-components";
import { IOptionItem } from "../../UIComponents/antd-components/types";
import { ALERTS, ContactMethodType } from "../../types";
import { useRecoilState } from "recoil";
import { selectedContactMethodsAtom } from "../../atoms";
import { LoadingStatus } from "../../redux-store/reducers/withLoadingState";
const AmsActionCenter: React.FC = () => {
  const dispatch = useDispatch();
  const {
    account_managers,
    account_manager_id,
    shownBrandsTasksIds,
    reslove_loading_status,
    contactTemplatesById,
    alert_types,
  } = useSelect((state) => ({
    account_managers: state.getAdminsByRoleReducer.account_manager,
    ...state.actionCenterBrandsReducer,
    reslove_loading_status: state.resolveTasksReducer.loadingStatus,
    ...state.getContactTemplatesReducer,
  }));
  const loggedInAccountManagerId = useMemo(
    () => (account_managers || []).find((element) => element.logged_in)?.id,
    [account_managers]
  );
  const [, setSelectedContactMethodsState] = useRecoilState(
    selectedContactMethodsAtom
  );
  useEffect(() => {
    setSelectedContactMethodsState((prev) => ({
      ...prev,
      contact_methods_view: undefined,
      selected_contact_methods: undefined,
    }));
    dispatch(selectStoreIdAction(""));
    dispatch(getBrandsWithTasksReset());
  }, []);
  useEffect(() => {
    if (loggedInAccountManagerId) {
      dispatch(
        getBrandsWithTasks({ account_manager_id: loggedInAccountManagerId })
      );
      dispatch(
        getResolvedActions({
          page: 0,
          account_manager_id: loggedInAccountManagerId,
        })
      );
    }
  }, [loggedInAccountManagerId]);

  useEffect(() => {
    if (Object.values(contactTemplatesById).length) {
      setSelectedContactMethodsState((prev) => ({
        ...prev,
        resolve_all: {
          contact_methods_view: {
            [ContactMethodType.Whatsapp]: {
              message: contactTemplatesById[1].content,
              selected: true,
            },
            [ContactMethodType.SMS]: {
              message: contactTemplatesById[2].content,
              selected: false,
            },
            [ContactMethodType.Email]: {
              message: contactTemplatesById[3].content,
              selected: false,
            },
          },
          selected_contact_methods: {
            [ContactMethodType.Whatsapp]: true,
            [ContactMethodType.SMS]: false,
            [ContactMethodType.Email]: false,
          },
        },
      }));
    }
  }, [contactTemplatesById]);
  useEffect(() => {
    if (reslove_loading_status !== LoadingStatus.idle) {
      dispatch(resolveTasksReset());
    }
  }, [reslove_loading_status]);
  useEffect(() => {
    if (shownBrandsTasksIds.length) {
      dispatch(getContactTemplates({ template_code: 0 }));
    }
  }, [shownBrandsTasksIds]);
  const alert_type_options = [
    { value: ALERTS.transaction_drop, label: "%TXN" },
    { value: ALERTS.orders_drop, label: "Total orders" },
    { value: ALERTS.fulfilment, label: "Fulfillment rate" },
  ];

  const account_manager_options: IOptionItem<string>[] = useMemo(
    () =>
      account_managers.map(
        (account_manager): IOptionItem<string> => ({
          label: account_manager.name,
          value: account_manager.id,
        })
      ),
    [account_managers]
  );
  const defaultAccountManager = useMemo(() => {
    const loggedinAccountManager = account_managers.find(
      (account_manager) => account_manager.logged_in
    );
    return {
      value: loggedinAccountManager?.id,
      label: loggedinAccountManager?.name,
    };
  }, [account_managers]);

  const defaultAlertTypes = useMemo(
    () => alert_type_options.map((element) => element.value),
    [alert_type_options]
  );

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          marginBottom: "24px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Select
          options={account_manager_options}
          onChange={({ value }) => {
            dispatch(getBrandsWithTasks({ account_manager_id: value }));
            dispatch(
              updateBrandsWithTasksFilter({
                alert_types: defaultAlertTypes,
                account_manager_id: value,
              })
            );
            dispatch(
              getResolvedActions({ page: 0, account_manager_id: value })
            );
          }}
          defaultValue={defaultAccountManager as IOptionItem<string>}
          label={"AMs"}
        />
        <div style={{ margin: "0 8px" }} />
        <MultiSelect
          options={alert_type_options}
          label={"Alert type"}
          defaultValues={alert_types}
          onChange={(values) => {
            dispatch(
              updateBrandsWithTasksFilter({
                alert_types: values as ALERTS[],
                account_manager_id:
                  account_manager_id ?? loggedInAccountManagerId,
              })
            );
          }}
        />
      </div>
      <div className={styles.container}>
        <BrandActionsCard />
        <ResolvedActionsCard />
      </div>
    </div>
  );
};

export default AmsActionCenter;
