import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getBrandsFailure,
  getBrandsStatisticsSuccess,
  getBrandsSuccess,
} from "../actions";
import { getBrands, IStore } from "../../axios/getBrands";
import { selectToken } from "../selectors";
import { getBrandsAction } from "../../constants";
import { IGetBrandsAction } from "../../types";
import { getBrandsStatistics } from "../../axios/getBrandsStatistics";

export interface IGroupedData {
  grouping_criteria: string;
  stores: IStore[];
}

// const brandsGrouping: { [x: string]: ISelectItemData[] } = {
//   countries: countriesForGrouping,
//   status: brandStatusesForGrouping,
//   paymentStatuses: paymentStatusesForGrouping,
//   activity: activityForGrouping,
//   all: [{
//     value: 'all', label: 'All groups'
//   }]
// };

function* getBrandsStats() {
  try {
    const token = yield select(selectToken);
    const statisticsRes = yield call(getBrandsStatistics, token);
    yield put(getBrandsStatisticsSuccess(statisticsRes.data));
    // tslint:disable-next-line:no-empty
  } catch {}
}

function* brandsSaga(action: IGetBrandsAction) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getBrands, token, {
      group: action.payload.group,
    });
    yield put(getBrandsSuccess(res.data));
    yield* getBrandsStats();
    // let nextGroupingCriteria: ISelectItemData = yield select(selectNextGroupCriteria);
    // let currentGroupingCriteria: ISelectItemData;
    // const lastGroup: string = yield select(selectLastGroup);
    // if (action.payload.group && action.payload.group !== lastGroup) {
    //   nextGroupingCriteria = brandsGrouping[action.payload.group][0];
    //   yield put(resetPage());
    // }
    // const lastPage: number = yield select(selectLastPage);
    // currentGroupingCriteria=nextGroupingCriteria;
    // yield put(addPage());
    // if(nextGroupingCriteria){
    //   const res = yield call(getBrands, token, {
    //   page: lastPage,
    //   group: action.payload.group,
    //   groupingCriteria: nextGroupingCriteria
    // });
    // const brands = res.data || [];
    // if (brands.length < 20 && action.payload.group) {
    //   yield put(resetPage());
    //   const nextGroupingCriteriaIndex: number = brandsGrouping[action.payload.group]
    //      .findIndex(group => group.value === nextGroupingCriteria.value);
    //    nextGroupingCriteria = brandsGrouping[action.payload.group][nextGroupingCriteriaIndex + 1];
    // }
    //
    //  yield put(getBrandsSuccess({
    //    brands, nextGroupingCriteria,currentGroupingCriteria,
    //    group: (action.payload.group as string)
    //  }));
    //  }else{
    //    notify.show('There are no more brands to load','error');
    //    yield put(getBrandsSuccess({
    //      brands:[],
    //      nextGroupingCriteria:{label:'Finished',value:-1}
    //      ,currentGroupingCriteria:{label:'Finished',value:-1},
    //      group: (action.payload.group as string),
    //    }));
    //    yield put(disableLoadMore());
    //  }
  } catch (e) {
    yield put(getBrandsFailure(e));
  }
}

export function* watchBrandsSaga() {
  yield takeLatest(getBrandsAction.requested, brandsSaga);
}
