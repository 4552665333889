import * as React from "react";
import * as styles from "./styles.module.sass";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { required } from "../../redux-store/validators";
import { hot } from "react-hot-loader";
import { RenderDropDownSelect } from "../../Components/FormElements/RenderDropDownSelect";
import { RenderDatePicker } from "../../Components/FormElements/RenderDatePicker";
import { normalizeDate } from "../../redux-store/transformers";
import { paymentTermsSelectItems } from "../../constants";

class PaidForm extends React.Component<{} & InjectedFormProps> {
  public render() {
    return (
      <form autoComplete="off" className={styles.wrapper}>
        <h1 className={styles.title}>Add payment Plan</h1>
        <div className={styles.fieldsWrapper}>
          <Field
            name="activation_date"
            component={RenderDatePicker as any}
            label="Activation date"
            validate={[required]}
            normalize={normalizeDate}
          />
          <Field
            name="payment_term"
            component={RenderDropDownSelect as any}
            options={paymentTermsSelectItems}
            label="Payment term"
            validate={[required]}
          />
        </div>
      </form>
    );
  }
}

const PaidFormComponent = reduxForm({
  form: "paidForm",
})(PaidForm);
export default hot(module)(PaidFormComponent) as any;
