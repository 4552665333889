import { Mutations } from "../../queries";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeMerchantDealsAtom } from "../../atoms";

export const useDriveThruDeal = (store_id, data) => {
  const [activeMerchantDeals, setActiveMerchantDeals] = useRecoilState(
    activeMerchantDealsAtom
  );

  const {
    mutate: mutateDriveThruDeal,
    variables: mutateDriveThruDealVariables,
    reset: mutationDriveThruDealReset,
    status: mutateDriveThruDealStatus,
  } = Mutations.editActiveMerchantDeals(store_id, "drive-thru-status");
  const driveThruToggle = (checked: boolean) => {
    setActiveMerchantDeals((prevState) => ({
      ...prevState,
      drive_thru_status: {
        status: checked ? "service_enabled" : "service_disabled",
        loading: "loading",
      },
    }));
    mutateDriveThruDeal(checked ? "service_enabled" : "service_disabled");
  };
  useEffect(() => {
    if (mutateDriveThruDealStatus === "success") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        drive_thru_status: {
          status: prevState.drive_thru_status.status,
          loading: "success",
        },
      }));
      mutationDriveThruDealReset();
    } else if (mutateDriveThruDealStatus === "error") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        drive_thru_status: {
          status: data?.drive_thru_status,
          loading: "error",
        },
      }));
    }
  }, [mutateDriveThruDealVariables, mutateDriveThruDealStatus, data]);

  return {
    driveThruToggle,
    activeMerchantDeals,
  };
};
