import * as React from "react";
import * as styles from "./style.module.sass";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  formValueSelector,
} from "redux-form";
import {
  fullTimeValidator,
  required,
  max_size,
} from "../../../../redux-store/validators";
import { RenderTextAreaGenericField } from "../../../FormElements/RenderTextAreaGenericField";
import { IRootReducerState } from "../../../../redux-store/rootReducer";
import { connect, useDispatch } from "react-redux";
import { sendSmsMessage } from "../../../../redux-store/actions/sendSmsMessageAction";
import Loader from "react-loader-spinner";
import RenderFileField from "../../../SharedComponent/RenderFileField/RenderFileField";
import { hot } from "react-hot-loader";
export const defaultCSVExample = require("../../../../../public/exmples/text.csv")
  .default;
import { RenderFullTimePicker } from "../../../FormElements/RenderFullTimePicker";
import { Drawer } from "@material-ui/core";

interface ISendSms {
  successNumber: number;
  failedNumber: number;
  is_loading: boolean;
  is_success: boolean;
  file_data: File;
  open: boolean;
  handleClose: () => void;
  PostSendSmsMessage: (data) => void;
}

const SendSMS: React.FC<ISendSms & InjectedFormProps> = (props) => {
  const {
    // successNumber,
    // failedNumber,
    is_loading,
    // is_success,
    file_data,
    handleClose,
    open,
    handleSubmit,
    reset,
  } = props;

  const dispatch = useDispatch();
  const handleSubmission = (values) => {
    const formData = new FormData();
    formData.append("message", values.message);
    formData.append("data", values.data[0]);
    formData.append("scheduled_at", values.scheduling_datetime.timestamp);
    dispatch(sendSmsMessage(formData));
    reset();
    handleClose();
  };
  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <span className={styles.title}>Add SMS</span>
          <img
            onClick={handleClose}
            className={styles.closeIcon}
            src={require("../../../../assets/icon-close.svg")}
          />
        </div>
        <div className={styles.formWrapper}>
          <form onSubmit={handleSubmit(handleSubmission)}>
            <Field
              name="message"
              component={RenderTextAreaGenericField as any}
              label="SMS body"
              placeholder={"Write your SMS message here..."}
              className={styles.select}
              validate={[required, max_size]}
            />
            <div className={styles.fileWrapper}>
              <Field
                name="data"
                type="file"
                component={RenderFileField as any}
                label="Upload CSV file"
                validate={[required]}
                className="form-control"
              />
              <a
                className={styles.fileLab}
                href={defaultCSVExample}
                download={true}
              >
                Download CSV template{" "}
              </a>
            </div>
            {file_data === undefined ? (
              ""
            ) : file_data[0] === undefined ? (
              ""
            ) : (
              <span className={styles.fileName}>{file_data[0].name}</span>
            )}
            <div className={styles.separator} />
            <Field
              className={styles.datePicker}
              name="scheduling_datetime"
              validate={[fullTimeValidator]}
              component={RenderFullTimePicker}
              label={"Schedule at"}
            />

            <button type="submit" className={styles.submitBtn}>
              {is_loading ? (
                <Loader type="Puff" color="#fff" height={25} width={25} />
              ) : (
                "SEND SMS"
              )}
            </button>
          </form>
        </div>
      </div>
    </Drawer>
  );
};

let SendSMSForm = reduxForm({
  form: "SendSMSForm",
})(SendSMS);

const selector = formValueSelector("SendSMSForm");
SendSMSForm = connect((state) => ({
  file_data: selector(state, "data"),
}))(SendSMSForm) as any;

const mapStateToProps = (state: IRootReducerState) => ({
  successNumber: state.SendSmsMessageReducer.successNumber,
  failedNumber: state.SendSmsMessageReducer.failedNumber,
  is_loading: state.SendSmsMessageReducer.is_loading,
  is_success: state.SendSmsMessageReducer.is_success,
});
// const mapDispatchToProps = (dispatch: Dispatch<ISendSmsMessage>) => ({
//   PostSendSmsMessage: data => dispatch(sendSmsMessage(data))
// });

export default hot(module)(connect(mapStateToProps, null)(SendSMSForm) as any);
