import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  createCategory,
  createCategoryFailure,
  createCategorySuccess,
} from "../actions/categoriesAction";
import { createCategoryAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { createCategoryAPI } from "../../axios/createCategory";
import { customNotify } from "../../../../UIComponents/customNotification/customNotification";

const actionType = union({ createCategory });

function* createCategorySaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);

  const formData = new FormData();
  formData.append("name_ar", payload.name_ar);
  formData.append("name_en", payload.name_en);
  formData.append("active_image", payload.active_image);
  formData.append("inactive_image", payload.inactive_image);

  const res = yield call(createCategoryAPI, token, formData);
  res.status === 200
    ? customNotify.success(
        `[${payload.name_en}-${payload.name_ar}] addedd successfully`
      )
    : customNotify.error("There is something wrong");
  return { ...payload, ...res.data };
}

export function* watchCreateCategorySaga() {
  yield takeLatest(
    createCategoryAction.requested,
    handleOptimism(createCategorySaga, {
      success: createCategorySuccess,
      failure: createCategoryFailure,
    })
  );
}
