import * as React from "react";
import * as stringDirection from "string-direction";
import { useMemo } from "react";

export const DirectedText: React.FC<
  { text?: string } & React.HTMLAttributes<HTMLSpanElement>
> = ({ text, ...otherProps }) => {
  const direction = useMemo(() => {
    return stringDirection.getDirection(
      (text || "")?.toString().substring(0, 1)
    );
  }, [text]);
  return <div {...otherProps} style={{ ...otherProps.style, direction }} />;
};
