import React from "react";
import { Input } from "..";
import styles from "./styles.module.sass";
interface IProps {
  control: any;
  masked_promo_codes_name: string;
  masked_values_name: string;
  setError: any;
  initialValue?: any;
  isEditing?: boolean;
}
const MaskedPromoShares: React.FC<IProps> = ({
  control,
  masked_promo_codes_name,
  masked_values_name,
  setError,
  initialValue,
  isEditing,
}) => {
  return (
    <div className={styles.maskedPromoSharesWrapper}>
      <span className={styles.maskedPromoLabel}>Masked promo</span>
      <div className={styles.maskedPromoInputsWrapper}>
        <Input
          initialValue={initialValue.masked_promo_codes}
          setError={setError}
          control={control}
          name={masked_promo_codes_name}
          label={"Masked promocode"}
          isEditing={isEditing}
          rules={{
            required: {
              value: true,
              message: "masked promocode is required",
            },
          }}
          placeholder="Eg.. Koin-50"
        />

        <div style={{ margin: "0 10px" }} />
        <Input
          initialValue={initialValue.masked_values}
          setError={setError}
          rules={{
            min: {
              value: 0,
              message: "Brand share should be greater than or equal 0",
            },
            max: {
              value: 100,
              message: "Brand share should be less than or equal 100",
            },
          }}
          min={"0"}
          max={"100"}
          pattern="^[0-100]"
          percentage={true}
          control={control}
          name={masked_values_name}
          label={"Brand share"}
        />
      </div>
    </div>
  );
};

export default MaskedPromoShares;
