import * as React from "react";
import * as styles from "./styles.module.sass";
import { IPaymentLogs } from "../../../types/index";
import * as moment from "moment";

interface IProps {
  log: IPaymentLogs;
}

class Log extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { log } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.collection}>
          <div className={styles.dot} />
          <div className={styles.date}>
            {moment(log.created_at, "x").format("MM/DD")}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.text}>
            {log.store_name}{" "}
            {log.type === "verify"
              ? "payment verified"
              : "next payment confirmed"}
          </div>
          {log.comment && (
            <div className={styles.comment}>
              <div className={styles.adminComment}>Admin commented: </div>
              <div className={styles.commentText}>{log.comment}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Log;
