import { editBrand } from "./../../axios/editBrand";
import { useSelect } from "./../../hooks/useSelect";
import { useMutation } from "react-query";

export const editBrandMutation = (store_id) => {
  const { token } = useSelect((state) => ({
    token: state.authReducer.token,
  }));
  return useMutation((values) =>
    editBrand(token, { ...(values as any), store_id })
  );
};
