import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  getPushNotifications,
  getPushNotificationsFailure,
  getPushNotificationsSuccess,
} from "../actions/pushNotification";
import { selectToken } from "../../../../redux-store/selectors";
import { getPushNotificationAPI } from "../../axios/get-push-notification";
import { getPushNotificationsAction } from "../actions/constants";
import { union } from "ts-action";
const actionType = union({ getPushNotifications });
function* pushNotificationsSaga({ payload }: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const { page, per_page } = payload;
    const res = yield call(getPushNotificationAPI, token, page, per_page);
    yield put(
      getPushNotificationsSuccess({
        campaigns: res.data.sms_campaigns,
        total_campaigns: res.data.total_push_campaigns,
      })
    );
  } catch (e) {
    yield put(getPushNotificationsFailure(e));
  }
}
export function* watchGetPushNotificationsSaga() {
  yield takeLatest(getPushNotificationsAction.requested, pushNotificationsSaga);
}
