import { useProgressState } from "./useProgressState";
import { useState, useEffect } from "react";
import { getUXCamStatus } from "../../axios/getUXCamStatus";
import { setUXCamStatus as setUXCamStatusAPI } from "../../axios/setUXCamStatus";

export const useShopXSettings = (token: string) => {
  const [uxCamStatus, setUXCamStatus] = useState();
  const { loading, setFailure, setLoading, setSuccess } = useProgressState();
  const [initialLoading, setInitialLoading] = useState(false);

  const toggleUXCam = () => {
    setLoading();
    const newStatus = !uxCamStatus;
    setUXCamStatus(newStatus);
    setUXCamStatusAPI(token, Number(newStatus) as 0 | 1)
      .then(setSuccess)
      .catch(() => {
        setUXCamStatus(!newStatus);
        setFailure();
      });
  };

  useEffect(() => {
    getUXCamStatus().then(({ data: { status } }) => {
      setInitialLoading(true);
      setUXCamStatus(Boolean(status));
    });
  }, []);
  return {
    initialLoading,
    submitting: loading,
    toggleUXCam,
    uxCamStatus,
  };
};
