import React from "react";
import styles from "./styles.module.sass";
interface IProps {
  state: any;
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
  title: string;
  disabled?: boolean;
}
const Tab: React.FC<IProps> = ({ state, onClick, style, title, disabled }) => {
  return (
    <div
      className={`${disabled ? styles.disabledWrapper : styles.wrapper}`}
      style={{
        ...style,
        backgroundColor: state ? "#f09440" : "#FFFFFF",
        color: state ? "#FFFFFF" : "#f09440",
      }}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default Tab;
