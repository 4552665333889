import * as React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import styles from "./styles.module.sass";
import { useEffect, useState } from "react";
import { useToggleState } from "../../ShopXSettings/useToggleState";

export interface ISelectItemData<T = any> {
  value: T;
  text: string;
  disabled?: boolean;
}
interface IProps {
  options: ISelectItemData[];
  dropdownIcon?: React.ImgHTMLAttributes<HTMLImageElement>;
  onChange: (value: ISelectItemData) => void;
  initialValue?: ISelectItemData;
  className?: string;
  disabled?: boolean;
  renderSelectItem?: (item: ISelectItemData) => React.ReactChild;
  isOpen?: boolean;
  label?: string;
  placeholder?: string;
  showLabelWithValue?: boolean;
}

const SelectItemWrapper: React.FunctionComponent<{
  onSelect: (value: any) => void;
  value: any;
  disabled?: boolean;
}> = (props) => {
  const onSelect = () => props.onSelect(props.value);
  return (
    <DropdownItem
      disabled={props.disabled}
      className={styles.dropdownItem}
      onClick={onSelect}
    >
      {props.children}
    </DropdownItem>
  );
};

export const Select: React.FunctionComponent<IProps> = (props) => {
  const {
    options,
    className,
    disabled,
    onChange,
    initialValue,
    label,
    placeholder,
  } = props;
  const [value, selectValue] = useState(initialValue || options[0]);
  const [isOpen, toggleMenu] = useToggleState(false);
  useEffect(() => {
    if (initialValue) {
      selectValue(initialValue);
    }
  }, [initialValue]);
  const handleChange = (v: ISelectItemData) => {
    selectValue(v);
    onChange(v);
  };

  return (
    <div className={`${className || ""} ${styles.dropDownPart}`}>
      {!props.showLabelWithValue && label && (
        <span className={styles.label}>{label}</span>
      )}
      <Dropdown isOpen={isOpen || props.isOpen} toggle={toggleMenu}>
        <DropdownToggle disabled={disabled} className={styles.switch}>
          {props.showLabelWithValue ? (
            <React.Fragment>
              <span className={styles.labelWithValue}>
                {label} {value?.text ?? placeholder}
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span
                style={value.text.length ? {} : { color: "#8E8E93" }}
                className={styles.selectedItem}
              >
                {value.text.length ? value.text : placeholder ?? ""}
              </span>
            </React.Fragment>
          )}

          <img
            className={styles.dropdownIcon}
            src={
              props.dropdownIcon
                ? props.dropdownIcon
                : require("../../../assets/chevron-down.svg")
            }
          />
        </DropdownToggle>

        <DropdownMenu className={styles.menuWrapper}>
          {options.map((option, index) => (
            <SelectItemWrapper
              key={index}
              value={option}
              disabled={option.disabled}
              onSelect={() => {
                handleChange(option);
              }}
            >
              {option.text}
            </SelectItemWrapper>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
