import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getPosProvidersAction } from "../../constants";
import {
  getPosProvidersSuccess,
} from "../actions/pos-actions";
import { ISelectItemData } from "../../Components/FormElements/RenderDropDownSelect";

interface IPosProvidersState {
  posProviders: ISelectItemData[];
}

export const posProvidersReducer = withLoadingReducer<IPosProvidersState>(
  reducer<IPosProvidersState>(
    [
      on(getPosProvidersSuccess, (state, { payload }) => ({
        ...state,
        posProviders: [
          ...payload.map((item) => ({
            label: item.name,
            value: item.value,
          })),
        ],
      })),
    ],
    {
      posProviders: [],
    }
  ),
  getPosProvidersAction
);
