export interface IFormErrors {
  [key: string]: {
    type?: string;
    message?: string;
  };
}

export interface IStartEndDate<T> {
  errors: IFormErrors;
  start_date: number;
  end_date: number;
  control: any;
  header?: string;
  setValue: (field: T, value: any) => void;
  starting_from_today?: boolean;
}

export const dateInputStyle = {
  height: "60px",
  width: "100%",
  borderRadius: "10px",
  paddingLeft: "40px",
};