import React from "react";
import { BeatLoader } from "react-spinners";
import styles from "./styles.module.sass";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  className?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
  viewOnly?: boolean;
  handleClickWrapper: () => void;
  shouldShowLoading?: boolean;
  error?: string;
  noValidationMessages?: boolean;
  postFix?: string;
  touched?: boolean;
}
export const DefaultInput: React.FC<IProps> = ({
  handleClickWrapper,
  label,
  name,
  className,
  isValid,
  isInvalid,
  onBlur,
  disabled,
  viewOnly,
  shouldShowLoading,
  error,
  noValidationMessages,
  postFix,
  touched,
  ...rest
}) => {
  return (
    <div
      onClick={handleClickWrapper}
      className={`${styles.wrapper}  ${className || ""}`}
    >
      <label htmlFor={name} className={styles.customLabel}>
        {label && <span>{label}</span>}
      </label>

      <div
        style={{
          ...(isValid ? { borderColor: "#38c172" } : {}),
          ...(isInvalid ? { borderColor: "#db3030" } : {}),
          ...(disabled ? { cursor: "not-allowed" } : {}),
        }}
        className={`${styles.inputAndLoadingWrapper}`}
      >
        <input
          {...rest}
          onBlur={onBlur}
          disabled={viewOnly || disabled}
          className={styles.input}
          id={name}
          name={name}
        />
        {shouldShowLoading && (
          <BeatLoader loading={true} size={7} color={"#FEBE19"} />
        )}
        {postFix && (
          <div className={styles.postfixWrapper}>
            <span className={styles.postfix}>{postFix}</span>
          </div>
        )}
      </div>
      {!noValidationMessages && error && (
        <p
          style={{
            margin: 0,
            padding: "1rem",
            color: "#db3030",
            fontSize: "1.2rem",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default DefaultInput;
