import { PromoTypes } from "./../../types";
import { useEffect, useState } from "react";
import { store } from "../../../../redux-store/configureStore";
import { useDeepCompareMemo } from "use-deep-compare";
import { PromoMerchantVisibility } from "../../types";

export const useMissingBrandCsvValues = ({ watchers }) => {
  const currentStores = store.getState().CountryStoresReducer.stores;
  const [showMissingCsvValuesCard, setShowMissingCsvValuesCard] = useState(
    false
  );
  const matchedStores: any[] = useDeepCompareMemo(() => {
    return watchers.csv_values
      ?.map((csv_store) => {
        const stores = currentStores.find(
          (current_store) =>
            csv_store.store_name.toLowerCase().replace(/\s/g, "") ===
              current_store.label.toLowerCase().replace(/\s/g, "") ||
            csv_store.store_id === current_store.value
        );
        if (stores?.label && stores?.value) {
          return {
            name: stores.label,
            id: stores.value,
            store_fund_percentage: csv_store.merchant_share
              ? Number(csv_store?.merchant_share)
              : "",
            masked_value: csv_store.masked_value
              ? Number(csv_store.masked_value)
              : "",
            masked_promo_code:
              csv_store?.masked_promo_code?.replace(/\s/g, "").length > 0
                ? csv_store?.masked_promo_code?.replace(/\s/g, "")
                : "",
            max_discount_value: csv_store?.max_discount_value
              ? Number(csv_store.max_discount_value)
              : "",
            min_threshold: csv_store?.min_threshold
              ? Number(csv_store.min_threshold)
              : "",
          };
        } else {
          return {
            id: undefined,
            name: undefined,
            store_fund_percentage: undefined,
            masked_value: undefined,
            masked_promo_code: undefined,
            max_discount_value: undefined,
            min_threshold: undefined,
          };
        }
      })
      ?.filter((item) => item?.name && item?.id);
  }, [watchers.csv_values]);
  const isEmpty = (value) => {
    return !String(value).replace(/\s/g, "").length;
  };

  const missingBrandCsvValues: any[] = useDeepCompareMemo(
    () =>
      matchedStores
        ?.filter(
          (element) =>
            ((isEmpty(element?.masked_value) ||
              isEmpty(element?.masked_promo_code)) &&
              watchers.promo_merchant_visibility ===
                PromoMerchantVisibility.masked) ||
            isEmpty(element.store_fund_percentage) ||
            (watchers.type === PromoTypes.percentage &&
              watchers.promo_merchant_visibility !==
                PromoMerchantVisibility.masked &&
              (isEmpty(element.max_discount_value) ||
                isEmpty(element.min_threshold)))
        )
        .map((element, index) => {
          return `${element.name} : Missing (${
            isEmpty(element.masked_value) &&
            watchers.promo_merchant_visibility ===
              PromoMerchantVisibility.masked
              ? "Masked share"
              : ""
          }${
            isEmpty(element.masked_promo_code) &&
            watchers.promo_merchant_visibility ===
              PromoMerchantVisibility.masked
              ? isEmpty(element.masked_value)
                ? ", Masked promo code"
                : "Masked promo code"
              : ""
          }${
            isEmpty(element.store_fund_percentage)
              ? (isEmpty(element.masked_value) ||
                  isEmpty(element.masked_promo_code)) &&
                watchers.promo_merchant_visibility ===
                  PromoMerchantVisibility.masked
                ? ", Merchant share"
                : "Merchant share"
              : ""
          }${
            isEmpty(element.max_discount_value) &&
            watchers.type === PromoTypes.percentage
              ? isEmpty(element.masked_value) ||
                isEmpty(element.masked_promo_code) ||
                isEmpty(element.store_fund_percentage)
                ? ", Capping"
                : "Capping"
              : ""
          }${
            isEmpty(element.min_threshold) &&
            watchers.type === PromoTypes.percentage
              ? isEmpty(element.masked_value) ||
                isEmpty(element.masked_promo_code) ||
                isEmpty(element.store_fund_percentage)
                ? ", Min Eligible Invoice"
                : "Min Eligible Invoice"
              : ""
          })`;
        }),

    [matchedStores, watchers.promo_merchant_visibility, watchers.type]
  );
  useEffect(() => {
    if (missingBrandCsvValues?.length > 0) {
      setShowMissingCsvValuesCard(true);
    } else {
      setShowMissingCsvValuesCard(false);
    }
  }, [missingBrandCsvValues]);
  return {
    missingBrandCsvValues,
    setShowMissingCsvValuesCard,
    showMissingCsvValuesCard,
  };
};
