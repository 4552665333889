import React, { useCallback } from "react";
import { WrappedFieldProps } from "redux-form";
import { SettingsSwitch } from "./settingsSwitch";
import * as styles from "./style.module.sass";

interface IProps {
  className: string;
  disabled?: boolean;
  color: string;
  enableDisableEnum?: any;
}

export enum PromoCodesUniversalToggleStatues {
  enable = "enable",
  disable = "disable",
}

export enum PromoCodesPreselectedStatues {
  enable = "enable",
  disable = "disable",
}

const ToggleSwitch: React.FC<WrappedFieldProps & IProps> = (props) => {
  const { input, disabled, color, enableDisableEnum } = props;
  const { disable, enable } =
    enableDisableEnum ?? PromoCodesUniversalToggleStatues;
  const handleChange = useCallback(
    (checked) => {
      input.onChange(checked ? enable : disable);
    },
    [input]
  );

  return (
    <SettingsSwitch
      disabled={disabled}
      onColor={color}
      offColor={"#fff"}
      className={styles.switch}
      checked={input.value === enable}
      onChange={handleChange}
    />
  );
};

export default ToggleSwitch;
