import moment from "moment-timezone";

type Moment = moment.Moment;
/**
 * @name FULL_DATE_FORMAT
 * @description
 * # FULL_DATE_FORMAT
 * Month Day Year
 */
export const FULL_DATE_FORMAT = "MM DD YYYY";

/**
 * @name FULL_TIME_FORMAT
 * @description
 * # FULL_TIME_FORMAT
 * hour:minute:second PM/AM
 */
export const FULL_TIME_FORMAT = "hh mm ss a";

export const CAIRO_TIMEZONE = "Africa/Cairo";

export const getFullDate = (m: Moment): string => m.format(FULL_DATE_FORMAT);
export const getFullTime = (m: Moment): string => m.format(FULL_TIME_FORMAT);
export const getMomentFromDateAndTime = (
  date: string,
  time: string,
  timezone?: string
): Moment =>
  moment.tz(
    `${date} ${time}`,
    `${FULL_DATE_FORMAT} ${FULL_TIME_FORMAT}`,
    timezone as any
  );
export const convertToCairoTimeZone = (timeZonedMoment: Moment): Moment =>
  timeZonedMoment.tz(CAIRO_TIMEZONE);

export const combineDateAndTimeMoments = (
  dateMoment: Moment,
  timeMoment: Moment,
  timezone?: string
) => {
  const date = getFullDate(dateMoment);
  const time = getFullTime(timeMoment);
  return getMomentFromDateAndTime(date, time, timezone);
};
export const getMomentTimeStamp = (m: Moment): number => +m.format("x");

export const getServerTime = (
  dateMoment: Moment,
  timeMoment: Moment,
  timezone: string
): Moment => {
  const combinedDateTime = combineDateAndTimeMoments(
    dateMoment,
    timeMoment,
    timezone
  );
  return convertToCairoTimeZone(combinedDateTime).utc();
};
