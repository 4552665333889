import React, { useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { GenericButton } from "../../SharedComponent/GenericButtonWithIcon";
import AddUserForm from "./AddUserForm";
import Search from "./AddUserForm/Search";
import { useSelect } from "../../../hooks/useSelect";
const User: React.FC = () => {
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const { watchers } = useSelect((state) => state.watchersReducer);
  return (
    <div className={styles.wrapper}>
      <div className={styles.addWrapper}>
        <div className={styles.add}>
          <span className={styles.title}>
            Watch towers users ({watchers.length})
          </span>
          <div className={styles.addUserWrapper}>
            {open && <AddUserForm open={open} onClose={closeDrawer} />}
            <Search />
            <GenericButton
              label={"Add User"}
              className={styles.addUser}
              IconClass={styles.icon}
              onClick={openDrawer}
              iconPath={require("../../../assets/add.svg")}
            />
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.userWrapper}>
          <span className={styles.basicInfo}>Basic info</span>
          <span className={styles.email}>Email</span>
          <span className={styles.country}>Country</span>
          <span className={styles.role}>Role</span>
        </div>
      </div>
    </div>
  );
};

export default hot(module)(User);
