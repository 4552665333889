import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  editPromoCode,
  editPromoCodeSuccess,
  editPromoCodeFailure,
} from "../actions/promoCodeActions";
import { editPromoCodeAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { editPromoCodeAPI } from "../../axios/editPromoCode";
import { notify } from "react-notify-toast";

const actionType = union({ editPromoCode });

function* editPromoCodeSaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  const res = yield call(editPromoCodeAPI, token, payload.id, payload.data);
  const resStatus =
    res.status === 200
      ? notify.show("promo code edited successfully", "success")
      : notify.show("promo code creation failed", "error");
  return {
    ...payload,
    ...res.data,
    resStatus,
  };
}

export function* watchEditPromoCodeSaga() {
  yield takeLatest(
    editPromoCodeAction.requested,
    handleOptimism(editPromoCodeSaga, {
      success: editPromoCodeSuccess,
      failure: editPromoCodeFailure,
    })
  );
}
