import { AxiosReturn } from "./types";
import * as Requests from "./requests";

export enum QuotaStatuses {
  Rejected = 0,
  Accepted = 1,
  Holding = 2,
}

type QuotaStatusesType =
  | QuotaStatuses.Rejected
  | QuotaStatuses.Accepted
  | QuotaStatuses.Holding;

import axios from "./axios";
import { IReachQuotasResponse } from "../redux-store/actions/getReachQuotasActions";

export interface IGetQuotasFilters {
  store_id?: string;
  status?: QuotaStatusesType;
  page?: number;
}

export const getQuotas = (
  token: string,
  filters: IGetQuotasFilters
): AxiosReturn<{ quotas: IReachQuotasResponse[]; total_quotas: number }> => {
  return axios.get(Requests.getQuotas, {
    baseURL: process.env.LUMEN_ENDPOINT,
    headers: {
      token,
    },
    params: filters,
  });
};

export const getQuotasMock = (
  token: string,
  filters: IGetQuotasFilters
): AxiosReturn<{ quotas: IReachQuotasResponse[] }> => {
  return axios.get(
    "http://private-9f707-shopxadminlumen.apiary-mock.com/reach/purchased-quotas",
    {
      baseURL: "",
      headers: {
        token,
      },
      params: filters,
    }
  );
};
