import React, { useState, useCallback } from "react";
import * as styles from "./styles.module.sass";
import { GenericButton } from "../../../../SharedComponent/GenericButtonWithIcon";
import { hot } from "react-hot-loader";
import {
  IStoreTag,
  deleteStoreTag,
} from "../../../redux-store/actions/tags-actions";
import { useDispatch } from "react-redux";
import AddNewTags from "../../AddNewTags/index";
import { confirmDanger } from "../../../BannerCards/lib/confirmDanger";
interface IProps {
  tags: IStoreTag;
}
const BrandTags: React.FC<IProps> = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = () => setOpenModal(false);
  const { tags } = props;
  const { id, name_ar, name_en } = tags;
  const dispatch = useDispatch();
  const handleDeleteTag = useCallback(async () => {
    const result = await confirmDanger(
      "Are you sure you want to delete this tag?"
    );
    if (result.value) {
      dispatch(deleteStoreTag(tags));
    }
  }, [tags.id]);
  return (
    <div className={styles.tags} key={id}>
      <span className={styles.title}>
        {name_en} - {name_ar}
      </span>
      <div className={styles.btnWrapper}>
        <GenericButton
          iconPath={require("../../../../../assets/edit.svg")}
          className={styles.edit}
          onClick={openModalHandler}
          IconClass={styles.icon}
        />
        <div style={{ margin: "0 .2rem" }} />
        <GenericButton
          iconPath={require("../../../../../assets/icon-delete.svg")}
          className={styles.delete}
          onClick={handleDeleteTag}
        />
      </div>
      {openModal && (
        <AddNewTags
          isEditing={true}
          id={id}
          initialValues={{
            ...tags,
          }}
          open={openModal}
          onClose={closeModalHandler}
        />
      )}
    </div>
  );
};

export default hot(module)(BrandTags);
