import { getMerchants, getActiveLocationsStats } from "./merchants";
import {
  editOrderingGMVType,
  getOrderingGMVType,
  getActiveMerchantDeals,
  editActiveMerchantDeals,
  editBrandMutation,
} from "./merchantProfile";
export const Queriers = {
  getMerchants,
  getActiveLocationsStats,
  getOrderingGMVType,
  getActiveMerchantDeals,
};

export const Mutations = {
  editOrderingGMVType,
  editActiveMerchantDeals,
  editBrandMutation,
};
