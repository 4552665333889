import { useQuery } from "react-query";
import { OrderingGMVTypes } from "../../atoms/merchantProfile";
import { getOrderingGMVTypeApi } from "../../axios/merchantProfile";
import { store } from "../../redux-store/configureStore";

export const getOrderingGMVType = (store_id) => {
  const token = store.getState().authReducer.token;
  const result = useQuery<{ gmv_type: OrderingGMVTypes }, Error>(
    ["ordering-gmv-type", store_id],
    async () => await getOrderingGMVTypeApi(token, store_id),
    {
      retry: 2,
      enabled: !!store_id,
    }
  );
  return result;
};
