import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSelectedBrand } from "../../redux-store/actions";
import {
  ContactMethodType,
  IUseBrandContactMethodsReturnType,
  ISelectedContactMethodsState,
  IContactMethodView,
  IContactMethodValue,
} from "../../types";
import { useSelect } from "../useSelect";
import {
  currentContactMethodAtom,
  selectedContactMethodsAtom,
} from "../../atoms";
import { useRecoilState } from "recoil";

export const useBrandContactMethods = (): IUseBrandContactMethodsReturnType => {
  const { selected_store_id, selectedBrandsById } = useSelect((state) => ({
    ...state.actionCenterBrandsReducer,
    ...state.getContactTemplatesReducer,
  }));
  const dispatch = useDispatch();

  const [templateMessageValue, setTemplateMessageValue] = useState<string>("");
  const [activeContactMethod, setActiveContactMethod] = useRecoilState<
    ContactMethodType
  >(currentContactMethodAtom);
  const [
    { resolve_all, contact_methods_view, selected_contact_methods },
    setSelectedContactMethods,
  ] = useRecoilState<ISelectedContactMethodsState>(selectedContactMethodsAtom);
  const handleInitialSelectedContactMethods = useCallback(() => {
    const initialSelectedContactMethods = {};
    const initialSelectContactMessage = {};
    for (const brand of Object.values(selectedBrandsById)) {
      const templatesById = brand.contact_templates.reduce(
        (acc, next) => ({ ...acc, [next.id]: next }),
        {}
      );

      initialSelectedContactMethods[brand.store_id] = {
        1: true,
        2: templatesById[2].selected,
        3: templatesById[3].selected,
      };
      initialSelectContactMessage[brand.store_id] = {
        1: {
          message: templatesById[1].updated_content,
          selected: true,
        },
        2: {
          message: templatesById[1].updated_content,
          selected: false,
        },
        3: {
          message: templatesById[1].updated_content,
          selected: false,
        },
      };
    }
    setSelectedContactMethods((prev) => ({
      ...prev,
      selected_contact_methods: initialSelectedContactMethods,
      contact_methods_view: initialSelectContactMessage,
    }));
  }, [selectedBrandsById]);
  useEffect(() => {
    if (
      typeof selected_contact_methods === "undefined" &&
      typeof contact_methods_view === "undefined"
    ) {
      handleInitialSelectedContactMethods();
    }
  }, [
    contact_methods_view,
    selected_contact_methods,
    handleInitialSelectedContactMethods,
  ]);

  const handleTemplateMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    contact_method: ContactMethodType
  ) => {
    setTemplateMessageValue(event.target.value);

    const selectedStores = selected_store_id
      ? [selected_store_id]
      : Object.values(selectedBrandsById).map((brand) => brand.store_id);

    selectedStores.forEach((storeId) => {
      setSelectedContactMethods((prev: ISelectedContactMethodsState) => {
        const currentContactMethodsView = prev.contact_methods_view?.[storeId];
        if (!currentContactMethodsView) {
          return prev;
        }
        return {
          ...prev,
          resolve_all: selected_store_id
            ? { ...prev.resolve_all }
            : {
                ...prev.resolve_all,
                contact_methods_view: {
                  ...prev.resolve_all.contact_methods_view,
                  [contact_method]: {
                    ...prev.resolve_all.contact_methods_view[contact_method],
                    message: event.target.value,
                  },
                },
              },
          contact_methods_view: {
            ...prev.contact_methods_view,
            [storeId]: {
              ...currentContactMethodsView,
              [contact_method]: {
                ...currentContactMethodsView[contact_method],
                message: event.target.value,
              },
            },
          },
        };
      });

      const currentBrand = selectedBrandsById[storeId];
      const currentContactMethod = currentBrand.contact_templates.find(
        (template) => template.id === activeContactMethod
      );

      if (currentContactMethod) {
        dispatch(
          updateSelectedBrand({
            store_id: selected_store_id ?? undefined,
            contact_templates: {
              id: currentContactMethod.id,
              updated_content: event.target.value,
              selected: currentContactMethod.selected,
            },
          })
        );
      }
    });
  };
  const handleSelectedContactMethods = (contactMethod: ContactMethodType) => {
    setSelectedContactMethods((prevState) => {
      const newState = {
        ...prevState,
        resolve_all: {
          ...prevState.resolve_all,
        },
        selected_contact_methods: {
          ...prevState.selected_contact_methods,
        },
      };
      const updateSelectedContactMethod = (brand) => {
        const storeId = brand.store_id;

        newState.selected_contact_methods[storeId] = {
          ...newState.selected_contact_methods[storeId],
          [contactMethod]: selected_store_id
            ? !newState.selected_contact_methods[storeId][contactMethod]
            : newState.resolve_all.selected_contact_methods[contactMethod],
        };
      };
      if (selected_store_id) {
        updateSelectedContactMethod({ store_id: selected_store_id });
      } else {
        newState.resolve_all.selected_contact_methods = {
          ...newState.resolve_all.selected_contact_methods,
          [contactMethod]: !newState.resolve_all.selected_contact_methods[
            contactMethod
          ],
        };
        Object.values(selectedBrandsById).forEach((brand) =>
          updateSelectedContactMethod(brand)
        );
      }
      return newState;
    });

    const selected = selected_store_id
      ? !selected_contact_methods?.[selected_store_id][contactMethod]
      : !resolve_all.selected_contact_methods[contactMethod];

    const updated_content =
      (selected_store_id
        ? contact_methods_view?.[selected_store_id][contactMethod]?.message
        : resolve_all.contact_methods_view[contactMethod]?.message) ?? "";
    dispatch(
      updateSelectedBrand({
        store_id: selected_store_id ?? undefined,
        contact_templates: {
          id: contactMethod,
          selected,
          updated_content,
        },
      })
    );
  };
  const handleContactMethodTabView = (value: ContactMethodType) => {
    const selectedStores = selected_store_id
      ? [selected_store_id]
      : Object.values(selectedBrandsById).map((brand) => brand.store_id);
    selectedStores.forEach((storeId) => {
      setSelectedContactMethods((prev: ISelectedContactMethodsState) => {
        const currentContactMethodsView = prev.contact_methods_view?.[storeId];
        if (!currentContactMethodsView) {
          return prev;
        }

        const updatedContactMethodsView: any = Object.entries(
          currentContactMethodsView
        ).reduce((acc, [key, valueObj]) => {
          if (key === value.toString()) {
            return {
              ...acc,
              [key]: {
                ...valueObj,
                selected: true,
              },
            };
          } else {
            return {
              ...acc,
              [key]: {
                ...valueObj,
                selected: false,
              },
            };
          }
        }, {});
        const contactMethodIds = [1, 2, 3];
        const newContactMethodsView = Object.fromEntries(
          contactMethodIds.map((id) => [
            id,
            {
              ...(prev.resolve_all?.contact_methods_view?.[id] ?? {}),
              selected: value === id,
            },
          ])
        );
        return {
          ...prev,
          resolve_all: {
            ...prev.resolve_all,
            contact_methods_view: selected_store_id
              ? { ...prev.resolve_all?.contact_methods_view }
              : {
                  ...(newContactMethodsView as IContactMethodValue<
                    IContactMethodView
                  >),
                },
          },
          contact_methods_view: {
            ...prev.contact_methods_view,
            [storeId]: updatedContactMethodsView,
          },
        };
      });
    });
  };

  useEffect(() => {
    if (!selected_store_id && !templateMessageValue?.trim()) {
      setTemplateMessageValue(
        resolve_all.contact_methods_view[activeContactMethod]?.message
      );
    }
  }, [
    templateMessageValue,
    resolve_all.contact_methods_view,
    activeContactMethod,
    selected_store_id,
  ]);

  const checkedContactMethod = (value) => {
    if (selected_contact_methods && selected_store_id) {
      return selected_contact_methods[selected_store_id][value];
    } else if (!selected_store_id && resolve_all.selected_contact_methods) {
      return resolve_all.selected_contact_methods[value];
    }
    return false;
  };

  return {
    handleSelectedContactMethods,
    activeContactMethod,
    setActiveContactMethod,
    handleTemplateMessageChange,
    templateMessageValue,
    handleContactMethodTabView,
    selected_store_id,
    checkedContactMethod,
    setTemplateMessageValue,
  };
};
