import { action, props, payload } from "ts-action";
import { BEGIN, COMMIT, REVERT } from "redux-optimistic-ui";

// if you have an optimistic action
// you should have an optimistic reaction

let id = 0;

const uuid = () => id++;

export const optAction = <P = void>(
  type: string,
  payloadType: { _p: P; _as: "payload" } = payload<P>()
) => {
  const creator = (p2: P) =>
    action(
      type,
      props<{
        payload: typeof payloadType._p;
        meta: { optimistic: { type: string; id: number } };
      }>()
    )({ meta: { optimistic: { type: BEGIN, id: uuid() } }, payload: p2 });

  creator.type = type;

  return creator;
};

export interface IOptReaction<P extends {} = { [x: string]: any }> {
  payload: P;
  meta: {
    optimistic: {
      type: string;
      id: number;
    };
  };
  type: string;
}

export interface IOptReactionCreator<P = {}> {
  (p2: P, optimisticId: any): IOptReaction<P>;
  type: string;
}

export const optReaction = <P = void>(
  type: string,
  payloadType: { _p: P; _as: "payload" } = payload<P>(),
  optimisticOperationType: typeof COMMIT | typeof REVERT
) => {
  const creator: IOptReactionCreator<P> = (p2, optimisticId) =>
    action(
      type,
      props<{
        payload: typeof payloadType._p;
        meta: { optimistic: { type: string; id: number } };
      }>()
    )({
      meta: { optimistic: { type: optimisticOperationType, id: optimisticId } },
      payload: p2,
    });

  creator.type = type;

  return creator;
};
