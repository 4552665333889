import moment from "moment";
import { useState } from "react";
import { getBrand } from "../../axios/getBrand";
import { notify } from "react-notify-toast";
import { useSelect } from "../useSelect";

export const useToggleEditModal = (merchant_id) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [fullData, setFullData] = useState<{ store: any; manager: any }>({
    store: {},
    manager: {},
  });
  const { countries, token } = useSelect((state) => ({
    countries: state.countriesReducer.countries,
    token: state.authReducer.token,
  }));
  const toggleEditBrandModal = () => {
    if (!editModalOpen) {
      getBrand(token, merchant_id)
        .then((res) => {
          const manager = {
            email: res.data.manager.email,
            password: undefined,
            phone_number: res.data.manager.phoneNumber,
            manager_id: res.data.manager.id,
          };
          const storeCountry = countries.find(
            (c) =>
              c.ISO_code.toUpperCase() ===
              res.data.store.country_iso_code.toUpperCase()
          );
          const storeData = {
            name: res.data.store.name,
            country_iso_code: storeCountry || {},
            timezone: res.data.store.timezone,
            status: res.data.store.status,
            activation_date: moment(res.data.store.activation_date, "x"),
            id: res.data.store.id,
            api_key: res.data.store.api_key,
            pos: res.data.store.pos || "none",
          };
          setFullData({ store: { ...storeData }, manager: { ...manager } });
          setEditModalOpen(!editModalOpen);
        })
        .catch(() => {
          notify.show("An error occurred while fetching brand data.", "error");
        });
    } else {
      setEditModalOpen(!editModalOpen);
    }
  };
  return {
    toggleEditBrandModal,
    editModalOpen,
    fullData,
  };
};
