import { action, payload } from "ts-action";
import {
  createBannerCardAction,
  deleteBannerCardAction,
  getBannerCardsAction,
  reOrderBannerCardAction,
} from "./constants";
import {
  ICreateBannerCard,
  IGetBannerCardItem,
  IReorderBannerCardItem,
} from "../../types";
import { optAction, optReaction } from "../../../../redux-store/actions/lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const getBannerCards = action(getBannerCardsAction.requested);
export const getBannerCardsSuccess = action(
  getBannerCardsAction.fulfilled,
  payload<IGetBannerCardItem[]>()
);
export const getBannerCardsFailure = action(getBannerCardsAction.rejected);

export const createBannerCard = optAction(
  createBannerCardAction.requested,
  payload<ICreateBannerCard>()
);
export const createBannerCardSuccess = optReaction(
  createBannerCardAction.fulfilled,
  payload<IGetBannerCardItem>(),
  COMMIT
);
export const createBannerCardFailure = optReaction(
  createBannerCardAction.rejected,
  payload<ICreateBannerCard>(),
  REVERT
);

export const deleteBannerCard = optAction(
  deleteBannerCardAction.requested,
  payload<IGetBannerCardItem>()
);
export const deleteBannerCardSuccess = optReaction(
  deleteBannerCardAction.fulfilled,
  payload<IGetBannerCardItem>(),
  COMMIT
);
export const deleteBannerCardFailure = optReaction(
  deleteBannerCardAction.rejected,
  payload<IGetBannerCardItem>(),
  REVERT
);

export const reOrderBannerCard = optAction(
  reOrderBannerCardAction.requested,
  payload<IReorderBannerCardItem>()
);
export const reOrderBannerCardSuccess = optReaction(
  reOrderBannerCardAction.fulfilled,
  payload<IReorderBannerCardItem>(),
  COMMIT
);
export const reOrderBannerCardFailure = optReaction(
  reOrderBannerCardAction.rejected,
  payload<IReorderBannerCardItem>(),
  REVERT
);
