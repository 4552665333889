import { call, put, takeLatest } from "redux-saga/effects";
import { loginAction } from "../../constants";
import { ILoginAction } from "../../types";
import * as actions from "../actions";
import { login } from "../../axios/login";
import { notify } from "react-notify-toast";
import { getAdminsByRole } from "../../Components/TargetedTools/redux-store/actions/admins-by-role-actions";
import { getCountries } from "../actions";

function* loginSaga(action: ILoginAction) {
  try {
    const response = yield call(login, action.payload);
    yield put(actions.loginSuccess(response.data));
    if(response.data.token){
      yield put(getCountries());
      yield put(getAdminsByRole({ role: "account_manager" }));
    }
  } catch (e) {
    notify.show("Please check your credentials", "error");
    yield put(actions.loginFailure(e));
  }
}

export function* watchLoginSaga() {
  yield takeLatest(loginAction.requested, loginSaga);
}
