import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IOption } from "../../../../TargetedTools/components/MultipleSelect";
import { useSelect } from "../../../../../hooks/useSelect";
import { getSystemPromoCode } from "../../../../../redux-store/actions/getSystemPromoCodesActions";
import {
  CustomDropDownSelect,
  MultipleSelect,
} from "../../../../TargetedTools/components";
import { ISelectItemData } from "../../../../TargetedTools/components/CustomDropDownSelect";
import styles from "./styles.module.sass";
import { getCountryStores } from "../../../../../redux-store/actions/getCountryStoresActions";

export const PromocodeFilters: React.FC = () => {
  const dispatch = useDispatch();
  const {
    countries,
    allStores,
    filters: { status, country_codes, brand_ids, order_by, search },
  } = useSelect((state) => ({
    ...state.countriesReducer,
    ...state.CountryStoresReducer,
    ...state.SystemPromoCodesReducer.current,
  }));

  const renderSelectItem = (item: ISelectItemData) => (
    <div className={styles.dropdownItem}>
      <span className={styles.dropdownItemLabel}>{item?.label || "All"}</span>
    </div>
  );

  const renderSelectToggle = (item: ISelectItemData) => (
    <div className={styles.dropDownSelect}>
      <div className={styles.dropdownItemSelected}>
        <span className={styles.dropdownLabel}>Promo code status:</span>
        <span className={styles.dropdownValue}>{item?.label || "All"}</span>
      </div>
      <img
        className={styles.dropdownIcon}
        src={require("../../../../../assets/feather _ chevron-down.svg")}
      />
    </div>
  );
  {
    /* Filter promo codes by activity */
  }
  {
    /* activity options */
  }
  const statusOptions: ISelectItemData[] = React.useMemo(() => {
    return [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Active",
        value: "active",
      },
      {
        label: "Inactive",
        value: "inactive",
      },
    ];
  }, []);
  {
    /* handle select to filter promo code by activity  */
  }
  const handleStatusPromocodeSelect = (item: ISelectItemData) => {
    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: {
          country_codes,
          status: item.value,
          brand_ids,
          order_by,
          search,
        },
      })
    );
  };

  {
    /* Filter by country */
  }
  const countriesOptions = React.useMemo(() => {
    return [
      ...countries.map(
        ({ ISO_code, EnName }): IOption<string> => ({
          label: EnName,
          value: ISO_code,
        })
      ),
    ];
  }, [countries]);
  {
    /* handle select to filter by countries */
  }

  const handleCountriesSelect = (values, setValues, id) => {
    const updatedValues = values.includes(id)
      ? values.filter((item: any) => item !== id)
      : [...values, id];
    setValues(updatedValues);

    const filteredStores = allStores.filter((store) =>
      updatedValues.includes(store.country_iso_code)
    );

    const validBrandIds =
      brand_ids?.filter((brandId) =>
        filteredStores.some((store) => store.value === brandId)
      ) || [];

    const updatedBrandIds =
      validBrandIds.length > 0 ? validBrandIds : undefined;

    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: {
          status,
          country_codes: updatedValues,
          brand_ids: updatedBrandIds,
          order_by,
          search,
        },
      })
    );
  };

  {
    /* Filter by brands  */
  }

const brandsOptions = React.useMemo(() => {
  const filteredStores = country_codes?.length
    ? allStores.filter((store) =>
        country_codes.includes(store.country_iso_code)
      )
    : allStores;

  return filteredStores.map(
    (store): IOption<string> => ({
      label: store.label,
      value: store.value,
    })
  );
}, [allStores, country_codes]);

  const handleStoriesSelect = (values, setValues, id) => {
    const updatedValues = values.includes(id)
      ? values.filter((item: any) => item !== id)
      : [...values, id];

    setValues(updatedValues);

    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: {
          status,
          country_codes,
          brand_ids: updatedValues,
          order_by,
          search,
        },
      })
    );
  };
  useEffect(() => {
    dispatch(getCountryStores([]));
  }, []);
  const countriesDispatching = () => {
    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: {
          status,
          brand_ids: undefined,
          country_codes: undefined,
          order_by,
          search,
        },
      })
    );
  };
  const brandsDispatching = () => {
    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: 15,
        filters: {
          status,
          country_codes,
          brand_ids: undefined,
          order_by,
          search,
        },
      })
    );
  };

  return (
    <div className={styles.filterWrapper}>
      <CustomDropDownSelect
        renderSelectItem={renderSelectItem}
        renderSelectToggle={renderSelectToggle}
        options={statusOptions}
        onChange={handleStatusPromocodeSelect}
      />
      <div className={styles.separator} />
      <MultipleSelect
        justify={"flex-end"}
        searchLabel={"brands"}
        dropdownLabel={"Brands:"}
        selectAll={brand_ids === undefined}
        onChange={handleStoriesSelect}
        options={brandsOptions}
        selectedLabel={"brands selected"}
        placeholder={"Search for brands"}
        dispatchingFunctions={brandsDispatching}
      />
      <div className={styles.separator} />
      <MultipleSelect
        justify={"center"}
        searchLabel={"countries"}
        dispatchingFunctions={countriesDispatching}
        selectAll={country_codes === undefined}
        selectedLabel={"countries selected"}
        dropdownLabel={"Country:"}
        placeholder={"Search for countries"}
        onChange={handleCountriesSelect}
        options={countriesOptions}
      />
    </div>
  );
};

export default PromocodeFilters;
