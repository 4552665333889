import update from "immutability-helper";

export const mapById = <I extends { id: string | number }>(
  items: I[]
): Record<string | number, I> => {
  return items.reduce((acc, next) => {
    return {
      ...acc,
      [next.id]: next,
    };
  }, {});
};

export const convertToIds = <I extends { id: string }>(items: I[]) =>
  items.map(({ id }) => id);

interface IOrderedObject {
  order: number;
}

export const orderByIndex = <T extends IOrderedObject>(a: T, i: number): T => ({
  ...a,
  order: i + 1,
});

export const updateItemOrdering = <T extends IOrderedObject>(
  items: T[],
  reOrderedItem: T,
  oldItem: T
) => {
  const reOrderedItems = update(items, {
    $splice: [
      [oldItem.order - 1, 1],
      [reOrderedItem.order - 1, 0, reOrderedItem],
    ],
  });
  return reOrderedItems.map(orderByIndex);
};

export interface ICommissions {
  pickup_commission: number;
  delivery_commission: number;
  delivery_share: number;
  delivery_share_type: "percentage" | "value";
}
