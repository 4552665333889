import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IStep } from "../types";

export const getCurrentStep = (token): AxiosReturn<{ steps: IStep }> => {
  return axios.get(Requests.getCurrentStep, {
    baseURL: process.env.WIZARD_LUMEN_ENDPOINT,
    headers: {
      token,
    },
  });
};
