import { IStore } from "../../axios/getBrands";
import {
  IEditBrandAction,
  IEditBrandFailureAction,
  IEditBrandSuccessAction,
  IFillEditBrandAction,
  IResetEditBrandAction,
} from "../../types";
import {
  ACTION_EDIT_BRAND_MUTATION_SUCCESS,
  ACTION_FILL_EDIT_BRAND,
  ACTION_RESET_EDIT_BRANDS,
  editBrandAction,
} from "../../constants";

export const fillBrandData = (data: IStore): IFillEditBrandAction => ({
  type: ACTION_FILL_EDIT_BRAND,
  payload: data,
});

export const editBrand = (): IEditBrandAction => ({
  type: editBrandAction.requested,
});

export const editBrandSuccess = (data: IStore): IEditBrandSuccessAction => ({
  type: editBrandAction.fulfilled,
  payload: data,
});

export const editBrandFailure = (error): IEditBrandFailureAction => ({
  type: editBrandAction.rejected,
  payload: error,
});
export const resetEditBrand = (): IResetEditBrandAction => ({
  type: ACTION_RESET_EDIT_BRANDS,
});

export const editBrandMutationSuccess = (data: any): any => ({
  type: ACTION_EDIT_BRAND_MUTATION_SUCCESS,
  payload: data,
});