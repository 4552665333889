import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  deleteStoreTag,
  editStoreTagSuccess,
  editStoreTagFailure,
} from "../actions/tags-actions";
import { editStoreTagAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { editStoreTagsAPI } from "../../axios/editStoreTag";
import { customNotify } from "../../../../UIComponents/customNotification/customNotification";

const actionType = union({ deleteStoreTag });

function* editStoreTagSaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  const { id } = payload;
  yield call(editStoreTagsAPI, token, payload, id);
  return (
    payload &&
    customNotify.success(
      `[${payload.name_en}-${payload.name_ar}] Tag edited successfully`
    )
  );
}

export function* watchEditTagSaga() {
  yield takeLatest(
    editStoreTagAction.requested,
    handleOptimism(editStoreTagSaga, {
      success: editStoreTagSuccess,
      failure: editStoreTagFailure,
    })
  );
}
