import { call, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { union } from "ts-action";
import {
  createPushNotification,
  createPushNotificationFailure,
  createPushNotificationSuccess,
} from "../actions/pushNotification";
import { createPushNotificationAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";
import { customNotify } from "../../../../UIComponents/customNotification/customNotification";
import { sendPushNotificationAPI } from "../../../../axios/sendPushNotification";

const actionType = union({ createPushNotification });

function* createPushNotificationSaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);

  const res = yield call(sendPushNotificationAPI, token, payload);

  const resStatus =
    res.status === 200
      ? customNotify.success(`Push addedd successfully`)
      : customNotify.error("There is something wrong");

  return {
    ...payload,
    ...res.data,
    resStatus,
  };
}
export function* watchCreatePushNotificationSaga() {
  yield takeLatest(
    createPushNotificationAction.requested,
    handleOptimism(createPushNotificationSaga, {
      success: createPushNotificationSuccess,
      failure: createPushNotificationFailure,
    })
  );
}
