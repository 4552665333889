import {
  editPromotedSectionTitleActionType,
  getPromotedBrandsTitleAction,
  IEditPromotedSectionTitleSuccessAction,
  IGetPromotedSectionTitleSuccessAction,
} from "../../types";
import * as constants from "../../constants";

export interface IPromotedSectionTitleReducerState {
  en_title: string;
  ar_title: string;
  isLoading: "suceess" | "loading" | "error";
}

const initialState: IPromotedSectionTitleReducerState = {
  isLoading: "loading",
  en_title: "Newly Joined",
  ar_title: "انضم حديثاً",
};

export const promotedSectionTitleReducer = (
  state = initialState,
  action: getPromotedBrandsTitleAction | editPromotedSectionTitleActionType
): IPromotedSectionTitleReducerState => {
  switch (action.type) {
    case constants.getPromotedBrandsTitleAction.requested:
    case constants.editPromotedBrandsTitleAction.requested: {
      return {
        ...state,
        isLoading: "loading",
      };
    }
    case constants.getPromotedBrandsTitleAction.fulfilled: {
      return {
        ...state,
        isLoading: "suceess",
        en_title: (action as IGetPromotedSectionTitleSuccessAction).payload
          .en_title,
        ar_title: (action as IGetPromotedSectionTitleSuccessAction).payload
          .ar_title,
      };
    }
    case constants.getPromotedBrandsTitleAction.rejected: 
    case constants.editPromotedBrandsTitleAction.rejected:
    {
      return { ...state, isLoading: "error" };
    }

    case constants.editPromotedBrandsTitleAction.fulfilled: {
      return {
        ...state,
        isLoading: "suceess",
        en_title: (action as IEditPromotedSectionTitleSuccessAction).payload
          .en_title,
        ar_title: (action as IEditPromotedSectionTitleSuccessAction).payload
          .ar_title,
      };
    }

    default:
      return state;
  }
};
