import { AxiosReturn } from "./types";
import * as Requests from "./requests";

enum CampaignStatuses {
  PendingCreation = 0,
  PendingApproval = 1,
  Rejected = 2,
  Scheduled = 3,
  Running = 4,
  Completed = 5,
}

type CampaignStatusesType =
  | CampaignStatuses.PendingCreation
  | CampaignStatuses.PendingApproval
  | CampaignStatuses.Rejected
  | CampaignStatuses.Scheduled
  | CampaignStatuses.Running
  | CampaignStatuses.Completed;

import axios from "./axios";
import { IReachCampaignsResponse } from "../redux-store/actions/getReachCampaignsActions";

export interface IGetCampaignsFilters {
  store_id?: string;
  status?: CampaignStatusesType;
  page?: number;
}

export const getCampaigns = (
  token: string,
  filters: IGetCampaignsFilters
): AxiosReturn<{
  campaigns: IReachCampaignsResponse[];
  total_campaigns: number;
}> => {
  return axios.get(Requests.campaignRequests, {
    headers: {
      token,
    },
    baseURL: process.env.LUMEN_ENDPOINT,
    params: filters,
  });
};

export const getCampaignsMock = (
  token: string,
  filters: IGetCampaignsFilters
): AxiosReturn<{ campaigns: IReachCampaignsResponse[] }> => {
  return axios.get(
    "https://private-9f707-shopxadminlumen.apiary-mock.com/reach/campaigns",
    {
      baseURL: "",
      headers: {
        token,
      },
      params: filters,
    }
  );
};
