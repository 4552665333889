export const convertDataURLToFile = (url: string): Promise<File[]> => {
  const isDataUrl = url.slice(0, 4) === "data";
  const urlTrail = isDataUrl ? "" : "?_=" + new Date().getTime();
  return fetch(url + urlTrail)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      return [new File([blob], "File name", { type: blob.type || "image" })];
    });
};
