import { Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getStatus } from "../../../../../Utils/status";
import { RoutingMethods } from "../../../../axios/getBrands";
import { Mutations } from "../../../../queries";
import { editBrandMutationSuccess } from "../../../../redux-store/actions/editBrandActions";
import Commission from "../Commission";
import DropdownsPopup from "../DropdownsPopup";
import GenericCard from "../GenericCard";
import GMVCalculation from "../GMVCalculation";
import styles from "./styles.module.sass";

const useStyles = makeStyles(() => ({
  root: {
    padding: "2.4rem 3rem",
  },
}));

export type AccountTypes =
  | "strategic_account"
  | "top_account"
  | "local_favorite"
  | "long_tail"
  | "priority_1"
  | "priority_2";
interface IProps {
  store_id: string;
  BRAND_DETAILS: any;
}
export const ProfileSettingCards: React.FC<IProps> = ({
  store_id,
  BRAND_DETAILS,
}) => {
  const { root } = useStyles();
  const [openAccountTypePopup, setOpenAccountTypePopup] = useState(false);
  const [openStartDatePopup, setOpenStartDatePopup] = useState(false);
  const [openBrandStatusPopup, setOpenBrandStatusPopup] = useState(false)
  const dispatch = useDispatch();
  const { mutate, status, variables } = Mutations.editBrandMutation(store_id);
  const accountTypeOptions = useMemo(
    () => [
      {
        text: "Local Favorite",
        value: "local_favorite",
      },
      {
        text: "Top Account",
        value: "top_account",
      },
      {
        text: "Strategic Account",
        value: "strategic_account",
      },
      {
        text: "P1",
        value: "priority_1",
      },
      {
        text: "P2",
        value: "priority_2",
      },
      {
        text: "Long Tail",
        value: "long_tail",
      },
    ],
    []
  );

  const brandStatusOptions = useMemo(
    () => [
      {
        text: "Inactive",
        value: 0,
      },
      {
        text: "Running",
        value: 1,
      },
      {
        text: "In progress",
        value: 2,
      },
      {
        text: "Closed",
        value: 3,
      },
      {
        text: "Churned",
        value: 4,
      },
    ],
    []
  );
  useEffect(() => {
    if (status === "success") {
      dispatch(editBrandMutationSuccess({ store_id, ...(variables as any) }));
      setOpenAccountTypePopup(false);
      setOpenStartDatePopup(false);
      setOpenBrandStatusPopup(false)
    }
  }, [variables, status]);
  const routingMethodTexts: Record<
    "pos" | "standalone" | "call_center",
    string
  > = {
    call_center: "Call center",
    pos: "POS",
    standalone: "Standalone",
  };
  const accountTypeTexts: Record<AccountTypes, string> = {
    top_account: "Top account",
    local_favorite: "Local favorite",
    strategic_account: "Strategic account",
    long_tail: "Long tail",
    priority_1: "P1",
    priority_2: "P2",
  };
  const statusesClass = [
    styles.inActive,
    styles.active,
    styles.inProgress,
    styles.closed,
    styles.churned,
  ];

  return (
    <div className={root}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={4}>
          <GenericCard
            editOnClick={() => setOpenBrandStatusPopup(true)}
            label="Brand Status"
            editable={true}
          >
            {getStatus(BRAND_DETAILS.status) && (
              <span className={statusesClass[BRAND_DETAILS.status]}>
                <span className={styles.dot} />
                {getStatus(BRAND_DETAILS.status)}
              </span>
            )}
          </GenericCard>
          {openBrandStatusPopup && (
            <DropdownsPopup
              name={"status"}
              initialValue={BRAND_DETAILS?.status}
              dropdownType={"single-select"}
              loading={status === "loading"}
              mutate={mutate}
              options={brandStatusOptions}
              open={openBrandStatusPopup}
              onClose={() => setOpenBrandStatusPopup(false)}
              dropdownLabel={"Brand Status"}
              title={"Edit brand status"}
            />
          )}
        </Grid>
        <Grid item={true} xs={4}>
          <GenericCard
            editOnClick={() => setOpenStartDatePopup(true)}
            label="Start Date"
            editable={true}
          >
            {BRAND_DETAILS.operating_start_date && (
              <span className={styles.cardTitle}>
                {moment(BRAND_DETAILS.operating_start_date).format(
                  "D MMM YYYY"
                )}
              </span>
            )}
          </GenericCard>
          {openStartDatePopup && (
            <DropdownsPopup
              initialValue={BRAND_DETAILS?.operating_start_date}
              dropdownType={"date-picker"}
              loading={status === "loading"}
              mutate={mutate}
              open={openStartDatePopup}
              onClose={() => setOpenStartDatePopup(false)}
              dropdownLabel={"Start Date"}
              title={"Edit start date"}
            />
          )}
        </Grid>
        <Grid item={true} xs={4}>
          <GenericCard label="Routing Method">
            <span className={styles.cardTitle}>
              {(BRAND_DETAILS?.routing_method as RoutingMethods) in
                RoutingMethods
                ? BRAND_DETAILS?.routing_method === RoutingMethods.pos &&
                  BRAND_DETAILS.pos_ordering === "foodics" &&
                  BRAND_DETAILS.pos === "foodics"
                  ? `${routingMethodTexts[
                  BRAND_DETAILS?.routing_method as RoutingMethods
                  ]
                  } - foodics`
                  : routingMethodTexts[
                  BRAND_DETAILS?.routing_method as RoutingMethods
                  ]
                : "N/A"}
            </span>
          </GenericCard>
        </Grid>
        <Grid item={true} xs={4}>
          <GenericCard
            editOnClick={() => setOpenAccountTypePopup(true)}
            label="Account Type"
            editable={true}
          >
            {BRAND_DETAILS?.account_type && (
              <span className={styles.cardTitle}>
                {accountTypeTexts[BRAND_DETAILS.account_type]}
              </span>
            )}
          </GenericCard>
          {openAccountTypePopup && (
            <DropdownsPopup
              name={"account_type"}
              initialValue={BRAND_DETAILS?.account_type}
              dropdownType={"single-select"}
              options={accountTypeOptions}
              loading={status === "loading"}
              mutate={mutate}
              open={openAccountTypePopup}
              onClose={() => setOpenAccountTypePopup(false)}
              dropdownLabel={"Account Type"}
              title={"Edit account type"}
            />
          )}
        </Grid>
        <Grid item={true} xs={4}>
          <Commission store_id={store_id} />
        </Grid>


        <Grid item={true} xs={4}>
          <GMVCalculation store_id={store_id} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileSettingCards;
