import * as actions from "../../constants";
import {
  IDashboardPreviewAction,
  IDashboardPreviewActionError,
  IDashboardPreviewActionSuccess,
} from "../../types";

export const logDashboardPreviewRequest = (
  payload: IDashboardPreviewAction["payload"]
): IDashboardPreviewAction => ({
  type: actions.dashboardPreviewLoggerAction.requested,
  payload,
});

export const logDashboardPreviewRequestSuccess = (): IDashboardPreviewActionSuccess => ({
  type: actions.dashboardPreviewLoggerAction.fulfilled,
});

export const logDashboardPreviewRequestFailure = (
  error: Error
): IDashboardPreviewActionError => ({
  type: actions.dashboardPreviewLoggerAction.rejected,
  payload: error,
});
