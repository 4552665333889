import { resolveTasksApi } from "./../../axios";
import { resolveTasksAction } from "./../../constants";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  resolveTasksFailure,
  resolveTasks,
  resolveTasksSuccess,
} from "../actions";
import { selectToken } from "../selectors";
import { union } from "ts-action";
import { notify } from "react-notify-toast";

const actionTypes = union({ resolveTasks });
function* resolveTasksSaga({ payload }: typeof actionTypes) {
  try {
    const data = payload.stores.map((store) => ({
      id: store.id,
      tasks: store.tasks.map((task) => task.id),
      contact_templates: store.contact_templates,
    }));
    const token: string = yield select(selectToken);
    yield call(resolveTasksApi, token, data);
    yield put(resolveTasksSuccess(payload));
    notify.show("Successfully resolved tasks.", "success");
  } catch (error) {
    notify.show("Failed to resolve tasks.", "success");
    yield put(resolveTasksFailure(error));
  }
}

export function* watchResolveTasksSaga() {
  yield takeLatest(resolveTasksAction.requested, resolveTasksSaga);
}
