import React from "react";

interface ICheckIconProps {
  fill?: string;
  width?: number;
  height?: number;
  stroke?: string;
  style?: React.CSSProperties;
}
export const CheckIcon: React.FC<ICheckIconProps> = ({
  fill,
  height,
  stroke,
  width,
  style,
}) => {
  return (
    <svg
      style={style}
      width={`${width ?? 20}`}
      height={`${height ?? 20}`}
      viewBox="0 0 20 20"
      fill={`${fill ?? "none"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6666 5L7.49992 14.1667L3.33325 10"
        stroke={`${stroke ?? "#38C172"}`}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
