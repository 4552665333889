import React, { Fragment } from "react";
import { RenderCustomizablePreviewImageUpload } from "../RenderCustomizablePreviewImageUpload";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import { useProcessImageResource } from "../../lib/useProcessImageResource";

interface IProps extends WrappedFieldProps {
  wrapperClassName?: string;
  imagePreviewClassName?: string;
  dropZoneClassName?: string;
  buttonWrapperClassName?: string;
  imageSize?: string;
  customBaseUrl: string;
  uploadButtonUI?: React.ReactNode;
  recommendedSizeUI?: React.ReactNode;
  showChangeBtn?: boolean;
}

export const CustomDropZoneField: React.FC<IProps> = ({
  wrapperClassName,
  imagePreviewClassName,
  dropZoneClassName,
  buttonWrapperClassName,
  imageSize,
  customBaseUrl,
  uploadButtonUI,
  recommendedSizeUI,
  showChangeBtn,
  ...props
}) => {
  const [isDraggingOver, setIsDraggingOver] = React.useState();
  const handleDragOver = React.useCallback(() => setIsDraggingOver(true), []);
  const handleDragLeave = React.useCallback(() => {
    // console.log(`I've benn cold`);
    setIsDraggingOver(false);
  }, []);
  const imageSrc = useProcessImageResource(
    props.input.value || "",
    customBaseUrl
  );

  return (
    <RenderCustomizablePreviewImageUpload
      {...props}
      wrapperClassName={`${styles.imageUploadWrapper} ${
        wrapperClassName || ""
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDropFile={handleDragLeave}
      previewUI={
        imageSrc ? (
          <Fragment>
            {showChangeBtn ? (
              <div
                className={`${styles.imagePreview} ${
                  imagePreviewClassName || ""
                }`}
                style={{
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${imageSrc}) lightgray 50% / cover no-repeat`,
                }}
              >
                <div className={styles.changeImage}>Change</div>
              </div>
            ) : (
              <img
                className={`${styles.imagePreview} ${
                  imagePreviewClassName || ""
                }`}
                src={imageSrc}
              />
            )}
          </Fragment>
        ) : (
          <div
            className={`${styles.dropZone} ${dropZoneClassName || ""} ${
              isDraggingOver ? styles.isDraggingOver : ""
            }`}
          >
            {recommendedSizeUI}
          </div>
        )
      }
      uploadButtonUI={
        uploadButtonUI ?? (
          <div className={buttonWrapperClassName}>
            <div className={styles.sizeNote}>
              Banner should be {imageSize || "450px * 158px"}
            </div>
            <div className={styles.uploadButton}>Upload photo</div>
          </div>
        )
      }
    />
  );
};
