import Datetime from "react-datetime";
import * as React from "react";
import * as styles from "./styles.module.sass";
import { WrappedFieldProps } from "redux-form";
import "react-datetime/css/react-datetime.css";
import * as moment from "moment-timezone";
import { Moment } from "moment";
import TimezonePicker from "react-timezone";
import { useCallback, useEffect, useRef } from "react";
import { getMomentTimeStamp, getServerTime } from "./lib";

interface IProps {
  label: string;
  disabled?: boolean;
  guess?: boolean;
}

export const RenderFullTimePicker: React.FC<WrappedFieldProps & IProps> = ({
  meta,
  label,
  input,
  disabled,
  guess,
}) => {
  const dateRef = useRef<any>(null);
  const timeRef = useRef<any>(null);
  const timeZoneRef = useRef<any>(null);
  const { value, onChange, onBlur } = input;
  useEffect(() => {
    if (guess) {
      const timezone = moment.tz.guess();
      onChange({ ...value, timezone });
    }
  }, []);

  const calculateTimeStamp = useCallback((date, time, timezone) => {
    if (date && time && timezone) {
      return getMomentTimeStamp(getServerTime(date, time, timezone));
    }
    return undefined;
  }, []);

  const handleDateChange = useCallback(
    (date) => {
      onBlur({
        ...value,
        date,
        timestamp: calculateTimeStamp(date, value.time, value.timezone),
      });
    },
    [value, onBlur, calculateTimeStamp]
  );
  const handleTimeChange = useCallback(
    (time) => {
      onBlur({
        ...value,
        time,
        timestamp: calculateTimeStamp(value.date, time, value.timezone),
      });
    },
    [value, onBlur, calculateTimeStamp]
  );

  const handleTimeZoneChange = useCallback(
    (timezone) => {
      onBlur({
        ...value,
        timezone,
        timestamp: calculateTimeStamp(value.date, value.time, timezone),
      });
    },
    [value, onBlur, calculateTimeStamp]
  );

  const onlyTodayAndAfter = useCallback((current: Moment | any) => {
    return current.isAfter(moment().startOf("day").subtract(1, "day"));
  }, []);
  const { warning, error, touched, invalid } = meta;
  const isInvalid = touched && invalid;
  return (
    <div className={styles.fullTimeWrapper}>
      <div className={styles.textWrapper}>
        <h2>{label}</h2>
      </div>
      <div className={styles.pickersWrapper}>
        {/* <div className={styles.timePicker}>
          <TimezonePicker
            ref={timeZoneRef}
            inputProps={{
              placeholder: "Select Timezone...",
              name: "timezone",
              disabled,
              className: `${isInvalid ? styles.invalid : ""}`
              // onBlur: onBlurTimezone,
              // onFocus: onFocusTimezone
            }}
            onChange={handleTimeZoneChange}
            value={input.value.timezone}
          />
          <div
            onClick={timeZoneRef.current?.handleFocus}
            className={styles.icon}
          >
            <IcoMoon icon="clock" />
          </div>
        </div>
         */}
        <div className={styles.datePicker}>
          <Datetime
            isValidDate={onlyTodayAndAfter}
            ref={dateRef}
            inputProps={{
              placeholder: "DD / MM / YYYY",
              readOnly: true,
              className: `${styles.date} ${isInvalid ? styles.invalid : ""}`,
              disabled,
              // onBlur: onBlurDate,
              // onFocus: onFocusDate
            }}
            timeFormat={false}
            value={input.value.date}
            onChange={handleDateChange}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div className={styles.timePicker}>
            <Datetime
              ref={timeRef}
              inputProps={{
                placeholder: "Choose a time...",
                readOnly: true,
                className: `${styles.time} ${isInvalid ? styles.invalid : ""}`,
                disabled,
                // onBlur: onBlurTime,
                // onFocus: onFocusTime
              }}
              dateFormat={false}
              value={input.value.time}
              onChange={handleTimeChange}
            />
          </div>
          <div style={{ margin: "0 .8rem" }} />
          <div className={styles.timePicker}>
            <TimezonePicker
              ref={timeZoneRef}
              inputProps={{
                placeholder: "Select Timezone...",
                name: "timezone",
                disabled,
                className: `${isInvalid ? styles.invalid : ""}`,
                // onBlur: onBlurTimezone,
                // onFocus: onFocusTimezone
              }}
              onChange={handleTimeZoneChange}
              value={input.value.timezone}
            />
          </div>
        </div>
      </div>
      {touched &&
        ((error && <span className={styles.formError}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};
