import React from "react";

export const AmsIcon: React.FC<{ is_active: boolean }> = ({ is_active }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 15.75V14.25C13.5 13.4544 13.2234 12.6913 12.7312 12.1287C12.2389 11.5661 11.5712 11.25 10.875 11.25H5.625C4.92881 11.25 4.26113 11.5661 3.76884 12.1287C3.27656 12.6913 3 13.4544 3 14.25V15.75"
        stroke={is_active ? "#FFA351" : "#000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 8.25C9.69975 8.25 10.875 6.90685 10.875 5.25C10.875 3.59315 9.69975 2.25 8.25 2.25C6.80025 2.25 5.625 3.59315 5.625 5.25C5.625 6.90685 6.80025 8.25 8.25 8.25Z"
        stroke={is_active ? "#FFA351" : "#000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AmsIcon;
