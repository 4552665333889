import React from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import {
  IPushCampaign,
  PushNotificationStatus,
} from "../../axios/get-push-notification";
import moment from "moment";
import { useProcessImageResource } from "../../BannerCards/lib/useProcessImageResource";

interface IProps {
  notification: IPushCampaign;
}

const PushNotificationTable: React.FC<IProps> = ({
  notification: {
    created_at,
    message,
    image,
    scheduled_at,
    sent_users_count,
    status,
    title,
    total_users_count,
  },
}) => {
  const percentage = (userCount, totalUsersCount) => {
    if (totalUsersCount !== 0) {
      return Number(100 * userCount) / Number(totalUsersCount) + "%";
    } else {
      return "0%";
    }
  };

  const date = moment.unix(created_at).format("LL");
  const time = moment.unix(created_at).format("LT");

  const date_scheduled_at = moment.unix(scheduled_at).format("LL");
  const time_scheduled_at = moment.unix(scheduled_at).format("LT");
  const pushNotificationImage = useProcessImageResource(
    image,
    process.env.NOTIFICATIONS_ENDPOINT + "/images/pushNotifications/"
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.dateWrapper}>
        <span className={styles.dateHeader}>{date}</span>
        <span className={styles.dateTitle}>{time}</span>
      </div>
      <div className={styles.bodyWrapper}>
        <img
          className={styles.img}
          src={
            image !== null
              ? pushNotificationImage
              : require("../../../../assets/avatar.svg")
          }
        />
        <div className={styles.separator} />
        <div className={styles.bodyTextWrapper}>
          <span className={styles.bodyText}>{title}</span>
          <span className={styles.bodySubText}>{message}</span>
        </div>
      </div>
      <div className={styles.successRate}>
        <span className={styles.dateHeader}>
          {percentage(sent_users_count, total_users_count)}
        </span>
        <span className={styles.dateTitle}>
          {sent_users_count} / {total_users_count}
        </span>
      </div>
      {/* <div className={styles.openRate}>
        <span className={styles.dateHeader}>3%</span>
        <span className={styles.dateTitle}>6 / 196</span>
      </div> */}

      <div className={styles.statusWrapper}>
        {status > PushNotificationStatus.success &&
          status < PushNotificationStatus.failure && (
            <>
              <div className={styles.completedWrapper}>
                <span className={styles.completedTitle}>Completed</span>
              </div>
              <span className={styles.statusTitle}>Pushing completed</span>
            </>
          )}
        {status === PushNotificationStatus.scheduled && (
          <>
            <div className={styles.scheduledWrapper}>
              <span className={styles.scheduledTitle}>Scheduled</span>
            </div>
            <div className={styles.scheduledDateWrapper}>
              {date_scheduled_at} , {time_scheduled_at}
            </div>
          </>
        )}
        {status === PushNotificationStatus.running ||
          (status === PushNotificationStatus.success && (
            <div className={styles.runningWrapper}>
              <span className={styles.runningTitle}>Running</span>
            </div>
          ))}
        {status === PushNotificationStatus.failure && (
          <div className={styles.failureWrapper}>
            <span className={styles.failureTitle}>Failure</span>
          </div>
        )}
        {status === PushNotificationStatus.pending_for_creation && (
          <>
            <div className={styles.pendingWrapper}>
              <span className={styles.pendingTitle}>Pending Creation</span>
            </div>
            <span className={styles.per}>
              {percentage(sent_users_count, total_users_count)}...
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default hot(module)(PushNotificationTable);
