import { call, put, select, takeLatest } from "redux-saga/effects";
import { getReachQuotasAction } from "../../constants";
import { union } from "ts-action";
import { selectToken } from "../selectors";
import { AxiosResponse } from "axios";
import {
  getReachQuotas,
  getReachQuotasFailure,
  getReachQuotasSuccess,
  IReachQuotasResponse,
} from "../actions/getReachQuotasActions";
import { getQuotas } from "../../axios/get-quotas";

const getReachQuotasActionType = union({ getReachQuotas });
type getReachQuotasActionType = typeof getReachQuotasActionType;
function* getReachQuotasSaga(action: getReachQuotasActionType) {
  try {
    const token = yield select(selectToken);
    const res: AxiosResponse<{
      quotas: IReachQuotasResponse[];
      total_quotas: number;
    }> = yield call(getQuotas, token, action.payload);
    yield put(getReachQuotasSuccess(res.data));
  } catch (e) {
    yield put(getReachQuotasFailure(e));
  }
}

export function* watchGetReachQuotasSaga() {
  yield takeLatest(getReachQuotasAction.requested, getReachQuotasSaga);
}
