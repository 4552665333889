import { takeLatest, put, select, call } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getCommissionsAction } from "../actions/constants";
import {
  getCommissionsFailure,
  getCommissionsSuccess,
  getCommissions,
} from "../actions/commissions";
import { getCommissionsApi } from "../../../../axios/getCommissions";
import { union } from "ts-action";
const actionType = union({ getCommissions });

function* getCommissionsSaga({ payload }: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getCommissionsApi, token, payload);
    yield put(getCommissionsSuccess(res.data));
  } catch (e) {
    yield put(getCommissionsFailure(e));
  }
}
export function* watchGetCommissionsSaga() {
  yield takeLatest(getCommissionsAction.requested, getCommissionsSaga);
}
