import React from "react";
import { useRecoilState } from "recoil";
import { filtersAtom } from "../../../../atoms";
import { useDeepCompareCallback } from "use-deep-compare";
import { Select } from "../../../../Components/FormElements/Select";
import styles from "./styles.module.sass";
import debounce from "lodash/debounce";
import {
  paymentStatusesOptions,
  merchantStatusOptions,
  countriesOptions,
} from "../../../../helpers/options";

interface IProps {
  brandsCount: number;
}
export const MerchantsTableHeader: React.FC<IProps> = ({ brandsCount }) => {
  const [, setFilters] = useRecoilState(filtersAtom);
  const handleSearch = useDeepCompareCallback(
    debounce((value: string) => {
      setFilters((prev) => ({
        params: {
          ...prev.params,
          search: value === "" ? undefined : value.trim(),
          page: 0,
        },
        queryEnabled: true,
      }));
    }, 300),
    [setFilters]
  );

  const handleChange = useDeepCompareCallback(
    (event) => {
      handleSearch(event.target.value);
    },
    [handleSearch]
  );
  return (
    <div className={styles.main}>
      <div className={styles.searchAndTitleWrapper}>
        <span className={styles.title}>Brands ({brandsCount || 0}) </span>
        <div className={styles.inputWrapper}>
          <img
            src={require("../../../../assets/search.svg")}
            className={styles.searchIcon}
            alt=""
          />
          <input
            onChange={handleChange}
            maxLength={30}
            className={styles.input}
            type="text"
            placeholder="Search by brand name..."
          />
        </div>
      </div>
      <div className={styles.filters}>
        <Select
          dropdownIcon={require("../../../../assets/feather _ chevron-down-.svg")}
          showLabelWithValue={true}
          label="Merchants Status:"
          className={styles.select}
          options={merchantStatusOptions}
          onChange={(input) => {
            setFilters((prev) => ({
              queryEnabled: true,
              params: {
                ...prev.params,
                merchant_status: input.value,
                page: 0,
              },
            }));
          }}
        />
        <Select
          dropdownIcon={require("../../../../assets/feather _ chevron-down-.svg")}
          showLabelWithValue={true}
          label="Payment Status:"
          className={styles.select}
          options={paymentStatusesOptions}
          onChange={(input) => {
            setFilters((prev) => ({
              queryEnabled: true,
              params: {
                ...prev.params,
                payment_status: input.value,
                page: 0,
              },
            }));
          }}
        />
        <Select
          dropdownIcon={require("../../../../assets/feather _ chevron-down-.svg")}
          showLabelWithValue={true}
          label="Country:"
          className={styles.select}
          options={countriesOptions}
          onChange={(country) => {
            setFilters((prev) => ({
              queryEnabled: true,
              params: {
                ...prev.params,
                country: country.value,
                page: 0,
              },
            }));
          }}
        />
      </div>
      <div className={styles.tableHeader}>
        <span className={styles.brandName}>Brand name</span>
        <span className={styles.brandStatus}>Brand Status</span>
        <span className={styles.activeDealsType}>Active deals types</span>
        <span className={styles.paymentStatus}>Payment status</span>
        <span className={styles.action}>Action</span>
      </div>
    </div>
  );
};

export default MerchantsTableHeader;
