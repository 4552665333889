import { action, payload } from "ts-action";
import { getAdminsByRoleAction } from "./constants";
export interface IAccountManager {
  name: string;
  email: string;
  id: string;
  logged_in: 1 | 0;
  status: string;
}

export const getAdminsByRole = action(
  getAdminsByRoleAction.requested,
  payload<{ role: string }>()
);
export const getAdminsByRoleSuccess = action(
  getAdminsByRoleAction.fulfilled,
  payload<{
    data: IAccountManager[];
    role: string;
  }>()
);
export const getAdminsByRoleFailure = action(
  getAdminsByRoleAction.rejected,
  payload<Error>()
);
