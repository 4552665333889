import {
  getBrandsWithTasksAction,
  ACTION_UPDATE_BRANDS_WITH_TASKS_FILTER,
  getPointsOfContactsAction,
  getContactTemplatesAction,
  ACTION_SET_SELECTED_TASKS,
  ACTION_SELECT_STORE_ID,
  ACTION_UPDATE_SELECTED_BRAND,
  resolveTasksAction,
  getResolvedActionsAction,
  ACTION_GET_SELECTED_ACTIONS,
} from "../../constants";
import { action, payload } from "ts-action";
import {
  ALERTS,
  IActionSummary,
  IBrandTasks,
  IContactTemplate,
  IContactTemplatePayload,
  IPointOfContact,
  IResolveTasksPayload,
  ITask,
} from "../../types";

export const getBrandsWithTasks = action(
  getBrandsWithTasksAction.requested,
  payload<{ account_manager_id: string }>()
);
export const getBrandsWithTasksSuccess = action(
  getBrandsWithTasksAction.fulfilled,
  payload<{ data: IBrandTasks[]; account_manager_id: string }>()
);
export const getBrandsWithTasksFailure = action(
  getBrandsWithTasksAction.rejected,
  payload<{data: [], account_manager_id: string}>()
);
export const getBrandsWithTasksReset = action(getBrandsWithTasksAction.reset);

export const setSelectedTasksAction = action(
  ACTION_SET_SELECTED_TASKS,
  payload<{
    store_id?: string;
    task?: ITask;
    selected: boolean;
    page: "action-center" | "take-action";
  }>()
);

export const updateBrandsWithTasksFilter = action(
  ACTION_UPDATE_BRANDS_WITH_TASKS_FILTER,
  payload<{
    alert_types: ALERTS[];
    account_manager_id: string;
    sort?: {
      direction: "desc" | "asc";
      store_id: string;
    };
  }>()
);

export const getPointsOfContacts = action(
  getPointsOfContactsAction.requested,
  payload<{ store_id: string }>()
);
export const getPointsOfContactsSuccess = action(
  getPointsOfContactsAction.fulfilled,
  payload<{ points_of_contacts: IPointOfContact[]; store_id: string }>()
);
export const getPointsOfContactsFailure = action(
  getPointsOfContactsAction.rejected,
  payload<Error>()
);

export const getContactTemplates = action(
  getContactTemplatesAction.requested,
  payload<{ template_code: number }>()
);
export const getContactTemplatesSuccess = action(
  getContactTemplatesAction.fulfilled,
  payload<IContactTemplate[]>()
);
export const getContactTemplatesFailure = action(
  getContactTemplatesAction.rejected,
  payload<Error>()
);

export const selectStoreIdAction = action(
  ACTION_SELECT_STORE_ID,
  payload<string>()
);
export const updateSelectedBrand = action(
  ACTION_UPDATE_SELECTED_BRAND,
  payload<{
    store_id?: string;
    contact_templates?: IContactTemplatePayload;
  }>()
);

export const resolveTasks = action(
  resolveTasksAction.requested,
  payload<IResolveTasksPayload<ITask>>()
);
export const resolveTasksSuccess = action(
  resolveTasksAction.fulfilled,
  payload<IResolveTasksPayload<ITask>>()
);
export const resolveTasksFailure = action(
  resolveTasksAction.rejected,
  payload<Error>()
);
export const resolveTasksReset = action(resolveTasksAction.reset);
// get resolved actions
export const getResolvedActions = action(
  getResolvedActionsAction.requested,
  payload<{ page: number, account_manager_id: string }>()
);
export const getResolvedActionsSuccess = action(
  getResolvedActionsAction.fulfilled,
  payload<{
    account_manager_id: string;
    has_more: boolean;
    page: number;
    resolved_actions: IActionSummary[];
  }>()
);
export const getResolvedActionsFailure = action(
  getResolvedActionsAction.rejected,
  payload<Error>()
);
export const getSelectedActions = action(ACTION_GET_SELECTED_ACTIONS);
