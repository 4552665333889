import { call, put, select, takeLatest } from "redux-saga/effects";
import { notify } from "react-notify-toast";
import {
  createUniversalPromoCodeSuccess,
  createUniversalPromoCodeFailure,
  createUniversalPromoCode,
} from "../actions/promoCodeActions";
import { selectToken } from "../../../../redux-store/selectors";
import { createUniversalPromoCodeAPI } from "../../axios/createUniversalPromoCode";
import { createUniversalPromoCodeAction } from "../actions/constants";
import { union } from "ts-action";
import { PromoScreens } from "../../AddPromoCode/PromoCodeForm/lib";

const actionType = union({ createUniversalPromoCode });

function* createUniversalPromoCodeSaga({ payload }: typeof actionType) {
  try {
    const token: string = yield select(selectToken);
    const isMultiplier = payload.type === PromoScreens.multiplier;
    const isCashbackPromo = payload.type === PromoScreens.cashback_percentage;
    const type = isMultiplier
      ? "points-multiplier"
      : isCashbackPromo
      ? "cashback"
      : "discount";

    if (isMultiplier) {
      Reflect.deleteProperty(payload, "type");
    }
    const res = yield call(createUniversalPromoCodeAPI, token, payload, type);
    notify.show("promo code created successfully", "success");


    yield put(
      createUniversalPromoCodeSuccess({
        ...payload,
        ...res.data,
      })
    );
  } catch (error) {
    if (
      error.response.data?.validation_errors?.promo_code && 
      error.response.data?.validation_errors?.promo_code[0].includes(
        "the promo_code value can't equal to an active promo code in any of the specified stores"
      )
    ) {
      notify.show(
        "The promo code value can't equal to an active promo code in any of the specified stores",
        "error"
      );
    } else if(error.message.includes("Network Error")) {
      notify.show("Please check your internet", "error");
    } else {
      notify.show("promo code creation failed", "error");
    }
    yield put(createUniversalPromoCodeFailure(payload));
  }
}

export function* watchCreateUniversalPromoCodeSaga() {
  yield takeLatest(
    createUniversalPromoCodeAction.requested,
    createUniversalPromoCodeSaga
  );
}
