import { atom, selector } from "recoil";
import { PaymentStatus, MerchantStatus, Country, Activity } from "../types";

export const searchAtom = atom<string>({
  key: "merchantsSearchAtom",
  default: "",
});
export const filtersAtom = atom<{
  params: {
    country?: Country;
    search?: string;
    payment_status?: PaymentStatus;
    merchant_status?: MerchantStatus;
    activity?: Activity;
    page: number;
  };
  queryEnabled: boolean;
}>({
  key: "filtersForMerchants",
  default: {
    params: {
      activity: undefined,
      country: undefined,
      merchant_status: undefined,
      payment_status: undefined,
      search: undefined,
      page: 0,
    },
    queryEnabled: false,
  },
});

export const merchantsAtom = atom<{
  merchantsIds: string[];
  merchantById: { [x: string]: any };
  status: "loading" | "idle" | "success" | "error";
  hasMore: boolean;
  total_stores_count: number;
}>({
  key: "merchantsAtom",
  default: {
    merchantById: {},
    merchantsIds: [],
    status: "idle",
    hasMore: false,
    total_stores_count: 0,
  },
});

export const merchantsFiltersState = selector({
  key: "merchantsFiltersState",
  get: ({ get }) => {
    const filters = get(filtersAtom);
    return filters;
  },
});
