import { getResolvedActionsAction } from "../../constants";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { IActionSummary } from "../../types";
import { getResolvedActionsSuccess, resetAppState } from "../actions";

interface IGetResolvedActionsState {
  resolved_actions: IActionSummary[];
  has_more: boolean;
  page: number;
  account_manager_id: string;
}
const resolvedActionsInitialState: IGetResolvedActionsState = {
  has_more: true,
  page: 0,
  account_manager_id: "",
  resolved_actions: [],
};
export const getResolvedActionsReducer = withLoadingReducer<
  IGetResolvedActionsState
>(
  reducer<IGetResolvedActionsState>(
    [
      on(resetAppState, () => resolvedActionsInitialState),
      on(getResolvedActionsSuccess, (state, { payload }) => ({
        ...payload,
        resolved_actions:
          payload.page === 0
            ? payload.resolved_actions
            : [...state.resolved_actions, ...payload.resolved_actions],
      })),
    ],
    resolvedActionsInitialState
  ),
  getResolvedActionsAction
);
