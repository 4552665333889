import moment from "moment-timezone";
import { combineDateAndTimeMoments } from "../Components/FormElements/RenderFullTimePicker/lib";

export const diffFromNow = (
  dateMoment: Moment,
  timeMoment: Moment,
  timezone: string
): number => {
  const timeZonedTime = combineDateAndTimeMoments(
    dateMoment,
    timeMoment,
    timezone
  );
  return timeZonedTime.diff(moment.tz(timezone), "minute");
};
type Moment = moment.Moment;
export const percentageCommissions = (value) => {
  return value !== undefined
    ? +value >= 0 && +value <= 100
      ? undefined
      : "Commission percentage must be in range of 0% and 100%"
    : "Commission percentage must be in range of 0% and 100%";
};
export const required = (value) =>
  value || typeof value === "number" ? undefined : "Required";
export const maxRank = (value) =>
  value > 100 || value <= 0
    ? "please enter number between 0 to 100"
    : undefined;
export const max_size = (value) =>
  value && value.length < 400 ? undefined : "Max characters are 400 character";
export const code_split = (value) => {
  return typeof value?.koinz === "number" &&
    typeof value?.brand === "number" &&
    value.koinz + value.brand === 100
    ? undefined
    : "please enter number between 0 to 100";
};
export const isEmpty = (value) =>
  value && value.trim() ? undefined : "Field can't be empty";
export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const promoCodeLength = (value) => {
  return value && value.length > 30
    ? `Must be 30 characters or less`
    : undefined;
};
export const greaterThanZero = (value) =>
  value > 0 ? undefined : "Value is not valid";
export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const fullTimeValidator = (value) => {
  const defVal = {
    date: undefined,
    time: undefined,
    timezone: undefined,
    ...value,
  };
  const { date, time, timezone } = defVal;
  if (!date && !time && !timezone) {
    return "Please select date, time and timezone for your campaign";
  }
  if (date && !time && !timezone) {
    return "Please select time and timezone for your campaign";
  }
  if (date && time && !timezone) {
    return "Please select timezone for your campaign";
  }
  if (date && !time && timezone) {
    return "Please select time for your campaign";
  }

  if (!date && time && !timezone) {
    return "Please select date and timezone for your campaign";
  }

  if (!date && !time && timezone) {
    return "Please select date and time for your campaign";
  }

  if (!date && time && timezone) {
    return "Please select date for your campaign";
  }

  if (date && time && !timezone) {
    return "Please select timezone for your campaign";
  }

  if (date && time && timezone) {
    const difference = diffFromNow(date as Moment, time as Moment, timezone);
    return difference < 5
      ? "The time you choose is not available.Please select a time that is 5 minutes from now"
      : "";
  }

  return undefined;
};
