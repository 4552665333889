import { getAccountManagerURL } from "./requests";
import axios from "./axios";

export const getAccountManagerApi = (
  token: string,
  data: {
    store_id: string;
  }
) => {
  return axios.get(getAccountManagerURL(data.store_id), {
    headers: {
      token,
    },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
