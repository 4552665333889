import axios from "./axios";
import * as Requests from "./requests";
export interface IPaymentRequest {
  store_id: string;
  password: string;
  payment_term: number;
  payment_date: number;
  comment?: string;
}

export const verifyPayment = (token, data: IPaymentRequest): any => {
  return axios.get(Requests.verifyPayment(data.store_id), {
    baseURL: process.env.LUMEN_ENDPOINT,
    params: data,
    headers: {
      token,
    },
  });
};
