import * as constants from "../../constants";
interface ISendPushNotificationReducer {
  is_loading: boolean;
  err: string;
  is_success: boolean;
}

const InitialState: ISendPushNotificationReducer = {
  is_loading: false,
  err: "",
  is_success: false,
};

const SendPushNotificationReducer = (
  state = InitialState,
  action
): ISendPushNotificationReducer => {
  switch (action.type) {
    case constants.sendPushNotificationAction.requested:
      return {
        ...state,
        is_loading: true,
        is_success: false,
      };
    case constants.sendPushNotificationAction.fulfilled:
      return {
        ...state,
        is_loading: false,
        is_success: true,
      };
    case constants.sendPushNotificationAction.rejected:
      return {
        ...state,
        is_loading: false,
        is_success: false,
        err: action.payload,
      };
    default:
      return state;
  }
};
export default SendPushNotificationReducer;
