import { action, payload } from "ts-action";
import {
  getPushNotificationsAction,
  createPushNotificationAction,
} from "./constants";
import { IPushCampaign } from "../../axios/get-push-notification";
import { optAction, optReaction } from "../../../../redux-store/actions/lib";
import { COMMIT, REVERT } from "redux-optimistic-ui";

export const createPushNotification = optAction(
  createPushNotificationAction.requested,
  payload<FormData>()
);
export const createPushNotificationSuccess = optReaction(
  createPushNotificationAction.fulfilled,
  payload<IPushCampaign>(),
  COMMIT
);
export const createPushNotificationFailure = optReaction(
  createPushNotificationAction.rejected,
  payload<IPushCampaign>(),
  REVERT
);

export const getPushNotifications = action(
  getPushNotificationsAction.requested,
  payload<{ page: number; per_page: number }>()
);
export const getPushNotificationsSuccess = action(
  getPushNotificationsAction.fulfilled,
  payload<{ campaigns: IPushCampaign[]; total_campaigns: number }>()
);

export const getPushNotificationsFailure = action(
  getPushNotificationsAction.rejected,
  payload<Error>()
);
