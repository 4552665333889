import * as React from "react";
import * as styles from "./styles.module.sass";
import { IPaymentLogs } from "../../../types/index";
import Log from "../Log";

interface IProps {
  logsPerYear: IPaymentLogs[];
  year: string;
}

class YearlyLogs extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.year}>{this.props.year}</div>
        <div className={styles.logsWrapper}>
          {this.props.logsPerYear.map((log) => (
            <Log key={log.created_at} log={log} />
          ))}
        </div>
      </div>
    );
  }
}

export default YearlyLogs;
