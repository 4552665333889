import React, { useState } from "react";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";

const Search: React.FC = () => {
  const [search, setSearch] = useState("");
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <input
        type="text"
        className={styles.input}
        placeholder={"Search for name or email…"}
        value={search}
        onChange={onChange}
      />
      <img
        className={styles.icon}
        src={require("../../../../../assets/search.svg")}
      />
    </div>
  );
};

export default hot(module)(Search);
