import { select, call, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { reorderBannerCardAPI } from "../../axios/reorderBannerCard";
import { union } from "ts-action";
import {
  reOrderBannerCard,
  reOrderBannerCardFailure,
  reOrderBannerCardSuccess,
} from "../actions/bannerCardsActions";
import { reOrderBannerCardAction } from "../actions/constants";
import { handleOptimism } from "../../../../redux-store/sagas/handleOptimism";

const actionType = union({ reOrderBannerCard });

function* reorderBannerCardSaga({ payload }: typeof actionType) {
  const token = yield select(selectToken);
  yield call(reorderBannerCardAPI, token, payload.id, payload.order);
  return payload;
}

export function* watchReorderBannerCardSaga() {
  yield takeLatest(
    reOrderBannerCardAction.requested,
    handleOptimism(reorderBannerCardSaga, {
      success: reOrderBannerCardSuccess,
      failure: reOrderBannerCardFailure,
    })
  );
}
