import * as React from "react";
import * as styles from "./styles.module.sass";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { IGetPaymentLogsAction, IPaymentLogs } from "../../types";
import { connect } from "react-redux";
import { History } from "history";
import { LoadingPageInfo } from "../LoadingPageInfo";
import { Dispatch } from "redux";
import { getPaymentLogs } from "../../redux-store/actions";
import { RouteComponentProps } from "react-router";
import * as moment from "moment";
import YearlyLogs from "./YearlyLogs";

interface IProps {
  history: History;
  paymentLogs: IPaymentLogs[];
  completed: boolean;
  inProgress: boolean;
  failed: boolean;
  getPaymentLogs: (stores_id: string) => void;
}

const getYear = (time_stamp) => moment(time_stamp, "x").format("YYYY");

class PaymentLogs extends React.Component<
  IProps &
    RouteComponentProps<{
      store_id: string;
    }>
> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    const { store_id } = this.props.match.params;
    this.props.getPaymentLogs(store_id);
  }

  public render() {
    let years: string[] = [];
    let logsMap: { [x: string]: IPaymentLogs[] } = {};
    if (this.props.completed) {
      if (this.props.paymentLogs.length === 0) {
        return (
          <div className={styles.wrapper}>
            <h1>There are no logs!</h1>
          </div>
        );
      }
      this.props.paymentLogs
        .sort((a, b) =>
          a.created_at > b.created_at ? 1 : b.created_at > a.created_at ? -1 : 0
        )
        .forEach((log) => {
          const currentYear = getYear(log.created_at);
          if (!logsMap[currentYear]) {
            logsMap = {
              ...logsMap,
              [currentYear]: [log],
            };
            years = [...years, currentYear];
          } else {
            logsMap = {
              ...logsMap,
              [currentYear]: [...logsMap[currentYear], log],
            };
          }
        });
      years = years.reverse();
      return (
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <h1>Payment logs</h1>
            {years.map((year) => (
              <YearlyLogs key={year} year={year} logsPerYear={logsMap[year]} />
            ))}
          </div>
        </div>
      );
    }
    if (this.props.failed) {
      return (
        <div className={styles.wrapper}>
          <h1>Failed to load logs!</h1>
        </div>
      );
    }
    return <LoadingPageInfo />;
  }
}

const mapStateToProps = (state: IRootReducerState) => ({
  paymentLogs: state.paymentLogsReducer.logs,
  completed: state.paymentLogsReducer.completed,
  inProgress: state.paymentLogsReducer.inProgress,
  failed: state.paymentLogsReducer.failed,
});
const mapDispatchToProps = (dispatch: Dispatch<IGetPaymentLogsAction>) => ({
  getPaymentLogs: (store_id) => dispatch(getPaymentLogs(store_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLogs);
