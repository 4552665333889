import React from "react";
import styles from "./styles.module.sass";
import "../../../styles.less";
import ic_message_square from "../../../assets/ic_message_square.svg";
import arrow_down from "../../../assets/arrow-down.svg";
import arrow_up from "../../../assets/arrow-up.svg";
import chevron_down from "../../../assets/icon-chevaron-up.svg";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  getPointsOfContacts,
  selectStoreIdAction,
  setSelectedTasksAction,
  updateBrandsWithTasksFilter,
} from "../../..//redux-store/actions";
import { useDispatch } from "react-redux";
import { IBrandTasks } from "../../../types";
import { CheckIcon } from "../../../assets/components";
import { useSelect } from "../../../hooks/useSelect";
import { actionCenterAlerts } from "../../../constants";

const BranchesNeedAction: React.FC<
  Omit<IBrandTasks, "contact_templates"> & RouteComponentProps
> = ({ tasks, store_id, history, points_of_contacts }) => {
  const dispatch = useDispatch();
  const { account_manager_id, alert_types } = useSelect(
    (state) => state.actionCenterBrandsReducer
  );

  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          <th />
          <th>Branch</th>
          <th>Alert type</th>
          <th>
            <div className={styles.percentageWrapper}>
              <span>Percentage</span>
              <div className={styles.arrowsWrapper}>
                <img
                  src={chevron_down}
                  onClick={() => {
                    dispatch(
                      updateBrandsWithTasksFilter({
                        account_manager_id,
                        alert_types,
                        sort: {
                          direction: "asc",
                          store_id,
                        },
                      })
                    );
                  }}
                  className={styles.percentageSortIcon}
                />
                <img
                  onClick={() => {
                    dispatch(
                      updateBrandsWithTasksFilter({
                        account_manager_id,
                        alert_types,
                        sort: {
                          direction: "desc",
                          store_id,
                        },
                      })
                    );
                  }}
                  style={{ marginTop: "5px", transform: "rotate(180deg)" }}
                  src={chevron_down}
                  className={styles.percentageSortIcon}
                />
              </div>
            </div>
          </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {tasks?.map((element, index) => (
          <tr key={index}>
            <td>
              <div
                onClick={() => {
                  if (!(points_of_contacts || []).length) {
                    dispatch(getPointsOfContacts({ store_id }));
                  }
                  dispatch(
                    setSelectedTasksAction({
                      store_id,
                      task: { ...element },
                      selected: !element.selected,
                      page: "action-center",
                    })
                  );
                }}
                style={{
                  backgroundColor: element.selected ? "#1C1C1E" : "#F2F2F7",
                }}
                className={styles.checkbox}
              >
                {element.selected && (
                  <CheckIcon
                    style={{ width: "12px", height: "12px" }}
                    stroke="#fff"
                  />
                )}
              </div>
            </td>
            <td>
              <span className={styles.branchName}>
                {element.branch_name.length < 15
                  ? element.branch_name
                  : element.branch_name.substring(0, 15) + "..."}
              </span>
            </td>
            <td>
              {typeof element.alert_type === "number" && (
                <div className={styles.alertTypeContent}>
                  <div className={styles.dot} />
                  <span className={styles.font14}>
                    {actionCenterAlerts[element.alert_type]}
                  </span>
                </div>
              )}
            </td>
            <td>
              <div className={styles.filter}>
                <img
                  src={element.alert_percentage > 0 ? arrow_up : arrow_down}
                  className={styles.arrow_down}
                />
                <span className={styles.font14}>
                  {element.alert_percentage}%
                </span>
              </div>
            </td>
            <td>
              <div
                onClick={() => {
                  if (element.selected) {
                    history.push("/account-managers/action-center/take-action");
                    dispatch(selectStoreIdAction(store_id));
                  }
                }}
                style={{ cursor: element.selected ? "pointer" : "not-allowed" }}
                className={styles.action}
              >
                <img
                  src={ic_message_square}
                  style={{ width: "24px", height: "24px" }}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default withRouter(BranchesNeedAction);
