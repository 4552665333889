import React from "react";
import { WrappedFieldProps } from "redux-form";
import { uDIC } from "../../../../SharedComponent/useDefaultAndInsertedClassName";

interface IProps extends WrappedFieldProps {
  wrapperClassName?: string;
  onDropFile?: (e: React.DragEvent) => void;
  onDragOver?: (e: React.DragEvent) => void;
  onDragLeave?: (e: React.DragEvent) => void;
  previewUI?: React.ReactNode;
  uploadButtonUI?: React.ReactNode;
}

const prevent = (e: React.SyntheticEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

const exec = (fn?, ...args) => fn && fn(...args);

export const RenderCustomizablePreviewImageUpload: React.FC<IProps> = (
  props
) => {
  const {
    input: { onChange },
    onDropFile,
    onDragOver,
    uploadButtonUI,
    previewUI,
    wrapperClassName,
    onDragLeave,
  } = props;
  const handleDrop = React.useCallback(
    (e: React.DragEvent) => {
      prevent(e);
      exec(onDropFile, e);
      if (e.dataTransfer && e.dataTransfer.files.length) {
        const imageFile = e.dataTransfer.files[0];
        onChange(imageFile);
      }
    },
    [onDropFile, onChange]
  );

  const handleSelectFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length) {
        onChange(e.target.files[0]);
      }
    },
    [onChange]
  );

  const handleDragOver = React.useCallback(
    (e: React.DragEvent) => {
      exec(onDragOver, e);
    },
    [onDragOver]
  );
  const handleDragLeave = React.useCallback((e: React.DragEvent) => {
    exec(onDragLeave, e);
  }, []);
  return (
    <>
      <label
        htmlFor={props.input.name}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`${uDIC("", wrapperClassName)}`}
      >
        {previewUI}
        {uploadButtonUI}
      </label>
      <input
        accept={"image/*"}
        type="file"
        id={props.input.name}
        onChange={handleSelectFile}
        hidden={true}
      />
    </>
  );
};
