import {
  IConfirmPaymentAction,
  IConfirmPaymentFailureAction,
  IConfirmPaymentSuccessAction,
  IResetPaymentAction,
  IVerifyPaymentAction,
  IVerifyPaymentFailureAction,
  IVerifyPaymentSuccessAction,
} from "../../types";
import {
  ACTION_RESET_PAYMENT,
  confirmPaymentAction,
  verifyPaymentAction,
} from "../../constants";

export const verifyPayment = (store_id: string): IVerifyPaymentAction => ({
  type: verifyPaymentAction.requested,
  payload: store_id,
});

export const verifyPaymentSuccess = (
  data: number
): IVerifyPaymentSuccessAction => ({
  type: verifyPaymentAction.fulfilled,
  payload: data,
});

export const verifyPaymentFailure = (error): IVerifyPaymentFailureAction => ({
  type: verifyPaymentAction.rejected,
  payload: error,
});

export const confirmPayment = (store_id: string): IConfirmPaymentAction => ({
  type: confirmPaymentAction.requested,
  payload: store_id,
});

export const confirmPaymentSuccess = (): IConfirmPaymentSuccessAction => ({
  type: confirmPaymentAction.fulfilled,
});

export const confirmPaymentFailure = (error): IConfirmPaymentFailureAction => ({
  type: confirmPaymentAction.rejected,
  payload: error,
});

export const resetPayment = (): IResetPaymentAction => ({
  type: ACTION_RESET_PAYMENT,
});
