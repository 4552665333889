import { call, put, select, takeLatest } from "redux-saga/effects";
import { editPromotedBrandAction } from "../../constants";
import { selectToken } from "../selectors";
import { notify } from "react-notify-toast";
import { setSubmitFailed, setSubmitSucceeded } from "redux-form";
import { IAddStoreInfo, IStore } from "../../axios/getBrands";
import { changeStorePromotedStatus } from "../../axios/changeStorePromotedStatus";
import { editBrandFailure } from "../actions/editBrandActions";
import { editPromotedBrandSuccess } from "../actions/edtitPromotedBrandActions";

// emails are not case sensitive, but our db is, so we gotta lower case it

export const lowerCaseEmailProp = (brandData: IAddStoreInfo) => {
  const modifiedData = { ...brandData };
  if (brandData.email) {
    modifiedData.email = modifiedData.email.toLowerCase();
  }
  return modifiedData;
};

function* editPromotedBrandSaga({
  payload,
}: {
  type: string;
  payload: {
    store: IStore;
    body: {
      promoted_status: "add" | "remove";
    };
  };
}) {
  try {
    const token = yield select(selectToken);
    yield call(
      changeStorePromotedStatus,
      payload.store.id,
      token,
      payload.body
    );
    yield put(
      editPromotedBrandSuccess({
        store: payload.store,
        promoted_status: payload.body.promoted_status,
      })
    );
    yield put(setSubmitSucceeded("editBrand"));
    payload.body.promoted_status === "add"
      ? notify.show("this Brand is promoted ", "success")
      : notify.show("this Brand is not promoted any more ", "success");
  } catch (e) {
    yield put(setSubmitFailed("editBrand"));

    notify.show("An error has occurred", "error");

    yield put(editBrandFailure(e));
  }
}

export function* watchEditPromotedBrandSaga() {
  yield takeLatest(editPromotedBrandAction.requested, editPromotedBrandSaga);
}
