import axios from "./axios";
import * as Requests from "./requests";
import { ICategory } from "../types";

export const getCategoriesAPI = (token: string) => {
  return axios.get<ICategory[]>(Requests.getCategories, {
    headers: {
      token,
    },
    baseURL: process.env.LUMEN_ENDPOINT,
  });
};
