import { Mutations } from "../../queries";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeMerchantDealsAtom } from "../../atoms";

export const useDeliveryDeal = (store_id, data) => {
  const [activeMerchantDeals, setActiveMerchantDeals] = useRecoilState(
    activeMerchantDealsAtom
  );

  const {
    mutate: mutateDeliveryDeal,
    variables: mutateDeliveryDealVariables,
    reset: mutationDeliveryDealReset,
    status: mutateDeliveryDealStatus,
  } = Mutations.editActiveMerchantDeals(store_id, "delivery-status");
  const deliveryToggle = (checked: boolean) => {
    setActiveMerchantDeals((prevState) => ({
      ...prevState,
      delivery_status: {
        status: checked ? "service_enabled" : "service_disabled",
        loading: "loading",
      },
    }));
    mutateDeliveryDeal(checked ? "service_enabled" : "service_disabled");
  };
  useEffect(() => {
    if (mutateDeliveryDealStatus === "success") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        delivery_status: {
          status: prevState.delivery_status.status,
          loading: "success",
        },
      }));
      mutationDeliveryDealReset();
    } else if (mutateDeliveryDealStatus === "error") {
      setActiveMerchantDeals((prevState) => ({
        ...prevState,
        delivery_status: {
          status: data?.delivery_status,
          loading: "error",
        },
      }));
    }
  }, [mutateDeliveryDealVariables, mutateDeliveryDealStatus, data]);

  return {
    deliveryToggle,
    activeMerchantDeals,
  };
};
