import * as constants from "../../constants";
import {
  ISendPushNotification,
  ISendPushNotificationSuccess,
  ISendPushNotificationFailed,
} from "../../constants";

export const sendPushNotification = (
  data: FormData
): ISendPushNotification => ({
  type: constants.sendPushNotificationAction.requested,
  payload: data,
});

export const sendPushNotificationSuccess = (
  res: string
): ISendPushNotificationSuccess => ({
  type: constants.sendPushNotificationAction.fulfilled,
  payload: res,
});

export const sendPushNotificationFailed = (
  err: Error
): ISendPushNotificationFailed => ({
  type: constants.sendPushNotificationAction.rejected,
  payload: err,
});
