import { IStore } from "../../axios/getBrands";
import {
  ACTION_EDIT_PROMOTED_BRAND_MUTATION_SUCCESS,
  ACTION_FILL_EDIT_PROMOTED_BRAND,
  ACTION_RESET_EDIT_PROMOTED_BRANDS,
  editPromotedBrandAction,
} from "../../constants";
import { action, payload } from "ts-action";

export const fillPromotedBrandData = action(
  ACTION_FILL_EDIT_PROMOTED_BRAND,
  payload<IStore>()
);
export const editPromotedBrand = action(
  editPromotedBrandAction.requested,
  payload<{
    store: IStore;
    body: {
      promoted_status: "add" | "remove";
    };
  }>()
);
export const editPromotedBrandSuccess = action(
  editPromotedBrandAction.fulfilled,
  payload<{ store: IStore; promoted_status: "add" | "remove" }>()
);
export const editPromotedBrandFailure = action(
  editPromotedBrandAction.rejected,
  payload<Error>()
);
export const resetEditPromotedBrand = action(ACTION_RESET_EDIT_PROMOTED_BRANDS);
export const editPromotedBrandMutationSuccess = action(
  ACTION_EDIT_PROMOTED_BRAND_MUTATION_SUCCESS,
  payload<any>()
);
