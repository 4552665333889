import * as Requests from "./requests";
import axios from "./axios";
// import { IPushCampaign } from "../Components/TargetedTools/axios/get-push-notification";
export interface ISendPushNotificationType {
  title: string;
  message: string;
  date: any;
}

export const sendPushNotificationAPI = (token: string, data: any) => {
  return axios.post(Requests.sendNotificationURL, data, {
    headers: {
      token,
    },
    baseURL: process.env.NOTIFICATIONS_ENDPOINT,
  });
};
