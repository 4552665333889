import React from "react";
import * as styles from "./styles.module.sass";
import { Aux } from "../../../App";
import ConfirmSelections from "../../../UIComponents/DeleteRequest";
import { Button } from "reactstrap";
import { IReachQuotasResponse } from "../../../redux-store/actions/getReachQuotasActions";
import * as moment from "moment";
import { stopPropagationWrapper } from "../../CampaignRequests/CampaignRequest";
import { numberFormatter } from "../../../../Utils/numberFormatter";

interface IProps {
  readonly quota: IReachQuotasResponse;
  readonly onReject: (id: string) => Promise<any>;
  readonly onAccept: (id: string) => Promise<any>;
}

export class QuoteRequest extends React.Component<IProps> {
  public renderStatusActions = () => {
    const { status } = this.props.quota;
    switch (status) {
      case 2:
        return (
          <div style={{ width: "15%" }}>
            <ConfirmSelections renderButtons={this.renderToggleButtons} />
          </div>
        );
      case 1:
        return (
          <div
            style={{
              width: "15%",
              color: "green",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Accepted
          </div>
        );
      case 0:
        return (
          <div
            style={{
              width: "15%",
              color: "red",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Rejected
          </div>
        );
      default:
        return null;
    }
  };

  public deleteRequest = () => this.props.onReject(this.props.quota.id);
  public acceptRequest = () => this.props.onAccept(this.props.quota.id);

  public renderToggleButtons = (toggleCollapse) => {
    const toggleCollapseDelete = stopPropagationWrapper(() =>
      toggleCollapse(this.deleteRequest)
    );
    const toggleCollapseAccept = stopPropagationWrapper(() =>
      toggleCollapse(this.acceptRequest)
    );
    return (
      <Aux>
        <span onClick={toggleCollapseDelete} className={styles.deleteBtn}>
          Reject
        </span>
        <Button onClick={toggleCollapseAccept} className={styles.btn}>
          Accept
        </Button>
      </Aux>
    );
  };

  public render() {
    const { quota } = this.props;
    return (
      <Aux>
        <div className={styles.wrapper}>
          <div className={styles.date}>
            {moment(quota.created_at).format("MMM DD, hh:mm")}
          </div>
          <div className={styles.user}>
            <img
              src={process.env.ENDPOINT + quota.store.store_image}
              className={styles.userImg}
            />
            <span className={styles.userName}>{quota.store.name}</span>
          </div>
          {quota.manager.phoneNumber ? (
            <div className={styles.phone}>
              <img
                className={styles.logo}
                src={require("../../../assets/phone.svg")}
              />
              {quota.manager.phoneNumber}
            </div>
          ) : (
            <div className={styles.phone} />
          )}
          <div className={styles.quota}>{quota.name}</div>
          <div className={styles.points}>
            {numberFormatter(quota.points, 0)}
            {+quota.reach_quota_eloquent_id === 4 && "+"}
            <span>Points</span>
          </div>
          {this.renderStatusActions()}
        </div>
      </Aux>
    );
  }
}
